import React from 'react'

function ModalConfirmation({children,recordName,icon}) {
  return (
    <>
    <div className="popup">
      <i className={`fa fa-${icon}`}></i>
      <h5 className="text-center m-1"><span>{recordName}?</span>  </h5>
  
  <div className="text-center py-2">
    {children}
  </div>
</div></>
  )
}

export default ModalConfirmation