import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { currentData, deepCopy } from '../../utils/utils';
import { useCreateExpenseMutation, useDeleteExpenseReceiptImageMutation, useLazyGetSingleExpenseQuery, useUpdateSingleExpenseMutation } from '../../store/apis/baseApi';
import { errorMessage, successMessage } from '../toastMessages/ToastifyMsg';
import CustomDeleteloader from '../CustomDeleteloader';
import { IMG_URL } from '../../common/common';

function CreateExpense(props) {
    const { expenseId } = useParams();




    const naviagte = useNavigate();
    const imgUrl = IMG_URL;
    const [loader, setLoader] = useState(false);
    const [actionLoader,setActionLoader] = useState(false);
    const [loaderText,setLoaderText] =useState("");
    const [Image, setImage] = useState(null);
    const [tempImage, setTempImage] = useState(null);
    const [expenseForm, setExpenseForm] = useState({
        expenses_vendor: "",
        expenses_category: "",
        expenses_title: "",
        expenses_description: "",
        expenses_total_amount: 0.00,
        expenses_total_amount_tax: 0.00,
        expenses_date: ""
    });

    const [creatExpense] = useCreateExpenseMutation();
    const [getSingleExpense] = useLazyGetSingleExpenseQuery();
    const [updateSingleExpense] = useUpdateSingleExpenseMutation();
    const [deleteExpenseReceiptImage] = useDeleteExpenseReceiptImageMutation();

    // =========Setting current date of invoice START ================
    useEffect(() => {
        if (!props.updating) {
            setExpenseForm((prevState) => ({ ...prevState, expenses_date: currentData() }))
        }
    }, [props.updating]);

    // get single expense
    useEffect(() => {
        if (props.updating) {
            setLoaderText("Loading . . .");
            setActionLoader(true);
            (async () => {
                try {
                    const { data, error } = await getSingleExpense(expenseId);
                    if (data) {
                        if (data?.success) {
                            let singleExpense = deepCopy(data.data);
                            setExpenseForm((prevState)=> ({
                                ...prevState,
                                expenses_vendor: singleExpense?.expenses_vendor,
                                expenses_category: singleExpense?.expenses_category,
                                expenses_title: singleExpense?.expenses_title,
                                expenses_description: singleExpense?.expenses_description,
                                expenses_total_amount: singleExpense?.expenses_total_amount,
                                expenses_total_amount_tax: singleExpense?.expenses_total_amount_tax,
                                expenses_date: singleExpense?.expenses_date,
                            }))
                            if (singleExpense.expenses_receipt_image) {
                                        setImage(`${imgUrl}${singleExpense.expenses_receipt_image}`)
                                        setTempImage(singleExpense.expenses_receipt_image);
                            }
                        }
                        if (!data?.success) {
                            errorMessage(data?.message);
                        }
                    }
                    if (error) {
                        errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
                    }
                } catch (error) { }
                finally {
                    setActionLoader(false);
                    setLoaderText("");
                }
            })()

        }
    }, [expenseId, getSingleExpense,imgUrl, props.updating])


    // =========Setting current date of invoice END ================
    const handleInput = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        setExpenseForm((prevState) => ({ ...prevState, [name]: name!=="expenses_total_amount_tax"&&name!=="expenses_total_amount"? value.trim(): isNaN(parseFloat(value))? 0.00:parseFloat(value) }));
    }

    // const keyPress =(e)=>{ 
    //     const {name} = e.target;
    //     if (e.key === 'Backspace') {
    //         setExpenseForm((prevState)=>({...prevState , [name]: name!=="expenses_total_amount_tax"&&name!=="expenses_total_amount"? "":  0.00}))
    //     }
    // }

    const removeImage = async () => {
        try {
            setLoaderText("Deleting . . .");
            setImage(null);
            setImage(null);
            if (props.updating) {
              setActionLoader(true);
              const { data, error } = await deleteExpenseReceiptImage({id:expenseId});
              if (data) {
                if (data.success){successMessage(data?.message);}
                if(!data.success){errorMessage(data?.message);}
              }
              if (error){errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");}
            }
        } catch(error){}
        finally{ setActionLoader(false);setLoaderText("")}
    };

    // add image in states to display image on ui and also updating the state which is being added in state for image while submiting form
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setTempImage(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            };
            event.target.value = "";
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (props.updating) {
            try {
                setLoader(true);

                let expenseData = new FormData();
                let body = { ...expenseForm,
                    expenses_total_amount_tax: Number(expenseForm.expenses_total_amount_tax)? expenseForm?.expenses_total_amount_tax:0.00,
                    expenses_total_amount: Number(expenseForm.expenses_total_amount)? expenseForm?.expenses_total_amount:0.00,
                }
                expenseData.append('expenses_receipt_image', tempImage)
                expenseData.append('data', JSON.stringify(body));
                
                const {data,error} = await updateSingleExpense({id:expenseId,expenseData});
                if (data) {
                    if (data.success) {
                        successMessage(data?.message);
                        naviagte('/expenses')
                    }
                    if (!data.success) {
                            errorMessage(data?.message);
                    }    
                }

                if (error) {
                    errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
                }
            } catch (error) {}
            finally{setLoader(false);}
        } else {
            try {
                setLoader(true);

                let expenseData = new FormData();
                let body = { ...expenseForm,
                    expenses_total_amount_tax: Number(expenseForm.expenses_total_amount_tax)>=0 ? expenseForm?.expenses_total_amount_tax:0.00,
                    expenses_total_amount: Number(expenseForm.expenses_total_amount)>=0 ? expenseForm?.expenses_total_amount:0.00,
                }

                expenseData.append('expenses_receipt_image', tempImage)

                expenseData.append('data', JSON.stringify(body));

                const { data, error } = await creatExpense(expenseData);
                if (data) {
                    if (data?.success) {
                        successMessage(data?.message);
                        naviagte('/expenses')
                    }
                    if (!data?.success) {
                        errorMessage(data?.message)
                    }
                }
                if (error) {
                    errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
                }

            } catch (error) {

            }
            finally {
                setLoader(false);
            }
        }


    }


    return (
        <>
            {loader &&<CustomDeleteloader message={`${props.updating ? "Updating" : "Saving"} . . .`} />}
            {actionLoader &&<CustomDeleteloader message={loaderText} />}
            <div className="create-expense-wrapper">
                <div className="container">
                    <div className="spacer"></div>
                    <div className="productsBreadcrumbs">

                        <div className="breadcrumb-container">

                            <nav className="breadcrumbs">
                                <Link to="/" className="breadcrumbs__item" title="Go to Dashboard">Dashboard</Link>
                                <Link to="/expenses"
                                    // onClick={navigateTo} 
                                    className="breadcrumbs__item" title="Go to Expenses">Expenses</Link>
                                {/* <Link to="" className="breadcrumbs__item">Cart</Link> */}
                                <span className="breadcrumbs__item is-active"> {props.updating ? "Edit" : "Create"}</span>
                            </nav>  <div className="spacer"></div>
                        </div>
                    </div>
                    <div className="expense-form-wrapper">
                        <h3>{props.updating ? 'Edit' : 'Create'} Expense</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="formField">
                                <label className="col-md-12 col-lg-3 col-12" htmlFor="expenses_vendor">
                                    Vendor
                                </label>
                                <div className="col-lg-9 col-12">
                                    <input
                                        className={`form-control `}
                                        type="text"
                                        name="expenses_vendor"
                                        id="expenses_vendor"
                                        onChange={(e) => handleInput(e)}
                                        // onKeyDown={(e)=>keyPress(e)}
                                        defaultValue={expenseForm?.expenses_vendor}
                                        placeholder="Vendor Name"
                                        autoComplete="off"
                                    ></input>
                                </div>
                            </div>
                            <div className="formField">
                                <label className="col-md-12 col-lg-3 col-12" htmlFor="expenses_category">
                                    Category <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-9 col-12">
                                    <input
                                        className={`form-control `}
                                        type="text"
                                        name="expenses_category"
                                        id="expenses_category"
                                        required
                                        onChange={(e) => handleInput(e)}
                                        // onKeyDown={(e)=>keyPress(e)}
                                        defaultValue={expenseForm?.expenses_category}
                                        placeholder="Expense Category"
                                        autoComplete="off"
                                    ></input>
                                </div>
                            </div>
                            <div className="formField">
                                <label className="col-md-12 col-lg-3 col-12" htmlFor="expenses_title">
                                    Title <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-9 col-12">
                                    <input
                                        className={`form-control `}
                                        required
                                        type="text"
                                        name="expenses_title"
                                        id="expenses_title"
                                        onChange={(e) => handleInput(e)}
                                        // onKeyDown={(e)=>keyPress(e)}
                                        defaultValue={expenseForm?.expenses_title}
                                        placeholder="Expense Title"
                                        autoComplete="off"
                                    ></input>
                                </div>
                            </div>
                            <div className="formField">
                                <label className="col-md-12 col-lg-3 col-12" htmlFor="expenses_description">
                                    Description
                                </label>
                                <div className="col-lg-9 col-12">
                                    <textarea
                                        className={`form-control `}
                                        type="text"
                                        name="expenses_description"
                                        id="expenses_description"
                                        onChange={(e) => handleInput(e)}
                                        // onKeyDown={(e)=>keyPress(e)}
                                        defaultValue={expenseForm?.expenses_description}
                                        placeholder="Description"
                                        autoComplete="off"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="formField">
                                <label className="col-md-12 col-lg-3 col-12" htmlFor="expenses_total_amount">
                                    Expense Amount <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-9 col-12">
                                    <input
                                        className={`form-control `}
                                        required
                                        type="number"
                                        name="expenses_total_amount"
                                        id="expenses_total_amount"
                                        onChange={(e) => handleInput(e)}
                                        // onKeyDown={(e)=>keyPress(e)}
                                        value={expenseForm?.expenses_total_amount}
                                        placeholder="0.00"
                                        autoComplete="off"
                                    ></input>
                                </div>
                            </div>
                            <div className="formField">
                                <label className="col-md-12 col-lg-3 col-12" htmlFor="expenses_total_amount_tax">
                                    Expense Tax
                                </label>
                                <div className="col-lg-9 col-12">
                                    <input
                                        className={`form-control `}
                                        type="number"
                                        name="expenses_total_amount_tax"
                                        id="expenses_total_amount_tax"
                                        onChange={(e) => handleInput(e)}
                                        // onKeyDown={(e)=>keyPress(e)}
                                        value={expenseForm?.expenses_total_amount_tax}
                                        placeholder="0.00"
                                        autoComplete="off"
                                    ></input>
                                </div>
                            </div>
                            <div className="formField">
                                <label className="col-md-12 col-lg-3 col-12" htmlFor="expenses_date">
                                    Date <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-9 col-12">
                                    <input
                                        className={`form-control `}
                                        required
                                        type="date"
                                        name="expenses_date"
                                        id="expenses_date"
                                        onChange={(e) => handleInput(e)}
                                        defaultValue={expenseForm?.expenses_date}
                                        placeholder="Siri"
                                        autoComplete="off"
                                    ></input>
                                </div>
                            </div>
                            <div className="formField">
                                <label className="col-md-12 col-lg-3 col-12" htmlFor="expenses_receipt_image">
                                    Receipt Image
                                </label>
                                <div className="col-lg-9 col-12">
                                    <div className="image-wrapper my-1">
                                        <div className="image-wrapper-inner form-control">
                                            {Image === null &&
                                                <div className="imagelabel">
                                                    <i className="fas fa-image"></i>
                                                    <span> Image</span>
                                                </div>
                                            }
                                            <input type="file" className="form-control" accept=".png,.jpeg,.jpg,.webp" name="expenses_receipt_image" id='expenses_receipt_image' defaultValue={''} onChange={handleFileInputChange}></input>
                                            <span className="showlabel"><i className="fa fa-plus"></i>
                                                {Image === null ? ' Add ' : ' Update '

                                                }
                                                your recipt</span>
                                            {Image !== null &&
                                                <span className="removeImage" onClick={removeImage}><i className="fa fa-times"></i></span>
                                            }
                                            {/* <div className="overlay"></div> */}
                                            {Image !== null &&
                                                <img src={Image} className="customImage" alt="test" height={130} width={200} />
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="button-wrapper">
                                <button className="btn btn-save" type="submit" title={ props?.updating ? "Update" : "Save"}> <span>{props?.updating ? "Update" : "Save"}</span><i className="fas fa-save"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="spacer"></div>
        </>
    )
}

export default CreateExpense