import moment from "moment/moment";
import React from "react";
import { separateComma } from "../../utils/utils";
// import { convertNumberToCommaNotation } from "../../utils/utils";

function Preview({ formData, total, totalDeductions, selectedCurrency, logoImage }) {
  // console.log(moment(formData?.salary_date).format("ll"),formData?.salary_date,"formData?.term>",formData?.term,"afterMoment>>",(moment(moment(formData?.salary_date).add(formData?.term, "days")).format("MMM Do YYYY")));

  return (
    <>
      <div className="spacer"></div>
      {/* <div className="spacer"></div> */}
      <section>
        <div className="container px-0">
          <div className="col-12" style={{"margin":'auto'}}>
            <div
              className="previewInvoiceContainer salarypreviewcontainer border-slip"
              style={{ borderTop: `4px solid ${formData?.color}` }}
            >
              <div className="previewHeader">
                <div className="FromHeader">
                  <div className="col-12 col-md-6 fromDetailsContainer">
                  {logoImage &&(
                    <div className="fromDetails form-details-image" >
                      <div>
                        <img
                          width={100}
                          max-height={100}
                          height={"auto"}
                          src={ logoImage}
                          alt=""
                        />
                      </div>
                    </div>)
                    }
                  </div>
                  <div className="col-12 col-md-6 invoiceDetailsContainer">
                    <div className="invoiceDetails">
                      <div className=" col-md-12 invoice">
                        <div className="col-12">
                          <b className="text-uppercase">  <span>{formData?.title}</span></b>
                          <p className="text-uppercase">
                            <span>{formData?.receipt_number}</span>
                          </p>
                        </div>
                      </div>
                      <div className=" col-md-12 invoiceDate">
                        <div className="col-12">
                          <b> Date  </b>
                          <p> {moment(formData?.salary_date).format("ll")}
                          </p>
                        </div>
                      </div>
                      { formData?.term !== "none" &&(
                        <div className=" col-md-12 dueDate">
                          <div className="col-12">
                            <b> Due Date </b>
                            <p>
                              {" "}
                              {/* {moment(moment(formData?.invoicedate).add(formData?.terms, 'days').calendar(),"MM-DD-YYYY").format('ll')} */}
                              {/* {moment(moment(formData?.salary_date).add(formData?.term, "days").calendar(),"MM-DD-YYYY").format("ll")} */}
                              {moment(moment(formData?.salary_date).add(formData?.term, "days")).format("ll")}
                            </p>
                          </div>
                        </div>)
                      }
                      <div className=" col-md-12 balanceDue">
                        <div className="col-12">
                          <b>Net Amount  </b>
                          <p>
                            {formData?.currency_selected?.Code}{" "}
                            {formData?.currency_selected?.Symbol}
                            {separateComma(Number(formData?.total_net_pay))}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="toDetailsContainer to-from-container">
                  <div className="form-details-salary-slip">
                    <h4 > From<br/> {formData?.from_name}</h4>

                    <div className={`${formData?.from_phone ? "":"d-none"}`}> <b> Business Number:</b> {formData?.from_phone}</div>
                    


                    <div>{formData?.from_address1}</div>
                    
                    <div>{formData?.from_address2}</div>
                    
                    
                    <div>{formData?.from_zip_code}</div>
                   
                    
                    
                    <div>{formData?.from_email}</div>
                  </div>
                  <div className="col-6">
                    <div className="toDetailsHeader">
                      <div className="toDetailsHeading heading-customer-name">
                        <h4>To<br/>{formData?.to_employee_name}</h4>
                        {/* <h4 className=""></h4> */}
                      </div>
                    </div>
                    <div className="toDetails">
                      <div>{formData?.to_employee_address1}</div>
                      <div>{formData?.to_employee_address2}</div>
                      <div>{formData?.to_employee_zip_code}</div>
                      <div>{formData?.to_employee_phone}</div>
                      <div>{formData?.to_employee_email}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="previewBodyDesktop">
                <div className="previewBodytableDesktop">
                  <div
                    className="d-flex responsive-design-preview"
                    style={{ gap: "10px", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "50%" }} className="inner-preview-amount">
                      <div
                        className="previewBodytableheadDesktop"
                        style={{ justifyContent: "space-around" }}
                      >
                        <div className="col-6 text-start">
                          <span>EARNINGS</span>
                        </div>
                        <div className="col-6 text-end">
                          <span>AMOUNT</span>
                        </div>
                        {/* <div className="col-3">
                                <span>DEDUCTIONS</span>
                              </div>
                              <div className="col-3">
                                <span>AMOUNT</span>
                              </div> */}
                      </div>

                      <div
                        className="previewBodyTableBodyContainer"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        {formData?.earnings.map((desc, index) => {
                          return (
                            <div className="previewSinglerecord"
                              key={index}
                             
                            >
                              <div className="col-6">
                                <div className="text-start">
                                  <p>
                                    {desc?.earning_title}
                                  </p>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="text-end">
                                  <p>
                                  {formData?.currency_selected?.Symbol}{separateComma(Number(desc?.earning_amount))}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="preview-table-footer">
                        <div className="col-6"><span>Total Earnings</span></div>
                        <div className="col-6">
                          <span>
                            {formData?.currency_selected?.Symbol}{separateComma(Number(total))}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "50%" }} className="inner-preview-amount">
                      <div
                        className="previewBodytableheadDesktop"
                        style={{ justifyContent: "space-around" }}
                      >
                        <div className="col-6 text-start">
                          <span>DEDUCTIONS</span>
                        </div>
                        <div className="col-6 text-end">
                          <span>AMOUNT</span>
                        </div>
                        {/* <div className="col-3">
                                <span>DEDUCTIONS</span>
                              </div>
                              <div className="col-3">
                                <span>AMOUNT</span>
                              </div> */}
                      </div>

                      <div
                        className="previewBodyTableBodyContainer"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        {formData?.deductions.map((deductions, index) => {
                          return (
                            <div
                              key={index}
                              className="previewSinglerecord"
                            >
                              <div className="col-6">
                                <div className="text-start">
                                  <p>
                                    {" "}
                                    {deductions?.deduction_title}
                                  </p>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="text-end">
                                  <p>
                                  {formData?.currency_selected?.Symbol}{separateComma(Number(deductions?.deduction_amount))}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="preview-table-footer">
                        <div className="col-6"><span>Total Deductions</span></div>
                        <div className="col-6">
                          <span>
                            {formData?.currency_selected?.Symbol}{separateComma(Number(totalDeductions))}
                          </span>

                        </div>

                       
                        <p></p>
                      </div>
                    </div>
                  </div>
                    <div className="salarytotalAmount-container">
                             <p className="total-amount-formdata" >
                          Net Amount: {formData?.currency_selected?.Symbol}{separateComma(Number(formData?.total_net_pay))}
                          
                        </p>
                        {formData?.amount_in_words &&(
                          <p className="amount-in-words"> <span><b>Amount In words : </b></span>{formData?.amount_in_words} </p>
                         )
                        }
                    </div>
                  <div className="previewBodyTableFooterNotesContainer">
                    {/* <div className="salary-inword"><b>Net Salary In Words ({formData?.currency_selected?.Symbol}):</b>&nbsp; {numberToWords(formData?.total_net_pay)} Only</div> */}
                    <div className="footerNotes">
                      <h6 className="text-start"><b> Notes:</b></h6>
                      <p className="text-start">{formData?.ending_notes}</p>
                    </div>
                    <div className="footerGallery"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Preview;
