import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { FRONTSITE, LARAVELIMG } from '../../common/common';
import SelectImg from '../imgSelect/SelectImg';
// import { useGetInvoiceTemplatesQuery } from '../../store/apis/baseApi';


function SelectInvoiceTemplate({formData,handleBlur,handleClose, filteredTemps}) {
  const [allTemplates,setAllTemplates] = useState([]);
  const navigate = useNavigate();
  // https://stagingfront.tradingforms.com/invoice-template
  const frontsiteUrl = FRONTSITE;
  useEffect(()=>{
    if (filteredTemps) {
        const options = filteredTemps.map(template => ({
          label: template.template_title,
          value: template.id,
          image: `${LARAVELIMG}${template?.template_image}`,
          selected: template?.id,
          type: 'template',
        }))
        setAllTemplates(options);
    }
  },[filteredTemps])


  const handleOnChangeSelect = (selected)=>{
      handleBlur(selected?.value);
  }
  const handleSelectForm = (e) => {
    e.preventDefault();
    handleClose();
  }
  



  return (
    <>
    <div className="templatesContainer">
          <div className="temp-header">
              <label htmlFor="selectedTemplate"> <b> Select Template</b></label>
              <Link to={`${frontsiteUrl}${formData?.type === "chargable"?'invoice':'quotation'}-template`} target="_blank" >View Templates</Link>
          </div>

          {/* <select className="form-select my-2" value={formData?.selectedTemplate} onChange={handleBlur} name="selectedTemplate" aria-label="selectTemplate">
              {allTemplates &&
                  allTemplates.map((temp, index)=>{
                    return(
                      <option value={temp.id} key={index}>{temp.template_title}</option>
                    )
                  })
              }

          </select> */}
          <form onSubmit={handleSelectForm}>
            <div className="col-12 py-2">
              <SelectImg
                  options={allTemplates}
                  handleOnChangeSelect={handleOnChangeSelect}
                  defaultSelect= {formData?.selectedTemplate}
              />
            </div>
            <div className="termsConditions">
                  By continuing you agree to our <strong> 
                    <Link to={`${frontsiteUrl}terms-of-services`} target="_blank" rel="noreferrer noopener" >Terms of Use</Link> </strong>
                    and acknowledge that you have read our <strong>
                    <Link to={`${frontsiteUrl}privacy-policy`} target="_blank" rel="noreferrer noopener" >Privacy Policy</Link></strong>.</div>
          <div className="submitContainer">
            <button type='button' className='btn  btn-primary' onClick={()=>navigate(-1)}>
              <i className="fas fa-angle-double-left"></i> <span>Back</span>
            </button>
            <button type='submit' className="btn btn-save">agree & continue</button>
          </div>
          </form>

    </div>
    </>
  )
}

export default SelectInvoiceTemplate