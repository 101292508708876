import React, { useCallback, useEffect, useState } from 'react'
import CustomDeleteloader from '../CustomDeleteloader'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { 
  useDeleteSingleInvoiceFooterImageMutation, 
  useDeleteSingleInvoiceLogoMutation, 
  useLazyGetQuotationUniqueNumberQuery, 
  useLazyGetSingleInvoiceQuery, 
  useSaveInvoicesMutation, 
  useUpdateInvoiceMutation, 
  useDeleteSingleInvoiceItemMutation, 
  useLazyClientsNameSearchQuery, 
  useLazyGetClientDataByIdQuery, 
  useLazyItemByNameSearchQuery, 
  useLazyGetItemDataByIdQuery, 
  useLazyGetCompanySettingQuery } from '../../store/apis/baseApi';
import uuid from 'react-uuid';
import countriesCurrencies from '../../assets/js/countriesCurrency';
import { errorMessage, successMessage } from '../toastMessages/ToastifyMsg';
import { currentData, deepCopy } from '../../utils/utils';
import axios from 'axios';
import TaxationDiscount from '../createInvoice/TaxationDiscount';
import Preview from '../createInvoice/Preview';
import Edit from '../createInvoice/Edit';
import ReactPortal from '../reactPortal/ReactPortal';
import Modal from '../modal/Modal';
import SelectInvoiceTemplate from '../createInvoice/SelectInvoiceTemplate';
import SendEmail from '../SendEmail';
import PhotoDetails from '../createInvoice/PhotoDetails';
import { IMG_URL, LARAVELAPI } from '../../common/common';
import { useDispatch, useSelector } from 'react-redux';
import { setPlanDetails } from '../../store/slices/subscribedPlanDetails';

let templateSelected = false;

function CreateQuotation(props) {
  const imgUrl = IMG_URL;
  const LARAVELurl = LARAVELAPI;
  const allTempStore =  useSelector((state)=>state.allTemplates);

  const quotationPlanDetail = useSelector(state=>state.planDetails);
  const { quotationId } = useParams();
  let urlId ;
  if (quotationId) {
    urlId = window.atob(quotationId);
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [clientsNameSearch] = useLazyClientsNameSearchQuery();
  const [getClientDataById] =  useLazyGetClientDataByIdQuery();
  const [itemByNameSearch] = useLazyItemByNameSearchQuery();
  const [getItemDataById] = useLazyGetItemDataByIdQuery();
  const [showForm, setShowForm] = useState(0);
  const [logoImage, setLogoImage] = useState(null);
  const [footerImagesA, setFooterImagesA] = useState([]);
  const [tempLogo, setTempLogo] = useState();
  const [isFooterOpen, setIsFooterOpen] = useState(false);
  const [templateSelect, setTemplateSelect] = useState(true);
  // const [isOpen, setIsOpen] = useState(false);


  const [addedImage, setAddedImage] = useState(null);
  const [footerModalInput, setFooterModalInput] = useState();
  const [footerImagesCol, setFooterImagesCol] = useState([]);
  const [allTemplates,setAllTemplates] = useState([]);

  const [loader,setLoader] = useState(true);
  const [delLoader,setDelLoader] = useState(false);
  const [postLoader,setPostLoader] = useState(false);
  const [emailModal,setEmailModal] = useState(false);
  const [emailbuttonLoader,setEmailbuttonLoader] = useState(false);
  const [isConfirmation,setIsConfirmation]= useState(false);
  const [pdfId,setPdfID] = useState('');

  
  const [saveInvoices] =  useSaveInvoicesMutation();
  const [updateInvoice] = useUpdateInvoiceMutation();
  const [deleteSingleInvoiceLogo] = useDeleteSingleInvoiceLogoMutation();
  const [deleteSingleInvoiceFooterImage] = useDeleteSingleInvoiceFooterImageMutation();
  const [getQuotationUniqueNumberQuery] = useLazyGetQuotationUniqueNumberQuery();
  const [getSingleInvoice] = useLazyGetSingleInvoiceQuery();
  const [deleteSingleInvoiceItem] = useDeleteSingleInvoiceItemMutation();
  const [getCompanySetting] = useLazyGetCompanySettingQuery();

  const [showSuggest, setShowSuggest] = useState(false);
  const [showSuggestItem,setShowSuggestItem] =useState(false);


  // http://localhost:4000/api/v1/
  // https://stagingapi.tradingforms.com/api/v1/
  // const liveUrl = "https://stagingapi.tradingforms.com/api/v1/";

   // FormData Body
  const [formData, setFormData] = useState({
    invoiceHeader: "QUOTATION",
    // logo: null,
    color: "#00acee",
    type: "quotation",
    senderName: "",
    senderEmail: "",
    senderStreet: "",
    senderCityState: "",
    senderWebsite: "",
    senderZipCode: "",
    senderPhone: "",
    senderFax: "",
    senderGst: "",
    senderNtn: "",
    invoiceNumber: "",
    invoicedate: "",
    terms: "none",
    termValue: "",
    customerWebsite: "",
    customerName: "",
    customerEmail: "",
    customerStreet: "",
    customerCityState: "",
    customerZipCode: "",
    customerFax: "",
    customerGst: "",
    customerNtn: "",
    customerPhone: "",
    description: [

      {
        id: uuid(),
        itemDescription: "",
        additionalDetail: "",
        rate: "0.00",
        quantity: "1",
        amount: "0.00",
        tax: "false",
        state: "new",
      },
    ],
    paid: "false",
    taxType: "none",
    taxLabel: "Tax",
    taxRate: 0,
    taxInclusive: "false",
    discountType: "none",
    discountValue: 0,
    currency: countriesCurrencies[0],
    subTotal: 0,
    discountAmount: 0,
    totalTax: 0,
    total: 0,
    totalPaid: 0,
    balanceDue: 0,
    notes: "",
    selectedTemplate: "",
    // footerImages: [
    //   // {
    //   //   img_filename: '',
    //   //   img_description: '',
    //   //   img_additional_notes : '',
    //   // }
    // ],
    previewEmail: "",
    status: "outstanding",
  });

  // sendEmail Body
  const [emailBody, setEmailBody] = useState({
    id: "",
    email_to: "",
    email_body: "Kindly find the attached quotation.",
    attachment: "true",
    cc_status : "false",
    cc_emails: ""
  });

  // ------- Templates api call -------
  useEffect(() => {
    setLoader(true);

    let allTemps = deepCopy(allTempStore.allTemplates);
    let filterTemps = allTemps?.filter(
      (temp) => temp?.template_type === "invoice"
    );

    if (filterTemps.length) {
      setAllTemplates(filterTemps);
      // handleDefaultTemplate(filterTemps[0].id);
      setTimeout(() => {

        setLoader(false);
      }, 500);
    }
  }, [allTempStore]);


// ------- Company Settings --------------
useEffect(() => {
  if (!props.updating) {
    
    const compantSettings = async () => {
      try {
        const { data, error } = await getCompanySetting();
        setLoader(true);
        if (data) {
          if (data.success) {
            if (data && Object.keys(data.data).length) {
              let companySettings = deepCopy(data.data);
              setFormData((prevState) => {
                return {
                  ...prevState,
                  currency: JSON.parse(companySettings?.currency),
                  color: companySettings?.company_color,
                  invoiceHeader: companySettings?.invoice_title,
                  senderName: companySettings?.company_name,
                  senderEmail: companySettings?.company_email,
                  senderStreet: companySettings?.company_address1,
                  senderCityState: companySettings?.company_address2,
                  senderWebsite: companySettings?.company_website,
                  senderZipCode: companySettings?.company_zip_code,
                  senderPhone: companySettings?.company_phone,
                  senderFax: companySettings?.company_fax,
                  senderGst: companySettings?.company_gst,
                  senderNtn: companySettings?.company_ntn,
                  taxType: companySettings?.tax_type || "none",
                  taxLabel: companySettings?.tax_label,
                  taxRate: companySettings?.tax_rate,
                  description: prevState.description.map((item) => {
                    return ({
                      ...item,
                      tax: companySettings?.tax_type === "onTotal" ? "true" : item.tax ?? "false"
                    })
                  }
                  ),
                  notes: companySettings?.company_default_note,
                };
              });
              if (
                companySettings?.company_logo !== "NULL" &&
                companySettings?.company_logo !== "null" &&
                companySettings?.company_logo !== null
              ) {
                // let imgUrl = "https://stagingapi.tradingforms.com/";
                setLogoImage(`${imgUrl}${companySettings?.company_logo}`);
                setTempLogo(companySettings?.company_logo);
              }

              setLoader(false);
            }
          }
          if (!data.success) {
            setLoader(false);
          }
        }

        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
          setLoader(false);
        }

      } catch (error) {

      }

    }
    compantSettings();
  }
}, [imgUrl,getCompanySetting, props.updating]);

// ------- unique number for quotation------
useEffect(() => {

  if(!props.updating){
    setLoader(true);
    (async()=>{
      try {
        const {data, error} = await getQuotationUniqueNumberQuery();
          // console.log(data);
        
        if (data) {
          // console.log("uniqueNumber>>", data);
          if (data.success) {
          // console.log("Success>uniqueNumber>>", data);

             let uniqueQuote = deepCopy(data.data);
                if (uniqueQuote !=="")  {
                  setFormData((prevState)=>{
                    return{
                      ...prevState,
                      invoiceNumber: uniqueQuote
                    }
                  })
                }
                if(data.data.length === 0){
                  uniqueQuote="TFQUOT-0001"
                  setFormData((prevState)=>{
                    return{
                      ...prevState,
                      invoiceNumber: uniqueQuote
                    }
                  })
                }
                setLoader(false);
          }
          if (!data.success) {
            // console.log("!Success>uniqueNumber>>",data.message);
            errorMessage(data?.message);
            setLoader(false);
          }
        }
        if (error) {
          // console.log("Error in fetching unique invoice number",error.data.message);
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
           setLoader(false);
        }
        
      } catch (error) {
        // console.log("catchError",error);
        setLoader(false);
      }
     })();
    
  }


  
}, [getQuotationUniqueNumberQuery,props.updating])



// -------Get Single record which has to update

  const singleDataSet = (data) => {

    let invoiceData = deepCopy(data.invoicedata[0]);
    let descriptionArray = deepCopy(data.description);
    // let paymentsRecords = deepCopy(data.payments);
    let footerAllImages = deepCopy(data.FooterImages);
    descriptionArray = descriptionArray.map((item) => {
      return ({ tax: item.tax === "true" ? true : false, state: 'old', ...item })
    })
    // console.log(invoiceData);
    let date = new Date(invoiceData.invoicedate);
    var invoiceDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    setPdfID(invoiceData?.suid_inv_id);
    setFormData((prevState) => {
      return {
        ...prevState,
        invoiceHeader: invoiceData.invoiceHeader,
        logo: invoiceData.logo,
        color: invoiceData.color,
        senderName: invoiceData.senderName,
        senderEmail: invoiceData.senderEmail,
        senderStreet: invoiceData.senderStreet,
        senderCityState: invoiceData.senderCityState,
        senderZipCode: invoiceData.senderZipCode,
        senderPhone: invoiceData.senderPhone,
        senderFax: invoiceData.senderFax,
        senderGst: invoiceData.senderGst,
        senderNtn: invoiceData.senderNtn,
        senderWebsite: invoiceData.senderWebsite,
        invoiceNumber: invoiceData.invoiceNumber,
        invoicedate: invoiceDate,
        terms: invoiceData.terms,
        termValue: invoiceData.termValue,
        customerName: invoiceData.customerName,
        customerEmail: invoiceData.customerEmail,
        customerStreet: invoiceData.customerStreet,
        customerCityState: invoiceData.customerCityState,
        customerZipCode: invoiceData.customerZipCode,
        customerFax: invoiceData.customerFax,
        customerGst: invoiceData.customerGst,
        customerWebsite: invoiceData.customerWebsite,
        customerNtn: invoiceData.customerNtn,
        customerPhone: invoiceData.customerPhone,
        description: descriptionArray,
        // paid: false,
        // payments: paymentsRecords,
        taxType: invoiceData.taxType,
        taxLabel: invoiceData.taxLabel,
        taxRate: invoiceData.taxRate,
        taxInclusive: invoiceData.taxInclusive,
        discountType: invoiceData.discountType,
        discountValue: Number(invoiceData.discountValue),
        currency: JSON.parse(invoiceData.currency),
        subTotal: invoiceData.subTotal,
        discountAmount: invoiceData.discountAmount,
        totalTax: invoiceData.totalTax,
        total: invoiceData.total,
        totalPaid: invoiceData.totalPaid,
        balanceDue: invoiceData.balanceDue,
        notes: invoiceData.notes,
        selectedTemplate: JSON.stringify(invoiceData.selectedTemplate),
        footerImages: [
          // {
          //   img_filename: '',
          //   img_description: '',
          //   img_additional_notes : '',
          // }
        ],
        previewEmail: invoiceData.previewEmail,
        status: invoiceData.status,
      }
    }

    );
    setEmailBody((prevState) => {
      return ({
        ...prevState,
        id: urlId,
        email_to: invoiceData.customerEmail,
        email_body: `Kindly find the attached quotation.`
      })
    })
    // console.log(invoiceData.customerEmail);
    // setPayments(paymentsRecords);
    // https://stagingapi.tradingforms.com:4000/
    // http://localhost:4000/
    // let imgUrl = "https://stagingapi.tradingforms.com/";

    let arrays = footerAllImages.map((item) => {
      let link = imgUrl.concat(item.img_filename);
      return { ...item, img_filename: link };
    });
    // console.log(footerAllImages);
    setFooterImagesA([]);
    setFooterImagesCol(arrays);

    setTempLogo(invoiceData.invoiceLogo);
    if (invoiceData.invoiceLogo !== "NULL" && invoiceData.invoiceLogo !== null && invoiceData.invoiceLogo) {
      setLogoImage(`${imgUrl}${invoiceData.invoiceLogo}`);
    } else {
      setLogoImage(null);
    }
    setShowForm(1);
  }


  useEffect(() => {
    if (props.updating) {
      setTemplateSelect(false);
      setLoader(true);
      (async () => {
        try {
          const { data, error } = await getSingleInvoice(urlId );
          if (data) {
          setShowForm(1);
          if (data.success) {
             singleDataSet(data);
            setTimeout(setLoader(false),1000);
          }
          if (!data.success) {
            errorMessage(data?.message);
          }
        }
        if (error) {
          setTimeout(setLoader(false),1000);
          
        }
      } catch (error) {}
      finally{
          setTimeout(setLoader(false),1000);
        }
        
      })();
    }
  }, [urlId , props.updating, getSingleInvoice,imgUrl]);

 // =========Setting current date of invoice START ================
 useEffect(() => {
  if (!props.updating) {
    setFormData((prevState)=>{
      return{
      ...prevState,
        invoicedate : currentData()
    }
    })
  }
}, [props.updating]);
// =========Setting current date of invoice END ================

  // =================||Modal handling START||=====================
  const handleCloseSelectTemplate = useCallback(() => {
    setTemplateSelect(false);
  }, []);
  // const handleModalOpen = useCallback(() => {
  //   setIsOpen(true);
  // }, []);
  
  const handleOpenSelectTemplate = useCallback(() => {
    setTemplateSelect(true);
  }, []);
  const increaseInvoicePlan = (type)=>{
    const newPlanDetails = JSON.parse(JSON.stringify(quotationPlanDetail?.planDetails))
    newPlanDetails.plan_usage_details[type].usage_count += 1
    dispatch(setPlanDetails(newPlanDetails));
  }
  const handleOpenEmail = ()=>{
    if (quotationPlanDetail?.planDetails?.plan_usage_details?.email_sent?.usage_count<quotationPlanDetail?.planDetails?.plan_usage_details?.email_sent?.total) {
      setEmailModal(true);
    }
    else errorMessage("Limit Reached. Upgrade your plan!");
  }
  const handleCloseEmail = ()=>{
      setEmailModal(false);
      setEmailBody((prevState)=>{
        return{
          ...prevState,
          id: "",
          email_to:"",
          cc_status: "false",
          cc_emails:""
        }
      })
  }
  // =================||Modal handling END||=====================


  // =============calculations START=============
  useEffect(() => {
    setFormData((prevState) => {
      let totalSum = 0;
      let totalTax = 0;
      let grandTotal = 0;
      let discount = 0;
      let totalAfterDiscount = 0;
      let afterDiscTaxableArray = [];
      let afterDiscNonTaxableArray = [];
      let totalPayed = 0;
      let balanceDue = 0;
      let taxableArray = 0;
      let nonTaxableArray = 0;
      let taxableSum = 0;
      let nonTaxableSum = 0;
      prevState?.description.forEach((value) => {
        totalSum = Number(totalSum + value.rate * value.quantity);
      });
      //==== filtering non-taxable items
      nonTaxableArray = prevState?.description.filter((desc) => {
        return desc.tax === "false";
      });
      //==== filtering taxable items
      taxableArray = prevState?.description.filter((desc) => {
        return desc.tax === "true";
      });
      // console.log("nonTaxableSum>>",nonTaxableSum);
      

      if (prevState?.discountType === "percent") {
        
        discount = Number((prevState?.discountValue / 100) * totalSum);
        let discPer= prevState?.discountValue;

        afterDiscNonTaxableArray =  nonTaxableArray.map((item)=>{
            let afterDiscount =  item.amount  - (discPer *item.amount)/100;
            return {
              ...item,
              afterDiscountAmount: afterDiscount}
        })
        // console.log()
        afterDiscTaxableArray = taxableArray.map((item)=>{
          let afterDiscount =  item.amount  - (discPer *item.amount)/100;
          return {
            ...item,
            afterDiscountAmount: afterDiscount}
        }) 
        // console.log("afterDiscNonTaxableArray",afterDiscNonTaxableArray,"afterDiscTaxableArray",afterDiscTaxableArray)
      }

      if(prevState?.discountType === "flatAmount") {
        discount = Number(prevState?.discountValue);
        let totalItems = 0;
        let discountPerItem = 0;
        prevState.description.forEach((item)=>{
          if (item.quantity > 0 && item.rate > 0) {
            totalItems+=1;
            discountPerItem = discount/totalItems;
          }
        })
        // console.log("totalItems>>",totalItems,"discountAmount",discount);
        // console.log("discountOnEachItem-discount/totalItems>>",(discount/totalItems));
         
        afterDiscNonTaxableArray =  nonTaxableArray.map((item)=>{
          let afterDiscount=0; 
          if (item.quantity > 0 && item.rate > 0) {
            afterDiscount =  item.amount - discountPerItem;
            
          }else{
            afterDiscount =  0 ;
          }
          return {
            ...item,
            afterDiscountAmount: afterDiscount}
      })
      // console.log()
      afterDiscTaxableArray = taxableArray.map((item)=>{
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount =  item.amount - discountPerItem;
          
        }else{
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount}
      }) 

        totalAfterDiscount = (taxableSum + nonTaxableSum) - discount;
        
        // console.log("totalAfterDiscount>>",totalAfterDiscount);
        
      }
      if (prevState?.discountType === "none") {

        afterDiscNonTaxableArray =  nonTaxableArray.map((item)=>{
          let afterDiscount=0; 
          if (item.quantity > 0 && item.rate > 0) {
            afterDiscount =  item.amount;
            
          }else{
            afterDiscount =  0 ;
          }
          return {
            ...item,
            afterDiscountAmount: afterDiscount}
      })
      // console.log()
      afterDiscTaxableArray = taxableArray.map((item)=>{
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount =  item.amount ;
          
        }else{
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount}
      }) 
      }
      
      if (formData?.taxType === "onTotal" || formData?.taxType === "perItem") {
        afterDiscTaxableArray.forEach((item)=>{
          if (item.quantity > 0 && item.rate > 0) {
            taxableSum = taxableSum + (prevState.taxRate * item.afterDiscountAmount) / 100; 
          }
        })  
        totalTax = taxableSum;

        // console.log("taxableSumAfterDiscount>>",taxableSum,afterDiscTaxableArray," afterDiscNonTaxableArray>>", afterDiscNonTaxableArray)
        grandTotal = Number(totalTax + totalSum - discount);
        
      }
      // if (formData?.taxType === "perItem") {
      //   grandTotal = Number(totalTax + totalSum - discount);
        
      // }
      // calculating if tax type deducted
      if (formData?.taxType === "deducted") {
        afterDiscTaxableArray.forEach((item)=>{
            if (item.quantity > 0 && item.rate > 0) {
              taxableSum +=   (prevState.taxRate * item.afterDiscountAmount) / 100;
            }
        })
        totalTax = taxableSum;


        grandTotal = Number(totalSum - totalTax - discount);
          // console.log("taxDeducted>>",taxDeducted,"grandTotal>>",grandTotal)
      }
      if (formData?.taxType === "none") {
        grandTotal = Number(totalTax + totalSum - discount);
      }
    
      //  else {
        // console.log("totalTax + totalSum - discount",taxableSum,totalTax , totalSum , discount,"grandTotal",grandTotal)
      // }

      // calculating total payments been made

      // payments.forEach((payment) => {
      //   totalPayed = Number(totalPayed + Number(payment?.amountPaid));
      // });
      //
      totalPayed = totalPayed.toFixed(2);
      let finalTotalSum = Number(totalSum).toFixed(2);
      let finalDiscount = Number(discount).toFixed(2);
      let finalTotalTax = Number(taxableSum).toFixed(2);
      let finalGrandTotal = Number(grandTotal).toFixed(2);
      balanceDue = finalGrandTotal - totalPayed;
      // console.log(finalGrandTotal,"-",totalPayed,">>" , balanceDue)
      let statu;
      if (totalPayed >= balanceDue) {
        statu = "paid";
      } else {
        statu = "outstanding";
      }
      return {
        ...prevState,
        subTotal: finalTotalSum,
        discountAmount: Number(finalDiscount),
        totalTax: finalTotalTax,
        total: finalGrandTotal,
        balanceDue: Number(balanceDue),
        totalPaid: totalPayed,
        status: statu,
      };
    });
  }, [
    formData?.description,
    // payments,
    formData?.taxRate,
    formData?.taxType,
    formData?.discountValue,
    formData?.discountType,
  ]);
  // =============calculations END=============


  // const handleDefaultTemplate=(value)=>{
  //   // console.log(value) 

  //   if (!props.updating && formData?.selectedTemplate === "") {
      
  //     setFormData((prevState) => {
  //       return { ...prevState, selectedTemplate: value };
  //     });
  //   }
  // }
// =========== Copy to clipboard ===========
const copyToClip= async() => {
  try {

    let url = `${LARAVELurl}invoice_preview?id=${pdfId}`
    await navigator.clipboard.writeText(url);
    // alert('URL copied to clipboard!');
    successMessage("Shareable Link Copied To Clipboard!");
  } catch (error) {
    // console.error('Failed to copy URL:', error);
  }
}


const taxOnTotal = () => {
  setFormData((prevState) => ({
    ...prevState,
    description: prevState?.description.map((item) => ({
      ...item,
      tax: "true",
    })),
  }));
}

  const handleBlur = (e) => {
    e.preventDefault();
    let {name,value} = e.target;

    if (e.target.name === "currency") {
      setFormData((prevState) => {
        return { ...prevState, [e.target.name]: JSON.parse(e.target.value) };
      });
      return;
    }

    if(e.target.name === "taxType" && e.target.value === "none"){
      setFormData((prevState)=>{
            return {...prevState, taxRate: 0 }
      })
    }

    if(e.target.name === "discountType" && e.target.value === "none"){
      setFormData((prevState)=>{
            return {...prevState, discountValue: 0 }
      })
    }
    if (e.target.name === "invoicedate") {
      // console.log("e.target.value",e.target.value);
      let value = e.target.value;
      if (formData?.terms !== "none") {
        let date = new Date(value);
        // console.log("invoicedate>>date",date);

        let addedDays = date.addDays(Number(formData?.terms));
        // console.log("addedDays>>",addedDays);

        let final = date.toISOString(addedDays);
        // console.log("final>>",final);
         let conver = new Date(final);

        let invoicesDate = new Date(
          conver.getTime() - conver.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];

    
    // console.log(date.toISOString(addedDays),"<<<invoicesDate>>>",invoicesDate);
      setFormData((prevState)=>{
        return {...prevState, invoicedate: e.target.value, termValue: invoicesDate }
      })
      } else {
        setFormData((prevState)=>{
          return {...prevState, invoicedate: e.target.value, termValue: "" }
        })
      }
        
    }
    if (e.target.name === "terms") {
      let termsinput = e.target.value;
      // console.log("select-termsinput>>",termsinput);
      if (termsinput !=="none") {
        
        let date = new Date(formData?.invoicedate);
        let addedDays = date.addDays(Number(termsinput));
        // console.log("select-addedDays>",addedDays);
        let final = date.toISOString(addedDays);
        // console.log("select-final>>>>",final);
            let conver = new Date(final);
    
            let invoicesDate = new Date(
              conver.getTime() - conver.getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0];
    
              // console.log(invoicesDate);
        
        setFormData((prevState)=>{
          return {...prevState, term:termsinput , termValue: invoicesDate }
        });
      }else{
        setFormData((prevState)=>{
          return {...prevState, term:termsinput , termValue: "" }
        });
      }
    }
    // if(name === "senderPhone" || name === "senderFax" || name === "customerFax" || name === "customerPhone"){
    //   let phonenumb= teleValidation(e);
    //   // console.log("phonenumb>>>",phonenumb);
    //   setFormData((prevState) => {
    //     return { ...prevState, [name]: phonenumb };
    //   });
    //   // console.log(`phonenumbForm--${name}>>`,formData?.[name]);
    // }
    if (name !== "termValue" && name !=="invoicedate"
    // && name !== "senderPhone" && name !== "senderFax" && name !== "customerFax" && name !== "customerPhone"
    ) {
      // console.log("others")
      if (name === "customerName") {
        e.target.value.trim() ? setShowSuggest(true) : setShowSuggest(false);
      }
      if (name === "taxType" && value ==="onTotal") {
        taxOnTotal();
      }
      setFormData((prevState) => {
        return { ...prevState, [name]: e.target.value };
      });
    }

    // console.log(formData);
  };

  const handleOnChangeSelectCurrency= (currency)=>{
    setFormData((prevState) => {
      return { ...prevState, currency: currency };
    });
  }

  const handleSelectTemplate = (value) => {
    setFormData((prevState) => {
      return { ...prevState, selectedTemplate: `${value}` };
    });
  }

// handle phone/fax numbers fields 
  const handleTeleFields = (name, value) => {
    setFormData((prevState)=>{
      return{
        ...prevState,
        [name]: value
      }
    });
  }

  // remove image from front site,from DB and also empty the state which is being added in formData while submiting form
  const removeImage = async() => {
    setLogoImage(null);
    setTempLogo(null);

    if (props.updating) {
      setDelLoader(true);
      try {
        const {data,error} = await deleteSingleInvoiceLogo(urlId );
        if (data) {
          if (data.success) {
            setDelLoader(false);
            successMessage(data.message);
          }
          if (!data.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ??  "logoNot Deleted");
        }
        
      } catch (error) {
      }finally{setDelLoader(false);}
    }
  };

  const addFooterImages = (event) => {
    // console.log("image",event.target.files[0]);
    const file = event.target.files[0];
    // console.log(event,file);
    if (file) {
      let uid = uuid();
    // console.log("uuid", uid);
    setFooterImagesA((prevState) => {
      return [
        ...prevState,
        {
          id: uid,
          img_filename: file,
          img_description: "",
          img_additional_notes: "",
        },
      ];
    });
    // console.log("footerImagesA>>",footerImagesA)

    // // console.log("footerImagesA", footerImagesA);
    
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // console.log(reader.result)
      setAddedImage({
        id: uid,
        img_filename: reader.result,
        img_description: "",
        img_additional_notes: "",
      });
      setFooterImagesCol((prevState) => {
        return [
          ...prevState,
          {
            id: uid,
            img_filename: reader.result,
            img_description: "",
            img_additional_notes: "",
          },
        ];
      });
    }
    // console.log(footerImagesCol,footerImagesA);
    setFooterModalInput(uid);
    // console.log("addedImage",addedImage || '');
    setIsFooterOpen(true);

    event.target.value= "";
    }
    // console.log("afterIF>>",footerImagesA);

  };

  const removeSingleFooterImage = async(id) => {
    // console.log(id);
    try {
      setFooterImagesA((prevState) => prevState.filter((item) => item.id !== id));
      setFooterImagesCol((prevState) =>
        prevState.filter((item) => item.id !== id)
      );
      if (props.updating) {
        setDelLoader(true);
        const {data,error} = await deleteSingleInvoiceFooterImage(id);
        if (data) {
          if (data.success) {
            setDelLoader(false);
            successMessage(data.message);
          }
          if (!data.success) {
            // setDelLoader(false);
            errorMessage(data.message);
          }
        }
        if (error) {
          // setDelLoader(false);
          errorMessage(error?.error ?? error?.data?.message ?? error.data?.message ?? "logoNot Deleted from DB");
        }
      }
    } catch (error) {
    }
    finally{
      setDelLoader(false);
    }
    
  };


  const footerInnerModalClose = (id) => {
    let datas= footerImagesA.filter((item) => (item.id !== id))
     
    setFooterImagesA(datas);
    // (prevState) => prevState.filter((item) => item.id !== id)
    // console.log("datas",datas,"footerImagesA>>",footerImagesA)
    let apiArray =  footerImagesCol.filter((item) =>(item.id !== id))
       setFooterImagesCol(apiArray);
    setIsFooterOpen(false);
    setAddedImage(null);
  };


  const footerModalForm = (e, id) => {
    e.preventDefault();
    let desc = e.target.img_description.value;
    let additionalDetail = e.target.img_additional_notes.value;

    // console.log(desc, additionalDetail, id);
    setFooterImagesA((prevState) => {
      return prevState.map((item) => {
        // let updatedItem = {...item}
        if (item.id === id) {
          item.img_description = desc;
          item.img_additional_notes = additionalDetail;
        }
        return item;
      });
    });

    setFooterImagesCol((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          item.img_description = desc;
          item.img_additional_notes = additionalDetail;
        }
        return item;
      });
    });
    setIsFooterOpen(false);
    // console.log(footerImagesA, footerImagesCol);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    setTempLogo(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setLogoImage(reader.result);
    };
    event.target.value= "";
  };

  function handleAddDescription(descriptionItem) {
    setFormData(
      (prevState) => {
        return {
          ...prevState,
          description: [...prevState.description, descriptionItem],
        };
      }
      // ,()=>{
      //   console.log(formData);
      // }
    );
        // console.log(formData);

  }

  const calculateTotals = (newformData) => {
    let totalSum = 0;
    let totalTax = 0;
    let grandTotal = 0;
    let discount = 0;
    let totalAfterDiscount = 0;
    let afterDiscTaxableArray = [];
    let afterDiscNonTaxableArray = [];
    let totalPayed = 0;
    let balanceDue = 0;
    let taxableArray = 0;
    let nonTaxableArray = 0;
    let taxableSum = 0;
    let nonTaxableSum = 0;
    newformData?.description.forEach((value) => {
      totalSum = Number(totalSum + value.rate * value.quantity);
    });
    //==== filtering non-taxable items
    nonTaxableArray = newformData?.description.filter((desc) => {
      return desc.tax === "false";
    });
    //==== filtering taxable items
    taxableArray = newformData?.description.filter((desc) => {
      return desc.tax === "true";
    });



    if (newformData?.discountType === "percent") {

      discount = Number((newformData?.discountValue / 100) * totalSum);
      let discPer = newformData?.discountValue;

      afterDiscNonTaxableArray = nonTaxableArray.map((item) => {
        let afterDiscount = item.amount - (discPer * item.amount) / 100;
        return {
          ...item,
          afterDiscountAmount: afterDiscount
        }
      })
      afterDiscTaxableArray = taxableArray.map((item) => {
        let afterDiscount = item.amount - (discPer * item.amount) / 100;
        return {
          ...item,
          afterDiscountAmount: afterDiscount
        }
      })
    }

    if (newformData?.discountType === "flatAmount") {
      discount = Number(newformData?.discountValue);
      let totalItems = 0;
      let discountPerItem = 0;
      newformData.description.forEach((item) => {
        if (item.quantity > 0 && item.rate > 0) {
          totalItems += 1;
          discountPerItem = discount / totalItems;
        }
      })

      afterDiscNonTaxableArray = nonTaxableArray.map((item) => {
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount = item.amount - discountPerItem;

        } else {
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount
        }
      })

      afterDiscTaxableArray = taxableArray.map((item) => {
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount = item.amount - discountPerItem;

        } else {
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount
        }
      })

      totalAfterDiscount = (taxableSum + nonTaxableSum) - discount;

    }
    if (newformData?.discountType === "none") {

      afterDiscNonTaxableArray = nonTaxableArray.map((item) => {
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount = item.amount;

        } else {
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount
        }
      })
      afterDiscTaxableArray = taxableArray.map((item) => {
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount = item.amount;

        } else {
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount
        }
      })
    }


    if (formData?.taxType === "onTotal" || formData?.taxType === "perItem") {
      afterDiscTaxableArray.forEach((item) => {
        if (item.quantity > 0 && item.rate > 0) {
          taxableSum = taxableSum + (newformData.taxRate * item.afterDiscountAmount) / 100;
        }
      })
      totalTax = taxableSum;
      grandTotal = Number(totalTax + totalSum - discount);

    }


    // calculating if tax type deducted
    if (formData?.taxType === "deducted") {
      afterDiscTaxableArray.forEach((item) => {
        if (item.quantity > 0 && item.rate > 0) {
          taxableSum += (newformData.taxRate * item.afterDiscountAmount) / 100;
        }
      })
      totalTax = taxableSum;


      grandTotal = Number(totalSum - totalTax - discount);
    }
    if (formData?.taxType === "none") {
      grandTotal = Number(totalTax + totalSum - discount);
    }

    // calculating total payments been made

    // payments.forEach((payment) => {
    //   totalPayed = Number(totalPayed + Number(payment?.amountPaid));
    // });
    //
    totalPayed = totalPayed.toFixed(2);
    let finalTotalSum = Number(totalSum).toFixed(2);
    let finalDiscount = Number(discount).toFixed(2);
    let finalTotalTax = Number(taxableSum).toFixed(2);
    let finalGrandTotal = Number(grandTotal).toFixed(2);
    balanceDue = finalGrandTotal - totalPayed;
    let statu;
    if (totalPayed >= balanceDue) {
      statu = "paid";
    } else {
      statu = "outstanding";
    }

    return {
      subTotal: finalTotalSum,
      discountAmount: finalDiscount,
      totalTax: finalTotalTax,
      total: finalGrandTotal,
      balanceDue: JSON.stringify(balanceDue),
      totalPaid: totalPayed,
      status: statu,
    };
  };

  async function handleRemoveDescription(id) {

    const updatedFormData = {
      ...formData,
      description: formData.description.filter((description) => description.id !== id),
    };
    setFormData(updatedFormData);
    let deleted = formData?.description.filter(
      (description) => description.id === id
    )

    if (deleted[0].state === "old") {

      const updatedCalculation = calculateTotals(updatedFormData);
      try {
        setDelLoader(true);
        let body = {
          id,
          invoiceID: urlId,
          subTotal: Number(updatedCalculation?.subTotal),
          total: Number(updatedCalculation?.total),
          balanceDue: updatedCalculation?.balanceDue,
          status: updatedCalculation?.status
        }
        const { data, error } = await deleteSingleInvoiceItem(body);
        if (data) {
          if (data.success) {
            successMessage(data.message);
          }
          if (!data.success) {
            errorMessage(data?.message);
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error?.data?.message);
        }
      } catch (error) {
      }
      finally {
        setDelLoader(false);
      }

    }
  }

  function handleEditDescription(e, id) {
    setFormData((prevState) => {
      let newState = deepCopy(prevState);
      let oldDesc = newState.description.find((desc) => desc.id === id);

      if (oldDesc) {
        if (e.target.name === "itemDescription") {
          
          e.target.value.trim() ? oldDesc.showSuggestion = true : oldDesc.showSuggestion = false;
        }
        oldDesc[e.target.name] = e.target.value;
      }

      // if (e.target.name === "rate" || e.target.name === "quantity") {
        // newState.subTotal = asdsa
        // newState.total = asdsa
        let amount = (
          Number(oldDesc?.quantity) * Number(oldDesc?.rate)
        ).toFixed(2);
        oldDesc["amount"] = amount;
      // }

      return newState;
    });
    // console.log(formData.description);
  }

  const handleSubmit = async() => {

    if (props.updating) {
      try{
        setPostLoader(true);
        setIsConfirmation(false);
        let updateInvoiceForm = new FormData();
        let body = {
          ...formData,
          description: formData?.description.map((item) => {
            return item.state === "new"
              ? {
                  itemDescription: item.itemDescription,
                  additionalDetail: item.additionalDetail,
                  rate: item.rate.length <1 ? "0.00":item.rate,
                  quantity: item.quantity.length <1 ? "0":item.quantity,
                  amount: item.amount.length <1 ? "0.00":item.amount,
                  tax: item.tax,
                }
              : {
                  id: item.id,
                  itemDescription: item.itemDescription,
                  additionalDetail: item.additionalDetail,
                  rate: item.rate.length <1 ? "0.00":item.rate,
                  quantity: item.quantity.length <1 ? "0":item.quantity,
                  amount: item.amount.length <1 ? "0.00":item.amount,
                  tax: item.tax,
                };
          }),
          currency: JSON.stringify(formData?.currency),
          footerImagesFieldArray:  footerImagesA.map(footerImageDetail=>({
          img_description: footerImageDetail.img_description,
          img_additional_notes: footerImageDetail.img_additional_notes,
          })), 
          };
        // console.log("UPDATE",body);
          if (tempLogo) {
            updateInvoiceForm.append('logo',tempLogo)
          }
        footerImagesA.forEach(footerImage => {
          updateInvoiceForm.append('footerImages',footerImage.img_filename)
        }) 
        updateInvoiceForm.append('data',JSON.stringify(body))
        
        const resp = await updateInvoice({id: urlId, updateInvoiceForm})
          // console.log("UpdateRESP: ",resp)
        if (resp.data) {

          if (resp.data.success) {
            successMessage(resp.data.message);
            // console.log("resp.data.success",resp.data)
            // navigate('/quotation'); 
            singleDataSet(resp?.data)
          }
          if (!resp.data.success) {
            errorMessage(resp.data.message);
            // console.log("!resp.data.success",resp.data)
          }

        }
        if (resp.error) {
          // console.log(resp.error.data.message)
          errorMessage(resp?.error?.error ?? resp.error.data.error.message);
        }

      }catch (error){
          // console.log("catchError")
      }finally{
      setPostLoader(false);
      }
      
    } else {
      
      try{
        setPostLoader(true);
        let saveInvoiceForm = new FormData();


        let body = {
          ...formData,
          description: formData?.description.map((item) => ({
            itemDescription: item.itemDescription,
            additionalDetail: item.additionalDetail,
            rate: item.rate.length <1 ? "0.00":item.rate,
            quantity: item.quantity.length <1 ? "0":item.quantity,
            amount: item.amount.length <1 ? "0.00":item.amount,
            tax: item.tax,
          })),
          currency: JSON.stringify(formData?.currency),
          
          footerImagesFieldArray:  footerImagesA.map(footerImageDetail=>({
            img_description: footerImageDetail.img_description,
              img_additional_notes: footerImageDetail.img_additional_notes,
        })), 
        };

        
        // console.log("bodysent2backend>",body);
        if (tempLogo) {
          saveInvoiceForm.append('logo',tempLogo);
          // console.log("Checklist-1. templogo null");
        }
        footerImagesA.forEach(footerImage => {
          saveInvoiceForm.append('footerImages',footerImage.img_filename)
        }) 
        
        saveInvoiceForm.append('data',JSON.stringify(body))


        const resp = await saveInvoices(saveInvoiceForm)
        // console.log("RESP: ",resp)
        if (resp.data) {
          if (resp.data.success) {
            successMessage(resp.data.message);
            setTimeout(() => {
              increaseInvoicePlan("quotation_invoice")
            }, 10);
            navigate('/quotation');
          }
          if (!resp.data.success) {
              errorMessage(resp.data.message);
              // console.log(resp.data.message);
          }
          
        }

        if (resp.error) {
          errorMessage(resp.error.data.mesage);
          // console.log(resp.error.data);
        }
      
      }
        catch (error) {
          // console.log("error>>",error)
          // errorMessage(error.message);
      }
      finally{
        setPostLoader(false);
      }
      // =============================
      // return
      // const { data, error } = await createSalarySlip(form);
      // if (data) {
      //   console.log("salary added->", data);
      //   // navigate("/salary-slips");
      // }
      // if (error) {
      //   console.log("salary not added Error->", error);
      // }
      
    }
  };

  const confirmSave = (type) => {

    if (type === true) {
      setPostLoader(true);
      handleSubmit();
      setIsConfirmation(false);
    }
    if (type === false) {
        setIsConfirmation(false);
    }
  }
  
  const saveModal =(e) => {
    e.preventDefault();
    setIsConfirmation(true);
  }
  
  // =============Name Suggestion List START============
  const [suggestList,setSuggestList]  =useState([]);

  const autoSuggstion = async (e) => {
    e.preventDefault();
    try {
      let search = e.target.value;
      if (search !== "" || search !== " ") {
        const {data,error} = await clientsNameSearch(search);
        if (data) {
          if (data?.success) {
              let list = deepCopy(data.data);
              setSuggestList(list);
          }
          if (!data?.success) {
              errorMessage(data.message);
          }
        }
        if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
        }
      }
      
    } catch (error) {
      
    }
  };

  const fillFields = async (id) => {
    try {
      let search = id;
        const {data,error} =await getClientDataById(search);
        if (data) {
          if (data?.success) {
            let list = deepCopy(data.data[0]);
            setFormData((prevState) => ({
              ...prevState,
              customerName: "",
            }));
    
            let entity_name = list.entity_name || "";
            let entity_city = list.entity_city +", "+ list.entity_state || "";
            let entity_email = list.entity_email || "";
            let entity_fax = list.entity_fax || "";
            let entity_gst = list.entity_gst || "";
            let entity_ntn = list.entity_ntn || "";
            let entity_phone_official = list.entity_phone_official || "";
            let entity_address = list.entity_address || "";
            let entity_website = list.entity_website || "";
            let entity_zipCode = list.entity_zip_code || "";
            setFormData((prevState) => ({
              ...prevState,
              customerName: entity_name,
              customerCityState: entity_city,
              customerEmail: entity_email,
              customerFax: entity_fax,
              customerGst: entity_gst,
              customerNtn: entity_ntn,
              customerPhone: entity_phone_official,
              customerStreet: entity_address,
              customerZipCode: entity_zipCode,
              customerWebsite: entity_website,
            }));
            setShowSuggest(false);
          }
          if (!data?.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
        }
      
    } catch (error) {
      
    }
    finally{
      setShowSuggest(false);
    }

  };
   // =============Name Suggestion List END===============

  // ==============item Suggestion List START=============
   const [allDescitems,setAllDescItems] = useState([]);

   const autoSuggstionitems = async (e) => {
    try {
      e.preventDefault();
    let search = e.target.value;
    const {data,error}  = await itemByNameSearch(search);
    if (data) {
      if (data?.success) {
        let list = deepCopy(data.data);
        setAllDescItems(list);
      }
      if (!data.success) {
        errorMessage(data?.message);
      }
    }
    if (error) {
      errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
    }
    } catch (error) {
      
    }
   
  };
  //  autoSuggstionitems();
  const handleHideSuggestion = (rowId) => {
    if (formData?.description.find((item)=>item.id===rowId && item.showSuggestion===true)) {
      setFormData((prevState) => {
        let newState = deepCopy(prevState);
        let oldDesc = newState.description.find((desc) => desc.id === rowId);
        if (!!oldDesc) {
          oldDesc.showSuggestion = false;
        }
        return newState;
      });
      
    }
  }
  const fillItemFields = async (rowId, id) => {
    try {
      const {data,error} = await getItemDataById(id);
      if (data) {
          if (data.success) {
           let item = deepCopy(data.data[0]);
            setFormData((prevState) => {
              let newState = deepCopy(prevState);
              let oldDesc = newState.description.find((desc) => desc.id === rowId);
              if (oldDesc) {
                oldDesc.itemDescription = item.item_name;
                oldDesc.additionalDetail = item.description;
                oldDesc.rate = JSON.stringify(item.item_rate);
                oldDesc.tax = formData?.taxType === "onTotal" ? "true": item.item_texable;
                oldDesc.amount = JSON.stringify(item.item_rate*oldDesc.quantity);
              }
              return newState;
            });
            setShowSuggestItem(false);
          }
          if (!data.success) {
            errorMessage(data?.message)
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding."); 
      }

    } catch (error) {
    } finally {
      handleHideSuggestion(rowId);
    }
  };

   // ==============item Suggestion List END=============

    // ============= SEND EMAIL START ===================
    const emailChange = (e)=>{
      e.preventDefault();
      const {name,value} = e.target;
      if (name === "cc_status" & value === "false") {
        setEmailBody((prevState)=>{
          return({
            ...prevState, cc_emails:""
          })
        })
      }
      setEmailBody((prevState)=>{
        return({
          ...prevState, [name]:value
        })
      })
        // console.log(emailBody);

    }

    const handleEmail= async(e)=>{
      e.preventDefault();
      try {
        setEmailbuttonLoader(true);
      let id=urlId ;
      
        let body = {
          id,
          email_to: emailBody?.email_to,
          email_body: emailBody?.email_body,
          attachment: emailBody?.attachment,
          cc_status: emailBody?.cc_status,
          cc_emails: emailBody?.cc_emails,
          type: "quotation"
        }
      // https://stagingfront.tradingforms.com/api/invoice_email?id=59
      // console.log(body);
      // https://stagingfront.tradingforms.com
      let token =  localStorage.getItem("tradingformtoken");
      const {data,error} =  await axios(`${LARAVELurl}invoice_email`, {
        method: "POST",
        data: body,
        headers: {
          "Content-Type": "application/json;",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        if (data) {
          // console.log("emaildata>>",data)
          if (data.success) {
          // console.log("emaildata>>",data)
          // console.log("success>>emaildata>>",data)
            setEmailModal(false);
            setEmailbuttonLoader(false);
            successMessage(data?.message);
            increaseInvoicePlan("email_sent");
            setEmailBody((prevState)=>{
              return{
                ...prevState,
                attachment:"true",
                cc_status: "false",
                cc_emails:""
              }
            })
          }
          if (!data.success) {
            // console.log("error>>emaildata>>",data)
            setEmailbuttonLoader(false);
            errorMessage(data.message);
          }
        }
        if (error) {
          // console.log("error in post>>", error);
          errorMessage(error?.message);
          setEmailbuttonLoader(false);
        }
      } catch (error) {
        // console.log("catchError",error)
      }
      finally{
        setEmailbuttonLoader(false);
      }
      
        // setEmailbuttonLoader(false);
    }
    // ============= SEND EMAIL END ===================


  return (
    <>
      { loader &&
        <CustomDeleteloader message="Loading . . ."/>
    }
    {delLoader &&
      <CustomDeleteloader message="Deleting . . ."/>
    }
    {postLoader &&
      <CustomDeleteloader message={`${props.updating ?  'Updating ': 'Adding '}. . .`}/>
    }

    <div className="spacer"></div>

    <div className="container">
        <div className="col-11 invoice-detail">
          <div className="col-lg-11 col-xl-10 col-11">
            
            <div className="breadcrumb-container">
              
              <nav className="breadcrumbs">
                <Link to="/" className="breadcrumbs__item" title="Go to Dashboard">Dashboard</Link>
                <Link to="/quotation" className="breadcrumbs__item" title="Go to Quotations">Quotations</Link> 
                {/* <Link to="" className="breadcrumbs__item">Cart</Link> */}
                <span  className="breadcrumbs__item is-active"> {props.updating ? "Edit":"Create"}</span> 
              </nav>  <div className="spacer"></div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="invoice-actions">
              <div className="invoice-navBtn">
                {props.updating &&
                 <span> 
                  <button
                    type="button"
                    onClick={() => setShowForm(1)}
                    className={`btn btn-secondary btn-sm me-1 ${showForm===1? 'active cursor-disable':''}` }
                    title='Click To Preview'
                  >
                    <i className="far fa-eye"></i><span>Preview</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShowForm(0);
                      if (!templateSelected) {
                        !templateSelected && setTemplateSelect(true);
                        templateSelected = true;
                      }
                    }}
                    className={`btn btn-secondary btn-sm ${showForm===0? 'active cursor-disable':''}` }
                    title='Click To Edit'
                  >
                    <i className="fas fa-edit"></i><span>Edit</span>
                  </button>
                  </span>
                }
                { showForm === 0 && (
                  <button
                    type="button"
                    onClick={handleOpenSelectTemplate}
                    className="btn btn-primary btn-sm me-0"
                    title='Click to select Template'
                  >
                    <i className="fa fa-file-invoice"></i><span>Select Template</span>
                  </button> )
                }
              </div>
                { props.updating &&
                <div className="invoice-actions-export">
                  {/* <button
                    type="button"
                    className="btn btn-sm"
                    onClick={handleModalOpen}
                  >
                    <i className="fas fa-money-check-alt"></i>
                   <span> Record Payment</span>
                  </button> */}
                  <div className="invoice-actions-update">
                    <Link to={`${LARAVELurl}invoice_preview?id=${pdfId}`} className="btn btn-sm button-pdf" target="_blank" title='Click To view PDF'>
                    <i className="fa fa-file-pdf"></i><span>PDF</span>
                    </Link>
                    <button type="button" className="btn btn-sm button-copy-url" onClick={copyToClip} title="Click to copy shareable link.">
                      <i className="fa fa-copy"></i><span>Copy URL</span>
                    </button>

                  </div>

                  <button type="button" className="btn button-email-invoice btn-sm" onClick={handleOpenEmail} title='Click to send an email'>
                  <i className="fa fa-envelope"></i><span>Email Quotation </span> 
                  </button>
                </div>
              }
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="new-edit-invoice-container">
                {showForm === 1 && (
                  <div data-aos="fade-right">
                    <Preview formData={formData} logoImage={logoImage} footerImagesCol={footerImagesCol} type="quotation"/>
                  </div>
                )}
                <form></form>
                {showForm === 0 && (
                  <div data-aos="fade-left">
                    <Edit
                      handleBlur={handleBlur}
                      formData={formData}
                      handleAddDescription={handleAddDescription}
                      handleRemoveDescription={handleRemoveDescription}
                      handleChange={handleEditDescription}
                      handleSubmit={handleSubmit}
                      handleFileInputChange={handleFileInputChange}
                      logoImage={logoImage}
                      removeImage={removeImage}
                      addFooterImages={addFooterImages}
                      footerImagesA={footerImagesCol}
                      removeSingleFooterImage={removeSingleFooterImage}
                      fillFields={fillFields}
                      autoSuggstion={autoSuggstion}
                      suggestList= {suggestList}
                      autoSuggstionitems={autoSuggstionitems}
                      fillItemFields={fillItemFields}
                      allDescitems={allDescitems}
                      showSuggest={showSuggest}
                      setShowSuggest={setShowSuggest}
                      showSuggestItem={showSuggestItem}
                      setShowSuggestItem={setShowSuggestItem}
                      // updateImageDetail={updateImageDetail}
                      update={props.updating}
                      type="quotation"
                      saveModal={saveModal}
                      prefix="Quotation"
                      handleHideSuggestion={handleHideSuggestion}
                      handleTeleFields={handleTeleFields}
                    />

                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 pt-5">
              <TaxationDiscount handleBlur={handleBlur} formData={formData} showForm= {showForm} handleOnChangeSelectCurrency={handleOnChangeSelectCurrency}/>
            </div>
          </div>
        </div>

        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>

      <ReactPortal>
        {templateSelect && (
          <Modal
            handleClose={handleCloseSelectTemplate}
            header={`Welcome to the <span className='pos-rel'>TradingForms</span>. Join happy small business.`}
            type="template"
            contentType="notscrollable"
          >
            <SelectInvoiceTemplate
              formData={formData}
              handleBlur={handleSelectTemplate}
              // handleDefaultTemplate= {handleDefaultTemplate}
              handleClose={handleCloseSelectTemplate}
              filteredTemps= {allTemplates}
            />
          </Modal>
        )}
      </ReactPortal>
        
      <ReactPortal>
        {isFooterOpen && (
          <Modal
            handleClose={footerInnerModalClose}
            header="Photo Details"
            type="form"
          >
            <PhotoDetails
              handleClose={footerInnerModalClose}
              addedImage={addedImage}
              handleSubmit={footerModalForm}
              footerModalInputId={footerModalInput}
            />
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>

      <ReactPortal>
          { emailModal &&
                <Modal handleClose={handleCloseEmail} type="form" contentType="email-form" header="Enter details to send mail.">
                    <SendEmail emailBody={emailBody} handleSubmit={handleEmail} emailChange={emailChange} loader={emailbuttonLoader}/>
                </Modal>
          }
      </ReactPortal>

      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon="save text-success"
            header="Photo Details"
            type="confirm"
            recordName={`Are you sure you want to ${props.updating? "Update": "Save"}`}
          >
            <button className="btn btn-save m-1 col-xs-12 col-sm-6 col-md-3" onClick={()=>confirmSave(true)}> Yes</button>
            <button className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3" onClick={()=>confirmSave(false)}>No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
    </>

  )
}

export default CreateQuotation