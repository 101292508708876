import React from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Filler,);
// labels:["May 12","May 13","May 14","May 15","May 16","May 17","May 18"]
function CountGraph({ dataCount }) {
  // console.log("CountGraph::->dataCount", dataCount)
  const type1Count = dataCount?.count?.type_1?.count || 0;
  const type2Count = dataCount?.count?.type_2?.count || 0;
  const maxCount = type1Count + type2Count;
  const dataLength = 10;

  const generateDataAndLabels = (length, max) => {
    const data = [];
    const labels = [];
    for (let i = 0; i < length; i++) {
      data.push(Math.floor(Math.random() * (max + 1)));
      labels.push((i + 1).toString());
    }
    return { data, labels };
  };

  const { data: chartData, labels } = generateDataAndLabels(dataLength, maxCount);


  const data = {
    labels: labels,
    datasets: [
      {
        data: chartData,
        // backgroundColor: dataCount.backgroundcolor,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart render
            return null;
          }

          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, dataCount.backgroundcolor);
          gradient.addColorStop(1, '#fff');

          return gradient;
        },
        borderColor: dataCount.color,
        fill: true,
        borderWidth: 1,
        pointBoderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointeWidth: 0,
        pointBorderWidth: 0,
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: false,
      //  {
      // display: false
      // }
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        grid: { display: false },
      },
      y: {
        display: false,
        grid: { display: false },
      },
    },
    scaleShowGridLines: true,
    tooltipEvents: ["mousemove", "touchstart", "touchmove"],
    tooltipFillColor: "rgba(0,0,0,0.8)",
    scaleGridLineColor: "rgba(0,0,0,.05)",
    scaleShowHorizontalLines: true,
  };

  return (
    <>
      {/* <h2>Count Graph</h2> */}
      <div className="col-sm-6 col-md-6 col-lg-3 col-12 py-2" data-aos={dataCount.animation}>
        {dataCount && (
          <div className="count-card">
            {/* <div className="card-icon-container">
                <span className="card-icon" style={{"backgroundColor": `${dataCount.backgroundcolor}`}}>
                    <i className={`fa ${dataCount.icon}`} style={{'color': `${dataCount.color}`}}></i>
                </span>
            </div> */}
            <h5 className="title">
              <span>
                {dataCount.title}
              </span>
              <br />
            </h5>
            <div className="counts-container px-2">
              <div className="single-type-count-container">
                <h6 className="count-title">
                  {dataCount?.count?.type_1?.count}
                </h6>
                <span className="count-tag" style={{ 'backgroundColor': dataCount.backgroundcolor, 'color': dataCount.textColor }}>{dataCount?.count?.type_1?.title}</span>
              </div>
              <div className="single-type-count-container">
                <h6 className="count-title">
                  {dataCount?.count?.type_2?.count}
                </h6>
                <span className="count-tag" style={{ 'backgroundColor': dataCount.backgroundcolor, 'color': dataCount.textColor }}>{dataCount?.count?.type_2?.title}</span>
              </div>
            </div> 
            <div style={{ 'height': '70px', 'width': '100%' }}>
              <Line data={data} options={options} />
            </div>
            {/* <h5 className="title">{dataCount.title}</h5>
            <h4 className="title">
              {dataCount.count}
            </h4> */}
            {/* <p>Total Count</p> */}
          </div>
        )}
      </div>
    </>
  );
}

export default CountGraph;
