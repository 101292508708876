import React, { useEffect, useState } from "react";
// import { ThreeCircles } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "./components/toastMessages/ToastifyMsg";
import AllItems from "./Items/Allitems";
import Outstandings from "./Items/Outstandings";
import Paid from "./Items/Paid";
import {
  useDeleteItemMutation,
  useItemsSettingsAllQuery,
} from "./store/apis/baseApi";
// import CustomLoader from "./components/CustomLoader";
import ReactPortal from "./components/reactPortal/ReactPortal";
import Modal from "./components/modal/Modal";
import CustomDeleteloader from "./components/CustomDeleteloader";
import { useSelector } from "react-redux";

function Items() {
  // const [renderChild, useRenderChild] =useState();
  const [showInvoice, setShowInvoice] = useState(1);
  const [allItems, setAllItems] = useState([]);
  const { data, error, isLoading } = useItemsSettingsAllQuery();
  const [tableLoader,setTableLoader] = useState(true);
  const [deleteItem] = useDeleteItemMutation();
  const [loader,setLoader]= useState(false);
  const [deleteModalItem, setDeleteModalItem] = useState(false);
  const [isConfirmation,setIsConfirmation] = useState(false);
  const [deleteId,setDeleteId] =useState();

  const navigate = useNavigate();
  const productsPlanDetail = useSelector(state=>state.planDetails);

  useEffect(() => {
    try {
      if (data) {
        // console.log(data);
        if (data.success) {
          setAllItems(data.data);
        }
        if (!data.success) {
          // errorMessage(data.message);
          setAllItems([])
        }
      }
      if (error) {
        errorMessage(error?.error ?? error?.data?.error?.message);
      }
    } catch (error) {
      
    }
  }, [data, error]);

useEffect(() => {
  setTimeout(() => {
    setTableLoader(isLoading);
  }, 500);
}, [isLoading])

  const confirmation = (id, name)=>{
    // deleteInvoice(id);
    setDeleteModalItem(name);
    setDeleteId(id);
    setIsConfirmation(true);

  }



  const deleteSingleItem = async (value) => {

    if (value === true) {
      setIsConfirmation(false);
      setLoader(true);
      try {
        const { data, error } = await deleteItem(deleteId);
        if (data) {
          // console.log("Data>>>", data);
          if (data.success) {
            setLoader(false);
            successMessage(data.message);
          }
          if (!data.success) {
              errorMessage(data.message);
              setLoader(false);
          }
          
          
          // navigate('/items');
        }
    
        if (error) {
          // console.log("ERROR:", error.data.message);
          errorMessage(error?.error ?? error?.data?.error?.message);
          setLoader(false);
        }
      } catch (error) {
        
      }
    }else{
      setIsConfirmation(false);
    }
  };
//  !data && !error ? (
//     <CustomLoader/>

//   ) :
const handleCreateNavigate=() => {
  if (productsPlanDetail?.planDetails?.plan_usage_details?.products?.usage_count<productsPlanDetail?.planDetails?.plan_usage_details?.products?.total) {
    navigate('/items/create');
  }else {
    errorMessage("Limit Reached. Upgrade your plan!");
    navigate('/subscription-plans');
  } 
}
  return (
    <>
   {loader ===true &&
      <CustomDeleteloader message="Deleting . . ."/>
    }
    <div className="spacer"></div>
    {/* <div className="spacer"></div> */}
      <div className="invoice-container">
        <div className="container invoice-list" data-aos="zoom-in">
          <div className="invoice-actions">
            <div className="invoice-navBtn">
              <button
                onClick={() => setShowInvoice(1)}
                className={`btn btn-secondary btn-sm ${showInvoice===1? 'active cursor-disable':''}` }
                type="button"
              >
                All Products
              </button>
              <button
                onClick={() => setShowInvoice(2)}
                className={`btn btn-secondary btn-sm ${showInvoice===2? 'active cursor-disable':''}` }
                type="button"
              >
                Digital
              </button>
              <button
                onClick={() => setShowInvoice(3)}
                className={`btn btn-secondary btn-sm ${showInvoice===3? 'active cursor-disable':''}` }
                type="button"
              >
                Physical
              </button>
            </div>
            <div className="searchCreate-invoice">
              {/* <div className="invoice-searchContainer col-xs-12 col-sm-9 col-md-7">
                        <input type="text" name=""  placeholder="Search By Client Name"></input>
                    </div> */}
              <div className="createInvoice col-xs-12 col-sm-3 col-md-5">
                <button className="btn btn-primary d-flex align-items-center justify-content-center gap-2" onClick={handleCreateNavigate}>
                  <i className="fa fa-plus"></i> <span> Add Product </span>
                </button>
              </div>
            </div>
          </div>

          <div className="container px-0 fadeInOpacity overflow-hidden">
            {showInvoice === 1 && (
              <AllItems
                allItem={allItems}
                deleteSingleItem={confirmation}
                isLoading={tableLoader}
              />
            )}
            {showInvoice === 2 && (
              <Outstandings
                allItem={allItems}
                deleteSingleItem={confirmation}
                isLoading={tableLoader}
              />
            )}{" "}
            {/* shows Digital items*/}
            {showInvoice === 3 && (
              <Paid allItem={allItems} deleteSingleItem={confirmation} isLoading={tableLoader}/>
            )}
            {/* shows Physical items*/}
          </div>
          {/* <div className="invoice-list-footer">
            <span>All your invoices are auto saved here</span>
          </div> */}
        </div>
      </div>

      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon={"trash-alt text-danger"}
            header=""
            type="confirm"
            recordName={`Are you sure you want to delete this product ${deleteModalItem}`}
          >
            <button type="button" className="btn btn-danger m-1 col-md-3 col-12" onClick={()=>deleteSingleItem(true)} title="Yes, delete Product"> Yes</button>
            <button type="button" className="btn btn-secondary m-1 col-md-3 col-12" onClick={()=>deleteSingleItem(false)} title="Do not delete Product">No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
    </>
  );
}

export default Items;
