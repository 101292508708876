import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { suffix } from '../../utils/utils';
import moment from 'moment/moment';
import BusinessContractDoc from './contractsDocType/BusinessContractDoc';
import WirelessContractDoc from './contractsDocType/WirelessContractDoc';
import VoipContractDoc from './contractsDocType/VoipContractDoc';

const ContractDoc = ({ contractContent, companyPro, handleChange, clientNameRef, clientByRef, clientTitleRef, url, handleOpenSignTest, handleShowSpecimen, submit }) => {
    const [content, setContent] = useState({});
    const date = new Date();

    useEffect(() => {
        if (contractContent) {
            setContent(contractContent);
        }
    }, [contractContent])

    
    return (

        <div className="main-contract" style={{ 'margin': '30px 50px', 'textAlign': 'left' }}>
            
            {(content?.contractType === "business_internet" || content?.contractType ==="dedicated_internet" )  &&
                <BusinessContractDoc content={content} handleChange={handleChange} companyPro={companyPro} url={url}
                    clientNameRef={clientNameRef}
                    clientByRef={clientByRef} 
                    clientTitleRef={clientTitleRef}
                    handleOpenSignTest={handleOpenSignTest}
                    handleShowSpecimen={handleShowSpecimen}
                    submit={submit}
                    date={date}
                />
            }

            {content?.contractType ==="wireless_internet" &&
                <WirelessContractDoc 
                content={content} handleChange={handleChange} companyPro={companyPro} url={url}
                    clientNameRef={clientNameRef}
                    clientByRef={clientByRef} 
                    clientTitleRef={clientTitleRef}
                    handleOpenSignTest={handleOpenSignTest}
                    handleShowSpecimen={handleShowSpecimen}
                    submit={submit}
                    date={date}
                />
            }
            {content?.contractType ==="voip_voice" && 
                <VoipContractDoc
                content={content} handleChange={handleChange} companyPro={companyPro} url={url}
                clientNameRef={clientNameRef}
                clientByRef={clientByRef} 
                clientTitleRef={clientTitleRef}
                handleOpenSignTest={handleOpenSignTest}
                handleShowSpecimen={handleShowSpecimen}
                submit={submit}
                date={date}
                />
            }

        </div>

    )
}

export default ContractDoc
