import React, { useEffect, useState } from "react";
import { useGetAllEarningsQuery } from "../../store/apis/baseApi";
import CustombuttonSpinner from "../../components/CustombuttonSpinner";
import { deepCopy } from "../../utils/utils";

function AllEarning({ deleteEarning }) {
  const [allEarnings, setAllEarnings] = useState();
  const { data, error} = useGetAllEarningsQuery();

  useEffect(() => {
    if (data) {
      // console.log(data)
      if (data.success) {
        let allEarningsdata = deepCopy(data.data);
          // console.log('allEarningsdata',allEarningsdata)
        setAllEarnings(allEarningsdata);
        
      }
      if (!data.success) {
        console.log(data.message);
      }
    }
    if (error) {
      console.log("ERROR:", error);
    }
  }, [data, error]);
  return (
    <>
       {!allEarnings ? (<CustombuttonSpinner/>):
          (<div className="container">
        {allEarnings?.length >0 &&(
          
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Sr.#</th>
              <th className="text-start">Earnings Title</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allEarnings?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td className="text-start">{item.addition_title}</td>
                  <td>
                    <span
                      className="text-danger cursor-pointer"
                      onClick={() => deleteEarning(item.id,item.addition_title)}
                      title={`Delete ${item.addition_title}`}
                    >
                      <i className="fa fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        )
        }
      </div>)

        }
      
    </>
  );
}

export default AllEarning;
