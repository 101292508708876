import { createSlice } from "@reduxjs/toolkit";
const initialState = {
}

export  const subscribedPlanDetailsSlice = createSlice({
    name:"planDetails",
    initialState,
    reducers:{
        setPlanDetails:(state,{payload})=>{
            state.planDetails= payload;
        },
    }
})

export const {setPlanDetails} =subscribedPlanDetailsSlice.actions;
export default subscribedPlanDetailsSlice.reducer;