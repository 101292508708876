import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomDeleteloader from "./components/CustomDeleteloader";
// import CustomLoader from "./components/CustomLoader";
import Modal from "./components/modal/Modal";
import ReactPortal from "./components/reactPortal/ReactPortal";
import {
  errorMessage,
  successMessage,
} from "./components/toastMessages/ToastifyMsg";
import Allsalaries from "./Salary/Allsalaries";
import {
  useCreateSalaryGroupMutation,
  useDeleteSingleGroupMutation,
  useDeleteSingleSalaryGroupLogoMutation,
  useDeleteSingleSalaryMutation,
  useDuplicateSalaryGroupMutation,
  useDuplicateSalarySlipMutation,
  useGetAllGroupSalariesQuery,
  useGetAllSalaryQuery,
  // useGetAllSalaryQuery,
  useGetCompanySettingQuery,
  useLazyGetSingleSalaryGroupQuery,
  useUpdateSingleSalaryGroupMutation,
} from "./store/apis/baseApi";
import SendEmail from "./components/SendEmail";
import axios from "axios";
import AllGroupPayslip from "./Salary/AllGroupPayslip";
import { deepCopy } from "./utils/utils";
import GroupDetailsForm from "./components/GroupDetailsForm";
import { IMG_URL, LARAVELAPI } from "./common/common";
import { useDispatch, useSelector } from "react-redux";
import { setPlanDetails } from "./store/slices/subscribedPlanDetails";
import { useGetUserPlanServiceMutation } from "./store/apis/laravelApi";

var companyData = {
  title: "",
  from_name: "",
  from_email: "",
  from_address1: "",
  from_address2: "",
  from_zip_code: "",
  from_phone: "",
  from_fax: "",
  from_gst: "",
  from_ntn: "",
  from_website: "",
  group_number: "GROUP-",
};
var companyslogo = null;

function SalarySlips() {
  const auth = useSelector((state) => state.auth);
  const LARAVELurl = LARAVELAPI;
  const imgUrl = IMG_URL;
  const [showInvoice, setShowInvoice] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const salaryslipsPlanDetail = useSelector(state=>state.planDetails);
  
  const location = useLocation();
  const {state} = location;
  useEffect(() => {
  if (state) {
    setShowInvoice(+state.showTab)
  }
  }, [state])

  const [loader, setLoader] = useState(false);
  const [deleteModalSalary, setDeleteModalSalary] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const { data:allPayslipData, error:allPayslipError,isLoading } = useGetAllSalaryQuery();
  const [tableLoader,setTableLoader] = useState(true);
  const [groupTableLoader,setGroupsTableLoader] = useState(true);
  const [allPayslip,setAllPayslip] = useState();
  const [deleteId, setDeleteId] = useState();
  const [emailModal, setEmailModal] = useState();
  const [deleteSingleSalary] = useDeleteSingleSalaryMutation();
  const [emailTitle, setEmailTitle] = useState("");
  const [emailbuttonLoader, setEmailbuttonLoader] = useState(false);
  // sendEmail Body
  const [duplicateSalarySlip] = useDuplicateSalarySlipMutation();
  const [isConfirmDuplicate, setIsConfirmDuplicate] = useState(false);
  const [duplicateId, SetDuplicateId] = useState("");
  const [duplicateName, SetDuplicateName] = useState("");
  const [duplicateLoader, setDuplicateLoader] = useState(false);

  const [groupModal, setGroupModal] = useState(false);
  const [groupbuttonLoader, setGroupbuttonLoader] = useState(false);
  const [groupDetails, setGroupDetails] = useState({
    title: "",
    from_name: "",
    from_email: "",
    from_address1: "",
    from_address2: "",
    from_zip_code: "",
    from_phone: "",
    from_fax: "",
    from_gst: "",
    from_ntn: "",
    from_website: "",
    group_number: "GROUP-",
  });
  const [logoImage, setLogoImage] = useState(null);
  const [salaryLogo, setSalaryLogo] = useState();

  const { data, error } = useGetCompanySettingQuery();
  const [createSalaryGroup] = useCreateSalaryGroupMutation();
  const { data: getAllGroupData, error: getAllGroupError, isLoading:isLoadingGroups } =
    useGetAllGroupSalariesQuery();
  const [updateGroup, setUpdateGroup] = useState(false);
  const [getSingleSalaryGroup] = useLazyGetSingleSalaryGroupQuery();
  const [updateSingleSalaryGroup] = useUpdateSingleSalaryGroupMutation();
  const [deleteSingleGroup] = useDeleteSingleGroupMutation();
  const [singleGroupId, setSingleGroupId] = useState();
  const [duplicateSalaryGroup] = useDuplicateSalaryGroupMutation();
  const [deleteSingleSalaryGroupLogo] =  useDeleteSingleSalaryGroupLogoMutation();
  const [getUserPlanService] = useGetUserPlanServiceMutation();

  const [allgroups, setAllGroups] = useState([]);

  const [dType, setDType] = useState();

  const [emailBody, setEmailBody] = useState({
    id: "",
    email_to: "",
    email_body: "Kindly find the attached document.",
    attachment: "true",
    cc_status : "false",
    cc_emails: ""
  });

  
  useEffect(() => {
    setTimeout(() => {
     setTableLoader(isLoading);
    }, 1000);
  },[isLoading]);

  useEffect(() => {
    setTimeout(() => {
      setGroupsTableLoader(isLoadingGroups);
    }, 1000);
  },[isLoadingGroups])

  useEffect(() => {
    try {
      if (allPayslipData) {
        if (allPayslipData.success) {
          let allSalaries  = deepCopy(allPayslipData.data);
  
            let finalSalary = allSalaries.map((salary)=>{
              return({...salary,currency_selected:JSON.parse(salary?.currency_selected || {})})
            })
          setAllPayslip(finalSalary);
        }
        if (!allPayslipData.success) {
          // console.log("!successallPayslipData", allPayslipData.message);
          // errorMessage(allPayslipData?.message);
          setAllPayslip([]);
        } 
        }
      if (allPayslipError) {
          // console.log(allPayslipError.data.error.message);
          errorMessage(allPayslipError?.error.message ?? allPayslipError?.error ??  allPayslipError?.data?.error?.message);
      }
      
    } catch (error) {
      // console.log("catchError")
    }
  
    
  }, [allPayslipData,allPayslipError])
  



  const confirmation = (id, name, type) => {

    // console.log("id>", id, "name>", name, "type>", type);
    setIsConfirmation(true);
    setDType(type);
    // deleteInvoice(id);
    setDeleteId(id);
    setDeleteModalSalary(name);
  };

  const openGroupModal = async (check, id) => {
    if (check === true) {
      setUpdateGroup(true);
      setSingleGroupId(id);
      try {
        const { data, error } = await getSingleSalaryGroup(id);
        if (data) {
          if (data.success) {
            let singleGroupData = deepCopy(data.groupInvoiceData);
            setGroupDetails(() => {
              return {
                ...groupDetails,
                title: singleGroupData.title,
                from_name: singleGroupData.from_name,
                from_email: singleGroupData.from_email,
                from_address1: singleGroupData.from_address1,
                from_address2: singleGroupData.from_address2,
                from_zip_code: singleGroupData.from_zip_code,
                from_phone: singleGroupData.from_phone,
                from_fax: singleGroupData.from_fax,
                from_gst: singleGroupData.from_gst,
                from_ntn: singleGroupData.from_ntn,
                from_website: singleGroupData.from_website,
                group_number: singleGroupData.group_number,
              };
            });
            if (singleGroupData.logo !== "NULL" && singleGroupData.logo !== null && singleGroupData.logo) {
              // let imgUrl = "https://stagingapi.tradingforms.com/";
              setLogoImage(`${imgUrl}${singleGroupData.logo}`);
              setSalaryLogo(singleGroupData.logo);
            } else {
              setLogoImage(null);
            }
            setGroupModal(true);
          }
          if (!data.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
          // console.log(error.data.error.message);
          errorMessage(error?.error ?? error?.data?.error?.message);
        }
        
      } catch (error) {
        
      }
    }
    if (check === false && id === "noValue") {
      setGroupModal(true);
      // console.log("ifFALSE", check, id);
    }
  };

  const deleteInvoice = async (deltype) => {
    // if (value === true) {
      if (deltype === "delsalary") {
        // console.log("delsalary")
        try {
          setIsConfirmation(false);
          setLoader(true);
          const { data, error } = await deleteSingleSalary(deleteId);

          if (data) {
            // console.log("invoiceDeleted",data);
            // setTimeout(() => {
    
            // }, 10000);
            if (data.success) {
              successMessage(data.message);
            }
            if (!data.success) {
              errorMessage(data.message);
            }

          }
          if (error) {
            errorMessage(error?.error ?? error.data.error.message);
          }
        } catch (error) {
        } finally {
          setLoader(false);
          setDType("");
          setDeleteId();
        }
      }
      if (deltype === "delgroupsalary") {
        // console.log("delgroupsalary")
        try {
          setIsConfirmation(false);
          setLoader(true);
            const {data,error} = await deleteSingleGroup(deleteId);
            if (data) {
              if (data.success) {
                successMessage(data.message);
              }
              if (!data.success) {
                errorMessage(data.message);
              }
            }
            if (error) {
              errorMessage(error?.error ?? error.data.error.message);
            }
        } catch (error) {
          // console.log(error);
        }
        finally{
          setLoader(false);
          setDeleteId();
          setDeleteModalSalary("");
          setDType("");
        }
      }
    
  };


  const handleCloseConfirmation = () => {
      setIsConfirmation(false);
      setDType();
      setDeleteId();
  }

  const handleCloseDuplicate = () => {
    setIsConfirmDuplicate(false);
    setDType();
    SetDuplicateName();
    SetDuplicateId();

  }
  
  const handleOpenEmail = (title, invID, customerEmail) => {
    const usageDetails = salaryslipsPlanDetail?.planDetails?.plan_usage_details;
    if (usageDetails?.email_sent?.usage_count < usageDetails?.email_sent?.total) {
      setEmailModal(true);
      setEmailBody((prevState) => {
        return {
          ...prevState,
          id: invID,
          email_to: customerEmail,
        };
      });
      setEmailTitle(title);
    }
    else{
      errorMessage("Limit Reached. Upgrade your plan!");
    }
  };

  const handleCloseEmail = () => {
    setEmailModal(false);
    setEmailBody((prevState)=>{
      return{
        ...prevState,
        id: "",
        email_to:"",
        cc_status: "false",
        cc_emails:""
      }
    })
    setEmailTitle("")
  };

  const handleCloseGroupModal = () => {
    setGroupModal(false);
    setUpdateGroup(false);
    setSingleGroupId();
    setGroupDetails(companyData);
    setLogoImage(companyslogo);
  };

  // PLAN COUNT INCREASE
  const increasePlan = (type)=>{
    const newPlanDetails = JSON.parse(JSON.stringify(salaryslipsPlanDetail?.planDetails))
    // const newPlanDetails = { ...invoicePlanDetail.planDetails };
    newPlanDetails.plan_usage_details[type].usage_count += 1;
    dispatch(setPlanDetails(newPlanDetails));
  }

  //================email=======================
  const emailChange = (e) => {
    e.preventDefault();
    const {name,value} = e.target;
    if (name === "cc_status" & value === "false") {
      setEmailBody((prevState)=>{
        return({
          ...prevState, cc_emails:""
        })
      })
    }
    setEmailBody((prevState)=>{
      return({
        ...prevState, [name]:value
      })
    })
    // console.log(emailBody);
  };
  
  const handleEmail = async (e) => {
    e.preventDefault();
    setEmailbuttonLoader(true);
    
    let body = {
      id: emailBody?.id,
      email_to: emailBody?.email_to,
      email_body: emailBody?.email_body,
      attachment: emailBody?.attachment,
      cc_status: emailBody?.cc_status,
      cc_emails: emailBody?.cc_emails,
      type: "salary",
    };
    // https://stagingfront.tradingforms.com/api/invoice_email?id=59
    // console.log(body);
    let token = localStorage.getItem("tradingformtoken");
    await axios(`${LARAVELurl}salary_invoice_email`, {
      method: "POST",
      data: body,
      headers: {
        "Content-Type": "application/json;",
        "Access-Control-Allow-Origin": "*", //'Access-Control-Allow-Origin'
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // navigate('/invoice');
        // console.log("posted>>", response);
        setEmailModal(false);
        setEmailbuttonLoader(false);
        increasePlan("email_sent");
        successMessage(response.data?.message);
        setEmailBody((prevState)=>{
          return{
            ...prevState,
            id: "",
            email_to:"",
            attachment:"true",
            cc_status: "false",
            cc_emails:""
          }
        })
      })
      .catch((error) => {
        // console.log("error in post>>", error);
        // setEmailModal(false);
        setEmailbuttonLoader(false);
        errorMessage(error.data?.message);
      });
    setEmailbuttonLoader(false);
    setEmailModal(false);
  };

  // ======Duplicate============

  const duplicationValidation = (type) => {
    const usageDetails = salaryslipsPlanDetail?.planDetails?.plan_usage_details;

    if (type === "salary") {
      return usageDetails?.single_payslip?.usage_count < usageDetails?.single_payslip?.total;
    }

    if (type === "groupsalary") {
      return usageDetails?.salary_group?.usage_count < usageDetails?.salary_group?.total;
    }

    // Return false if type is not supported
    return false;
  }
  

  const duplicateConfirmation = (id, name,type) => {
    // console.log("id>", id, "name>", name, "type>", type);
    if (duplicationValidation(type)) {
      setIsConfirmDuplicate(true);
      setDType(type);
      SetDuplicateName(name);
      SetDuplicateId(id);
      
    }else{
      errorMessage("Limit Reached. Upgrade your plan!");
      // navigate('/subscription-plans');
    }
  };
  const getLatestCount = async () => {
    if (auth.user !==null) {
          try {
          const body = {
            user_email:auth.user?.email,
            user_id:auth?.user?.id
          }

          const resp = await getUserPlanService(body);
          dispatch(setPlanDetails(resp.data));
        } catch (error) {
          console.log("plans->error",error);
        }
    }
  }


  const createDuplicateSubmit = async (type) => {
    if (type === "dupsalary") {
      try {
        setIsConfirmDuplicate(false);
        setDuplicateLoader(true);
        // console.log("duplicate payslip", duplicateId);
        const { data, error } = await duplicateSalarySlip(duplicateId);
        if (data) {
          if (data.success) {
            increasePlan('single_payslip')
          successMessage(data.message);
          }
          if (!data.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
          // console.log("payslip!duplicated", error);
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Error.");
        }
      } catch (error) {
        // console.log("catchError");
      } finally {
        // console.log("finally");
        setDuplicateLoader(false);
        SetDuplicateId("");
        SetDuplicateName("");
      }
    }
    if (type === "dupgroupsalary") {
      try {
        setIsConfirmDuplicate(false);
        setDuplicateLoader(true);
        const {data,error} = await duplicateSalaryGroup(duplicateId);
        if(data){
          // console.log("Duplicatedata",data);
          if (data.success) {
            // increasePlan('salary_group')
            await getLatestCount();
            successMessage(data.message);
          }
          if (!data.success) {
            errorMessage(data.message);
            await getLatestCount();
          }
        }
        if(error){
          errorMessage(error?.error ?? error.data.error.message);
        }
        
      } catch (error) {
        // console.log("catchError");
      }
      finally{
        setDuplicateLoader(false);
        SetDuplicateId("");
        SetDuplicateName("");

      }
    }
  };

  // ======GET ALL GROUPS =======================

  useEffect(() => {
    try {
      if (getAllGroupData) {
        // console.log("getAllGroupData>>",getAllGroupData)
        if (getAllGroupData.success) {
          // console.log("success-getAllGroupData",getAllGroupData.message);
          let grouplisting = deepCopy(getAllGroupData.data);
          setAllGroups(grouplisting);
        }
        if (!getAllGroupData.success) {
          // console.log("!success-getAllGroupData",getAllGroupData.message);
          setAllGroups([]);
        }
      }
      if (getAllGroupError) {
        // console.log(getAllGroupError.data.error.message);
        errorMessage(getAllGroupError?.error ?? getAllGroupError?.data?.error?.message);
      }
      
    } catch (error) {
      // console.log("catchErrorAllgroups")
    }
  }, [getAllGroupData, getAllGroupError]);

  //  ===============Create Group START==============

  const groupChange = (e) => {
    let { name, value } = e.target;
    // if (name === "from_phone" || name === "from_fax") {
    //   let phonenumb = teleValidation(e);
    //   // console.log("phonenumb>>>",phonenumb);
    //   setGroupDetails((prevState) => {
    //     return { ...prevState, [name]: phonenumb };
    //   });
    //   // console.log(`phonenumbForm--${name}>>`,formData?.[name]);
    // }
    // if (name !== "from_phone" && name !== "from_fax") {
      setGroupDetails((prevState) => {
        return { ...prevState, [name]: value };
      });
      // console.log(groupDetails)
    // }
  };

  // handle phone/fax numbers fields 
  const handleTeleFields = (name, value) => {
    setGroupDetails((prevState)=>{
      return{
        ...prevState,
        [name]: value
      }
    });
  }
  // ==============Company settings================
  useEffect(() => {
    // if (!props.updating) {
    // setLoader(true);
    try {
      if (data) {
          // console.log("companySettingsdata",data);
        if (data.success) {
          // console.log("successcompanySettings",data);
          if (Object.keys(data.data).length) {
              // console.log("Object.keys",data);
              let companySettings = deepCopy(data.data);
              setGroupDetails((prevState) => {
                return {
                  ...prevState,
                  // logo: null,
                  title: "",
                  from_name: companySettings?.company_name || "",
                  from_email: companySettings?.company_email || "",
                  from_address1: companySettings?.company_address1 || "",
                  from_address2: companySettings?.company_address2 || "",
                  from_zip_code: companySettings?.company_zip_code || "",
                  from_phone: companySettings?.company_phone || "",
                  from_fax: companySettings?.company_fax || "",
                  from_website: companySettings?.company_website || "",
                  from_gst: companySettings?.company_gst || "",
                  from_ntn: companySettings?.company_ntn || "",
                  group_number:"Group-"
                  // receipt_number: companySettings.invoice_number || "",
                  // ending_notes: companySettings?.company_default_note || "",
                };
              });
              // https://stagingapi.tradingforms.com/
              // http://localhost:4000/
              companyData = {
                from_name: companySettings?.company_name || "",
                from_email: companySettings?.company_email || "",
                from_address1: companySettings?.company_address1 || "",
                from_address2: companySettings?.company_address2 || "",
                from_zip_code: companySettings?.company_zip_code || "",
                from_phone: companySettings?.company_phone || "",
                from_fax: companySettings?.company_fax || "",
                from_website: companySettings?.company_website || "",
                from_gst: companySettings?.company_gst || "",
                from_ntn: companySettings?.company_ntn || "",
                group_number:"Group-"
              };
              if (
                companySettings.company_logo !== "NULL" &&
                companySettings.company_logo !== null
              ) {
                // let imgUrl = "https://stagingapi.tradingforms.com/";
                setLogoImage(`${imgUrl}${companySettings.company_logo}`);
                companyslogo = `${imgUrl}${companySettings.company_logo}`;
                setSalaryLogo(companySettings.company_logo);
              } else {
                setLogoImage(null);
              }
          }
           setLoader(false);
        }
        if (!data.success) {
          errorMessage(data.message)
          setLoader(false);
        }
        
      }
      if (error) {
        // console.log(error?.error ?? error.data.error.message);
        errorMessage(error?.error ?? error?.data?.error?.message);
      }
      
    } catch (error) {
      
    }
   
    // }
  }, [data, error,imgUrl]);

  // remove image from front site and also empty the state which is being added in formData while submiting form
  const removeImage = async () => {
    setLogoImage(null);
    setSalaryLogo(null);
    try {
      if (updateGroup) {
        setLoader(true);
        const {data,error} = await deleteSingleSalaryGroupLogo(singleGroupId);
        if (data) {
          // console.log("logoDeleted",data);
          if (data.success) {
            successMessage(data.message);
            // console.log("success>logoDeleted",data);
          }
          if (!data.success) {
            // console.log("!success>logoDeleted",data.message);
            errorMessage(data.message);
          }
        }
        if (error) {
          // console.log("logo!Deleted",error.data);
          errorMessage(error?.error ?? error?.data?.error?.message);
        }
      }
    } catch (error) {
      // console.log("catchError")
    }
    finally{
      setLoader(false);
    }
  };

  // add image in states to display image on personal detail logo and also updating the state which is being added in formData while submiting form
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSalaryLogo(file);
      const reader = new FileReader();
      // setLogoImage();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setLogoImage(reader.result);
      };
      event.target.value = "";
    }

    // console.log(salaryLogo);
    // console.log(formData.logo);
  };

  // Submit groupDetails

  const groupDetailsSubmit = async (e) => {
    e.preventDefault();
    // setGroupModal(false);
    setGroupbuttonLoader(true);
    if (updateGroup === true) {
      try {
        let updatePayslipGroup = new FormData();

        let body = { ...groupDetails };
        if (salaryLogo) {
          updatePayslipGroup.append("logo", salaryLogo);
        }
        updatePayslipGroup.append("data", JSON.stringify(body));

        const { data, error } = await updateSingleSalaryGroup({
          id: singleGroupId,
          updatePayslipGroup,
        });
        if (data) {
          // console.log("GroupUpdated",data);
          if (data.success) {
            successMessage(data.message);
            // console.log("success>GroupUpdated",data);
            
          }
          if (!data.success) {
              errorMessage(data.message);
              // console.log("!successGroupUpdate", data.message);
          }
        }
        if (error) {
          // console.log("GroupNot Updated", error);
          errorMessage(error?.error ?? error?.data?.error?.message);
        }
      } catch (error) {
        // console.log("catchError");
      } finally {
        setLoader(false);
        setGroupbuttonLoader(false);
        setGroupModal(false);
        // setGroupDetails((prevState)=>{
        //   return {
        //     ...prevState
        //     ,companyData}});
        setGroupDetails(companyData)
        setLogoImage(companyslogo);
      }
    } else {
      try {
        setGroupbuttonLoader(true);

        let paySlipGroup = new FormData();
        let body = { ...groupDetails };
        // console.log("groupbody>>>",body);
        if (salaryLogo) {
          paySlipGroup.append("logo", salaryLogo);
        }
        paySlipGroup.append("data", JSON.stringify(body));

        const { data, error } = await createSalaryGroup(paySlipGroup);
        if (data) {
          if (data.success) {
            successMessage(data.message);
            const newPlanDetails = JSON.parse(JSON.stringify(salaryslipsPlanDetail?.planDetails));
            newPlanDetails.plan_usage_details.salary_group.usage_count += 1;
            setTimeout(() => {
              dispatch(setPlanDetails(newPlanDetails));
            }, 10);
            setTimeout(() => {
              
            }, 10);
          }
          if (!data.success) {
            errorMessage(data.message);
          }
          // props.history.push("/salary-group");
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message??"Error");
        }
      } catch (error) {
        // console.log("catchError");
        // errorMessage(error.data.message);
      } finally {
        // console.log("finally");
        // setGroupDetails((prevState)=>{
        //   return {
        //     ...prevState
        //     ,companyData}});
        setGroupDetails(companyData);
        setLogoImage(companyslogo);
        setGroupbuttonLoader(false);
        setGroupModal(false);
      }
    }
  };

  const handleCreateNavigate=() => {
    if (salaryslipsPlanDetail?.planDetails?.plan_usage_details?.single_payslip?.usage_count<salaryslipsPlanDetail?.planDetails?.plan_usage_details?.single_payslip?.total) {
      navigate('create');
    }else {
      errorMessage("Limit Reached. Upgrade your plan!");
      navigate('/subscription-plans');
    } 
  }

  const handleCreateCheckGroup = (status,value) => {
    if (salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group?.usage_count<salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group?.total) {
    openGroupModal(status, value)
    }else {
      errorMessage("Limit Reached. Upgrade your plan!");
      navigate('/subscription-plans');
    }
  }
  
  

  return (
    <>
      {loader === true && <CustomDeleteloader message="Deleting . . ." />}
      {duplicateLoader === true && (
        <CustomDeleteloader message="Duplicating . . ." />
      )}
      {/* {initailLoader 

      } */}
      <div className="spacer"></div>
      {/* <div className="spacer"></div> */}
      <div className="invoice-container">
        <div className="container invoice-list" data-aos="zoom-in">
          <div className="invoice-actions">
            <div className="invoice-navBtn invoice-navBtn-custom">
              <button
                onClick={() => setShowInvoice(1)}
                className={`btn btn-secondary btn-sm ${
                  showInvoice === 1 ? "active cursor-disable" : ""
                }`}
                type="button"
                title="View All Payslips"
              >
                All PaySlips
              </button>
              <button
                onClick={() => setShowInvoice(2)}
                className={`btn btn-secondary btn-sm ${
                  showInvoice === 2 ? "active cursor-disable" : ""
                }`}
                type="button"
                title="View All Payslip Groups"
              >
                PaySlip Groups
              </button>

            </div>
            <div className="searchCreate-invoice">
              <Link to='/email-history'  state={{emailHistory: 'salary' }} className="btn btn-secondary btn-sm email-history" title="View sent emails history for Payslips"><i className="fa fa-history"></i>  <span> Email History </span></Link>
              <div className="createInvoice">
                {showInvoice === 1 && (
                  <button className="btn btn-primary d-flex align-items-center justify-content-center" title="Go to Create New Pasyslip" onClick={handleCreateNavigate}>
                    <i className="fa fa-plus pe-2"></i><span>Create Payslip</span>
                  </button>
                )}
                {showInvoice === 2 && (
                  <button
                    className="btn btn-primary action-button-group"
                    onClick={() => handleCreateCheckGroup(false, "noValue")}
                    title="Create New Payslip Group"
                  >
                    <i className="fa fa-plus"></i><span className="action-button-inner">Create Payslip Group</span>
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="container px-0 fadeInOpacity overflow-hidden">
            {showInvoice === 1 && (
              <Allsalaries
                deleteSalary={confirmation}
                handleOpenEmail={handleOpenEmail}
                duplicatepayslip={duplicateConfirmation}
                allPayslipsData={allPayslip}
                url={"salary-slips/edit"}
                showFrom={false}
                isLoading={tableLoader}
              />
            )}

            {showInvoice === 2 && (
                <AllGroupPayslip
                  allgroups={allgroups}
                  openGroupModal={openGroupModal}
                  deleteSalary={confirmation}
                  duplicatepayslipGroup={duplicateConfirmation}
                  isLoading={groupTableLoader}
                />
            )}
          </div>
          
          {/* <div className="invoice-list-footer"><span>All your invoices are auto saved here</span></div> */}
        </div>
        {/* <div className="spacer"></div> */}
        {showInvoice === 2 && (
          <>
            <div className="spacer"></div>
            <div className="container invoice-list paygroup-instructions-container">
              <div className="paygroup-instructions">
                <h5>What are Payslip Groups?</h5>
                <ul>
                  <li>
                    <span>
                    Empower your payroll process with our user-friendly 'Grouped Payslips' feature and create custom groups.
                    </span>
                  </li>
                  <li>
                    <span>After creating your Payslip Group, click on 'View' to access a well-organized workspace, allowing you to effortlessly generate and manage payslips.</span>
                  </li>
                  <li>
                    <span>Simplify your payroll process by swiftly creating payslips within the designated group, ensuring a smooth and productive payroll experience.</span>
                  </li>
                </ul>

              </div>
            </div>
          </>
          )
        }
      </div>

      {/* delete modal */}
      <ReactPortal>
        {isConfirmation && (
          <Modal
            header=""
            type="confirm"
            icon={"trash-alt text-danger"}
            recordName={`Are you sure want to delete this ${
              dType === "salary"
                ? `Payslip# ${deleteModalSalary}`
                : `Payslip Group '${deleteModalSalary}'`
            } `}
          >
            <button
              className="btn btn-danger m-1 col-md-3 col-12"
              onClick={() => deleteInvoice( `${ dType==='salary' ? 'delsalary':'delgroupsalary'}`)}
              title={`Yes, Delete ${ dType==='salary' ? 'Payslip':'Payslip Group'}`}
            >
              {" "}
              Yes
            </button>
            <button
              className="btn btn-secondary m-1 col-md-3 col-12"
              onClick={handleCloseConfirmation
                // deleteInvoice(false, `${ dType==='salary' ? 'delsalary':'delgroupsalary'}`)
              }
              title={`Do not Delete ${ dType==='salary' ? 'Payslip':'Payslip Group'}`}
            >
              No
            </button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>

      {/* email modal */}
      <ReactPortal>
        {emailModal && (
          <Modal
            handleClose={handleCloseEmail}
            type="form" contentType="email-form"
            header={`Enter details to send mail for ${emailTitle}`}
          >
            <SendEmail
              emailBody={emailBody}
              handleSubmit={handleEmail}
              emailChange={emailChange}
              loader={emailbuttonLoader}
            />
          </Modal>
        )}
      </ReactPortal>

      {/* duplicate modal */}
      <ReactPortal>
        {isConfirmDuplicate && (
          <Modal
            header=""
            type="confirm"
            icon={"copy text-info"}
            recordName={`Are you sure want to duplicate ${
              dType === "salary"
                ? `Payslip# ${duplicateName}`
                : `Payslip Group'${duplicateName}'`
            }  `}
          >
            <button
              className="btn btn-save m-1 col-md-3 col-12"
              onClick={() => createDuplicateSubmit(`${ dType==='salary' ? 'dupsalary':'dupgroupsalary'}`)}
              title={`Yes, Duplicate ${ dType==='salary' ? 'Payslip':'Payslip Group'}`}
            >
              {" "}
              Yes
            </button>
            <button
              className="btn btn-primary m-1 col-md-3 col-12"
              onClick={handleCloseDuplicate}
              title={`Do not Duplicate ${ dType==='salary' ? 'Payslip':'Payslip Group'}`}
            >
              No
            </button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>

      {/* create payslipGroup modal */}
      <ReactPortal>
        {groupModal && (
          <Modal
            handleClose={handleCloseGroupModal}
            type="form"
            header={`${updateGroup ? 'Update ': 'Create '}Payslip Group`}
          >
            <GroupDetailsForm
              groupDetails={groupDetails}
              groupChange={groupChange}
              groupDetailsSubmit={groupDetailsSubmit}
              loader={groupbuttonLoader}
              logoImage={logoImage}
              handleFileInputChange={handleFileInputChange}
              removeImage={removeImage}
              updateGroup={updateGroup}
              handleTeleFields={handleTeleFields}
            />
          </Modal>
        )}
      </ReactPortal>
    </>
  );
}

export default SalarySlips;
