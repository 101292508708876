import React from 'react'
import logo from '../assets/images/firstLogo.webp'

function CustomLoader() {
  return (
    <div className="custom-loader-container">
        <div className="custom-loader">
            <div className="spinner"></div>
            <img src={logo} alt=""></img>
        </div>
  </div>
  )
}

export default CustomLoader