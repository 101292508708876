import React from 'react'
// import CustombuttonSpinner from '../../components/CustombuttonSpinner'
import { separateComma } from '../../utils/utils'
import TableLoader from '../../components/loaders/TableLoader'

function PayslipReport({ reportsData, allYear, tableloader, activeYear, changeActiveYear, reportClass }) {

  return (
    <>
      <div className="reports-container">

      
        <div className="reports-body">
         
          {tableloader === false ?
            <div className="table-responsive h-100" data-aos="fade-left">
              {reportsData?.data?.months ?
                <table className='table'>
                  <thead>
                    <tr>
                      <th style={{'minWidth': '165px'}}>Months</th>
                      <th style={{ "width": "75px" }}>Consumers</th>
                      <th style={{ "width": "75px" }}>Payslips</th>
                      <th style={{ "width": "75px" }}>Earnings</th>
                      <th style={{ "width": "75px" }}>Deductions</th>
                      <th style={{ "width": "120px" }}>Net Pay</th>

                    </tr>

                  </thead>
                  <tbody>

                    {reportsData?.data?.months.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td style={{'minWidth': '165px'}}>{record?.month_name}</td>
                          <td style={{ "width": "75px" }}>{record?.total_consumers}</td>
                          <td style={{ "width": "75px" }}>{record?.total_salary_slips}</td>
                          <td style={{ "width": "75px" }}>{separateComma(record?.total_earnings)}</td>
                          <td style={{ "width": "75px" }}>{separateComma(record?.total_deductions)}</td>
                          <td style={{ "width": "120px" }}>{separateComma(record?.total_net_pay)}</td>
                        </tr>
                      )
                    })

                    }

                  </tbody>
                </table> : <div className='reports-no-result'>There are no reports for payslips.</div>
              }
            </div> : (<TableLoader />)
          }
        </div>

        <div className='reports-footer'>
          <div className="years-wrapper">
            <div className='year-inner-container'>
              {
                allYear.map((year, index) => { return (<button className={`btn btn-primary ${activeYear === `${year.year}` ? 'active cursor-disable' : ''}`} onClick={() => changeActiveYear(`${year?.year}`)} key={year?.year} title={`View ${reportClass==='singler'?'Single Payslips':'Group Payslips'} Report For Year ${year?.year}`}><span>{year?.year}</span></button>) })
              }
            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default PayslipReport
