import React, { useCallback, useEffect, useState } from "react";
import countriesCurrencies from "./assets/js/countriesCurrency";
import ReactPortal from "./components/reactPortal/ReactPortal";
// import Modal from "../modal/Modal";
import Modal from "./components/modal/Modal";

import {
  useCreateCompanySettingsMutation,
  useCreateSalaryDeductionMutation,
  useCreateSalaryEarningMutation,
  useDeleteCompanyLogoMutation,
  useDeleteCompanybgMutation,
  useDeleteSingleDeductionMutation,
  useDeleteSingleEarningMutation,
  useGetCompanySettingQuery,
  useLazyGetUserBgImageQuery,
} from "./store/apis/baseApi";
import AddEarning from "./views/settings/AddEarning";
import AddDecuction from "./views/settings/AddDecuction";
import AllDeduction from "./views/settings/AllDeduction";
import AllEarning from "./views/settings/AllEarning";
import { errorMessage, successMessage } from "./components/toastMessages/ToastifyMsg";
import CustomLoader from "./components/CustomLoader";
import CustombuttonSpinner from "./components/CustombuttonSpinner";
import CustomDeleteloader from "./components/CustomDeleteloader";
import { deepCopy } from "./utils/utils";
import { useDispatch } from "react-redux";
import { setBackgroundUrl } from "./store/slices/backgroundPicture";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { IMG_URL } from "./common/common";
import SelectImg from "./components/imgSelect/SelectImg";


function Settings() {
  const imgUrl = IMG_URL;

  const [createSalaryDeduction] = useCreateSalaryDeductionMutation();
  const [createSalaryEarning] = useCreateSalaryEarningMutation();
  const [deleteSingleEarning] = useDeleteSingleEarningMutation();
  const [deleteSingleDeduction] = useDeleteSingleDeductionMutation();
  const [deleteCompanybg] = useDeleteCompanybgMutation();
  const [isEarningOpen, setIsEarningOpen] = useState(false);
  const [isDeductionOpen, setIsDeductionOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(null); //base64
  const [showbg,setShowbg] = useState(null); //base64
  const {data, error} = useGetCompanySettingQuery();
  const [deleteCompanyLogo] =useDeleteCompanyLogoMutation();
  const [settingsId,setSettingsId] = useState(); 
  const [loader,setLoader] = useState(true);
  const [tempLogo,setTempLogo]= useState(""); // logo file
  const [tempbg,setTempbg] = useState("");  // bg file
  const [deleteModalInvoice, setDeleteModalInvoice] = useState(false);
  const [isConfirmation,setIsConfirmation] = useState(false);
  const [deleteId,setDeleteId] =useState();
  const [isConfirmationDeduct,setIsConfirmationDeduct]= useState(false);
  const [buttonLoader,setButtonLoader]= useState(false);
  const [delLoader,setDelLoader]= useState(false);
  const [postLoader,setPostLoader]= useState(false);
  // const [logoLoader,setLogoLoader]= useState(false);
  const [getUserBgImage] = useLazyGetUserBgImageQuery();
  const dispatch = useDispatch();


  const [createCompanySettings] = useCreateCompanySettingsMutation();

  const [currencyOption,setCurrencyOption]  = useState(null);

  useEffect(() => {
    if (countriesCurrencies) {
      const options = countriesCurrencies.map( currency=>({
        label: `${currency?.CountryName} ${currency?.Symbol}`,
        value: currency?.Code,
        image: currency?.Flag,
        type: 'currency',
        custom_value: currency
      }));
      
      setCurrencyOption(options);
    }
  }, []);

// =================Get All deductions END==========

  // ==============
  const [formData, setFormData] = useState({
    company_name: "",
    company_email: "",
    company_address1: "",
    company_address2: "",
    company_phone: "",
    company_fax: "",
    company_zip_code: "",
    company_business_number: "",
    company_gst:"",
    company_ntn: "",
    // logo: "",
    // company_bg_image: "",
    invoice_number: "",
    invoice_title: "",
    company_color: "#00acee",
    company_default_note: "",
    company_website: "",
    quotation_number: "",
    salary_number: "",
    tax_type: "none",
    tax_label: "",
    tax_rate: "",
    tax_inclusive: "0",
    currency: countriesCurrencies[0],
  });
  
  useEffect(() => {
    setLoader(true);
    // console.log(data)
    if (data) {
      if (data.success) {
        if (data?.data && Object.keys(data.data).length ) {
          let settings= deepCopy(data.data);
          setFormData((prevState)=>{
            return({
              ...prevState,
              // logo: settings.company_logo,
            company_name: settings.company_name,
            company_email: settings.company_email,
            company_address1: settings.company_address1,
            company_address2: settings.company_address2,
            company_phone: settings.company_phone,
            company_zip_code: settings.company_zip_code,
            company_fax: settings.company_fax,
            company_business_number: settings.company_business_number,
            company_gst:settings.company_gst,
            company_ntn: settings.company_ntn,
            // company_bg_image: settings.company_bg_image,
            // logo: settings.company_logo,
            company_color: settings.company_color,
            company_default_note: settings.company_default_note,
            tax_type: settings.tax_type || "none",
            tax_label: settings.tax_label,
            tax_rate: settings.tax_rate,
            tax_inclusive: settings.tax_inclusive, 
            invoice_number: settings.invoice_number, 
            invoice_title: settings.invoice_title,
            company_website: settings.company_website,
            quotation_number: settings.quotation_number,
            salary_number: settings.salary_number,
            currency: JSON.parse(settings?.currency),
          })
            
            }
          );
          setSettingsId(settings.parent_id);
            // https://stagingapi.tradingforms.com/
            // http://localhost:4000/
            // https://stagingapi.tradingforms.com/
            // let imgUrl = "https://stagingapi.tradingforms.com/";
            if ( settings.company_logo !==null && settings.company_logo !== "null") {
              setTempLogo(settings.company_logo);
              setShowLogo(`${imgUrl}${settings.company_logo}`);
            }else{
              setShowLogo(null);
            }
            if ( settings.company_bg_image !==null && settings.company_bg_image !== "null" && settings.company_bg_image) {
              setShowbg(`${imgUrl}${settings.company_bg_image}`);
              setTempbg(settings.company_bg_image);
            }else{
              setShowbg(`${imgUrl}company_bg_images/backgroundpic.png`);
              setTempbg("company_bg_images/backgroundpic.png")
            }

            // setLoader(false);
        }
      if (!data.success) {
          errorMessage(data.message);
      }
    }
    }
    
      if (error) {
        // console.log("Error",error);
        errorMessage(error?.error ?? error?.data?.error?.message ?? "Server not responding.");
        // setLoader(false);
      }
      setTimeout(() => {
        
        setLoader(false);
      }, 1000);
  }, [data,error,imgUrl]);
  


  // ==========Modals START===============
      // earning modal
  const handleModalEarnigOpen = useCallback(() => {
    setIsEarningOpen(true);
  }, []);

  const handleModalEarnigClose = useCallback(() => {
    setIsEarningOpen(false);
  }, []);
    //  deduction modal
  const handleModalDeductionOpen = useCallback(() => {
    setIsDeductionOpen(true);
  }, []);

  const handleModalDeductionClose = useCallback(() => {
    setIsDeductionOpen(false);
  }, []);
// ============Modals END======================
  


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(name,">",value)
    if (event.target.name === "currency") {
      setFormData((prevState) => {
        return { ...prevState, [event.target.name]: JSON.parse(event.target.value) };
      });
      return;
    }
    // if (event.target.name === "company_phone" || event.target.name === "company_fax" || event.target.name ==="company_business_number") {
    //   let phonenumb= teleValidation(event);
    //   // console.log("phonenumb>>>",phonenumb)
    //   setFormData({ ...formData, [name]: phonenumb });
    // }
    if (event.target.name !=="currency" 
    // && event.target.name !== "company_phone" && event.target.name !== "company_fax" && event.target.name !=="company_business_number"
    ) {
      if (event.target.name === "tax_type" && event.target.value === "none") {
        setFormData((prevState)=>{
          return{
            ...prevState,
            [name]: "none",
            tax_label: "",
            tax_rate: "0"
          }
        })
      }else{
        setFormData({ ...formData, [name]: value });
      }
    }
    // console.log(formData);
  };

  const handleOnChangeSelect = (selected) => {
    setFormData((prevState) => {
      return { ...prevState, currency: selected?.custom_value };
    });
  }
  
  

  const handleCheckbox = (e) => {
    // console.log(JSON.stringify(e.target.checked))
    handleInputChange({
    preventDefault: e.preventDefault,
    target: {
      name: e.target.name,
      value: e.target.checked===true ? "true":"false"
    }
  })
}

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    event.target.value=""
    // console.log(file)
    if (event.target.name === "logo") {
      // console.log("logo",event.target.name)
          reader.onloadend = () => {
          setShowLogo( reader.result);
        };

        reader.readAsDataURL(file);

        if (!file) {
          setFormData({
            ...formData,
          });
        }
        else{
          // setFormData({
          //   ...formData,
          //   logo: file,
          // });
          setTempLogo(file);
        }
    }
    if (event.target.name === "company_bg_image") {
      // console.log("company_bg_image",event.target.name)
      reader.onloadend = () => {
      setShowbg( reader.result);
    };

    reader.readAsDataURL(file);

    if (!file) {
      setFormData({
        ...formData,
      });
    }
    else{
      // setFormData({
      //   ...formData,
      //   company_bg_image: file,
      // });
      setTempbg(file);
    }
}
    // event.target.value="";
    
  };

  //  delete logo
  const removeImage= async()=>{
    setShowLogo(null);
    setTempLogo(null);
    // setFormData((prevState)=>{
    //    return {...prevState, logo:""}
    //   }
    // );
      try {
        const {data,error} = await deleteCompanyLogo(settingsId);
        if (data) {
            // console.log("Comapany Logo Deleted from Db",data);
            if (data.success) {
              successMessage("Image "+data.message);
            }
            if (!data.success) {
              errorMessage("Image "+data.message);
            }
        }
        if (error) {
            // console.log("Comapany Logo NOT Deleted",error);
            errorMessage(error?.error ?? error?.data?.message);
        }
        
      } catch (error) {
        
      }
  }

//  delete bg Image
  const removeImageBg= async()=>{
    setShowbg(null);
    setTempbg(null);

    // setFormData((prevState)=>{
    //   return {...prevState, company_bg_image:""}
    //   }
    // );
      try {
        const {data,error} = await deleteCompanybg(settingsId);
        if (data) {
          if (data.success) {
            dispatch(setBackgroundUrl("company_bg_images/backgroundpic.png"));
            successMessage("Image "+ data.message);
          }
          if (!data.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
            // console.log("Comapany Logo NOT Deleted",error);
            errorMessage(error?.error ?? error?.data?.message);
        }
        
      } catch (error) {
        
      }
  }

  // submit Form
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setButtonLoader(true);

       let companySettingForm = new FormData();
    
    let body = {
      ...formData,
      currency: JSON.stringify(formData.currency),
      // logo:tempLogo,
      // company_bg_image:tempbg,
    };    


    companySettingForm.append('logo',tempLogo);
    companySettingForm.append('company_bg_image',tempbg);
    companySettingForm.append('data',JSON.stringify(body));

    const resp = await createCompanySettings(companySettingForm);

    if (resp.data) {
        // console.log(resp);
        // successMessage(resp.data.message);
        if (resp.data.success) {
          const {data:bgData,error:bgError} = await getUserBgImage();
          if (bgData) {
            successMessage("Bussiness Settings Updated successfully.");
            // if (bgData.data.company_bg_image !== null) {
            //   dispatch(setBackgroundUrl(bgData.data.company_bg_image));
            // }
          }
          if (bgError) {
            errorMessage(bgError?.error ?? bgError?.data?.error?.message ?? bgError.data?.message ?? "Eror In Fetching Background Image.")
          }
        }
        if (!resp.data.success) {
          errorMessage(resp.data.message);
          setLoader(false);
        }
        

    }
    if (resp.error) {
      errorMessage(resp.error.message?? "Error in Save.");
      // setButtonLoader(false);
    }

        setButtonLoader(false);
       
    } catch (error) {
    }
    finally{
      setButtonLoader(false);
    }
    



  };

  // ============Earnings post-delete START======================
  
  const handleSubmitEarnings=async(e)=>{
    e.preventDefault();
    setPostLoader(true);
    let addition_title = e.target.addition_title.value;
    // setEarningsData({ evente.target.addition_title.naevente]: e.target.addition_title.value })
    // console.log(addition_title);

    setIsEarningOpen(false);
    // console.log({addition_title: addition_title})
    try {
      const { data, error } = await createSalaryEarning({addition_title: addition_title});
      if (data) {
        if (data.success) {
          // console.log("data Posted>>", data);
          setPostLoader(false);
          successMessage(data.message);
          
        }
        if (!data.success) {
          setPostLoader(false);
          errorMessage(data.message)
        }
  
      }
      if (error) {
        // console.log(error, "<< data error");
        setPostLoader(false);
        errorMessage(error?.error ?? error?.data?.message ?? error.data?.message);
  
      }
      
    } catch (error) {
      
    }

  }

  const deleteEarning = async(value)=>{
    if (value=== true) {
      setDelLoader(true);
      setIsConfirmation(false);
      try {
        const {data,error} = await deleteSingleEarning(deleteId);
        if(data){
          if (data.success) {
            // console.log("Data",data);
            // navigate('/items');
            
            setDeleteId();
            
            setDelLoader(false);
            successMessage(data.message);
          }
          if (!data.success) {
            setDelLoader(false);
            setDeleteId();
            errorMessage(data.message);
          }
  
        }
        
        if(error){
          // console.log('ERROR:', error.data.message);
          errorMessage(error.data?.message);
    
        }
        
      } catch (error) {
        
      }
    } else {
      setDeleteId();
      setIsConfirmation(false);
    }

  }

  const confirmationEarning = (id,name)=>{
    setIsConfirmation(true);
    // deleteInvoice(id);
    setDeleteModalInvoice(name);
    setDeleteId(id);
  }
  
 
// ============Earnings post-delete END======================



// ==============deduction post/delete START==================

  const handleSubmitDeduction = async(e)=>{
    e.preventDefault();
    setPostLoader(true);
    let deduction_title = e.target.deduction_title.value;
    // console.log(e.target.deduction_title.value);
    setIsDeductionOpen(false);
    try {
      const {data, error} = await createSalaryDeduction({deduction_title: deduction_title})
      if (data) {
        if (data.success) {
          // console.log("data Posted>>", data);
        setPostLoader(false);
        successMessage(data.message);
        }
        if (!data.success) {
          errorMessage(data.message);
          setPostLoader(false);
        }
      }
      if (error) {
        // console.log(error, "<< data error");
        setPostLoader(false);
        errorMessage(error.data?.message);
  
      }
      
    } catch (error) {
      
    }
  }

  const deleteDeduction= async(value)=>{
    // console.log(id);
      if (value === true) {
        setIsConfirmationDeduct(false);
        setDelLoader(true);
        try {
          const {data,error} = await deleteSingleDeduction(deleteId);
          if(data){
            // console.log("Data",data);
            if (data.success) {
              setIsConfirmation(false)
            setDeleteId();
            
            setDelLoader(false);
            successMessage(data.message);
            }
            if (!data.success) {
              errorMessage(data.message);
              setDelLoader(false);
            }
            
            
          }
          
          if(error){
            // console.log('ERROR:', error.data.message);
            setDelLoader(false);
            errorMessage(error?.error ?? error?.data?.message ?? error.data?.message);
    
          }
          
        } catch (error) {
          
        }
      } else {
        setDeleteId();
        setIsConfirmationDeduct(false);
      }


  }

  const confirmationDeduction = (id,name)=>{
    setIsConfirmationDeduct(true);
    // deleteInvoice(id);
    setDeleteModalInvoice(name);
    setDeleteId(id);
  }
// ==============deduction post/delete END==================

  return (
    <>  {loader === true &&
        <CustomLoader/>
        }
        {delLoader &&
          <CustomDeleteloader message="Deleting . . ."/>
        }
        {postLoader &&
          <CustomDeleteloader message="Adding . . ."/>
        }
      {/* <form className="container" onSubmit={handleSubmit}> */}
      <div className="spacer"></div>
      {/* <div className="spacer"></div> */}
      <section className="SettingContainer" data-aos="zoom-in">
        
        <form className="container" onSubmit={handleSubmit}>
          <div className="col-md-12 col-lg-10 settingsInnerContainer">

            <div className="card">
              <div className="card-header">
                  <h3><i className="fa fa-cogs"></i> Settings </h3>
              </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className=" my-3 p-0">
                  <div className="card-header custom-header">
                    <h5> Business Details  </h5>
                    {/* <i className="fa fa-briefcase"></i> */}
                  </div>

                  <div className="card-body row">
                    {/* <form> */}
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="companyName">
                        Company Name
                      </label>
                      <div className="col-12">
                        <input
                          onChange={handleInputChange}
                          defaultValue={formData?.company_name}
                          className="form-control"
                          type="text"
                          name="company_name"
                          // required
                          id="company_name"
                          placeholder=""
                          autoComplete="off"
                        ></input>
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="companyEmail">
                        Company Email
                      </label>
                      <div className="col-12">
                        <input
                          defaultValue={formData.company_email}
                          onChange={handleInputChange}
                          className="form-control"
                          type="email"
                          name="company_email"
                          id="companyEmail"
                          // required
                          placeholder="" autoComplete="off"
                        ></input>
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="companyAddressPrimary">
                        Company Street#
                      </label>
                      <div className="col-12">
                        <input
                          onChange={handleInputChange}
                          defaultValue={formData?.company_address1}
                          className="form-control"
                          type="text"
                          name="company_address1"
                          id="companyAddressPrimary"
                          // required
                          placeholder="Street" autoComplete="off"
                        ></input>
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="companyAddressSecondary">
                       Company City, State
                      </label>
                      <div className="col-12">
                        <input
                          onChange={handleInputChange}
                          defaultValue={formData?.company_address2}
                          className="form-control"
                          type="text"
                          name="company_address2"
                          id="companyAddressSecondary"
                          // required
                          placeholder="City, State" autoComplete="off"
                        ></input>
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="company_zip_code">
                        Company ZipCode
                      </label>
                      <div className="col-12">
                        <input
                          onChange={handleInputChange}
                          defaultValue={formData?.company_zip_code}
                          className="form-control"
                          type="text"
                          name="company_zip_code"
                          id="company_zip_code"
                          // required
                          placeholder="" autoComplete="off"
                        ></input>
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="companyPhone">
                        Company Phone
                      </label>
                      <div className="col-12">
                        {/* <input
                          onChange={handleInputChange}
                          value={formData?.company_phone}
                          className="form-control"
                          type="tel"
                          maxLength="14"
                          name="company_phone"
                          id="companyPhone"
                          // required
                          placeholder="" autoComplete="off"
                        ></input> */}
                         <PhoneInput
                          value={formData.company_phone}
                          country={'us'}
                          onChange={(value, country, e, formattedValue) => handleInputChange(e)}
                          countryCodeEditable={false}
                          inputProps={{
                          name:'company_phone'
                          }}
                        />
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="companyTax">
                        Company Fax
                      </label>
                      <div className="col-12">
                        {/* <input
                          onChange={handleInputChange}
                          value={formData?.company_fax}
                          className="form-control"
                          type="tel"
                          maxLength="14"
                          name="company_fax"
                          id="companyTax"
                          // required
                          placeholder="" autoComplete="off"
                        ></input> */}
                         <PhoneInput
                          value={formData.company_fax}
                          country={'us'}
                          onChange={(value, country, e, formattedValue) => handleInputChange(e)}
                          countryCodeEditable={false}
                          inputProps={{
                          name:'company_fax'
                          }}
                        />
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="companyBusinessNumber">
                        Company Business Number
                      </label>
                      <div className="col-12">
                        {/* <input
                          onChange={handleInputChange}
                          value={formData?.company_business_number}
                          className="form-control"
                          type="tel"
                          maxLength="14"
                          name="company_business_number"
                          id="companyBusinessNumber"
                          // required
                          placeholder="" autoComplete="off"
                        ></input> */}
                         <PhoneInput
                          value={formData.company_business_number}
                          country={'us'}
                          onChange={(value, country, e, formattedValue) => handleInputChange(e)}
                          countryCodeEditable={false}
                          inputProps={{
                          name:'company_business_number'
                          }}
                        />
                      </div>
                    </div>
                    
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="company_gst">
                        Company GST#
                      </label>
                      <div className="col-12">
                        <input
                          onChange={handleInputChange}
                          defaultValue={formData?.company_gst}
                          className="form-control"
                          type="text"
                          name="company_gst"
                          id="companyBusinessNumber"
                          // required
                          placeholder="" autoComplete="off"
                        ></input>
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="company_ntn">
                        Company NTN#
                      </label>
                      <div className="col-12">
                        <input
                          onChange={handleInputChange}
                          defaultValue={formData?.company_ntn}
                          className="form-control"
                          type="text"
                          name="company_ntn"
                          id="company_ntn"
                          // required
                          placeholder="" autoComplete="off"
                        ></input>
                      </div>
                    </div>
                    
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="company_color">
                        Company Color
                      </label>
                      <div className="col-12">
                        <input
                          onChange={handleInputChange}
                          value={formData?.company_color}
                          className="form-control"
                          type="color"
                          name="company_color"
                          id="companyColor"
                          // required
                          placeholder="" autoComplete="off"
                        ></input>
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="company_website">
                        Company Website 
                      </label>
                      <div className="col-12">
                        <input
                          onChange={handleInputChange}
                          defaultValue={formData?.company_website}
                          className="form-control"
                          type="url"
                          name="company_website"
                          id="company_website"
                          // required
                          placeholder="https://example.com"
                          pattern="https://.*" autoComplete="off"
                        ></input>
                      </div>
                    </div>
                      {/* <div className="formField col-md-6 col-12">
                        <label className="col-12" htmlFor="company_bg_image">
                          Background Image
                        </label>
                        <div className="col-12 bussinessLogo">
                          <div className="invoiceLogo my-1">
                            <div className="logoContainer form-control">
                              {showbg === null &&
                                <div className="logolabel">
                                  <i className="fas fa-image"></i>
                                  <span> Image</span>
                                </div>
                              }
                              <input type="file" className="form-control" accept=".png,.jpeg,.jpg,.webp" name="company_bg_image" defaultValue={''} onChange={handleFileInputChange}></input>
                              <span className="showlabel"><i className="fa fa-plus"></i>
                                {showbg === null ? ' Add ' : ' Update '

                                }
                                your logo</span>
                              {showbg !== null &&
                                <span className="removeImage" onClick={removeImageBg}><i className="fa fa-times"></i></span>
                              }
                              {showbg !== null &&
                                <img src={showbg} className="logoImage" alt="test" height={130} width={200} />
                              }
                            </div>
                            <div className="bgImage-warning">
                              <sub>* Default background image cannot be deleted.</sub>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="logo">
                        Company Logo
                      </label>
                      <div className="col-12 bussinessLogo">
                      <div className="invoiceLogo my-1">
                                <div className="logoContainer form-control">
                              { showLogo === null  &&
                                    <div className="logolabel">
                                          <i className="fas fa-image"></i>
                                          <span> Logo</span>
                                    </div>
                                  }
                                  <input type="file" className="form-control" accept=".png,.jpeg,.jpg,.webp" name="logo" defaultValue={''} onChange={handleFileInputChange}></input>
                                  <span className="showlabel"><i className="fa fa-plus"></i>   
                                    { showLogo === null ? ' Add ' : ' Update '
                                      
                                    }  
                                      your logo</span>
                                    {showLogo !== null &&
                                      <span className="removeImage" onClick={removeImage}><i className="fa fa-times"></i></span>
                                      }
                                      {/* <div className="overlay"></div> */}
                                  { showLogo !== null &&
                                  <img src={showLogo} className="logoImage" alt="test" height={130} width={200}/>
                              }
                          </div>  
                                {/* {logoLoader &&
                                    <CustomDeleteloader message="Deleting..."/>
                                } */}
                      </div>
                        {/* <input
                          onChange={handleFileInputChange}
                          className="form-control"
                          type="file"
                          name="logo"
                          id="companyLogo"
                          required
                          placeholder=""
                        ></input> */}
                      </div>
                    </div>
                    <div className="formField col-md-6 col-12">
                      <label className="col-12" htmlFor="company_default_note">
                        Company Default Note
                      </label>
                      <div className="col-12 my-1 company-default-notes">
                        <textarea
                          onChange={handleInputChange}
                          value={formData?.company_default_note}
                          className="form-control"
                          type="text"
                          name="company_default_note"
                          id="companyNote"
                          // required
                          rows={5}
                          placeholder="Note: Thankyou for bussiness." autoComplete="off"
                        ></textarea>
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                  
                </div>
                
              </div>
              <div className="col-12 px-4">
                  <hr></hr>
              </div>
              <div className="col-12">
                <div className="col-12 row justify-content-center">
                  <div className="col-sm-12 col-md-6 col-12">
                    <div className="card-header custom-header">
                      <h5>Tax Settings</h5>
                    </div>
                    <div className="card-body px-2">
                      {/* <form> */}
                      <div className="col-12 taxDisBodyFileds">
                        <div className="col-12 labelCont">
                          <label htmlFor="taxType">Type</label>
                        </div>
                        <div className="col-12">
                          <select
                            onChange={handleInputChange}
                            value={formData?.tax_type}
                            className="form-select"
                            name="tax_type"
                            aria-label="Default select example"
                          >
                            <option value="onTotal">On Total</option>
                            <option value="deducted">Deducted</option>
                            <option value="perItem">Per Item</option>
                            <option value="none">None</option>
                          </select>
                        </div>
                      </div>
                      { formData?.tax_type !== "none" &&(
                        <div className="fadeInOpacity">
                          <div className="col-12 taxDisBodyFileds">
                            <div className="col-12 labelCont">
                              <label htmlFor="taxLabel">Label</label>
                            </div>
                            <div className="col-12 formField">
                              <input
                                onChange={handleInputChange}
                                defaultValue={formData?.tax_label}
                                type="text"
                                className="form-control"
                                name="tax_label" autoComplete="off" placeholder="Tax"
                              ></input>
                              
                            </div>
                          </div>
                          <div className="col-12 taxDisBodyFileds">
                            <div className="col-12 labelCont">
                              <label htmlFor="taxRate">Rate</label>
                            </div>
                            <div className="col-12 formField percent-postfix">
                              <input
                                onChange={handleInputChange}
                                defaultValue={formData?.tax_rate}
                                type="number"
                                className="form-control"
                                name="tax_rate"
                                min={0}
                                max={100}
                                placeholder="0.00%" autoComplete="off"
                              ></input>
                            </div>
                          </div>
                          {/* <div className="col-12 taxDisBodyFileds taxDisBodyFiledsCheckbox ">
                            <div className="col-4 labelCont">
                              <label htmlFor="tax_inclusive">Inclusive?</label>
                            </div>
                            <div className="col-1 formField">
                              <input
                                onChange={handleCheckbox}
                                checked={formData?.tax_inclusive==="true" ? true:false}
                                type="checkbox"
                                id="tax_inclusive"
                                name="tax_inclusive"
                                // checked={formData?.tax_inclusive==="true"?true:false}
                              />
                            </div>
                          </div> */}
                        </div>)
                      }
                      
                    </div>
                        
                  </div>
                  <div className="col-sm-12 col-md-6 col-12">
                          <div className="card-header custom-header">
                          {/* <i className="fa fa-dollar-sign"></i> */}
                            <h5> Invoice & Currency Settings </h5>
                          </div>
                          <div className="card-body px-2">
                          <div className="col-12 taxDisBodyFileds">
                              <div className="col-12 labelCont">
                                <label htmlFor="currency">Currrency</label>
                              </div>
                              <div className="col-12 ">
                                {/* <input type="text" name="currency" onChange={handleBlur} className="form-control" placeholder="USD" defaultValue={formData?.currency}></input> */}
                                {/* <select
                                  onChange={handleInputChange}
                                  value={JSON.stringify(formData?.currency)}
                                  className="form-select p"
                                  name="currency"
                                  aria-label="currency"
                                  style={{'paddingLeft': '35px'}}
                                >
                                  {countriesCurrencies.map((country, index) => {
                                    return (
                                      <option key={index} value={JSON.stringify(country)}>
                                        {country.CountryName} {country.Code} - {country.Symbol}
                                      </option>
                                    );
                                  })}
                                </select>
                                <img src={formData?.currency?.Flag} loading='lazy' alt={formData?.currency?.CountryName} width={25} height='auto' style={{"position":"absolute", "top":"50%","left":"0", "transform": "translate(4px, -50%)","filter":"drop-shadow(2px 1px 4px rgba(0,0,0,0.4))",}}/> */}
                                <SelectImg
                                  options={currencyOption}
                                  handleOnChangeSelect={handleOnChangeSelect}
                                  defaultSelect={JSON.stringify(formData?.currency?.Code)}
                                /> 
                              </div>
                            </div>
                            {/* <form> */}
                            <div className="formField">
                              <label className="col-12" htmlFor="invoice_title">
                                Invoice Title
                              </label>
                              <div className="col-12">
                                <input
                                  onChange={handleInputChange}
                                  defaultValue={formData?.invoice_title}
                                  className="form-control"
                                  type="text"
                                  name="invoice_title"
                                  // required
                                  placeholder=""
                                ></input>
                              </div>
                            </div>
                            <div className="formField">
                              <label className="col-12" htmlFor="invoice_number">
                                Invoice no. Prefix
                              </label>
                              <div className="col-12">
                                <input
                                  onChange={handleInputChange}
                                  defaultValue={formData?.invoice_number}
                                  className="form-control"
                                  type="text"
                                  name="invoice_number"
                                  // required
                                  placeholder=""
                                ></input>
                              </div>
                            </div>
                            <div className="formField">
                              <label className="col-12" htmlFor="quotation_number">
                                Quotation no. Prefix
                              </label>
                              <div className="col-12">
                                <input
                                  onChange={handleInputChange}
                                  defaultValue={formData?.quotation_number}
                                  className="form-control"
                                  type="text"
                                  name="quotation_number"
                                  // required
                                  placeholder=""
                                ></input>
                              </div>
                            </div>
                            <div className="formField">
                              <label className="col-12" htmlFor="salary_number">
                                Salary no. Prefix
                              </label>
                              <div className="col-12">
                                <input
                                  onChange={handleInputChange}
                                  defaultValue={formData?.salary_number}
                                  className="form-control"
                                  type="text"
                                  name="salary_number"
                                  // required
                                  placeholder=""
                                ></input>
                              </div>
                            </div>
                           
                            
                            

                      
                          </div>
                  </div>
                </div>
                <div className="submitContainer">
                    <button type="submit" disabled={buttonLoader} className={`btn btn-save btn-sm p-3 ${buttonLoader === true ? 'cursor-disable':''}`} title="Save Settings"> <span>Save Settings </span>&nbsp;
                    <i className="fas fa-save"></i> 
                    {buttonLoader &&
                        <span className="spinner-container">
                            <CustombuttonSpinner/>            
                        </span>
                    }
                    </button>
                  </div>
              </div>
              
            </div>

            </div>
          </div>
          
        </form>
        <div className="spacer"></div>
        <div className="container">
          <div className="row justify-content-center">

          <div className="col-md-12 col-lg-5">
            
            <div className="card my-3 p-0 earningContainer">
              <div className="card-header">
                <h5>Earnings</h5>
              </div>
              <div className="card-body">
                <div className="addContainer">
                        <button type="button" className="btn btn-sm btn-primary" onClick={handleModalEarnigOpen} title="Add New Earning"><i className="fa fa-plus"></i> Add Earnings</button>
                </div>
                     
                  <div className="earningListing">
                      <AllEarning deleteEarning={confirmationEarning}/>
                  </div>

              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="card my-3 p-0 deductionsContainer">
              <div className="card-header">
                <h5>Deductions</h5>
              </div>
              <div className="card-body">
              <div className="addContainer">
                        <button type="button" className="btn btn-sm btn-primary" onClick={handleModalDeductionOpen} title="Add New Deduction"><i className="fa fa-plus"></i> Add Deductions</button>
                </div>
                        
                  <div className="deductionListing">
                          <AllDeduction deleteDeduction={confirmationDeduction}/>
                  </div>
              </div>
            </div>
          </div>
          </div>

        </div>

       
      </section>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <ReactPortal>
        {isEarningOpen && (
          <Modal
            handleClose={handleModalEarnigClose}
            header="Add Earnings"
            type="form"
            contentType="notscrollable"
            // handleEarnings={handleEarnings}
          >
            <AddEarning handleSubmit={handleSubmitEarnings}/>
          </Modal>
        )}
      </ReactPortal>
      <ReactPortal>
        {isDeductionOpen &&
          <Modal
          handleClose={handleModalDeductionClose}
          header="Add Deductions"
          type="form"
          contentType="notscrollable"
          >
              <AddDecuction handleSubmit={handleSubmitDeduction}/>
          </Modal>
        }
      </ReactPortal>

        {/* earning */}
      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon={"trash-alt text-danger"}
            type="confirm"
            recordName={`Are you sure you want to delete this ${deleteModalInvoice}`}
          >
            <button className="btn btn-danger m-1 col-md-3 col-12" onClick={()=>deleteEarning(true)} title="Yes, delete earning"> Yes</button>
            <button className="btn btn-secondary m-1 col-md-3 col-12" onClick={()=>deleteEarning(false)} title="Do not delete">No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
      <ReactPortal>
        {isConfirmationDeduct && (
          <Modal
            icon={"trash-alt text-danger"}
            type="confirm"
            recordName={`Are you sure you want to delete this ${deleteModalInvoice}`}
          >
            <button className="btn btn-danger m-1 col-md-3 col-12" onClick={()=>deleteDeduction(true)} title="Yes, delete deduction"> Yes</button>
            <button className="btn btn-secondary m-1 col-md-3 col-12" onClick={()=>deleteDeduction(false)} title="Do not delete">No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
    </>
  );
}

export default Settings;
