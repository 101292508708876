import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import moment from "moment/moment";
import { Link, useNavigate } from 'react-router-dom';
import TableLoader from '../../components/loaders/TableLoader';
import { deepCopy } from '../../utils/utils';

function AllContracts({ contracts, isLoading }) {
    // const navigate = useNavigate()
    const [allContracts, setAllContracts] = useState([]);
    const [search, setSearch] = useState("");
    const [filterContract, setFilterContract] = useState([]);


    useEffect(() => {
        const allData = deepCopy(contracts);
        setAllContracts(allData || []);
        setFilterContract(allData || []);
    }, [contracts]);

    const columns = [
        // {
        //     name: "Invoice#",
        //     selector: (row) => row.invoiceNumber,
        //     sortable: true,
        //     width: '125px'
        // },
        {
            name: "From",
            selector: (row) => (<span className="text-capitalize">{row.sending_to_fullname}</span>),
            // sortable: true,
        },
        {
            name: "For",
            selector: (row) => (<span className="text-capitalize">{row.content?.fullName}</span>),
        },

        {
            name: "Accessibility",
            selector: (row) => (row.accessibility ==="opencontract"? 'Open Contract':'Password Protected'),
            // sortable: true,
        },
        {
            name: "Expire",
            selector: (row) => moment(row.expiry_at).format("LL"),
            // sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex gap-1">
                    {/* <ToolTip title='View' position="left"> */}
                    <Link target='_blank' to={`/view-contract/${row.cid}`}
                        className="btn btn-sm listing-button-edits listing-button-simple"

                        title='View'
                    >
                        <i className="fa fa-eye"></i>
                    </Link>

                </div>
            ),
            width: '90px',
        },
    ];


    useEffect(() => {
        const result = allContracts.filter((contract) => {
            return contract?.content?.fullName?.toLowerCase().match(search.toLowerCase());
        });
        setFilterContract(result);
    }, [search, allContracts]);




    return (
        <>
            <div className="py-2">
                {isLoading ? (<><TableLoader /></>) :
                    <div className="table-wrapper" data-aos="fade-right">
                        <DataTable
                            columns={columns}
                            data={filterContract}
                            fixedHeader
                            fixedHeaderScrollHeight="400px"
                            highlightOnHover
                            // progressPending={isLoading}
                            // progressComponent={<CustombuttonSpinner />}
                            pointerOnHover
                            responsive
                            subHeader
                            subHeaderComponent={
                                <div className="col-md-4 col-12">
                                    { filterContract.length > 0 &&
                                        <div className="table-header-custom">
                                            <label className="col-md-3 col-12 text-start" htmlFor="search">Search: </label>
                                            <div className="col-md-9 col-12">
                                                <input
                                                    type="search"
                                                    placeholder="Search by"
                                                    className="form-control"
                                                    value={search}
                                                    autoComplete="off"
                                                    // style={{ width: "28%" }}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    name="search"
                                                    id="search"
                                                ></input>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            // onRowClicked={(row,event)=>{ navigate(`/invoice/edit/${window.btoa(`${row.id}`)}`);}}
                            // subHeaderAlign="left"
                            // selectableRows
                            // selectableRowsHighlight
                            // paginationDefaultPage
                            pagination
                            // paginationPerPage={6}
                            // paginationRowsPerPageOptions={[5,10,15,20,25,30,35,40,45,50]}
                            // paginationIconFirstPage={'|<'}
                            // subHeaderWrap
                            sortIcon={<i className="fa fa-angle-down"></i>}
                        // persistTableHead
                        />
                    </div>
                }
            </div>



        </>
    )
}

export default AllContracts