import React from 'react'

const TableLoader = () => {
  return (
   <div className="table-loader">
        <svg viewBox="25 25 50 50">
            <circle r="20" cy="50" cx="50"></circle>
        </svg>
    </div>
  )
}

export default TableLoader