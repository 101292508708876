import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutUser } from "../slices/userSlice";


const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NODE_ENV === "development" ? process.env.REACT_APP_NODEAPI_STAG : process.env.REACT_APP_NODEAPI_PRODUCTION, //Staging url
  //credentials: "include", // will face CORS if credential is not provided
  prepareHeaders: (headers, { getState }) => {
    const stateToken = getState().auth.token;
    const token = localStorage.getItem("tradingformtoken");

    // If we have a token set in state, let's assume that we should be passing it.
    headers.set("Authorization", `Bearer ${stateToken || token || ""}`);

    // headers.set('Cross-Origin-Opener-Policy', '*');
    // headers.set("Access-Control-Allow-Credentials", "true");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Access-Control-Request-Method", "*");

    return headers;
  },
})

const baseQueryWithSessionCheck = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.data?.message === "Please provide token" || result?.error?.data?.error?.message === "jwt expired") {
    api.dispatch(logoutUser());

  }
  return result
}




const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithSessionCheck,
  // baseQuery: fetchBaseQuery({

  //   baseUrl: process.env.NODE_ENV === "development" ? process.env.REACT_APP_NODEAPI_STAG:process.env.REACT_APP_NODEAPI_PRODUCTION, //Staging url
  //   // baseUrl: "http://localhost:4000/api/v1/",
  //   //mode: "cors",// ==> enable cors here
  //   crendentials: "include", // will face CORS if credential is not provided
  //   //credentials: "same-origin",
  //   prepareHeaders: (headers, { getState }) => {
  //     const stateToken = getState().auth.token;
  //     const token = localStorage.getItem("tradingformtoken");

  //     // If we have a token set in state, let's assume that we should be passing it.
  //     headers.set("Authorization", `Bearer ${stateToken || token || ""}`);

  //     // headers.set('Cross-Origin-Opener-Policy', '*');
  //     headers.set("Access-Control-Allow-Credentials", "true");
  //     headers.set("Access-Control-Allow-Origin", "*");
  //     headers.set("Access-Control-Request-Method", "*");

  //     return headers;
  //   },
  // }),
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  tagTypes: [
    "CLIENTS",
    "ITEMS",
    "INVOICE",
    "SALARYEARNING",
    "SALARYDEDUCTION",
    "SALARY",
    "GROUP_SALARY",
    "GROUP_SALARY_INTERNAL",
    "COMPANY_SETTING",
    "EXPENSE",
  ],

  endpoints: (builder) => ({
    getUserByAuthToken: builder.query({
      query: () => "users/get_user",
    }),

    loginUser: builder.mutation({
      query: (body) => ({
        url: "users/user_login",
        method: "POST",
        body,
      }),
    }),
    getUserBgImage: builder.query({
      query: () => ({
        url: "users/get_user_bg_image"
      })
    }),

    userRegister: builder.mutation({
      query: (body) => ({
        url: "users/user_register",
        method: "POST",
        body,
      }),
    }),

    userSocialLogin: builder.mutation({
      query: (body) => ({
        url: "users/social_login",
        method: "POST",
        body
      })
    }),

    forgetPassowrd: builder.mutation({
      query: (body) => ({
        url: 'users/forget_password',
        method: "POST",
        body,
      })
    }),
    deleteUserAccount: builder.mutation({
      query: () => ({
        url: 'users/user_account_closer_request',
        method: "POST",
      })
    })
    ,
    // global notifications
    globalNotifications: builder.query({
      query: () => "notifications/top_notifications"
    }),
    // ===>> Dashboard graphs count apis
    dashBoardCount: builder.query({
      query: () => "users/get_total_dashbaord_counts",

    }),
    // clients APIs
    //-->> Add/Create SingleClient
    createEntitiesSettings: builder.mutation({
      query: (body) => ({
        url: "users/entities_settings",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CLIENTS"],
    }),

    //-->> getAllClientsListing
    entitiesSettingsAll: builder.query({
      query: () => "users/entities_settings_all",
      providesTags: ["CLIENTS"],
    }),

    //-->> getSingleClient
    singleEntitySettings: builder.query({
      query: (id) => `users/entities_settings_single/${id}`,
    }),

    //-->> UpdateSingleClientRecord
    updateSingleEntitySettings: builder.mutation({
      query: ({ id, ...entityDetails }) => ({
        url: `users/update_entities_settings/${id}`,
        method: "PUT",
        body: entityDetails,
      }),
      invalidatesTags: ["CLIENTS"],
    }),

    //-->> DeleteSingleClientRecord
    deleteEntitiesSettings: builder.mutation({
      query: (id) => ({
        url: `users/delete_entities_settings/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CLIENTS"],
    }),

    // -------items APIs----------

    //-->> Add/Create single item
    addItem: builder.mutation({
      query: (body) => ({
        url: "users/items_settings",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ITEMS"],
    }),

    //-->> getAllItemsListing
    itemsSettingsAll: builder.query({
      query: () => "users/items_settings_all",
      providesTags: ["ITEMS"],
    }),

    //-->> Delete SingleitemsRecord
    deleteItem: builder.mutation({
      query: (id) => ({
        url: `users/items_settings_delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ITEMS"],
    }),
    //-->> get single item by by ID
    getSingleItem: builder.query({
      query: (id) => `users/items_settings_single/${id}`,
    }),

    //-->> Update single Item Record
    updateItem: builder.mutation({
      query: ({ id, ...itemDetail }) => ({
        url: `users/items_settings_update/${id}`,
        method: "PUT",
        body: itemDetail,
      }),
      invalidatesTags: ["ITEMS"],
      // invalidatesTags: (resp,error,args) => args.id ? ["ITEMS"] : [],
    }),

    // ========= Invoicing APIs ==============

    //-->> getAllInvoiceListing
    getAllInvoices: builder.query({
      query: () => "invoice/get_all_invoices",
      providesTags: ['INVOICE'],
    },

    ),

    // -->> unique invoice number
    getInvoiceUniqueNumber: builder.query({
      query: () => ({
        url: 'invoice/get_invoice_count/chargable',
        method: 'GET'
      }),
      invalidatesTags: ["INVOICE"]
    }),

    // -->> unique quotation number
    getQuotationUniqueNumber: builder.query({
      query: () => ({
        url: 'invoice/get_invoice_count/quotation',
        method: 'GET'
      }),
      invalidatesTags: ["INVOICE"]
    }),

    // -->> create new Invoice
    saveInvoices: builder.mutation({
      query: (body) => {
        return {
          url: "invoice/save_all_invoices",
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["INVOICE"],
    }),

    // -->> get single Invoice by ID
    getSingleInvoice: builder.query({
      query: (id) => `invoice/get_single_invoice/${id}`,
    }),

    // -->> delete single Invoice by ID
    deleteSingleInvoice: builder.mutation({
      query: (id) => ({
        url: `invoice/delete_single_invoice/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["INVOICE"]
    }),
    addSinglePayment: builder.mutation({
      query: ({ id, ...payment }) => ({
        url: `invoice/save_invoice_record_payment/${id}`,
        method: "POST",
        body: payment,
      })
    }),
    deleteSingleInvoicePayment: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `invoice/delete_invoice_record_payment/${id}`,
        method: "DELETE",
        body
      })
    }),
    deleteSingleInvoiceLogo: builder.mutation({
      query: (id) => ({
        url: `invoice/delete_single_invoice_photo/${id}`,
        method: "DELETE",
      })
    }),

    //   --> Update single Invoice by ID
    updateInvoice: builder.mutation({
      query: ({ id, updateInvoiceForm: invoiceDetail }) => ({
        url: `invoice/update_single_invoice/${id}`,
        method: "PUT",
        body: invoiceDetail,
      }),
      // invalidatesTags: ["INVOICE"],
    }),
    deleteSingleInvoiceFooterImage: builder.mutation({
      query: (id) => ({
        url: `invoice/delete_invoice_photo/${id}`,
        method: 'DELETE',
      })
    }),
    deleteSingleInvoiceItem: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `invoice/delete_single_invoice_item/${id}`,
        method: "DELETE",
        body
      }),
      // invalidatesTags: ["INVOICE"]
    }),
    duplicateInvoice: builder.mutation({
      query: (id) => ({
        url: `invoice/duplicate_invoice_data/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["INVOICE"]
    }),

    // ========== Salary Slips APIs============================

    //  get all salary_invoice
    getAllSalary: builder.query({
      query: () => "salary_invoice/get_all_salary_invoices",
      providesTags: ["SALARY"]
    }),

    // -->> unique salary number

    // -->> unique salary number
    getSalaryUniqueNumber: builder.query({
      query: () => ({
        url: 'salary_invoice/get_salary_invoice_count',
        method: 'GET'
      }),
      invalidatesTags: ["SALARY"],
    }),

    //  create Salary Slip
    createSalarySlip: builder.mutation({
      query: (body) => ({
        url: "salary_invoice/save_all_salary_invoices",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SALARY"],
    }),

    // getSingleSalarySlip
    getSingleSalarySlip: builder.query({
      query: (id) => `salary_invoice/get_single_salary_invoice/${id}`,
      providesTags: ["SALARY"],
    }),

    // Delete Single Salary
    deleteSingleSalary: builder.mutation({
      query: (id) => ({
        url: `salary_invoice/delete_single_salary_invoice/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SALARY"],
    }),
    deleteSingleSalaryLogo: builder.mutation({
      query: (id) => ({
        url: `salary_invoice/delete_single_salary_invoice_photo/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SALARY"],
    }),
    deleteSalarySingleEarning: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `salary_invoice/delete_invoice_salary_earning_single/${id}`,
        method: "DELETE",
        body
      }),
      // invalidatesTags: ["SALARY"],
    }),
    deleteSalarySingleDeduction: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `salary_invoice/delete_invoice_salary_deduction_single/${id}`,
        method: "DELETE",
        body
      }),
      invalidatesTags: ["SALARY"],
    }),
    // update salary slip 
    updateSalarSlip: builder.mutation({
      query: ({ id, updateSalary: salaryDetail }) => ({
        url: `salary_invoice/update_single_salary_invoice/${id}`,
        method: 'PUT',
        body: salaryDetail,
      }),
      // invalidatesTags: ["SALARY"],
    }),
    duplicateSalarySlip: builder.mutation({
      query: (id) => ({
        url: `salary_invoice/duplicate_salary_invoice_data/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["SALARY"],
    }),

    // ==============END================

    // =================GROUP SALARIES START=============

    // GET ALL GROUP SALARIES
    getAllGroupSalaries: builder.query({
      query: () => ({
        url: `group_salary_invoice/get_all_group_salary_invoices`,
        method: "GET",
      }),
      providesTags: ["GROUP_SALARY"],
    }),

    createSalaryGroup: builder.mutation({
      query: (paySlipGroup) => ({
        url: `group_salary_invoice/save_group_salary_invoice`,
        method: "POST",
        body: paySlipGroup,
      }),
      invalidatesTags: ["GROUP_SALARY"],
    }),

    getSingleSalaryGroup: builder.query({
      query: (id) => ({
        url: `group_salary_invoice/get_single_group_salary_invoice/${id}`,
        method: "GET",
      }),
      providesTags: ["GROUP_SALARY"],
    }),

    updateSingleSalaryGroup: builder.mutation({
      query: ({ id, updatePayslipGroup: groupSalary }) => ({
        url: `group_salary_invoice/update_single_group_salary_invoice/${id}`,
        method: "PUT",
        body: groupSalary,
      }),
      invalidatesTags: ["GROUP_SALARY"],
    }),

    deleteSingleGroup: builder.mutation({
      query: (id) => ({
        url: `group_salary_invoice/delete_single_group_salary_invoices/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GROUP_SALARY"],
    }),

    duplicateSalaryGroup: builder.mutation({
      query: (id) => ({
        url: `group_salary_invoice/duplicate_group_salary_invoice_data/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["GROUP_SALARY"],
    }),

    deleteSingleSalaryGroupLogo: builder.mutation({
      query: (id) => ({
        url: `group_salary_invoice/delete_single_group_salary_invoice_photo/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GROUP_SALARY"],
    }),
    // ---------------------------------------
    // ----Internal group salaries---------
    // ---------------------------------------
    // get all internal salaries listing of a group 
    getAllGroupInternalSalary: builder.query({
      query: (id) => ({
        url: `group_salary_internal_invoice/get_all_group_internal_salary_invoices/${id}`,
        method: "GET",
      }),
      providesTags: ["GROUP_SALARY_INTERNAL"],
    }),

    //  post internal salary Data
    createInternalSalary: builder.mutation({
      query: (saveGroupInternalSalary) => ({
        url: `group_salary_internal_invoice/save_all_group_internal_salary_invoice`,
        method: "POST",
        body: saveGroupInternalSalary,
      }),
      invalidatesTags: ["GROUP_SALARY_INTERNAL"],
    }),
    //  get single salary Data
    getSingleInternalSalary: builder.query({
      query: (id) => ({
        url: `group_salary_internal_invoice/get_single_group_internal_salary_invoice/${id}`,
        method: "GET",
      }),
      providesTags: ["GROUP_SALARY_INTERNAL"],
    }),
    //  update single salary Data
    updateInternalSalary: builder.mutation({
      query: ({ id, updateGroupInternalSalary }) => ({
        url: `group_salary_internal_invoice/update_single_group_internal_salary_invoice/${id}`,
        method: "PUT",
        body: updateGroupInternalSalary,
      }),
      // invalidatesTags: ["GROUP_SALARY_INTERNAL"],
    }),
    //  delete single salary Data
    deleteInternalSalary: builder.mutation({
      query: (id) => ({
        url: `group_salary_internal_invoice/delete_single_group_internal_salary_invoice/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GROUP_SALARY_INTERNAL"],
    }),

    duplicateInternalSalarySlip: builder.mutation({
      query: (id) => ({
        url: `group_salary_internal_invoice/duplicate_single_group_internal_salary_invoice/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["GROUP_SALARY_INTERNAL"],
    }),













    // =================GROUP SALARIES END ================



    // User company settings START
    createCompanySettings: builder.mutation({
      query: (body) => ({
        url: 'users/save_company_settings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ["COMPANY_SETTING"],
    }),
    getCompanySetting: builder.query({
      query: () => "users/get_company_settings",
      providesTags: ["COMPANY_SETTING"],
    })
    ,
    deleteCompanyLogo: builder.mutation({
      query: (id) => ({
        url: `users/delete_company_info_settings_photo/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["COMPANY_SETTING"],
    }),
    deleteCompanybg: builder.mutation({
      query: (id) => ({
        url: `users/delete_company_info_settings_bg_image/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["COMPANY_SETTING"],
    }),


    // ==========company UserSettings END=================

    //  Get all templates
    getInvoiceTemplates: builder.query({
      query: () => "invoice/invoice_templates",
    }),

    //profile settings 
    createProfileSettings: builder.mutation({
      query: (body) => ({
        url: 'users/update_user_profile',
        method: 'POST',
        body,
      })
    }),
    updateUserPassword: builder.mutation({
      query: (body) => ({
        url: `users/update_user_password`,
        method: 'POST',
        body,
      })
    }),
    //  create Earning Additions
    createSalaryEarning: builder.mutation({
      query: (body) => ({
        url: 'salary_invoice/save_salary_addition',
        method: 'POST',
        body,
      }),
      invalidatesTags: ["SALARYEARNING"],
    }),
    getAllEarnings: builder.query({
      query: () => 'salary_invoice/get_all_salary_addition',
      providesTags: ["SALARYEARNING"],
    },
    ),
    deleteSingleEarning: builder.mutation({
      query: (id) => ({
        url: `salary_invoice/delete_salary_addition/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ["SALARYEARNING"],
    }),

    //  DEDUCTION API's Start
    // create  deduction in salary invoice
    createSalaryDeduction: builder.mutation({
      query: (body) => ({
        url: 'salary_invoice/save_salary_deduction',
        method: 'POST',
        body,
      }),
      invalidatesTags: ["SALARYDEDUCTION"]
    }),

    getAllDeductions: builder.query({
      query: () => 'salary_invoice/get_all_salary_deduction',
      providesTags: ["SALARYDEDUCTION"]
    },
    ),
    deleteSingleDeduction: builder.mutation({
      query: (id) => ({
        url: `salary_invoice/delete_salary_deduction/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["SALARYDEDUCTION"]
    }),

    // Search Clients
    clientsNameSearch: builder.query({
      query: (name) => `users/search_entities_settings_names?search_query=${name}`
    }),
    // get single client data 
    getClientDataById: builder.query({
      query: (id) => `users/search_entities_settings_all?search_query=${id}`
    }),

    // search items/products
    itemByNameSearch: builder.query({
      query: (item) => `users/search_items_settings_names?search_query=${item}`
    }),
    //  get single item/product data
    getItemDataById: builder.query({
      query: (itemId) => (`users/search_items_settings_all?search_query=${itemId}`)
    }),

    // search all earnings
    earnNameSearch: builder.query({
      query: (search) => `salary_invoice/search_salary_addition?search_query=${search}`
    }),

    //search all deduction 
    deductionNameSearch: builder.query({
      query: (search) => `salary_invoice/search_salary_deduction?search_query=${search}`
    }),

    // email history 
    emailHistory: builder.query({
      query: (type) => `emails/user_all_time_emails_sent_history?email_type=${type}`
    }),

    // reports
    reports: builder.query({
      query: ({report_type,report_class,year,download}) =>({
        url:`reports/user_all_time_invoices_reports?report_type=${report_type}&report_class=${report_class}&year=${year}&downloadable=${download}`,
        method: "GET",
        // headers: ()=>({
        //    responseType: "blob"

        // })
      })
    }),

    // Expenses
    getAllExpenses: builder.query({
      query:({month,year})=>`expensesreports/get_all_expenses_report?months=${month}&year=${year}&download=no`,
      providesTags: ["EXPENSE"]
    }),

    createExpense: builder.mutation({
      query: (body)=>({
        url:`expensesreports/add_new_expenses_report`,
        method:"POST",
        body
      }),
      invalidatesTags: ['EXPENSE'],
    }),
    getSingleExpense: builder.query({
      query: (id)=>  `expensesreports/get_single_expense_report/${id}`,
    }),
    updateSingleExpense: builder.mutation({
      query: ({id,expenseData})=>({
        url:`/expensesreports/update_expenses_report/${id}`,
        method: "PUT",
        body:expenseData
      }),
      invalidatesTags: ['EXPENSE'],

    }),
    deleteSingleExpense: builder.mutation({
      query: ({id})=>({
        url:`/expensesreports/delete_single_expense_report/${id}`,
        method : 'DELETE' ,
      }),
      // invalidatesTags: ['EXPENSE'],
    }),
    deleteExpenseReceiptImage: builder.mutation({
      query: ({id})=>({
        url:`/expensesreports/delete_single_expense_image_report/${id}`,
        method :"DELETE",
      }),
      //      invalidatesTags:['RECEIPT']
    })
  })
});



export const {
  useGetUserByAuthTokenQuery,
  useLoginUserMutation,
  useUserSocialLoginMutation,
  useLazyGetUserBgImageQuery,
  useUserRegisterMutation,
  useForgetPassowrdMutation,
  useDeleteUserAccountMutation,
  useGlobalNotificationsQuery,
  useDashBoardCountQuery,
  useCreateEntitiesSettingsMutation,
  useEntitiesSettingsAllQuery,
  useSingleEntitySettingsQuery,
  useLazySingleEntitySettingsQuery,
  useUpdateSingleEntitySettingsMutation,
  useDeleteEntitiesSettingsMutation,
  useAddItemMutation,
  useItemsSettingsAllQuery,
  useDeleteItemMutation,
  useGetSingleItemQuery,
  useLazyGetSingleItemQuery,
  useUpdateItemMutation,
  useGetAllInvoicesQuery,
  useLazyGetAllInvoicesQuery,
  useGetInvoiceUniqueNumberQuery,
  useLazyGetInvoiceUniqueNumberQuery,
  useSaveInvoicesMutation,
  useLazyGetSingleInvoiceQuery,
  useGetSingleInvoiceQuery,
  useDeleteSingleInvoiceLogoMutation,
  useDeleteSingleInvoiceMutation,
  useDeleteSingleInvoiceFooterImageMutation,
  useDeleteSingleInvoicePaymentMutation,
  useAddSinglePaymentMutation,
  useUpdateInvoiceMutation,
  useDuplicateInvoiceMutation,
  useDeleteSingleInvoiceItemMutation,
  // useEmailInvoiceMutation,
  useGetAllSalaryQuery,
  useLazyGetAllSalaryQuery,
  useGetSalaryUniqueNumberQuery,
  useLazyGetSalaryUniqueNumberQuery,
  useCreateSalarySlipMutation,
  useLazyGetQuotationUniqueNumberQuery,
  useDeleteSalarySingleEarningMutation,
  useDeleteSalarySingleDeductionMutation,
  useDuplicateSalarySlipMutation,
  useGetQuotationUniqueNumberQuery,
  useLazyGetSingleSalarySlipQuery,
  useGetSingleSalarySlipQuery,
  useDeleteSingleSalaryMutation,
  useDeleteSingleSalaryLogoMutation,
  useUpdateSalarSlipMutation,
  // 
  useCreateSalaryGroupMutation,
  useGetAllGroupSalariesQuery,
  useLazyGetSingleSalaryGroupQuery,
  useGetSingleSalaryGroupQuery,
  useUpdateSingleSalaryGroupMutation,
  useDeleteSingleGroupMutation,
  useDuplicateSalaryGroupMutation,
  useDeleteSingleSalaryGroupLogoMutation,
  // 
  useGetAllGroupInternalSalaryQuery,
  useCreateInternalSalaryMutation,
  useUpdateInternalSalaryMutation,
  useLazyGetSingleInternalSalaryQuery,
  useDeleteInternalSalaryMutation,
  useDuplicateInternalSalarySlipMutation,
  // 
  useCreateCompanySettingsMutation,
  useGetCompanySettingQuery,
  useLazyGetCompanySettingQuery,
  useDeleteCompanyLogoMutation,
  useDeleteCompanybgMutation,
  useCreateProfileSettingsMutation,
  useUpdateUserPasswordMutation,
  useCreateSalaryEarningMutation,
  useGetInvoiceTemplatesQuery,
  useLazyGetInvoiceTemplatesQuery,
  useGetAllEarningsQuery,
  useDeleteSingleEarningMutation,
  useCreateSalaryDeductionMutation,
  useGetAllDeductionsQuery,
  useDeleteSingleDeductionMutation,
  // searches
  useLazyClientsNameSearchQuery,
  useLazyGetClientDataByIdQuery,
  useLazyItemByNameSearchQuery,
  useLazyGetItemDataByIdQuery,
  useLazyEarnNameSearchQuery,
  useLazyDeductionNameSearchQuery,
  //history
  useLazyEmailHistoryQuery,
  // reports
  useLazyReportsQuery,
  // expenses
  useGetAllExpensesQuery,
  useLazyGetAllExpensesQuery,
  useCreateExpenseMutation,
  useLazyGetSingleExpenseQuery,
  useUpdateSingleExpenseMutation,
  useDeleteSingleExpenseMutation,
  useDeleteExpenseReceiptImageMutation,
} = baseApi;
export default baseApi;
