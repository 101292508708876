import React from "react";

function PhotoDetails({ addedImage, index, handleClose, onImage,footerModalInputId, handleSubmit }) {

// console.log(addedImage);
  return (
    <>
      <div className="photoDetail">
        <img
          src={addedImage?.img_filename}
          alt="addedimage"
          width={"100%"}
          height={"auto"}
        ></img>
        <div>
            <form onSubmit={(e)=>handleSubmit(e,footerModalInputId)}>
                {/* ====<label htmlFor="image_title" className="mt-2">Title</label> */}
                <input
                    type="text"
                    className="form-control my-2"
                    maxLength={60}
                    name="img_description"
                    defaultValue={addedImage?.img_description}
                    placeholder="Description"
                    autoComplete="off"
                ></input>
                {/* ===<label htmlFor="photo_detail"></label> */}
                <textarea className="form-control my-2" name="img_additional_notes" defaultValue={addedImage?.img_additional_notes} rows="4" maxLength={150} cols="" placeholder="Addintional Details" autoComplete="off"></textarea>
                <button type="submit" className="btn btn-save">Upload Photo</button>
                <button type="button" className="btn" onClick={()=>handleClose(footerModalInputId)}>Cancel</button>
            </form>

        </div>


      </div>
    </>
  );
}

export default PhotoDetails;
