import { useState, useEffect } from "react";
import React from "react";
import "./Items.css";
import {
  useAddItemMutation,
  // useGetSingleItemQuery,
  useLazyGetSingleItemQuery,
  useUpdateItemMutation,
} from "../../store/apis/baseApi";
import { useNavigate, useParams } from "react-router";
import { errorMessage, successMessage } from "../toastMessages/ToastifyMsg";
import CustomDeleteloader from "../CustomDeleteloader";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPlanDetails } from "../../store/slices/subscribedPlanDetails";

const CreateItems = (props) => {
  const [addItem] = useAddItemMutation();
  const { itemId } = useParams();

  const dispatch = useDispatch();
  const productsPlanDetail = useSelector(state=>state.planDetails);
  
  let urlId ;
  if (itemId) {
    urlId = window.atob(itemId);
  }
  const [getSingleItem] = useLazyGetSingleItemQuery();
  const [loader,setLoader] = useState(false);
  const [postData,setPostData] = useState(false);
  const navigate = useNavigate();
  const [updateItem] = useUpdateItemMutation();
  const [formData, setFormData] = useState({
    item_type: "physical",
    item_name: "",
    description: "",
    item_rate: 0.00,
    item_texable: "false",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked === true ? "true" : "false",
    }));
  };

  useEffect(() => {
    if (props.updating) {
      setLoader(true);
      (async () => {
        const { data, error } = await getSingleItem(urlId);

        if (data) {
          if (data.success) {
            let itemData = data.data;
            setFormData({
              id:urlId,
              item_type: itemData.item_type,
              item_name: itemData.item_name,
              description: itemData.description,
              item_rate: itemData.item_rate,
              item_texable: itemData.item_texable,
            });
          }
          if (!data.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
          // console.log("Error", error);
          errorMessage(error?.error ?? "Not saved.");
        }
        setLoader(false);
      })();
      
    }
  }, [itemId,props.updating,getSingleItem,urlId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // do something with the form data
    setPostData(true);
    if (props.updating) {
      // console.log("FORM",formData);
      const { data, error } = await updateItem(formData);
      if (data) {
        if (data.success) {
          setPostData(false);
          successMessage(data.message);
          navigate("/items");
        }
        if (!data.success) {
          setPostData(false);
          errorMessage(data.message);
        }
        // console.log("Data", data.message);
      }

      if (error) {
        setPostData(false);
        console.log("ERROR:", error?.data?.message);
      }
    } else {
      // console.log("body",formData);
      const { data, error } = await addItem(formData);
      if (data) {
        if (data.success) {
          // console.log("Data", data.message);
          setPostData(false);
          successMessage(data.message);
          const newPlanDetails = JSON.parse(JSON.stringify(productsPlanDetail?.planDetails));
            newPlanDetails.plan_usage_details.products.usage_count += 1;
            setTimeout(() => {
              dispatch(setPlanDetails(newPlanDetails));
            }, 10);
          navigate("/items");
        }
        if (!data.success) {
          errorMessage(data.message);
          setPostData(false);
        }
      }

      if (error) {
        setPostData(false);
        console.log("ERROR:", error?.data?.message);
      }
    }
  };

  return (
    
    <div className="createitems-container">
       { loader &&
        <CustomDeleteloader message="Loading . . ."/>
    }
       {postData &&
        <CustomDeleteloader message={`${itemId ? 'Updating . . .': 'Adding . . .'}`}/> 
      }
      <div className="container">
      <div className="spacer"></div>
      <div className="productsBreadcrumbs">
        
            <div className="breadcrumb-container">
              
              <nav className="breadcrumbs">
                <Link to="/" className="breadcrumbs__item">Dashboard</Link>
                <Link to="/items"  
                // onClick={navigateTo} 
                className="breadcrumbs__item">Products</Link> 
                {/* <Link to="" className="breadcrumbs__item">Cart</Link> */}
                <span  className="breadcrumbs__item is-active"> {props.updating ? "Edit":"Create"}</span> 
              </nav>  <div className="spacer"></div>
            </div>
      </div>
      </div>
      <form className="Items-container container" onSubmit={handleSubmit}  data-aos="zoom-in">
        <div className="card-header heading-card-2 ">
          <h3 className="text-center">{props.updating ? "Update":"Add"}  Product</h3>
        </div>
        <div className="items-group-fields">
          <div className="group-label select-custom">
            <label className="col-3" htmlFor="item_type">Product Type <span className="text-danger">* </span></label>
            <select
              name="item_type"
              id="item_type"
              value={formData.item_type}
              onChange={handleInputChange}
              className="form-select"
            >
              {/* <option value="">Select an option</option> */}
              <option value="physical">Physical (default)</option>
              <option value="digital">Digital</option>
              {/* <option value="option3">Physical 3</option> */}
            </select>
          </div>
          <div className="group-label">
            <label className="col-3" htmlFor="item_name">Name <span className="text-danger">* </span></label>
            <input
              type="text"
              name="item_name"
              id="item_name"
              value={formData.item_name}
              onChange={handleInputChange}
              className="form-control"
              required
              autoComplete="off"
            />
          </div>

          <div className="group-label">
            <label className="col-3" htmlFor="description">Description </label>
            <textarea
              rows="4"
              cols="60"
              name="description"
              id="description"
              value={formData.description}
              onChange={handleInputChange}
              className="form-control"
              autoComplete="off"
            />
          </div>
          <div className="group-label">
            <label className="col-3" htmlFor="item_rate">Rate <span className="text-danger">* </span></label>
            <input
              type="number"
              name="item_rate"
              id="item_rate"
              value={formData.item_rate}
              onChange={handleInputChange}
              className="form-control"
              required
              autoComplete="off"
            />
          </div>
          <div className="formfield group-label">
            <label htmlFor="item_texable" className="col-3">Taxable</label>
            {/* <label> */}
            <div className="col-9">
              <input
                type="checkbox"
                name="item_texable"
                id="item_texable"
                checked={formData.item_texable === "true" ? true : false}
                onChange={handleCheckboxChange}
                  // required
              />

            </div>
            {/* </label> */}
          </div>

          <div style={{ textAlign: "end" }}>
            <button type="submit" className={`btn btn-sm btn-save ${formData?.item_type !=="" && formData?.item_name !=="" && formData?.item_rate !=="" ? "":"cursor-disable"}`}
            title={formData?.item_type !=="" && formData?.item_name !=="" && formData?.item_rate !=="" ? "Click to save.":"Please fill all required fields."}
              disabled={formData?.item_type !=="" && formData?.item_name !=="" && formData?.item_rate !=="" ? false:true}
            > {itemId ? "Update":"Save"} &nbsp; <span><i className="fas fa-save"></i></span></button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateItems;
