import React from 'react'

function InvoiceNotes({formData,handleBlur}) {

  return (
    <>
        <div className="col-12 invoiceFooter fadeInOpacity">

          <div className="col-12 notesContainer">
            <div className="header">Notes</div>
            <textarea rows="5" className="form-control col-12" name='ending_notes' onChange={(e)=>handleBlur(e)} aria-label="With textarea"  defaultValue={formData?.ending_notes} placeholder='Notes - any releavant information not covered, additional terms and conditions'></textarea>
          </div>
          {/* <div className="invoiceFooterImage col-4">
              <div className="header">Photos</div>
              
                  <div className="invoiceLogo">
                            <input type="file" accept=".png,.jpge" name="" onBlur={(e)=>handleBlur(e)}></input>
                            <div className="logoNote">+ Add Logo</div>
                        </div>
                  
          </div> */}
        </div>
    </>
  )
}

export default InvoiceNotes