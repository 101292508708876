import React from 'react'
import { Markup } from 'react-render-markup'

function ModalContainer({handleClose, header, children,contentType}) {
  return (
    <>
         <div className="modal-container fadeInOpacity" style={{  transform: `${contentType==="notscrollable"?'translate(0, 70%)': contentType==='email-form'?'translate(0, 30%)':''}`}}>
                <div className="modal-Header">
                    <h5> <Markup markup={header}/></h5>
                    { handleClose &&
                      <button onClick={handleClose} className="btn close-btn"><i className="fa fa-times"></i></button>
                    }
                </div>
                <div className="modal-body">{children}</div>
            </div>

    </>
  )
}

export default ModalContainer