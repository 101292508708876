import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import Page from './Page'
// import { suffix } from '../../../utils/utils';
// import moment from 'moment/moment';
import BusinessDedicated from './contractTypes/BusinessDedicated';
import WirelessPdf from './contractTypes/WirelessPdf';
import VoipPdf from './contractTypes/VoipPdf';

function Document({contractContent,url,documentRef,companyPro,}) {
    const [content,setContent] = useState({})
    const date = new Date();
    useEffect(() => {
        if (contractContent) {
            setContent(contractContent);
        }
    }, [contractContent])
    // console.log(content)
    return (
        <Page id={'documentToPrint'} documentRef={documentRef} >
            <div className="main-contract" style={{ 'margin': '30px 50px', 'textAlign': 'left','fontSize': '11px','fontFamily': 'Arial' }}>
                {   (content?.contractType === "business_internet" || content?.contractType ==="dedicated_internet" ) &&
                    <BusinessDedicated content={content} url={url} companyPro={companyPro}  contractContent={contractContent} date={date}/>
                }

                {content?.contractType === "wireless_internet" &&
                       <WirelessPdf content={content} url={url} companyPro={companyPro}  contractContent={contractContent} date={date}/>
                }
                
                {content?.contractType === "voip_voice" && 
                    <VoipPdf
                        content={content} url={url} companyPro={companyPro}  contractContent={contractContent} date={date}
                    />
                }

            </div>
        </Page>
    )
}

export default Document