import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useEffect } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

const emptyDoughnutPlugin = {
  id: 'emptyDoughnut',
  afterDraw(chart, args, pluginOptions) {
    const { datasets } = chart.data;
    const { color, width, radiusDecrease } = pluginOptions;
    let hasData = false;

    for (let i = 0; i < datasets.length; i += 1) {
      const dataset = datasets[i];
      if (dataset.data.some(value => value > 0)) {
        hasData = true;
        break;
      }
    }

    if (!hasData) {
      const { chartArea: { left, top, right, bottom }, ctx } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || 'rgba(154, 165, 255, 0.7)';
      ctx.arc(centerX, centerY, r - (radiusDecrease || 0), 0, 2 * Math.PI);
      ctx.stroke();
      ctx.restore();
    }
  }
};

function Donut({ data}) {
//   console.log("donut>>",data);
useEffect(() => {
  

    // console.log("donut>>>", data);
  
}, [data])

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        render: 'value:data',
        fontColor: '#fff',
        precision: 0,
        fontSize: 10,
        fontStyle: 'bold',
        position: 'outside',
        outsidePadding: 4,
        textMargin: 4,
        overlap: true,
        showZero: true,
        callbacks: function (value) {
          return '<span style="color: red">' + value + '</span>';
        },
        usePointStyle: true,
        font: {
          size: 10,
          color: '#151B27',
        },
      },
    },
    responsive: true,
  },
  // title: {
  //   display: true,
  //   text: "test", // Use the provided 'title' prop
  //   position: 'top', // Adjust as needed
  //   fontSize: 16, // Adjust as needed
  //   fontColor: 'black', // Adjust as needed
  // },
};




  return (
    <>
      <div className="col-sm-6 col-lg-4 col-12 py-1" data-aos={data.animation}>
        <div className="donut-card">
          {data && (
            <>
              <h5 className="title">{data.label}</h5>
              <div className="doughnut-chart-wrapper">
                <Doughnut
                  // data={data}
                  data={{
                    labels: data.labels,
                    datasets: [
                      {
                        label: data.label,
                        data: data.data,
                        backgroundColor: data.backgroundColor,
                        hoverBackgroundColor: data.hoverBackgroundColor,
                        // hoverBorderColor: "rgba(234, 236, 244, 1)",
                        cutout: '65%',
                        hoverOffset: 20,
                        borderColor: "transparent",
                        // circumference:180,
                        // clip: {left: 5, top: false, right: -2, bottom: 0}
                      },
                    ],
                  }}

                  options={options}
                  plugins={[emptyDoughnutPlugin]}
                />
              </div>
            </>
          )}
          {/* <div className="donut-footer">
            {
              data.titles.map((data,index)=>{
                return(
                  <div className="donut-footer-rec" key={index}><p> <i className={`fa ${data?.icon}`} style={{'color': `${data?.color}`}}></i> {data?.title}: {data?.count}</p></div>
                )
              })
            }
          </div> */}

        </div>
      </div>
    </>
  );
}

export default Donut;
