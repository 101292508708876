import React, { useEffect, useRef, useState } from 'react'
import './SignaturesFonts.css';


// , 'Meddon','Poppins', 'WalterTurncoat', 'MonsieurLaDoulaise',  'LoversQuarrel', 'Megrim', 'CraftyGirls', 'DeliusSwashCaps',

const fontFamily = ['Arial', 'Alfie', 'Brittney', 'Sagrantino', 'AguafinaScript', 'AlexBrush', 'AnnieUseYourTelescope', 'Bilbo', 'BilboSwashCaps',
    'ButterflyKids', 'Calligraffitti', 'ClickerScript', 'Courgette', 'Devonshire', 'Felipa', 'GiveYouGlory', 'GrandHotel',
    'GreatVibes', 'JimNightshade', 'Kristi', 'LaBelleAurore', 'Macondo', 
    'MrsSaintDelafield', 'Niconne', 'OleoScriptSwashCaps', 'Qwigley', 'ReenieBeanie', 'RugeBoogie', 'SedgwickAve', 'Sevillana', 'ShadowsIntoLight',
    'Stalemate', 'UnifrakturMaguntia', 'Vibur', 'Zeyada'];


async function generateSignatureImage(name,font,color) {
    // let imgUrl = [];
   
    // for (let index = 0; index < fontFamily.length; index++) {
        const canvas = document.createElement('canvas');
        canvas.width = 360;
        canvas.height = 61;
        const ctx = canvas.getContext('2d');
    
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.textBaseline = 'middle';
        ctx.font = `35px ${font}`;
        // ctx.textAlign = 'center';
        // ctx.lineWidth = 20;
        ctx.fillStyle = 'transparent';
        ctx.fillRect(0, 0, 360, 61);
        ctx.fillStyle = color;
    
        // Measure the width of the text.
        const textWidth = ctx.measureText(name).width;

        // Center the text on the canvas.
        ctx.fillText(name, 170 - textWidth / 2, 30);
    
        // imgUrl.push(canvas.toDataURL());
    // }
    return canvas.toDataURL()
    // return imgUrl

}

function AutoSignature({ client,handleSaveSignature,selectedColor,handleChangeFileSig }) {
    // const [signature, setSignature] = useState(null);
    const [name, setName] = useState(`${client.client_first_name} ${client.client_last_name}`);
    const [fontColor,setFontColor] = useState("black");
    const [selectedFont,setSelectedFont] = useState('Arial')

    const ref = useRef();


    const generateSignature = async (name) => {
        if (name.trim()) {
            const base64String = await generateSignatureImage(name, selectedFont,fontColor);
            // setSignature(base64String);
            handleSaveSig(base64String);
        }else{
            // alert('Please enter your name!')
            handleSaveSig(false);

        }
    };

    useEffect(() => {
        (async()=>{
            if (name) {
                setFontColor(selectedColor);
                const base64String = await generateSignatureImage(name,selectedFont,selectedColor);
                // setSignature(base64String);
                handleSaveSig(base64String);
                
            }
        })()

    }, [selectedColor])


    // useEffect(() => {
    //     setTimeout(() => {
    //         (async()=>{
    //             setName(client);
    //             const base64String = await generateSignatureImage(defaultName);
    //             setSignature(base64String);
    //         })()
    //     }, 100);
    // }, [defaultName])

    const handleChangeChecked = async(font)=>{
        if (name.trim()) {
            setSelectedFont(font);
            const base64String = await generateSignatureImage(name,font,fontColor);
            // setSignature(base64String);
            handleSaveSig(base64String);
            
        }
    }

    // const handleChangeColor = async(color)=>{
    //     setFontColor(color);
    //     const base64String = await generateSignatureImage(name,selectedFont,color);
    //     setSignature(base64String)
    // }

    // const handleAutoGenSig = async (font)=>{
    //     setSelectedFont(font);
    //     const base64String = await generateSignatureImage(name,font,fontColor);
    //     setSignature(base64String)
    // }

    const handleSaveSig = (base64String)=>{
        // if (base64String) {
            handleChangeFileSig(base64String);
        // }
    }
    return (
        <>   
            <div className="sign-input-wrapper">
                <div className='sign-input-inner-wrapper'>
                   
                    <div className="col-12">
                        <input className="form-control" type="text" autoComplete={"off"} placeholder='Type Name' id='name' name='name' value={name} onChange={e => {generateSignature(e.target.value); setName(e.target.value)}} />
                    </div>
                </div>
              

                
            </div>
            {/* <div className="font-color-wrapper">
                <div><span>Select Color</span></div>
                <div className='color-select-btn-container'>
                    <button style={{'backgroundColor':'black'}} onClick={()=>handleChangeColor('black')}>{fontColor === "black" && <i className="fas fa-check"></i>}</button>
                    <button style={{'backgroundColor':'blue'}} onClick={()=>handleChangeColor('blue')}>{fontColor === "blue" && <i className="fas fa-check"></i>}</button>
                    <button style={{'backgroundColor':'red'}} onClick={()=>handleChangeColor('red')}>{fontColor === "red" && <i className="fas fa-check"></i>}</button>
                    <button style={{'backgroundColor':'#1d3363'}} onClick={()=>handleChangeColor('#1d3363')}>{fontColor === "#1d3363" && <i className="fas fa-check"></i>}</button>
                    <button style={{'backgroundColor':'green'}} onClick={()=>handleChangeColor('green')}>{fontColor === "green" && <i className="fas fa-check"></i>}</button>

                </div>

            </div> */}
            <div className="auto-sign-container">
                <div className="auto-sign-inner-container" ref={ref}>
                    {
                    // signature.length >0 ? (
                        name.trim()  ? (
                        fontFamily.map((font,index ) => {
                            return (
                                <div className='single-signature-container' key={index}>
                                    <input type="radio" name='sign' id={font} checked={selectedFont===font &&true} onChange={e =>handleChangeChecked(font)}/>
                                    <label htmlFor={font} className="single-signature"  >
                                                <div className="single-signature-wrapper">
                                                    {/* <img id={index}  src={`${sign}`} alt="Signature" /> */}
                                                    <span style={{'fontFamily':font,'color':fontColor}}>{name}</span>
                                                </div>
                                                {/* <div className='w-100'>
                                                    <button className='btn btn-save w-100' type='button' onClick={()=> handleSaveAutoGenSig(sign)}>Use</button>
                                                </div> */}
                                        </label>
                                </div> 
                                )
                        }))
                        : 
                        <>
                            <div className='col-12 d-flex align-items-center justify-content-center opacity-75'> 
                              <i>Type Your Name . . .</i>
                            </div>
                        </>
                        // :<>
                        //    <div className='col-12 d-flex align-items-center justify-content-center'>
                        //     <TableLoader/>  
                        //    </div>
                        // </>

                    }
                </div>
                
                {/* <div className="mt-1 text-end">
                    <button className={`btn btn-primary ${!signature?'cursor-disable pointer-disable':''}`} type='button' disabled={!signature} onClick={handleSaveSig}><b> Save</b></button>
                </div> */}

            </div>
            {/* <img src={signature} alt='sig'/> */}
        </>
    )
}

export default AutoSignature