import React, {useEffect, useState} from 'react'
import './profile.css'
import { useCreateProfileSettingsMutation } from '../store/apis/baseApi';
import { useDispatch } from 'react-redux';
import { errorMessage, successMessage } from '../components/toastMessages/ToastifyMsg';
import CustombuttonSpinner from '../components/CustombuttonSpinner';
import { setUser } from '../store/slices/userSlice';
import { deepCopy } from '../utils/utils';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'


const Profile = ({auth}) => {
  const [createProfileSettings] = useCreateProfileSettingsMutation();
  const [loader,setLoader] = useState(false);
  const authw = auth;
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    phone:"",
  });


  useEffect(() => {
    setFormData({
      name:   auth?.name || "",
      phone:  auth?.phone || "",
      email:  auth?.email || "",
      type:   auth?.type || "",
    })

  }, [auth])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "name" || name ==="phone") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      
    }
  };

  const handleSubmit = async (event) =>{
    setLoader(true);
    event.preventDefault();
    let body = {
      name: event.target.name.value,
      phone:event.target.phone.value,
    }
    if (body.name !=="" || body.phone !=="") {
      try {
        const {data,error} = await createProfileSettings(body);
        if(data){
            if (data.success) {
              setFormData((prevState)=>{
                return {
                  ...prevState,
                  name:event.target.name.value,
                  phone: event.target.phone.value,
                }
              })
              let auth = deepCopy(authw);
              auth.name=event.target.name.value;
              auth.phone = event.target.phone.value;
              dispatch(setUser(auth));
              successMessage(data.message);
            }
            if (!data.success) {
              errorMessage(data.message);
            }
        }
        if(error){
          // console.log(error)
          errorMessage(error.data.errors[0].message+error.data.errors[1].message ?? error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
        }
        
      } catch (error) {
        // console.log("catchError>",error);
      }
    } else {
        errorMessage("Please Enter Name or Phone");
    }
    setLoader(false);
    // location.reload();
   }
  return (
    <>
    {/* <div className='heading-profile'> <h4>Edit Profile</h4></div> */}
   
    <form className="input-fields profile-form" onSubmit={handleSubmit}>
      
      <div className="form-feild">
        <div className="form-field-inner">
          <label htmlFor="name" className="col-sm-12 col-md-2 col-12">Name</label>
          <div className="col-sm-12 col-md-8 col-12">
            <input className='form-control' type="text" id="name" name="name" defaultValue={formData.name} onChange={handleInputChange} required/>
          </div>

        </div>
      </div>
      <div className="form-feild">
        <div className="form-field-inner">
          <label className='col-sm-12 col-md-2 col-12' htmlFor='email'>Email</label>
          <div className='col-sm-12 col-md-8 col-12'>
            <input  type="email" id="email" className="cursor-disable form-control" disabled name="email" defaultValue={formData.email} readOnly/>
          </div>
        </div>
      </div>

      <div className="form-feild">
        <div className="form-field-inner">
          <label className='col-sm-12 col-md-2 col-12' htmlFor='phone'>Phone</label>
          {/* <input className='form-control' type="tel"  name="phone" maxLength="14" value={formData.phone} onChange={handleInputChange} required/> */}
          <div className='col-sm-12 col-md-8 col-12'>
            <PhoneInput
                value={formData?.phone}
                country={"us"}
                countryCodeEditable={false}
                onChange={(value, country, e, formattedValue)=>handleInputChange(e)}
                inputProps={{name:'phone'}}
              />
          </div>
        </div>

      </div>
      <div className="form-feild">
        <div className="form-field-inner">
          <label htmlFor='type' className="col-sm-12 col-md-2 col-12">Type</label>
          <div className="col-sm-12 col-md-8 col-12">
            <input type="text"  className="text-capitalize cursor-disable form-control" disabled id='type' name="type" defaultValue={formData.type} onChange={handleInputChange} readOnly/>
          </div>
        </div>
      </div>
      {/* <div className="input-wrapper">
        <p>Confirm Password</p>
        <input type="password" id="confirm_password" name="confirm_password" defaultValue={formData.confirm_password} onChange={ handleInputChange} />
      </div> */}
      <div className="text-center">
        <div className="col-12">
            <button type="submit" className={`btn btn-save ${formData?.name.length > 0  && formData?.phone.length > 14 ? '':'cursor-disable'}`} disabled={formData?.name.length > 0  && formData?.phone.length > 14 ? false:true}> 
            
            <div className="submitbuttonInnerContainer">
              <div className="button-container">
              <i className="fas fa-save"></i> <span> Update Profile</span>
              </div> 
            {loader===true &&
                  <div className="spinner-container">
                                <CustombuttonSpinner/>            
                            </div>}
            </div> </button>

        </div>
      </div>
    </form></>
  )
}

export default Profile