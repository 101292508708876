import React from "react";
import { IMG_URL } from "../common/common";

import { Accordion } from "react-bootstrap";

function Usage() {
  const imgUrl = IMG_URL;
  
  return (
    <>
      <div className="usage-container" >
        <h2 className="section-usage-header">
          How to make an invoice and quotation using the
          <span className="pos-rel"> TradingForms</span> invoice and quotation generator
        </h2>
        <Accordion flush>
          <Accordion.Item eventKey="0" className="border-0">
            <Accordion.Header className="accordion-header-custom">How to make an invoice and quotation?</Accordion.Header>
            <Accordion.Body>
              <div className="usage-list">
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="usage-step">
                      <h5 className="usage-step-title">
                        How to make an invoice and quotation
                      </h5>
                      <p>
                        This page provides a comprehensive guide on creating an invoice using the TradingForms Invoice Generator.
                        Whether you're a freelancer, small business owner, or self-employed professional, this step-by-step guide
                        shows how to make an invoice using the TradingForms Invoice Generator.
                      </p>

                      <ol>
                        <li>
                          <span>
                            The first time you will provide the necessary credentials to log in or create a new account if required.
                          </span>
                        </li>
                        <li>
                          <span>
                            Begin (first select the Template and then) by entering your business information in the Bill From section.
                            This includes your Name, Email, Website, Address, Date, and logo.
                            Ensure that the information provided is accurate and up to date.

                          </span>
                        </li>
                        <li>
                          <span>
                            Next, provide the necessary details of your client or customer in the Bill To section.
                            This includes their Name, Email, Website, Address, Phone, and any other relevant details required for
                            invoicing purposes.
                          </span>
                        </li>
                        <li>
                          <span>
                            List the products or services provided to the client. Include a description, quantity, unit price, and
                            any applicable taxes or discounts. If there are multiple items, you can add them as separate line items.
                          </span>
                        </li>
                        <li>
                          <span>
                            If you require, apply any taxes or discounts to the invoice total. Specify the tax rate and any discounts
                            provided, and then the system will calculate the final invoice amount.
                          </span>
                        </li>
                        <li>
                          <span>
                            Based on the provided quantity and unit price for each item, the TradingForms Invoice Generator would
                            automatically calculate the subtotal. This will ensure that the calculations are accurate.
                          </span>
                        </li>
                        <li>
                          <span>
                            Once you have entered all the necessary details, preview the invoice to ensure its accuracy and appearance.
                            Make any final adjustments if needed. Once satisfied, then Save the invoice.
                          </span>
                        </li>
                      </ol>
                      <h5 className="usage-step-title">
                        Invoice and quotation Preview:
                      </h5>
                      <ol>
                        <li>
                          <span>
                            <b> On Preview:</b> We have some fields that will show on the screen.
                          </span>
                        </li>
                        <li>
                          <span>
                            <b> Edit:</b> By clicking on this button, you will be navigated to edit mode of the invoice.
                          </span>
                        </li>
                        <li>
                          <span>
                            <b> Record Payment:</b> While clicking on this, a modal will appear and here you will be able to pay the payment via cash/cheque/credit card etc.
                          </span>
                        </li>
                        <li>
                          <span>
                            <b> PDF:</b> Clicking on this button, PDF will open in a new tab.
                          </span>
                        </li>
                        <li>
                          <span>
                            <b> Copy URL:</b> When you click on this button your Invoice url will be copied and you can use it for sharing.
                          </span>
                        </li>
                        <li>
                          <span>
                            <b> Email invoice:</b> When you click on an email invoice, popup will show and you can write email and send it.
                          </span>
                        </li>
                        <li>
                          <span>
                            <b> Color, DiscountType, Currency:</b> While clicking on color you can customize Invoice color. You can also add Tax. You can select the discount type which is “On Total”, “Per Item”. You can select currency and dropdown will appear, you can select one of them.
                          </span>
                        </li>
                      </ol>
                      <div className="usage-step">
                        <h5 className="usage-step-title">
                          How to send an invoice and quotation
                        </h5>
                        <ol>
                          <li>
                            <span>
                              Before sending an invoice, make sure it's accurate and complete. Double-check all the details, including the client's information, invoice number, payment due date, itemized products or services, and the total amount. Verify that any taxes or discounts are properly applied.
                            </span>
                          </li>
                          <li>
                            <span>
                              The most convenient method to send the invoice is via email. So click on the Email Invoice icon in the Invoice List and a modal will appear.
                            </span>
                          </li>
                          <li>
                            <span>
                              Ensure you have the correct email address for your client.
                            </span>
                          </li>
                          <li>
                            <span>
                              When sending the invoice, it's essential to include a professional and courteous message. This message should be Greetings, Invoice references, Invoice details, Thank you etc.
                            </span>
                          </li>
                          <li>
                            <span>
                              Attach the invoice as a PDF (hit checkbox) and Click on the Send button.
                            </span>
                          </li>
                          <li>
                            <span>
                              On preview the invoice screen, you will also see the button “Email Invoice”. You can also share the invoice with the help of this button.
                            </span>
                          </li>
                        </ol>


                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="usage-step">
                      <h5 className="usage-step-title">
                        Key components of an invoice and quotation:
                      </h5>
                      <p>There are key components of an invoice and quotation, including essential elements and optional customization features.</p>
                      <img className="usage-screenshot-custom" src={`${imgUrl}instructions/invoiceComponents.png`} alt="key components of an invoice"></img>
                      <div className="spacer"></div>


                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                  </div>

                </div>




              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="border-0">
            <Accordion.Header className="accordion-header-custom">How to make Payslips ?</Accordion.Header>
            <Accordion.Body>
              <div className="usage-list">
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="usage-step">
                      <h5 className="usage-step-title">
                        How to make payslip
                      </h5>
                      <p>
                        Create accurate and professional payslips effortlessly with our user-friendly TradingForms App, offering employees a transparent overview of earnings and deductions for each pay period. Here's how you can do it:
                      </p>

                      <ol>
                        <li>
                          <span>
                            Access the dashboard with your unique login credentials.
                          </span>
                        </li>
                        <li>
                          <span>
                            Once inside, you'll click on the Payslips module. By clicking on this, you will land on the Payslips Listing, where you will have a straightforward interface to see 'All Payslips' and 'Payslips Group'.

                          </span>
                        </li>
                        <li>
                          <span>
                            To Create a Payslip, You will click on the "+ Create Payslip" button.
                          </span>
                        </li>
                        <li>
                          <span>
                            Start by choosing a Template and inputting accurate business information (Name, Email, Address, Date, Logo) in 'From'.
                          </span>
                        </li>
                        <li>
                          <span>
                            Then, enter employee details (Name, Email, Address, Phone) in 'To' for a seamless payslip.
                          </span>
                        </li>
                        <li>
                          <span>
                            Then click on Next, here, simply input the Earnings and Deductions for the specified employee during the chosen pay period. Our well-organized input fields ensure you won't miss any crucial information.

                          </span>
                        </li>
                        <li>
                          <span>
                            After adding Earnings and Deductions, you will click on the Next button then You will add any Notes.
                          </span>
                        </li>
                        <li>
                          <span>
                            After that, you verify the details and then hit the 'Save' button. The system will instantly process the data and add the Payslip.
                          </span>
                        </li>
                        <li>
                          <span>
                            On Preview, we have some fields that will show on the screen.
                          </span>
                        </li>
                        <li>
                          <span>
                            Edit: on clicking on this button, you will be navigated to edit mode of the Payslip.
                          </span>
                        </li>
                        <li>
                          <span>
                            Currency change: You can select your preferred currency.
                          </span>
                        </li>
                        <li>
                          <span>
                            PDF: Tap to open the Payslip as PDF in a new tab.
                          </span>
                        </li>
                        <li>
                          <span>
                            Copy URL: Instantly copy the Payslip URL for easy sharing.
                          </span>
                        </li>
                        <li>
                          <span>
                            Email Payslip: Compose and send an email with the Payslip attachment via a convenient modal.
                          </span>
                        </li>
                      </ol>

                      <div className="usage-step">
                        <h5 className="usage-step-title">
                          How to send a payslip
                        </h5>
                        <ol>
                          <li>
                            <span>
                              Ensure payslip accuracy. Review employee information, payslip number, earnings, deductions, and total amount.
                            </span>
                          </li>
                          <li>
                            <span>
                              Conveniently email payslip via app. Click the 'Email Payslip' icon, and a modal will appear.
                            </span>
                          </li>
                          <li>
                            <span>
                              Verify the correct employee's email address.
                            </span>
                          </li>
                          <li>
                            <span>
                              Add a courteous message: Greetings, payslip references, details, and gratitude.
                            </span>
                          </li>
                          <li>
                            <span>
                              Attach the payslip as PDF (select checkbox) and hit 'Send'.

                            </span>
                          </li>
                          <li>
                            <span>
                              Preview payslip; use 'Email Payslip' button to share.

                            </span>
                          </li>
                        </ol>


                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="usage-step">
                      <h5 className="usage-step-title">
                        Key components of Payslip:
                      </h5>
                      <p>There are key components of payslip, including essential elements and optional customization features.</p>
                      <img className="usage-screenshot-custom" src={`${imgUrl}instructions/payslipComponents.png`} alt="key components of payslip"></img>
                      <div className="spacer"></div>


                    </div>
                  </div>

                </div>

              </div>

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <div className="d-flex justify-content-between align-items-center border-bottom  fw-semibold mb-3"  onClick={() => toggleAccordion('invoice')}>
          <h4 className="accordion-header">
            How to make an invoice and quotation ?
          </h4>
          <i className={`fa ${accordionInvoice?'fa-chevron-up':'fa-chevron-down'}`}></i>
        </div>
        {accordionInvoice && (
          <div className="usage-list">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="usage-step">
                  <h4 className="usage-step-title">
                    How to make an invoice and quotation
                  </h4>
                  <p>
                    This page provides a comprehensive guide on creating an invoice using the TradingForms Invoice Generator.
                    Whether you're a freelancer, small business owner, or self-employed professional, this step-by-step guide
                    shows how to make an invoice using the TradingForms Invoice Generator.
                  </p>
                  <ol>
                    <li>
                      <span>
                        The first time you will provide the necessary credentials to log in or create a new account if required.
                      </span>
                    </li>
                    <li>
                      <span>
                        Begin (first select the Template and then) by entering your business information in the Bill From section.
                        This includes your Name, Email, Website, Address, Date, and logo.
                        Ensure that the information provided is accurate and up to date.

                      </span>
                    </li>
                    <li>
                      <span>
                        Next, provide the necessary details of your client or customer in the Bill To section.
                        This includes their Name, Email, Website, Address, Phone, and any other relevant details required for
                        invoicing purposes.
                      </span>
                    </li>
                    <li>
                      <span>
                        List the products or services provided to the client. Include a description, quantity, unit price, and
                        any applicable taxes or discounts. If there are multiple items, you can add them as separate line items.
                      </span>
                    </li>
                    <li>
                      <span>
                        If you require, apply any taxes or discounts to the invoice total. Specify the tax rate and any discounts
                        provided, and then the system will calculate the final invoice amount.
                      </span>
                    </li>
                    <li>
                      <span>
                        Based on the provided quantity and unit price for each item, the TradingForms Invoice Generator would
                        automatically calculate the subtotal. This will ensure that the calculations are accurate.
                      </span>
                    </li>
                    <li>
                      <span>
                        Once you have entered all the necessary details, preview the invoice to ensure its accuracy and appearance.
                        Make any final adjustments if needed. Once satisfied, then Save the invoice.
                      </span>
                    </li>
                  </ol>
                  <h4 className="usage-step-title">
                    Invoice and quotation Preview:
                  </h4>
                  <ol>
                    <li>
                      <span>
                        <b> On Preview:</b> We have some fields that will show on the screen.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b> Edit:</b> By clicking on this button, you will be navigated to edit mode of the invoice.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b> Record Payment:</b> While clicking on this, a modal will appear and here you will be able to pay the payment via cash/cheque/credit card etc.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b> PDF:</b> Clicking on this button, PDF will open in a new tab.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b> Copy URL:</b> When you click on this button your Invoice url will be copied and you can use it for sharing.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b> Email invoice:</b> When you click on an email invoice, popup will show and you can write email and send it.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b> Color, DiscountType, Currency:</b> While clicking on color you can customize Invoice color. You can also add Tax. You can select the discount type which is “On Total”, “Per Item”. You can select currency and dropdown will appear, you can select one of them.
                      </span>
                    </li>
                  </ol>
                  <div className="usage-step">
                    <h4 className="usage-step-title">
                      How to send an invoice and quotation
                    </h4> 
                    <ol>
                      <li>
                        <span>
                          Before sending an invoice, make sure it's accurate and complete. Double-check all the details, including the client's information, invoice number, payment due date, itemized products or services, and the total amount. Verify that any taxes or discounts are properly applied.
                        </span>
                      </li>
                      <li>
                        <span>
                          The most convenient method to send the invoice is via email. So click on the Email Invoice icon in the Invoice List and a modal will appear.
                        </span>
                      </li>
                      <li>
                        <span>
                          Ensure you have the correct email address for your client.
                        </span>
                      </li>
                      <li>
                        <span>
                          When sending the invoice, it's essential to include a professional and courteous message. This message should be Greetings, Invoice references, Invoice details, Thank you etc.
                        </span>
                      </li>
                      <li>
                        <span>
                          Attach the invoice as a PDF (hit checkbox) and Click on the Send button.
                        </span>
                      </li>
                      <li>
                        <span>
                          On preview the invoice screen, you will also see the button “Email Invoice”. You can also share the invoice with the help of this button.
                        </span>
                      </li>
                    </ol>


                  </div>
                </div>

              </div>
              <div className="col-md-12 col-lg-6">
                <div className="usage-step">
                  <h4 className="usage-step-title">
                    Key components of an invoice and quotation:
                  </h4>
                  <p>There are key components of an invoice and quotation, including essential elements and optional customization features.</p>
                  <img className="usage-screenshot-custom" src={`${imgUrl}instructions/invoiceComponents.png`} alt="key components of an invoice"></img>
                  <div className="spacer"></div>


                </div>
              </div>
              <div className="col-md-12 col-lg-6">
              </div>

            </div>




          </div>
        )
        } */}
        {/* <div className="d-flex justify-content-between align-items-center border-bottom  fw-semibold mb-3" onClick={()=>toggleAccordion('payslip')}>
          <h4 className="accordion-header" >
            How to make Payslips using the TradingForms payslip generator?
          </h4>
          <i className={`fa ${accordionPayslip?'fa-chevron-up':'fa-chevron-down'}`}></i>
        </div>
        {accordionPayslip && (
          <div className="usage-list">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="usage-step">
                  <h4 className="usage-step-title">
                    How to make payslip
                  </h4>
                  <p>
                    Create accurate and professional payslips effortlessly with our user-friendly TradingForms App, offering employees a transparent overview of earnings and deductions for each pay period. Here's how you can do it:
                  </p>
                  <ol>
                    <li>
                      <span>
                        Access the dashboard with your unique login credentials.
                      </span>
                    </li>
                    <li>
                      <span>
                        Once inside, you'll click on the Payslips module. By clicking on this, you will land on the Payslips Listing, where you will have a straightforward interface to see 'All Payslips' and 'Payslips Group'.

                      </span>
                    </li>
                    <li>
                      <span>
                        To Create a Payslip, You will click on the "+ Create Payslip" button.
                      </span>
                    </li>
                    <li>
                      <span>
                        Start by choosing a Template and inputting accurate business information (Name, Email, Address, Date, Logo) in 'From'.
                      </span>
                    </li>
                    <li>
                      <span>
                        Then, enter employee details (Name, Email, Address, Phone) in 'To' for a seamless payslip.
                      </span>
                    </li>
                    <li>
                      <span>
                        Then click on Next, here, simply input the Earnings and Deductions for the specified employee during the chosen pay period. Our well-organized input fields ensure you won't miss any crucial information.

                      </span>
                    </li>
                    <li>
                      <span>
                        After adding Earnings and Deductions, you will click on the Next button then You will add any Notes.
                      </span>
                    </li>
                    <li>
                      <span>
                        After that, you verify the details and then hit the 'Save' button. The system will instantly process the data and add the Payslip.
                      </span>
                    </li>
                    <li>
                      <span>
                        On Preview, we have some fields that will show on the screen.
                      </span>
                    </li>
                    <li>
                      <span>
                        Edit: on clicking on this button, you will be navigated to edit mode of the Payslip.
                      </span>
                    </li>
                    <li>
                      <span>
                        Currency change: You can select your preferred currency.
                      </span>
                    </li>
                    <li>
                      <span>
                        PDF: Tap to open the Payslip as PDF in a new tab.
                      </span>
                    </li>
                    <li>
                      <span>
                        Copy URL: Instantly copy the Payslip URL for easy sharing.
                      </span>
                    </li>
                    <li>
                      <span>
                        Email Payslip: Compose and send an email with the Payslip attachment via a convenient modal.
                      </span>
                    </li>
                  </ol>
                  
                  <div className="usage-step">
                    <h4 className="usage-step-title">
                      How to send a payslip
                    </h4>
                    <ol>
                      <li>
                        <span>
                          Ensure payslip accuracy. Review employee information, payslip number, earnings, deductions, and total amount.
                        </span>
                      </li>
                      <li>
                        <span>
                          Conveniently email payslip via app. Click the 'Email Payslip' icon, and a modal will appear.
                        </span>
                      </li>
                      <li>
                        <span>
                          Verify the correct employee's email address.
                        </span>
                      </li>
                      <li>
                        <span>
                          Add a courteous message: Greetings, payslip references, details, and gratitude.
                        </span>
                      </li>
                      <li>
                        <span>
                          Attach the payslip as PDF (select checkbox) and hit 'Send'.

                        </span>
                      </li>
                      <li>
                        <span>
                          Preview payslip; use 'Email Payslip' button to share.

                        </span>
                      </li>
                    </ol>


                  </div>
                </div>

              </div>
              <div className="col-md-12 col-lg-6">
                <div className="usage-step">
                  <h4 className="usage-step-title">
                    Key components of Payslip:
                  </h4>
                  <p>There are key components of payslip, including essential elements and optional customization features.</p>
                  <img className="usage-screenshot-custom" src={`${imgUrl}instructions/payslipComponents.png`} alt="key components of payslip"></img>
                  <div className="spacer"></div>


                </div>
              </div>
            </div>
          </div> 
        )
        } */}


        {/* <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item rounded-3 border-0 mb-2">
            <h2 className="accordion-header">
              <button className="accordion-button border-bottom  fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                How to make an invoice and quotation ?
              </button>
            </h2>
            
            <div id="flush-collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <div className="usage-list">
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="usage-step">
                        <h4 className="usage-step-title">
                          How to make an invoice and quotation
                        </h4>
                        <p>
                          This page provides a comprehensive guide on creating an invoice using the TradingForms Invoice Generator.
                          Whether you're a freelancer, small business owner, or self-employed professional, this step-by-step guide
                          shows how to make an invoice using the TradingForms Invoice Generator.
                        </p>

                        <ol>
                          <li>
                            <span>
                              The first time you will provide the necessary credentials to log in or create a new account if required.
                            </span>
                          </li>
                          <li>
                            <span>
                              Begin (first select the Template and then) by entering your business information in the Bill From section.
                              This includes your Name, Email, Website, Address, Date, and logo.
                              Ensure that the information provided is accurate and up to date.

                            </span>
                          </li>
                          <li>
                            <span>
                              Next, provide the necessary details of your client or customer in the Bill To section.
                              This includes their Name, Email, Website, Address, Phone, and any other relevant details required for
                              invoicing purposes.
                            </span>
                          </li>
                          <li>
                            <span>
                              List the products or services provided to the client. Include a description, quantity, unit price, and
                              any applicable taxes or discounts. If there are multiple items, you can add them as separate line items.
                            </span>
                          </li>
                          <li>
                            <span>
                              If you require, apply any taxes or discounts to the invoice total. Specify the tax rate and any discounts
                              provided, and then the system will calculate the final invoice amount.
                            </span>
                          </li>
                          <li>
                            <span>
                              Based on the provided quantity and unit price for each item, the TradingForms Invoice Generator would
                              automatically calculate the subtotal. This will ensure that the calculations are accurate.
                            </span>
                          </li>
                          <li>
                            <span>
                              Once you have entered all the necessary details, preview the invoice to ensure its accuracy and appearance.
                              Make any final adjustments if needed. Once satisfied, then Save the invoice.
                            </span>
                          </li>
                        </ol>
                        <h4 className="usage-step-title">
                          Invoice and quotation Preview:
                        </h4>
                        <ol>
                          <li>
                            <span>
                              <b> On Preview:</b> We have some fields that will show on the screen.
                            </span>
                          </li>
                          <li>
                            <span>
                              <b> Edit:</b> By clicking on this button, you will be navigated to edit mode of the invoice.
                            </span>
                          </li>
                          <li>
                            <span>
                              <b> Record Payment:</b> While clicking on this, a modal will appear and here you will be able to pay the payment via cash/cheque/credit card etc.
                            </span>
                          </li>
                          <li>
                            <span>
                              <b> PDF:</b> Clicking on this button, PDF will open in a new tab.
                            </span>
                          </li>
                          <li>
                            <span>
                              <b> Copy URL:</b> When you click on this button your Invoice url will be copied and you can use it for sharing.
                            </span>
                          </li>
                          <li>
                            <span>
                              <b> Email invoice:</b> When you click on an email invoice, popup will show and you can write email and send it.
                            </span>
                          </li>
                          <li>
                            <span>
                              <b> Color, DiscountType, Currency:</b> While clicking on color you can customize Invoice color. You can also add Tax. You can select the discount type which is “On Total”, “Per Item”. You can select currency and dropdown will appear, you can select one of them.
                            </span>
                          </li>
                        </ol>
                        <div className="usage-step">
                          <h4 className="usage-step-title">
                            How to send an invoice and quotation
                          </h4>
                          <ol>
                            <li>
                              <span>
                                Before sending an invoice, make sure it's accurate and complete. Double-check all the details, including the client's information, invoice number, payment due date, itemized products or services, and the total amount. Verify that any taxes or discounts are properly applied.
                              </span>
                            </li>
                            <li>
                              <span>
                                The most convenient method to send the invoice is via email. So click on the Email Invoice icon in the Invoice List and a modal will appear.
                              </span>
                            </li>
                            <li>
                              <span>
                                Ensure you have the correct email address for your client.
                              </span>
                            </li>
                            <li>
                              <span>
                                When sending the invoice, it's essential to include a professional and courteous message. This message should be Greetings, Invoice references, Invoice details, Thank you etc.
                              </span>
                            </li>
                            <li>
                              <span>
                                Attach the invoice as a PDF (hit checkbox) and Click on the Send button.
                              </span>
                            </li>
                            <li>
                              <span>
                                On preview the invoice screen, you will also see the button “Email Invoice”. You can also share the invoice with the help of this button.
                              </span>
                            </li>
                          </ol>


                        </div>
                      </div>

                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="usage-step">
                        <h4 className="usage-step-title">
                          Key components of an invoice and quotation:
                        </h4>
                        <p>There are key components of an invoice and quotation, including essential elements and optional customization features.</p>
                        <img className="usage-screenshot-custom" src={`${imgUrl}instructions/invoiceComponents.png`} alt="key components of an invoice"></img>
                        <div className="spacer"></div>


                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                    </div>

                  </div>




                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item rounded-3 border-0 mb-2">
            <h2 className="accordion-header">
              <button className="accordion-button border-bottom collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              How to make Payslips using the TradingForms payslip generator
              </button>
            </h2>
            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <div className="usage-list">
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="usage-step">
                        <h4 className="usage-step-title">
                          How to make payslip
                        </h4>
                        <p>
                          Create accurate and professional payslips effortlessly with our user-friendly TradingForms App, offering employees a transparent overview of earnings and deductions for each pay period. Here's how you can do it:
                        </p>

                        <ol>
                          <li>
                            <span>
                              Access the dashboard with your unique login credentials.
                            </span>
                          </li>
                          <li>
                            <span>
                              Once inside, you'll click on the Payslips module. By clicking on this, you will land on the Payslips Listing, where you will have a straightforward interface to see 'All Payslips' and 'Payslips Group'.

                            </span>
                          </li>
                          <li>
                            <span>
                              To Create a Payslip, You will click on the "+ Create Payslip" button.
                            </span>
                          </li>
                          <li>
                            <span>
                              Start by choosing a Template and inputting accurate business information (Name, Email, Address, Date, Logo) in 'From'.
                            </span>
                          </li>
                          <li>
                            <span>
                              Then, enter employee details (Name, Email, Address, Phone) in 'To' for a seamless payslip.
                            </span>
                          </li>
                          <li>
                            <span>
                              Then click on Next, here, simply input the Earnings and Deductions for the specified employee during the chosen pay period. Our well-organized input fields ensure you won't miss any crucial information.

                            </span>
                          </li>
                          <li>
                            <span>
                              After adding Earnings and Deductions, you will click on the Next button then You will add any Notes.
                            </span>
                          </li>
                          <li>
                            <span>
                              After that, you verify the details and then hit the 'Save' button. The system will instantly process the data and add the Payslip.
                            </span>
                          </li>
                          <li>
                            <span>
                              On Preview, we have some fields that will show on the screen.
                            </span>
                          </li>
                          <li>
                            <span>
                              Edit: on clicking on this button, you will be navigated to edit mode of the Payslip.
                            </span>
                          </li>
                          <li>
                            <span>
                              Currency change: You can select your preferred currency.
                            </span>
                          </li>
                          <li>
                            <span>
                              PDF: Tap to open the Payslip as PDF in a new tab.
                            </span>
                          </li>
                          <li>
                            <span>
                              Copy URL: Instantly copy the Payslip URL for easy sharing.
                            </span>
                          </li>
                          <li>
                            <span>
                              Email Payslip: Compose and send an email with the Payslip attachment via a convenient modal.
                            </span>
                          </li>
                        </ol>


                        <div className="usage-step">
                          <h4 className="usage-step-title">
                            How to send a payslip
                          </h4>

                          <ol>
                            <li>
                              <span>
                                Ensure payslip accuracy. Review employee information, payslip number, earnings, deductions, and total amount.
                              </span>
                            </li>
                            <li>
                              <span>
                                Conveniently email payslip via app. Click the 'Email Payslip' icon, and a modal will appear.
                              </span>
                            </li>
                            <li>
                              <span>
                                Verify the correct employee's email address.
                              </span>
                            </li>
                            <li>
                              <span>
                                Add a courteous message: Greetings, payslip references, details, and gratitude.
                              </span>
                            </li>
                            <li>
                              <span>
                                Attach the payslip as PDF (select checkbox) and hit 'Send'.

                              </span>
                            </li>
                            <li>
                              <span>
                                Preview payslip; use 'Email Payslip' button to share.

                              </span>
                            </li>
                          </ol>


                        </div>
                      </div>

                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="usage-step">
                        <h4 className="usage-step-title">
                          Key components of Payslip:
                        </h4>
                        <p>There are key components of payslip, including essential elements and optional customization features.</p>
                        <img className="usage-screenshot-custom" src={`${imgUrl}instructions/payslipComponents.png`} alt="key components of payslip"></img>
                        <div className="spacer"></div>


                      </div>
                    </div>


                  </div>

                </div>
              </div>
            </div>
          </div>
        
        </div> */}

      </div>
      {/* <div className="spacer"></div> */}
      {/* <div className="usage-container" data-aos="fade-left">
        <h2 className="section-usage-header">
          How to make Payslips using the
          <span className="pos-rel"> TradingForms</span> payslip generator
        </h2>

        <div className="usage-list">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="usage-step">
                <h4 className="usage-step-title">
                  How to make payslip
                </h4>
                <p>
                  Create accurate and professional payslips effortlessly with our user-friendly TradingForms App, offering employees a transparent overview of earnings and deductions for each pay period. Here's how you can do it:
                </p>
                
                <ol>
                  <li>
                    <span>
                      Access the dashboard with your unique login credentials.
                    </span>
                  </li>
                  <li>
                    <span>
                      Once inside, you'll click on the Payslips module. By clicking on this, you will land on the Payslips Listing, where you will have a straightforward interface to see 'All Payslips' and 'Payslips Group'.

                    </span>
                  </li>
                  <li>
                    <span>
                      To Create a Payslip, You will click on the "+ Create Payslip" button.
                    </span>
                  </li>
                  <li>
                    <span>
                      Start by choosing a Template and inputting accurate business information (Name, Email, Address, Date, Logo) in 'From'.
                    </span>
                  </li>
                  <li>
                    <span>
                      Then, enter employee details (Name, Email, Address, Phone) in 'To' for a seamless payslip.
                    </span>
                  </li>
                  <li>
                    <span>
                      Then click on Next, here, simply input the Earnings and Deductions for the specified employee during the chosen pay period. Our well-organized input fields ensure you won't miss any crucial information.

                    </span>
                  </li>
                  <li>
                    <span>
                      After adding Earnings and Deductions, you will click on the Next button then You will add any Notes.
                    </span>
                  </li>
                  <li>
                    <span>
                      After that, you verify the details and then hit the 'Save' button. The system will instantly process the data and add the Payslip.
                    </span>
                  </li>
                  <li>
                    <span>
                      On Preview, we have some fields that will show on the screen.
                    </span>
                  </li>
                  <li>
                    <span>
                      Edit: on clicking on this button, you will be navigated to edit mode of the Payslip.
                    </span>
                  </li>
                  <li>
                    <span>
                      Currency change: You can select your preferred currency.
                    </span>
                  </li>
                  <li>
                    <span>
                      PDF: Tap to open the Payslip as PDF in a new tab.
                    </span>
                  </li>
                  <li>
                    <span>
                      Copy URL: Instantly copy the Payslip URL for easy sharing.
                    </span>
                  </li>
                  <li>
                    <span>
                      Email Payslip: Compose and send an email with the Payslip attachment via a convenient modal.
                    </span>
                  </li>
                </ol>
                
                <div className="usage-step">
                  <h4 className="usage-step-title">
                    How to send a payslip
                  </h4>
                  <ol>
                    <li>
                      <span>
                        Ensure payslip accuracy. Review employee information, payslip number, earnings, deductions, and total amount.
                      </span>
                    </li>
                    <li>
                      <span>
                        Conveniently email payslip via app. Click the 'Email Payslip' icon, and a modal will appear.
                      </span>
                    </li>
                    <li>
                      <span>
                        Verify the correct employee's email address.
                      </span>
                    </li>
                    <li>
                      <span>
                        Add a courteous message: Greetings, payslip references, details, and gratitude.
                      </span>
                    </li>
                    <li>
                      <span>
                        Attach the payslip as PDF (select checkbox) and hit 'Send'.

                      </span>
                    </li>
                    <li>
                      <span>
                        Preview payslip; use 'Email Payslip' button to share.

                      </span>
                    </li>
                  </ol>


                </div>
              </div>

            </div>
            <div className="col-md-12 col-lg-6">
              <div className="usage-step">
                <h4 className="usage-step-title">
                  Key components of Payslip:
                </h4>
                <p>There are key components of payslip, including essential elements and optional customization features.</p>
                <img className="usage-screenshot-custom" src={`${imgUrl}instructions/payslipComponents.png`} alt="key components of payslip"></img>
                <div className="spacer"></div>


              </div>
            </div>
          </div>

        </div>
      </div> */}
    </>
  );
}

export default Usage;
