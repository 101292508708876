import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

function PersonalDetails({
  formData,
  handleBlur,
  logoImage,
  removeImage,
  handleFileInputChange,
  fillFields,
  autoSuggstion,
  suggestList,
  showSuggest,
  setShowSuggest,
  prefix,
  toName,toEmail,fromName,fromEmail
}) {
  const [toggleSender, setToggleSender] = useState(false);
  const [toogleTo, setToogleTo] = useState(false);
  // console.log(formData,">>",formData?.senderWebsite);

  return (
    <>
      <div className="PersonalDetails fadeInOpacity">
        <div className="formStepOne">
          <div className="col-12">
            <div className="invoiceHeader container">
              <div className="col-6 row justify-content-end p-0">
                <div className="formField p-0">
                  <div className="w-100">
                    <input
                      type=""
                      name="invoiceHeader"
                      className="form-control "
                      onChange={(e) => handleBlur(e)}
                      defaultValue={formData?.invoiceHeader}
                      placeholder="Invoice"
                      autoComplete="off"
                    ></input>{" "}
                    {/* value="Invoice" */}
                  </div>
                </div>
              </div>
              <div className="invoiceLogo">
                <div className="logoContainer form-control">
                  {logoImage === null && (
                    <div className="logolabel">
                      <i className="fas fa-image"></i>
                      <span> + Logo</span>
                    </div>
                  )}
                  <input
                    type="file"
                    className="form-control"
                    accept=".png,.jpeg,.jpg,.webp"
                    name="logo"
                    defaultValue={""}
                    onChange={handleFileInputChange}
                  ></input>
                  <span className="showlabel">
                    <i className="fa fa-plus"></i>
                    {logoImage === null ? " Add " : " Update "}
                    your logo
                  </span>
                  {logoImage !== null && (
                    <span className="removeImage" onClick={removeImage}>
                      <i className="fa fa-times"></i>
                    </span>
                  )}
                  {/* <div className="overlay"></div> */}
                  {logoImage !== null && (
                    <img
                      src={logoImage}
                      className="logoImage"
                      alt="test"
                      height={130}
                      width={200}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-12">
            <div className="fromPersonalDetails">
              <h6 className="fw-bold">Bill From</h6>
              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="senderName">
                  Name<span className="text-danger">*</span>
                </label>
                <div className="col-sm-9 col-12">
                  <input
                    className={`form-control ${fromName?  'shake':''}`}
                    required
                    type="text"
                    name="senderName"
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => handleBlur(e)}
                    defaultValue={formData?.senderName}
                    placeholder="Siri"
                    autoComplete="off"
                  ></input>{" "}
                  {/* value="" */}
                </div>
              </div>

              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="senderEmail">
                  Email<span className="text-danger">*</span>
                </label>
                <div className="col-sm-9 col-12">
                  <input
                    className={`form-control ${fromEmail?  'shake':''}`}
                    required
                    type="email"
                    name="senderEmail"
                    onChange={(e) => handleBlur(e)}
                    onBlur={(e) => handleBlur(e)}
                    defaultValue={formData?.senderEmail}
                    placeholder="abc@xyz.com"
                    autoComplete="off"
                  ></input>{" "}
                  {/* value="" */}
                </div>
              </div>
              <div
                className={`showMoreDetails  ${
                  toggleSender === false ? "borderUp" : "borderDown"
                } `}
                onClick={() => setToggleSender(!toggleSender)}
              >
                <span className="btnDetail">Show {toggleSender?"Less":"More"} Details</span>
                <span>
                  {toggleSender === true ? (
                    <i className="fas fa-chevron-up"></i>
                  ) : (
                    <i className="fa fa-chevron-down"></i>
                  )}
                </span>
              </div>
              {toggleSender === true && (
                <div className="showMoreDetailsContainer fadeInOpacity">
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="senderWebsite">
                      Website
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="url"
                        name="senderWebsite"
                        placeholder="https://example.com"
                        onChange={(e) => handleBlur(e)}
                        defaultValue={formData?.senderWebsite}
                        autoComplete="off"
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="senderGst">
                      GST#
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="senderGst"
                        // placeholder="123 45 6789"
                        onChange={(e) => handleBlur(e)}
                        defaultValue={formData?.senderGst}
                        autoComplete="off"
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="senderNtn">
                      NTN#
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="senderNtn"
                        // placeholder="123-45-6789"
                        onChange={(e) => handleBlur(e)}
                        defaultValue={formData?.senderNtn}
                        autoComplete="off"
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField align-items-start">
                    <label className="col-sm-3 col-12" htmlFor="senderAddress">
                      Address
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        type="text"
                        className="form-control"
                        name="senderStreet"
                        onChange={(e) => handleBlur(e)}
                        placeholder="Street"
                        defaultValue={formData?.senderStreet}
                        autoComplete="off"
                      ></input>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="senderCityState"
                        onChange={(e) => handleBlur(e)}
                        placeholder="City,State"
                        defaultValue={formData?.senderCityState}
                        autoComplete="off"
                      ></input>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="senderZipCode"
                        onChange={(e) => handleBlur(e)}
                        placeholder="ZipCode"
                        defaultValue={formData?.senderZipCode}
                        autoComplete="off"
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                 
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="senderPhone">
                      Phone
                    </label>
                    {/* <div className="w-100"> */}
                      {/* <input
                        className="col-9 form-control"
                        type="text"
                        name="senderPhone"
                        maxLength="14"
                        placeholder="(123) 456-7890"
                        onChange={(e) => handleBlur(e)}
                        value={formData?.senderPhone}
                        autoComplete="off"
                      ></input> */}
                      <div className="col-sm-9 col-12">
                        <PhoneInput
                          value={formData.senderPhone}
                          country={'us'}
                          onChange={(value, country, e, formattedValue) => handleBlur(e)}
                          countryCodeEditable={false}
                          inputProps={{
                            name:'senderPhone'
                          }}
                        />
                      </div>
                    {/* </div> */}
                  </div>
                   <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="senderFax">
                      Fax
                    </label>
                    <div className="col-sm-9 col-12">
                      <PhoneInput
                        value={formData.senderFax}
                        country={'us'}
                        onChange={(value, country, e, formattedValue) => handleBlur(e)}
                        countryCodeEditable={false}
                        inputProps={{
                          name: 'senderFax'
                        }}
                        // enableSearch={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-12">
            <div className="toPersonalDetails">
              <h6 className="fw-bold">BILL To</h6>
              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="customerName">
                  Name<span className="text-danger">*</span>
                </label>
                <div className="col-sm-9 col-12 nameSuggest">
                  <input
                    className={`form-control ${toName?  'shake':''}`}
                    required
                    type="text"
                    name="customerName"
                    onChange={(e) => handleBlur(e)}
                    onKeyUp={(e) => autoSuggstion(e)}
                    onBlur={()=> {
                      setTimeout(() => {
                        setShowSuggest(false)
                      }, 500)
                    }}
                    value={formData?.customerName}
                    placeholder="Alex"
                    autoComplete="off"
                  ></input>{" "}
                  {showSuggest && (
                    <div className="autosuggestDropDownContainer">
                      <div className="autosuggestDropDown">
                        {
                          <ul>
                            {suggestList.length ? (
                              suggestList?.map((list, index) => {
                                return (
                                  <li
                                    key={list.id}
                                    onClick={() => {
                                      // console.log("CLICKED")
                                      fillFields(list.id)
                                    }}
                                  >
                                    {list.entity_name}
                                  </li>
                                );
                              })
                            ) : (
                              <li className="text-danger">No Result Found !</li>
                            )}
                          </ul>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="customerEmail">
                  Email<span className="text-danger">*</span>
                </label>
                <div className="col-sm-9 col-12">
                  <input
                    className={`form-control ${toEmail?  'shake':''}`}
                    required
                    type="email"
                    name="customerEmail"
                    onChange={(e) => handleBlur(e)}
                    value={formData?.customerEmail}
                    placeholder="abc@xyz.com"
                    autoComplete="off"
                  ></input>{" "}
                  {/* value="" */}
                </div>
              </div>
              <div
                className={`showMoreDetails  ${
                  toogleTo === false ? "borderUp" : "borderDown"
                } `}
                onClick={() => setToogleTo(!toogleTo)}
              >
                <span className="btnDetail">Show {toogleTo?"Less":"More"}  Details</span>
                <span>
                  {toogleTo === true ? (
                    <i className="fas fa-chevron-up"></i>
                  ) : (
                    <i className="fa fa-chevron-down"></i>
                  )}
                </span>
              </div>
              {toogleTo === true && (
                <div className="showMoreDetailsContainer fadeInOpacity">
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="customerWebsite">
                      Website
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="url"
                        name="customerWebsite"
                        placeholder="https://example.com"
                        onChange={(e) => handleBlur(e)}
                        defaultValue={formData?.customerWebsite}
                        autoComplete="off"
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="customerGst">
                      GST#
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="customerGst"
                        // placeholder="123-45-6789"
                        onChange={(e) => handleBlur(e)}
                        value={formData?.customerGst}
                        autoComplete="off"
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="customerNtn">
                      NTN#
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="customerNtn"
                        // placeholder="123-45-6789"
                        onChange={(e) => handleBlur(e)}
                        value={formData?.customerNtn}
                        autoComplete="off"
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField align-items-start">
                    <label className="col-sm-3 col-12" htmlFor="customerAddress">
                      Address
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        type="text"
                        className="form-control"
                        name="customerStreet"
                        onChange={(e) => handleBlur(e)}
                        placeholder="Street"
                        value={formData?.customerStreet}
                        autoComplete="off"
                      ></input>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="customerCityState"
                        onChange={(e) => handleBlur(e)}
                        placeholder="City,State"
                        value={formData?.customerCityState}
                        autoComplete="off"
                      ></input>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="customerZipCode"
                        onChange={(e) => handleBlur(e)}
                        placeholder="ZipCode"
                        value={formData?.customerZipCode}
                        autoComplete="off"
                      ></input>{" "}
                      {/* defaultValue="" */}
                    </div>
                  </div>
                  
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="customerPhone">
                      Phone
                    </label>
                    <div className="col-sm-9 col-12">
                        <PhoneInput
                          value={formData.customerPhone}
                          country={'us'}
                          onChange={(value, country, e, formattedValue) => handleBlur(e)}
                          countryCodeEditable={false}
                          inputProps={{
                            name:'customerPhone'
                          }}
                        />
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="customerFax">
                      Fax
                    </label>
                    <div className="col-sm-9 col-12">
                      <PhoneInput
                        value={formData.customerFax}
                        country={'us'}
                        onChange={(value, country, e, formattedValue) => handleBlur(e)}
                        countryCodeEditable={false}
                        inputProps={{
                          name: 'customerFax'
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <hr className="col-12"></hr>
          <div className="InvoiceInfo col-sm-12 col-md-12 col-lg-6 col-12">
            <div className="formField">
              <label className="col-sm-3 col-12" htmlFor="invoiceNumber">
               { formData?.type === "chargable" ?"Invoice":"Quot"}#
              </label>
              <div className="col-sm-9 col-12 position-relative">
                {/* placeholder="INV0001" */}
                <input
                  className="form-control cursor-disable"
                  type="text"
                  name="invoiceNumber"
                  onChange={(e) => handleBlur(e)}
                  defaultValue={formData?.invoiceNumber}
                  autoComplete="off"
                  readOnly
                ></input>
                <span
                  className="prefixInfo"
                  title={`${prefix}# Prefix can be changed from settings.`}
                >
                  <i className="fa fa-info-circle"></i>
                </span>{" "}
              </div>
              {/* value="" */}
            </div>
            <div className="formField">
              <label className="col-sm-3 col-12" htmlFor="invoicedate">
                Date <span className="text-danger">*</span>
              </label>
              <input
                className="col-sm-9 col-12"
                type="date"
                name="invoicedate"
                required
                onChange={(e) => handleBlur(e)}
                defaultValue={formData?.invoicedate}
                autoComplete="off"
              ></input>{" "}
              {/* value="" */}
            </div>
            { formData?.type === "chargable" &&( 
              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="terms">
                  Terms
                </label>
                {/* <input className="col-9" type="text" name="terms" onChange={(e)=>handleBlur(e)} defaultValue={formData?.terms}></input>{" "} */}
                {/* value="" */}
                <select
                  className="form-select"
                  name="terms"
                  onChange={(e) => handleBlur(e)}
                  key={formData?.terms}
                  defaultValue={formData?.terms}
                >
                  <option value="none">None</option>
                  <option value="0">On Receipt</option>
                  <option value="1">Next Day</option>
                  <option value="2">2 Days</option>
                  <option value="3">3 Days</option>
                  <option value="4">4 Days</option>
                  <option value="5">5 Days</option>
                  <option value="6">6 Days</option>
                  <option value="7">7 Days</option>
                  <option value="10">10 Days</option>
                  <option value="14">14 Days</option>
                  <option value="21">21 Days</option>
                  <option value="14">14 Days</option>
                  <option value="30">30 Days</option>
                  <option value="45">45 Days</option>
                  <option value="60">60 Days</option>
                  <option value="90">90 Days</option>
                  <option value="120">120 Days</option>
                  <option value="180">180 Days</option>
                  <option value="365">365 Days</option>
                </select>
              </div> )
              }
              {formData?.terms !== "none" && (
                <div className="formField">
                  <label className="col-sm-3 col-12" htmlFor="termValue">
                    Term Date
                  </label>
                  {/* <div className="col-sm-9 col-12"> */}
                    <input
                      className="col-sm-9 col-12 cursor-disable"
                      type="date"
                      name="termValue"
                      onChange={(e) => handleBlur(e)}
                      value={formData?.termValue}
                      readOnly
                      disabled
                      autoComplete="off"
                    ></input>{" "}
                    {/* value="" */}

                  {/* </div> */}
                </div>
              )}
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;
