import React, { useState } from 'react'
import CustombuttonSpinner from '../../components/CustombuttonSpinner';


const ExcelJS = require('exceljs');

function ExportExcel({ reportsData, reportClass, reportType }) {
  const [loader, setLoader] = useState(false);
  const createDownloadData = () => {
    setLoader(true);
    exportExcel();
  }
  
  const exportExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet(`${reportClass === "chargable" ? "Invoice" : "Quotation"} Report ${reportsData.current_report_year}`);

      sheet.getRow(1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
      sheet.getRow(1).font = {
        bold: true,
      }

      if (reportType === "invoices") {
        if (reportClass === "chargable") {
          sheet.columns = [
            {
              header: "Months",
              key: "month",
              width: 50,
            },
            {
              header: "Consumers",
              key: "client",
              width: 10,
            },
            {
              header: "Invoice",
              key: "invoices",
              width: 10,
            },
            {
              header: "Total",
              key: "total",
              width: 15,
            },
            {
              header: "Outstanding",
              key: "outstanding",
              width: 15,
            },
            {
              header: "Total Tax",
              key: "totalTax",
              width: 15,
            },
            {
              header: "Paid",
              key: "paid",
              width: 15,
            },
          ];
          reportsData?.data?.months.map(singleRecord =>
            sheet.addRow({
              month: singleRecord?.month_name,
              client: singleRecord?.total_consumers,
              invoices: singleRecord?.total_invoices,
              total: singleRecord?.actual_amount,
              outstanding: singleRecord?.total_outstandings,
              totalTax: singleRecord?.total_tax,
              paid: singleRecord?.total_paid,
            })
          );
        }
        if (reportClass === "quotation") {
          sheet.columns = [
            {
              header: "Months",
              key: "month",
              width: 50,
            },
            {
              header: "Consumers",
              key: "client",
              width: 10,
            },
            {
              header: "Quotations",
              key: "quotation",
              width: 12,
            },
            {
              header: "Total",
              key: "total",
              width: 15,
            },
          ];
          reportsData?.data?.months.map(singleRecord =>
            sheet.addRow({
              month: singleRecord?.month_name,
              client: singleRecord?.total_consumers,
              quotation: singleRecord?.total_invoices,
              total: singleRecord?.actual_amount,
            })
          );
        }

        workbook.xlsx.writeBuffer().then(data => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });

          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = `${reportClass === "chargable" ? "invoice" : "quotation"}_report_${reportsData.current_report_year}.xlsx`;
          anchor.click();
          window.URL.revokeObjectURL(url);
        })


      }
      if (reportType === "salaries") {
        sheet.columns = [
          {
            header: "Months",
            key: "month",
            width: 50,
          },
          {
            header: "Consumers",
            key: "clients",
            width: 10,
          },
          {
            header: "Payslips",
            key: "payslips",
            width: 10,
          },
          {
            header: "Earnings",
            key: "earnings",
            width: 15,
          },
          {
            header: "Deductions",
            key: "deductions",
            width: 15,
          },
          {
            header: "Net Pay",
            key: "netpay",
            width: 15,
          },
        ]
        reportsData?.data?.months.map(record =>
          sheet.addRow({
            month: record?.month_name,
            clients: record?.total_consumers,
            payslips: record?.total_salary_slips,
            earnings: record?.total_earnings,
            deductions: record?.total_deductions,
            netpay: record?.total_net_pay,

          })
        );
        workbook.xlsx.writeBuffer().then(data => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });

          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = `${reportClass === "singler" ? "payslips" : "group_payslips"}_report_${reportsData.current_report_year}.xlsx`;
          anchor.click();
          window.URL.revokeObjectURL(url);
        })

      }

    } catch (error) {
    } finally {
      setLoader(false);
    }
  };


  return (
    <>
      <button onClick={createDownloadData} className={`btn btn-sm btn-save ${loader === true ? 'cursor-disable' : ''}`} title={`Download ${reportClass === "chargable"?"Invoices":reportClass ==='quotation'?"Quotations":'Payslips'} Report as Excel`} disabled={loader}><i className="fa fa-file-excel"></i><span className='position-relative'>Export{loader && <CustombuttonSpinner />} </span></button>
    </>

  )
}

export default ExportExcel