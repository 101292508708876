import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    backgroundUrl: "company_bg_images/backgroundpic.png"
}

export const backgroundSlice = createSlice({
  name: "backgroundUrl",

  initialState,

  reducers: {
    setBackgroundUrl: (state, {payload}) => {
      state.backgroundUrl = payload;
    },
  },
});
export const { setBackgroundUrl } = backgroundSlice.actions;

export default backgroundSlice.reducer;