import React from 'react'
import { FRONTSITE } from '../../common/common';
import { Link } from 'react-router-dom';

import './Footer.css'

function Footer() {
  const frontsiteUrl = FRONTSITE;

  return (
    <>
        <div className="footer">
        <div>
        <div className="menu">
          <input type="checkbox" id="toggle" />
          <label id="show-menu" htmlFor="toggle">
            <div className="btn" title='Support'>
                <i className="fa fa-headset md-36 toggleBtn menuBtn animated"></i>
              {/* <i className="fa fa-times md-36 toggleBtn closeBtn"></i> */}
              <svg className='toggleBtn closeBtn' width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 12 7 7m5 5 5 5m-5-5 5-5m-5 5-5 5"/></svg>
            </div>
            <div className="btn" title='Go to Support'>
            <Link to={`${frontsiteUrl}contact-us`} target='_blank'>
                         <i className="fa fa-phone md-36 "></i>
                </Link>
                
              {/* <i className="material-icons md-36">phone_enabled</i> */}
            </div>
            {/* <div className="btn">
              <i className="material-icons md-36">photo</i>
            </div>
            <div className="btn">
              <i className="material-icons md-36">music_note</i>
            </div>
            <div className="btn">
              <i className="material-icons md-36">chat_bubble</i>
            </div>
            <div className="btn">
              <i className="material-icons md-36">settings</i>
            </div>
            <div className="btn">
              <i className="material-icons md-36">phone</i>
            </div>
            <div className="btn">
              <i className="material-icons md-36">V1.0</i>
            </div> */}
              <div className="btn" title='View Version 3.0 Updates'>
                <Link to={`${frontsiteUrl}release-notes`} target='_blank'> v3.0</Link>
              {/* <i className="material-icons md-36">videocam</i> */}
            </div>
          </label>
        </div>
      </div>

        </div>
    </>
  )
}

export default Footer