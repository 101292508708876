import React from 'react'
// import CustombuttonSpinner from '../../components/CustombuttonSpinner'
import { separateComma } from '../../utils/utils'
import TableLoader from '../../components/loaders/TableLoader'

function InvoiceReport({ reportsData, allYear, tableloader, activeYear, changeActiveYear, reportClass }) {
    return (
        <>

            <div className="reports-container">
                <div className='reports-body'>
                    {tableloader === false ?
                        <div className="table-responsive h-100" data-aos="fade-right">
                            {reportsData?.data?.months ?
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th style={{'minWidth': '165px'}}>Months</th>
                                            <th style={{ "width": "75px" }}>Consumers</th>
                                            <th style={{ "width": "75px" }}>{reportClass === "chargable" ? "Invoices" : "Quotations"}</th>
                                            {reportClass === "chargable" && <th style={{ "width": "75px" }}>Total</th>}
                                            {reportClass === "chargable" && <th style={{ "width": "75px" }}>Outstanding</th>}
                                            {reportClass === "chargable" && <th style={{ "width": "120px" }}>Total Tax</th>}
                                            <th style={{ "width": "120px" }}>{reportClass === "chargable" ? "Paid" : "Total"}</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {reportsData?.data?.months.map((record, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{'minWidth': '165px'}}>{record?.month_name}</td>
                                                    <td style={{ "width": "75px" }}>{record?.total_consumers}</td>
                                                    <td style={{ "width": "75px" }}>{record?.total_invoices}</td>
                                                    {reportClass === "chargable" && <td style={{ "width": "75px" }}>{separateComma(record?.actual_amount)}</td>}
                                                    {reportClass === "chargable" && <td style={{ "width": "75px" }}>{separateComma(record?.total_outstandings)}</td>}
                                                    {reportClass === "chargable" && <td style={{ "width": "120px" }}>{separateComma(record?.total_tax)}</td>}
                                                    <td style={{ "width": "120px" }}>{reportClass === "chargable" ? separateComma(record?.total_paid) : separateComma(record?.actual_amount)}</td>

                                                </tr>
                                            )
                                        })

                                        }

                                    </tbody>
                                </table> : <div className='reports-no-result'>There are no reports for {reportClass === "chargable" ? "Invoices" : "Quotations"}.</div>
                            }
                        </div> : (<TableLoader />)
                    }

                </div>

                <div className='reports-footer'>
                    <div className="years-wrapper">
                        <div className='year-inner-container'>
                            {!tableloader &&
                                allYear.map((year, index) => { return (<button className={`btn btn-primary ${activeYear === `${year.year}` ? 'active cursor-disable' : ''}`} onClick={() => changeActiveYear(`${year?.year}`)} key={year?.year} title={`View ${reportClass === "chargable" ? "Invoices" : "Quotations"} Reports For Year ${year?.year}`}><span>{year?.year}</span></button>) })
                            }
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default InvoiceReport
