import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
// import moment from "moment/moment";
import countriesCurrencies from "../../assets/js/countriesCurrency";
import {
  useAddSinglePaymentMutation,
  useDeleteSingleInvoiceFooterImageMutation,
  useDeleteSingleInvoiceLogoMutation,
  useDeleteSingleInvoicePaymentMutation,
  // useEmailInvoiceMutation,
  // useGetInvoiceUniqueNumberQuery,
  // useGetCompanySettingQuery,
  useLazyGetInvoiceUniqueNumberQuery,
  useLazyGetSingleInvoiceQuery,
  useSaveInvoicesMutation,
  useUpdateInvoiceMutation,
  useDeleteSingleInvoiceItemMutation,
  useLazyClientsNameSearchQuery,
  useLazyGetClientDataByIdQuery,
  useLazyItemByNameSearchQuery,
  useLazyGetItemDataByIdQuery,
  useLazyGetCompanySettingQuery,
} from "../../store/apis/baseApi";
// import { useSaveInvoicesMutation } from "../../store/apis/baseApi";
import { currentData, deepCopy } from "../../utils/utils";
import CustomDeleteloader from "../CustomDeleteloader";
import Modal from "../modal/Modal";
import ReactPortal from "../reactPortal/ReactPortal";
import { errorMessage, successMessage } from "../toastMessages/ToastifyMsg";
import Edit from "./Edit";
import InvoicePaymentRecord from "./InvoicePaymentRecord";
import PhotoDetails from "./PhotoDetails";
import Preview from "./Preview";
import SelectInvoiceTemplate from "./SelectInvoiceTemplate";
import TaxationDiscount from "./TaxationDiscount";
import SendEmail from "../SendEmail";
// import moment from "moment/moment";
import { IMG_URL, LARAVELAPI } from "../../common/common";
import { useDispatch, useSelector } from "react-redux";
import { setPlanDetails } from "../../store/slices/subscribedPlanDetails";

// let templateSelected = false;

function Create(props) {
 
  // const liveUrl = "https://stagingapi.tradingforms.com/api/v1/";
  // BASEAPI_PRODUCTION
  // const liveUrl = NODEAPI;
  const imgUrl = IMG_URL;
  const LARAVELurl = LARAVELAPI;
  const allTempStore =  useSelector((state)=>state.allTemplates);

  const dispatch = useDispatch();
  
  const { invoiceId } = useParams();
  let urlId;
  if (invoiceId) {
    urlId = window.atob(invoiceId);
  }
  const invoicePlanDetail = useSelector(state=>state.planDetails);


  
  const [getInvoiceUniqueNumber] = useLazyGetInvoiceUniqueNumberQuery();
  const [getCompanySetting] = useLazyGetCompanySettingQuery();

  const [getSingleInvoice] = useLazyGetSingleInvoiceQuery();
  const [deleteSingleInvoiceLogo] = useDeleteSingleInvoiceLogoMutation();
  const [clientsNameSearch] = useLazyClientsNameSearchQuery();
  const [getClientDataById] =  useLazyGetClientDataByIdQuery();
  const [itemByNameSearch] = useLazyItemByNameSearchQuery();
  const [getItemDataById] = useLazyGetItemDataByIdQuery();

  const [showForm, setShowForm] = useState(0);
  const [logoImage, setLogoImage] = useState(null);
  const [footerImagesA, setFooterImagesA] = useState([]);
  const [tempLogo, setTempLogo] = useState(null);
  const [isFooterOpen, setIsFooterOpen] = useState(false);
  const [addedImage, setAddedImage] = useState(null);
  const [footerModalInput, setFooterModalInput] = useState();
  const [footerImagesCol, setFooterImagesCol] = useState([]);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [pdfId,setPdfID] = useState('');

  const [deleteSingleInvoiceFooterImage] = useDeleteSingleInvoiceFooterImageMutation();
  // const [emailInvoice] = useEmailInvoiceMutation();
  // const [saveInvoices] = useSaveInvoicesMutation();
  const [addSinglePayment] = useAddSinglePaymentMutation();
  const [deleteSingleInvoicePayment] = useDeleteSingleInvoicePaymentMutation();
  const [deleteSingleInvoiceItem] = useDeleteSingleInvoiceItemMutation();

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [templateSelect, setTemplateSelect] = useState(true);
  const [loader, setLoader] = useState(true);
  const [delLoader, setDelLoader] = useState(false);
  const [postLoader, setPostLoader] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [emailbuttonLoader, setEmailbuttonLoader] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [showSuggest, setShowSuggest] = useState(false);
  const [showSuggestItem,setShowSuggestItem] =useState(false);


  
  // if(showForm === 0 && !templateSelect){
  //   setTemplateSelect(true);
  // }

  const [payments, setPayments] = useState([]);

  // FormData Body
  const [formData, setFormData] = useState({
    invoiceHeader: "",
    logo: null,
    color: "#00acee",
    type: "chargable",
    senderName: "",
    senderEmail: "",
    senderStreet: "",
    senderCityState: "",
    senderZipCode: "",
    senderWebsite: "",
    senderPhone: "",
    senderFax: "",
    senderGst: "",
    senderNtn: "",
    invoiceNumber: "",
    invoicedate: "",
    terms: "none",
    termValue: "",
    customerWebsite: "",
    customerName: "",
    customerEmail: "",
    customerStreet: "",
    customerCityState: "",
    customerZipCode: "",
    customerFax: "",
    customerGst: "",
    customerNtn: "",
    customerPhone: "",
    description: [
      {
        id: uuid(),
        itemDescription: "",
        additionalDetail: "",
        rate: "0.00",
        quantity: "1",
        amount: "0.00",
        tax: "false",
        state: "new",
      },
    ],
    paid: false,
    taxType: "none",
    taxLabel: "Tax",
    taxRate: 0,
    taxInclusive: "false",
    discountType: "none",
    discountValue: 0,
    currency: countriesCurrencies[0],
    subTotal: 0,
    discountAmount: 0,
    totalTax: 0,
    total: 0,
    totalPaid: 0,
    balanceDue: 0,
    notes: "",
    selectedTemplate: "",
    // footerImages: [
    //   // {
    //   //   img_filename: '',
    //   //   img_description: '',
    //   //   img_additional_notes : '',
    //   // }
    // ],
    previewEmail: "",
    status: "outstanding",
  });

  // sendEmail Body
  const [emailBody, setEmailBody] = useState({
    id: "",
    email_to: "",
    email_body: "Kindly find the attached document.",
    attachment: "true",
    cc_status : "false",
    cc_emails: ""
  });

  // ------- Templates api call -------
  // const handleDefaultTemplate = (value) => {
  //   if (!props.updating && formData?.selectedTemplate === "") {
  //     setFormData((prevState) => {
  //       return { ...prevState, selectedTemplate: value };
  //     });
  //   }
  // };

  useEffect(() => {
    try {
      setLoader(true);
      let allTemps = deepCopy(allTempStore.allTemplates);
        let filterTemps = allTemps?.filter(
          (temp) => temp?.template_type === "invoice"
        );
        
        if (filterTemps.length) {
          setAllTemplates(filterTemps);
          // handleDefaultTemplate(filterTemps[0].id);
          
        }
    } catch (error) {}finally{
      setTimeout(() => {
            
      setLoader(false);
    }, 500);}
        
  }, [allTempStore]);

  // ------- Company Settings  api call --------------
  useEffect(() => {
    if (!props.updating) {
      setLoader(true);
      const compantSettings = async () => {
        try {
          const { data, error } = await getCompanySetting();
          if (data) {
            if (data.success) {
              if (data && Object.keys(data.data).length) {
                let companySettings = deepCopy(data.data);
                setFormData((prevState) => {
                  return {
                    ...prevState,
                    currency: JSON.parse(companySettings?.currency),
                    color: companySettings?.company_color,
                    invoiceHeader: companySettings?.invoice_title,
                    senderName: companySettings?.company_name,
                    senderEmail: companySettings?.company_email,
                    senderStreet: companySettings?.company_address1,
                    senderCityState: companySettings?.company_address2,
                    senderWebsite: companySettings?.company_website,
                    senderZipCode: companySettings?.company_zip_code,
                    senderPhone: companySettings?.company_phone,
                    senderFax: companySettings?.company_fax,
                    senderGst: companySettings?.company_gst,
                    senderNtn: companySettings?.company_ntn,
                    taxType: companySettings?.tax_type || "none",
                    taxLabel: companySettings?.tax_label,
                    taxRate: companySettings?.tax_rate,
                    description: prevState.description.map((item) => {
                      return ({
                        ...item,
                        tax: companySettings?.tax_type === "onTotal" ? "true" : item.tax ?? "false"
                      })
                    }
                    ),
                    notes: companySettings?.company_default_note,
                  };
                });
                if (
                  companySettings?.company_logo !== "NULL" &&
                  companySettings?.company_logo !== "null" &&
                  companySettings?.company_logo !== null
                ) {
                  // let imgUrl = "https://stagingapi.tradingforms.com/";
                  setLogoImage(`${imgUrl}${companySettings?.company_logo}`);
                  setTempLogo(companySettings?.company_logo);
                }

                // setLoader(false);
              }
            }
            if (!data.success) {
              // setLoader(false);
              errorMessage(data.message)
            }
          }

          if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
            // setLoader(false);
          }

        } catch (error) {

        }finally{setLoader(false);}

      }
      compantSettings();
    }
  }, [imgUrl,getCompanySetting, props.updating]);

  // ------- unique number for invoice api call ------
  useEffect(() => {
    if (!props.updating) {
      setLoader(true);
      (async () => {
        try {
          setLoader(true);
          const { data, error } = await getInvoiceUniqueNumber();

        if (data) {
          if (data.success) {
            let uniqueinvoice = deepCopy(data.data);
            if (uniqueinvoice !== "") {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  invoiceNumber: uniqueinvoice,
                };
              });
            }
            if (data.data.length === 0) {
              uniqueinvoice = "TFINV-0001";
              setFormData((prevState) => {
                return {
                  ...prevState,
                  invoiceNumber: uniqueinvoice,
                };
              });
            }
            // setLoader(false);
            
          }
          if (!data.success) {
            // console.log(data.message);
            errorMessage(data.message);
          }
        }
        if (error) {
          // console.log("Error in fetching data invoice number", error.data.error.message);
          errorMessage(error?.error ?? `Unique ${error.data.error.message}` ?? error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
          // setLoader(false);
        }
        } catch (error) {
          // console.log("catchError")
        }
        finally{setLoader(false);}
        
      })();
    }
  }, [getInvoiceUniqueNumber,props.updating]);

  // -------- get Single invoice api call------------

  const singleDataSet = (data) => {
    let invoiceData = deepCopy(data.invoicedata[0]);
    let descriptionArray = deepCopy(data.description);
    let paymentsRecords = deepCopy(data.payments);
    let footerAllImages = deepCopy(data.FooterImages);
    descriptionArray = descriptionArray.map((item) => {
      return {
        // tax: item.tax === "true" ? "true" : "false",
        state: "old",
        ...item,
      };
    });

    let date = new Date(invoiceData.invoicedate);
    var invoiceDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    setPdfID(invoiceData?.suid_inv_id);
    setFormData((prevState) => {
      return {
        ...prevState,
        invoiceHeader: invoiceData.invoiceHeader,
        logo: invoiceData.logo,
        color: invoiceData.color,
        senderName: invoiceData.senderName,
        senderEmail: invoiceData.senderEmail,
        senderStreet: invoiceData.senderStreet,
        senderCityState: invoiceData.senderCityState,
        senderZipCode: invoiceData.senderZipCode,
        senderPhone: invoiceData.senderPhone,
        senderFax: invoiceData.senderFax,
        senderGst: invoiceData.senderGst,
        senderNtn: invoiceData.senderNtn,
        senderWebsite: invoiceData.senderWebsite,
        invoiceNumber: invoiceData.invoiceNumber,
        invoicedate: invoiceDate,
        terms: invoiceData.terms,
        termValue: invoiceData.termValue,
        customerName: invoiceData.customerName,
        customerEmail: invoiceData.customerEmail,
        customerWebsite: invoiceData.customerWebsite,
        customerStreet: invoiceData.customerStreet,
        customerCityState: invoiceData.customerCityState,
        customerZipCode: invoiceData.customerZipCode,
        customerFax: invoiceData.customerFax,
        customerGst: invoiceData.customerGst,
        customerNtn: invoiceData.customerNtn,
        customerPhone: invoiceData.customerPhone,
        description: descriptionArray,
        // paid: false,
        // payments: paymentsRecords,
        taxType: invoiceData.taxType,
        taxLabel: invoiceData.taxLabel,
        taxRate: invoiceData.taxRate,
        taxInclusive: invoiceData.taxInclusive,
        discountType: invoiceData.discountType,
        discountValue: Number(invoiceData.discountValue),
        currency: JSON.parse(invoiceData.currency),
        subTotal: invoiceData.subTotal,
        discountAmount: invoiceData.discountAmount,
        totalTax: invoiceData.totalTax,
        total: invoiceData.total,
        totalPaid: invoiceData.totalPaid,
        balanceDue: invoiceData.balanceDue,
        notes: invoiceData.notes,
        selectedTemplate: JSON.stringify(invoiceData.selectedTemplate),
        // footerImages: [
        //   // {
        //   //   img_filename: '',
        //   //   img_description: '',
        //   //   img_additional_notes : '',
        //   // }
        // ],
        previewEmail: invoiceData.previewEmail,
        status: invoiceData.status,
      };
    });
    setEmailBody((prevState) => {
      return {
        ...prevState,
        id: urlId,
        email_to: invoiceData.customerEmail,
        // email_body: `Kindly find the attached invoice.`,
      };
    });
    setPayments(paymentsRecords);

    let arrays = footerAllImages.map((item) => {
      let link = imgUrl.concat(item.img_filename);
      return { ...item, img_filename: link };
    });
    setFooterImagesA([]);
    setFooterImagesCol(arrays);

    setTempLogo(invoiceData.invoiceLogo);
    if (
      invoiceData.invoiceLogo !== "NULL" &&
      invoiceData.invoiceLogo !== null && invoiceData.invoiceLogo
    ) {
      setLogoImage(`${imgUrl}${invoiceData.invoiceLogo}`);
    } else {
      setLogoImage(null);
    }
    setShowForm(1);
  }

  useEffect(() => {
    if (props.updating) {
      setLoader(true);
      setTemplateSelect(false);
      (async () => {
        try {
          setLoader(true);
          const { data, error } = await getSingleInvoice(urlId);
          if (data) {
            if (data.success) {
              singleDataSet(data);
            }
            if (!data.success) {
              errorMessage(data?.message);
            }
          }
          if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error?.data?.message);
            // setLoader(false);
          }
          
        } catch (error) {}finally{setLoader(false);}
      })();
    }
  }, [urlId, props.updating, getSingleInvoice,imgUrl]);

  // =========Setting current date of invoice START ================
  useEffect(() => {
    if (!props.updating) {
      setFormData((prevState)=>{
        return{
        ...prevState,
          invoicedate : currentData()
      }
      })
    }
  }, [props.updating]);
  // =========Setting current date of invoice END ================

  

  // =========== Copy to clipboard ===========
  const copyToClip = async () => {
    try {
      // https://stagingfront.tradingforms.com/invoice_preview?id=
      let url = `${LARAVELurl}invoice_preview?id=${pdfId}`;
      await navigator.clipboard.writeText(url);
      // alert('URL copied to clipboard!');
      successMessage("Shareable Link Copied To Clipboard!");
    } catch (error) {
      // console.error("Failed to copy URL:", error);
      errorMessage("Failed to copy shareable Link:");
    }
  };
 
  const taxOnTotal = () => {
    setFormData((prevState) => ({
      ...prevState,
      description: prevState?.description.map((item) => ({
        ...item,
        tax: "true",
      })),
    }));
  }
  
  
  // handle input fields
  const handleBlur = (e) => {
    e.preventDefault();
    // console.log(e.target.name);
    // console.log(e.target.value);
    let { name, value } = e.target;
    if (name === "currency") {
      setFormData((prevState) => {
        return { ...prevState, [name]: JSON.parse(e.target.value) };
      });
      return;
    }

    if (name === "taxType" && e.target.value === "none") {
      setFormData((prevState) => {
        return { ...prevState, taxRate: 0 };
      });
    }

    if (name === "discountType" && e.target.value === "none") {
      setFormData((prevState) => {
        return { ...prevState, discountValue: 0 };
      });
    }
    if (name === "invoicedate") {
      // console.log("e.target.value",e.target.value);
      let value = e.target.value;
      if (formData?.terms !== "none") {
        let date = new Date(value);

        let addedDays = date.addDays(Number(formData?.terms));

        let final = date.toISOString(addedDays);
        let conver = new Date(final);

        let invoicesDate = new Date(
          conver.getTime() - conver.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];

        // console.log(date.toISOString(addedDays),"<<<invoicesDate>>>",invoicesDate);
        setFormData((prevState) => {
          return {
            ...prevState,
            invoicedate: e.target.value,
            termValue: invoicesDate,
          };
        });
      } else {
        setFormData((prevState) => {
          return { ...prevState, invoicedate: e.target.value, termValue: "" };
        });
      }
    }
    if (name === "terms") {
      let termsinput = e.target.value;
      // console.log("select-termsinput>>",termsinput);
      if (termsinput !== "none") {
        let date = new Date(formData?.invoicedate);
        let addedDays = date.addDays(Number(termsinput));
        // console.log("select-addedDays>",addedDays);
        let final = date.toISOString(addedDays);
        // console.log("select-final>>>>",final);
        let conver = new Date(final);

        let invoicesDate = new Date(
          conver.getTime() - conver.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];

        // console.log(invoicesDate);

        setFormData((prevState) => {
          return { ...prevState, term: termsinput, termValue: invoicesDate };
        });
      } else {
        setFormData((prevState) => {
          return { ...prevState, term: termsinput, termValue: "" };
        });
      }
    }
    
    if (
      name !== "termValue" 
      
    ) {
      // console.log("others",e.target.value)
      if (name === "customerName") {
        
        e.target.value.trim() ? setShowSuggest(true) : setShowSuggest(false);
      }
      if (name === "taxType" && value ==="onTotal") {
        taxOnTotal();
      }
      setFormData((prevState) => {
        return { ...prevState, [name]: e.target.value };
      });
    }
  };

  const handleOnChangeSelectCurrency= (currency)=>{
    setFormData((prevState) => {
      return { ...prevState, currency: currency };
    });
  }

  const handleSelectTemplate = (value) => {
    setFormData((prevState) => {
      return { ...prevState, selectedTemplate: `${value}` };
    });
  }

  // handle phone/fax numbers fields 
  const handleTeleFields = (name, value) => {
    setFormData((prevState)=>{
      return{
        ...prevState,
        [name]: value
      }
    });
  }


  // remove image from front site,from DB and also empty the state which is being added in formData while submiting form
  const removeImage = async () => {
    setLogoImage(null);
    setTempLogo(null);

    if (props.updating) {
      setDelLoader(true);
      const { data, error } = await deleteSingleInvoiceLogo(urlId);
      if (data) {
        // console.log("logoDeleted",data);
        if (data.success) {
          // console.log("data.success>logoDeleted",data);
          setDelLoader(false);
          successMessage(data.message);
        }
        if(!data.success){
          setDelLoader(false);
          errorMessage(data.message);
        }
      }
      if (error) {
        // console.log("logo! Deleted,error", error.data.message);
        errorMessage(error?.error ?? error?.data?.error?.message);
        setDelLoader(false);
        // errorMessage("Logo not Deleted!");
      }
    }
  };

  const addFooterImages = (event) => {
    // console.log("image",event.target.files[0]);
    const file = event.target.files[0];
    // console.log(event,file);
    if (file) {
      let uid = uuid();
      // console.log("uuid", uid);
      setFooterImagesA((prevState) => {
        return [
          ...prevState,
          {
            id: uid,
            img_filename: file,
            img_description: "",
            img_additional_notes: "",
          },
        ];
      });
      // console.log("footerImagesA>>",footerImagesA)

      // // console.log("footerImagesA", footerImagesA);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        // console.log(reader.result)
        setAddedImage({
          id: uid,
          img_filename: reader.result,
          img_description: "",
          img_additional_notes: "",
        });
        setFooterImagesCol((prevState) => {
          return [
            ...prevState,
            {
              id: uid,
              img_filename: reader.result,
              img_description: "",
              img_additional_notes: "",
            },
          ];
        });
      };
      // console.log(footerImagesCol,footerImagesA);
      setFooterModalInput(uid);
      // console.log("addedImage",addedImage || '');
      setIsFooterOpen(true);

      event.target.value = "";
    }
    // console.log("afterIF>>",footerImagesA);
  };

  const removeSingleFooterImage = async (id) => {
    // console.log(id);
    setFooterImagesA((prevState) => prevState.filter((item) => item.id !== id));
    setFooterImagesCol((prevState) =>
      prevState.filter((item) => item.id !== id)
    );
    if (props.updating) {
      setDelLoader(true);
      const { data, error } = await deleteSingleInvoiceFooterImage(id);
      if (data) {
        // console.log("Single footer ImageDeleted from DB",data);
        if(data.success) {
          // console.log("data.success>>Single footer ImageDeleted from DB",data);
          setDelLoader(false);
          successMessage(data.message);
        }
        if(!data.success) {
            errorMessage(data.message);
            // console.log("!success>footerImageDeleted");
            setDelLoader(false);
        }
      }
      if (error) {
        // console.log("logoNot Deleted from DB", error);
        setDelLoader(false);
        errorMessage(error.data.error.message);
      }
    }
  };

  // const updateImageDetail = (id) => {
  //   let selected = footerImagesCol.filter((item) => {
  //     return item.id === id;
  //   });
  //   let updateItem = selected[0];
  //   // console.log(selected[0]);
  //   setAddedImage(updateItem);
  //   setFooterModalInput(updateItem.id);
  //   setIsFooterOpen(true);
  // };

  // const handleFooterModalClose = useCallback(() => {
  //   setIsFooterOpen(false);
  //   setAddedImage(null);
  //   setFooterModalInput(null);
  // }, []);

  const footerInnerModalClose = (id) => {
    // console.log(id);
    let datas = footerImagesA.filter((item) => item.id !== id);

    setFooterImagesA(datas);
    // (prevState) => prevState.filter((item) => item.id !== id)
    // console.log("datas", datas, "footerImagesA>>", footerImagesA);
    let apiArray = footerImagesCol.filter((item) => item.id !== id);
    setFooterImagesCol(apiArray);
    // showDaaaa();
    setIsFooterOpen(false);
    setAddedImage(null);
  };

  const footerModalForm = (e, id) => {
    e.preventDefault();
    let desc = e.target.img_description.value;
    let additionalDetail = e.target.img_additional_notes.value;

    // console.log(desc, additionalDetail, id);
    setFooterImagesA((prevState) => {
      return prevState.map((item) => {
        // let updatedItem = {...item}
        if (item.id === id) {
          item.img_description = desc;
          item.img_additional_notes = additionalDetail;
        }
        return item;
      });
    });

    setFooterImagesCol((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          item.img_description = desc;
          item.img_additional_notes = additionalDetail;
        }
        return item;
      });
    });
    setIsFooterOpen(false);
    // console.log(footerImagesA, footerImagesCol);
  };

  // ============================================================
  // add image in states to display image on personal detail logo and also updating the state which is being added in formData while submiting form
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTempLogo(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setLogoImage(reader.result);
      };
      event.target.value = "";
    }
    // console.log(logoImage);
    // console.log(formData.logo);
  };

  function handleAddDescription(descriptionItem) {
    setFormData(
      (prevState) => {
        return {
          ...prevState,
          description: [...prevState.description, descriptionItem],
        };
      }
      // ,()=>{
      //   console.log(formData);
      // }
    );
    // console.log(formData);
  }
   // =============calculations START =============
   useEffect(() => {
    setFormData((prevState) => {
      let totalSum = 0;
      let totalTax = 0;
      let grandTotal = 0;
      let discount = 0;
      let totalAfterDiscount = 0;
      let afterDiscTaxableArray = [];
      let afterDiscNonTaxableArray = [];
      let totalPayed = 0;
      let balanceDue = 0;
      let taxableArray = 0;
      let nonTaxableArray = 0;
      let taxableSum = 0;
      let nonTaxableSum = 0;
      prevState?.description.forEach((value) => {
        totalSum = Number(totalSum + value.rate * value.quantity);
      });
      //==== filtering non-taxable items
      nonTaxableArray = prevState?.description.filter((desc) => {
        return desc.tax === "false";
      });
      //==== filtering taxable items
      taxableArray = prevState?.description.filter((desc) => {
        return desc.tax === "true";
      });
      

      if (prevState?.discountType === "percent") {
        
        discount = Number((prevState?.discountValue / 100) * totalSum);
        let discPer= prevState?.discountValue;

        afterDiscNonTaxableArray =  nonTaxableArray.map((item)=>{
            let afterDiscount =  item.amount  - (discPer *item.amount)/100;
            return {
              ...item,
              afterDiscountAmount: afterDiscount}
        })
        afterDiscTaxableArray = taxableArray.map((item)=>{
          let afterDiscount =  item.amount  - (discPer *item.amount)/100;
          return {
            ...item,
            afterDiscountAmount: afterDiscount}
        }) 
        // console.log("afterDiscNonTaxableArray",afterDiscNonTaxableArray,"afterDiscTaxableArray",afterDiscTaxableArray)
      }

      if(prevState?.discountType === "flatAmount") {
        discount = Number(prevState?.discountValue);
        let totalItems = 0;
        let discountPerItem = 0;
        prevState.description.forEach((item)=>{
          if (item.quantity > 0 && item.rate > 0) {
            totalItems+=1;
            discountPerItem = discount/totalItems;
          }
        })
        // console.log("totalItems>>",totalItems,"discountAmount",discount);
        // console.log("discountOnEachItem-discount/totalItems>>",(discount/totalItems));
         
        afterDiscNonTaxableArray =  nonTaxableArray.map((item)=>{
          let afterDiscount=0; 
          if (item.quantity > 0 && item.rate > 0) {
            afterDiscount =  item.amount - discountPerItem;
            
          }else{
            afterDiscount =  0 ;
          }
          return {
            ...item,
            afterDiscountAmount: afterDiscount}
      })
      // console.log()
      afterDiscTaxableArray = taxableArray.map((item)=>{
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount =  item.amount - discountPerItem;
          
        }else{
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount}
      }) 

        totalAfterDiscount = (taxableSum + nonTaxableSum) - discount;
        
        // console.log("totalAfterDiscount>>",totalAfterDiscount);
        
      }
      if (prevState?.discountType === "none") {

        afterDiscNonTaxableArray =  nonTaxableArray.map((item)=>{
          let afterDiscount=0; 
          if (item.quantity > 0 && item.rate > 0) {
            afterDiscount =  item.amount;
            
          }else{
            afterDiscount =  0 ;
          }
          return {
            ...item,
            afterDiscountAmount: afterDiscount}
      })
      // console.log()
      afterDiscTaxableArray = taxableArray.map((item)=>{
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount =  item.amount ;
          
        }else{
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount}
      }) 
      }
     
      
      if (formData?.taxType === "onTotal" || formData?.taxType === "perItem") {
        afterDiscTaxableArray.forEach((item)=>{
          if (item.quantity > 0 && item.rate > 0) {
            taxableSum = taxableSum + (prevState.taxRate * item.afterDiscountAmount) / 100; 
          }
        })  
        totalTax = taxableSum;
        grandTotal = Number(totalTax + totalSum - discount);
        
      }
     

      // calculating if tax type deducted
      if (formData?.taxType === "deducted") {
        afterDiscTaxableArray.forEach((item)=>{
            if (item.quantity > 0 && item.rate > 0) {
              taxableSum +=   (prevState.taxRate * item.afterDiscountAmount) / 100;
            }
        })
        totalTax = taxableSum;


        grandTotal = Number(totalSum - totalTax - discount);
          // console.log("taxDeducted>>",taxDeducted,"grandTotal>>",grandTotal)
      }
      if (formData?.taxType === "none") {
        grandTotal = Number(totalTax + totalSum - discount);
      }
    
     

      // calculating total payments been made

      payments.forEach((payment) => {
        totalPayed = Number(totalPayed + Number(payment?.amountPaid));
      });
      //
      totalPayed = totalPayed.toFixed(2);
      let finalTotalSum = Number(totalSum).toFixed(2);
      let finalDiscount = Number(discount).toFixed(2);
      let finalTotalTax = Number(taxableSum).toFixed(2);
      let finalGrandTotal = Number(grandTotal).toFixed(2);
      balanceDue = finalGrandTotal - totalPayed;
      let statu;
      if (totalPayed >= balanceDue) {
        statu = "paid";
      } else {
        statu = "outstanding";
      }
     
      return {
        ...prevState,
        subTotal: finalTotalSum,
        discountAmount: Number(finalDiscount),
        totalTax: finalTotalTax,
        total: finalGrandTotal,
        balanceDue: Number(balanceDue),
        totalPaid: totalPayed,
        status: statu,
      };
    });
  }, [
    formData?.description,
    payments,
    formData?.taxRate,
    formData?.taxType,
    formData?.discountValue,
    formData?.discountType,
  ]);
  // =============calculations END =============
  const calculateTotals = (newformData) => {
    let totalSum = 0;
      let totalTax = 0;
      let grandTotal = 0;
      let discount = 0;
      let totalAfterDiscount = 0;
      let afterDiscTaxableArray = [];
      let afterDiscNonTaxableArray = [];
      let totalPayed = 0;
      let balanceDue = 0;
      let taxableArray = 0;
      let nonTaxableArray = 0;
      let taxableSum = 0;
      let nonTaxableSum = 0;
      newformData?.description.forEach((value) => {
        totalSum = Number(totalSum + value.rate * value.quantity);
      });
      //==== filtering non-taxable items
      nonTaxableArray = newformData?.description.filter((desc) => {
        return desc.tax === "false";
      });
      //==== filtering taxable items
      taxableArray = newformData?.description.filter((desc) => {
        return desc.tax === "true";
      });
      
      

      if (newformData?.discountType === "percent") {
        
        discount = Number((newformData?.discountValue / 100) * totalSum);
        let discPer= newformData?.discountValue;

        afterDiscNonTaxableArray =  nonTaxableArray.map((item)=>{
            let afterDiscount =  item.amount  - (discPer *item.amount)/100;
            return {
              ...item,
              afterDiscountAmount: afterDiscount}
        })
        afterDiscTaxableArray = taxableArray.map((item)=>{
          let afterDiscount =  item.amount  - (discPer *item.amount)/100;
          return {
            ...item,
            afterDiscountAmount: afterDiscount}
        }) 
      }

      if(newformData?.discountType === "flatAmount") {
        discount = Number(newformData?.discountValue);
        let totalItems = 0;
        let discountPerItem = 0;
        newformData.description.forEach((item)=>{
          if (item.quantity > 0 && item.rate > 0) {
            totalItems+=1;
            discountPerItem = discount/totalItems;
          }
        })
         
        afterDiscNonTaxableArray =  nonTaxableArray.map((item)=>{
          let afterDiscount=0; 
          if (item.quantity > 0 && item.rate > 0) {
            afterDiscount =  item.amount - discountPerItem;
            
          }else{
            afterDiscount =  0 ;
          }
          return {
            ...item,
            afterDiscountAmount: afterDiscount}
      })

      afterDiscTaxableArray = taxableArray.map((item)=>{
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount =  item.amount - discountPerItem;
          
        }else{
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount}
      }) 

        totalAfterDiscount = (taxableSum + nonTaxableSum) - discount;
        
      }
      if (newformData?.discountType === "none") {

        afterDiscNonTaxableArray =  nonTaxableArray.map((item)=>{
          let afterDiscount=0; 
          if (item.quantity > 0 && item.rate > 0) {
            afterDiscount =  item.amount;
            
          }else{
            afterDiscount =  0 ;
          }
          return {
            ...item,
            afterDiscountAmount: afterDiscount}
      })
      afterDiscTaxableArray = taxableArray.map((item)=>{
        let afterDiscount = 0;
        if (item.quantity > 0 && item.rate > 0) {
          afterDiscount =  item.amount ;
          
        }else{
          afterDiscount = 0;
        }
        return {
          ...item,
          afterDiscountAmount: afterDiscount}
      }) 
      }
      
      
      if (formData?.taxType === "onTotal" || formData?.taxType === "perItem") {
        afterDiscTaxableArray.forEach((item)=>{
          if (item.quantity > 0 && item.rate > 0) {
            taxableSum = taxableSum + (newformData.taxRate * item.afterDiscountAmount) / 100; 
          }
        })  
        totalTax = taxableSum;
        grandTotal = Number(totalTax + totalSum - discount);
        
      }
     

      // calculating if tax type deducted
      if (formData?.taxType === "deducted") {
        afterDiscTaxableArray.forEach((item)=>{
            if (item.quantity > 0 && item.rate > 0) {
              taxableSum +=   (newformData.taxRate * item.afterDiscountAmount) / 100;
            }
        })
        totalTax = taxableSum;


        grandTotal = Number(totalSum - totalTax - discount);
      }
      if (formData?.taxType === "none") {
        grandTotal = Number(totalTax + totalSum - discount);
      }

      // calculating total payments been made

      payments.forEach((payment) => {
        totalPayed = Number(totalPayed + Number(payment?.amountPaid));
      });
      //
      totalPayed = totalPayed.toFixed(2);
      let finalTotalSum = Number(totalSum).toFixed(2);
      let finalDiscount = Number(discount).toFixed(2);
      let finalTotalTax = Number(taxableSum).toFixed(2);
      let finalGrandTotal = Number(grandTotal).toFixed(2);
      balanceDue = finalGrandTotal - totalPayed;
      let statu;
      if (totalPayed >= balanceDue) {
        statu = "paid";
      } else {
        statu = "outstanding";
      }
     
      return {
        subTotal: finalTotalSum,
        discountAmount: finalDiscount,
        totalTax: finalTotalTax,
        total: finalGrandTotal,
        balanceDue: balanceDue,
        totalPaid: totalPayed,
        status: statu,
      };
  };

  async function handleRemoveDescription(id) {
   
    const updatedFormData = {
      ...formData,
      description: formData.description.filter((description) => description.id !== id),
    };
    setFormData(updatedFormData);
    let deleted = formData?.description.filter(
      (description) => description.id === id
    )

    if(deleted[0].state === "old") {
      
      const updatedCalculation = calculateTotals(updatedFormData);
      try {
        setDelLoader(true);
        let body = {
          id,
          invoiceID: urlId,
          subTotal: Number(updatedCalculation?.subTotal),
          total: Number(updatedCalculation?.total),
          balanceDue: updatedCalculation?.balanceDue,
          status: updatedCalculation?.status
        }
        const { data, error } = await deleteSingleInvoiceItem(body);
        if (data) {
          if (data.success) {
            successMessage(data.message);
          }
          if (!data.success) {
            errorMessage(data?.message);
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error?.data?.message);
        }
      } catch (error) {
      }
      finally {
        setDelLoader(false);
      }
      
    }
  }

  function handleEditDescription(e, id) {
      // console.log("handleEditDescription>>e.target>>", e.target,id)
    setFormData((prevState) => {
      let newState = deepCopy(prevState);
      let oldDesc = newState.description.find((desc) => desc.id === id);

      if (oldDesc) {
        // console.log("afawf>>>",e.target.value)
        if (e.target.name === "itemDescription") {
          e.target.value.trim() ? oldDesc.showSuggestion = true : oldDesc.showSuggestion = false;
        }
        oldDesc[e.target.name] = e.target.value;
      }

      // if (e.target.name === "rate" || e.target.name === "quantity") {
        // newState.subTotal = asdsa
        // newState.total = asdsa
        let amount = (
          Number(oldDesc?.quantity) * Number(oldDesc?.rate)
        ).toFixed(2);
        oldDesc["amount"] = amount;
      // }

      return newState;
    });
  }

 
      // delete payment
  const handleDeletePayment = async (id) => {
    setDelLoader(true);
    try {
      let deletedPayment= payments.filter((payment)=> payment.id === id);
      deletedPayment = deletedPayment[0];
       setPayments(payments.filter((payment) => payment.id !== id));
    let updatetotalPaid = 0;
    // let updatedPayment = payments.filter((payment) => payment.id !== id);

    payments.forEach((payment) => {
      updatetotalPaid = Number(updatetotalPaid + Number(payment?.amountPaid));
    });
    updatetotalPaid = updatetotalPaid - Number(deletedPayment.amountPaid);

    let newStatus = updatetotalPaid >= formData?.total ? "paid":"outstanding" ;
    
    // console.log("status:",formData?.status," Balance:", formData?.balanceDue,"Total:",formData?.total)
    let dueBalance = formData?.total - updatetotalPaid
    let body = {
      id,
      invoiceID: urlId, 
      status:newStatus,
      totalPaid: JSON.stringify(updatetotalPaid),
      balanceDue: JSON.stringify(dueBalance),
    }
    const { data, error } = await deleteSingleInvoicePayment(body);
    if (data) {
      if (data.success) {

        setDelLoader(false);
        successMessage(data.message);
      }
      if (!data.success) {
        errorMessage(data.message);
        setDelLoader(false);
      }
    }
    if (error) {
      setDelLoader(false);
      errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
    }
    } catch (error) {
    }
    finally{
      setDelLoader(false);
    }
    // }
  };
      //add payment
  const handleAddPayment = async (paymentItem) => {
    try {
      setPostLoader(true);

    // console.log({ id: invoiceId,amountPaid: paymentItem.amountPaid,paymentDate: paymentItem.paymentDate,paymentMethod: paymentItem.paymentMethod,paymentNote: paymentItem.paymentNote,})
    let updatetotalPaid = 0;
    payments.forEach((payment) => {
      updatetotalPaid = Number(updatetotalPaid + Number(payment?.amountPaid));
    });
    updatetotalPaid = updatetotalPaid + Number(paymentItem.amountPaid);
    // console.log("updatetotalPaid>>",updatetotalPaid,paymentItem.amountPaid);

    let due = Number(formData?.total) - updatetotalPaid;
    // console.log("due>",due, "updatetotalPaid",updatetotalPaid,Number(formData?.total) === updatetotalPaid ? "paid":"outstanding"  )
    let newStatus = Number(formData?.total) === updatetotalPaid ? "paid":"outstanding";

    const { data, error } = await addSinglePayment({
      id: urlId,
      amountPaid: paymentItem.amountPaid,
      paymentDate: paymentItem.paymentDate,
      paymentMethod: paymentItem.paymentMethod,
      paymentNote: paymentItem.paymentNote,
      status: newStatus,
      totalPaid: updatetotalPaid,
      balanceDue: due,
    });
    if (data) {
      // console.log("If data", data);
      if (data.success) {
        let onePayment = {
          ...paymentItem,
          id: data.recordpaymentID,
        };
        // console.log("add>>>",data.recordpaymentID);
  
        setPayments((prevState) => {
          return [...prevState, onePayment];
        });
        setPostLoader(false);
        successMessage(data.message);
      }
       if (!data.success) {
        setPostLoader(false);
        errorMessage(data.message);
        console.log(data.message);
       } 
      }

    if (error) {
      setPostLoader(false);
      errorMessage(error?.error ?? error?.data?.error?.message);
    }
    } catch (error) {}
    finally{
      setPostLoader(false);
    }
    
  };

  // =================||Modal handling||=====================
  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const handleCloseSelectTemplate = useCallback(() => {
    setTemplateSelect(false);
  }, []);
  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handleOpenSelectTemplate = useCallback(() => {
    setTemplateSelect(true);
  }, []);
  
  const increaseInvoicePlan = (type)=>{
    const newPlanDetails = JSON.parse(JSON.stringify(invoicePlanDetail?.planDetails))
    newPlanDetails.plan_usage_details[type].usage_count += 1
    dispatch(setPlanDetails(newPlanDetails));
  }
  
  const handleOpenEmail = () => {
    if (invoicePlanDetail?.planDetails?.plan_usage_details?.email_sent?.usage_count<invoicePlanDetail?.planDetails?.plan_usage_details?.email_sent?.total) {
      setEmailModal(true);
    }else errorMessage("Limit Reached. Upgrade your plan!");
  };
  
  const handleCloseEmail = () => {
    setEmailModal(false);
    setEmailBody((prevState)=>{
      return{
        ...prevState,
        id: "",
        email_to:"",
        cc_status: "false",
        cc_emails:""
      }
    })
  };
 

  // -------------------Post/Update Invoice-------------
  const [saveInvoices] = useSaveInvoicesMutation();
  const [updateInvoice] = useUpdateInvoiceMutation();


  const handleSubmit = async (e) => {
    setIsConfirmation(false);
    setPostLoader(true);
    if (props.updating) {

      try {
        let updateInvoiceForm = new FormData();
        let body = {
          ...formData,
          description: formData?.description.map((item) => {
            return item.state === "new"
              ? {
                  itemDescription: item.itemDescription,
                  additionalDetail: item.additionalDetail,
                  rate: item.rate.length <1 ? "0.00":item.rate,
                  quantity: item.quantity.length <1 ? "0.00":item.quantity,
                  amount: item.amount.length <1 ? "0.00":item.amount,
                  tax: item.tax,
                }
              : {
                  id: item.id,
                  itemDescription: item.itemDescription,
                  additionalDetail: item.additionalDetail,
                  rate: item.rate.length <1 ? "0.00":item.rate,
                  quantity: item.quantity.length <1 ? "0.00":item.quantity,
                  amount: item.amount.length <1 ? "0.00":item.amount,
                  tax: item.tax,
                };
          }),
          currency: JSON.stringify(formData?.currency),
          footerImagesFieldArray: footerImagesA.map((footerImageDetail) => ({
            img_description: footerImageDetail.img_description,
            img_additional_notes: footerImageDetail.img_additional_notes,
          })),

          // logo: tempLogo,
          // footerImages: footerImagesA.map((item) => ({
          //   // id: item.id,
          //   img_filename: item.img_filename,
          //   img_description: item.img_description,
          //   img_additional_notes: item.img_additional_notes,
          // })),
        };

        if (tempLogo) {
          updateInvoiceForm.append("logo", tempLogo);
        }
        else{
          updateInvoiceForm.append("logo", "");
        }
        footerImagesA.forEach((footerImage) => {
          updateInvoiceForm.append("footerImages", footerImage.img_filename);
        });
        updateInvoiceForm.append("data", JSON.stringify(body));
        const resp = await updateInvoice({ id: urlId, updateInvoiceForm });
        if (resp?.data?.success) {
          successMessage(resp.data.message);
          singleDataSet(resp?.data)
          // navigate("/invoice");
          // setShowForm(1);
        }
        if (!resp?.data?.success) {
            errorMessage(resp?.data?.message);
          }
        if (resp.error) {
          errorMessage(resp?.error?.data?.message ?? resp?.error?.error ?? resp?.error?.data?.error?.message ?? "Server not responding.");
        }
      } catch (error) {
      } finally {
        setPostLoader(false);
      }
    } else {
      try {
        let saveInvoiceForm = new FormData();

        let body = {
          ...formData,
          description: formData?.description.map((item) => ({
            itemDescription: item.itemDescription,
            additionalDetail: item.additionalDetail,
            rate: item.rate.length <1 ? "0.00":item.rate,
            quantity: item.quantity.length <1 ? "0.00":item.quantity,
            amount: item.amount.length <1 ? "0.00":item.amount,
            tax: item.tax,
          })),
          currency: JSON.stringify(formData?.currency),

          footerImagesFieldArray: footerImagesA.map((footerImageDetail) => ({
            img_description: footerImageDetail.img_description,
            img_additional_notes: footerImageDetail.img_additional_notes,
          })),
        };

        if(tempLogo){
          saveInvoiceForm.append("logo", tempLogo);
        }
        footerImagesA.forEach((footerImage) => {
          saveInvoiceForm.append("footerImages", footerImage.img_filename);
        });

        saveInvoiceForm.append("data", JSON.stringify(body));

        const resp = await saveInvoices(saveInvoiceForm);
        if (resp.data) {
          if (resp.data.success) {
            successMessage(resp.data.message);
            // const newPlanDetails = JSON.parse(JSON.stringify(invoicePlanDetail?.planDetails));
            // newPlanDetails.plan_usage_details.invoice.usage_count += 1;
            setTimeout(() => {
              increaseInvoicePlan("invoice")
              // dispatch(setPlanDetails(newPlanDetails));
            }, 10);
            navigate("/invoice");
          }
          if (!resp.data.success) {
              errorMessage(resp.data.message);
          }
        }
        
        if (resp.error) {
            errorMessage(resp.error.data.message);
        }

        // }
      } catch (error) {
       
        // ============================
        errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? error ?? "Server not responding.");
      } finally {
        setPostLoader(false);
      }
      // ========================
    }
  };

  const confirmSave = (type) => {
    if (type === true) {
      // setPostLoader(true);
      handleSubmit();
    }
    if (type === false) {
      setIsConfirmation(false);
    }
  };

  const saveModal = (e) => {
    e.preventDefault();
    setIsConfirmation(true);
  };
  // =============Name Suggestion List START============
  const [suggestList, setSuggestList] = useState([]);

  const autoSuggstion = async (e) => {
    e.preventDefault();
    try {
      let search = e.target.value;
      if (search.trim()) {
        const {data,error} = await clientsNameSearch(search.trim());
        if (data) {
          if (data?.success) {
              let list = deepCopy(data.data);
              setSuggestList(list);
          }
          if (!data?.success) {
              errorMessage(data.message);
          }
        }
        if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
        }
      }
      
    } catch (error) {
      
    }
  };

  const fillFields = async (id) => {
    // console.log("FIELD",id)
    // e.preventDefault();
    try {
      let search = id;
        const {data,error} =await getClientDataById(search);
        if (data) {
          if (data?.success) {
            let list = deepCopy(data.data[0]);
            // setSuggestList(list);
            // setAutoSuggstionInput(list.entity_name);
            setFormData((prevState) => ({
              ...prevState,
              customerName: "",
            }));
    
            let entity_name = list.entity_name || "";
            let entity_city = list.entity_city +", "+ list.entity_state || "";
            let entity_email = list.entity_email || "";
            let entity_fax = list.entity_fax || "";
            let entity_gst = list.entity_gst || "";
            let entity_ntn = list.entity_ntn || "";
            let entity_phone_official = list.entity_phone_official || "";
            let entity_address = list.entity_address || "";
            let entity_website = list.entity_website || "";
            let entity_zipCode = list.entity_zip_code || "";
            setFormData((prevState) => ({
              ...prevState,
              customerName: entity_name,
              customerCityState: entity_city,
              customerEmail: entity_email,
              customerFax: entity_fax,
              customerGst: entity_gst,
              customerNtn: entity_ntn,
              customerPhone: entity_phone_official,
              customerStreet: entity_address,
              customerZipCode: entity_zipCode,
              customerWebsite: entity_website,
            }));
            setShowSuggest(false);
          }
          if (!data?.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
        }
      
    } catch (error) {
      
    }
    finally{
      setShowSuggest(false);
    }

  };
  // =============Name Suggestion List END===============

  // ==============item Suggestion List START=============
  const [allDescitems, setAllDescItems] = useState([]);

  const autoSuggstionitems = async (e) => {
    try {
      e.preventDefault();
    let search = e.target.value;
    const {data,error}  = await itemByNameSearch(search);
    if (data) {
      if (data?.success) {
        let list = deepCopy(data.data);
        setAllDescItems(list);
      }
      if (!data.success) {
        errorMessage(data?.message);
      }
    }
    if (error) {
      errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
    }
    } catch (error) {
      
    }
   
  };

  const handleHideSuggestion = (rowId) => {
    // console.log(formData?.description.filter((item)=>item.id===rowId ))
    if (formData?.description.find((item)=>item.id===rowId && item.showSuggestion===true)) {
      setFormData((prevState) => {
        let newState = deepCopy(prevState);
        let oldDesc = newState.description.find((desc) => desc.id === rowId);
        if (!!oldDesc) {
          oldDesc.showSuggestion = false;
        }
        return newState;
      });
    }
  }
  
  //  autoSuggstionitems();
  const fillItemFields = async (rowId, id) => {
    try {
      const {data,error} = await getItemDataById(id);
      if (data) {
          if (data.success) {
          
           let item = deepCopy(data.data[0]);
            setFormData((prevState) => {
              let newState = deepCopy(prevState);
              let oldDesc = newState.description.find((desc) => desc.id === rowId);
              if (oldDesc) {
                oldDesc.itemDescription = item.item_name;
                oldDesc.additionalDetail = item.description;
                oldDesc.rate = JSON.stringify(item.item_rate);
                oldDesc.tax =   formData?.taxType === "onTotal" ? "true": item.item_texable;
                oldDesc.amount = JSON.stringify(item.item_rate*oldDesc.quantity);
              }
              return newState;
            });
            setShowSuggestItem(false);
          }
          if (!data.success) {
            errorMessage(data?.message)
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding."); 
      }

    } catch (error) {
    } finally {
      handleHideSuggestion(rowId);
    }
  };

  // ==============item Suggestion List END=============

  // ============= SEND EMAIL START ===================
  const emailChange = (e) => {
    e.preventDefault();
    const {name,value} = e.target;
    if (name === "cc_status" & value === "false") {
      setEmailBody((prevState)=>{
        return({
          ...prevState, cc_emails:""
        })
      })
    }
    setEmailBody((prevState)=>{
      return({
        ...prevState, [name]:value
      })
    })
    // console.log(emailBody);
  };

  const handleEmail = async (e) => {
    e.preventDefault();
    setEmailbuttonLoader(true);
    let id = urlId;
    let body = {
      id,
      email_to: emailBody?.email_to,
      email_body: emailBody?.email_body,
      attachment: emailBody?.attachment,
      cc_status: emailBody?.cc_status,
      cc_emails: emailBody?.cc_emails,
      type: "invoice",
    };
    // https://stagingfront.tradingforms.com/api/invoice_email?id=59
    // console.log(body);
    let token = localStorage.getItem("tradingformtoken");
    const {data,error}= await axios(`${LARAVELurl}invoice_email`, {
      method: "POST",
      data: body,
      headers: {
        "Content-Type": "application/json;",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })

    if (data) {
      // console.log("emailSent>>",data);
      if (data.success) {
        setEmailModal(false);
        successMessage(data?.message);
        setEmailbuttonLoader(false);
        increaseInvoicePlan("email_sent");
        setEmailBody((prevState)=>{
          return{
            ...prevState,
            attachment:"true",
            cc_status: "false",
            cc_emails:""
          }
        })
      }
      if (!data.success) {
        setEmailbuttonLoader(false);
        errorMessage(data?.message);
      }
    }
    if (error) {
        // console.log('Error', error.data.message);
        setEmailbuttonLoader(false);
        errorMessage(error?.error ?? error?.data?.error?.message);
    }

  };

  return (
    <>
      {loader && <CustomDeleteloader message="Loading . . ." />}
      {delLoader && <CustomDeleteloader message="Deleting . . ." />}
      {postLoader && (
        <CustomDeleteloader
          message={`${props.updating ? "Updating" : "Saving"} . . .`}
        />
      )}

      <div className="spacer"></div>

      <div className="container">
        <div className="col-11 invoice-detail">
          <div className="col-lg-11 col-xl-10 col-11">
            <div className="breadcrumb-container">
              <nav className="breadcrumbs">
                <Link to="/" className="breadcrumbs__item" title="Go to Dashboard">
                  Dashboard
                </Link>
                <Link to="/invoice" className="breadcrumbs__item" title="Go to Invoices">
                  Invoices
                </Link>
                {/* <Link to="" className="breadcrumbs__item">Cart</Link> */}
                <span className="breadcrumbs__item is-active">
                  {props.updating ? "Edit" : "Create"}
                </span>
              </nav>
              <div className="spacer"></div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="invoice-actions">
              <div className="invoice-navBtn">
                {props.updating && (
                  <span>
                    <button
                      type="button"
                      onClick={() => setShowForm(1)}
                      className={`btn btn-secondary btn-sm me-1 ${
                        showForm === 1 ? "active cursor-disable" : ""
                      }`}
                      disabled={showForm === 1?true:false}
                      title="Click to Preview"
                    >
                      <i className="far fa-eye"></i><span>Preview</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setShowForm(0);
                        // if (!templateSelected) {
                        //   !templateSelected && setTemplateSelect(true);
                        //   templateSelected = true;
                        // }
                      }}
                      title="Click to Edit"
                      className={`btn btn-secondary btn-sm ${
                        showForm === 0 ? "active cursor-disable" : ""
                      }`}
                      disabled={showForm === 0?true:false}
                    >
                      <i className="fas fa-edit"></i><span>Edit</span>
                    </button>
                  </span>
                )}
                {showForm === 0 && (
                    <button
                      type="button"
                      onClick={handleOpenSelectTemplate}
                      className="btn btn-primary btn-sm me-0"
                      title="Click to select Template"
                    >
                      <i className="fa fa-file-invoice"></i> {" "}
                      <span>Select Template</span>
                    </button>)
                }
              </div>
              {props.updating && (
                <div className="invoice-actions-export">
                  <div className="invoice-actions-update">
                    <button
                      type="button"
                      className="btn btn-sm button-record-payment"
                      onClick={handleModalOpen}
                      title="Click to record payments"
                    >
                      <i className="fas fa-money-check-alt"></i>
                       <span>Record Payment</span>
                    </button>
                    <Link
                      to={`${LARAVELurl}invoice_preview?id=${pdfId}`}
                      className="btn btn-sm button-pdf"
                      target="_blank"
                      title="Click to view PDF"
                    >
                      <i className="fa fa-file-pdf"></i><span>PDF</span>
                    </Link>
                    <button
                      type="button"
                      className="btn btn-sm button-copy-url"
                      onClick={copyToClip}
                      title="Click to copy shareable link."
                    >
                      <i className="fa fa-copy"></i><span>Copy URL</span>
                    </button>

                  </div>
                  <button
                    type="button"
                    className="btn button-email-invoice btn-sm"
                    onClick={handleOpenEmail}
                    title="Click to send an email"
                  >
                    <i className="fa fa-envelope"></i>
                    <span>Email Invoice </span>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div className="new-edit-invoice-container">
                {showForm === 1 && (
                  <div data-aos="fade-right">
                    <Preview
                      formData={formData}
                      logoImage={logoImage}
                      footerImagesCol={footerImagesCol}
                      type="chargable"
                    />

                  </div>
                )}
                <form></form>
                {showForm === 0 && (
                  <div data-aos="fade-left">
                    <Edit
                      handleBlur={handleBlur}
                      formData={formData}
                      handleAddDescription={handleAddDescription}
                      handleRemoveDescription={handleRemoveDescription}
                      handleChange={handleEditDescription}
                      // handleSubmit={handleSubmit}
                      handleFileInputChange={handleFileInputChange}
                      logoImage={logoImage}
                      removeImage={removeImage}
                      addFooterImages={addFooterImages}
                      footerImagesA={footerImagesCol}
                      removeSingleFooterImage={removeSingleFooterImage}
                      fillFields={fillFields}
                      autoSuggstion={autoSuggstion}
                      suggestList={suggestList}
                      autoSuggstionitems={autoSuggstionitems}
                      fillItemFields={fillItemFields}
                      allDescitems={allDescitems}
                      saveModal={saveModal}
                      // updateImageDetail={updateImageDetail}
                      update={props.updating}
                      showSuggest={showSuggest}
                      setShowSuggest={setShowSuggest}
                      showSuggestItem={showSuggestItem}
                      setShowSuggestItem={setShowSuggestItem}
                      type="chargable"
                      prefix="Invoice"
                      handleHideSuggestion={handleHideSuggestion}
                      handleTeleFields={handleTeleFields}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3 pt-5">
              <TaxationDiscount handleBlur={handleBlur} formData={formData} handleOnChangeSelectCurrency={handleOnChangeSelectCurrency} showForm= {showForm}/>
            </div>
          </div>
        </div>

        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
      {/* <button onClick={() => setIsOpen(true)}>
          Click to Open Modal
        </button> */}

      <ReactPortal>
        {templateSelect && (
          <Modal
            handleClose={handleCloseSelectTemplate}
            header={`Welcome to the <span class='pos-rel'>TradingForms</span>. Join happy small business.`}
            type="template"
            contentType="notscrollable"
          >
            <SelectInvoiceTemplate
              formData={formData}
              handleBlur={handleSelectTemplate}
              // handleDefaultTemplate= {handleDefaultTemplate}
              handleClose={handleCloseSelectTemplate}
              filteredTemps={allTemplates}
            />
          </Modal>
        )}
      </ReactPortal>

      <ReactPortal>
        {isOpen && (
          <Modal
            handleClose={handleModalClose}
            header="Record Payment"
            type="form"
          >
            <InvoicePaymentRecord
              handleDeletePayment={handleDeletePayment}
              handleAddPayment={handleAddPayment}
              handleModalClose={handleModalClose}
              formData={formData}
              payments={payments}
            />
          </Modal>
        )}
      </ReactPortal>

      <ReactPortal>
        {isFooterOpen && (
          <Modal
            handleClose={footerInnerModalClose}
            header="Photo Details"
            type="form"
          >
            <PhotoDetails
              handleClose={footerInnerModalClose}
              addedImage={addedImage}
              handleSubmit={footerModalForm}
              footerModalInputId={footerModalInput}
            />
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>

      <ReactPortal>
        {emailModal && (
          <Modal
            handleClose={handleCloseEmail}
            type="form" contentType="email-form"
            header="Enter details to send mail."
          >
            <SendEmail
              emailBody={emailBody}
              handleSubmit={handleEmail}
              emailChange={emailChange}
              loader={emailbuttonLoader}
            />
          </Modal>
        )}
      </ReactPortal>

      <ReactPortal>
        {isConfirmation && (
          <Modal
            header=""
            type="confirm"
            recordName={`Are you sure you want to ${
              props.updating ? "Update" : "Save"
            }`}
            icon="save text-success"
          >
            <button
              className="btn btn-save m-1 col-xs-12 col-sm-6 col-md-3 col-12"
              onClick={() => confirmSave(true)}
            >
              {" "}
              Yes
            </button>
            <button
              className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3 col-12"
              onClick={() => confirmSave(false)}
            >
              No
            </button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
    </>
    // emailModal
  );
}

export default Create;
