import React from "react";
import { separateComma } from "../../utils/utils";

function InvoiceDescriptionRow({
  deleteRow,
  rowData,
  handleChange,
  currency,
  autoSuggstionitems,
  allDescitems,
  fillItemFields,
  showSuggestItem,
  setShowSuggestItem,
  handleHideSuggestion
}) {
  // console.log(rowData);
  const handleCheckbox = (e, id) => {
    // console.log("e.target.name>>",e.target.name,"e.target.value>>",e.target.checked)
    handleChange(
      {
        preventDefault: e.preventDefault,
        target: {
          name: e.target.name,
          value: JSON.stringify(e.target.checked),
        },
      },
      id
    );
  };
    // console.log("rowData>>",rowData);
  return (
    <>
      <div className="invoiceDescriptionRow fadeInOpacity" id={rowData?.id}>
        <div className="col-5">
          <div className="item-row">
            <div className="item-row-action">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => {
                  deleteRow(rowData?.id);
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
            <div className="formField ">
              <div className="w-100 m-1 ">
                <div className="nameSuggest">
                  <input
                    type="text"
                    name="itemDescription"
                    required
                    className="form-control m-0"
                    onChange={(e) => handleChange(e, rowData.id)}
                    onKeyUp={autoSuggstionitems}
                    onBlur={()=>{
                      setTimeout(() => {
                        // setShowSuggestItem(false)
                        handleHideSuggestion(rowData.id)
                      }, 300);
                    }}
                    placeholder="Product Name"
                    value={rowData?.itemDescription}
                    autoComplete="off"
                  ></input>

                  { rowData.showSuggestion && (
                    <div className="autosuggestDropDownContainer">
                      <div className="autosuggestDropDown">
                        <ul>
                          { rowData?.itemDescription && allDescitems.length  ? (
                              allDescitems?.map((list, index) => {
                              return (
                                <li
                                  key={list.id}
                                  onClick={() =>
                                    fillItemFields(rowData?.id, list.id)
                                  }
                                >
                                  {list.item_name}
                                </li>
                              );
                            })) :(
                              <li className="text-danger"><small> No Result Found !</small></li>
                            )
                          }
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <textarea
                  rows="3"
                  name="additionalDetail"
                  cols=""
                  maxLength={180}
                  className="form-control"
                  onChange={(e) => handleChange(e, rowData.id)}
                  placeholder="Additional details"
                  value={rowData?.additionalDetail}
                  autoComplete="off"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="formField">
            <input
              type="number"
              name="rate"
              className="form-control count-input-text"
              onChange={(e) => handleChange(e, rowData.id)}
              placeholder="0.00"
              min={0.00}
              value={rowData?.rate}
              autoComplete="off"
              required
            ></input>
            {/*value="" */}
          </div>
        </div>
        <div className="col-2">
          <div className="formField">
            <input
              type="number"
              name="quantity"
              className="form-control count-input-text"
              onChange={(e) => handleChange(e, rowData.id)}
              placeholder="1"
              min={1}
              defaultValue={rowData?.quantity}
              autoComplete="off"
              required
            ></input>
            {/*value="" */}
          </div>
        </div>
        <div className="col-2">
          <div className="text-break my-2 amount-container">
            <span>{currency}</span>
            <span>
              {separateComma(Number(rowData?.quantity * rowData?.rate))}
            </span>
          </div>
        </div>
        <div className="col-1">
          <div className="pt-2">
            <input
              type="checkbox"
              name="tax"
              onChange={(e) => handleCheckbox(e, rowData.id)}
              checked={rowData?.tax === "true" ? true : false}
            ></input>{" "}
            {/*value="" */}
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceDescriptionRow;
