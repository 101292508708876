import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Link} from "react-router-dom";
// import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment/moment";
import { dataTablecustomStyles, deepCopy, separateComma } from "../../utils/utils";
// import CustombuttonSpinner from "../../components/CustombuttonSpinner";
import TableLoader from "../../components/loaders/TableLoader";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

function Allquotation({allInvoice,deleteInvoice,handleOpenEmail,duplicateInvoice,isLoading}) {
    // const { data, error, isLoading } = useGetAllInvoicesQuery();
  // const {data,error} =useGetAllInvoicesAllQuery();
  // const [tableloader, setTableloader] = useState(true);

  const [allInvoices, setAllInvoices] = useState([]);
  const [search, setSearch] = useState("");
  const [filterInvoice, setFilterInvoice] = useState([]);
  // const [deleteSingleInvoice] = useDeleteSingleInvoiceMutation();
  const navigate = useNavigate();

  useEffect(() => {
    // setTableloader(true);
      let invoiceData = deepCopy(allInvoice);
      setAllInvoices(invoiceData || []);
      setFilterInvoice(invoiceData|| []);
      // setTableloader(false);
  },[allInvoice] );
  // let ids = allInvoice[0].id;
  // let encode = encodeURIComponent(`/${ids}`);
  // console.log(">>",encode)

  const columns = [
    {
      name: "Quotation #",
      selector: (row) => row.invoiceNumber,
      sortable: true,
      // width: '125px'
    },
    {
      name: "From",
      selector: (row) => row.senderName,
      // sortable: true,
    },
    {
      name: "Consumers",
      selector: (row) => row.customerName,
    },
    {
      name: "Date",
      selector: (row) => moment(row.invoicedate).format("ll"),
      sortable: true,
    },
    {
      name: "Total",
      // ${row.currency.Symbol} 
      selector: (row) => ( <div className=""> <div className="table-balanceValue"  >
         {row.currency.Symbol}{separateComma(row.total)}
        </div>
        {/* <div className="">
          <small className={`${row.totalPaid >= row.total ? "text-success":"text-danger "}`}><b> {row.totalPaid >= row.total ? "PAID":"DUE"}</b></small>
        </div> */}
         </div>),
          // width: '125px'
    },
    {
      // name: "",
      cell: (row) => (
        <div className="d-flex gap-1" title="Select an action">
          <DropdownButton
              as={ButtonGroup}
              key={'start'}
              id={`dropdown-button-drop-${row.id}`}
              drop={'start'}
              variant="dropdown-menu-custom"
              title={<i className="fas fa-ellipsis-v"></i> }
              // size="sm"
            >
              <Dropdown.Item eventKey="1">
                    <div
                    className="d-flex gap-2 align-items-center listing-button-edits"
                    onClick={() => {
                      navigate(`/quotation/edit/${window.btoa(`${row.id}`)}`);
                    }} 
                    title='View Quotation'
                  >
                    <i className="fa fa-eye"></i> View
                  </div>
              </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <div 
                className="d-flex gap-2 align-items-center listing-button-edits"
                onClick={() => {
                  navigate(`/quotation/edit/${window.btoa(`${row.id}`)}`);
                }}
                title="Edit Quotation"
              >
                <i className="fa fa-edit"></i> Edit
              </div>
            </Dropdown.Item>

              <Dropdown.Item eventKey="3">
                <div
                  className="d-flex gap-2 align-items-center listing-button-edits"
                  onClick={() => deleteInvoice(row.id,row.invoiceNumber)}
                  title={`Delete ${row.invoiceNumber}`}
                >
                  <i className="fa fa-trash-alt text-danger"></i> Delete
              </div>
              </Dropdown.Item>

              {/* <Dropdown.Divider /> */}

              <Dropdown.Item eventKey="4">
                <div onClick={()=>handleOpenEmail(row.invoiceNumber,row.id,row.customerEmail)} className="d-flex gap-2 align-items-center listing-button-edits"
                title="e-mail Quotation"
                >
                    <i className="fa fa-mail-bulk"></i> Email  Quotation
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="5">
                <div onClick={() => duplicateInvoice(row.id,row.invoiceNumber)}  className="d-flex gap-2 align-items-center listing-button-edits" title={`Duplicate ${row.invoiceNumber}`}>
                    <i className="fa fa-copy"></i> Duplicate 
                </div>
              </Dropdown.Item>

            </DropdownButton>
        </div>
      ),
      right: true,
      width: '60px',
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    const result = allInvoices.filter((invoice) => {
      return invoice?.customerName?.toLowerCase().match(search.toLowerCase());
    });
    setFilterInvoice(result);
  }, [search, allInvoices]);

  return (
    <>
       <div className="container px-0 fadeInOpacity">
        

        <div className="py-2">
          {/* 
            title={"All Invoices"} 
            fixedHeader
            fixedHeaderScrollHeight="400px"
          */}
          {/* <div className="card py-3"> */}
          {isLoading ? (<TableLoader/>):
          (
            <div className="table-wrapper" data-aos="fade-right">
              <DataTable
                columns={columns}
                data={filterInvoice}
                fixedHeader
                fixedHeaderScrollHeight="400px"
                className="table-scroll-custom"
                highlightOnHover
                pointerOnHover  
                responsive
                subHeader
                subHeaderComponent={
                  <div className="col-md-4 col-12">
                  <div className="table-header-custom">
                    <label className="col-md-3 col-12 text-start" htmlFor="search">Search: </label>
                    <div className="col-md-9 col-12">
                      <input
                        type="search"
                        placeholder="Search By Consumer"
                        className="form-control"
                        value={search}
                        autoComplete="off"
                        // style={{ width: "28%" }}
                        onChange={(e) => setSearch(e.target.value)}
                        name="search"
                        id="search"
                      ></input>
                    </div>
                  </div>
                </div>
                }
                // subHeaderAlign="left"
                onRowClicked={(row,event)=>{navigate(`/quotation/edit/${window.btoa(`${row.id}`)}`)}}
                pagination
                sortIcon={<i className="fa fa-angle-down"></i>}
                customStyles={dataTablecustomStyles}
              />

            </div>
          )

          }
            
          {/* </div> */}
        </div>
      </div>
    </>
  )
}

export default Allquotation