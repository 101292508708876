import React, { useEffect, useState } from 'react'
// import uuid from 'react-uuid'
// import {countries as currencyList} from '';
// import countries from '../../assets/js/countries'
// import currencyList from '../../assets/js/currency'
import countriesCurrencies from '../../assets/js/countriesCurrency'
import SelectImg from '../imgSelect/SelectImg'

function TaxationDiscount({formData,handleBlur,showForm, handleOnChangeSelectCurrency}) {
  const [currencyOption,setCurrencyOption]  = useState(null);

  // const handleCheckbox = (e) => {
  //   handleBlur({
  //     preventDefault: e.preventDefault,
  //     target: {
  //       name: e.target.name,
  //       value: e.target.checked
  //     }
  //   })
  // }



  useEffect(() => {
    if (countriesCurrencies) {
      const options = countriesCurrencies.map( currency=>({
        label: `${currency?.CountryName} ${currency?.Symbol}`,
        value: currency?.Code,
        image: currency?.Flag,
        type: 'currency',
        custom_value: currency
      }));
      
      setCurrencyOption(options);
    }
  }, []);
  

  const handleOnChangeSelect= (selected) => {
      handleOnChangeSelectCurrency(selected.custom_value);
  }
  

  return (
    <>
      {/* <h6>PREVIEW VIA EMAIL</h6> */}
            <div className={`sideSetting ${showForm !== 0 ? "cursor-disable":"" }`}>
            {/* <div className="taxDisHeader col-12">Color</div> */}

              {/* <div className="col-12 taxDisBodyFileds">
                <div className="col-4 labelCont pe-1">
                  <label htmlFor="previewEmail">Preview Via Email</label>
                </div>
                <div className="col-sm-8 col-lg-12 col-12 formField">
                  <input type="email" className="form-control" name="previewEmail" onChange={handleBlur}  defaultValue={formData?.previewEmail} placeholder="abc@xyz.com"></input>
                </div>
              </div> */}
                   
              <div className="taxDisHeader col-12">Color</div>
              <div className="col-12 taxDisBodyFileds">
                <div className="col-sm-4 col-lg-12 col-12 labelCont">
                  <label htmlFor="color" className={`${showForm !==0 ? 'cursor-disable opacity-four':''}`}>Custom Color</label>
                </div>
                <div className="col-sm-8 col-lg-12 col-12 formField">
                  <input type="color" className={`form-control ${showForm !==0 ? 'opacity-four':''}`} name="color" onChange={handleBlur} disabled={showForm !==0 ? true:false} value={formData?.color}></input>
                </div>
              </div>
              <div className="col-12 taxDisBodyFileds p-0">
                <div className="col-sm-4 col-lg-12 col-12 labelCont">
                  <label htmlFor="currency" className={`${showForm !==0 ? 'cursor-disable opacity-four':''}`}>Currency</label>
                </div>
                <div className={`col-sm-8 col-lg-12 col-12 formField ${showForm !==0 ? 'cursor-disable opacity-four':''}`}>
                  {/* <input type="text" name="currency" onChange={handleBlur} className="form-control" placeholder="USD" defaultValue={formData?.currency}></input> */}
                  {/* <select className={`form-select ${showForm !==0 ? 'cursor-disable opacity-four':''}`} name="currency" onChange={handleBlur} value={JSON.stringify(formData?.currency)} disabled={showForm !==0 ? true:false} aria-label="Select Currency" style={{'paddingLeft': '35px'}}>
                      {
                        countriesCurrencies.map((country,index)=>{
                          return(
                            <option key={index} value={JSON.stringify(country)} >
                              {country.Code} {country.CountryName}  {country.Symbol} 
                            </option>
                          )
                        })
                      }
                  </select>
                  <img src={formData?.currency?.Flag} loading='lazy' alt={formData?.currency?.CountryName} width={25} height='auto' className={showForm !==0 ? 'cursor-disable opacity-four':''} style={{"position":"absolute", "top":"50%","left":"0", "transform": "translate(4px, -50%)","filter":"drop-shadow(2px 1px 4px rgba(0,0,0,0.4))",}}/> */}
                    
                  <SelectImg
                    options={currencyOption}
                    handleOnChangeSelect={handleOnChangeSelect}
                    defaultSelect={JSON.stringify(formData?.currency?.Code)} 
                  />  

                </div>
              </div>
              <div className="taxDisHeader col-12">Tax</div>
              <div className="col-12 taxDisBodyFileds">
                <div className="col-sm-4 col-lg-12 col-12 labelCont">
                  <label htmlFor="taxType" className={`${showForm !==0 ? 'cursor-disable opacity-four':''}`}>Type</label>
                </div>
                <div className="col-sm-8 col-lg-12 col-12">
                  <select className={`form-select ${showForm !==0 ? 'cursor-disable opacity-four':''}`} name="taxType"  value={formData?.taxType} disabled={showForm !==0 ? true:false} onChange={handleBlur} aria-label="Select Tax Type">
                    <option value="onTotal">On Total</option>
                    <option value="deducted">Deducted</option>
                    <option value="perItem">Per Item</option>
                    <option value="none">None</option>
                  </select>
                </div>
              </div>
              {   formData?.taxType !=="none" &&
                <div className="fadeInOpacity">  
                  <div className="col-12 taxDisBodyFileds">
                    <div className="col-sm-4 col-lg-12 col-12 labelCont">
                      <label htmlFor="taxLabel" className={`${showForm !==0 ? 'cursor-disable opacity-four':''}`}>Label</label>
                    </div>
                    <div className="col-sm-8 col-lg-12 col-12 formField">
                      <input type="text" className={`form-control ${showForm !==0 ? 'opacity-four':''}`} name="taxLabel" disabled={showForm !==0 ? true:false} onChange={handleBlur} value={formData?.taxLabel} maxLength={15}></input>
                    </div>
                  </div>
                  <div className="col-12 taxDisBodyFileds">
                    <div className="col-sm-4 col-lg-12 col-12 labelCont">
                      <label htmlFor="taxRate" className={`${showForm !==0 ? 'cursor-disable opacity-four':''}`}>Rate</label>
                    </div>
                    <div className="col-sm-8 col-lg-12 col-12 formField percent-postfix">
                      <input type="number" className={`form-control ${showForm !==0 ? 'opacity-four':''}`} name="taxRate" onChange={handleBlur} min={0} max={100} disabled={showForm !==0 ? true:false} placeholder="0.00%" value={formData?.taxRate}></input>
                    </div>
                  </div>
                  {/* <div className="col-12 taxDisBodyFileds">
                    <div className="col-sm-4 col-lg-12 col-12 labelCont">
                      <label htmlFor="taxInclusive">Inclusive?</label>
                    </div>
                    <div className="col-sm-8 col-lg-12 col-12 formField px-2">
                      <input  type="checkbox"  id="taxInclusive"  name="taxInclusive" onChange={handleCheckbox} defaultChecked={formData?.taxInclusive ==="true" ? true: false}/>
                    </div>
                  </div> */}
                </div>
              }
              <div className="taxDisHeader col-12">Discount</div>
              <div className="col-12 taxDisBodyFileds">
                <div className="col-sm-4 col-lg-12 col-12 labelCont">
                  <label htmlFor="discountType" className={`${showForm !==0 ? 'cursor-disable opacity-four':''}`}>Type</label>
                </div>
                <div className="col-sm-8 col-lg-12 col-12">
                  <select className={`form-select ${showForm !==0 ? 'cursor-disable opacity-four':''}`} name="discountType" onChange={handleBlur} value={formData?.discountType} disabled={showForm !==0 ? true:false} aria-label="Select Discount Type">
                    <option value="none">None</option>
                    <option value="percent">Percent</option>
                    <option value="flatAmount">Flat Amount</option>
                  </select>
                </div>
              </div>
                { formData?.discountType !=="none" &&
                  <div className="col-12 taxDisBodyFileds">
                  <div className="col-sm-4 col-lg-12 col-12 labelCont">
                    <label htmlFor="discountValue" className={`${showForm !==0 ? 'cursor-disable opacity-four':''}`}>{ formData?.discountType === "flatAmount" ? "Amount": "Percent"}</label>
                  </div>
                    <div className="col-sm-8 col-lg-12 col-12 formField">
                      <input type="number" name="discountValue" className={`form-control ${showForm !==0 ? 'opacity-four':''}`} disabled={showForm !==0 ? true:false} onChange={handleBlur} value={formData?.discountValue} placeholder={formData?.discountType ==="percent" ? "0.00%":"00.00"}></input>
                    </div>
                  </div>
                }
              
              
              {showForm !== 0 &&
                <span className='text-danger'>* Go to edit mode to change <i>Tax</i> and <i> Discount</i>. After saving, those <i>Tax</i> and <i> Discount</i> settings will be reflected in PDF and Email.</span>
              }
            </div>
    </>
  )
}

export default TaxationDiscount