import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AllContracts from './AllContracts';
import { useLazyGetAllContractsQuery } from '../../store/apis/laravelApi';
import { deepCopy } from '../../utils/utils';
import { errorMessage } from '../../components/toastMessages/ToastifyMsg';
import { FRONTSITE } from '../../common/common';

function ContractsListing() {
    const frontsiteUrl = FRONTSITE;
    // const navigate = useNavigate();
    const [showContract, setShowContract] = useState('all');
    const [getAllContracts] = useLazyGetAllContractsQuery();
    const [isLoadingAll,setIsLoadingAll] = useState(false);
    const [contracts,setContracts] = useState([]);
    const [allContracts,setAllContracts] = useState([]);

    useEffect(() => {
        (async()=>{
            try {
                setIsLoadingAll(true)
                const {data,error} =await getAllContracts();
                if (data) {
                    if (data?.status ==="success") {
                        let allContracts = deepCopy(data?.data);
                        allContracts = allContracts.map(contract=>({...contract,content:JSON.parse(contract?.content)}));
                        allContracts = allContracts.map(contract=>({...contract,content: {...contract?.content,fullName: `${contract?.content?.client_first_name} ${contract?.content?.client_last_name}`}}))
                        console.log("allContracts",allContracts)
                        setContracts(allContracts);
                        setAllContracts(allContracts);
                    }
                }
                if (error) {
                    errorMessage(error?.data?.message);
                }
            } catch (error) {
            }finally{
                setIsLoadingAll(false)
            }
        })()
    }, [getAllContracts])

    useEffect(() => {
        if (showContract !== "all") {
            let filtered = allContracts.filter(contract=>{
                return contract.status===showContract
            } )
            setContracts(filtered)
        }else{
            setContracts(allContracts)
        }
        
    }, [showContract,allContracts])


    // const navigateFunc = () => { navigate('/email-history', { state: { emailHistory: 'invoice' } }) };

    return (
        <>
            {/* <h2 className='text-warning'>UnderDevelopment</h2> */}
            <div className="spacer"></div>

            <div className="invoice-container">
                <div className="container invoice-list" data-aos="zoom-in">
                    <div className="invoice-actions">
                        <div className="invoice-navBtn invoice-navBtn-custom">
                            <button
                                onClick={() => setShowContract('all')}
                                className={`btn btn-secondary btn-sm ${showContract === 'all' ? "active cursor-disable" : ""
                                    }`}
                                type="button"
                                title="View All Contracts"
                            >
                                All
                            </button>
                            <button
                                onClick={() => setShowContract('signed')}
                                className={`btn btn-secondary btn-sm ${showContract === "signed" ? "active cursor-disable" : ""
                                    }`}
                                type="button"
                                title="View Signed Contracts"
                            >
                                Signed
                            </button>
                            <button
                                onClick={() => setShowContract('sent')}
                                className={`btn btn-secondary btn-sm ${showContract === "sent" ? "active cursor-disable" : ""
                                    }`}
                                type="button"
                                title="View Unsigned Contracts"
                            >
                                Unsigned
                            </button>
                        </div>
                        <div className="searchCreate-invoice">
                            {/* <div className="invoice-searchContainer col-xs-12 col-sm-9 col-md-7">
                    <input
                    type="text"
                    name=""
                    placeholder="Search By Client Name"
                    ></input>
                </div> */}
                            {/* <div className="">
                    <button onClick={navigateFunc} type="button" className="btn btn-secondary btn-sm email-history">
                        <i className="fa fa-history"></i> <span>Email History</span> 
                    </button>
                </div> */}
                            <div className="createInvoice">

                                {/* <Link
                    className="btn btn-primary"
                    to={{
                        pathname: "create",
                        // state: `${allInvoiceRefetch}`,
                    }}
                    // state={{allInvoiceRefetch}}
                    >
                    <i className="fa fa-plus"></i> <span>Create Invoice</span>
                    </Link> */}
                            </div>
                        </div>
                    </div>

                    <div  className="container px-0 fadeInOpacity overflow-hidden">
                            <AllContracts 
                                contracts={contracts}
                                isLoading={isLoadingAll}
                            />
                        {!isLoadingAll &&    
                            <p className='brand-note'><Link to={`${frontsiteUrl}contact-us`} target='_blank'> <i>Note: To use Contract services contact us at <span> support@tradingforms.com</span>.</i> </Link></p>
                        }
                    </div>

                </div>






            </div>
        </>
    )
}

export default ContractsListing