import moment from 'moment/moment'
import React, { useState } from 'react'
import { useGetSubscriptionHistoryMutation } from '../store/apis/laravelApi';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import CustombuttonSpinner from '../components/CustombuttonSpinner';

function SubscriptionHistory() {
    const user = useSelector(state => state.auth.user);
    const [planHistory, setPlanHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchResults, setSearchResults] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');

    const [getSubscriptionHistory] = useGetSubscriptionHistoryMutation();

    useEffect(() => {
        const handleGetSubHistory = async () => {
            try {
                setIsLoading(true);
                const body = {
                    user_email: user?.email,
                    user_id: user?.id
                }
                const resp = await getSubscriptionHistory(body);
                if (resp?.data?.status === "success") {
                    setPlanHistory(resp?.data?.data);
                    setSearchResults(resp?.data?.data);
                }

            } catch (error) {

            } finally {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        }
        handleGetSubHistory();
    }, [getSubscriptionHistory, user]);



      useEffect(()=>{
        const searchMonth = searchTerm.trim().toLowerCase();
    
        const results = planHistory.filter(subscription => {
          const activationDateObj = new Date(subscription.activation_datetime);
          const expiresOnDateObj = new Date(subscription.expires_on);
    
          const activationMonth = activationDateObj.toLocaleString('default', { month: 'long' }).toLowerCase();
          const expiresOnMonth = expiresOnDateObj.toLocaleString('default', { month: 'long' }).toLowerCase();
    
          return activationMonth.includes(searchMonth) || expiresOnMonth.includes(searchMonth);
        });
        setSearchResults(results);
      },[searchTerm,planHistory])

    return (
        <>
            <div className="spacer"></div>
            <div className="invoice-container">
                <div className='container invoice-list' data-aos="zoom-in">
                    <h4 className='text-capitalize'>
                        Subscription history
                    </h4>
                    {isLoading ? <div className="position-relative d-flex align-items-center w-100 py-5"><CustombuttonSpinner /></div> : <div className="plan-info-wrapper">
                        <div className='d-flex align-items-center justify-content-end mb-3'>
                        <label className="col-xs-12 form-label mb-0 me-1" htmlFor="search">Search:</label> <div className='col-12 col-md-5'>
                                <input
                                    type="search"
                                    className='form-control'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Enter month"
                                    name='search'
                                />

                            </div>
                        </div>
                        <div className='responsive-table'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th>Plan Name</th>
                                        <th>Status</th>
                                        <th>Type</th>
                                        <th>Subscribed Date</th>
                                        <th>Expires on</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/*  {user.subscriptions.map((item, index) => (
                                    <SubscriptionItem key={index} item={item} />
                                    ))} */}

                                    {searchResults?.map(plan => (
                                        <tr key={plan?.id}>
                                            <td className='text-capitalize'>{plan?.packagessubcribed?.package_title} Plan</td>
                                            <td className={`text-capitalize ${plan?.subscription_current_status.toLowerCase() === 'active' ? 'text-success' : 'text-danger'}`}><strong>{plan?.subscription_current_status}</strong></td>
                                            <td>{plan?.subscription_validation_days === 30 ? 'Monthly' : 'Yearly'} </td>
                                            <td>{moment(plan?.activation_datetime).format('ll')}</td>
                                            <td>{moment(plan?.expires_on).format('ll')}</td>
                                            <td>${plan?.package_price.toFixed(2)}</td>
                                        </tr>
                                    ))
                                    }



                                    {searchResults.length <= 0 &&
                                        <tr>
                                            <td colSpan='6' className='text-center'>No subscription yet!</td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>

                    </div>
                    }
                </div>
            </div>
        </>
    )
}

export default SubscriptionHistory