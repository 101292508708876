// import React from 'react'
import { Link } from 'react-router-dom'
import './DashboardHeader.css'
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalNotificationsQuery } from '../../store/apis/baseApi';
import { useEffect, useState } from 'react';
import { deepCopy, notiIcon, notificationBackgroundColor } from '../../utils/utils';
import { hideTopNotification, showTopNotification } from '../../store/slices/notification';
import { errorMessage } from '../toastMessages/ToastifyMsg';
import { Markup } from 'react-render-markup';

function DashboardHeader() {
    const dispatch = useDispatch();
    const { data: notificationData, error: notificationError } = useGlobalNotificationsQuery();

    const auth = useSelector((state) => state.auth);
    let name = auth.user.name;

    const planDetails = useSelector(state => state.planDetails);
    const [showDemoNotification, setShowDemoNotification] = useState(true);

    const notification = useSelector((state) => state.topNotification);

    const [notificationMessage, setNotificationMessage] = useState({
        notification_title: notification?.notification_title,
        notification_details: notification?.notification_details,
        notification_type: notification?.notification_type,
        notification_type_icon: notification?.notification_type_icon,
        notification_show: notification?.notification_show,
        notification_color: notification?.notification_color,
    });

    useEffect(() => {
        try {
            if (notificationData) {
                if (notificationData.success) {
                    let notificationCopy = deepCopy(notificationData.data);
                    let notificationBg = notificationBackgroundColor(notificationCopy.notification_type);
                    let notificationIcon = notiIcon(notificationCopy.notification_type);

                    dispatch(showTopNotification({
                        notification_title: notificationCopy.notification_title,
                        notification_details: notificationCopy.notification_details,
                        notification_type: notificationCopy.notification_type,
                        notification_type_icon: notificationIcon,
                        notification_color: notificationBg,
                        notification_show: true,
                    }));
                }
                if (!notificationData.success) {
                    dispatch(hideTopNotification());
                }
            }
            if (notificationError) {
                errorMessage(notificationError?.error ?? notificationError?.data?.error?.message);
            }
        } catch (error) {
        }


    }, [notificationData, notificationError, dispatch]);

    useEffect(() => {
        setNotificationMessage({
            notification_title: notification.notification_title,
            notification_details: notification.notification_details,
            notification_type: notification.notification_type,
            notification_type_icon: notification?.notification_type_icon,
            notification_show: notification.notification_show,
            notification_color: notification.notification_color,
        })
    }, [notification]);

    return (
        <>
            <div className='dash-header-wrapper'>
                <nav className='navbar dashboard-header'>
                    <div></div>
                    <div className='brand-logo'><h2 className='brand-logo-title'><Link to='/'><span className='pos-rel'>TradingForms</span></Link></h2></div>
                    <ul className='navbar-nav'>
                        {planDetails?.planDetails?.current_package?.package_title.toLowerCase() === "free" &&
                            <li className="p-0">
                                <Link className="btn-upgrade-plan" to="/subscription-plans" title="Upgrade to Premium plan.">
                                    <span>Updgrade</span>
                                </Link>
                            </li>
                        }
                        <li className='nav-item'>
                            <Link to='/usersettings' title='User profile settings'>
                                <div className='nav-profile'>
                                    <svg width="20" height="17" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.8622 12.7176C11.439 12.7176 8.15608 14.0774 5.73555 16.4979C3.31502 18.9185 1.95518 22.2014 1.95518 25.6246C1.95518 26.0974 2.14301 26.5508 2.47734 26.8852C2.81168 27.2195 3.26513 27.4073 3.73795 27.4073H25.9864C26.4592 27.4073 26.9127 27.2195 27.247 26.8852C27.5814 26.5508 27.7692 26.0974 27.7692 25.6246C27.7692 22.2014 26.4093 18.9185 23.9888 16.4979C21.5683 14.0774 18.2853 12.7176 14.8622 12.7176Z" fill="#F9F9F9" stroke="#979797" strokeWidth="2.97636" />
                                        <path d="M14.8645 16.2831C18.921 16.2831 22.2094 12.9947 22.2094 8.93821C22.2094 4.88175 18.921 1.59333 14.8645 1.59333C10.808 1.59333 7.51963 4.88175 7.51963 8.93821C7.51963 12.9947 10.808 16.2831 14.8645 16.2831Z" fill="#F9F9F9" stroke="#979797" strokeWidth="2.97636" />
                                    </svg>
                                    <span>{name}</span>

                                </div>
                            </Link>
                        </li>

                    </ul>
                </nav>

                {notificationMessage?.notification_show &&
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ 'display': 'none' }}>
                        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </symbol>
                        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                        </symbol>
                        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </symbol>
                    </svg>
                }
                {(notificationMessage?.notification_type === 'normal' || notificationMessage?.notification_type === 'news') &&

                    <div className={`alert alert-primary alert-dismissible container d-flex fade show`} role="alert">
                        <svg className="bi flex-shrink-0 me-3" width="24" height="24" role="img" aria-label="Info:"><use xlinkHref="#info-fill" /></svg>
                        <div><strong>{notificationMessage?.notification_title}</strong><br />
                            <Markup markup={notificationMessage?.notification_details} />
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                }
                {notificationMessage?.notification_type === 'update' &&
                    <div className={`alert alert-success alert-dismissible container d-flex fade show`} role="alert">
                        <svg className="bi flex-shrink-0 me-3" width="24" height="24" role="img" aria-label="Success:"><use xlinkHref="#check-circle-fill" /></svg>
                        <div><strong>{notificationMessage?.notification_title}</strong><br />
                            <Markup markup={notificationMessage?.notification_details} />
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                }
                {notificationMessage?.notification_type === 'alarming' &&
                    <div className={`alert alert-warning alert-dismissible container d-flex fade show`} role="alert">
                        <svg className="bi flex-shrink-0 me-3" width="24" height="24" role="img" aria-label="Warning:"><use xlinkHref="#exclamation-triangle-fill" /></svg>
                        <div><strong>{notificationMessage?.notification_title}</strong><br />
                            <Markup markup={notificationMessage?.notification_details} />
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>

                }

                
                {showDemoNotification && planDetails?.planDetails?.code === 403 && !planDetails?.planDetails?.status &&
                    <div className={`alert alert-danger alert-dismissible container d-flex fade show`} role="alert">
                        <svg className="bi flex-shrink-0 me-3" width="24" height="24" role="img" aria-label="Danger:"><use xlinkHref="#exclamation-triangle-fill" /></svg>
                        <div><strong>Subscription Expired!</strong><br />
                            {planDetails?.planDetails?.message}
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => { setShowDemoNotification(false) }}></button>
                    </div>
                }

                {showDemoNotification && planDetails?.planDetails?.status && planDetails?.planDetails?.code === 200 && planDetails?.planDetails?.current_package?.package_title.toLowerCase() === "free" &&
                    <div className={`alert alert-warning alert-dismissible container d-flex fade show`} role="alert">
                        <svg className="bi flex-shrink-0 me-3" width="24" height="24" role="img" aria-label="Warning:"><use xlinkHref="#exclamation-triangle-fill" /></svg>
                        <div><strong>Demo Account Activated!</strong><br />
                            Our free plan equips you with everything you need to get started, automatically activated when you create your account. Need more? <Link to='/subscription-plans'>Upgrade</Link> to unlock even more features!
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => { setShowDemoNotification(false) }}></button>
                    </div>
                }
            </div>
        </>
    )
}

export default DashboardHeader