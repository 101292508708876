import React, { memo, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { dataTablecustomStyles, deepCopy } from '../utils/utils';
// import CustombuttonSpinner from '../components/CustombuttonSpinner';
import TableLoader from '../components/loaders/TableLoader';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';


function Outstanding({ allItem , deleteSingleItem, isLoading}) {
    const [allItems, setAllItems] = useState([]);
  // const {data, error, isLoading} = useItemsSettingsAllQuery();
//   const [deleteItem]= useDeleteItemMutation();
  const [search, setSearch] = useState("");
  const [filterItems, setFilterItems] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
      let itemData= deepCopy(allItem);
    //   console.log(itemData);
    let allProducts = itemData.map((product)=>{
      return({
        ...product,
        item_texable :product.item_texable === "true" ? "yes":"no"
      })
    })
      setAllItems(allProducts.filter(item=> item.item_type === "digital"));
      setFilterItems(allProducts.filter(item=> item.item_type === "digital"));

  
  }, [allItem]);


  


  const columns = [
    { 
      name: "Product Name",
      selector: (row) => (<div className="text-capitallize">
      {row.item_name}
    </div>) ,
    },
    {
      name: "Product Type",
      selector: (row) =>( <div className="text-capitalize">
        {row.item_type}
      </div> ),
    },
    {
      name: "Rate",
      selector: (row) => row.item_rate
      // ( <div>
      //   {separateComma(row.item_rate)}        
      // </div> )
      ,
      sortable: true,
    },
    {
      name: "Taxable",
      selector: (row) =>  (<div className="text-capitalize">
      {row.item_texable}
   </div>),
    },
    {
      // name: "",
      cell: (row) => (
        <div className="d-flex" title="Select an action">
           <DropdownButton
              as={ButtonGroup}
              key={'start'}
              id={`dropdown-button-drop-${row.id}`}
              drop={'start'}
              variant="dropdown-menu-custom"
              title={<i className="fas fa-ellipsis-v"></i> }
              // size="sm"
            >
              <Dropdown.Item eventKey="1">
                    <div
                    className="d-flex gap-2 align-items-center listing-button-edits"
                    onClick={() => {
                      navigate(`/items/edit/${window.btoa(`${row.id}`)}`);
                    }} 
                    title='View Product'
                  >
                    <i className="fa fa-eye"></i> View
                  </div>
              </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <div 
                className="d-flex gap-2 align-items-center listing-button-edits"
                onClick={() => {
                  navigate(`/items/edit/${window.btoa(`${row.id}`)}`);
                }}
                title="Edit Product"
              >
                <i className="fa fa-edit"></i> Edit
              </div>
            </Dropdown.Item>

              <Dropdown.Item eventKey="3">
                <div
                  className="d-flex gap-2 align-items-center listing-button-edits"
                  onClick={() => {deleteSingleItem(row.id,row.item_name)}}
                  title={`Delete ${row.item_name} Product`}
                >
                  <i className="fa fa-trash-alt text-danger"></i> Delete
              </div>
              </Dropdown.Item>

              {/* <Dropdown.Divider /> */}

              

            </DropdownButton>
        </div>
      ),
      width: '60px',
      right: true,
      allowOverflow: true,
      button: true,
    },
  ];
  useEffect(() => {
    const result = allItems.filter((item) => {
      return item?.item_name?.toLowerCase().match(search?.toLowerCase());
    });
    setFilterItems(result);
  }, [search, allItems]);
    // console.log('Outstanding');
  return (
    <>
       <div className="container px-0">
        {/* <div className="card p-2"> */}
        <div className="py-3 fadeInOpacity">
          {/* 
            title={"All Invoices"} 
            fixedHeader
            fixedHeaderScrollHeight="400px"
          */}
          {isLoading ? (<TableLoader/>)
              :
              (
                <div className="table-wrapper" data-aos="fade-up">
                  <DataTable
                   columns={columns}
                   data={filterItems}
                   fixedHeader
                   fixedHeaderScrollHeight="400px"
                   className="table-scroll-custom"
                   highlightOnHover
                   pointerOnHover  
                   responsive
                   subHeader
                   subHeaderComponent={
                    <div className="col-md-4 col-12">
                      <div className="table-header-custom">
                        <label className="col-md-3 col-12 text-start" htmlFor="search">Search: </label>
                        <div className="col-md-9 col-12">
                          <input
                            type="search"
                            placeholder="Search By Product Name"
                            className="form-control"
                            value={search}
                            autoComplete="off"
                            // style={{ width: "28%" }}
                            onChange={(e) => setSearch(e.target.value)}
                            name="search"
                            id="search"
                          ></input>
                        </div>
                      </div>
                    </div>
                   }
                   // subHeaderAlign="left"
                   onRowClicked={(row,event)=>{navigate(`/items/edit/${window.btoa(`${row.id}`)}`)}}
                   pagination
                   sortIcon={<i className="fa fa-angle-down"></i>}
                   customStyles={dataTablecustomStyles}
                 />

                </div>
              )
          }
          
        </div>
               
        {/* </div> */}
       </div>
    </>
  )
}

export default memo(Outstanding);