/* global google */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useLazyGetInvoiceTemplatesQuery,
  //  useLazyGetUserBgImageQuery,
  useLoginUserMutation, useUserSocialLoginMutation
} from "../store/apis/baseApi";
import { setUser } from "../store/slices/userSlice";
import logo from "../assets/images/first_without_background.webp";
import googleIcon from "../assets/images/icon-google.webp";
import jwt_decode from "jwt-decode";
import "./Logins.css";
import { errorMessage } from "../components/toastMessages/ToastifyMsg";
import CustombuttonSpinner from "../components/CustombuttonSpinner";
import { setBackgroundUrl } from "../store/slices/backgroundPicture";

function Logins() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [gloader, setGloader] = useState(false);

  const [loginUser] = useLoginUserMutation();
  const [userSocialLogin] = useUserSocialLoginMutation();


  const [data, setData] = useState({
    email: "",
    password: "",
  })


  const postSocialLogin = useCallback(
    async (socialData) => {
      try {
        const { data, error } = await userSocialLogin(socialData);
        if (data) {
          if (data.success) {

            if (data.user) {
              localStorage.setItem("tradingformtoken", data.token);
              dispatch(setUser(data.user));
              setGloader(false);
              // handleTemplates();
              // await handleActivatedPlan(data.user);
              navigate("/");

            }
            if (!data.user) {
              errorMessage(data?.message);
              setGloader(false);
            }
          }
          if (!data.success) {
            errorMessage(data?.message);
            setGloader(false);
          }


        }
        if (error) {
          errorMessage("SocialLogin_error!");
          setGloader(false);
        }
      } catch (error) {
      }


    },
    [dispatch, navigate, userSocialLogin]
  )

  const handleSocialCallbackResponse = useCallback(
    (response) => {
      setGloader(true);
      // console.log("Encoded JWT ID Token: "+ response);
      let userObject = jwt_decode(response.credential);

      let userSocailData = {
        google_id: userObject.sub,
        google_email: userObject.email,
        email_verified: userObject.email_verified === true ? "1" : "0",
        google_username: userObject.name,
        google_other: userObject.picture,
        login_type: "google",
      }
      postSocialLogin(userSocailData);

    },
    [postSocialLogin],
  )


  useEffect(() => {
    // global google
    if (window.google) {
      google.accounts.id.initialize({
        client_id: "349936528125-fle526cnassi5lfs95g9unt3fv500l94.apps.googleusercontent.com",
        callback: handleSocialCallbackResponse
      });
      google.accounts.id.renderButton(
        document.getElementById("googleSignIn"),
        {
          // theme: "outline" , 
          size: "large",
          width: '100',
          // scope: 'profile email',
          // width: 240,
          // height: 50,
          // longtitle: true,
          theme: 'outline',
          // onsuccess: onSuccess,
          // onfailure: onFailure
        }
      );
      google.accounts.id.prompt();

    }
  }, [handleSocialCallbackResponse])


  const loginPage = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);
      let email = e.target.email.value;
      let password = e.target.password.value;
      if (email !== "" && password.length >= 6) {

        const { data, error } = await loginUser({
          email: e.target.email.value,
          password: e.target.password.value,
        });

        if (data) {
          if (data.success) {
            localStorage.setItem("tradingformtoken", data.token);
            dispatch(setUser(data.user));
            // if (data.user.company_bg_image) {
            //   dispatch(setBackgroundUrl(data.user.company_bg_image));
            // }
            // handleTemplates();
            // setLoader(false);
            // await handleActivatedPlan(data?.user);
            navigate("/");
          }
          if (!data.success) {

            if (data.message) {
              errorMessage(data.message);
            }
            // if (data?.errors[0]) {
            //   errorMessage(data.errors[0].msg);
            //   setLoader(false);
            // }
            // if (data?.errors[1]) {
            //   errorMessage(data.errors[1].msg);
            //   setLoader(false);
            // }
            // if (data?.errors[2]) {
            //   errorMessage(data.errors[2].msg);
            //   setLoader(false);
            // }
            // errorMessage(data.message);
            setLoader(false);
            // console.log(data)
          }


          // console.log('IF DATA',data);

        }

        if (error) {
          // console.log("ERROR:", error);
          setLoader(false);
          if (error.error) {
            errorMessage(error.error);
            setLoader(false);
          }
          if (error.data.errors) {
            setLoader(false);
            errorMessage(error.data.errors[0].msg)
            // setLoader(false);
          }
        }
      } else {

        if (email === "" && password === "") {

          setLoader(false);
          errorMessage("Please enter credentials.")
        }
        if (email === "" && password.length > 0) {
          setLoader(false);
          errorMessage("Please enter email.");
        }
        if (password === "" && email.length > 0) {
          setLoader(false);
          errorMessage("Please enter password.");
        }
        if (password.length < 6) {
          setLoader(false);
          errorMessage("Password must contain alteast 6 characters.");
        }
      }
    } catch (error) {
    }

  };

  const emptyFields = (e) => {
    e.preventDefault();
    // console.log(e.target.value)
    setData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
    // console.log(data);
  }


  const password = () => {
    const togglePassword = document.querySelector("#togglePassword");
    const password = document.querySelector("#id_password");

    // toggle the type attribute
    const type =
      password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
    // toggle the eye slash icon
    togglePassword.classList.toggle("fa-eye-slash");
  };


  return (
    <>
      <section className="loginView--container">
        <div className="loginView-wrapper">
          <div className="signUp-content">
            <div className="signUp-content-inner" data-aos="fade-right">
            <div className="logoContainer">
                <img src={logo} alt="logo"></img>
              </div>
              <h2 className="title">Create a free <span className="pos-rel">TradingForms</span> account.</h2>
              <span>Register yourself in one easy step.</span>
              <div className="sign-up-cta"><Link to="/signup">Sign up</Link></div></div>
          </div>
          <div className="credentials-wrapper">

            <div className="credentials-wrapper-inner">
              {/* <div className="logoContainer">
                <img src={logo} alt="logo"></img>
              </div> */}
              <div className="credentials-content">
              
                {/* <form>
                            <h2 className="title" style={{marginBottom: "20px",}}>Welcome back!</h2>
                            </form> */}
                <div className="form-container">
                {/* To Your <span className="pos-rel">TradingForms</span>  Account */}
                  <p className="title">Login </p>
                  <form className="form" onSubmit={loginPage}>
                    <div className="input-group">
                      <label htmlFor="email"><i className="fa fa-envelope"></i> Email</label>
                      <input
                        type="email"
                        name="email"
                        autoComplete={"on"}
                        placeholder="abc@xyz.com"
                        id="email"
                        onChange={(e) => emptyFields(e)}
                        autoFocus={true}
                        defaultValue={data?.email}
                        required
                      ></input>
                    </div>
                    <div className="input-group">
                      <label htmlFor="password"><i className="fa fa-lock"></i> Password</label>
                      <input
                        type="password"
                        name="password"
                        // autoComplete="new-password"
                        placeholder=""
                        id="id_password"
                        onChange={(e) => emptyFields(e)}
                        autoComplete="on"
                        defaultValue={data?.password}
                        required
                      ></input>
                      <i
                        className="far fa-eye"
                        onClick={password}
                        id="togglePassword"
                      ></i>
                      <div className="forgot">
                        <Link
                          to="/resetpassword"
                          rel="noopener noreferrer"
                          href="#"
                        >
                          Forgot Password ?
                        </Link>
                      </div>
                    </div>
                    <button type="submit" className={`sign ${!loader ? '' : 'cursor-disable'}`}  disabled={!loader ? false : true}>
                      
                      {loader ?
                        <span className="spinner-container">

                          <CustombuttonSpinner />
                        </span>: "Log in"
                      }
                    </button>
                    <div className="pos-rel or-seperator"><p>or Log in with</p></div>
                  </form>
                  <div className="platform-wrapper">
                    <div className="googleBtnWrapper">
                      {/* {reqLoading.ggLoading ? ( */}
                      {/* <button
                                                className="btn animate-dots-blue login-cta-outline"
                                                type="button"
                                                disabled
                                            >
                                                <DotLoader />
                                            </button> */}
                      {/* // ) : ( */}
                      <>
                        {gloader !== true ?
                          (
                            <button className="btn" id="customGoogleSingIn">
                              <img src={googleIcon} alt="gmailicon" />
                              <span>  Google </span>
                              <div id="googleSignIn"></div>
                            </button>
                          )
                          : (
                            <button className="btn cursor-disable"><CustombuttonSpinner /></button>
                          )
                        }
                      </>
                      {/* // )} */}
                    </div>

                    {/* {reqLoading.fbLoading ? (
                                        <button
                                            className="btn animate-dots-blue login-cta-outline"
                                            type="button"
                                            disabled
                                        >
                                            <DotLoader />
                                        </button>
                                    ) : (
                                        <LoginSocialFacebook
                                            // isOnlyGetToken
                                            appId={APP_IDS.FACEBOOK || ""}
                                            onResolve={handleFacebookResolve}
                                            onReject={(err) => {
                                                console.log(err);
                                            }}
                                        >
                                            <button className="btn">
                                                <img src={fbIcon} alt="Facebook icon" />
                                                Log in with Facebook
                                            </button>
                                        </LoginSocialFacebook>
                                    )} */}
                  </div>


                  <p className="signup">
                    <span>Don't have an account? </span>
                    <Link to="/signup" href="#" className="">
                      {" "}
                      Sign up
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="corner-circle"></div>
            <div className="circle-wrapper"><div className="circle-in-circle"></div></div>
            <div className="upperleft-light-circle"></div>
          </div>
          
        </div>
        <div className="overlay"></div>
      </section>
    </>
  );
}

export default Logins;
