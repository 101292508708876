import React, { useState } from 'react'

function AddDecuction({handleSubmit}) {

  const [validateF,setValidateF] =useState(false);

  const validate=(e)=>{
    e.preventDefault();
    let value = e.target.value;
    // setModalInput(value);
    if (value!== "") {
      setValidateF(true);
    }
    // setValidateF()
    // console.log(value,"-",validateF)
  }

  return (
    <>
     <div className="addEaringsModal">
        <form onSubmit={(e)=>handleSubmit(e)}>

        <div className="formField">
          <label className="col-12" htmlFor="deduction_title">
            Deduction Title
          </label>
          <div className="col-12">
            <input
              className="form-control"
              type="text"
              name="deduction_title"
              required
              placeholder=""
              defaultValue={''}
              maxLength={25}
              onChange={(e)=>validate(e)}
             autoFocus
            ></input>
          </div>
        </div>
        {/* <div className="formField">
          <label className="col-4"htmlFor="">
            Amount
          </label>
          <div className="col-8">
            <input
              className="form-control"
              type="number"
              name="earningAmount"
              required=""
              placeholder=""
              defaultValue={''}
            ></input>
          </div>
        </div> */}
        <div className="text-end">
            <button type="submit" className={`btn btn-save ${validateF!==true ? "cursor-disable": ""}`} disabled={!validateF} title='Save Deduction'>
            <span className="mx-1"> Save Deduction</span>
            <i className="fa fa-save"></i>   
            </button>
        </div>
        </form>
      </div></>
  )
}

export default AddDecuction