import { React, useCallback, useState } from 'react';
import CustomDeleteloader from '../components/CustomDeleteloader'
import { useDispatch } from 'react-redux';
import ReactPortal from "../components/reactPortal/ReactPortal";
import Modal from "../components/modal/Modal";
import { useNavigate } from 'react-router-dom';
import { setUser } from "../store/slices/userSlice";
import { errorMessage, successMessage } from '../components/toastMessages/ToastifyMsg';
import './changepassword.css'
import { useDeleteUserAccountMutation } from '../store/apis/baseApi';


function DeleteAccount() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [logoutLoader, setLogoutLoader] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [deleteUserAccount] = useDeleteUserAccountMutation()



    const logout = (message) => {
        localStorage.removeItem("tradingformtoken");
        dispatch(setUser(null));
        setIsConfirmation(false);
        successMessage(message);
        navigate("/login");
    };

    const handleSubmit = async () => {
        try {
            const { data, error } = await deleteUserAccount();
            if (data) {
                if (data.success) {
                    logout(data?.message);
                }
                if (!data.success) {
                    errorMessage(data?.message);
                }
            }
            if (error) {
                errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
            }
        } catch (error) {

        }

    }

    const openConfirm = useCallback(
        (e) => {
            e.preventDefault();
            setIsConfirmation(true);
        },
        [],
    )


    const confirmationModal = () => {
        // e.preventDefault();
        setIsConfirmation(false);
        setLogoutLoader(true);
        handleSubmit();

    }

    return (
        <>
            {logoutLoader === true &&
                <CustomDeleteloader message="Logging out . . ." />
            }

            {/* <div className='heading-profile'> <h4 className='text-danger'>Deactivate Account</h4></div> */}
            <div className="delete-account-wrapper"> 
                <div className="danger-icon-container">
                    <i className="fa fa-exclamation-triangle text-danger"></i>
                </div>
                <div className="password-fields">
                    <h6 className='text-danger text-center'>Your account will be deactivated after this action. To reactivate your account contact our support team.</h6>
                    <form className='profile-form' onSubmit={openConfirm}>
                        <button type='submit' className='btn btn-danger bg-danger button-container'><i className="fa fa-exclamation-triangle"></i> <span>Deactivate</span></button>
                    </form>
                </div>

            </div>


            <ReactPortal>
                {isConfirmation && (
                    <Modal
                    icon={"exclamation-circle text-danger"}
                        header=""
                        type="confirm"
                        recordName={`Are you sure you want to Deactivate your account`}
                    >
                        <button className="btn btn-danger m-1 col-xs-12 col-sm-6 col-md-3" onClick={() => confirmationModal()}> Yes</button>
                        <button className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3" onClick={() => setIsConfirmation(false)}>No</button>
                        {/* index={index} onImage={onImage} */}
                    </Modal>
                )}
            </ReactPortal>
        </>
    )
}

export default DeleteAccount
