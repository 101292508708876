import { createPortal } from "react-dom";



function ReactPortal({ children, wrapperId = "portal-root" }) {
 
  return createPortal(children, document.getElementById(wrapperId));
}

export default ReactPortal;
