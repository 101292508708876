import React, { useCallback, useEffect, useRef, useState } from 'react'
import logo from "../../assets/images/first_without_background.webp"
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import './ContractViewer.css'
import ReactPortal from '../../components/reactPortal/ReactPortal';
import Modal from '../../components/modal/Modal';
import SignatureCanvas from 'react-signature-canvas'
import AutoSignature from '../../components/autoSignature/AutoSignature';
import UploadSign from './UploadSign';
import { useAcceptTermsMutation, useLazyGetSingleContractQuery, useSaveContractBodyMutation } from '../../store/apis/laravelApi';
import { deepCopy } from '../../utils/utils';
import { Markup } from 'react-render-markup';
import CustomLoader from '../../components/CustomLoader';
import Document from './printToPdf/Document';
import ContractDoc from './ContractDoc';
import { errorMessage, successMessage } from '../../components/toastMessages/ToastifyMsg';
// import { LARAVELAPI } from '../../common/common';
// import axios from 'axios';



function ContractViewer() {
    
    // const LARAVELurl = LARAVELAPI;

    const { contractId } = useParams();
    const documentRef = useRef(null);
    const bottomRef = useRef(null);
    const navigate = useNavigate();
    const [get_single_contract] = useLazyGetSingleContractQuery();
    const [acceptTerms] = useAcceptTermsMutation();
    const [saveContractBody] = useSaveContractBodyMutation();

    const [isConfirmation, setIsConfirmation] = useState(false)
    const [agreeModal, setAgreeModal] = useState(false);
    const [contractContent, SetContractContent] = useState({});
    const [fontColor, setFontColor] = useState("black");
    const [clientData, setClientData] = useState({})
    const [agree, setAgree] = useState(false);
    const [sigModal, setSigModal] = useState(false);
    const [sign, setSign] = useState();
    const [url, setUrl] = useState(false);
    const [showSig, setShowSig] = useState(false)
    const [signType, setSignType] = useState('Draw');
    const [loader, setLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [signTest, setSignTest] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [download, setDownload] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [contentSub, setContentSub] = useState(null);
    const [companyPro,setCompanyPro] = useState({
        companyBy: "",
        companyName: "",
        companyTitle: "",
        clientBy: "",
        clientName: "",
        clientTitle: "",
    })
    
    const clientByRef = useRef(null)
    const clientNameRef = useRef(null)
    const clientTitleRef = useRef(null)
    

    const handleChangeComp = (e)=>{
        const {name,value} = e.target;
        setCompanyPro(prevState=> ({...prevState, [name]:value}))
    }

    useEffect(() => {
        (async () => {
            try {
                setLoader(true);
                const { data, error } = await get_single_contract({ id: contractId })
                if (data) {
                    if (data.status === "success") {
                        let contractData = deepCopy(data?.data);
                        SetContractContent(contractData);
                        // console.log("contractData>",contractData)
                        let newClientdata= {
                            contractType: contractData.contracttype,
                            ...JSON.parse(contractData.content)
                        }
                        setClientData(newClientdata);
                        setAgree(contractData?.acceptance === 'yes' ? true : false);
                        if (contractData?.status === "signed") {
                            setSubmit(true);
                            setDownload(contractData?.contract_preview?.contract_download_link);
                            setContentSub(contractData?.contract_preview?.html_content);
                        }
                    }
                }
                if (error) {
                    errorMessage(error?.data?.message ?? "Server Not Responding.")
                    navigate('/')
                }
            } catch (error) {

            } finally {
                setTimeout(() => {
                    setLoader(false);
                }, 100)
                setTimeout(() => {
                    bottomRef.current.scrollIntoView({ behavior: "smooth" });
                }, 250);
                
            }

        })()
    }, [contractId,navigate, get_single_contract])


    const handleOpenSignTest = useCallback(() => {
        setSignTest(true);
    }, [])
    const handleCloseSignTest = useCallback(() => {
        setSignTest(false);
    }, [])

    const handleShowSpecimen = useCallback(() => {
        setShowSig(true);
    }, [])
    const handleCloseSpecimen = useCallback(() => {
        setShowSig(false);
    }, [])

    const handleOpenAgree = (e) => {
        e.preventDefault();
        // if (agree) {
        //     handleOpenSignTest();
        // } else {
            if (url && companyPro?.clientName.trim() && companyPro?.clientTitle.trim() ) {
                setAgreeModal(true);
            }
            else{
                if (!url ) {
                    handleOpenSignTest();
                    clientByRef.current.scrollIntoView({
                        behavior: 'smooth',
                      })
                    return;
                }
                if (!companyPro?.clientName.trim()  ) {
                    clientNameRef.current.focus();
                    return;
                }
                if (!companyPro?.clientTitle.trim() ) {
                    clientTitleRef.current.focus();
                    return;
                }
            }
        // }
    }

    const handleCloseAgree = useCallback(
        () => {
            setAgreeModal(false);
        }, [])

    const handleCloseSigModal = useCallback(() => {
        setSigModal(false);
    }, [])

    const handleClearSignature = () => {
        sign.clear();
        setUrl(false)
    }

    const handleChangeAgree = (e) => {
        setAgree(e.target.checked);
    }


    const handleSaveContract = async () => {
        try {
            // e.preventDefault();
            setSubmitLoader(true);
            setIsConfirmation(false);
            documentRef.current.style.width = "unset";
            documentRef.current.style.display = "block";
            let body = { cid: contractId, content: documentRef.current.outerHTML };
            // documentRef.current.style.width = "210mm";
            documentRef.current.style.display = "none";
            // console.log("axios call")

            // let token =  localStorage.getItem("tradingformtoken");
            // let {data, error }= await axios(`${LARAVELurl}v1/send_contract_content_details`, {
            // method: "POST",
            // data: body,
            // headers: {
            //     // "Content-Type": "application/json;",
            //     "Access-Control-Allow-Origin": "*",
            //     'Cross-Origin-Opener-Policy': '*',
            // },
            // })

            const { data, error } = await saveContractBody(body);
            if (data) {
                if (data.status === "success") {
                    setDownload(data?.data?.preview_contract);
                    const url = data?.data?.preview_contract;
                    if (url) {
                        const anchor = document.createElement('a');
                        anchor.href = url;
                        anchor.download = `contract.pdf`;
                      
                        // Prevent default behavior of the click event
                        anchor.addEventListener('click', (event) => {
                          event.preventDefault();
                      
                          // Trigger the download manually
                          window.open(url, '_blank');
                      
                          // Revoke the object URL after the download is complete
                          window.URL.revokeObjectURL(url);
                        });
                      
                        // Simulate a click to initiate the download
                        anchor.click();
                      }
                    successMessage(data?.message);
                    setSubmit(true);
                }
                if (data.status === "error") {
                    errorMessage(data?.message);
                }
            }
            if (error) {
                // console.log("axiosCall-error>>",error)
                errorMessage(error?.data?.message ?? "Server Not Responding");
            }
        } catch (error) {

        } finally {
            setTimeout(() => {
                setSubmitLoader(false);
            }, 2000);
        }

        // html2canvas(input)
        //     .then((canvas) => {
        //         const imgData = canvas.toDataURL('image/png');

        //         // Document of a4WidthMm wide and inputHeightMm high
        //         if (inputHeightMm > a4HeightMm) {
        //             // elongated a4 (system print dialog will handle page breaks)
        //             const pdf = new jsPDF('p', 'mm', [inputHeightMm + 250, a4WidthMm]);
        //             console.log(pdf)
        //             pdf.addImage(imgData, 'PNG', 0, 0);
        //             console.log("pdf>>", pdf)
        //             pdf.save(`${id}.pdf`);
        //             const blob = pdf.output('blob');

        //             console.log(blob)

        //             // Create a FormData object to send the PDF file to the API
        //             const formData = new FormData();
        //             formData.append('file', blob);
        //         } else {
        //             // standard a4
        //             const pdf = new jsPDF();
        //             pdf.addImage(imgData, 'PNG', 0, 0);
        //             pdf.save(`${id}.pdf`);
        //             const blob = pdf.output('blob');
        //             console.log(blob)

        //             // Create a FormData object to send the PDF file to the API
        //             const formData = new FormData();
        //             formData.append('file', blob);
        //         }

        //     });




        ////////////////////////////////////////////////////////
        // System to manually handle page breaks
        // Wasn't able to get it working !
        // The idea is to break html2canvas screenshots into multiple chunks and stich them together as a pdf
        // If you get this working, please email me a khuranashivek@outlook.com and I'll update the article
        ////////////////////////////////////////////////////////
        // range(0, numPages).forEach((page) => {
        //   console.log(`Rendering page ${page}. Capturing height: ${a4HeightPx} at yOffset: ${page*a4HeightPx}`);
        //   html2canvas(input, {height: a4HeightPx, y: page*a4HeightPx})
        //     .then((canvas) => {
        //       const imgData = canvas.toDataURL('image/png');
        //       console.log(imgData)
        //       if (page > 0) {
        //         pdf.addPage();
        //       }
        //       pdf.addImage(imgData, 'PNG', 0, 0);
        //     });
        //   ;
        // });

        // setTimeout(() => {
        //   pdf.save(`${id}.pdf`);
        // }, 5000);

    }



    const handleSubmitAgree = async (e) => {
        try {
            e.preventDefault();
            if (!agree) {
                // alert('Agree to the terms and conditions to proceed');
                setErrorMsg('Agree to terms and conditions to proceed');
                setTimeout(() => {
                    setErrorMsg(false)
                }, 2000);
            }
            else {
                setAgreeModal(false);
                setSubmitLoader(true)

                // let body ={ cid: contractId, accepting: agree ? 'yes' : 'no' }

                // let {data, error }= await axios(`${LARAVELurl}v1/accept_terms_conditions_contract`, {
                //     method: "POST",
                //     data: body,
                //     headers: {
                //         // "Content-Type": "application/json;",
                //         "Access-Control-Allow-Origin": "*",
                //         'Cross-Origin-Opener-Policy': '*',
                //     },
                //     })

                const { data, error } = await acceptTerms({ cid: contractId, accepting: agree ? 'yes' : 'no' })
                if (data) {
                    // console.log("accept-axiosCall")
                    successMessage(data?.message);
                    // setSignTest(true);
                    await handleSaveContract();
                }
                if (error) {
                    setAgreeModal(true);
                    errorMessage(error?.data?.message ?? "Error.");
                }
            }
        } catch (error) {        }
        finally {
            setSubmitLoader(false);
        }
    }
   

    const handleSaveSignature = (signCanvas) => {
        // this function only gets bas64 from draw signature & closes the modal for all signature types 'draw, 'type' and 'upload'
        if (signType === "Draw") {
            if (!signCanvas._sigPad._isEmpty) {
                let image = sign.getTrimmedCanvas().toDataURL('image/png')
                setUrl(image);
                setSignTest(false);
            }
            else {
                setErrorMsg("Draw signatures to save.");
                setTimeout(() => {
                    setErrorMsg(false);
                }, 2000);
            }
        }

        if (!url && (signType === 'Type' || signType === 'Upload')) {
            setErrorMsg(signType === 'Type' ? "Select signatures." : 'Upload Signature.');
            setTimeout(() => {
                setErrorMsg(false);
            }, 2000);
        }
        if (url && (signType === 'Type' || signType === 'Upload')) {
            setSignTest(false);
        }

    }

    const handleChangeFileSig = (image) => {
        // this function only gets bas64 from auto signature & upload signature only 
        setUrl(image)
    }


    

    const handleChangeColor = async (color) => {
        setFontColor(color);
    }

    const handleConfirmation = (e) => {
        e.preventDefault();
        setIsConfirmation(true);
    }

    const downloadContract = () => {
        setDownloading(true);
        const url = download;
        if (url) {
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `contract.pdf`;
          
            // Prevent default behavior of the click event
            anchor.addEventListener('click', (event) => {
              event.preventDefault();
          
              // Trigger the download manually
              window.open(url, '_blank');
          
              // Revoke the object URL after the download is complete
              window.URL.revokeObjectURL(url);
            });
          
            // Simulate a click to initiate the download
            anchor.click();
          }
        setTimeout(() => {
            setDownloading(false)
        }, 1500);
    }



    // const docHtml = ()=>{
    //     setSubmitLoader(true);
    //     const input = document.getElementById('documentToPrint')
    //     // input.style.display = 'block'
    //     const width = documentRef.current.style.width;
    //     documentRef.current.style.width= "unset";
    //     documentRef.current.style.display= "block";
    //     console.log(width); 
    //     console.log(documentRef.current.outerHTML);
    //     documentRef.current.style.width = "210mm";
    //     documentRef.current.style.display= "none";

    //     setTimeout(() => {
    //         // input.style.display = 'none'
    //         setSubmitLoader(false);
    //     }, 1000);

    // }

    // console.log(handleSaveContract())
    return (<>

        {(loader || submitLoader) && (
            <CustomLoader />
        )

        }

        <section className="contract-wrapper">

            <div className="contract-head-wrapper">
                <div className="contract-header-inner-wrapper">
                    <div className="brandLogo">
                        <NavLink className="navbar-brand me-0" to="/">
                            <img src={logo} alt="logo" width="100" height="auto"></img>
                        </NavLink>
                    </div>
                    <div className="">
                        <h4 className='text-light'><span className='pos-rel'>TradingForms</span> Contract</h4>
                    </div>
                </div>
            </div>
            {/* <h4 className="text-warning my-3">Under Development</h4> */}
            <div className="container">
                <div className="contract-body-wrapper">
                    <div className="contract-body-inner-wrapper">
                        {submit &&<>
                            <p className='text-danger m-0 text-center'><i>You have already signed this contract.</i></p>
                            <div className="export-container">
                                <button type='button' className={`btn btn-primary ${downloading ? 'cursor-disable' : ''}`} disabled={downloading} onClick={downloadContract} target='_blank'>
                                    {downloading ? <div className="downloading"><span className="custom-arrow"></span></div> : <i className="fa fa-file-pdf"></i>} <span>Export</span>
                                </button>
                            </div>
                        </>
                            }
                        <div className='main-contract'>
                            {/* <Markup markup={contractContent?.content}/> */}
                            {/* <Document/> */}
                            {!loader && <>

                                {contractContent?.status === "signed" ? <><Markup markup={contentSub} /> </> : <ContractDoc contractContent={clientData} submit={submit} companyPro={companyPro} clientNameRef={clientNameRef} clientByRef={clientByRef} clientTitleRef={clientTitleRef} url={url} handleOpenSignTest={handleOpenSignTest} handleChange={handleChangeComp} handleShowSpecimen={handleShowSpecimen}/>

                                }
                                <Document contractContent={clientData} handleOpenSignTest={handleOpenSignTest}  companyPro={companyPro} documentRef={documentRef} url={url} />

                            </>

                            }
                        </div>
                        {/* {!contentSub &&
                            <div className="contract-signature-img-wrapper">
                                <div className="contract-signature-img-label-wrapper">
                                    <div className="signature-label">
                                        <h4 onClick={!url ? handleOpenAgree : handleShowSpecimen}>Signature:</h4>
                                    </div>
                                    <div className="contract-signature-img-inner-wrapper">
                                        {url &&
                                            <img onClick={handleShowSpecimen} src={url} alt='signature' width={150} height={100}></img>
                                        }
                                    </div>
                                </div>
                            </div>
                        } */}
                        {!submit &&
                            <div className="agrement-action-wrapper">
                                <form ref={bottomRef} onSubmit={(e) => handleOpenAgree(e)}>
                                    {/* {(!agree || !url) ? ( */}
                                    <button type='button' className='btn btn-save' onClick={(e) => handleOpenAgree(e)}>Agree</button>
                                    {/* // ) */}
                                    {/* //     : (<button className='btn btn-save' type='submit' >Submit</button>)} */}

                                </form>

                            </div>
                        }
                    </div>
                </div>

            </div>

        </section>


        {agreeModal &&
            <Modal
                header={`Agreement`}
                type="template"
                contentType="notscrollable"
            >
                <div className="agreement-conditon-wrapper">
                    <form onSubmit={(e) => handleSubmitAgree(e)}>
                        <div className="agreement-input-container">
                            <input
                                name="agree"
                                type="checkbox"
                                id='agree'
                                defaultChecked={agree}
                                onChange={e => handleChangeAgree(e)} />
                            <label htmlFor='agree'>I've read and accept the <strong className='text-danger'>terms & conditions</strong> written in the document.</label>
                        </div>
                        {errorMsg && <small className='text-danger'>{errorMsg}</small>}
                        <div className='agreement-submit'>
                            <button type='button' className='btn btn-secondary btn-agre-close' onClick={handleCloseAgree}>READ AGAIN</button>
                            <button type='submit' className={`btn btn-save ${!agree ? 'cursor-disable' : ''}`} disabled={!agree}>SUBMIT</button>
                        </div>
                    </form>


                </div>

            </Modal>
        }

        {sigModal &&
            <ReactPortal>
                <Modal
                    header={`${signType} Your Signatures`}
                    type="form"
                    handleClose={handleCloseSigModal}
                >
                    <div>
                        <div className="sign-btn-tabs-wrapper">
                            <button type='button' className={`btn btn-tab-sign ${signType === 'Draw' ? 'active' : ''}`} onClick={() => setSignType('Draw')}>Draw</button>
                            <button type='button' className={`btn btn-tab-sign ${signType === 'Type' ? 'active' : ''}`} onClick={() => setSignType('Type')}>Type</button>
                            <button type='button' className={`btn btn-tab-sign ${signType === 'Upload' ? 'active' : ''}`} onClick={() => setSignType('Upload')}>Upload</button>
                        </div>
                    </div>
                    <div className="signature-wrapper">
                        {signType === 'Draw' && (
                            <>
                                <div className="signature-inner-wrapper">
                                    <SignatureCanvas
                                        penColor='green'
                                        ref={data => setSign(data)}
                                        canvasProps={{ width: '100%', height: '100%' }}
                                        clearOnResize={true}
                                    />
                                </div>
                                <div className="signature-actions">
                                    <button className='btn btn-save' onClick={() => handleSaveSignature(sign.getTrimmedCanvas().toDataURL('image/png'))}>Save</button>
                                    <button className='btn btn-primary' onClick={handleClearSignature}>Clear</button>
                                </div>
                            </>)
                        }
                        {signType === 'Type' &&
                            (
                                <>
                                    <AutoSignature client={clientData} handleSaveSignature={handleSaveSignature} />
                                </>
                            )
                        }
                        {signType === 'Upload' &&
                            (<>
                                <UploadSign handleSaveUploadSign={handleSaveSignature} />
                            </>)
                        }
                        <div className='my-1'>
                            <small className="text-danger">Note: Signatures once saved cannot be changed or updated.</small>
                        </div>

                    </div>

                </Modal>
            </ReactPortal>
        }
        {showSig &&
            <ReactPortal>
                <Modal handleClose={handleCloseSpecimen} header={"Signature Specimen"} type='form'>
                    <div className='signature-specimen-wrapper'>
                        <img src={url} alt='signature_Specimen'></img>
                    </div>
                </Modal>
            </ReactPortal>
        }
        {signTest &&
            <ReactPortal>
                <Modal type="contract" handleClose={handleCloseSignTest}>
                    <div className="custom-contract-modal-header">
                        <i className='fa fa-times' onClick={handleCloseSignTest}></i>
                    </div>
                    <div className="custom-contract-modal-body">
                        <div className="tab-container">
                            <ul className="tabs-pills">
                                <li className={signType === 'Draw' ? 'active' : ''} onClick={() => { setSignType('Draw'); setUrl(false); }}>Draw</li>
                                <li className={signType === 'Type' ? 'active' : ''} onClick={() => { setSignType('Type'); setUrl(false); }}>Type</li>
                                <li className={signType === 'Upload' ? 'active' : ''} onClick={() => { setSignType('Upload'); setUrl(false) }}>Upload</li>
                            </ul>
                            <div className="tab-panels">
                                <div className="tab-content">
                                    <div className="signatuter-canvas-container">

                                        {signType === 'Draw' && <>
                                            {/* <p>Draw Your Signature</p> */}
                                            <div className="draw-canvas-wrapper">
                                                <SignatureCanvas
                                                    penColor={fontColor}
                                                    ref={data => setSign(data)}
                                                    canvasProps={{ width: 400, height: 200 }}
                                                    clearOnResize={true}
                                                />
                                            </div>
                                            <p className='text-danger text-center'> <i>{errorMsg && errorMsg}</i></p>
                                        </>
                                        }
                                        {
                                            signType === 'Type' &&
                                            (<>
                                                <AutoSignature client={clientData} selectedColor={fontColor} handleSaveSignature={handleSaveSignature} handleChangeFileSig={handleChangeFileSig} />
                                            </>)
                                        }
                                        {
                                            signType === 'Upload' &&
                                            (<> <UploadSign handleChangeFileSig={handleChangeFileSig} /></>)
                                        }
                                        {signType !== 'Upload' &&
                                            <div className="action-container">
                                                <div className="font-color-wrapper">
                                                    <div className='select-color-label-container'><span>Select Color</span></div>
                                                    <div className='color-select-btn-container'>
                                                        <button style={{ 'backgroundColor': 'black' }} onClick={() => handleChangeColor('black')}>{fontColor === "black" && <i className="fas fa-check"></i>}</button>
                                                        <button style={{ 'backgroundColor': 'blue' }} onClick={() => handleChangeColor('blue')}>{fontColor === "blue" && <i className="fas fa-check"></i>}</button>
                                                        <button style={{ 'backgroundColor': 'red' }} onClick={() => handleChangeColor('red')}>{fontColor === "red" && <i className="fas fa-check"></i>}</button>
                                                        <button style={{ 'backgroundColor': '#1d3363' }} onClick={() => handleChangeColor('#1d3363')}>{fontColor === "#1d3363" && <i className="fas fa-check"></i>}</button>
                                                        <button style={{ 'backgroundColor': 'green' }} onClick={() => handleChangeColor('green')}>{fontColor === "green" && <i className="fas fa-check"></i>}</button>

                                                    </div>
                                                </div>
                                                {signType === 'Draw' &&
                                                    <button className='btn btn-clear bg-light' onClick={handleClearSignature}><i className='fa fa-undo'></i> Clear</button>
                                                }
                                            </div>}

                                        <div className="save-container">
                                            <button className={`btn btn-save ${(signType !== 'Draw'&& !url) ? 'cursor-disable':''}`} disabled={signType === 'Draw' ? false : !url} onClick={() => handleSaveSignature(sign)}>Save</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </Modal>
            </ReactPortal>
        }

        {/* <ReactPortal>
            {isConfirmation && (
                <Modal
                    header=""
                    type="confirm"
                    recordName={`Are you sure you want to submit contract`}
                    icon={"exclamation-circle text-custom-success"}
                >
                    <button
                        className="btn btn-save m-1 col-xs-12 col-sm-6 col-md-3"
                        onClick={() => handleSaveContract()}
                    >
                        {" "}
                        Yes
                    </button>
                    <button
                        className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3"
                        onClick={() => setIsConfirmation(false)}
                    >
                        No
                    </button>
                </Modal>
            )}
        </ReactPortal> */}
    </>
    )
}

export default ContractViewer