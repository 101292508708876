import React, { useState } from "react";
import InvoiceDescription from "./InvoiceDescription";
import InvoiceNotes from "./InvoiceNotes";
import PersonalDetails from "./PersonalDetails";
import "./formStepper.css";
import { errorMessage } from "../toastMessages/ToastifyMsg";

function Edit(
  {
    formData,
    handleBlur,
    handleTeleFields,
    handleAddEarning,
    handleAddDeduction,
    handleRemoveDescription,
    update,
    handleChange,
    total,
    totalDeductions,
    handleFileInputChange,
    selectedCurrency,
    saveModal,
    logoImage,
    removeImage,
    fillFields,
    autoSuggstion,
    suggestList,
    allDeductionitems,
    allEarningItems,
    autoSuggstionitems,
    fillItemFields,
    showSuggest,
    setShowSuggest,
    showSuggestEarn,
    setShowSuggestEarn,
    showSuggestDeduct,
    setShowSuggestDecuct,
    handleHideSuggestion,
    readonlyFrom
  }
) {
  const [showStep, setShowStep] = useState(0); // state for form steps
  const [stepperState,setStepperState] = useState("left");

  // ============================
  const [toName,setToName] = useState(false);
  const [toEmail,setToEmail] = useState(false);
  const [fromName,setFromName] = useState(false);
  const [fromEmail,setFromEmail] = useState(false);


  const stepVerify = () => {

    if (formData.from_name.trim() === '' || formData.from_email.trim() === '' || formData.to_employee_name.trim() === '' || formData.to_employee_email.trim() === '') {
        if (formData.from_name.trim() === '') {
          setFromName(true);
          setTimeout(() => {
            setFromName(false);
          }, 1000);
        }
        if (formData.from_email.trim() === '') {
          setFromEmail(true);
          setTimeout(() => {
            setFromEmail(false);
          }, 1000);
        }
        if (formData.to_employee_name.trim() === '') {
          setToName(true);
          setTimeout(() => {
            setToName(false);
          }, 1000);
        }
        if (formData.to_employee_email.trim() === '') {
          setToEmail(true);
          setTimeout(() => {
            setToEmail(false);
          }, 1000);
        }
        errorMessage("Enter required fields to proceed.");
    }
    else{

      setShowStep(showStep + 1);
      setStepperState("left");
    }
  }

  return (
    <>
      <div className="container px-0 overflow-hidden">
        <div className="col-12">
          <div className="invoiceForm py-3">
            <ul className="form-stepper form-stepper-horizontal text-center mx-auto pl-0">
              {/* Step 1  */}
              <li
                className={`text-center form-stepper-list ${
                  showStep === 0
                    ? "form-stepper-active"
                    : "form-stepper-completed"
                }`}
                step="1"
              >
                <button
                  className="mx-2"
                  type="button"
                  onClick={() => setShowStep(0)}
                >
                  <span className="form-stepper-circle">
                    <span>
                      <i className="fas fa-user"></i>
                    </span>
                  </span>
                  {/* <div className="label">Personal <br></br> Details</div> */}
                </button>
              </li>
              {/* Step 2  */}
              <li
                className={`form-stepper-unfinished text-center form-stepper-list ${
                  showStep === 1
                    ? "form-stepper-active"
                    : showStep === 2
                    ? "form-stepper-completed"
                    : ""
                }`}
                step="2"
              >
                <button
                  className="mx-2"
                  type="button"
                  onClick={() => {if (update) {setShowStep(1)}} }
                >
                  <span className="form-stepper-circle text-muted">
                    <span>
                      <i className="fa fa-prescription-bottle"></i>
                    </span>
                  </span>
                  {/* <div className="label text-muted">Salary<br></br> Details</div> */}
                </button>
              </li>
              {/* Step 3 */}
              <li
                className={`form-stepper-unfinished text-center form-stepper-list ${
                  showStep === 2 ? "form-stepper-active" : ""
                }`}
                step="3"
              >
                <button
                  className="mx-2"
                  type="button"
                  onClick={() => {if(update){ setShowStep(2)}}}
                >
                  <span className="form-stepper-circle text-muted">
                    <span>
                      <i className="fa fa-sticky-note"></i>
                    </span>
                  </span>
                  {/* <div className="label text-muted">Invoice <br></br> Notes</div> */}
                </button>
              </li>
            </ul>
          </div>
          <div
            className="invoiceFormContainer border-slip"
            // style={{ borderTop:`4px solid ${formData?.color}`}}
          >
            {" "}
            <form onSubmit={(event) => saveModal(event)}>
              <div className="formStepsContainer d-flex">
                {showStep === 0 && (
                  <div data-aos={`fade-${stepperState}`}>
                    <PersonalDetails
                      formData={formData}
                      handleBlur={handleBlur}
                      handleFileInputChange={handleFileInputChange}
                      logoImage={logoImage}
                      removeImage={removeImage}
                      fillFields={fillFields}
                      autoSuggstion={autoSuggstion}
                      suggestList={suggestList}
                      showSuggest={showSuggest}
                      setShowSuggest={setShowSuggest}
                      readonlyFrom={readonlyFrom}
                      handleTeleFields={handleTeleFields}
                      toName= {toName}
                      toEmail= {toEmail}
                      fromName={fromName}
                      fromEmail={fromEmail}
                    />
                  </div>
                )}
                {showStep === 1 && (
                  <div className="w-100" data-aos={`fade-${stepperState}`}>
                    <InvoiceDescription
                      handleBlur={handleBlur}
                      formData={formData}
                      handleAddEarning={handleAddEarning}
                      handleAddDeduction={handleAddDeduction}
                      handleRemoveDescription={handleRemoveDescription}
                      handleChange={handleChange}
                      // handleChange2={handleChange2}
                      total={total}
                      totalDeductions={totalDeductions}
                      // selectedCurrency={selectedCurrency}
  
                      allDeductionitems={allDeductionitems}
                      allEarningItems={allEarningItems}
                      autoSuggstionitems={autoSuggstionitems}
                      fillItemFields={fillItemFields}
                      showSuggestEarn={showSuggestEarn}
                      setShowSuggestEarn={setShowSuggestEarn}
                      showSuggestDeduct={showSuggestDeduct}
                      setShowSuggestDecuct={setShowSuggestDecuct}
                      handleHideSuggestion={handleHideSuggestion}
                    />
                  </div>
                )}
                {/* {showStep === 2 && (
                  <TaxationDiscount
                    formData={formData}
                    setFormData={setFormData}
                  />
                )} */}
                {showStep === 2 && (
                  <div className="w-100" data-aos={`fade-${stepperState}`}>
                    <InvoiceNotes formData={formData} handleBlur={handleBlur} />
                  </div>
                )}
              </div>

              <div className="formNavigateContainer">
                {showStep !== 0 && (
                  <button
                    type="button"
                    disabled={showStep === 0 ? true : false}
                    onClick={() => {setShowStep(showStep - 1);setStepperState("right");}}
                    className="btn btn-primary m-1 btn-sm"
                    title="Go Back To Previous Step"
                  >
                    <span>
                      {" "}
                      <i className="fas fa-chevron-left"></i>
                    </span>
                    &nbsp; <span> Previous</span>
                  </button>
                )}
                {showStep < 2 && (
                  <button
                    type="button"
                    onClick={stepVerify}
                    className="btn btn-primary m-1 btn-sm"
                    title="Go To Next Step"
                  >
                    <span> Next </span>&nbsp;{" "}
                    <span>
                      {" "}
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </button>
                )}
                {showStep === 2 && (
                  <button
                    type="submit"
                    className={`btn btn-save m-1 btn-sm ${
                      formData?.from_name !== "" &&
                      formData.email !== "" &&
                      formData.to_employee_name !== "" &&
                      formData.to_employee_email !== ""
                        ? ""
                        : "cursor-disable"
                    }`}
                    title={
                      formData?.from_name !== "" &&
                      formData.email !== "" &&
                      formData.to_employee_name !== "" &&
                      formData.to_employee_email !== ""
                        ? `Click to ${update ? "Update" : "Save"}.`
                        : "Please fill all required fields."
                    }
                    disabled={
                      formData?.from_name !== "" &&
                      formData.email !== "" &&
                      formData?.salary_date !== "" &&
                      formData.to_employee_name !== "" &&
                      formData.to_employee_email !== ""
                        ? false
                        : true
                    }
                  >
                    <span>
                      {" "}
                      {update ? "Update" : "Save"} &nbsp;{" "}
                      <i className="fas fa-save"></i>{" "}
                    </span>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        {/* <div className="col-sm-12 col-md-12 col-lg-4 pt-5">
            <TaxationDiscount handleBlur={handleBlur} formData={formData}/>
          </div> */}
      </div>
      {/* <ReactPortal>
            {isOpen && 
            <Modal handleClose={handleModalClose} header="Record Payment">
              <InvoicePaymentRecord handleDeletePayment={handleDeletePayment} handleAddPayment={handleAddPayment} handleModalClose={handleModalClose} formData={formData}/>
            </Modal>}
      </ReactPortal> */}
    </>
  );
}

export default Edit;
