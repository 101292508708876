import React, { useState, useEffect } from 'react'
import moment from "moment/moment";
import { dataTablecustomStyles, deepCopy, separateComma } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ExportExpenses from '../../components/createExpense/ExportExpenses';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
// import TableLoader from '../../components/loaders/TableLoader';


const ExpenseListing = ({ expensesData, confirmation, year, loader }) => {
    const navigate = useNavigate();
    const [allExpenses, setAllExpenses] = useState([]);
    const [search, setSearch] = useState("");
    const [filterItems, setFilterItems] = useState([]);

    useEffect(() => {
        let expenseData = deepCopy(expensesData);
        setAllExpenses(expenseData);
        setFilterItems(expenseData);


    }, [expensesData]);

    const loaderElement = <div className="table-loader">
        <svg viewBox="25 25 50 50">
            <circle r="20" cy="50" cx="50"></circle>
        </svg>
    </div>


    const columns = [
        {
            name: "#",
            selector: (row) => row.srNum,
            sortable: true,
            width: '60px',
        },{
            name: "Title",
            selector: (row) => row.expenses_title,
            sortable: true,
            // width: '225px',
        },
        {
            name: "Category",
            selector: (row) => (<span className="text-capitalize">{row.expenses_category}</span>),
            // sortable: true,
            //    width: '125px'
        }
        , {
            name: "Date",
            selector: (row) => moment(row.expenses_date).format("ll"),
            // sortable: true,
            // width: '125px'
        },
        {
            name: "Expense Amount",
            selector: (row) => (<div>
                <span>{separateComma(row.expenses_total_amount)}
                </span>
            </div>),
            //  width: '125px'
            // sortable: true,
        },
        {
            name: "Expense Tax",
            selector: (row) => (<div>
                <span>{separateComma(row.expenses_total_amount_tax)}
                </span>
            </div>),
            //  width: '125px'
            // sortable: true,
        },
        {
            // name: "",
            cell: (row) => (
                <div className="d-flex" title="Select an action">
                   
                    <DropdownButton
              as={ButtonGroup}
              key={'start'}
              id={`dropdown-button-drop-${row.id}`}
              drop={'start'}
              variant="dropdown-menu-custom"
              title={<i className="fas fa-ellipsis-v"></i> }
              // size="sm"
            >
              <Dropdown.Item eventKey="1">
                    <div
                    className="d-flex gap-2 align-items-center listing-button-edits"
                    onClick={() => {
                        navigate(`/expenses/edit/${row.expense_id}`);
                    }} 
                    title='View Expenses'
                  >
                    <i className="fa fa-eye"></i> View
                  </div>
              </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <div 
                className="d-flex gap-2 align-items-center listing-button-edits"
                onClick={() => {
                    navigate(`/expenses/edit/${row.expense_id}`);
                }}
                title="Edit Expenses"
              >
                <i className="fa fa-edit"></i> Edit
              </div>
            </Dropdown.Item>

              <Dropdown.Item eventKey="3">
                <div
                  className="d-flex gap-2 align-items-center listing-button-edits"
                  onClick={() => { confirmation(row.expense_id, row.expenses_title) }}
                  title={`Delete ${row.expenses_title}`}
                >
                  <i className="fa fa-trash-alt text-danger"></i> Delete
              </div>
              </Dropdown.Item>

              {/* <Dropdown.Divider /> */}

              

            </DropdownButton>

                    {/* <div className="table-actions-wrapper">
                            <div>
                                <span><i className="fa fa-ellipsis-v"></i></span></div>
                </div> */}
                </div>
            ),
            width: '60px',
            right: true,
            allowOverflow: true,
            button: true,
            // allowOverflow: true,
            // reorder: true

        },
    ]

    useEffect(() => {
        const result = allExpenses?.filter((expense) => {
            return expense?.expenses_title?.toLowerCase().match(search?.toLowerCase()) || expense?.expenses_category?.toLowerCase().match(search?.toLowerCase()) || expense?.expenses_vendor?.toLowerCase().match(search?.toLowerCase());
        });
        setFilterItems(result);
    }, [search, allExpenses]);



    return (
        <>

            <div className="table-wrapper">
                <DataTable
                    columns={columns}
                    data={filterItems}
                    fixedHeader
                    fixedHeaderScrollHeight="400px"
                    className="table-scroll-custom"
                    highlightOnHover
                    pointerOnHover
                    responsive
                    progressPending={loader}
                    progressComponent={loaderElement}
                    // actions={

                    //     <ExportExpenses allExpenses={allExpenses} year={year} />
                    // }
                    subHeader
                    subHeaderComponent={
                        <div className="col-12">
                            {filterItems.length >0 &&
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                    <div className="col-md-4 col-12">
                                        <div className="table-header-custom gap-2 justify-content-start align-items-center">
                                            {/* <label className="col-md-3 col-12 text-start" htmlFor="search">Search: </label> */}
                                            <div className="col-12">
                                                <input
                                                    type="search"
                                                    placeholder="Search by Title or Category"
                                                    className="form-control"
                                                    value={search}
                                                    autoComplete="off"
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    name="search"
                                                    id="search"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    { allExpenses.length>0 &&
                                    <div className='col-md-3 col-12 d-flex justify-content-end'>
                                        <ExportExpenses allExpenses={allExpenses} year={year} />
                                    </div>
                                }
                                </div>
                            }
                        </div>
                    }
                    // subHeaderAlign="left"
                    onRowClicked={(row, event) => { navigate(`/expenses/edit/${row.expense_id}`) }}
                    pagination
                    sortIcon={<i className="fa fa-angle-down"></i>}
                    customStyles={dataTablecustomStyles}
                />
            </div>
        </>

    )
}

export default ExpenseListing