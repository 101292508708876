import { configureStore  } from "@reduxjs/toolkit";
import baseApi from "./apis/baseApi";
import { authSlice } from "./slices/userSlice";
import { backgroundSlice } from "./slices/backgroundPicture";
import { topNotificationSlice } from "./slices/notification";
import laravelApi from "./apis/laravelApi";
import { allTemplatesSlice } from "./slices/allTemplates";
import {  subscribedPlanDetailsSlice } from "./slices/subscribedPlanDetails";

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,

    // NODE APIS REDUCER
    [baseApi.reducerPath]: baseApi.reducer,

    // LARAVEL APIS REDUCER
    [laravelApi.reducerPath]: laravelApi.reducer,

    // backgorund 
    backgroundUrl: backgroundSlice.reducer,

    // top notification
    topNotification: topNotificationSlice.reducer,
    
    // for all templates 
    allTemplates: allTemplatesSlice.reducer,

    // for subscribed plansDetails
    planDetails: subscribedPlanDetailsSlice.reducer
  },
  
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(baseApi.middleware,laravelApi.middleware),
  devTools: false
});
