// import React from 'react'




import React  from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faCircleChevronLeft, 
//   faCircleChevronRight, 
//   faCircleXmark
// } from '@fortawesome/free-solid-svg-icons'

import './ModalImages.css';

const ModalImages = ({children}) => {

//   const [slideNumber, setSlideNumber] = useState(0)
//   const [openModal, setOpenModal] = useState(false)

//   const handleOpenModal = (index) => {
//     setSlideNumber(index)
//     setOpenModal(true)
//   }

  // Close Modal
//   const handleClose = () => {
//     setOpenModal(false)
//   }

  // Previous Image
//   const prevSlide = () => {
//     slideNumber === 0 
//     ? setSlideNumber( galleryImages.length -1 ) 
//     : setSlideNumber( slideNumber - 1 )
//   }

//   // Next Image  
//   const nextSlide = () => {
//     slideNumber + 1 === galleryImages.length 
//     ? setSlideNumber(0) 
//     : setSlideNumber(slideNumber + 1)
//   }

  return (
    // <div>

      
    //     <div className='sliderWrap'>
    //         <i class="fa fa-times-circle btnClose" onClick={handleClose}></i>
    //         <i class="fa fa-chevron-circle-left btnPrev" onClick={prevSlide}></i>
    //         <i class="fa fa-chevron-circle-right btnNext" onClick={nextSlide}></i>


    //       <i icon={faCircleXmark} className='btnClose'   ></i>
          
    //       <i icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} ></i>
    //       <i icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} ></i>
    //       <div className='fullScreenImage'>
    //         <img src={galleryImages[slideNumber].img} alt='' />
    //       </div>
    //     </div>
      

    //   <br />
    //   Current slide number:  {slideNumber}
    //   <br />
    //   Total Slides: {galleryImages.length}
    //   <br /><br />

    //   <div className='galleryWrap'>
    //     {
    //       galleryImages && galleryImages.map((slide, index) => {
    //         return(
    //           <div 
    //             className='single' 
    //             key={index}
    //             onClick={ () => handleOpenModal(index) }
    //           >
    //             <img src={slide.img} alt='' />
    //           </div>
    //         )
    //       })
    //     }
    //   </div>

    // </div>
    <div>
        {children}
    </div>
  )
}

export default ModalImages
// export default ModalImages