import React from 'react'
import moment from 'moment/moment'
import { separateComma } from '../../utils/utils'
import { useRef,useEffect } from 'react';
import { Tooltip } from 'bootstrap';

function SinglePaymentRecord({element, currency, handleDeletePayment}) {
  const tooltipRef = useRef(null);  
  
    useEffect(() => {
      if (element.paymentNote.trim()) {
        var tooltip = new Tooltip(tooltipRef.current, {
            title: `Note: ${element?.paymentNote}`,
            placement: 'right',
            trigger: 'hover'
        })
        
      }
    },[element])
  return (
    <>
        <div className="singlePaymentRecord">
            <div className="singlePaymentRecordActions">
                <button type="button" className="btn" onClick={()=>handleDeletePayment(element.id)}><i className="fa fa-times text-danger"></i></button>
            </div>
            <div className="singlePaymentRecord-detail col-md-10 col-sm-12">
              <div>
                  <span>Payment received on <b>{moment(element?.paymentDate).format('LL')}</b></span><br></br>
                  <div className="method-note-container">
                    <i style={{    "fontSize": "12px"}}>Paid via <span className="payment-method">{element?.paymentMethod}</span></i>
                    {element?.paymentNote.trim() &&
                      <i className="fa fa-info-circle" ref={tooltipRef}></i>
                    } 

                  </div>
              
              </div>
              <div className="payed">
                    <span><b>{currency?.Symbol}{separateComma(Number(element?.amountPaid))}</b></span> 
              </div>

            </div>
        </div>
    </>
       
  )
}

export default SinglePaymentRecord