import React, { useEffect, useState } from 'react'
// import CustomLoader from '../components/CustomLoader'
import { Link, useNavigate } from 'react-router-dom'
// import AllInvoices from '../Invoice/AllInvoices';
import { useDeleteSingleInvoiceMutation, useDuplicateInvoiceMutation, useGetAllInvoicesQuery, 
  // useLazyGetAllInvoicesQuery
 } from '../store/apis/baseApi';
import Allquotation from './quotations/Allquotation';
import ReactPortal from '../components/reactPortal/ReactPortal';
import Modal from '../components/modal/Modal';
import { errorMessage, successMessage } from '../components/toastMessages/ToastifyMsg';
import CustomDeleteloader from '../components/CustomDeleteloader';
import SendEmail from '../components/SendEmail';
import axios from 'axios';
import { deepCopy } from '../utils/utils';
import { LARAVELAPI } from '../common/common';
import { useDispatch, useSelector } from 'react-redux';
import { setPlanDetails } from '../store/slices/subscribedPlanDetails';

function Quotations() {
  const LARAVELurl = LARAVELAPI;
  
  const quotationPlanDetail = useSelector(state=>state.planDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showInvoice, setShowInvoice] = useState(1);
  const { data, error,isLoading} = useGetAllInvoicesQuery();
  const [allInvoices, setAllInvoices] = useState([]);
  const [deleteSingleInvoice] = useDeleteSingleInvoiceMutation();
  const [loader,setLoader]= useState(false);
  const [deleteModalInvoice, setDeleteModalInvoice] = useState(false);
  const [isConfirmation,setIsConfirmation] = useState(false);
  const [emailModal,setEmailModal] =useState();
  const [deleteId,setDeleteId] =useState();
  const [emailTitle,setEmailTitle] = useState("");
  const [emailbuttonLoader,setEmailbuttonLoader] = useState(false);
  // const [getAllInvoices]  = useLazyGetAllInvoicesQuery();
  const [tableLoader,setTableLoader] = useState(true);


  const [duplicateInvoice] = useDuplicateInvoiceMutation(); 
  const [isConfirmDuplicate,setIsConfirmDuplicate] = useState(false);
  const [duplicateId,SetDuplicateId] = useState("");
  const [duplicateName,SetDuplicateName] = useState("");
  const  [duplicateLoader,setDuplicateLoader] = useState(false);

  // sendEmail Body
  const [emailBody,setEmailBody] = useState({
    id: "",
    email_to:"",
    email_body: "Kindly find the attached document.",
    attachment: "true",
    cc_status : "false",
    cc_emails: ""
  });

  useEffect(() => {
    setTimeout(() => {
      setTableLoader(isLoading);
    }, 500);
  }, [isLoading])

  useEffect(() => {
    // (async()=>{
      // setTableLoader(true);
      // const {data,error} = await getAllInvoices();
        try {
          if (data) {
            if (data.success) {
              let invoiceData = deepCopy(data.data);
              let invoices = invoiceData.map((data)=>{
                return ({...data, currency: JSON.parse(data.currency)})
              })
              let filtered = invoices.filter(invoice=>invoice.type ==="quotation")
             
              setAllInvoices(filtered|| []);
            }
            if (!data.success) {
                // console.log(data.message);
            }
            // setFilterInvoice(invoiceData.filter(invoice=> invoice.status === "outstanding") || []);
          }
          if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
          }
        } catch (error) {
        }
          
         

    // })()


  }, [data,error]);


  const confirmation = (id,name)=>{
    setIsConfirmation(true);
    // deleteInvoice(id);
    setDeleteModalInvoice(name);
    setDeleteId(id);
  }

  const handleOpenEmail = (title,invID,customerEmail)=>{
    if (quotationPlanDetail?.planDetails?.plan_usage_details?.email_sent?.usage_count<quotationPlanDetail?.planDetails?.plan_usage_details?.email_sent?.total) {
      setEmailModal(true);
      setEmailBody((prevState)=>{
        return{
          ...prevState,
          id: invID,
          email_to: customerEmail,
        }
      })
      setEmailTitle(title);
    }
    else errorMessage("Limit Reached. Upgrade your plan!");
  }

  const handleCloseEmail = ()=>{
      setEmailModal(false);
      setEmailBody((prevState)=>{
        return{
          ...prevState,
          id: "",
          email_to:"",
          cc_status: "false",
          cc_emails:""
        }
      })
  }

  const deleteInvoice = async(value)=>{
    if (value === true) {
      setIsConfirmation(false);
      setLoader(true);
      const {data,error} = await deleteSingleInvoice(deleteId);
        
      if (data) {
        
        if (data.success) {
              setLoader(false);
              successMessage(data.message );
        }
        if (!data.success) {
              setLoader(false);
              errorMessage(data?.message );
        }
          
      }
      if (error) {
        // console.log("invoice!deleted",error.data.message);
        errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
      }
    } else {
      setIsConfirmation(false);
    }

   

}

 // email 
 const emailChange = (e)=>{
  e.preventDefault();
    const {name,value} = e.target;
    if (name === "cc_status" & value === "false") {
      setEmailBody((prevState)=>{
        return({
          ...prevState, cc_emails:""
        })
      })
    }
    setEmailBody((prevState)=>{
      return({
        ...prevState, [name]:value
      })
    })
}
const increaseQuotationPlan = (type)=>{
  const newPlanDetails = JSON.parse(JSON.stringify(quotationPlanDetail?.planDetails))
  // const newPlanDetails = { ...quotationPlanDetail.planDetails };
  newPlanDetails.plan_usage_details[type].usage_count += 1
  dispatch(setPlanDetails(newPlanDetails));
}

const handleEmail= async(e)=>{
e.preventDefault();
setEmailbuttonLoader(true);

  let body = {
    id: emailBody?.id,
    email_to: emailBody?.email_to,
    email_body: emailBody?.email_body,
    attachment: emailBody?.attachment,
    cc_status: emailBody?.cc_status,
    cc_emails: emailBody?.cc_emails,
    type: "quotation"
  }
// https://stagingfront.tradingforms.com/api/invoice_email?id=59
// console.log(body);
let token =  localStorage.getItem("tradingformtoken");
const {data,error} =  await axios(`${LARAVELurl}invoice_email`, {
  method: "POST",
  data: body,
  headers: {
    "Content-Type": "application/json;",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
  },
})
  if (data) {
    if (data.success) {
      setEmailModal(false);
    successMessage(data?.message);
    setEmailbuttonLoader(false);
    increaseQuotationPlan("email_sent")
    setEmailBody((prevState)=>{
      return{
        ...prevState,
        id: "",
        email_to:"",
        attachment:"true",
        cc_status: "false",
        cc_emails:""
      }
    })
    }
    if (!data.success) {
      // setEmailModal(false);
      errorMessage(data?.message);
      setEmailbuttonLoader(false);
    }
  }
  if (error) {
    // console.log("error",error);
    errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
    setEmailbuttonLoader(false);
  }
  
}

const duplicateConfirmation = (id, name) => {
  if (quotationPlanDetail?.planDetails?.plan_usage_details?.quotation_invoice?.usage_count <quotationPlanDetail?.planDetails?.plan_usage_details?.quotation_invoice?.total) {
    setIsConfirmDuplicate(true);
    SetDuplicateName(name);
    SetDuplicateId(id);
  }else{
    errorMessage("Limit Reached. Upgrade your plan!");
  }

};


const createDuplicateSubmit = async (value) => {
    if (value === true) {
      if (quotationPlanDetail?.planDetails?.plan_usage_details?.quotation_invoice?.usage_count<quotationPlanDetail?.planDetails?.plan_usage_details?.quotation_invoice?.total) {
        try{
          setIsConfirmDuplicate(false);
          setDuplicateLoader(true);
            const {data,error} = await duplicateInvoice(duplicateId);
            if (data) {
              if (data.success) {
                successMessage(data.message);
                increaseQuotationPlan("quotation_invoice");
                SetDuplicateId("");
              }
              if (!data.success) {
                errorMessage(data.message);
              }
            }
            if (error) {
              errorMessage(error.data.message);
            }
  
        }
        catch{
        }
        finally{
          setDuplicateLoader(false);
        }
      }
      else{
        errorMessage("Limit Reached. Upgrade your plan!");
            navigate('/subscription-plans');
      }
    } 
    if(value === false) {
      setIsConfirmDuplicate(false);
      SetDuplicateId("");
      SetDuplicateName("");
    }
}

const handleCreateNavigate=() => {
  if (quotationPlanDetail?.planDetails?.plan_usage_details?.quotation_invoice?.usage_count<quotationPlanDetail?.planDetails?.plan_usage_details?.quotation_invoice?.total) {
    navigate('create');
  }else {
    errorMessage("Limit Reached. Upgrade your plan!");
    navigate('/subscription-plans');
  } 
}

  return (
    <>
    {loader ===true && <CustomDeleteloader message="Deleting . . ."/>}
    {duplicateLoader === true && <CustomDeleteloader message="Duplicating . . ." />}

        <div className="spacer"></div><div className="spacer"></div>
      <div className="invoice-container">
        <div className="container invoice-list" data-aos="zoom-in">
          <div className="invoice-actions">
            <div className="invoice-navBtn invoice-navBtn-custom">
              <button
                onClick={() => setShowInvoice(1)}
                className={`btn btn-secondary btn-sm ${showInvoice===1? 'active cursor-disable':''}` }
                type="button"
                title='View All Quotations'
              >
                All Quotations
              </button>

              {/* <button
                onClick={() => setShowInvoice(2)}
                className={`btn btn-secondary btn-sm ${showInvoice===2? 'active cursor-disable':''}` }
                type="button"
                >
                Outstanding
                </button>
                <button
                onClick={() => setShowInvoice(3)}
                className={`btn btn-secondary btn-sm ${showInvoice===3? 'active cursor-disable':''}` }
                type="button"
                >
                Paid
              </button> */}
            </div>
            <div className="searchCreate-invoice">
              <Link to='/email-history' state={{emailHistory: 'quotation' }} className="col-xs-12 btn btn-secondary btn-sm email-history" title="View sent emails history for Invoices & Quotations" ><i className="fa fa-history"></i>  <span>Email History</span> </Link>
              {/* <div className="invoice-searchContainer col-xs-12 col-sm-9 col-md-7">
                <input
                  type="text"
                  name=""
                  placeholder="Search By Client Name"
                ></input>
              </div> */}
              <div className="createInvoice col-xs-12 col-sm-3 col-md-5">
                <button className="btn btn-primary d-flex align-items-center justify-content-center" onClick={handleCreateNavigate} to="/quotation/create" title="Go to Create New Quotation">
                  <i className="fa fa-plus pe-2"></i> <span>Create Quotation</span>
                </button>
              </div>
            </div>
          </div>
          
{/* {
          !data && !error ? (
    <CustomLoader/>
  ) :   */}
  <div className="container px-0 fadeInOpacity overflow-hidden">
            {showInvoice === 1 && 
                  <Allquotation allInvoice={allInvoices}
                  deleteInvoice={confirmation}
                    handleOpenEmail={handleOpenEmail} 
                    duplicateInvoice={duplicateConfirmation}
                    isLoading={tableLoader}
              />}
           {/*  {showInvoice === 2 && <Outstanding allInvoice={allInvoices} deleteInvoice={confirmation}/>}
            {showInvoice === 3 && <Paid allInvoice={allInvoices} deleteInvoice={confirmation}/>} */}
            {/* <CustomLoader/> */}
          </div>
          {/* } */}
          {/* <div className="invoice-list-footer">
            <span>All your invoices are auto saved here</span>
          </div> */}
        </div>
      </div>

      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon={"trash-alt text-danger"}
            header="Photo Details"
            type="confirm"
            recordName={`Are you sure want to delete this Quotation# ${deleteModalInvoice}`}
          >
            <button className="btn btn-danger m-1 col-xs-12 col-sm-6 col-md-3" onClick={()=>deleteInvoice(true)}> Yes</button>
            <button className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3" onClick={()=>deleteInvoice(false)}>No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>

      <ReactPortal>
          { emailModal &&
                <Modal handleClose={handleCloseEmail} type="form" contentType="email-form" header={`Enter details to send mail for ${emailTitle}`}>
                    <SendEmail emailBody={emailBody} handleSubmit={handleEmail} emailChange={emailChange} loader={emailbuttonLoader}/>
                </Modal>
          }
      </ReactPortal>

      <ReactPortal>
        {isConfirmDuplicate && (
          <Modal
            header=""
            type="confirm"
            icon={"copy text-info"}
            recordName={`Are you sure want to duplicate Quotation# ${duplicateName}`}
          >
            <button
              className="btn btn-save m-1 col-xs-12 col-sm-6 col-md-3"
              onClick={() => createDuplicateSubmit(true)}
            >
              {" "}
              Yes
            </button>
            <button
              className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3"
              onClick={() => createDuplicateSubmit(false)}
            >
              No
            </button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>

    </>
  )
}

export default Quotations