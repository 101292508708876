import React, { useEffect, useState } from 'react'
import { useGetAllDeductionsQuery } from '../../store/apis/baseApi';
import CustombuttonSpinner from '../../components/CustombuttonSpinner';
import { deepCopy } from '../../utils/utils';

function AllDeduction({ deleteDeduction }) {
  const [alldeduction, setAlldeduction] = useState();
  const { data, error } = useGetAllDeductionsQuery();

  useEffect(() => {
    if (data) {
      if (data.success) {
        let alldeductionData = deepCopy(data.data);
        setAlldeduction(alldeductionData);
      }
      if (!data.success) {
        console.log(data.message);
        
      }
    }
  }, [data, error]);


  return (
    <>
      {!alldeduction ? (<CustombuttonSpinner />)
        :
        (<><div className="container">
          {alldeduction?.length > 0 &&
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Sr.#</th>
                  <th className="text-start">Deduction Title</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {alldeduction?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td className="text-start">{item.deduction_title}</td>
                      <td>
                        <span className="text-danger cursor-pointer" onClick={() => deleteDeduction(item.id, item.deduction_title)}
                        title={`Delete ${item.deduction_title}`}
                        >
                          <i className="fa fa-trash-alt"></i></span>
                      </td>
                    </tr>
                  )
                })

                }
              </tbody>
            </table>
          }
        </div>
        </>)


      }
    </>
  )
}

export default AllDeduction