import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useUserRegisterMutation } from "../store/apis/baseApi";
import logo from "../assets/images/first_without_background.webp"
import { errorMessage, successMessage } from "./toastMessages/ToastifyMsg";
import CustombuttonSpinner from "./CustombuttonSpinner";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FRONTSITE } from "../common/common";


function Signup() {
  const frontsiteUrl = FRONTSITE;

  const [userRegister] = useUserRegisterMutation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const [register, setRgister] = useState({
    name: "",
    email: "",
    password: "",
    type: "individual",
    phone: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    setRgister((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    // console.log("registered",register);
    // console.log(e.target.password.length, e.target.confirm_password.length)
    if (e.target.password.value.length >= 6 && e.target.confirm_password.value.length >= 6) {

      if (e.target.password.value === e.target.confirm_password.value) {
        let body = {
          name: register.name,
          email: register.email,
          password: register.password,
          type: register.type,
          phone: register.phone,
        }
        // console.log("body",body)
        try {
          let { data, error } = await userRegister(body);

          if (data) {
            // console.log("Registered", data);
            if (data.success) {
              successMessage(data.message);
              setLoader(false);
              navigate("/login");
            }
            if (!data.success) {
              errorMessage(data.message);
              setLoader(false);
            }

          }
          if (error) {
            // console.log("Error in register",error.data);
            setLoader(false);
            errorMessage(error?.error ?? error?.data?.message ?? error.data?.message ?? error.data.errors[0].msg);
          }
        } catch (error) {
          // console.log("catchError>>",error);
        }


      }
      else {
        errorMessage("Password doesn't match.")
        setTimeout(setLoader(false), 1000)

      }

    } else {
      errorMessage("Enter 6 or more characters in password fields.")
      setTimeout(setLoader(false), 1000)

    }



  };

  const password = (field) => {

    if (field === "id_password") {
      const togglePassword = document.querySelector("#togglePassword");
      const password = document.querySelector("#id_password"); const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
      // toggle the eye slash icon
      togglePassword.classList.toggle("fa-eye-slash");
    }
    if (field === "id_conpassword") {
      const togglePassword = document.querySelector("#toggleconPassword");
      const password = document.querySelector("#id_conpassword"); const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
      // toggle the eye slash icon
      togglePassword.classList.toggle("fa-eye-slash");
    }


    // toggle the type attribute

  };






  return (

    <>


      {/* ================================================= */}
      <section className="loginView--container registerContainer">
        <div className="loginView-wrapper">
          <div className="credentials-wrapper" data-aos="fade-right">
            {/* <div className="logoContainer">
                <img src={logo} alt="logo"></img>
            </div> */}
            <div className="corner-circle"></div>
            <div className="circle-wrapper"><div className="circle-in-circle"></div></div>
            <div className="upperleft-light-circle"></div>
            <div className="credentials-wrapper-inner">
              {/* <div className="logoContainer">
                <img src={logo} alt="logo"></img>
              </div> */}

              <div className="credentials-content">

                <div className="form-container">
                  <p className="title">SignUp</p>
                  <form className="form" onSubmit={submit}>
                    {/* ========Name */}


                    <div className="input-container px-0">
                      <input className="input" type="text"
                        name="name"
                        id="name"
                        required
                        autoComplete="off"
                        // autoFocus
                        onChange={(e) => handleChange(e)}
                        defaultValue={register?.name}></input>
                      <label className="label" htmlFor="name">Name <span className="text-danger">*</span></label>
                    </div>

                    {/*============ Email ==========*/}


                    <div className="input-container px-0">
                      <input className="input"
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        required
                        onChange={(e) => handleChange(e)}
                        defaultValue={register?.email}
                      ></input>
                      <label className="label" htmlFor="email">Email <span className="text-danger">*</span></label>
                    </div>

                    {/*=========== Phone ============*/}



                    <div className="input-container input-container-tele  px-0">
                      {/* <input className="input telephone"
                            type="text"
                          name="phone"
                          required
                          // mask="+1 (999) 999-9999"
                          onChange={(e) => handleChange(e)}
                          value={register?.phone}
                          maxLength="14"
                          autoComplete="off"
                          ></input> */}

<label className="custom-label" htmlFor="phone">Phone <span className="text-danger">*</span></label>

                      <PhoneInput
                        value={register?.phone}
                        country={'us'}
                        // placeholder="+1 503 3262362"
                        countryCodeEditable={false}
                        onChange={(value, country, e, formattedValue) => handleChange(e)}
                        inputProps={{
                          name: 'phone'
                        }}
                        autoComplete="off"
                      />

                    </div>

                    {/* ============type =============*/}


                    <div className="input-container input-container-tele px-0">
                      {/* <label className="" htmlFor="phone">User Type</label> */}
                      <label className="custom-label" htmlFor="type">User type <span className="text-danger">*</span></label>


                      <select className="input"
                        name="type"
                        id="type"
                        required
                        onChange={handleChange}
                        defaultValue={register?.type}
                        aria-label="type"
                      >
                        {/* <option value="">Select type </option> */}
                        <option value="individual">Individual (default) </option>
                        <option value="company">Company</option>
                      </select>
                    </div>

                    {/* ===========password ===============*/}
                    <div className="input-container register-icon px-0">
                      <input className="input"
                        type="password"
                        name="password"
                        required
                        onChange={(e) => handleChange(e)}
                        defaultValue={register.password}
                        id="id_password"
                        autoComplete="off"
                        // autocomplete="new-password"
                        data-lpignore
                      ></input>
                      <label className="label" htmlFor="id_password">Password <span><span className="text-danger">*</span>
                      </span>
                      </label>
                      <i
                        className="far fa-eye"
                        onClick={() => password("id_password")}
                        id="togglePassword"
                      ></i>
                    </div>


                    {/* ===========confirm_password ============= */}

                    <div className="input-container register-icon px-0">
                      <input className="input"
                        type="password"
                        name="confirm_password"
                        id="id_conpassword"
                        required
                        autoComplete="off"
                        // autocomplete="new-password"
                        data-lpignore
                        onChange={(e) => handleChange(e)}
                      ></input>
                      {/* <span title="Password must contain atleast 6 characters."><i className="fa fa-info-circle"></i></span> */}
                      {/* <small className="text-danger"><sub>(Password must contain atleast 6 characters.)</sub></small> */}
                      <label className="label" htmlFor="id_conpassword">Confirm Password <span><span className="text-danger">*</span></span></label>
                      <i
                        className="far fa-eye"
                        onClick={() => password("id_conpassword")}
                        id="toggleconPassword"
                      ></i>
                    </div>

                    {/* =================================== */}
                    <div className="policyContainer">
                      <input type="checkbox" name="policy" defaultValue="false" id="policy" required></input>
                      <label htmlFor="policy">I agree with <Link to={`${frontsiteUrl}terms-of-services`} target="_Blank"> terms & conditions</Link> and <Link to={`${frontsiteUrl}privacy-policy`} target="_Blank">privacy</Link> policy.</label>
                    </div>


                    <button type="submit" className={`sign ${register.name !== "" && register.email !== "" && register.password !== "" && register.type !== "" && register.phone.length > 14 && register.confirm_password !== "" ? '' : 'cursor-disable'}`} disabled={register.name !== "" && register.email !== "" && register.password !== "" && register.type !== "" && register.phone.length > 14 && register.confirm_password !== "" ? false : true}>
                      Register
                      {loader &&
                        <span className="spinner-container">

                          <CustombuttonSpinner />
                        </span>
                      }
                    </button>
                  </form>

                  <p className="signup mt-2">
                    <span>Already have an account&nbsp; ? </span>
                    <Link to="/login" href="#" className="">
                      {" "}
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="signUp-content">
            <div className="signUp-content-inner" data-aos="fade-left">
              <div className="logoContainer">
                <img src={logo} alt="logo"></img>
            </div>
              <h2 className="title">Already have a <span className="pos-rel">TradingForms</span> <br></br> account? </h2>
              <div className="sign-up-cta"><Link to="/login">Sign in</Link></div></div>
          </div>
        </div>
        <div className="overlay"></div>
      </section>


    </>


    // RegisterAs (Company/Individual)

    // "name": "nofel",
    // "email": "nofel@gmail.com",
    // "email_verified_at": null,
    // "password": "$2b$10$SlcG7S2aE9.xssflb82LDeZEPleChQNvxAgTdUmtd2zCXfP1rQTrC",
    // "type": "company",
    // "status": "1",
    // "phone": "9809327438",
  );
}

export default Signup;
