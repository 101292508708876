import React, { useState } from 'react'
import CustombuttonSpinner from '../../components/CustombuttonSpinner';
import { useSelector } from 'react-redux';

function SinglePlan({ handleSubscribePlan, plan, subscribing, subscribedPlan,handleClickOpen}) {
    const [submitingPlan,setSubmittingPlan] = useState("");
    const subscribedPlanDetails = useSelector((state)=>state.planDetails?.planDetails);
    
    const handleSubmit = (plan)=>{
        if (!subscribing && subscribedPlan) {
            handleClickOpen(plan);
            setSubmittingPlan(plan);
        }
    }

    return (
        <div className="col-lg-4 mb-5 mb-lg-0 py-2">
            <div className={`bg-white px-4 py-4 rounded-lg shadow plan-container ${ subscribedPlan?.packagessubcribed?.local_price_id && subscribedPlanDetails.status === plan?.local_price_id&& subscribedPlanDetails.cod!==403?'plan-activated-border':''}`}>
                <h1 className="h6 text-uppercase font-weight-bold mb-2">{plan?.package_title}</h1>
                <h2 className="h1 font-weight-bold">${plan?.package_price}<span className="text-small font-weight-normal ml-2">/ {plan?.package_duration===30 ?'monthly':'yearly'}</span></h2>

                <div className={`custom-price-separator my-4 mx-auto ${subscribedPlan?.packagessubcribed?.local_price_id === plan?.local_price_id&&subscribedPlanDetails.code!==403 ?'activated':''}`}></div>
                <p className=' text-start'>{plan?.package_description}</p>
                <p className='mb-2 text-start font-weight-bold'>{plan?.package_title} have:</p>
                <ul className="list-unstyled mb-4 text-small text-start">
                    {plan?.packagesfeatures.map((feature,index)=>
                        <li className="mb-3 d-flex" key={index}>
                            <i className={`fa fa-check me-3 mt-1 ${subscribedPlan?.packagessubcribed?.local_price_id === plan?.local_price_id&& subscribedPlanDetails.code!==403?'activated':''}`}></i> <span> {feature.package_feature}</span>
                        </li>
                        
                    )}

                    {/*
                    <li className="mb-3 text-muted">
                        <i className="fa fa-times me-2"></i>
                        <del> Sed ut perspiciatis</del>
                    </li> */}
                </ul>
                {subscribing && submitingPlan=== plan?.local_price_id ?
                    <button disabled={subscribing} className="btn btn-save btn-block p-2 shadow rounded-pill position-relative btn-subscribe">
                        <CustombuttonSpinner/>
                    </button>:subscribedPlan?.packagessubcribed?.local_price_id===plan?.local_price_id && subscribedPlan?.subscription_type.toLowerCase() !=='demo' && subscribedPlanDetails.status
                    ?
                    <button type='button' disabled={subscribedPlan}  className={`btn p-2 position-relative btn-subscribe activated cursor-disable`}> 
                        Subscribed 
                    </button>:subscribedPlan?.packagessubcribed?.local_price_id===plan?.local_price_id && subscribedPlanDetails.code===403?<button type='button' onClick={() => handleSubmit(plan?.local_price_id)} className={`btn p-2 shadow position-relative btn-subscribe ${subscribedPlan?.packagessubcribed?.local_price_id === plan?.local_price_id&&subscribedPlanDetails.status?'btn-success cursor-disable':'btn-save'}`}> 
                            {/* Resubscribe */} Subscribe Again
                    </button>:
                    <button type='button' title={`Subscribe to ${plan?.package_title} plan`} onClick={() => handleSubmit(plan?.local_price_id)} className={`btn p-2 shadow position-relative btn-subscribe ${subscribedPlan?.packagessubcribed?.local_price_id === plan?.local_price_id&&subscribedPlanDetails.status?'btn-success cursor-disable':'btn-save'}`}> 
                        Subscribe 
                    </button>
                }
                {subscribedPlan?.packagessubcribed?.local_price_id===plan?.local_price_id && subscribedPlan?.subscription_type.toLowerCase() !=='demo' && subscribedPlanDetails.status &&
                    <div className="plan-activated-tag"><span>Activated</span></div>
                }
                {subscribedPlan?.packagessubcribed?.local_price_id===plan?.local_price_id && subscribedPlanDetails.code===403 &&

                    <div className="plan-activated-tag"><span>Expired</span></div>
                }
            </div>
        </div>
    )
}

export default SinglePlan