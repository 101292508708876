import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom';
import { dataTablecustomStyles, deepCopy, separateComma } from '../utils/utils';
// import CustombuttonSpinner from '../components/CustombuttonSpinner';
import TableLoader from '../components/loaders/TableLoader';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';



function Paid({allInvoice, deleteInvoice,handleOpenEmail,duplicateInvoice,isLoading}) {
    // console.log('Paid',allInvoice);
    const [allInvoices, setAllInvoices] = useState([]);
    const [search, setSearch] = useState("");
    const [filterInvoice, setFilterInvoice] = useState([]);
    const navigate = useNavigate();





  useEffect(() => {
    
      let invoiceData = deepCopy(allInvoice);
      // invoiceData = {}
      setAllInvoices(invoiceData.filter(invoice=> invoice.status === "paid") || []);
      setFilterInvoice(invoiceData.filter(invoice=> invoice.status === "paid") || []);
      
    
  }, [allInvoice]);

  // console.log(allInvoice[0])


  const columns = [
    {
      name: "Invoice #",
      selector: (row) => row.invoiceNumber,
      sortable: true,
      //  width: '125px'
    },
    {
      name: "From",
      selector: (row) => row.senderName,
      // sortable: true,
    },
    {
      name: "Consumers",
      selector: (row) => row.customerName,
    },
    {
      name: "Date",
      selector: (row) => moment(row.invoicedate).format("ll"),
      sortable: true,
    },
    {
      name: "Total Amount",
      // ${row.currency.Symbol} {row.totalPaid}
      selector: (row) => ( <div className="w-100 text-start">  <div className="table-balanceValue">{row.currency.Symbol}{separateComma(row.totalPaid)}</div> <small className="text-success"><b> PAID</b></small> </div>),
      // width: '125px'
      right:false,
    },
    {
      // name: "",
      cell: (row) => (
        <div className="d-flex gap-1" title="Select an action">
         
            <DropdownButton
              as={ButtonGroup}
              key={'start'}
              id={`dropdown-button-drop-${row.id}`}
              drop={'start'}
              variant="dropdown-menu-custom"
              title={<i className="fas fa-ellipsis-v"></i> }
              // size="sm"
            >
              <Dropdown.Item eventKey="1">
                    <div
                    className="d-flex gap-2 align-items-center listing-button-edits"
                    onClick={() => {
                      navigate(`/invoice/edit/${window.btoa(`${row.id}`)}`);
                    }} 
                    title='View Invoice'
                  >
                    <i className="fa fa-eye"></i> View
                  </div>
              </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <div 
                className="d-flex gap-2 align-items-center listing-button-edits"
                onClick={() => {
                  navigate(`/invoice/edit/${window.btoa(`${row.id}`)}`);
                }}
                title="Edit Invoice"
              >
                <i className="fa fa-edit"></i> Edit
              </div>
            </Dropdown.Item>

              <Dropdown.Item eventKey="3">
                <div
                  className="d-flex gap-2 align-items-center listing-button-edits"
                  onClick={() => deleteInvoice(row.id,row.invoiceNumber)}
                  title={`Delete ${row.invoiceNumber}`}
                >
                  <i className="fa fa-trash-alt text-danger"></i> Delete
              </div>
              </Dropdown.Item>

              {/* <Dropdown.Divider /> */}

              <Dropdown.Item eventKey="4">
                <div onClick={()=>handleOpenEmail(row.invoiceNumber,row.id,row.customerEmail)} className="d-flex gap-2 align-items-center listing-button-edits"
                title="e-mail Invoice"
                >
                    <i className="fa fa-mail-bulk"></i> Email  Invoice
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="5">
                <div onClick={() => duplicateInvoice(row.id,row.invoiceNumber)}  className="d-flex gap-2 align-items-center listing-button-edits" title={`Duplicate ${row.invoiceNumber}`}>
                    <i className="fa fa-copy"></i> Duplicate 
                </div>
              </Dropdown.Item>

            </DropdownButton>
        </div>
      ),
      width: '60px',
      minWidth: '60px',
      right: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    const result = allInvoices.filter((invoice) => {
      return invoice.customerName.toLowerCase().match(search.toLowerCase());
    });
    setFilterInvoice(result);
  }, [search, allInvoices]);

  return (
    <>
        <div className="container px-0 fadeInOpacity">
        {/* <div className="card p-2"> */}
            


           
          {/* 
            title={"All Invoices"} 
            fixedHeader
            fixedHeaderScrollHeight="400px"
          */}
          <div className="py-2 overflow-hidden">
            {isLoading ? (<TableLoader/>)
            :
            (
              <div className="table-wrapper" data-aos="fade-left">
                <DataTable
                  columns={columns}
                  data={filterInvoice}
                  fixedHeader
                  fixedHeaderScrollHeight="400px"
                  className="table-scroll-custom"
                  highlightOnHover
                  pointerOnHover  
                  responsive
                  subHeader
                  subHeaderComponent={
                    <div className="col-md-4 col-12">
                      <div className="table-header-custom">
                        <label className="col-md-3 col-12 text-start" htmlFor="search">Search: </label>
                        <div className="col-md-9 col-12">
                          <input
                            type="search"
                            placeholder="Search By Consumer"
                            className="form-control"
                            value={search}
                            autoComplete="off"
                            // style={{ width: "28%" }}
                            onChange={(e) => setSearch(e.target.value)}
                            name="search"
                            id="search"
                          ></input>
                        </div>
                      </div>
                    </div>
                  }
                  // subHeaderAlign="left"
                  onRowClicked={(row,event)=>{ navigate(`/invoice/edit/${window.btoa(`${row.id}`)}`);}}
                  pagination
                  sortIcon={<i className="fa fa-angle-down"></i>}
                  customStyles={dataTablecustomStyles}
                />

              </div>
            )
            }
            </div>
        
        {/* </div> */}
       </div>

    </>
  )
}

export default Paid