import React, { useEffect } from 'react'
import './Modal.css'
import ModalConfirmation from './ModalConfirmation';
import ModalContainer from './ModalContainer';
import ModalImages from './ModalImages';


function Modal({children,  handleClose , header, type,recordName, icon,contentType}) {
    // console.log('Modal');

    useEffect(()=>{
        const closeOnEscapeKey = e => {
            // console.log("KEY",e.key)
            return e.key === "Escape" ? (type!=="confirm" &&handleClose()) : null
        };
        window.addEventListener("keydown",closeOnEscapeKey);

        return ()=>{
            // console.log('unmount')
            window.removeEventListener("keydown",closeOnEscapeKey);
        };
    },[handleClose]);

    useEffect(() => {
     document.body.style.overflow="hidden";
     document.body.style.paddingRight="12px";

    
      return () => {
        document.body.style.overflow="";
     document.body.style.paddingRight="0";

      }
    }, [])

    return (
    <>  
        {/* { type !== "template" &&

            <span>Press Esc. to close modal</span>
        } */}
        <div className="modal " data-aos="zoom-in">
            { type === "form" &&
                <ModalContainer handleClose={handleClose} header={header} children={children} contentType={contentType}/>
            }
            { type === "images" &&
                    <ModalImages handleClose={handleClose} children={children}/>
            }   
            { type === "confirm" &&
                    <ModalConfirmation handleClose={handleClose} icon={icon} children={children} recordName={recordName}/>
            }
            { type === "template" &&
                    <ModalContainer header={header} children={children} contentType={contentType}/> 
            }   


            {/* <div className="modal-container">
                <div className="modal-Header">
                    <h4>{header}</h4>
                    <button onClick={handleClose} className="btn close-btn"><i className="fa fa-times"></i></button>
                </div>
                <div className="modal-body">{children}</div>
            </div> */}
            { type === "contract" &&
                    <div className='custom-contract-modal'> {children}</div>
            }
        </div>
        
    </>
  )
}

export default Modal;