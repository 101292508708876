import React, { useEffect, useState } from "react";
import SinglePaymentRecord from "./SinglePaymentRecord";
import uuid from "react-uuid";
import { currentData, separateComma } from "../../utils/utils";

function InvoicePaymentRecord({
  formData,
  handleDeletePayment,
  handleAddPayment,
  handleModalClose,
  payments
}) {

  const [amount, setAmount] = useState(false);



  const [paymentRecord, setPaymentRecord] = useState({
    id: uuid(),
    amountPaid: "",
    paymentDate: '',
    paymentMethod: "cash",
    paymentNote: "",
    state: "new",
  });

  useEffect(() => {
    let date = currentData();
    setPaymentRecord((prevState) => {
      return {
        ...prevState,
        paymentDate: date,
      }
    })
  }, [])
  
  //   const [total,setTotal] = useState(0);
  // useEffect(()=>{
  //   let date = new Date(paymentRecord?.paymentDate);
  //   var payDate = new Date(
  //     date.getTime() - date.getTimezoneOffset() * 60000
  //   )
  //     .toISOString()
  //     .split("T")[0];

  //     console.log(payDate);
  //     setPaymentRecord((prevState)=>{
  //       return({
  //         ...prevState, paymentDate: payDate,
  //       })
  //     })
  // },[paymentRecord?.paymentDate])


  // handleAddPayment();
  let total = 0;
  const handleOnChange = (e) => {
    e.preventDefault();
    setPaymentRecord((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (
      paymentRecord.amountPaid.trim() !== "" &&
      paymentRecord.paymentDate !== "" &&
      paymentRecord.paymentMethod.trim() !== "none" &&
      paymentRecord.amountPaid.trim() <= formData?.balanceDue
    ) {
      e.target.reset();
      handleAddPayment(paymentRecord);
      setPaymentRecord({
        id: uuid(),
        amountPaid: "",
        paymentDate: currentData(),
        paymentMethod: "cash",
        paymentNote: "",
        state: "new",
      });
    } else if (paymentRecord.amountPaid.trim() > formData?.balanceDue) {
      // errorMessage("Amount must be equal to or less than Balance amount.")
      setAmount(true);
      setTimeout(() => {
        setAmount(false)
      }, 5000);
    }

  }

  payments.map((payment) => {
    return (total = total + parseFloat(payment?.amountPaid));
  });


  return (
    <>
      <div className="paymentrecord">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-12">
              <div className="formField">
                <label className="col-sm-2 col-md-4 col-12" htmlFor="amountPaid">
                  Amount ({formData?.currency?.Code})
                </label>
                <div className="col-sm-10 col-md-8 col-12">
                  <input
                    className={`form-control ${amount ? 'shake' : ''}`}
                    type="number"
                    name="amountPaid"
                    id="amountPaid"
                    defaultValue={paymentRecord?.amountPaid}
                    onChange={(e) => handleOnChange(e)}
                    required
                    placeholder="0.00"
                    min={0.0}
                    // max={formData?.balanceDue}
                    step="any"
                  ></input>
                </div>
              </div>

            </div>
            <div className="col-sm-12 col-md-6 col-12">
              <div className="formField">
                <label className="col-sm-2 col-md-2 col-12" htmlFor="paymentDate">
                  Date
                </label>
                <div className="col-sm-10 col-md-10 col-12">
                  <input
                    className="form-control"
                    type="date"
                    name="paymentDate"
                    id="paymentDate"
                    defaultValue={paymentRecord?.paymentDate}
                    onChange={(e) => handleOnChange(e)}
                    required
                    placeholder="0.00"
                  ></input>
                </div>
              </div>
            </div>
          </div>
          {amount &&
            <span className="text-danger">Amount must be equal to or less than Balance amount.</span>
          }
          <div className="col-12">
            <div className="formField">
              <label className="col-sm-2 col-md-2 col-12" htmlFor="paymentMethod">
                Method
              </label>
              <div className="col-sm-10 col-md-10 col-12">
                <select
                  className="form-select"
                  name="paymentMethod"
                  id="paymentMethod"
                  defaultValue={paymentRecord?.paymentMethod}
                  onChange={(e) => handleOnChange(e)}
                  required
                  aria-label="paymentMethod"
                >
                  <option value="cash">Cash</option>
                  <option value="cheque">Cheque</option>
                  <option value="credit Card">Credit Card</option>
                  <option value="bank transfer">Bank Transfer</option>
                  <option value="PayPal">Paypal</option>
                  <option value="others">Others</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="formField">
              <label htmlFor="paymentNote" className="col-sm-2 col-md-2 col-12">
                Notes
              </label>
              <div className="col-sm-10 col-md-10 col-12">
                <textarea
                  rows="3"
                  cols=""
                  name="paymentNote"
                  id="paymentNote"
                  defaultValue={paymentRecord?.paymentNote}
                  onChange={(e) => handleOnChange(e)}
                  className="form-control"
                  placeholder="Optional payment note (optional)"
                ></textarea>
              </div>
            </div>
            <div className="payment-form-submit">
              <button
                type="submit"
                className={`btn btn-save text-light m-0 ${paymentRecord?.amountPaid !== "" && paymentRecord?.paymentDate !== "" && paymentRecord?.paymentMethod !== "" && formData?.balanceDue !== 0 ? "" : "cursor-disable"}`}
                disabled={paymentRecord?.amountPaid !== "" && paymentRecord?.paymentDate !== "" && paymentRecord?.paymentMethod !== "" && paymentRecord?.paymentMethod !== "none" && formData?.balanceDue !== 0 ? false : true}
              >
                Save Payment
              </button>
              <button
                type="button"
                onClick={handleModalClose}
                className="btn"> Close
              </button>
            </div>
          </div>
        </form>
        <div className="col-12">
          <div className="paymentContainer">
            <div className="col-12 ">
              <div className="paymentContainerInner">
                <div className="paymentContainer">
                  <span className="paymentContainerLabel"> Payment History</span>
                </div>
                <div className="dueBalanceContainer">
                  <span className={`dueBalanceLabel text-danger ${amount ? 'shake' : ''}`}>Balance:</span><span> {formData?.currency.Symbol}{separateComma(Number(formData?.balanceDue))}</span>
                </div>
              </div>

            </div>
            <div className="col-12">
              <div className="singlePaymentRecordContainer">
               
                {payments?.map((element, index) => {
                  return (
                    <SinglePaymentRecord
                      key={index}
                      handleDeletePayment={handleDeletePayment}
                      currency={formData?.currency}
                      element={element}
                    />
                  );
                })}
              </div>

              <div className="totalPayed">
                <span> Total Paid:</span>
                <span>{formData?.currency?.Symbol}</span>
                <span>{separateComma(Number(total))}</span>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}

export default InvoicePaymentRecord;
