import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

function PersonalDetails({ 
  formData,
  handleBlur, 
  handleTeleFields,
  handleFileInputChange, 
  logoImage, 
  removeImage,
  fillFields,
  autoSuggstion,
  suggestList,
  showSuggest,
  setShowSuggest,
  readonlyFrom,
  toName,toEmail,fromName,fromEmail
}) {
  const [toggleSender, setToggleSender] = useState(false);
  const [toogleTo, setToogleTo] = useState(false);
  // const handleBlur = (e)=>{
  //   e.preventDefault();
  //   // setFormData();
  // }
  // console.log("terms?",formData?.term);
  // console.log("readonlyFrom>>",readonlyFrom);
  return (
    <>
      <div className="PersonalDetails fadeInOpacity">
        <div className="formStepOne">
          <div className="col-12">
            <div className="invoiceHeader container">
              <div className="formField my-1">
                <input
                  type=""
                  name="title"
                  className="form-control"
                  onChange={(e) => handleBlur(e)}
                  defaultValue={formData?.title}
                  autoComplete="off"
                  readOnly={readonlyFrom}
                  disabled={readonlyFrom}
                ></input>
              </div>
              <div className="invoiceLogo my-1">
                {/* <input type="file" accept=".png,.jpge" name="invoiceLogo" onChange={(e)=>handleBlur(e)} ></input>
                          <div className="logoNote">Add Logo</div> */}
                <div className="logoContainer form-control">
                { logoImage === null &&
                          <div className="logolabel">
                                <i className="fas fa-image"></i>
                                <span> + Logo</span>
                          </div>
                        }
                        <input type="file" className={`form-control ${readonlyFrom?'cursor-disable':''}`} accept=".png,.jpeg,.jpg,.webp" name="logo" defaultValue={''} disabled={readonlyFrom}  readOnly={readonlyFrom} onChange={handleFileInputChange}></input>
                        <span className="showlabel">  
                          {readonlyFrom !== true && (
                              logoImage === null ? ' Add ' : ' Update '   
                          )}  
                            your logo</span>
                          {logoImage !== null && !readonlyFrom &&
                            <span className="removeImage" onClick={removeImage}><i className="fa fa-times"></i></span>
                            }
                            {/* <div className="overlay"></div> */}
                        { logoImage !== null &&
                        <img src={logoImage} className="logoImage" alt="logo" height={130} width={200}/>
                    }
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-12">
            <div className="fromPersonalDetails">
              <h6 className="fw-bold">From</h6>
              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="from_name">
                  Name<span className="text-danger">*</span>
                </label>
                <div className="col-sm-9 col-12">
                  <input
                    className={`form-control ${fromName?  'shake':''}`}
                    type="text"
                    name="from_name"
                    onChange={(e) => handleBlur(e)}
                    onBlur={(e) => handleBlur(e)}
                    defaultValue={formData?.from_name}
                    readOnly={readonlyFrom}
                    disabled={readonlyFrom}
                    placeholder="Tara Doyle"
                  ></input>
                </div>
              </div>

              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="from_email">
                  Email<span className="text-danger">*</span>
                </label>
                <div className="col-sm-9 col-12">
                  <input
                    className={`form-control ${fromEmail?  'shake':''}`}
                    type="email"
                    name="from_email"
                    onChange={(e) => handleBlur(e)}
                    onBlur={(e) => handleBlur(e)}
                    defaultValue={formData?.from_email}
                    readOnly={readonlyFrom}
                    disabled={readonlyFrom}
                    placeholder="abc@gmail.com"
                  ></input>
                </div>
              </div>
              <div
                className={`showMoreDetails  ${
                  toggleSender === false ? "borderUp" : "borderDown"
                } `}
                onClick={() => setToggleSender(!toggleSender)}
              >
                <span className="btnDetail">Show {toggleSender? "Less":"More"} Details</span>
                <span>
                  {toggleSender === true ? (
                    <i className="fas fa-chevron-up"></i>
                  ) : (
                    <i className="fa fa-chevron-down"></i>
                  )}
                </span>
              </div>
              {toggleSender === true && (
                <div className="showMoreDetailsContainer fadeInOpacity">
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="from_website">
                     Website
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="url"
                        name="from_website"
                        placeholder="https://www.abc.com"
                        onChange={(e) => handleBlur(e)}
                        defaultValue={formData?.from_website}
                        readOnly={readonlyFrom}
                        disabled={readonlyFrom}
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="from_gst">
                      GST#
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="from_gst"
                        // placeholder="123-45-6789"
                        onChange={(e) => handleBlur(e)}
                        defaultValue={formData?.from_gst}
                        readOnly={readonlyFrom}
                        disabled={readonlyFrom}
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="from_ntn">
                      NTN#
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="from_ntn"
                        onChange={(e) => handleBlur(e)}
                        defaultValue={formData?.from_ntn}
                        readOnly={readonlyFrom}
                        disabled={readonlyFrom}
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField align-items-start">
                    <label className="col-sm-3 col-12" htmlFor="senderAddress">
                      Address
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        type="text"
                        className="form-control"
                        name="from_address1"
                        onChange={(e) => handleBlur(e)}
                        placeholder="Street"
                        defaultValue={formData?.from_address1}
                        readOnly={readonlyFrom}
                        disabled={readonlyFrom}
                      ></input>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="from_address2"
                        onChange={(e) => handleBlur(e)}
                        placeholder="City,State"
                        defaultValue={formData?.from_address2}
                        readOnly={readonlyFrom}
                        disabled={readonlyFrom}
                      ></input>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="from_zip_code"
                        onChange={(e) => handleBlur(e)}
                        placeholder="ZipCode"
                        defaultValue={formData?.from_zip_code}
                        readOnly={readonlyFrom}
                        disabled={readonlyFrom}
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="from_phone">
                      Business Number
                    </label>
                    <div className="col-sm-9 col-12">
                      {/* <input
                        className="col-8 form-control"
                        type="text"
                        name="from_phone"
                        maxLength="14" placeholder="(123) 456-7890"
                        onChange={(e) => handleBlur(e)}
                        value={formData?.from_phone}
                        readOnly={readonlyFrom}
                      ></input> */}
                      <PhoneInput
                        value={formData.from_phone}
                        country={'us'}
                        onChange={(value, country, e, formattedValue) => handleBlur(e)}
                        countryCodeEditable={false}
                        inputProps={{
                          name: 'from_phone'
                        }}
                        disabled={readonlyFrom}
                        // enableSearch={true}
                      />
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="from_fax">
                      Fax
                    </label>
                    <div className="col-sm-9 col-12">
                      {/* <input
                        className="form-control"
                        type="text"
                        name="from_fax"
                        maxLength="14" placeholder="(123) 456-7890"
                        onChange={(e) => handleBlur(e)}
                        value={formData?.from_fax}
                        readOnly={readonlyFrom}
                      ></input> */}
                      <PhoneInput
                        value={formData.from_fax}
                        country={'us'}
                        onChange={(value, country, e, formattedValue) => handleBlur(e)}
                        countryCodeEditable={false}
                        inputProps={{
                          name: 'from_fax'
                        }}
                        // enableSearch={true}
                        disabled={readonlyFrom}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-12">
            <div className="toPersonalDetails">
              <h6 className="fw-bold">To</h6>
              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="to_employee_name">
                  Name<span className="text-danger">*</span>
                </label>
                <div className="w-100 nameSuggest">
                  <input
                    className={`form-control ${toName?  'shake':''}`}
                    type="text"
                    name="to_employee_name"
                    onChange={(e) => handleBlur(e)}
                    value={formData?.to_employee_name}
                    onKeyUp={(e)=>autoSuggstion(e)}
                    onBlur={()=> {
                      setTimeout(() => {
                        setShowSuggest(false)
                      }, 200)
                    }}
                    placeholder="Nadine Lynch"
                    autoComplete="off"
                  ></input>{" "}
                  {/* value="" */}
                  {showSuggest && (
                    <div className="autosuggestDropDownContainer">
                      <div className="autosuggestDropDown">
                        {
                        <ul>
                        {suggestList.length ? ( 
                          suggestList?.map((list, index)=>{
                            return (
                              <li key={index} onClick={()=>fillFields(list.id)}> 
                                {list.entity_name}
                              </li>
                            )
                        })
                        ):(
                          <li className="text-danger">No Result Found !</li>
                          )
                        }
                    </ul>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="to_employee_email">
                  Email<span className="text-danger">*</span>
                </label>
                <div className="col-sm-9 col-12">
                  <input
                    className={`form-control ${toEmail?  'shake':''}`}
                    type="email"
                    name="to_employee_email"
                    onChange={(e) => handleBlur(e)}
                    value={formData?.to_employee_email}
                    autoComplete="off"
                    placeholder="nadine@gmail.com"
                  ></input>{" "}
                  {/* value="" */}
                </div>
              </div>
              <div
                className={`showMoreDetails  ${
                  toogleTo === false ? "borderUp" : "borderDown"
                } `}
                onClick={() => setToogleTo(!toogleTo)}
              >
                <span className="btnDetail">Show {toogleTo? "Less":"More"} More Details</span>
                <span>
                  {toogleTo === true ? (
                    <i className="fas fa-chevron-up"></i>
                  ) : (
                    <i className="fa fa-chevron-down"></i>
                  )}
                </span>
              </div>
              {toogleTo === true && (
                <div className="showMoreDetailsContainer fadeInOpacity">
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="to_employee_website">
                      Website
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="url"
                        name="to_employee_website"
                        placeholder="https://example.com"
                        onChange={(e) => handleBlur(e)}
                        value={formData?.to_employee_website}
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="to_employee_gst">
                      GST#
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="to_employee_gst"
                        // placeholder="123-45-6789"
                        onChange={(e) => handleBlur(e)}
                        value={formData?.to_employee_gst}
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="to_employee_ntn">
                      NTN#
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        className="form-control"
                        type="text"
                        name="to_employee_ntn"
                        // placeholder="123-45-6789"
                        onChange={(e) => handleBlur(e)}
                        value={formData?.to_employee_ntn}
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField align-items-start">
                    <label className="col-sm-3 col-12" htmlFor="customerAddress">
                      Address
                    </label>
                    <div className="col-sm-9 col-12">
                      <input
                        type="text"
                        className="form-control"
                        name="to_employee_address1"
                        onChange={(e) => handleBlur(e)}
                        placeholder="Street"
                        value={formData?.to_employee_address1}
                      ></input>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="to_employee_address2"
                        onChange={(e) => handleBlur(e)}
                        placeholder="City,State"
                        value={formData?.to_employee_address2}
                      ></input>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="to_employee_zip_code"
                        onChange={(e) => handleBlur(e)}
                        placeholder="ZipCode"
                        value={formData?.to_employee_zip_code}
                      ></input>{" "}
                      {/* value="" */}
                    </div>
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="to_employee_phone">
                      Phone
                    </label>
                    {/* <input
                      className="form-control"
                      type="text"
                      name="to_employee_phone"
                      onChange={(e) => handleBlur(e)}
                      maxLength="14" placeholder="(123) 456-7890"
                      value={formData?.to_employee_phone}
                    ></input> */}
                    <PhoneInput
                        value={formData.to_employee_phone}
                        country={'us'}
                        onChange={(value, country, e, formattedValue) => handleBlur(e)}
                        countryCodeEditable={false}
                        inputProps={{
                          name: 'to_employee_phone'
                        }}
                        // enableSearch={true}
                      />
                  </div>
                  <div className="formField">
                    <label className="col-sm-3 col-12" htmlFor="to_employee_phone">
                      Fax
                    </label>
                    {/* <input
                      className="form-control"
                      type="text"
                      name="to_employee_fax"
                      onChange={(e) => handleBlur(e)}
                      maxLength="14" placeholder="(123) 456-7890"
                      value={formData?.to_employee_fax}
                    ></input> */}
                    <PhoneInput
                        value={formData.to_employee_fax}
                        country={'us'}
                        onChange={(value, country, e, formattedValue) => handleBlur(e)}
                        countryCodeEditable={false}
                        inputProps={{
                          name: 'to_employee_fax'
                        }}
                        // enableSearch={true}
                      />
                  </div>
                  
                </div>
              )}
            </div>
          </div>
          <hr className="col-12"></hr>
          <div className="InvoiceInfo col-sm-12 col-md-12 col-lg-6 col-12">
            <div className="formField position-relative">
              <label className="col-sm-3 col-12" htmlFor="receipt_number">
                Payslip#
              </label>
              <div className="col-sm-9 col-12">
                <input
                  className="form-control cursor-disable"
                  type="text"
                  name="receipt_number"
                  placeholder="INV0001"
                  onChange={(e) => handleBlur(e)}
                  defaultValue={formData?.receipt_number}
                  readOnly
                ></input><span className="prefixInfo" title="Payslip# Prefix can be changed from settings."><i className="fa fa-info-circle"></i></span>{" "}
              </div>
              {/* value="" */}
            </div>
            <div className="formField">
              <label className="col-sm-3 col-12" htmlFor="salary_date">
                Date <span className="text-danger">*</span>
              </label>
              <div className="col-sm-9 col-12">
                <input
                  className="form-control"
                  type="date"
                  name="salary_date"
                  onChange={(e) => handleBlur(e)}
                  defaultValue={formData?.salary_date}
                ></input>
              </div>
            </div>
            {/* <div className="formField">
              <label className="col-sm-3 col-12" htmlFor="term">
                Terms
              </label>
              <select className="form-select" name="term" onChange={(e)=>handleBlur(e)} defaultValue={formData?.term} >
                    <option value="none">None</option>
                    <option value="0">On Receipt</option>
                    <option value="1">Next Day</option>
                    <option value="2">2 Days</option>
                    <option value="3">3 Days</option>
                    <option value="4">4 Days</option>
                    <option value="5">5 Days</option>
                    <option value="6">6 Days</option>
                    <option value="7">7 Days</option>
                    <option value="10">10 Days</option>
                    <option value="14">14 Days</option>
                    <option value="21">21 Days</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="45">45 Days</option>
                    <option value="60">60 Days</option>
                    <option value="90">90 Days</option>
                    <option value="120">120 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                  </select>
            </div>

            { formData?.term !=="none" &&
                <div className="formField">
                        <label className="col-sm-3 col-12" htmlFor="term_value">Term Date</label>
                        <input className="col-9  cursor-disable" type="date" name="term_value"
                          readOnly disabled onChange={(e)=>handleBlur(e)} value={formData?.term_value} autoComplete="off"></input>{" "}
                       
                </div>
            } */}
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
}

export default PersonalDetails;
