import React, { useState } from 'react'
import { useEffect } from 'react';
import Select, { components } from 'react-select'

function SelectImg({ options, handleOnChangeSelect, defaultSelect }) {
    const [selected,setSelected] = useState(null);

    const { SingleValue, Option } = components;

    const IconSingleValue = (props) => ( //selected option
        <SingleValue {...props}>
            {/* <img src={props?.data?.image} style={{ height: props?.data?.type === 'template' ? '30px' : '30px', width: props?.data?.type === 'template' ? '30px' : '35px', borderRadius: props?.data?.type === 'template' ? '0' : '0%', marginRight: '10px', objectFit: 'cover' }} alt='' /> */}
            {props?.data?.label}
        </SingleValue>
    );

    const IconOption = (props) => ( //list options
        <Option {...props}>
            <img src={props?.data?.image} style={{ height: props?.data?.type === 'template' ? '150px' : '25px', width: props?.data?.type === 'template' ? '110px' : '45px', borderRadius: props?.data?.type === 'template' ? '0' : '0%', marginRight: '10px','objectFit':'contain' }} alt='' />
            {props?.data?.label}
        </Option>
    );

    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }),
    }

    useEffect(()=>{
        if (defaultSelect && options) {
            const selectedOption = JSON.parse(defaultSelect);
            const defaultIndex = options.findIndex(option => option.value === selectedOption);
            if (defaultIndex !== -1) {
              setSelected(defaultIndex);
            }
          }
    },[defaultSelect,options])
    return (
        <>
        {options &&
            <Select
                styles={customStyles}
                components={{ SingleValue: IconSingleValue, Option: IconOption }}
                options={options}
                onChange={handleOnChangeSelect}
                isSearchable={true}
                required
                className="basic-single"
                classNamePrefix="select"
                value={options[selected]}
                placeholder='Select . . . '
            />
            }
        </>
    )
}

export default SelectImg