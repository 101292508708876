import React, { useState } from 'react'
import './usersettings.css'
import Profile from './UserSettings/Profile'
import ChangePassword from './UserSettings/ChangePassword'
// import TeamManagement from './UserSettings/TeamManagement'
import { useSelector } from 'react-redux';
import DeleteAccount from './UserSettings/DeleteAccount';
import SubscribedPlanInfo from './UserSettings/SubscribedPlanInfo';
const UserSettings = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [showTab,setShowTab] = useState(0);


    const auth = useSelector((state) => state.auth);


    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const hidePopup = () => {
        setShowPopup(false);
    };
    // useEffect(() => {
    //   AOS.init({
    //     offset: 120,
    //     duration: 1200,
    //     easing: 'ease',
    //     delay: 0,
    //     once: false,
    //     mirror: false,
    //     anchorPlacement: 'top-bottom',
    //   });
    //   AOS.refresh();
    // }, []);

    return (
        <>
            <div className="spacer"></div>
            <div className="spacer"></div>

            <div data-aos="zoom-in">
                <div className="account-setting-wrapper">
                    <div className="account-setting-wrapper-inner">
                        <div className="tabs">
                                
                                    {/* <!-- Nav tabs  &nbsp;&nbsp;--> */}
                                    <ul className='tablist'>
                                        <li onClick={()=>setShowTab(0)} className={`${showTab===0?'active':''}`}><i className="fa fa-user-edit"></i> <span>Profile</span></li>
                                        <li onClick={()=>setShowTab(1)} className={`${showTab===1?'active':''}`}><i className="fa fa-lock"></i> <span>Password</span></li>
                                        <li onClick={()=>setShowTab(3)} className={`${showTab===3?'active':''}`}><i className="fa fa-info-circle"></i> <span>Subscribed Plan</span></li>
                                        <li onClick={()=>setShowTab(2)} className={`${showTab===2?'active':''} text-danger`}><i className="fa fa-exclamation-triangle"></i> <span>Deactivate Account</span></li>
                                        
                                    </ul>
                                
                        </div>
                        <div className="content-tabs overflow-hidden">
                            {showTab === 0 &&(
                                <div data-aos="fade-right">
                                    <Profile auth={auth.user}/>
                                </div>
                            )}
                            {showTab === 1 &&(
                                <div data-aos="zoom-in">
                                    <ChangePassword />
                                </div>
                            )}
                            {showTab === 2 &&(
                                <div data-aos="fade-left">
                                    <DeleteAccount />
                                </div>
                            )}
                            {showTab === 3 &&(
                                <div data-aos="fade-left">
                                    < SubscribedPlanInfo/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className="season_tabs container">


                    <div className="season_tab">
                        <input type="radio" id="tab-1" name="tab-group-1" defaultChecked />
                        <label htmlFor="tab-1" className="cursor-pointer"><i className="fa fa-pen"></i> <span> Edit Profile</span></label>

                        <div className="season_content">
                            <span><Profile auth={auth.user} /></span>
                        </div>
                    </div>

                    <div className="season_tab">
                        <input type="radio" id="tab-2" name="tab-group-1" />
                        <label htmlFor="tab-2" className="cursor-pointer" ><i className="fa fa-unlock"></i> <span> Change Password</span></label>

                        <div className="season_content">
                            <span><ChangePassword /></span>
                        </div>
                    </div>
                    <div className="season_tab">
                        <input type="radio" id="tab-3" name="tab-group-1" />
                        <label htmlFor="tab-3" className="cursor-pointer text-danger" ><i className="fa fa-exclamation-triangle"></i> <span> Deactivate Account</span></label>

                        <div className="season_content">
                            <span><DeleteAccount /></span>
                        </div>
                    </div>



                </div> */}

                    {/* <div className="season_tab">
            <input type="radio" id="tab-3" name="tab-group-1"/>
            <label htmlFor="tab-3" onClick={hidePopup}>Management Team</label>
        
            <div className="season_content">
                <span><TeamManagement togglePopup={togglePopup} hidePopup={hidePopup} showPopup={showPopup}/></span>
            </div> 
        </div> */}


            </div>
        </>
    )
}

export default UserSettings