import React from "react";
import "./FallbackUI.css";

import { Link } from "react-router-dom";

function FallbackUI(props) {
    return (
        <section className="error-container">
            <h3>Oops! Something went wrong.</h3>
            <Link to="/" onClick={props.handleHideFallError}>
                Go to dashboard
            </Link>
            <h1 className="error-message">{props.error.message}</h1>
        </section>
    );
}

export default FallbackUI;
