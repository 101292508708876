import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notification_title: "Thank you for using our free services.",
    notification_details: "All your notification messages will be shown here.",
    notification_type: "normal",
    notification_type_icon: "",
    notification_color: "notification-primary",
    notification_show: false,
}

export const topNotificationSlice = createSlice({
    name: "topNotification",
    initialState,
    reducers:{
        showTopNotification: (state, {payload})=>{
            state.notification_title = payload.notification_title;
            state.notification_details = payload.notification_details;
            state.notification_type = payload.notification_type;
            state.notification_color = payload.notification_color;
            state.notification_type_icon= payload.notification_type_icon;
            state.notification_show = payload.notification_show;
        },
        hideTopNotification: (state)=>{
            state.notification_title =""
            state.notification_details = "";
            state.notification_type = "none";
            state.notification_color = "notification-primary";
            state.notification_type_icon = ""
            state.notification_show = false;
        }
    }
})

export const {showTopNotification,hideTopNotification} = topNotificationSlice.actions;

export default topNotificationSlice.reducer;