import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
// import countriesCurrencies from "../../assets/js/countriesCurrency";
import { currentData, deepCopy } from "../../utils/utils";
import Modal from "../modal/Modal";
import ReactPortal from "../reactPortal/ReactPortal";
import Edit from "../createSalaryslip/Edit";
// import InvoicePaymentRecord from "./InvoicePaymentRecord";
import Preview from "../createSalaryslip/Preview";
import SelectInvoiceTemplate from "../createSalaryslip/SelectInvoiceTemplate";
// import currencies from '../../assets/js/countriesCurrency'
import { 
   useDeleteSingleSalaryLogoMutation,
  useLazyGetSalaryUniqueNumberQuery,
  // useGetInvoiceTemplatesQuery,
  useDeleteSalarySingleEarningMutation,
  useDeleteSalarySingleDeductionMutation,
  useCreateInternalSalaryMutation,
  useLazyGetSingleInternalSalaryQuery,
  // useGetSingleSalaryGroupQuery,
  useUpdateInternalSalaryMutation,
  useLazyClientsNameSearchQuery,
  useLazyGetClientDataByIdQuery,
  useLazyEarnNameSearchQuery,
  useLazyDeductionNameSearchQuery,
  useLazyGetSingleSalaryGroupQuery
  } from "../../store/apis/baseApi";
import uuid from "react-uuid";
import countriesCurrencies from "../../assets/js/countriesCurrency";
import axios from "axios";
import { errorMessage, successMessage } from "../toastMessages/ToastifyMsg";
import { Link } from "react-router-dom";
import CustomDeleteloader from "../CustomDeleteloader";
import SendEmail from "../SendEmail";
import { IMG_URL, LARAVELAPI } from "../../common/common";
import { useDispatch, useSelector } from "react-redux";
import SelectImg from "../imgSelect/SelectImg";
import { setPlanDetails } from "../../store/slices/subscribedPlanDetails";

function CreateGroupPayslip(props) {
  const imgUrl = IMG_URL;
  const LARAVELurl = LARAVELAPI;
  const groupSalaryslipsPlanDetail = useSelector(state=>state.planDetails);

  const allTempStore =  useSelector((state)=>state.allTemplates);

  const { gSalaryId } = useParams(); // salary id  for updating
  const { salarygroupId } = useParams(); // group id 
  let urlId; // for single salary id
  let salaryGroup; // for salary group id
  if (gSalaryId) {
    urlId = window.atob(gSalaryId);
    // console.log("window.atob(gSalaryId)--- gSalary>>", urlId, "salaryGroup>>", salarygroupId);

  }
  if (salarygroupId) {
    salaryGroup = window.atob(salarygroupId);
  }

  // let urlId ;
  // if (salaryId) {

  //   urlId = salaryId.slice(1);
  //   // console.log("salaryId>", urlId  );
  // }
  // ------------------------------------------------------------------------

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [breadcrumbTitle,setBreadcrumbTitle] = useState(""); 

  const [showForm, setShowForm] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [salaryLogo, setSalaryLogo] = useState();
  // const [createSalarySlip] = useCreateSalarySlipMutation();
  const [templateSelect, setTemplateSelect] = useState(true);
  const [allTemplates, setAllTemplates] = useState([]);

  const [emailModal, setEmailModal] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [pdfId,setPdfID] = useState('');

  const [clientsNameSearch] = useLazyClientsNameSearchQuery();
  const [getClientDataById] =  useLazyGetClientDataByIdQuery();
  const [earnNameSearch] = useLazyEarnNameSearchQuery();
  const [deductionNameSearch] = useLazyDeductionNameSearchQuery();
  const [getSingleSalaryGroup] = useLazyGetSingleSalaryGroupQuery();
  const [deleteSingleSalaryLogo] = useDeleteSingleSalaryLogoMutation();
  const [getSalaryUniqueNumberQuery] = useLazyGetSalaryUniqueNumberQuery();
  const [createInternalSalary] = useCreateInternalSalaryMutation();
  const [deleteSalarySingleEarning] = useDeleteSalarySingleEarningMutation();
  const [deleteSalarySingleDeduction] = useDeleteSalarySingleDeductionMutation();
  const [getSingleInternalSalary] =  useLazyGetSingleInternalSalaryQuery();
  const [updateInternalSalary] = useUpdateInternalSalaryMutation();

  const [loader, setLoader] = useState(true);
  const [deleteLoader,setDeleteLoader] = useState(false);
  const [postLoader, setPostLoader] = useState(false);
  const [showSuggest, setShowSuggest] = useState(false);
  const [showSuggestEarn, setShowSuggestEarn] = useState(false);
  const [showSuggestDeduct, setShowSuggestDeduct] = useState(false);
  const [emailbuttonLoader, setEmailbuttonLoader] = useState(false);
  const [currencyOption,setCurrencyOption]  = useState(null);

  const [emailBody, setEmailBody] = useState({
    id: "",
    email_to: "",
    email_body: "Kindly find the attached document.",
    attachment: "true",
    cc_status : "false",
    cc_emails: ""
  });
  
  const [formData, setFormData] = useState({
    // logo: null,
    salary_group_id: "",
    // id: urlId,
    title: "",
    template_id: "",
    from_name: "",
    from_email: "",
    from_address1: "",
    from_address2: "",
    from_zip_code: "",
    from_phone: "",
    from_fax: "",
    from_gst: "",
    from_website: "",
    from_ntn: "",
    to_employee_name: "",
    to_employee_email: "",
    to_employee_address1: "",
    to_employee_address2: "",
    to_employee_zip_code: "",
    to_employee_phone: "",
    to_employee_website: "",
    to_employee_fax: "",
    to_employee_gst: "",
    to_employee_ntn: "",
    receipt_number: "",
    salary_date: "",
    term: "none",
    term_value: "",
    earnings: [
      {
        id: uuid(),
        earning_title: "",
        earning_amount: "0.00",
        state: "new",
        showSuggestion: false
      },
    ],
    deductions: [
      {
        id: uuid(),
        deduction_title: "",
        deduction_amount: "0.00",
        state: "new",
        showSuggestion: false
      },
    ],
    currency_selected: countriesCurrencies[0],
    total_earnings: "0",
    total_deductions: "0",
    total_net_pay: "0",
    ending_notes: "Your monthly Payslip.",
  }
  );
  const increaseInvoicePlan = (type)=>{
    const newPlanDetails = JSON.parse(JSON.stringify(groupSalaryslipsPlanDetail?.planDetails))
    newPlanDetails.plan_usage_details[type].usage_count += 1
    dispatch(setPlanDetails(newPlanDetails));
  }

  // ---------Modal START---------------------
  const handleOpenEmail = () => {
    if (groupSalaryslipsPlanDetail?.planDetails?.plan_usage_details?.email_sent?.usage_count<groupSalaryslipsPlanDetail?.planDetails?.plan_usage_details?.email_sent?.total) {
      setEmailModal(true);
    }
    else errorMessage("Limit Reached. Upgrade your plan!");
  }
  const handleCloseEmail = useCallback(() => {
      setEmailModal(false);
      setEmailBody((prevState)=>{
        return{
          ...prevState,
          id: "",
          email_to:"",
          cc_status: "false",
          cc_emails:""
        }
      })
    },[])
    
  const handleCloseSelectTemplate = useCallback(() => {
    setTemplateSelect(false);
  }, []);

  const handleOpenSelectTemplate = useCallback(() => {
    setTemplateSelect(true);
  }, []);
  // ---------Modal END---------------------

  useEffect(() => {
    if (countriesCurrencies) {
      const options = countriesCurrencies.map( currency=>({
        label: `${currency?.CountryName} ${currency?.Symbol}`,
        value: currency?.Code,
        image: currency?.Flag,
        type: 'currency',
        custom_value: currency
      }));
      
      setCurrencyOption(options);
    }
  }, []);

  
  useEffect(()=>{
    setLoader(true);

        let allTemps = deepCopy(allTempStore.allTemplates);
        let filterTemps = allTemps?.filter(
          (temp) => temp?.template_type === "salary"
        );
        
        if (filterTemps.length) {
          setAllTemplates(filterTemps);
          handleDefaultTemplate(filterTemps[0].id);
          setTimeout(() => {
            
            setLoader(false);
          }, 500);
        }
    
  },[allTempStore])

  // get single internal salary

  const singleData = (data) => {
    setShowForm(1);
    let groupInvoiceData = deepCopy(data.groupInvoiceData[0]);
    let invoiceSalaryData = deepCopy(data.invoicesalarydata[0]); // array index NOT to be REMOVED. from backend we are getting array if index to be removed then from backend index must be add so that it will give us object instead of array
    let deductions = deepCopy(data.deductions);
    let earnings = deepCopy(data.earnings);
    setBreadcrumbTitle(groupInvoiceData?.title);
    let date = new Date(invoiceSalaryData.salary_date);
    deductions = deductions.map((item) => {
      return {
        state: "old",
        ...item,
      }
    })
    earnings = earnings.map((item) => {
      return {
        state: "old",
        ...item,
      }
    })
    // let fullDate= `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    // var datee = new Date("Sun May 11,2014");
    var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    setPdfID(invoiceSalaryData?.suid_slsl_id);
    setFormData((prevState) => {
      return {
        ...prevState,
        id: invoiceSalaryData.id,
        // logo: invoiceSalaryData.logo,
        title: invoiceSalaryData.title || "",
        template_id: JSON.stringify(invoiceSalaryData.template_id),
        from_name: groupInvoiceData.from_name || "",
        from_email: groupInvoiceData.from_email || "",
        from_address1: groupInvoiceData.from_address1 || "",
        from_address2: groupInvoiceData.from_address2 || "",
        from_zip_code: groupInvoiceData.from_zip_code || "",
        from_phone: groupInvoiceData.from_phone || "",
        from_fax: groupInvoiceData.from_fax || "",
        from_website: groupInvoiceData.from_website || "",
        from_gst: groupInvoiceData.from_gst || "",
        from_ntn: groupInvoiceData.from_ntn || "",
        to_employee_name: invoiceSalaryData.to_employee_name || "",
        to_employee_email: invoiceSalaryData.to_employee_email || "",
        to_employee_address1: invoiceSalaryData.to_employee_address1 || "",
        to_employee_address2: invoiceSalaryData.to_employee_address2 || "",
        to_employee_zip_code: invoiceSalaryData.to_employee_zip_code || "",
        to_employee_phone: invoiceSalaryData.to_employee_phone || "",
        to_employee_fax: invoiceSalaryData.to_employee_fax || "",
        to_employee_website: invoiceSalaryData.to_employee_website || "",
        to_employee_gst: invoiceSalaryData.to_employee_gst || "",
        to_employee_ntn: invoiceSalaryData.to_employee_ntn || "",
        receipt_number: invoiceSalaryData.receipt_number || "",
        salary_date: dateString || "",
        term: invoiceSalaryData.term || "",
        term_value: invoiceSalaryData.term_value || "",
        earnings: earnings,
        deductions: deductions,
        currency_selected: JSON.parse(invoiceSalaryData.currency_selected),
        total_earnings: invoiceSalaryData.total_earnings || "",
        total_deductions: invoiceSalaryData.total_deductions || "",
        total_net_pay: invoiceSalaryData.total_net_pay || "",
        ending_notes: invoiceSalaryData.ending_notes || "",
        amount_in_words: invoiceSalaryData.amount_in_words || null
      }
    }
    );

    setEmailBody((prevState) => {
      return ({
        ...prevState,
        id: urlId,
        email_to: invoiceSalaryData.to_employee_email,
        email_body: `Kindly find the attached PaySlip.`
      })
    })
    // http://35.172.214.163:4000/
    // http://localhost:4000/
    if (groupInvoiceData.logo !== null && groupInvoiceData.logo && groupInvoiceData.logo !== "NULL") {

      // https://stagingapi.tradingforms.com
      // // let imgUrl = 'https://stagingapi.tradingforms.com/'
      // let imgUrl = 'http://localhost:4000/'
      setLogoImage(`${imgUrl}${groupInvoiceData.logo}`);
    } else {
      setLogoImage(null)
    }


    setTemplateSelect(false);
    setLoader(false);
  }
  

  useEffect(() => {
    if (props.updating) {
      setLoader(true);
      setTemplateSelect(false);
      (async () => {
        try {
          const { data, error } = await getSingleInternalSalary(urlId);
          if (data) {
            if (data.success) {
                singleData(data);
            }
            if (!data.success) {
              errorMessage(data.message);
              setLoader(false);
            }

          }
          if (error) {
            // console.log("ERROR:", error);
            errorMessage(error?.error ?? error?.data?.error?.message ?? error?.data?.message);
            setLoader(false);
          }
        } catch (error) {

        }

      })();

    }
  }, [urlId, props.updating, getSingleInternalSalary]);
  

  //  from group data OR company setting 
  useEffect(() => {
    setLoader(true);
    try {
      if (!props.updating) {
        const groupData = async () => {
          const { data, error } = await getSingleSalaryGroup(salaryGroup);
          if (data) {
            if (data.success) {
              if (data && Object.keys(data.groupInvoiceData).length) {
                let groupInvoiceData = deepCopy(data.groupInvoiceData);
                setFormData((prevState) => {
                  return {
                    ...prevState,
                    // logo: null,
                    title: groupInvoiceData?.title,
                    from_name: groupInvoiceData?.from_name,
                    from_email: groupInvoiceData?.from_email,
                    from_address1: groupInvoiceData?.from_address1,
                    from_address2: groupInvoiceData?.from_address2,
                    from_zip_code: groupInvoiceData?.from_zip_code,
                    from_phone: groupInvoiceData?.from_phone,
                    from_fax: groupInvoiceData?.from_fax,
                    from_website: groupInvoiceData?.from_website,
                    from_gst: groupInvoiceData?.from_gst,
                    from_ntn: groupInvoiceData?.from_ntn,
                    // receipt_number: groupInvoiceData.invoice_number,
                    // ending_notes: groupInvoiceData?.from_default_note,


                  }
                })
                setBreadcrumbTitle(groupInvoiceData?.title);
                // https://stagingapi.tradingforms.com/
                // http://localhost:4000/

                if (groupInvoiceData.logo !== "NULL" && groupInvoiceData.logo !== null) {

                  // let imgUrl = 'https://stagingapi.tradingforms.com/'
                  // let imgUrl = 'http://localhost:4000/'  

                  setLogoImage(`${imgUrl}${groupInvoiceData.logo}`);
                  setSalaryLogo(groupInvoiceData.logo);
                } else {
                  setLogoImage(null)
                }
              }
            }
            if (!data.success) {
              errorMessage(data.message);
            }
            setLoader(false);
          }
          if (error) {
            errorMessage(error?.error ?? error.data.error.message ?? error?.data?.message);
            setLoader(false);
          }
        }
        groupData();
      }
    } catch (error) {

    }
    // }
  }, [getSingleSalaryGroup, salaryGroup, imgUrl, props.updating])

  // ------- unique number for invoice------
  useEffect(() => {
    if (!props.updating) {
      (async () => {
        try {
            setLoader(true);
            const { data, error } = await getSalaryUniqueNumberQuery();
            //  console.log(data);

            if (data) {
              //  console.log(data);
              if (data.success) {
                // console.log("success")
                let uniqueinvoice = deepCopy(data.data);
                if (uniqueinvoice !== "") {
      
                  setFormData((prevState) => {
                    return {
                      ...prevState,
                      receipt_number: uniqueinvoice
                    }
                  })
                }
                if (data.data.length === 0) {
                  uniqueinvoice = "TFPLP-0001";
                  setFormData((prevState) => {
                    return {
                      ...prevState,
                      receipt_number: uniqueinvoice
                    }
                  })
                }
              }
              if (!data.success) {
                errorMessage(data.message);
              }
              setLoader(false);
            }
            if (error) {
              // console.log("Error in fetching unique invoice number", error.data.error.message)
              errorMessage(error?.error ?? error?.data?.error?.message);
              setLoader(false);

            }
        } catch (error) {
          // console.log("catchError");
          // errorMessage("Catch")
        }
      
      })();

    }
  }, []);



  const handleDefaultTemplate = (value) => {
    // console.log(value);
    if (!props.updating && formData?.template_id === "") {

      setFormData((prevState) => {
        return { ...prevState, template_id: value };
      });
      // console.log("template",formData)
    }
  }
  // =========Setting current date of payslip START ================
 useEffect(() => {
  if (!props.updating) {
    setFormData((prevState)=>{
      return{
      ...prevState,
      salary_date : currentData()
    }
    })
  }
}, [props.updating]);
// =========Setting current date of payslip END ================


  // =========== Copy to clipboard  START==============
  const copyToClip = async () => {
    try {

      let url = `${LARAVELurl}salary_invoice_preview?id=${pdfId}`
      await navigator.clipboard.writeText(url);
      // alert('URL copied to clipboard!');
      successMessage("Shareable Link Copied To Clipboard!");
    } catch (error) {
      // console.error('catchError to copy URL:');
    }
  }
  // =========== Copy to clipboard END==============

  const handleBlur = (e) => {
    e.preventDefault();

    if (e.target.name === "currency_selected") {
      // console.log(JSON.parse(e.target.value));
      setFormData((prevState) => {
        return { ...prevState, [e.target.name]: JSON.parse(e.target.value) };
      });
    }
    // if (e.target.name === "salary_date") {

    //   // console.log("e.target.value",e.target.value);
    //   let value = e.target.value;
    //   if (formData?.term !== "none") {
    //     let date = new Date(value);
    //     // console.log("salarydate>>date",date);

    //     let addedDays = date.addDays(Number(formData?.term));

    //     // console.log("addedDays>>",addedDays);

    //     // let final = date.toISOString(addedDays);
    //     let final = addedDays;
    //     let conver = new Date(final);
    //     // console.log("conver>>",conver);
    //     let salaryDate = new Date(
    //       conver.getTime() - conver.getTimezoneOffset() * 60000
    //     )
    //       .toISOString()
    //       .split("T")[0];


    //     // console.log(date.toISOString(addedDays),"<<<salaryDate>>>",salaryDate);
    //     setFormData((prevState) => {
    //       return { ...prevState, salary_date: e.target.value, term_value: salaryDate }
    //     })
    //   } else {
    //     setFormData((prevState) => {
    //       return { ...prevState, salary_date: e.target.value, term_value: "" }
    //     })
    //   }

    // }
    // if (e.target.name === "term") {
    //   let termsinput = e.target.value;
    //   // console.log("select-termsinput>>",termsinput);
    //   if (termsinput !== "none") {
    //     let date = new Date(formData?.salary_date);
    //     let addedDays = date.addDays(Number(termsinput));
    //     // console.log("select-addedDays>",addedDays);
    //     let final = date.toISOString(addedDays);
    //     // console.log("select-final>>>>",final);
    //     let conver = new Date(final);

    //     let salaryDate = new Date(
    //       conver.getTime() - conver.getTimezoneOffset() * 60000
    //     )
    //       .toISOString()
    //       .split("T")[0];

    //     // console.log("salaryDate",salaryDate);

    //     setFormData((prevState) => {
    //       return { ...prevState, term: termsinput, term_value: salaryDate }
    //     });
    //   } else {
    //     setFormData((prevState) => {
    //       return { ...prevState, term: termsinput, term_value: "0" }
    //     });
    //   }

    // }
    if (e.target.name !== "currency_selected" && e.target.name !== "term_value" 
    // && e.target.name !== "from_phone" && e.target.name !== "from_fax" && e.target.name !== "to_employee_phone" && e.target.name !== "to_employee_fax"
    ) {
      // console.log("others>>")  
      if (e.target.name === "to_employee_name") {

        e.target.value.trim() ? setShowSuggest(true) : setShowSuggest(false);
      }
      setFormData((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      });
    }

    // console.log(formData[e.target.name]);
  };
  
  const handleOnChangeSelect= (selected) => {
    setFormData((prevState) => {
      return { ...prevState, currency_selected: selected.custom_value };
    });

}
  const handleSelectTemplate = (value) => {
    setFormData((prevState) => {
      return { ...prevState, template_id: `${value}` };
    });
  }

  

  // handle phone/fax numbers fields 
  const handleTeleFields = (name, value) => {
    setFormData((prevState)=>{
      return{
        ...prevState,
        [name]: value
      }
    });
  }

  // remove image from front site and also empty the state which is being added in formData while submiting form
  const removeImage = async () => {
    try {
       setLogoImage(null);
    setSalaryLogo(null);
    if (props.updating) {
      const { data, error } = await deleteSingleSalaryLogo(urlId);
      if (data) {
        if (data.success) {
          successMessage(data.message);
        }
        if (!data.success) {
          errorMessage(data.message);
        }
      }
      if (error) {
        // console.log("logoNot Deleted", error);
        errorMessage(error?.error ?? error?.data?.error?.message);
      }
    }
    } catch (error) {
      // console.log("catchError");
    }
   
  }
  // add image in states to display image on personal detail logo and also updating the state which is being added in formData while submiting form
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    // console.log(file)
    // setFormData({
    //   ...formData,
    //   logo: file,
    // });
    if (file) {

      setSalaryLogo(file);
      const reader = new FileReader();
      // setLogoImage();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setLogoImage(reader.result);
      };
    }

    // console.log(salaryLogo);
    // console.log(formData.logo);
  };

  function handleAddEarning(earningItem) {
    setFormData((prevState) => {
      return {
        ...prevState,
        earnings: [...prevState.earnings, earningItem],
      };
    });
  }

  function handleAddDeduction(deductionItem) {
    setFormData((prevState) => {
      return {
        ...prevState,
        deductions: [...prevState.deductions, deductionItem],
      };
    });
  }

  //  ===========remove=========
  const calculateTotals = (newFormData) => {
    let totalearn = 0;
    newFormData?.earnings.forEach((earnings) => {
      totalearn += Number(earnings?.earning_amount);
    });
    let totalDeductions = 0;
    newFormData?.deductions.forEach((deductions) => {
      totalDeductions += Number(deductions?.deduction_amount);
    });
    
      return {
        total_earnings: totalearn,
        total_deductions: totalDeductions,
        total_net_pay: totalearn - totalDeductions,
      }
    
  }

  async function handleRemoveDescription(id, type) {
    try {
      setDeleteLoader(true);
      let deleted = formData[type].filter((element) => element.id === id);

      const updatedFormData = {
        ...formData,
        [type]: formData[type].filter((element) => element.id !== id),
      };
      setFormData(updatedFormData);


      if (type === "earnings") {
        if (deleted[0].state === "old") {
          try {
            const updatedCalculation = calculateTotals(updatedFormData);
            const body = {
              id,
              payslipid:urlId,
              total_earnings: updatedCalculation?.total_earnings,
              total_net_pay: updatedCalculation?.total_net_pay,
            }
            
            const { data, error } = await deleteSalarySingleEarning(body);
            if (data) {
              // console.log(data);
              if (data.success) {
                successMessage(data.message)
                // console.log("success",data);
              }
              if (!data.success) {
                errorMessage(data.message);
              }
            }
            if (error) {
              // console.log(error);
              errorMessage(error?.error ?? error?.message);
            }
          } catch (error) {}
        }

      }
      if (type === "deductions") {
        if (deleted[0].state === "old") {
          const updatedCalculation = calculateTotals(updatedFormData);
            const body = {
              id,
              payslipid:urlId,
              total_deductions: updatedCalculation?.total_deductions,
              total_net_pay: updatedCalculation?.total_net_pay,
            }

          const { data, error } = await deleteSalarySingleDeduction(body);
          if (data) {
            // console.log(data);
            if (data.success) {
              successMessage(data.message);
            }
            if (!data.success) {
              errorMessage(data.message);
            }
          }
          if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
          }

        }
      }
    } catch (error) {
      // console.log("catchError");
    }finally{
      setDeleteLoader(false);
    }


  }

  function handleEditDescription(e, id, type) {
    setFormData((prevState) => {
      let newState = deepCopy(prevState);
      let oldDesc;
      if (type === "earnings") {
        oldDesc = newState.earnings.find((desc) => desc.id === id);
        if (oldDesc) {
          if (e.target.name === "earning_title") {
            e.target.value.trim() ? oldDesc.showSuggestion = true : oldDesc.showSuggestion = false;
            e.target.value.trim() ? setShowSuggestEarn(true) : setShowSuggestEarn(false);

          }
          oldDesc[e.target.name] = e.target.value;
        }
      }
      if (type === "deductions") {
        oldDesc = newState.deductions.find((desc) => desc.id === id);
        if (oldDesc) {
          if (e.target.name === "deduction_title") {

            //  console.log("oldDesc>>",oldDesc)
            e.target.value.trim() ? oldDesc.showSuggestion = true : oldDesc.showSuggestion = false;
            // e.target.value.trim() ? setShowSuggestDeduct(true) : setShowSuggestDeduct(false);

          }
          oldDesc[e.target.name] = e.target.value;
          // console.log(oldDesc[e.target.name] , e.target.value)


        }
      }
      return newState;
    });
    // console.log(formData);
  }

  function handleEditDescriptionDeductions (e,id) {
    setFormData((prevState) => {
      let newState = deepCopy(prevState);
      let oldDesc = newState.deductions.find(desc => desc.id === id);

      if (oldDesc) {
        e.target.value.trim() ? oldDesc.showSuggestion = true : oldDesc.showSuggestion = false;
        oldDesc[e.target.name] = e.target.value
      }

      return newState

    });
    // console.log(formData.deductions);
  }


  // =========calculations=========
  //for Earning amount  calculations
  const [total, setTotalEarnings] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);

  useEffect(() => {

    let total = 0;
    formData?.earnings.forEach((earnings) => {
      total += Number(earnings?.earning_amount);
    });
    let totalDeductions = 0;
    formData?.deductions.forEach((deductions) => {
      totalDeductions += Number(deductions?.deduction_amount);
    });
    // console.log("totalDeductions",totalDeductions)
    setTotalDeductions(JSON.stringify(totalDeductions));
    setTotalEarnings(JSON.stringify(total));
    setFormData((prevState) => {
      return {
        ...prevState,
        total_earnings: total,
        total_deductions: totalDeductions,
        total_net_pay: total - totalDeductions,
      }
    })
    // console.log(JSON.stringify(total),'maajidaa');
  }, [formData?.earnings, formData?.deductions]);

  // --------handle submit  START --------
  const handleSubmit = async () => {

    // event.preventDefault();
    // alert("Final Step reached(Saved). showStep->3");
    // console.log("body----before>>",formData);
    if (props.updating) {
      try {
        let updateGroupInternalSalary = new FormData();
      let body = {
        ...formData,
        earnings: formData.earnings.map((item) => {
          return (item.state === "new" ? {
            earning_title: item.earning_title,
            earning_amount: item.earning_amount,
          } : {
            id: item.id,
            earning_title: item.earning_title,
            earning_amount: item.earning_amount,
          })
        }),
        deductions: formData.deductions.map((item) => {
          return (item.state === "new" ? {
            deduction_title: item.deduction_title,
            deduction_amount: item.deduction_amount,
          } : {
            id: item.id,
            deduction_title: item.deduction_title,
            deduction_amount: item.deduction_amount,
          })
        }),
        currency_selected: JSON.stringify(formData.currency_selected),
        // logo: salaryLogo,
      };
      // console.log(body,"body----After->>",JSON.stringify(body));

      if (salaryLogo) {
        updateGroupInternalSalary.append('logo', salaryLogo);
      }
      updateGroupInternalSalary.append('data', JSON.stringify(body));

        const resp = await updateInternalSalary({id:urlId, updateGroupInternalSalary});

        if (resp.data) {
          if (resp?.data?.success) {
            singleData(resp?.data)
            setPostLoader(false);
            successMessage(resp.data.message);
            // navigate(`/salarygroup/${encodeURIComponent(`/${salaryGroup}`)}`);
            // navigate(-1);
          }
          if (!resp.data.success) {
            errorMessage(resp.data.message);
            setPostLoader(false);
          }
      }
      if (resp.error) {
        // console.log(resp.error);
        errorMessage(resp?.error?.error ??  resp?.error?.data?.error?.message ?? resp?.error?.data?.message ?? "Server not responding");
      }

      } catch (error) {
        // console.log("catchError");
      }
      

    } else {
      try {
        let saveGroupInternalSalary = new FormData();

      // console.log("salaryGroup>>>",salaryGroup)
      let body = {
        ...formData,
        salary_group_id: salaryGroup,
        earnings: formData.earnings.map((item) => ({
          earning_title: item.earning_title,
          earning_amount: item.earning_amount,
        })),
        deductions: formData.deductions.map((item) => ({
          deduction_title: item.deduction_title,
          deduction_amount: item.deduction_amount,
        })),
        currency_selected: JSON.stringify(formData.currency_selected),
        // logo: salaryLogo,
      };
      if (salaryLogo) {
        saveGroupInternalSalary.append('logo', salaryLogo);
      }

      saveGroupInternalSalary.append('data', JSON.stringify(body));

      // console.log("groupSalaryBody>>",body);

      const resp = await createInternalSalary(saveGroupInternalSalary);

      if (resp.data) {
        if (resp.data.success) {
          // console.log(resp.data)
          successMessage(resp.data.message);
          setPostLoader(false);
          
            setTimeout(() => {
              increaseInvoicePlan("salary_group_payslip")
            }, 10);
          // navigate(`/salarygroup/${encodeURIComponent(`/${salaryGroup}`)}`);
          navigate(-1);
        }
        if (!resp.data.success) {
          errorMessage(resp.data.message);
          setPostLoader(false);
        }

      }
      if (resp.error) {
          // console.log(resp.error);
          errorMessage(resp?.error?.error ?? resp?.error?.data?.error?.message);
      }
      } catch (error) {
        // console.log("catchError");
      }
      

    }
  };

  const confirmSave = (type) => {

    if (type === true) {
      setPostLoader(true);
      setIsConfirmation(false);
      handleSubmit();
    }
    if (type === false) {
      setIsConfirmation(false);
    }
  }

  const saveModal = (e) => {
    e.preventDefault();
    setIsConfirmation(true);
  }
  // --------handle submit  END --------

  //=========Name Suggestion START========== 
  const [suggestList, setSuggestList] = useState([]);

  const autoSuggstion = async (e) => {
    e.preventDefault();
    try {
      let search = e.target.value;
      if (search !== "" || search !== " ") {
        const {data,error} = await clientsNameSearch(search);
        if (data) {
          if (data?.success) {
              let list = deepCopy(data.data);
              setSuggestList(list);
          }
          if (!data?.success) {
              errorMessage(data.message);
          }
        }
        if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
        }
      }
      
    } catch (error) {
      
    }
  };


  const handleHideSuggestion = (rowId, type) => {
    // console.log("selectedOption",type);
    if (type === "earnings") {
      if (formData.earnings.find((earn)=>earn.id === rowId && earn.showSuggestion===true)) {
        setFormData((prevState) => {
          let newState = deepCopy(prevState);
          let oldDesc = newState.earnings.find((desc) => desc.id === rowId);
          if (!!oldDesc) {
            oldDesc.showSuggestion = false;
          }
          return newState;
        });
      }
    }

    if (type === "deductions") {
      // console.log("deductions>>")
      if (formData.deductions.find((deduct)=>deduct.id === rowId && deduct.showSuggestion===true)) {
        setFormData((prevState) => {
          let newState = deepCopy(prevState);
          let oldDesc = newState.deductions.find((desc) => desc.id === rowId);
          if (!!oldDesc) {
            oldDesc.showSuggestion = false;
          }
          return newState;
        });
        
      }
    }
  }

  const fillFields =async (name)=>{
    // e.preventDefault();
    try {
      let search = name; 
      
      
      const {data,error } = await getClientDataById(search);
      if (data) {
        if (data?.success) {
          let list = deepCopy(data.data[0]);
          setFormData((prevState)=>({
            ...prevState,
            to_employee_name: "",
          }));

          let entity_name = list.entity_name || "";
          let entity_city = list.entity_city +", "+ list.entity_state || "";
          let entity_email = list.entity_email || "";
          let entity_fax = list.entity_fax || "";
          let entity_gst = list.entity_gst || "";
          let entity_ntn = list.entity_ntn || "";
          let entity_phone_official = list.entity_phone_official || "";
          let entity_address = list.entity_address || "";
          let entity_website = list.entity_website || "";

          // console.log('entity_address',entity_name)
          let entity_zipCode = list.entity_zip_code || "";
          setFormData((prevState)=>({
              ...prevState,
              to_employee_name: entity_name,
              to_employee_address2: entity_city,
              to_employee_email: entity_email ,
              to_employee_fax: entity_fax , 
              to_employee_gst: entity_gst,
              to_employee_ntn: entity_ntn,
              to_employee_phone: entity_phone_official,
              to_employee_address1: entity_address,
              to_employee_zip_code: entity_zipCode,
              to_employee_website: entity_website,
            })
          );
          setShowSuggest(false);
        }
        if (!data.success) {
          errorMessage(data?.message);
        }
      }
      if (error) {
        errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
      }
    } catch (error) {
      
    }
  }
  //=========Name Suggestion END=================== 

  // ==========Earning Suggetion START===============
  const [allEarningItems, setAllEarningItems] = useState([]);
  const [allDeductionitems, setAllDeductionItems] = useState([]);


  const autoSuggstionitems= async(e,type)=>{
    let search = e.target.value;
    if (type==="earnings") {
     if (search) {
       
       try {
         const {data,error} = await earnNameSearch(search);
         if (data) {
           if (data?.success) {
         let list = deepCopy(data.data);
         setAllEarningItems(list);
           }
           if (!data.success) {
             errorMessage(data?.message);
           }
         }
     
         if (error) {
           errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
         }
         
       } catch (error) {
         
       }
   
   
     }
   
    }
    if (type ==="deductions") {
     try {
       const allData = await deductionNameSearch(search);
       const {data,error} =  allData;
     if (data) {
       if (data?.success) {
         let list = deepCopy(data.data);
       setAllDeductionItems(list);
       }
       if (!data?.success) {
         errorMessage(data.message);
       }
     }
     if (error) {
       errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
     }
     } catch (error) {
       
     }
     
    }
   
   }
  //  autoSuggstionitems();
  const fillItemFields = (e, rowId, type, value) => {
    e.preventDefault();
    // console.log(rowId,type,value);

    setFormData((prevState) => {
      let newState = deepCopy(prevState);
      let oldDesc;
      if (type === "earnings") {
        oldDesc = newState.earnings.find((desc) => desc.id === rowId);
        if (oldDesc) {

          oldDesc.earning_title = value;
        }
      } else if (type === "deductions") {
        oldDesc = newState.deductions.find((desc) => desc.id === rowId);
        if (oldDesc) {

          oldDesc.deduction_title = value;
        }
      }
      return newState;
    });
    if (type === "earnings") {
      setShowSuggestEarn(false);
    }
    if (type === "deductions") {
      setShowSuggestDeduct(false);
    }

    //  setFormData((prevState) => {
    //    let newState = deepCopy(prevState);
    //    let oldDesc = newState.description.find((desc) => desc.id === rowId);
    //    // console.log("Setitems>>",item)
    //    // console.log(oldDesc);
    //    if (oldDesc) {
    //     //  oldDesc.itemDescription = item.item_name;
    //     //  oldDesc.additionalDetail = item.description;
    //     //  oldDesc.rate = item.item_rate;
    //     //  oldDesc.tax = item.item_texable ==="yes" ? true:false;
    //      // console.log("UpdateoldDesc",oldDesc)
    //    }

    //    // if (e.target.name === "rate" || e.target.name === "quantity") {
    //    //   // newState.subTotal = asdsa
    //    //   // newState.total = asdsa
    //    //   let amount = (
    //    //     Number(oldDesc?.quantity) * Number(oldDesc?.rate)
    //    //   ).toFixed(2);
    //    //   oldDesc["amount"] = amount;
    //    // }
    //    // console.log("formData.description",formData.description)
    //    return newState;
    //  });
    //  console.log(formData.earnings)

  }


  // ==========Earning Suggetion END=================


  // ================email=======================
  const emailChange = (e) => {
    e.preventDefault();
    const {name,value} = e.target;
    if (name === "cc_status" & value === "false") {
      setEmailBody((prevState)=>{
        return({
          ...prevState, cc_emails:""
        })
      })
    }
    setEmailBody((prevState)=>{
      return({
        ...prevState, [name]:value
      })
    })
    // console.log(emailBody);

  }

  const handleEmail = async (e) => {
    try {
      e.preventDefault();
    setEmailbuttonLoader(true);
    let id = urlId;

    let body = {
      id,
      email_to: emailBody?.email_to,
      email_body: emailBody?.email_body,
      attachment: emailBody?.attachment,
      cc_status: emailBody?.cc_status,
      cc_emails: emailBody?.cc_emails,
      type: "salary"
    }
    // https://stagingfront.tradingforms.com/api/invoice_email?id=59
    // console.log(body);
    let token = localStorage.getItem("tradingformtoken");
    const {data,error} = await axios(`${LARAVELurl}salary_invoice_email`, {
      method: "POST",
      data: body,
      headers: {
        "Content-Type": "application/json;",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
    if (data) {
      if (data.success) {
        setEmailModal(false);
        setEmailbuttonLoader(false);
        successMessage(data?.message);
        increaseInvoicePlan("email_sent");
        setEmailBody((prevState)=>{
        return{
          ...prevState,
          attachment:"true",
          cc_status: "false",
          cc_emails:""
        }
      })
      }
      if (!data.success) {
        setEmailbuttonLoader(false);
        errorMessage(data?.message);
      }
    }
    if (error) {
      errorMessage(error?.error ?? error?.data?.message ?? error.data?.message );
      // console.log(error)
      setEmailbuttonLoader(false);
    }
    } catch (error) {
      // console.log("catchError");
    }
    
  }


  return (
    <>
      {/* <div><b> Internal </b>
        {gSalaryId ? ("Update") : ("Create")} GroupPayslip invoice
      </div> */}
      {/* =============================================== */}

    { loader &&
      <CustomDeleteloader message="Loading . . ."/>
    }
    {deleteLoader &&
        <CustomDeleteloader message="Deleting . . ." />
      }
    {postLoader &&
      <CustomDeleteloader message={`${props.updating ?  'Updating ': 'Adding '}. . .`}/>
    }


<div className="spacer"></div>
      <div className="container">
        <div className="col-12 invoice-detail salary-slip-details ">
            <div className="col-lg-11 col-xl-10 col-11">
                 <div className="breadcrumb-container">
                  
                  <nav className="breadcrumbs">
                    <Link to="/" className="breadcrumbs__item" title="Go to Dashboard">Dashboard</Link>
                    <Link to="/salary-slips" className="breadcrumbs__item" state={{showTab: '2'}} title="Go to Payslip Groups">Payslip Groups</Link>
                    <span onClick={()=>navigate(-1)} className="breadcrumbs__item" title={`Go to ${breadcrumbTitle} Group`} >
                      <span className="text-uppercase">{breadcrumbTitle}</span>
                    </span> 
                    {/* <Link to="" className="breadcrumbs__item">Cart</Link> */}
                    <span  className="breadcrumbs__item is-active"> {props.updating ? "Edit":"Create"} </span> 
                  </nav>  <div className="spacer"></div>
                </div>
            </div>
          <div className="col-lg-11 col-xl-10 col-11">
            <div className="invoice-actions">
              <div className="invoice-navBtn">
                { props.updating &&
                  <>
                    <button
                      type="button"
                      onClick={() => setShowForm(1)}
                      className={`btn btn-secondary btn-sm me-1 ${showForm===1? 'active cursor-disable':''}` }
                      title="Click to Preview"
                    >
                      {" "}
                      <i className="far fa-eye"></i><span>Preview</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowForm(0)}
                      className={`btn btn-secondary btn-sm me-1 ${showForm===0? 'active cursor-disable':''}` }
                      title="Click to Edit"
                    >
                      <i className="fas fa-edit"></i><span>Edit</span>
                    </button>
                  </>
                
                }
                {showForm === 0 && (
                  <button
                    type="button"
                    onClick={handleOpenSelectTemplate}
                    className="btn btn-primary btn-sm"
                    title="Click to Select Template"
                  >
                    <i className="fa fa-file-invoice"></i><span> Select Template</span>
                  </button>
                  )}

                {/* <select
                  className="btn text-start"
                  name="currency_selected"
                  onChange={handleBlur}
                  value={JSON.stringify(formData?.currency_selected)}
                >
                  {currencies.map((currency, index) => (
                    <option key={index} value={JSON.stringify(currency)}>
                      {currency.CountryName} {currency.Symbol}
                    </option>
                  ))}
                </select> */}
                <div style={{'width':'185px'}}>
                    <SelectImg
                      options={currencyOption}
                      handleOnChangeSelect={handleOnChangeSelect}
                      defaultSelect={JSON.stringify(formData?.currency_selected?.Code)}
                    /> 
                </div>
              </div>
              { props.updating &&
                <div className="invoice-actions-export">
                  {/* <button
                    type="button"
                    className="btn btn-sm"
                    onClick={handleModalOpen}
                  >
                    Record Payment
                  </button> */}
                  <Link className="btn btn-sm button-pdf" to={`${LARAVELurl}salary_invoice_preview?id=${pdfId}`} target="_blank" title="Click to view PDF">
                  <i className="fa fa-file-pdf"></i><span>PDF</span>
                  </Link>
                  <button type="button" className="btn btn-sm button-copy-url" onClick={copyToClip} title="Click to copy shareable link.">
                    <i className="fa fa-copy"></i><span>Copy URL</span>
                  </button>
                  <button type="button" className="btn btn-sm button-email-invoice" onClick={handleOpenEmail} title="Click to send an email">
                    <i className="fa fa-envelope"></i><span>Email Payslip</span> 
                  </button>
                </div>
               }
            </div>
          </div>
          <div className="row justify-content-center" style={{ width: "100%" }}>
            <div className="col-lg-11 col-xl-10 px-0">
              <div className="new-edit-invoice-container">
                {showForm === 1 && (
                  <div data-aos="fade-right">
                    <Preview
                      formData={formData}
                      logoImage={logoImage}
                      total={total}
                      totalDeductions={totalDeductions}
                      //  selectedCurrency={selectedCurrency}
                    />

                  </div>
                )}
                {showForm === 0 && (
                  <div data-aos="fade-right">
                    <Edit
                      handleBlur={handleBlur}
                      formData={formData}
                      handleAddEarning={handleAddEarning}
                      handleAddDeduction={handleAddDeduction}
                      handleRemoveDescription={handleRemoveDescription}
                      handleChange={handleEditDescription}
                      handleChange2={handleEditDescriptionDeductions}
                      total={total}
                      totalDeductions={totalDeductions}
                      // selectedCurrency={selectedCurrency}
                      // handleSubmit={handleSubmit}
                      saveModal={saveModal}
                      handleFileInputChange={handleFileInputChange}
                      logoImage={logoImage}
                      removeImage={removeImage}
                      fillFields={fillFields}
                      autoSuggstion={autoSuggstion}
                      suggestList= {suggestList}
  
                      autoSuggstionitems={autoSuggstionitems}
                      allEarningItems={allEarningItems}
                      allDeductionitems={allDeductionitems}
                      fillItemFields={fillItemFields}
                      update={urlId}
                      showSuggest={showSuggest}
                      setShowSuggest={setShowSuggest}
                      showSuggestEarn={showSuggestEarn}
                      setShowSuggestEarn={setShowSuggestEarn}
                      showSuggestDeduct={showSuggestDeduct}
                      setShowSuggestDecuct={setShowSuggestDeduct}
                      handleHideSuggestion={handleHideSuggestion} 
                      readonlyFrom={true}
                      handleTeleFields={handleTeleFields}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-12 col-lg-4 pt-5">
            <TaxationDiscount handleBlur={handleBlur} formData={formData}/>
          </div> */}
          </div>
        </div>

        <div className="spacer"></div>
      </div>


      {/* select template modal */}
      <ReactPortal>
        {templateSelect && (
          <Modal
            handleClose={handleCloseSelectTemplate}
            header="Welcome to the <span className='pos-rel'>TradingForms</span>. Join happy small business."
            type="template"
            contentType="notscrollable"
          >
            <SelectInvoiceTemplate
              formData={formData}
              handleBlur={handleSelectTemplate}
              // handleDefaultTemplate={handleDefaultTemplate}
              handleClose={handleCloseSelectTemplate}
              filteredTemps={allTemplates}
            />
          </Modal>
        )}
      </ReactPortal>

          {/* Email modal */}
      <ReactPortal>
          { emailModal &&
                <Modal handleClose={handleCloseEmail} type="form" contentType="email-form" header="Enter details to send mail.">
                    <SendEmail emailBody={emailBody} handleSubmit={handleEmail} emailChange={emailChange} loader={emailbuttonLoader}/>
                </Modal>
          }
      </ReactPortal>
      {/* save confirmation modal */}
      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon={"save text-success"}
            header=""
            type="confirm"
            recordName={`Are you sure you want to ${props.updating? "Update": "Save"}`}
          >
            <button className="btn btn-save m-1 col-xs-12 col-sm-6 col-md-3" onClick={()=>confirmSave(true)}> Yes</button>
            <button className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3" onClick={()=>confirmSave(false)}>No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>





    </>
  )
}

export default CreateGroupPayslip