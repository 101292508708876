import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    allTemplates : []
}

export  const allTemplatesSlice = createSlice({
    name: "allTemplates",
    initialState,
    reducers: {
        setAllTemplates(state,{payload}){
            state.allTemplates= payload;
            },
    }
})
export const {setAllTemplates} = allTemplatesSlice.actions;
export default allTemplatesSlice.reducer;