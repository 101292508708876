import React from "react";

function InvoiceDescriptionRow({
  deleteRow,
  rowData,
  handleChange,
  type,
  inputName,
  selectedOption,
  amount,
  inputValues,
  suggestList,
  autoSuggstionitems,
  fillItemFields,
  placeHolder,
  showSuggest,
  setShowSuggest,
  handleHideSuggestion
}) {
  // const [selectedOption, setSelectedOption] = useState(null);
  // const options = [
  //     { value: 'option-1', label: 'Basic' },
  //     { value: 'option-2', label: 'Loan' },
  //     { value: 'option-3', label: 'Provident Fund' },
  //   ];

  // console.log(rowData);
  return (
    <>
      <div className="invoiceDescriptionRow fadeInOpacity" id={rowData?.id}>
        <div className="col-7">
          <div className="item-row align-items-center">
            <div className="item-row-action">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => deleteRow(rowData?.id, type)}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
            <div className="formField">
              <div className="w-100 m-1 nameSuggest">
               
                <input type="text"  
                value={selectedOption} 
                name={inputName} 
                className="form-control m-0" 
                placeholder={placeHolder}
                 onChange={(e)=> handleChange(e,rowData?.id,type)} 
                 onKeyUp={(e)=>autoSuggstionitems(e,type)} autoComplete="off"
                 onBlur={()=>{
                  setTimeout(() => {
                    // setShowSuggest(false)
                    handleHideSuggestion(rowData?.id,type)
                  }, 500);
                 }}
                 >
                 </input>
                { rowData?.showSuggestion  && (
                <div className="autosuggestDropDownContainer">
                    <div className="autosuggestDropDown">
                      {
                       <ul>
                      { selectedOption && suggestList.length ? ( 
                        suggestList?.map((list, index)=>{
                          return (   
                            

                            <li key={index} onClick={(e)=>fillItemFields(e, rowData?.id, type, type==="earnings" ?  list.addition_title:list.deduction_title)}> 
                              {type==="earnings" ?  list.addition_title:list.deduction_title}
                            </li>
                          )
                      })
                      ): (
                        <li className="text-danger">No Result Found !</li>
                      )
                       }
                   </ul>
                      }
                    </div>
                  </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-5">
          <div className="formField">
            <input
              type="number"
              name={amount}
              className="form-control count-input-text"
              placeholder="0.00(*)"
              min={0.00}
              onChange={(e) => handleChange(e, rowData?.id, type)}
              defaultValue={inputValues} autoComplete="off"
            ></input>
          </div>
        </div>

        
      </div>
    </>
  );
}

export default InvoiceDescriptionRow;
