import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { currentData, deepCopy } from "../../utils/utils";
import Modal from "../modal/Modal";
import ReactPortal from "../reactPortal/ReactPortal";
import Edit from "./Edit";
// import InvoicePaymentRecord from "./InvoicePaymentRecord";
import Preview from "./Preview";
import SelectInvoiceTemplate from "./SelectInvoiceTemplate";
import {
  useLazyGetSingleSalarySlipQuery,
  useDeleteSingleSalaryLogoMutation,
  // useGetSalaryUniqueNumberQuery,
  useLazyGetSalaryUniqueNumberQuery,
  useCreateSalarySlipMutation,
  // useUpdateInvoiceMutation, 
  useUpdateSalarSlipMutation,
  useDeleteSalarySingleEarningMutation,
  useDeleteSalarySingleDeductionMutation,
  useLazyClientsNameSearchQuery,
  useLazyGetClientDataByIdQuery,
  useLazyEarnNameSearchQuery,
  useLazyDeductionNameSearchQuery,
  useLazyGetCompanySettingQuery
} from "../../store/apis/baseApi";

import uuid from "react-uuid";
import countriesCurrencies from "../../assets/js/countriesCurrency";
import axios from "axios";
import { errorMessage, successMessage } from "../toastMessages/ToastifyMsg";
import { Link } from "react-router-dom";
import CustomDeleteloader from "../CustomDeleteloader";
import SendEmail from "../SendEmail";
import { IMG_URL, LARAVELAPI } from "../../common/common";
import { useDispatch, useSelector } from "react-redux";
import SelectImg from "../imgSelect/SelectImg";
import { setPlanDetails } from "../../store/slices/subscribedPlanDetails";
//
function CreateSalaryslip(props) {
  const imgUrl = IMG_URL;
  const LARAVELurl = LARAVELAPI;
  const allTempStore = useSelector((state) => state.allTemplates);

  const dispatch = useDispatch();



  const [showForm, setShowForm] = useState(0);
  const [logoImage, setLogoImage] = useState(null);
  const [salaryLogo, setSalaryLogo] = useState();
  const [templateSelect, setTemplateSelect] = useState(true);
  const [allTemplates, setAllTemplates] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const { salaryId } = useParams();
  const payslipPlanDetail = useSelector(state=>state.planDetails);


  let urlId;
  if (salaryId) {

    urlId = window.atob(salaryId);
  }
  const [clientsNameSearch] = useLazyClientsNameSearchQuery();
  const [getClientDataById] = useLazyGetClientDataByIdQuery();
  const [earnNameSearch] = useLazyEarnNameSearchQuery();
  const [deductionNameSearch] = useLazyDeductionNameSearchQuery();

  const [getSingleSalarySlip] = useLazyGetSingleSalarySlipQuery();
  const [deleteSingleSalaryLogo] = useDeleteSingleSalaryLogoMutation();
  const [getCompanySetting] = useLazyGetCompanySettingQuery();

  const [getSalaryUniqueNumberQuery] = useLazyGetSalaryUniqueNumberQuery();

  const navigate = useNavigate();
  const [emailModal, setEmailModal] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [pdfId, setPdfID] = useState('');

  const [loader, setLoader] = useState(true);
  const [postLoader, setPostLoader] = useState(false);
  const [showSuggest, setShowSuggest] = useState(false);
  const [showSuggestEarn, setShowSuggestEarn] = useState(false);
  const [showSuggestDeduct, setShowSuggestDeduct] = useState(false);
  const [deleteSalarySingleEarning] = useDeleteSalarySingleEarningMutation();
  const [deleteSalarySingleDeduction] = useDeleteSalarySingleDeductionMutation();
  const [emailbuttonLoader, setEmailbuttonLoader] = useState(false);

  const [currencyOption,setCurrencyOption]  = useState(null);



  const [emailBody, setEmailBody] = useState({
    id: "",
    email_to: "",
    email_body: "Kindly find the attached document.",
    attachment: "true",
    cc_status: "false",
    cc_emails: ""
  });
  // const [updateSalarSlip] = useUpdateSalarSlipMutation();
  // http://localhost:4000/api/v1/
  // https://stagingapi.tradingforms.com/api/v1/
  // const liveUrl = 'https://stagingapi.tradingforms.com/api/v1/'

  // if (salaryId) {
  //   console.log("salaryId :", salaryId, props.updating);
  // }

  const [formData, setFormData] = useState({
    // logo: null,
    title: "PAYSLIP",
    template_id: "",
    from_name: "",
    from_email: "",
    from_address1: "",
    from_address2: "",
    from_zip_code: "",
    from_phone: "",
    from_fax: "",
    from_gst: "",
    from_website: "",
    from_ntn: "",
    to_employee_name: "",
    to_employee_email: "",
    to_employee_address1: "",
    to_employee_address2: "",
    to_employee_zip_code: "",
    to_employee_phone: "",
    to_employee_website: "",
    to_employee_fax: "",
    to_employee_gst: "",
    to_employee_ntn: "",
    receipt_number: "",
    salary_date: "",
    term: "none",
    term_value: "",
    earnings: [
      {
        id: uuid(),
        earning_title: "",
        earning_amount: "0.00",
        state: "new",
        showSuggestion: false
      },
    ],
    deductions: [
      {
        id: uuid(),
        deduction_title: "",
        deduction_amount: "0.00",
        state: "new",
        showSuggestion: false
      },
    ],
    currency_selected: countriesCurrencies[0],
    total_earnings: "0",
    total_deductions: "0",
    total_net_pay: "0",
    ending_notes: "",
  }
  );

  useEffect(() => {
    if (countriesCurrencies) {
      const options = countriesCurrencies.map( currency=>({
        label: `${currency?.CountryName} ${currency?.Symbol}`,
        value: currency?.Code,
        image: currency?.Flag,
        type: 'currency',
        custom_value: currency
      }));
      
      setCurrencyOption(options);
    }
  }, []);


  const increaseInvoicePlan = (type)=>{
    const newPlanDetails = JSON.parse(JSON.stringify(payslipPlanDetail?.planDetails))
    newPlanDetails.plan_usage_details[type].usage_count += 1
    dispatch(setPlanDetails(newPlanDetails));
  }
  // ---------Modal START---------------------
  const handleOpenEmail = () => {
    if (payslipPlanDetail?.planDetails?.plan_usage_details?.email_sent?.usage_count<payslipPlanDetail?.planDetails?.plan_usage_details?.email_sent?.total) {
    setEmailModal(true);
    }
    else errorMessage("Limit Reached. Upgrade your plan!");
  }
  const handleCloseEmail = () => {
    setEmailModal(false);
    setEmailBody((prevState) => {
      return {
        ...prevState,
        id: "",
        email_to: "",
        cc_status: "false",
        cc_emails: ""
      }
    })
  }

  // ---------Modal END---------------------

  //  Get all templates
  useEffect(() => {
    setLoader(true);

    let allTemps = deepCopy(allTempStore.allTemplates);
    let filterTemps = allTemps?.filter(
      (temp) => temp?.template_type === "salary"
    );

    if (filterTemps.length) {
      setAllTemplates(filterTemps);
      // handleDefaultTemplate(filterTemps[0].id);
      setTimeout(() => {

        setLoader(false);
      }, 500);
    }

  }, [allTempStore])



  // data from company settings
  useEffect(() => {
    if (!props.updating) {
      setLoader(true);

      try {
        const companySetting = async () => {
          const { data, error } = await getCompanySetting();

          if (data) {

            if (data.success) {
              if (Object.keys(data.data).length) {
                let companySettings = deepCopy(data.data);
                setFormData((prevState) => {
                  return {
                    ...prevState,
                    // title: companySettings?.invoice_title,
                    currency_selected: JSON.parse(companySettings.currency),
                    from_name: companySettings?.company_name,
                    from_email: companySettings?.company_email,
                    from_address1: companySettings?.company_address1,
                    from_address2: companySettings?.company_address2,
                    from_zip_code: companySettings?.company_zip_code,
                    from_phone: companySettings?.company_phone,
                    from_fax: companySettings?.company_fax,
                    from_website: companySettings?.company_website,
                    from_gst: companySettings?.company_gst,
                    from_ntn: companySettings?.company_ntn,
                    // receipt_number: companySettings.invoice_number,
                    ending_notes: companySettings?.company_default_note,


                  }
                })
                // https://stagingapi.tradingforms.com/
                // http://localhost:4000/

                if (companySettings.company_logo !== "NULL" && companySettings.company_logo !== null) {

                  // let imgUrl = 'https://stagingapi.tradingforms.com/'
                  setLogoImage(`${imgUrl}${companySettings.company_logo}`);
                  setSalaryLogo(companySettings.company_logo);
                } else {
                  setLogoImage(null)
                }
              }
            }
            if (!data.success) {
              errorMessage(data?.message);
            }
            setLoader(false);

          }
          if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
            setLoader(false);
          }

        }

        companySetting();
      } catch (error) {

      }


    }
  }, [getCompanySetting, imgUrl, props.updating])

  // ------- unique number for Payslip------
  useEffect(() => {
    if (!props.updating) {
      (async () => {
        setLoader(true);
        try {
          const { data, error } = await getSalaryUniqueNumberQuery();
          if (data) {
            if (data.success) {
              let uniqueinvoice = deepCopy(data.data);
              if (uniqueinvoice !== "") {

                setFormData((prevState) => {
                  return {
                    ...prevState,
                    receipt_number: uniqueinvoice
                  }
                })
              }
              if (data.data.length === 0) {
                uniqueinvoice = "TFQUOT-0001";
                setFormData((prevState) => {
                  return {
                    ...prevState,
                    receipt_number: uniqueinvoice
                  }
                })
              }
            }
            if (!data.success) {
              errorMessage(data?.message)
            }

            setLoader(false);
          }
          if (error) {
            // console.log("Error in fetching unique invoice number", error);
            setLoader(false);
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");

          }
        } catch (error) {

        }

      })();

    }
  }, [props.updating, getSalaryUniqueNumberQuery]);



  // const handleDefaultTemplate = (value) => {
  //   // console.log(value);
  //   if (!props.updating && formData?.template_id === "") {

  //     setFormData((prevState) => {
  //       return { ...prevState, template_id: value };
  //     });
  //     // console.log("template",formData)
  //   }
  // }

  // =========Setting current date of payslip START ================
  useEffect(() => {
    if (!props.updating) {
      setFormData((prevState) => {
        return {
          ...prevState,
          salary_date: currentData()
        }
      })
    }
  }, [props.updating]);
  // =========Setting current date of payslip END ================

  // -------------- get single salary data -------------

  const singleData = (data) => {
    setShowForm(1);
    setTemplateSelect(false);
    let invoiceSalaryData = data.invoicesalarydata[0]; // array index NOT to be REMOVED. In response we are getting array if index to be removed then from in response there must be object instead of array
    let deductions = data.deductions;
    let earnings = data.earnings;
    let date = new Date(invoiceSalaryData.salary_date);
    deductions = deductions.map((item) => {
      return {
        state: "old",
        ...item,
      }
    })
    earnings = earnings.map((item) => {
      return {
        state: "old",
        ...item,
      }
    })

    var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    setPdfID(invoiceSalaryData?.suid_slsl_id);
    setFormData((prevState) => {
      return {
        ...prevState,
        id: invoiceSalaryData.id,
        // logo: invoiceSalaryData.logo,
        title: invoiceSalaryData.title,
        template_id: JSON.stringify(invoiceSalaryData.template_id),
        from_name: invoiceSalaryData.from_name,
        from_email: invoiceSalaryData.from_email,
        from_address1: invoiceSalaryData.from_address1,
        from_address2: invoiceSalaryData.from_address2,
        from_zip_code: invoiceSalaryData.from_zip_code,
        from_phone: invoiceSalaryData.from_phone,
        from_fax: invoiceSalaryData.from_fax,
        from_website: invoiceSalaryData.from_website,
        from_gst: invoiceSalaryData.from_gst,
        from_ntn: invoiceSalaryData.from_ntn,
        to_employee_name: invoiceSalaryData.to_employee_name,
        to_employee_email: invoiceSalaryData.to_employee_email,
        to_employee_address1: invoiceSalaryData.to_employee_address1,
        to_employee_address2: invoiceSalaryData.to_employee_address2,
        to_employee_zip_code: invoiceSalaryData.to_employee_zip_code,
        to_employee_phone: invoiceSalaryData.to_employee_phone,
        to_employee_fax: invoiceSalaryData.to_employee_fax,
        to_employee_website: invoiceSalaryData.to_employee_website,
        to_employee_gst: invoiceSalaryData.to_employee_gst,
        to_employee_ntn: invoiceSalaryData.to_employee_ntn,
        receipt_number: invoiceSalaryData.receipt_number,
        salary_date: dateString,
        term: invoiceSalaryData.term,
        term_value: invoiceSalaryData.term_value,
        earnings: earnings,
        deductions: deductions,
        currency_selected: JSON.parse(invoiceSalaryData.currency_selected),
        total_earnings: invoiceSalaryData.total_earnings,
        total_deductions: invoiceSalaryData.total_deductions,
        total_net_pay: invoiceSalaryData.total_net_pay,
        ending_notes: invoiceSalaryData.ending_notes,
        amount_in_words: invoiceSalaryData.amount_in_words,
      }
    }
    );
    setEmailBody((prevState) => {
      return ({
        ...prevState,
        id: urlId,
        email_to: invoiceSalaryData.to_employee_email,
        email_body: `Kindly find the attached PaySlip.`
      })
    })
    // http://35.172.214.163:4000/
    // http://localhost:4000/
    if (invoiceSalaryData.logo !== null && invoiceSalaryData.logo !== "NULL" && invoiceSalaryData.logo !== "null") {

      setLogoImage(`${imgUrl}${invoiceSalaryData.logo}`);
      setSalaryLogo(invoiceSalaryData.logo);
    } else {
      setLogoImage(null)
    }
  }
  

  useEffect(() => {
    if (props.updating) {
      setLoader(true);
      setTemplateSelect(false);
      (async () => {
        const { data, error } = await getSingleSalarySlip(urlId);
        if (data) {
          if (data.success) {
            singleData(data);
          }
          if (!data.success) {
            errorMessage(data?.message);
          }

          setLoader(false);
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
          setLoader(false);
        }
      })();

    }
  }, [urlId, props.updating, getSingleSalarySlip, imgUrl]);


  // =========== Copy to clipboard  START==============
  const copyToClip = async () => {
    try {

      let url = `${LARAVELurl}salary_invoice_preview?id=${pdfId}`
      await navigator.clipboard.writeText(url);
      successMessage("Shareable Link Copied To Clipboard!");
    } catch (error) {
    }
  }
  // =========== Copy to clipboard END==============


  const handleBlur = (e) => {
    e.preventDefault();
    // console.log(e.target.name);
    // console.log(e.target.value);

    if (e.target.name === "currency_selected") {
      // console.log(JSON.parse(e.target.value));
      setFormData((prevState) => {
        return { ...prevState, [e.target.name]: JSON.parse(e.target.value) };
      });
    }
    //  if (e.target.name === "salary_date") {
    //   let value = e.target.value;
    //     if (formData?.term !== "none") {
    //       let date = new Date(value);

    //     let addedDays =  date.addDays(Number(formData?.term));       
    //     // let final = date.toISOString(addedDays);
    //     let final = addedDays;
    //      let conver = new Date(final);
    //       // console.log("conver>>",conver);
    //     let salaryDate = new Date(
    //       conver.getTime() - conver.getTimezoneOffset() * 60000
    //     )
    //       .toISOString()
    //       .split("T")[0];


    // // console.log(date.toISOString(addedDays),"<<<salaryDate>>>",salaryDate);
    //   setFormData((prevState)=>{
    //     return {...prevState, salary_date: e.target.value, term_value: salaryDate }
    //   })
    //     }else{
    //       setFormData((prevState)=>{
    //         return {...prevState, salary_date: e.target.value, term_value: "" }
    //       })
    //     }

    // }
    // if (e.target.name === "term") {
    //   let termsinput = e.target.value;
    //   // console.log("select-termsinput>>",termsinput);
    //   if (termsinput !== "none") {
    //     let date = new Date(formData?.salary_date);
    //   let addedDays = date.addDays(Number(termsinput));
    //   // console.log("select-addedDays>",addedDays);
    //   let final = date.toISOString(addedDays);
    //   // console.log("select-final>>>>",final);
    //       let conver = new Date(final);

    //       let salaryDate = new Date(
    //         conver.getTime() - conver.getTimezoneOffset() * 60000
    //       )
    //         .toISOString()
    //         .split("T")[0];

    //         // console.log("salaryDate",salaryDate);

    //   setFormData((prevState)=>{
    //     return {...prevState, term:termsinput , term_value: salaryDate }
    //   });
    //   }else{
    //     setFormData((prevState)=>{
    //     return {...prevState, term:termsinput , term_value: "0" }
    //   });
    //   }

    // }
    if (e.target.name !== "currency_selected" && e.target.name !== "term_value") {
      if (e.target.name === "to_employee_name") {

        e.target.value.trim() ? setShowSuggest(true) : setShowSuggest(false);
      }
      setFormData((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      });
    }

    // console.log(formData[e.target.name]);
  };

  const handleOnChangeSelect= (selected) => {
    setFormData((prevState) => {
      return { ...prevState, currency_selected: selected.custom_value };
    });

}
  const handleSelectTemplate = (value) => {
    setFormData((prevState) => {
      return { ...prevState, template_id: `${value}` };
    });
  }

  // handle phone/fax numbers fields 
  const handleTeleFields = (name, value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    });
  }

  // remove image from front site and also empty the state which is being added in formData while submiting form
  const removeImage = async () => {
    setLogoImage(null);
    setSalaryLogo(null);
    if (props.updating) {
      try {
        setDeleteLoader(true);
        const { data, error } = await deleteSingleSalaryLogo(urlId);
        if (data) {
          if (data.success) {
            successMessage(data.message);
          }
          if (!data.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
        }
      } catch (error) {
      }
      finally {
        setDeleteLoader(false);
      }

    }
  }
  // add image in states to display image on personal detail logo and also updating the state which is being added in formData while submiting form
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {

      setSalaryLogo(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setLogoImage(reader.result);
      };
    }

  };

  function handleAddEarning(earningItem) {
    setFormData((prevState) => {
      return {
        ...prevState,
        earnings: [...prevState.earnings, earningItem],
      };
    });
  }

  function handleAddDeduction(deductionItem) {
    setFormData((prevState) => {
      return {
        ...prevState,
        deductions: [...prevState.deductions, deductionItem],
      };
    });
  }

  // =========calculations=========
  //for Earning amount  calculations
  const [total, setTotalEarnings] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);

  useEffect(() => {
    let total = 0;
    formData?.earnings.forEach((earnings) => {
      total += Number(earnings?.earning_amount);
    });
    let totalDeductions = 0;
    formData?.deductions.forEach((deductions) => {
      totalDeductions += Number(deductions?.deduction_amount);
    });
    setTotalDeductions(JSON.stringify(totalDeductions));
    setTotalEarnings(JSON.stringify(total));
    setFormData((prevState) => {
      return {
        ...prevState,
        total_earnings: total,
        total_deductions: totalDeductions,
        total_net_pay: total - totalDeductions,
      }
    })
  }, [formData?.earnings, formData?.deductions]);

  //for deduction amount  calculations

  const calculateTotals = (newFormData) => {
    let totalearn = 0;
    newFormData?.earnings.forEach((earnings) => {
      totalearn += Number(earnings?.earning_amount);
    });
    let totalDeductions = 0;
    newFormData?.deductions.forEach((deductions) => {
      totalDeductions += Number(deductions?.deduction_amount);
    });

    return {
      total_earnings: totalearn,
      total_deductions: totalDeductions,
      total_net_pay: totalearn - totalDeductions,
    }

  }

  async function handleRemoveDescription(id, type) {
    try {

      handleHideSuggestion(id, type);
      // if (formData?.[type].length>1) {
      //   console.log(`${type} -> ${formData?.[type].length}`);
      // }
      // if (formData?.[type].length===1) {
      //   console.log(`${type} -> ${formData?.[type].length}`)
      //   errorMessage("Can't be deleted. At least one record must be present.");
      // }

      let deleted = formData[type].filter((element) => element.id === id);

      const updatedFormData = {
        ...formData,
        [type]: formData[type].filter((element) => element.id !== id),
      };
      setFormData(updatedFormData);
      if (type === "earnings") {
        if (deleted[0].state === "old") {
          try {
            setDeleteLoader(true);
            const updatedCalculation = calculateTotals(updatedFormData);
            const body = {
              id,
              payslipid: urlId,
              total_earnings: updatedCalculation?.total_earnings,
              total_net_pay: updatedCalculation?.total_net_pay,
            }
            const { data, error } = await deleteSalarySingleEarning(body);
            if (data) {
              if (data.success) {
                successMessage(data.message);
              }
              if (!data.success) {
                errorMessage(data.message);
              }
            }
            if (error) {
              errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
            }

          } catch (error) {

          }
          finally {
            setDeleteLoader(false);
          }
        }

      }
      if (type === "deductions") {
        if (deleted[0].state === "old") {
          try {
            setDeleteLoader(true);
            const updatedCalculation = calculateTotals(updatedFormData);
            const body = {
              id,
              payslipid: urlId,
              total_deductions: updatedCalculation?.total_deductions,
              total_net_pay: updatedCalculation?.total_net_pay,
            }
            const { data, error } = await deleteSalarySingleDeduction(body);
            if (data) {
              if (data.success) {
                successMessage(data.message);
              }
              if (!data.success) {
                errorMessage(data.message);
              }
            }
            if (error) {
              errorMessage(error.data.message);
            }
          } catch (error) {
          }
          finally {
            setDeleteLoader(false);
          }

        }
      }
    } catch (error) {
    }


  }
  // ======================================
  function handleEditDescription(e, id, type) {
    // console.log(e , "-",id,"-",type);
    setFormData((prevState) => {
      let newState = deepCopy(prevState);
      let oldDesc;
      if (type === "earnings") {
        oldDesc = newState.earnings.find((desc) => desc.id === id);
        if (oldDesc) {
          // console.log("oldDesc>>",oldDesc)
          if (e.target.name === "earning_title") {
            e.target.value.trim() ? oldDesc.showSuggestion = true : oldDesc.showSuggestion = false;
            e.target.value.trim() ? setShowSuggestEarn(true) : setShowSuggestEarn(false);

          }
          oldDesc[e.target.name] = e.target.value;
        }
      }
      if (type === "deductions") {
        oldDesc = newState.deductions.find((desc) => desc.id === id);
        if (oldDesc) {
          if (e.target.name === "deduction_title") {

            //  console.log("oldDesc>>",oldDesc)
            e.target.value.trim() ? oldDesc.showSuggestion = true : oldDesc.showSuggestion = false;
            // e.target.value.trim() ? setShowSuggestDeduct(true) : setShowSuggestDeduct(false);

          }
          oldDesc[e.target.name] = e.target.value;
          // console.log(oldDesc[e.target.name] , e.target.value)


        }
      }
      return newState;
    });
    // console.log(formData);
  }
  // handle change for deductions

  function handleEditDescriptionDeductions(e, id) {
    setFormData((prevState) => {
      let newState = deepCopy(prevState);
      let oldDesc = newState.deductions.find(desc => desc.id === id);

      if (oldDesc) {
        e.target.value.trim() ? oldDesc.showSuggestion = true : oldDesc.showSuggestion = false;
        oldDesc[e.target.name] = e.target.value
      }

      return newState

    });
  }


  const handleCloseSelectTemplate = useCallback(() => {
    setTemplateSelect(false);
    // console.log(formData)
  }, []);

  // const handleModalOpen = useCallback(() => {
  //   setIsOpen(true);
  // }, []);

  const handleOpenSelectTemplate = useCallback(() => {
    setTemplateSelect(true);
  }, []);



  // ==================EXTRA END ======================

  const [createSalarySlip] = useCreateSalarySlipMutation();
  const [updateSalarSlip] = useUpdateSalarSlipMutation();

  const handleSubmit = async () => {


    if (props.updating) {
      try {
        let updateSalary = new FormData();
        let body = {
          ...formData,
          earnings: formData.earnings.map((item) => {
            return (item.state === "new" ? {
              // id: item.id,
              earning_title: item.earning_title,
              earning_amount: item.earning_amount,
            } : {
              id: item.id,
              earning_title: item.earning_title,
              earning_amount: item.earning_amount,
            })
          }),
          deductions: formData.deductions.map((item) => {
            return (item.state === "new" ? {
              // id: item.id,
              deduction_title: item.deduction_title,
              deduction_amount: item.deduction_amount,
            } : {
              id: item.id,
              deduction_title: item.deduction_title,
              deduction_amount: item.deduction_amount,
            })
          }),
          currency_selected: JSON.stringify(formData.currency_selected),
          // logo: salaryLogo,
        };

        if (salaryLogo) {
          updateSalary.append('logo', salaryLogo);
        }
        updateSalary.append('data', JSON.stringify(body));

        const resp = await updateSalarSlip({ id: urlId, updateSalary });

        if (resp.data) {
          if (resp.data.success) {
            // navigate('/salary-slips');
            // console.log("response", resp?.data);
            singleData(resp?.data);
            successMessage(resp?.data?.message);

          }
          if (!resp?.data?.success) {
            errorMessage(resp?.data?.message);
            setPostLoader(false);
          }


        }
        if (resp?.error) {
          errorMessage(resp?.error?.data?.message);
          setPostLoader(false);
        }
      }
      catch { }
      finally{setPostLoader(false)}


    } else {
      try {
        setPostLoader(true);
        let saveSalarySlip = new FormData();
        let body = {
          ...formData,
          earnings: formData?.earnings.map((item) => ({
            earning_title: item?.earning_title,
            earning_amount: item?.earning_amount,
          })),
          deductions: formData?.deductions.map((item) => ({
            deduction_title: item?.deduction_title,
            deduction_amount: item?.deduction_amount,
          })),
          currency_selected: JSON.stringify(formData?.currency_selected),
          // logo: salaryLogo,
        };
        if (salaryLogo) {
          saveSalarySlip.append('logo', salaryLogo);
        }

        saveSalarySlip.append('data', JSON.stringify(body));

        const resp = await createSalarySlip(saveSalarySlip);

        if (resp?.data) {
          if (resp?.data?.success) {
            successMessage(resp?.data?.message);
            setPostLoader(false);
            setTimeout(() => {
              increaseInvoicePlan("single_payslip");
            }, 10);
            setTimeout(() => {
              
            }, 10);
            navigate('/salary-slips');
          }
          if (!resp?.data?.success) {
            errorMessage(resp?.data?.message);
            setPostLoader(false);
          }
        }
        if (resp?.error) {
          errorMessage(resp?.error?.message ?? resp?.error?.error ?? resp?.error?.data?.error?.message ?? resp?.error.data?.message ?? "Server not responding.");
          setPostLoader(false);
        }
      } catch (error) {
      }

    }
  };

  const confirmSave = (type) => {

    if (type === true) {
      setPostLoader(true);
      setIsConfirmation(false);
      handleSubmit();
    }
    if (type === false) {
      setIsConfirmation(false);
    }
  }

  const saveModal = (e) => {
    e.preventDefault();
    setIsConfirmation(true);
  }
  //=========Name Suggestion START========== 
  const [suggestList, setSuggestList] = useState([]);

  const autoSuggstion = async (e) => {
    e.preventDefault();
    try {
      let search = e.target.value;
      if (search !== "" || search !== " ") {
        const { data, error } = await clientsNameSearch(search);
        if (data) {
          if (data?.success) {
            let list = deepCopy(data.data);
            setSuggestList(list);
          }
          if (!data?.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
        }
      }

    } catch (error) {

    }
  };


  const handleHideSuggestion = (rowId, type) => {
    try {
      if (type === "earnings") {
        // console.log("earnings>filter", formData.earnings.filter((earn)=>earn.id === rowId));
        if (formData.earnings.find((earn) => earn.id === rowId && earn.showSuggestion === true)) {
          setFormData((prevState) => {
            let newState = deepCopy(prevState);
            let oldDesc = newState.earnings.find((desc) => desc.id === rowId);
            if (!!oldDesc) {
              oldDesc.showSuggestion = false;
            }

            return newState;
          });
        }
      }

      if (type === "deductions") {
        if (formData?.deductions.find((deduct) => deduct?.id === rowId && deduct?.showSuggestion === true)) {
          setFormData((prevState) => {
            let newState = deepCopy(prevState);
            let oldDesc = newState.deductions.find((desc) => desc.id === rowId);
            if (!!oldDesc) {
              oldDesc.showSuggestion = false;
            }
            return newState;
          });
        }

      }
    } catch (error) {
    }

  }

  const fillFields = async (name) => {
    // e.preventDefault();
    try {
      let search = name;


      const { data, error } = await getClientDataById(search);
      if (data) {
        if (data?.success) {
          let list = deepCopy(data.data[0]);
          setFormData((prevState) => ({
            ...prevState,
            to_employee_name: "",
          }));

          let entity_name = list.entity_name || "";
          let entity_city = list.entity_city + ", " + list.entity_state || "";
          let entity_email = list.entity_email || "";
          let entity_fax = list.entity_fax || "";
          let entity_gst = list.entity_gst || "";
          let entity_ntn = list.entity_ntn || "";
          let entity_phone_official = list.entity_phone_official || "";
          let entity_address = list.entity_address || "";
          let entity_website = list.entity_website || "";

          // console.log('entity_address',entity_name)
          let entity_zipCode = list.entity_zip_code || "";
          setFormData((prevState) => ({
            ...prevState,
            to_employee_name: entity_name,
            to_employee_address2: entity_city,
            to_employee_email: entity_email,
            to_employee_fax: entity_fax,
            to_employee_gst: entity_gst,
            to_employee_ntn: entity_ntn,
            to_employee_phone: entity_phone_official,
            to_employee_address1: entity_address,
            to_employee_zip_code: entity_zipCode,
            to_employee_website: entity_website,
          })
          );
          setShowSuggest(false);
        }
        if (!data.success) {
          errorMessage(data?.message);
        }
      }
      if (error) {
        errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
      }
    } catch (error) {

    }
  }
  //=========Name Suggestion END=================== 

  // ==========Earning Suggetion START===============
  const [allEarningItems, setAllEarningItems] = useState([]);
  const [allDeductionitems, setAllDeductionItems] = useState([]);


  const autoSuggstionitems = async (e, type) => {
    let search = e.target.value;

    if (type === "earnings") {
      if (search) {

        try {
          const { data, error } = await earnNameSearch(search);
          if (data) {
            if (data?.success) {
              let list = deepCopy(data.data);
              setAllEarningItems(list);
            }
            if (!data.success) {
              errorMessage(data?.message);
            }
          }

          if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
          }

        } catch (error) {

        }


      }

    }
    if (type === "deductions") {
      try {
        const allData = await deductionNameSearch(search);
        const { data, error } = allData;
        if (data) {
          if (data?.success) {
            let list = deepCopy(data.data);
            setAllDeductionItems(list);
          }
          if (!data?.success) {
            errorMessage(data.message);
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
        }
      } catch (error) {

      }

    }

  }
  //  autoSuggstionitems();
  const fillItemFields = (e, rowId, type, value) => {
    e.preventDefault();
    try {
      setFormData((prevState) => {
        let newState = deepCopy(prevState);
        let oldDesc;
        if (type === "earnings") {
          oldDesc = newState.earnings.find((desc) => desc.id === rowId);
          if (oldDesc) {

            oldDesc.earning_title = value;
          }
        } else if (type === "deductions") {
          oldDesc = newState.deductions.find((desc) => desc.id === rowId);
          if (oldDesc) {

            oldDesc.deduction_title = value;
          }
        }
        return newState;
      });
      if (type === "earnings") {
        setShowSuggestEarn(false);
      }
      if (type === "deductions") {
        setShowSuggestDeduct(false);
      }

    } catch (error) {

    }

    //  setFormData((prevState) => {
    //    let newState = deepCopy(prevState);
    //    let oldDesc = newState.description.find((desc) => desc.id === rowId);
    //    // console.log("Setitems>>",item)
    //    // console.log(oldDesc);
    //    if (oldDesc) {
    //     //  oldDesc.itemDescription = item.item_name;
    //     //  oldDesc.additionalDetail = item.description;
    //     //  oldDesc.rate = item.item_rate;
    //     //  oldDesc.tax = item.item_texable ==="yes" ? true:false;
    //      // console.log("UpdateoldDesc",oldDesc)
    //    }

    //    // if (e.target.name === "rate" || e.target.name === "quantity") {
    //    //   // newState.subTotal = asdsa
    //    //   // newState.total = asdsa
    //    //   let amount = (
    //    //     Number(oldDesc?.quantity) * Number(oldDesc?.rate)
    //    //   ).toFixed(2);
    //    //   oldDesc["amount"] = amount;
    //    // }
    //    // console.log("formData.description",formData.description)
    //    return newState;
    //  });
    //  console.log(formData.earnings)

  }


  // ==========Earning Suggetion END=================


  // ================email=======================
  const emailChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "cc_status" & value === "false") {
      setEmailBody((prevState) => {
        return ({
          ...prevState, cc_emails: ""
        })
      })
    }
    setEmailBody((prevState) => {
      return ({
        ...prevState, [name]: value
      })
    })
    // console.log(emailBody);

  }

  const handleEmail = async (e) => {
    try {
      e.preventDefault();
      setEmailbuttonLoader(true);
      let id = urlId;

      let body = {
        id,
        email_to: emailBody?.email_to,
        email_body: emailBody?.email_body,
        attachment: emailBody?.attachment,
        cc_status: emailBody?.cc_status,
        cc_emails: emailBody?.cc_emails,
        type: "salary"
      }
      // https://stagingfront.tradingforms.com/api/invoice_email?id=59
      // console.log(body);
      let token = localStorage.getItem("tradingformtoken");
      const { data, error } = await axios(`${LARAVELurl}salary_invoice_email`, {
        method: "POST",
        data: body,
        headers: {
          "Content-Type": "application/json;",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      if (data) {
        if (data.success) {
          // console.log("success>",data);
          setEmailModal(false);
          setEmailbuttonLoader(false);
          successMessage(data?.message);
          increaseInvoicePlan("email_sent");
          setEmailBody((prevState) => {
            return {
              ...prevState,
              attachment: "true",
              cc_status: "false",
              cc_emails: ""
            }
          })
        }
        if (!data.success) {
          // console.log("!success>",data);
          // setEmailModal(false);
          setEmailbuttonLoader(false);
          errorMessage(data?.message);
        }
      }
      if (error) {
        errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.");
        setEmailbuttonLoader(false);
      }
    } catch (error) {
    } finally {
      setEmailbuttonLoader(false);
    }

  }

  return (
    <>
      {loader &&
        <CustomDeleteloader message="Loading . . ." />
      }
      {deleteLoader &&
        <CustomDeleteloader message="Deleting . . ." />
      }
      {postLoader &&
        <CustomDeleteloader message={`${props.updating ? 'Updating ' : 'Adding '}. . .`} />
      }
      <div className="spacer"></div>
      <div className="container">
        <div className="col-12 invoice-detail salary-slip-details ">
          <div className="col-lg-11 col-xl-10 col-11">
            <div className="breadcrumb-container">

              <nav className="breadcrumbs">
                <Link to="/" className="breadcrumbs__item" title="Go To Dashboard">Dashboard</Link>
                <Link to="/salary-slips" className="breadcrumbs__item" title="Go To payslip">Payslips</Link>
                {/* <Link to="" className="breadcrumbs__item">Cart</Link> */}
                <span className="breadcrumbs__item is-active"> {props.updating ? "Edit" : "Create"} </span>
              </nav>  <div className="spacer"></div>
            </div>
          </div>
          <div className="col-lg-11 col-xl-10 col-11">
            <div className="invoice-actions">
              <div className="invoice-navBtn">
                {props.updating &&
                  <span>
                    <button
                      type="button"
                      onClick={() => setShowForm(1)}
                      className={`btn btn-secondary btn-sm me-1 ${showForm === 1 ? 'active cursor-disable' : ''}`}
                    title="Click To Preview"
                    >
                      {" "}
                      <i className="far fa-eye"></i><span>Preview</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowForm(0)}
                      className={`btn btn-secondary btn-sm me-1 ${showForm === 0 ? 'active cursor-disable' : ''}`}
                      title="Click To Edit"
                    >
                      <i className="fas fa-edit"></i><span>Edit</span>
                    </button>
                  </span>
                }
                {showForm === 0 && (
                  <button
                    type="button"
                    onClick={handleOpenSelectTemplate}
                    className="btn btn-primary btn-sm"
                    title="Click to select Template"
                  >
                    <i className="fa fa-file-invoice"></i><span>Select Template</span>
                  </button>)
                }

                <div style={{'width':'185px'}}>
                  <SelectImg
                    options={currencyOption}
                    handleOnChangeSelect={handleOnChangeSelect}
                    defaultSelect={JSON.stringify(formData?.currency_selected?.Code)}
                  /> 

                </div>

                {/* <select
                  className="btn text-start"
                  name="currency_selected"
                  onChange={handleBlur}
                  value={JSON.stringify(formData?.currency_selected)}
                >
                  {currencies.map((currency, index) => (
                    <option key={index} value={JSON.stringify(currency)}>
                      {currency.CountryName} {currency.Symbol}
                    </option>
                  ))}
                </select> */}
              </div>
              {props.updating &&
                <div className="invoice-actions-export">
                  {/* <button
                    type="button"
                    className="btn btn-sm"
                    onClick={handleModalOpen}
                  >
                    Record Payment
                  </button> */}
                  <Link className="btn btn-sm button-pdf" to={`${LARAVELurl}salary_invoice_preview?id=${pdfId}`} target="_blank" title="Click to view PDF">
                    <i className="fa fa-file-pdf"></i><span>PDF</span>
                  </Link>
                  <button type="button" className="btn btn-sm button-copy-url" onClick={copyToClip} title="Click to copy shareable link.">
                    <i className="fa fa-copy"></i><span>Copy URL</span></button>
                  <button type="button" className="btn btn-primary btn-sm button-email-invoice" onClick={handleOpenEmail} title="Click to send an email">
                    <i className="fa fa-envelope"></i><span>Email Payslip </span>
                  </button>
                </div>
              }
            </div>
          </div>
          <div className="row justify-content-center" style={{ width: "100%" }}>
            <div className="col-lg-11 col-xl-10 px-0">
              <div className="new-edit-invoice-container">
                {showForm === 1 && (
                  <div data-aos="fade-right">
                    <Preview
                      formData={formData}
                      logoImage={logoImage}
                      total={total}
                      totalDeductions={totalDeductions}
                    //  selectedCurrency={selectedCurrency}
                    />

                  </div>
                )}
                {showForm === 0 && (
                  <div data-aos="fade-left">
                    <Edit
                      handleBlur={handleBlur}
                      formData={formData}
                      handleAddEarning={handleAddEarning}
                      handleAddDeduction={handleAddDeduction}
                      handleRemoveDescription={handleRemoveDescription}
                      handleChange={handleEditDescription}
                      handleChange2={handleEditDescriptionDeductions}
                      total={total}
                      totalDeductions={totalDeductions}
                      // selectedCurrency={selectedCurrency}
                      // handleSubmit={handleSubmit}
                      saveModal={saveModal}
                      handleFileInputChange={handleFileInputChange}
                      logoImage={logoImage}
                      removeImage={removeImage}
                      fillFields={fillFields}
                      autoSuggstion={autoSuggstion}
                      suggestList={suggestList}

                      autoSuggstionitems={autoSuggstionitems}
                      allEarningItems={allEarningItems}
                      allDeductionitems={allDeductionitems}
                      fillItemFields={fillItemFields}
                      update={urlId}
                      showSuggest={showSuggest}
                      setShowSuggest={setShowSuggest}
                      showSuggestEarn={showSuggestEarn}
                      setShowSuggestEarn={setShowSuggestEarn}
                      showSuggestDeduct={showSuggestDeduct}
                      setShowSuggestDecuct={setShowSuggestDeduct}
                      handleHideSuggestion={handleHideSuggestion}
                      readonlyFrom={false}
                      handleTeleFields={handleTeleFields}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-12 col-lg-4 pt-5">
            <TaxationDiscount handleBlur={handleBlur} formData={formData}/>
          </div> */}
          </div>
        </div>

        <div className="spacer"></div>
      </div>
      {/* <button onClick={() => setIsOpen(true)}>
          Click to Open Modal
        </button> */}

      <ReactPortal>
        {templateSelect && (
          <Modal
            handleClose={handleCloseSelectTemplate}
            header="Welcome to the <span className='pos-rel'>TradingForms</span>. Join happy small business."
            type="template"
            contentType="notscrollable"
          >
            <SelectInvoiceTemplate
              formData={formData}
              handleBlur={handleSelectTemplate}
              // handleDefaultTemplate={handleDefaultTemplate}
              handleClose={handleCloseSelectTemplate}
              filteredTemps={allTemplates}
            />
          </Modal>
        )}
      </ReactPortal>
      {/* <ReactPortal>
        {isOpen && (
          <Modal
            handleClose={handleModalClose}
            header="Record Payment"
            type="form"
          >
            <InvoicePaymentRecord
              handleDeletePayment={handleDeletePayment}
              handleAddPayment={handleAddPayment}
              handleModalClose={handleModalClose}
              formData={formData}
            />
          </Modal>
        )}
      </ReactPortal> */}
      <ReactPortal>
        {emailModal &&
          <Modal handleClose={handleCloseEmail} type="form" contentType="email-form" header="Enter details to send mail.">
            <SendEmail emailBody={emailBody} handleSubmit={handleEmail} emailChange={emailChange} loader={emailbuttonLoader} />
          </Modal>
        }
      </ReactPortal>
      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon="save text-info"
            header=""
            type="confirm"
            recordName={`Are you sure you want to ${props.updating ? "Update" : "Save"}`}
          >
            <button className="btn btn-save m-1 col-xs-12 col-sm-6 col-md-3" onClick={() => confirmSave(true)}> Yes</button>
            <button className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3" onClick={() => confirmSave(false)}>No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
    </>
  );
}

export default CreateSalaryslip;
