import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AllClients from "./Clients/Allclients";
import Outstandings from "./Clients/Outstandings";
import Paid from "./Clients/Paid";
import Company from "./Clients/Company";
import {
  useDeleteEntitiesSettingsMutation,
  useEntitiesSettingsAllQuery,
} from "./store/apis/baseApi";
// import { ThreeCircles } from "react-loader-spinner";
// import CustomLoader from "./components/CustomLoader";

import { errorMessage, successMessage } from "./components/toastMessages/ToastifyMsg";
import CustomDeleteloader from "./components/CustomDeleteloader";
import ReactPortal from "./components/reactPortal/ReactPortal";
import Modal from "./components/modal/Modal";
import { deepCopy } from "./utils/utils";
import { useSelector } from "react-redux";

function Clients() {
  const [showInvoice, setShowInvoice] = useState(1);
  const { data, error, isLoading } = useEntitiesSettingsAllQuery();
  const [tableLoader,setTableLoader] = useState(true);
  const [deleteEntitiesSettings] = useDeleteEntitiesSettingsMutation();
  const [allClients, setAllClients] = useState([]);
  const navigate = useNavigate();
  const [loader,setLoader]= useState(false);
  const [isConfirmation,setIsConfirmation] = useState(false);
  const [deleteId,setDeleteId] =useState();
  const [deleteModalClient, setDeleteModalClient] = useState(false);

  const consumersPlanDetail = useSelector(state=>state.planDetails);

useEffect(() => {
setTimeout(() => {
  setTableLoader(isLoading);
}, 500);

}, [isLoading])

  useEffect(() => {
    try {
      if (data) {
        // console.log(data);
        if (data.success) {
          let allClientData = deepCopy(data.data);
          setAllClients(allClientData);
        }
        if(!data.success){
          setAllClients([]);
        }
      }
      if (error) {
        // console.log("ERROR:", error?.data?.error?.message);
        errorMessage(error?.error  ?? error?.data?.error?.message);
      }
      
    } catch (error) {
      // console.log("catchErrorAllCon")
    }
  }, [data, error]);


  const confirmation = (id,name)=>{
   
    // deleteInvoice(id);
     setDeleteModalClient(name);
    setDeleteId(id);
    setIsConfirmation(true);
  }


  const deleteEntity = async (value) => {
    if (value === true) {
      setIsConfirmation(false);
      setLoader(true);
      try {
        const { data, error } = await deleteEntitiesSettings(deleteId);
        // console.log("Data", data);
        // console.log("ERROR:", error);
        navigate("/clients");
        
    
        if (data) {
          if (data.success) {
            // console.log("DataSuccess", data);
            successMessage(data.message);
            setLoader(false);
          }
          if (!data.success) {
            errorMessage(data.message);
            setLoader(false);
          }
        }
    
        if (error) {
          // console.log("ERROR:", error.data);
          errorMessage(error?.error ?? error.data.message);
          setLoader(false);
        }
        
      } catch (error) {
        
      }
    }else{
      setIsConfirmation(false);
    }
  };
 //!data && !error ? (
  //   <CustomLoader/>
    
  // ) :


  const handleCreateNavigate=() => {
    if (consumersPlanDetail?.planDetails?.plan_usage_details?.consumers?.usage_count<consumersPlanDetail?.planDetails?.plan_usage_details?.consumers?.total) {
      navigate('/clients/create');
    }else {
      errorMessage("Limit Reached. Upgrade your plan!");
      navigate('/subscription-plans');
    } 
  }
  return(
    <>
     {loader ===true &&
      <CustomDeleteloader message="Deleting . . ."/>
    }
    <div className="spacer"></div>
    {/* <div className="spacer"></div> */}
      <div className="invoice-container">
        <div className="container invoice-list" data-aos="zoom-in">
          <div className="invoice-actions">
            <div className="invoice-navBtn">
              <button
                onClick={() => setShowInvoice(1)}
                className={`btn btn-secondary btn-sm ${showInvoice===1? 'active cursor-disable':''}` }
                type="button"
              >
                All Consumers
              </button>
              <button
                onClick={() => setShowInvoice(2)}
                className={`btn btn-secondary btn-sm ${showInvoice===2? 'active cursor-disable':''}` }
                type="button"
              >
                Clients
              </button>
              <button
                onClick={() => setShowInvoice(3)}
                className={`btn btn-secondary btn-sm ${showInvoice===3? 'active cursor-disable':''}` }
                type="button"
              >
                Employees
              </button>
              <button
                onClick={() => setShowInvoice(4)}
                className={`btn btn-secondary btn-sm ${showInvoice===4? 'active cursor-disable':''}` }
                type="button"
              >
                Companies
              </button>
            </div>
            <div className="searchCreate-invoice">
              {/* <div className="invoice-searchContainer col-xs-12 col-sm-9 col-md-7">
                        <input type="text" name=""  placeholder="Search By Client Name"></input>
                    </div> */}
              <div className="createInvoice col-xs-12 col-sm-3 col-md-5">
                <button className="btn btn-primary d-flex align-items-center justify-content-center gap-2" onClick={handleCreateNavigate}>
                  <i className="fa fa-plus"></i> <span>Add Consumer</span>
                </button>
              </div>
            </div>
          </div>

          <div className="container px-0 overflow-hidden">
            {/*Shows all records*/}
            {showInvoice === 1 && (
              <AllClients allClient={allClients} deleteEntity={confirmation} isLoading={tableLoader}/>
            )}{" "}
              {/*Shows all type==clients*/}
            {showInvoice === 2 && (
              <Outstandings
                allClient={allClients}
                deleteEntity={confirmation}
                isLoading={tableLoader}
              />
              )}{" "}
              {/*Shows all type==Employee*/}
            {showInvoice === 3 && (
              <Paid allClient={allClients} deleteEntity={confirmation} isLoading={tableLoader}/>
              )}{" "}
              {/*  Shows all type==Company */}
            {showInvoice === 4 && (
              <Company allClient={allClients} deleteEntity={confirmation} isLoading={tableLoader}/>
            )}{" "}
          </div>
          {/* <div className="invoice-list-footer">
            <span>All your invoices are auto saved here</span>
          </div> */}
        </div>
      </div>
      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon={"trash-alt text-danger"}
            header=""
            type="confirm"
            recordName={`Are you sure you want to delete this consumer ${deleteModalClient}`}
          >
            <button className="btn btn-danger m-1 col-md-3 col-12" onClick={()=>deleteEntity(true)} title="Yes, delete Consumer"> Yes</button>
            <button className="btn btn-secondary m-1 col-md-3 col-12" onClick={()=>deleteEntity(false)} title="Do not delete Consumer">No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
    </>
  );
}

export default Clients;
