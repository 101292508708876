import moment from "moment/moment";
import React from "react"
import GalleryImages from "./GalleryImages";
import { separateComma } from "../../utils/utils";

function Preview({formData,logoImage,footerImagesCol,type}) {

  // console.log( moment(moment(formData?.invoicedate).add(formData?.terms, "days")).format("ll"));
  
  return (
    <>
      <div className="spacer"></div>
      <div className="spacer"></div>
      <section>
        <div className="container px-0">
          <div className="col-12">
            <div className="previewInvoiceContainer" style={{"borderTop": `4px solid ${formData?.color}` }}>
                  <div className="previewHeader">
                    <div className="FromHeader">
                      
                      <div className="col-12 col-md-6 fromDetailsContainer">
                        <div className="fromDetails">
                            { logoImage &&
                              <div className="preview-logo-container">
                                <img width={100} max-height={100} height={"auto"} src={logoImage} alt="company_logo" />
                              </div>
                            }
                            <div>
                              <h3 className="my-3">{formData?.senderName}</h3>
                              <div>
                               {formData?.senderEmail &&  
                                   formData?.senderEmail
                               } 
                                </div>
                              <div>
                              { formData?.senderPhone.length >14 &&
                                <span> Phone Number : {formData?.senderPhone}</span>
                              }
                              </div>
                              <div>
                                    {  formData?.senderFax.length > 14  &&
                                      <span> Fax :  {formData?.senderFax}</span>
                                    }
                                  </div>
                              <div>
                                {formData?.senderStreet &&
                                  formData?.senderStreet
                                }
                              </div>
                              <div>
                                {formData?.senderCityState &&
                                  formData?.senderCityState
                                }
                              </div>
                              <div>
                                { formData?.senderZipCode &&
                                  formData?.senderZipCode
                                }
                              </div>
                              
                            </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 invoiceDetailsContainer">
                        <div className="invoiceDetails">
                          <div className="col-sm-6 col-md-12 col-lg-12 col-xl-12 invoice">
                            
                                <div className="col-12"><b className="text-uppercase">{formData?.invoiceHeader}</b></div>
                                <div className="col-12"><p>{formData?.invoiceNumber}</p></div> 
                            
                          </div>
                          <div className="col-sm-6 col-md-12 col-lg-12 col-xl-12 invoiceDate">
                            
                              <div className="col-12"><b> Date</b></div>
                              <div className="col-12"><p>{moment(formData?.invoicedate).format('ll')}</p></div>
                                                          
                          </div>
                          {formData?.terms !== "none" &&(
                            <div className="col-sm-6 col-md-12 col-lg-12 col-xl-12 dueDate">
                              
                                <div className="col-12"><b> Due Date</b></div>
                                <div className="col-12">
                                  {/* {moment(moment(formData?.invoicedate).add(formData?.terms, 'days').calendar(),"MM-DD-YYYY").format('ll')} */}
                                  <p> {moment(moment(formData?.invoicedate).add(formData?.terms, "days")).format("ll")}</p>
                                </div>
                              {/* = {moment(formData?.invoicedate, "MMMM Do YYYY").add(1, 'days')} */}
                            </div>)
                          }
                          <div className="col-sm-6 col-md-12 col-lg-12 col-xl-12 balanceDue">
                            {type ==="chargable" && (
                              <>
                              <div className="col-12"><b>Balance Due</b></div>
                              <div className="col-12">
                                  <p>
                                  {/* {formData?.currency?.Code} {formData?.currency?.Symbol}{separateComma(formData?.balanceDue)} */}
                                  {formData?.currency?.Code}{formData?.balanceDue >=0 ? " ":" -"}{formData?.currency?.Symbol}{formData?.balanceDue >=0 ? separateComma(Number(formData?.balanceDue)):separateComma(Number(Math.abs(formData?.balanceDue)))}
                                  </p>
                              </div>
                              </>
                            )
                            }
                            {type ==="quotation" && (
                              <>
                              <div className="col-12"><b>Total</b></div>
                              <div className="col-12">
                                  <p>
                                  {/* {formData?.currency?.Code} {formData?.currency?.Symbol}{separateComma(formData?.balanceDue)} */}
                                  {formData?.currency?.Code} {formData?.currency?.Symbol}{separateComma(Number(formData?.total))}
                                  {/* {formData?.balanceDue >=0 ? " ":" -"}{formData?.currency?.Symbol}{formData?.balanceDue >=0 ? separateComma(Number(formData?.balanceDue)):separateComma(Number(Math.abs(formData?.balanceDue)))} */}
                                  </p>
                              </div>
                              </>
                            )
                            }
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="toDetailsContainer">
                            <div className="toDetailsHeader">
                                    <div className="toDetailsHeading">BILL TO</div>
                                    <div>
                                      <h4 className="my-2">{formData?.customerName}</h4>
                                    </div>
                            </div>
                            <div className="toDetails">
                              <div>
                                  {formData?.customerEmail &&
                                    formData?.customerEmail
                                  }
                                  </div>
                                  <div>
                                    {  formData?.customerPhone.length >14 &&
                                      <span> Phone Number :  {formData?.customerPhone}</span>
                                    }
                                  </div>
                                  <div>
                                    {  formData?.customerFax.length > 14  &&
                                      <span> Fax :  {formData?.customerFax}</span>
                                    }
                                  </div>
                                  <div>
                                  {formData?.customerStreet &&
                                    formData?.customerStreet
                                  }
                                  </div>
                                  <div>
                                  {formData?.customerCityState &&
                                    formData?.customerCityState
                                  }
                                  </div>
                                  <div>
                                  { formData?.customerZipCode &&
                                    formData?.customerZipCode
                                  }
                                  </div>
                                  
                            </div>
                    </div>

                  </div>
                  <div className="previewBodyDesktop">
                      
                        <div className="previewBodytableDesktop">
                            <div className="previewBodytableheadDesktop">
                              <div className="col-6 descHead">
                                <span>DESCRIPTION</span>
                              </div>
                              <div className="col-2 res-col">
                                <span>RATE</span>
                              </div>
                              <div className="col-1 res-col">
                                <span>QTY</span>
                              </div>
                              <div className="col-2 res-col">
                                <span>AMOUNT</span>
                              </div>
                              <div className="col-1 res-col">
                                <span>TAX</span>
                              </div>
                            </div>
                            
                            <div className="previewBodyTableBodyContainer">
                              { formData?.description.map((desc,index)=>{
                                
                                  return(
                                    <div className="previewSinglerecord responsive-description" key={index}>
                                      <div className="col-xs-12 col-sm-12 col-md-6">
                                        <div className="descriptionlabel"><p>{desc?.itemDescription}</p></div>
                                        <div className="additionalDesc"><p>{desc?.additionalDetail}</p></div>
                                      </div>
                                      <div className="col-xs-12 col-sm-12 text-sm-end text-md-center col-md-2 px-1">
                                        <span className="res-tax-container">{formData?.currency?.Symbol}{separateComma(Number(desc?.rate))} <span className="res-tax">{desc?.tax === "true"? <b>*</b>: ""}</span></span>
                                      </div>
                                      <div className="col-xs-12 col-sm-12 text-sm-end text-md-center col-md-1 px-1">
                                        <div className="col-sm-1 responsive-quantity"><span className="responsive-multiple"> <i className="fa fa-times"></i></span> {desc?.quantity}</div> 
                                      </div>
                                      <div className="col-xs-12 col-sm-12 text-sm-end text-md-center col-md-2">
                                        <span className="responsive-divider">{formData?.currency?.Symbol}{separateComma(Number(desc?.rate * desc?.quantity))}</span>
                                      </div>
                                      <div className="col-xs-12 col-sm-12 text-sm-end text-md-center col-md-1">
                                        <span className="res-col"> {desc?.tax === "true"? "Yes": "No"}</span>
                                      </div>

                                      {/* <div className="responsive-single-preview">
                                        <div className="col-3">
                                          <div><span>{formData?.currency?.Symbol}{separateComma(desc?.rate)}</span></div>
                                          <div className="responsive-quantity"><span><i className="fa fa-times"></i></span> <span>{desc?.quantity}</span></div>
                                          <div><span className="responsive-divider">{formData?.currency?.Symbol}{separateComma(desc?.rate * desc?.quantity)}</span></div>

                                        </div>
                                      </div> */}

                                  </div>
  
                                  )
                                
                              })
                                
                              }
                            </div>
                            
                              <div className="previewBodyTableFooterContainer">
                                  <div className="col-md-5 col-12">
                                    <div className="invoiceFooterContainer">
                                        <div className="calcCont">
                                          <div className="calcContHeading"><p><b>SUBTOTAL</b></p></div>
                                          <div className="text-break pl-2"><p>{formData?.currency?.Symbol}{separateComma(Number(formData?.subTotal))}</p></div>
                                        </div>
                                        {  formData?.discountType !== 'none' &&  (
                                          <div className="calcCont">
                                            <div className="calcContHeading"><p><b>Discount ({formData?.discountType === "flatAmount" ? formData?.currency?.Symbol:''}{formData?.discountValue}{formData?.discountType === "percent" ? '%':''})</b></p></div>
                                            <div className="text-break pl-2">-{formData?.currency?.Symbol}{separateComma(Number(formData?.discountAmount))}</div>
                                          </div>)
                                        }
                                        {formData?.taxType !=="none" && (
                                          <div className="calcCont">
                                            <div className="calcContHeading"><p><b>{formData?.taxLabel.trim() ? formData?.taxLabel: <span className="opacity-50">Tax</span> } ({formData?.taxRate}%)</b></p></div>
                                            <div className="text-break pl-2"><p>{formData?.taxType ==="deducted" ? "-":""} {formData?.currency?.Symbol}{separateComma(Number(formData?.totalTax))}</p></div>
                                          </div> )
                                          }
                                        <hr className="mb-2 mt-0"></hr>
                                        <div className="calcCont">
                                          <div className="calcContHeading"><p><b>Total</b></p></div>
                                          <div className="text-break pl-2"><p>{formData?.currency?.Symbol}{separateComma(Number(formData?.total))}</p></div>
                                        </div> 
                                        {type ==="chargable" &&
                                          <div className="calcCont">
                                            <div className="calcContHeading"><p><b>Paid</b></p></div>
                                            <div className="text-break pl-2">-{formData?.currency?.Symbol}{separateComma(Number(formData?.totalPaid))}</div>
                                          </div> 
                                        }
                                        {formData?.type === "chargable" &&(<>
                                        
                                        <hr className="mb-2 mt-0"></hr>
                                          <div className="calcCont">
                                            <div className="calcContHeading"><p><b><span> Balance Due</span></b></p></div>
                                            <div className="text-break pl-2"><b>{formData?.currency?.Code}{formData?.balanceDue >=0 ? " ":" -"}{formData?.currency?.Symbol}{formData?.balanceDue >=0 ? separateComma(Number(formData?.balanceDue)):separateComma(Number(Math.abs(formData?.balanceDue)))}</b></div>
                                          </div>
                                          </>
                                        )}
                                        <hr className="my-0"></hr>
                                        <hr className="my-0"></hr>
                                        
                                    </div>
                                    

                                  </div>
                              </div>  
                              <div className="res-tax-note"><span><b>Items with * are Taxable.</b></span> </div>
                            {/* } */}
                            <div className="previewBodyTableFooterNotesContainer">
                              {formData?.notes && (
                                <div className="footerNotes">
                                  {/* <p className="text-start">Notes</p> */}
                                  <h6><b>Notes:</b></h6>
                                  <p>{formData?.notes}</p>
                                </div>)
                              }
                                
                                <div className="footerGallery">
                                      <GalleryImages galleryImages={footerImagesCol}/>
                                </div>
                            </div>
                        </div> 


                  </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Preview;
