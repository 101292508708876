import React, { useEffect, useState } from 'react'
import './Subscribe.css'
import { useSelector } from 'react-redux';
import { useCreateStripeCheckoutSessionMutation, useCurrentSubscribedPlanMutation, useLazyGetSubscriptionPlansQuery } from '../../store/apis/laravelApi';
import SinglePlan from './SinglePlan';
import { APPURL, FRONTSITE } from '../../common/common';
import { errorMessage } from '../../components/toastMessages/ToastifyMsg';
import CustombuttonSpinner from '../../components/CustombuttonSpinner';
import PricingFaq from '../../components/PricingFaq';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {  List, ListItem, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

  const SubmitButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    padding: '6px 12px',
    '&:hover': {
      backgroundColor: 'var(--themeBtnColor) !important',
      border: '1px solid var(--themeBtnColor) !important',
      boxShadow: 'rgba(31, 35, 65, 0.37) 0px 20px 30px -11px !important',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    backgroundColor: 'var(--buttonHover) !important',
    color: '#ffff !important',
    border: '1px solid var(--buttonHover) !important',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '1.75',
  });
function Subscribe() {
    const frontsiteUrl = FRONTSITE;
    const [open, setOpen] = useState(false);

    
    const auth = useSelector((state) => state.auth);
    
    const [planTab, setPlanTab] = useState('monthly');
    const [monthlyPlan, setMonthlyPlan] = useState([]);
    const [yearlyPlan, setYearlyPlan] = useState([]);
    const [loading, setLoading] = useState(true);
    const [subscribing, setSubscribing] = useState(false);
    const [subscribedPlan, setSubscribedPlan] = useState(null);
    const [toSubscribeId,setToSubscribeId] =useState(null);
    
    
    const [getSubscriptionPlans] = useLazyGetSubscriptionPlansQuery();
    const [createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation();
    const [currentSubscribedPlan] = useCurrentSubscribedPlanMutation();
    
    const handleClickOpen = (planId) => {
        setOpen(true);
        setToSubscribeId(planId);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    
    useEffect(() => {
        const handleCheckPlan = async () => {
            try {
                const body = {
                    user_email: auth?.user?.email,
                    user_id: auth?.user?.id
                }
                const res = await currentSubscribedPlan(body);
                if (res.data.status === "success") {
                    setSubscribedPlan(res?.data?.data);
                }
            } catch (error) {
                console.log("errorIn", error);
            }
        }

        const getData = async () => {
            setLoading(true);
            try {
                let res = await getSubscriptionPlans().unwrap();
                if (res?.status) {
                    setMonthlyPlan(res.data.filter(item => item.package_duration === 30));
                    setYearlyPlan(res.data.filter(item => item.package_duration === 365));
                    await handleCheckPlan();
                } else {
                    console.log("Error: ", res?.message);
                    errorMessage(res?.message);
                }
            } catch (error) {
                console.log("err fetch plans", error)
            }
            finally {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        }
        getData();
    }, [getSubscriptionPlans, auth, currentSubscribedPlan])


    const handleSubscribePlan = async (planId) => {
        try {
            setSubscribing(true);
            const body = {
                user_email: auth?.user.email,
                local_price_id: planId,
                checkout_success_url: process.env.NODE_ENV === "production" ? `${APPURL}subscription-success` : 'http://localhost:3000/subscription-success',
                checkout_failure_url: process.env.NODE_ENV === "production" ? `${APPURL}subscription-failure` : 'http://localhost:3000/subscription-failure',
            }
            const resp = await createStripeCheckoutSession(body);
            if (resp.data) {
                window.location = resp?.data?.url;
            }

        } catch (error) {
            console.log("handle Subscribe Plan Error: ", error);
            if (error.data.message) {
                errorMessage(error?.data?.message);
            }
        } finally {
            setTimeout(() => {
                setSubscribing(false);
            }, 2000);
        }

    }


    return (
        <>
            <section>
                <div className="container py-4">
                    {subscribedPlan?.subscription_type.toLowerCase() === 'demo' &&
                        <div className='card mb-4 text-start shadow demo-card border-0'>
                            <div className='card-body position-relative overflow-hidden'>
                                <div className="plan-activated-tag"><span>Activated</span></div>
                                <h4>
                                    {/* {subscribedPlan?.packagessubcribed[0]?.package_title} */}
                                    Free Plan
                                </h4>
                                <p className='mb-1'>
                                    {/* {subscribedPlan?.packagessubcribed[0]?.package_description} */}
                                    {/* Get started for free with our comprehensive plan that includes everything you need to manage your business effectively. */}
                                    Our free plan equips you with everything you need to get started, automatically activated when you create your account.
                                </p>
                                <p className='mb-1'>Features:</p>
                                <ul className='demo-list'>
                                    {/* {
                                        subscribedPlan?.packagessubcribed[0]?.features?.map((feature, index) => {
                                            return (<li key={index}>{feature}</li>)
                                        })
                                    } */}
                                    <li><i className='fa fa-check me-1 mt-1'></i> 1 Invoice/quotations per month</li>
                                    <li><i className='fa fa-check me-1 mt-1'></i> 1 Single PaySlips per month</li>
                                    <li><i className='fa fa-check me-1 mt-1'></i> 1 Group PaySlips(1 each) per month</li>
                                    <li><i className='fa fa-check me-1 mt-1'></i> Unlimited templates per month</li>
                                    <li><i className='fa fa-check me-1 mt-1'></i> 24/7 support</li>
                                    <li></li>

                                </ul>
                                <h6 className='mt-4 mb-1'>Need more? Upgrade to unlock even more features!</h6>
                            </div>
                        </div>
                     }
                    {/* <!-- END --> */}

                    <div className="pricing-switcher">
                        <p className="fieldset shadow">
                            <input type="radio" name="duration-1" value="monthly" id="monthly-1" disabled={loading} onChange={() => { if (!loading) setPlanTab('monthly') }} defaultChecked={planTab === "monthly" ? true : false} />
                            <label htmlFor="monthly-1" className={loading ? 'opacity-50' : ''} title='Monthly Plans'>Monthly</label>

                            {/* { plans.filter(plan=>plan?.package_duration===365).length >0 && */}
                            <>
                                <input type="radio" name="duration-1" value="yearly" id="yearly-1" disabled={loading} onChange={() => { if (!loading) setPlanTab('yearly') }} defaultChecked={planTab === "yearly" ? true : false} />
                                <label htmlFor="yearly-1" className={loading ? 'opacity-50' : ''} title='Yearly Plans'>Yearly</label>
                            </>
                            {/* } */}
                            <span className="switch"></span>
                        </p>
                    </div>


                    <div className="row text-center align-items-center plans-wrapper">
                        {planTab === 'monthly' && !loading &&
                            <>
                                {/* <!-- Pricing Table--> */}
                                {monthlyPlan.map((plan) => (
                                    <SinglePlan handleSubscribePlan={handleSubscribePlan} plan={plan} key={plan?.local_price_id} subscribing={subscribing} subscribedPlan={subscribedPlan} handleClickOpen={handleClickOpen}/>
                                ))
                                }
                                {monthlyPlan.length === 0 && !loading &&
                                    <div className='row justify-content-center'>
                                        <div className='card col-lg-6 bg-white px-4 py-4 rounded-lg shadow'>
                                            <div className="card-body">
                                                <p className='m-0'>Monthly subscriptions are not yet available.</p>

                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* <!-- END --> */}

                            </>
                        }
                        {planTab === 'yearly' && !loading &&
                            <>
                                {yearlyPlan.map((plan) => (
                                    <SinglePlan handleSubscribePlan={handleSubscribePlan} plan={plan} key={plan?.local_price_id} subscribing={subscribing} subscribedPlan={subscribedPlan} handleClickOpen={handleClickOpen} />
                                ))
                                }
                                {yearlyPlan.length === 0 && !loading &&
                                    <div className='row justify-content-center'>
                                        <div className='card col-lg-6 bg-white px-4 py-4 rounded-lg shadow'>
                                            <div className="card-body">
                                                <p className='m-0'>Yearly subscriptions are not yet available.</p>

                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    {!loading &&
                        <p className='fw-bold text-center mt-4 text'>*Prices are listed in USD and may vary by local currency.</p>
                    }
                    {/* <div className="row text-center align-items-center plans-wrapper"> */}
                    {/* <!-- Pricing Table--> */}
                    {/* <div className="col-lg-4 mb-5 mb-lg-0">
                                <div className="bg-white px-5 py-4 rounded-lg shadow plan-container">
                                    <h1 className="h6 text-uppercase font-weight-bold mb-4">Basic</h1>
                                    <h2 className="h1 font-weight-bold price">$44.49<span className="text-small font-weight-normal ml-2">/ yr*</span> <b className="save-ammount">Save 10%</b></h2>

                                    <div className="custom-price-separator my-4 mx-auto"></div>
                                    <p className='mb-2 text-start font-weight-bold'>Basic have:</p>
                                    <ul className="list-unstyled mb-5 text-small text-start">
                                        <li className="mb-3">
                                            <i className="fa fa-check me-2 text-primary"></i>4 Invoice/quotations per month</li>
                                        <li className="mb-3">
                                            <i className="fa fa-check me-2 text-primary"></i>4 Single PaySlips per month</li>
                                        <li className="mb-3">
                                            <i className="fa fa-check me-2 text-primary"></i>2 Group PaySlips(4 each) per month</li>
                                        <li className="mb-3">
                                            <i className="fa fa-check me-2 text-primary"></i>Unlimited templates per month
                                        </li>
                                        <li className="mb-3">
                                            <i className="fa fa-check me-2 text-primary"></i>24/7 support
                                        </li>
                                        <li className="mb-3">
                                            <i className="fa fa-check me-2 text-primary"></i> Eliminate anytime
                                        </li>
                                    </ul>
                                    <button type='button' onClick={() => handleSubscribePlan('44.49')} className="btn btn-save btn-block p-2 shadow rounded-pill">Subscribe</button>
                                </div>
                            </div> */}
                    {/* <!-- END --> */}

                    {/* </div> */}
                    {loading &&
                        <div className='h-50 position-relative py-5 my-5'>
                            <CustombuttonSpinner />
                        </div>
                    }
                </div>
                {!loading &&
                    <PricingFaq />
                }
            </section>


            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        handleSubscribePlan(toSubscribeId);
                        handleClose();
                    },
                }}
            >
                <DialogTitle>Subscription Terms & Conditions</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To subscribe the plan, please read and agree with the following terms and conditons. Payments will be processed by <strong>Noor Inc.</strong>
                    </DialogContentText>
                    <List>
                        <ListItem className='custom-subscribe-modal-li'>
                            <ListItemText primary="1. Non-Refundable Payment:" secondary="Please note that all payments made are final and non-refundable." />
                        </ListItem>
                        <ListItem className='custom-subscribe-modal-li'>
                            <ListItemText primary="2. Subscription Cancellation:" secondary="Purchasing this subscription will immediately cancel any previous subscriptions you have active." />
                        </ListItem>
                        <ListItem className='custom-subscribe-modal-li'>
                            <ListItemText primary="3. Automatic Renewal:" secondary="This subscription does not auto-renew. You will need to manually renew your subscription at the end of the billing period." />
                        </ListItem>
                        <ListItem className='custom-subscribe-modal-li'>
                            <ListItemText primary="4. Secure Transactions:" secondary="Your payment details are securely processed, and we do not store any sensitive financial information on our servers.Additionally, you will be redirected to our external payment partner's link to complete the payment process." />
                        </ListItem>
                        <ListItem className='custom-subscribe-modal-li'>
                            <ListItemText primary="5. Confirmation Required:" secondary="Please confirm your purchase details and agree to the terms of service before proceeding with payment.Note that we do not charge any tax on your behalf; tax obligations depend on your country or banking channel." />
                        </ListItem>
                    </List>
                    <FormControlLabel required control={<Checkbox required />} label={<Typography>
                        Do you agree with our <Link to={`${frontsiteUrl}terms-of-services`} target="_blank" title='Read all Terms & Conditions'>
                            Terms & Conditions</Link>?
                    </Typography>}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' sx={{
                        'borderColor': 'var(--light-blue)', 'color': 'var(--light-blue)', 
                        '&:hover': {
                            'borderColor': 'var(--themeColor)','color': 'var(--themeColor)'
                        },
                    }} onClick={handleClose} title='Do not subscribe'>Cancel</Button>
                    <SubmitButton type='submit' title='Agree to term and conditon & Subscribe to plan.'>Subscribe</SubmitButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Subscribe