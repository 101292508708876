import moment from 'moment/moment';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetSubscriptionHistoryMutation, useGetUserPlanServiceMutation } from '../store/apis/laravelApi';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustombuttonSpinner from '../components/CustombuttonSpinner';
import { setPlanDetails } from '../store/slices/subscribedPlanDetails';

function SubscribedPlanInfo() {
    const user = useSelector(state=>state.auth.user);
    const subscribedPlanDetails = useSelector((state)=>state.planDetails?.planDetails);
    
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const [planHistory,setPlanHistory] = useState([]);
    const [isLoading,setIsLoading] = useState(true);

    const [getSubscriptionHistory] = useGetSubscriptionHistoryMutation();
    const [getUserPlanService] = useGetUserPlanServiceMutation();

    useEffect(() => {
        if (auth.user !==null) {
          const handleGetSubUsage =async ()=> {
              try {
              const body = {
                user_email:auth.user?.email,
                user_id:auth?.user?.id
              }
    
              const resp = await getUserPlanService(body);
              dispatch(setPlanDetails(resp.data));
            } catch (error) {
              console.log("plans->error",error);
            }
          }
          handleGetSubUsage();
        }
      }, [auth,dispatch, getUserPlanService])
    


    useEffect(() => {
        const handleGetSubHistory = async ()=>{
            try {
                setIsLoading(true);
                const body = {
                    user_email:user?.email,
                    user_id: user?.id
                }
                const resp = await getSubscriptionHistory(body);
                if (resp?.data?.status === "success") {
                    const dataArray = resp?.data?.data;
                    const firstFiveElements = dataArray && dataArray.length > 0 ? dataArray.slice(0, 5) : [];
                    setPlanHistory(firstFiveElements);
                }

            } catch (error) {
                
            }finally{
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        }
        handleGetSubHistory();
    }, [getSubscriptionHistory,user]);
    
  return (
    <div className='plan-info-wrapper'>
        {isLoading? <div className="position-relative d-flex align-items-center w-100 py-5"><CustombuttonSpinner/></div>:
            <>
                <div className='subscribed-plan-title'>
                    <p>Your Subscribed Plan:
                        {subscribedPlanDetails?.status &&
                            <span className='plan-title'>
                                {subscribedPlanDetails?.current_package?.package_title} Plan
                            </span>
                        }
                        {!subscribedPlanDetails?.status &&
                            <strong className='text-danger'> Plan Expired</strong>
                        }
                    </p>
                    <Link to='/subscription-plans' className='btn btn-save'>Upgrade Plan</Link>
                </div>
                {subscribedPlanDetails?.status &&
                    <div className="responsive-table plan-features">
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Feature</th>
                                    <th>Total</th>
                                    <th> Used</th>
                                    <th>Remaining</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Invocies</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.invoice?.total}</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.invoice?.usage_count}</td>
                                    <td className={subscribedPlanDetails?.plan_usage_details?.invoice?.usage_count===subscribedPlanDetails?.plan_usage_details?.invoice?.total?'text-danger':'text-dark'}>
                                        {subscribedPlanDetails?.plan_usage_details?.invoice?.total - subscribedPlanDetails?.plan_usage_details?.invoice?.usage_count} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>Quotations</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.quotation_invoice?.total}</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.quotation_invoice?.usage_count}</td>
                                    <td className={subscribedPlanDetails?.plan_usage_details?.quotation_invoice?.usage_count===subscribedPlanDetails?.plan_usage_details?.quotation_invoice?.total?'text-danger':'text-dark'}>
                                        {subscribedPlanDetails?.plan_usage_details?.quotation_invoice?.total - subscribedPlanDetails?.plan_usage_details?.quotation_invoice?.usage_count} 
                                    </td>
                                </tr>
                                <tr>
                                    <td>Payslips</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.single_payslip?.total}</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.single_payslip?.usage_count}</td>
                                    <td className={subscribedPlanDetails?.plan_usage_details?.single_payslip?.usage_count===subscribedPlanDetails?.plan_usage_details?.single_payslip?.total?'text-danger':'text-dark'}>
                                        {subscribedPlanDetails?.plan_usage_details?.single_payslip?.total - subscribedPlanDetails?.plan_usage_details?.single_payslip?.usage_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Salary Groups</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.salary_group?.total}</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.salary_group?.usage_count}</td>
                                    <td className={subscribedPlanDetails?.plan_usage_details?.salary_group?.total===subscribedPlanDetails?.plan_usage_details?.salary_group?.usage_count?'text-danger':'text-dark'}>
                                        {subscribedPlanDetails?.plan_usage_details?.salary_group?.total - subscribedPlanDetails?.plan_usage_details?.salary_group?.usage_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='pe-0'>Salary Groups Payslip</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.salary_group_payslip?.total}</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.salary_group_payslip?.usage_count}</td>
                                    <td className={subscribedPlanDetails?.plan_usage_details?.salary_group_payslip?.usage_count===subscribedPlanDetails?.plan_usage_details?.salary_group_payslip?.total?'text-danger':'text-dark'}>
                                        {subscribedPlanDetails?.plan_usage_details?.salary_group_payslip?.total - subscribedPlanDetails?.plan_usage_details?.salary_group_payslip?.usage_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Consumers</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.consumers?.total}</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.consumers?.usage_count}</td>
                                    <td className={subscribedPlanDetails?.plan_usage_details?.consumers?.usage_count===subscribedPlanDetails?.plan_usage_details?.consumers?.total?'text-danger':'text-dark'}>
                                        {subscribedPlanDetails?.plan_usage_details?.consumers?.total - subscribedPlanDetails?.plan_usage_details?.consumers?.usage_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Products</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.products?.total}</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.products?.usage_count}</td>
                                    <td className={subscribedPlanDetails?.plan_usage_details?.products?.usage_count===subscribedPlanDetails?.plan_usage_details?.products?.total?'text-danger':'text-dark'}>
                                        {subscribedPlanDetails?.plan_usage_details?.products?.total - subscribedPlanDetails?.plan_usage_details?.products?.usage_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Emails</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.email_sent?.total}</td>
                                    <td>{subscribedPlanDetails?.plan_usage_details?.email_sent?.usage_count}</td>
                                    <td className={subscribedPlanDetails?.plan_usage_details?.email_sent?.usage_count===subscribedPlanDetails?.plan_usage_details?.email_sent?.total?'text-danger':'text-dark'}>
                                        {subscribedPlanDetails?.plan_usage_details?.email_sent?.total - subscribedPlanDetails?.plan_usage_details?.email_sent?.usage_count}
                                    </td>
                                </tr>
                                <tr>
                                    <td> templates per month</td>
                                    <td><i className="fa fa-infinity"></i></td>
                                    <td><i className="fa fa-infinity"></i></td>
                                    <td><i className="fa fa-infinity"></i></td>
                                </tr>
                                <tr>
                                    <td>24/7 support</td>
                                    <td><i className='fa fa-check'></i></td>
                                    <td><i className="fa fa-infinity"></i></td>
                                    <td><i className='fa fa-check'></i></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                }

                <div className='spacer'></div>
                <div className='subscribed-plan-title'>
                    <h5 className='fw-bold'>Subscription History</h5>
                    {planHistory.length >=5 &&
                        <Link className='btn btn-sm btn-primary'  to='/subscription-history'>View All</Link>
                    } 
                </div>
                <div className='responsive-table'>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Plan Name</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>Subscribed Date</th>
                                <th>Expires on</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {planHistory?.map(plan => (
                                    <tr key={plan?.id}>
                                        <td className='text-capitalize'>{plan?.packagessubcribed?.package_title} Plan</td>
                                        <td className={`text-capitalize ${plan?.subscription_current_status.toLowerCase()==='active'?'text-success':'text-danger'}`}><strong> {plan?.subscription_current_status}</strong></td>
                                        <td>{plan?.subscription_validation_days===30?'Monthly':'Yearly'} </td>
                                        <td>{moment(plan?.activation_datetime).format('ll')}</td>
                                        <td>{moment(plan?.expires_on).format('ll')}</td>
                                        <td>${plan?.package_price.toFixed(2)}</td>
                                    </tr>
                                ))
                            }

                           {planHistory.length <=0 &&
                            <tr>
                                <td colSpan='6' className='text-center'>No subscription yet!</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            
            </>
        }
    </div>
  )
}

export default SubscribedPlanInfo