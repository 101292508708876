import React from 'react'
import CustombuttonSpinner from '../CustombuttonSpinner';
import { useState } from 'react';
import moment from "moment/moment";
// import { IMG_URL } from '../../common/common';

const ExcelJS = require('exceljs');

// const toDataURL = (url) => {
//     const promise = new Promise((resolve, reject) => {
//       var xhr = new XMLHttpRequest();
//       xhr.onload = function () {
//         var reader = new FileReader();
//         reader.readAsDataURL(xhr.response);
//         reader.onloadend = function () {
//           resolve({ base64Url: reader.result });
//         };
//       };
//       xhr.open("GET", url);
//       xhr.responseType = "blob";
//       xhr.send();
//     });
  
//     return promise;
//   };

function ExportExpenses({ allExpenses,year }) {
    const [loader, setLoader] = useState(false);
    // const imgurl = IMG_URL;
    const createDownloadData = () => {
        setLoader(true);
        exportExcel();
    }

    const exportExcel = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet(`Expense Report `);

            sheet.getRow(1).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
            sheet.getRow(1).font = {
                bold: true,
            }
            sheet.columns = [
                {
                    header: "ExpenseTitle",
                    key: "title",
                    width: 30,
                },
                {
                    header: "Category",
                    key: "category",
                    width: 25,
                },
                {
                    header: "Vendor",
                    key: "vendor",
                    width: 20,
                },
                {
                    header: "Date",
                    key: "date",
                    width: 15,
                },
                {
                    header: "Total Expense",
                    key: "totalAmount",
                    width: 15,
                },

                {
                    header: "Total Expense Tax",
                    key: "totalAmountTax",
                    width: 20,
                },
                
                // {
                //     header: "Receipt Image",
                //     key: "receiptImage",
                //     width: 30,
                // },
            ];
            // const promise = Promise.all(
                allExpenses?.map(async(expense,index) =>{
                    // const rowNumber = index + 1;
                    sheet.addRow({
                        title: expense?.expenses_title,
                        category: expense?.expenses_category,
                        date: moment(expense?.expenses_date).format("ll"),
                        totalAmount: expense?.expenses_total_amount,
                        totalAmountTax: expense?.expenses_total_amount_tax,
                        vendor: expense?.expenses_vendor,
                        // description: expense?.expenses_description,
                        // receiptImage:  expense?.expenses_receipt_image ? imgurl+expense?.expenses_receipt_image:"",
                    })
                    // if (expense?.expenses_receipt_image !=null) {
                    //     let row = sheet.getRow(rowNumber);
                    //     row.height = 100;
                    //     const result = await toDataURL(imgurl+expense?.expenses_receipt_image);
                    //     const splitted = expense?.expenses_receipt_image.split(".");
                    //     const extName = splitted[splitted.length - 1];
    
                    //     const imageId2 = workbook.addImage({
                    //         base64: result.base64Url,
                    //         extension: extName,
                    //       });
                    //       sheet.addImage(imageId2, {
                    //         tl: { col: 6, row: rowNumber },
                    //         ext: { width: 100, height: 100 },
                    //       });
                        
                    // }



                }
                    
                )

            // );


            // promise.then(() => {
            workbook.xlsx.writeBuffer().then(data => {
                const blob = new Blob([data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
      
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = `expense-report-${year}.xlsx`;
                anchor.click();
                window.URL.revokeObjectURL(url);
              })
            setLoader(false)
        //  }
        // );
        } catch (error) {

        }

    }
    return (<>
        <button onClick={createDownloadData} className={`btn btn-sm btn-save ${loader === true ? 'cursor-disable' : ''}`} disabled={loader} title='Download Expense Reports As Excel'><i className="fa fa-file-excel"></i><span className='position-relative'>Export</span> { loader && <span  className={`position-relative p-2`}> <CustombuttonSpinner /></span>} </button>

    </>

    )
}

export default ExportExpenses