import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
// import moment from "moment/moment";
// import { separateComma } from "../utils/utils";
// import CustombuttonSpinner from "../components/CustombuttonSpinner";
import TableLoader from "../components/loaders/TableLoader";
import { dataTablecustomStyles } from "../utils/utils";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

function AllGroupPayslip({ allgroups, openGroupModal, deleteSalary, duplicatepayslipGroup, isLoading }) {
  const [allSalaries, setAllSalaries] = useState([]);
  const [search, setSearch] = useState("");
  const [filterSalaries, setFilterSalaries] = useState([]);
  const navigate = useNavigate();
  // const [tableloader, setTableloader] = useState(true);

  useEffect(() => {
    setAllSalaries(allgroups || []);
    setFilterSalaries(allgroups || []);
    // setTableloader(false);
  }, [allgroups]);

  const columns = [
    {
      name: "Group #",
      selector: (row) => (<div> {row.group_number} </div>),
      // sortable: true,
      width: '125px'
    },
    {
      name: "Group Title",
      selector: (row) => (
        <span className="text-uppercase">{row.title}</span>
      ),
      // sortable: true,
    },
    {
      name: "From",
      selector: (row) => (
        <span
          className="text-capitalize"
          // style={{ minWidth: "100px", display: "inline-block" }}
        >
          {row.from_name}
        </span>
      ),
      // sortable: true,
    },

    {
      name: "Email",
      selector: (row) => (<div> {row.from_email}</div>),
      // sortable: true,
    },
    {
      name: "Total Paylips",
      // {row.currency_selected.Symbol}
      selector: (row) => (
        <div className="count-container">
          <span>
            {row.invoice_count} Payslips

          </span>
          {/* {separateComma(row.total_earnings - row.total_deductions)} */}

        </div>
      ),
      // sortable: true,
    },
    {
      // name: "",
      cell: (row) => (
        <div className="d-flex gap-1" title="Select an action">
          <DropdownButton
              as={ButtonGroup}
              key={'start'}
              id={`dropdown-button-drop-${row.id}`}
              drop={'start'}
              variant="dropdown-menu-custom"
              title={<i className="fas fa-ellipsis-v"></i> }
              // size="sm"
            >
              <Dropdown.Item eventKey="1">
                <Link
                  className="d-flex gap-2 align-items-center text-decoration-none listing-button-edits"
                  to={{ pathname: `/salarygroup/${window.btoa(`${row.id}`)}` }}
                  state={{ groupTitle: row.title }}
                  title="View Payslip Group"
                >
                  <i className="fa fa-eye"></i> View 
                </Link>
              </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <div 
                className="d-flex gap-2 align-items-center listing-button-edits"
                onClick={() => {
                  openGroupModal(true, row.id)
                }}
                title="Edit Payslip Group"
              >
                <i className="fa fa-edit"></i> Edit
              </div>
            </Dropdown.Item>

              <Dropdown.Item eventKey="3">
                <div
                  className="d-flex gap-2 align-items-center listing-button-edits"
                  onClick={() => deleteSalary(row.id, row.title, "groupsalary")}
                  title={`Delete ${row.title} Payslip Group`}

                >
                  <i className="fa fa-trash-alt text-danger"></i> Delete
              </div>
              </Dropdown.Item>

              {/* <Dropdown.Divider /> */}

              {/* <Dropdown.Item eventKey="4">
                <div onClick={()=>handleOpenEmail(row.invoiceNumber,row.id,row.customerEmail)} className="d-flex gap-2 align-items-center listing-button-edits"
                title="e-mail Invoice"
                >
                    <i className="fa fa-mail-bulk"></i> Email  Invoice
                </div>
              </Dropdown.Item> */}
              <Dropdown.Item eventKey="5">
                <div onClick={() => duplicatepayslipGroup(row.id, row.title, "groupsalary")}  className="d-flex gap-2 align-items-center listing-button-edits" title={`Duplicate ${row.title} Payslip Group`}>
                    <i className="fa fa-copy"></i> Duplicate 
                </div>
              </Dropdown.Item>

            </DropdownButton>
        </div>
      ),
      width: '60px',
      right: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    const result = allSalaries.filter((invoice) => {
      return invoice?.title?.toLowerCase().match(search?.toLowerCase());
    });
    setFilterSalaries(result);
  }, [search, allSalaries]);

  return (
    <>
      <div>
        <div className="py-2">
          {/* 
            title={"All Invoices"} 
            fixedHeader
            fixedHeaderScrollHeight="400px"
          */}
          {isLoading ? (
            <div>
              <TableLoader />
              {/* <span>Loading . . .</span> */}
            </div>
          ) : (
            <div className="table-wrapper fadeInOpacity">
              <DataTable
                columns={columns}
                data={filterSalaries}
                fixedHeader
                fixedHeaderScrollHeight="400px"
                className="table-scroll-custom"
                highlightOnHover
                pointerOnHover  
                responsive
                subHeader
                subHeaderComponent={
                  <div className="col-md-4 col-12">
                    { filterSalaries.length >0 &&
                      <div className="table-header-custom">
                        <label className="col-md-3 col-12 text-start" htmlFor="search">Search: </label>
                        <div className="col-md-9 col-12">
                          <input
                            type="search"
                            placeholder="Search By  Group Title"
                            className="form-control"
                            value={search}
                            autoComplete="off"
                            // style={{ width: "28%" }}
                            onChange={(e) => setSearch(e.target.value)}
                            name="search"
                            id="search"
                          ></input>
                        </div>
                      </div>
                    }
                  </div>
                }
                // subHeaderAlign="left"
                onRowClicked={(row) => {
                  navigate(`/salarygroup/${window.btoa(`${row.id}`)}`, {
                    state: {
                      groupTitle: row.title
                    },
                  });
                }}
                sortIcon={<i className="fa fa-angle-down"></i>}
                pagination
                customStyles={dataTablecustomStyles}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AllGroupPayslip;
