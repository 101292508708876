import React, { useState,useEffect, useMemo } from 'react';
import { useDeleteSingleExpenseMutation, useGetAllExpensesQuery, useLazyGetAllExpensesQuery } from '../../store/apis/baseApi';
import { deepCopy, separateComma } from '../../utils/utils';
import { Link } from 'react-router-dom';
import ExpenseListing from './ExpenseListing';
import { errorMessage, successMessage } from '../../components/toastMessages/ToastifyMsg';
import ReactPortal from '../../components/reactPortal/ReactPortal';
import Modal from '../../components/modal/Modal';
import CustomDeleteloader from '../../components/CustomDeleteloader';


function Expenses() {
  const date = useMemo(() => new Date(),[]);
  const [tableloader, setTableloader] = useState(true);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loader,setLoader] = useState(false);

  const [activeYear, setActiveYear] = useState(JSON.stringify(date.getFullYear()));
  const [activeMonth,setActiveMonth] =useState('all');
  const [allYear, setAllYears] = useState([]);
  const [allExpenseData,setAllExpenseData] =useState([]);
  const [totalExpenseCount,setTotalExpenseCount] =useState({totalExpenseAmount: 0,totalExpenseTax:0});
  const [globalReport,setGlobalReport] = useState(true);

  // api hooks
  const [getAllExpenses] = useLazyGetAllExpensesQuery();
  const [deleteSingleExpense] = useDeleteSingleExpenseMutation();
  const {data,error,isLoading} = useGetAllExpensesQuery({month:'all',year:JSON.stringify(date.getFullYear())});

  useEffect(() => {
    setTableloader(isLoading);
      if (data) {
        if (data?.success) {
          let allExpensesData = deepCopy(data);
          setAllYears(allExpensesData?.data?.years);
          
          if (allExpensesData?.data?.all_results.length === 0 ) {
            let yearCount = allExpensesData?.data?.years.length - 1;
            setTableloader(true);
            setActiveYear(`${allExpensesData?.data?.years[yearCount]?.year}`);
            setActiveMonth('all');
          }
          let totalExpense = 0;
          let totalTax = 0;
          allExpensesData?.data?.all_results.forEach(expense => {
            totalExpense = totalExpense + expense?.expenses_total_amount ;
            totalTax = totalTax + expense?.expenses_total_amount_tax;
          });
          setTotalExpenseCount({totalExpenseAmount:totalExpense,totalExpenseTax:totalTax});
          let all_results = allExpensesData?.data?.all_results.map((expense,index)=> ({...expense,srNum:index+1}))
          setAllExpenseData(all_results);

        }
        if(!data?.success){
          setGlobalReport(data?.success);
          setTotalExpenseCount({totalExpenseAmount:0,totalExpenseTax:0});

        }
      } 
      if(error) {
        errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
      }
  }, [data,error,isLoading]);
  


  useEffect(() => {
    (async()=>{
      try {
          setTableloader(true);
          if (activeMonth!==null && activeYear!==null && activeMonth!=="null" && activeYear!=="null" &&activeMonth&&activeYear) {
            let params = {month:activeMonth,year:activeYear}; 
                const {data,error} = await getAllExpenses(params);
                if (data) {
                  if (data?.success) {
                    let allExpensesData = deepCopy(data);
                    setAllYears(allExpensesData?.data?.years);
                    
                    if (allExpensesData?.data?.all_results.length === 0 ) {
                      let yearCount = allExpensesData?.data?.years.length - 1;
                      setTableloader(true);
                      setActiveYear(`${allExpensesData?.data?.years[yearCount]?.year}`);
                    }
                    let totalExpense = 0;
                    let totalTax = 0;
                    allExpensesData?.data?.all_results.forEach(expense => {
                      totalExpense = totalExpense + expense?.expenses_total_amount ;
                      totalTax = totalTax + expense?.expenses_total_amount_tax;
                    });
                    setTotalExpenseCount({totalExpenseAmount:totalExpense,totalExpenseTax:totalTax});
                    let all_results = allExpensesData?.data?.all_results.map((expense,index)=> ({...expense,srNum:index+1}))
                    setAllExpenseData(all_results);
                    // setAllExpenseData(allExpensesData?.data?.all_results);
       
                  }
                  if(!data?.success){
                    setGlobalReport(data?.success);
                    setAllExpenseData([]);
                    setTotalExpenseCount({totalExpenseAmount:0,totalExpenseTax:0});
  
                  }
                }  
                if(error) {
                  errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
                }  
          }else{
            setAllExpenseData([]);setAllYears([]);
            setTotalExpenseCount({totalExpenseAmount:0,totalExpenseTax:0});
           }
        
      } catch (error) {
      }finally{ 
        setTimeout(() => {
          setTableloader(false);
        }, 100);
      }
    })()
  }, [getAllExpenses, activeMonth,activeYear,globalReport])

  const handleChangeYear= (year) => {
    setActiveYear(`${year}`);
    setActiveMonth('all');
    // setRefreshList(true);
  }

  const handleChangeMonth = (month)=>{
    setActiveMonth(month);
  }
  
  const confirmation = (id, name) => {
    setIsConfirmation(true);
    setDeleteModal(name);
    setDeleteId(id);
  };

  const closeConfirmation = ()=>{
    setIsConfirmation(false);
    setDeleteModal(null);
    setDeleteId(null);
  };

  
  const handleDeleteSingleExpense = async ()=>{
    try {
      setLoader(true);
      const {data,error} = await deleteSingleExpense({ id:deleteId });
      if (data) {
        if (data.success) {
            closeConfirmation();
              successMessage(data?.message);
              let newList = allExpenseData.filter(expense=> expense?.expense_id !== deleteId );
              let deleted = allExpenseData.filter(expense=> expense?.expense_id === deleteId );
              let currentYear = new Date(deleted[0].expenses_date).getFullYear();
              if (newList.length >0) {
                let totalExpense = 0;
                let totalTax = 0;
                newList.forEach(expense => {
                  totalExpense = totalExpense + expense?.expenses_total_amount ;
                  totalTax = totalTax + expense?.expenses_total_amount_tax;
                });
                setTotalExpenseCount({totalExpenseAmount:totalExpense,totalExpenseTax:totalTax});
                setAllExpenseData([...newList]);
              }else{
                let yearList = allYear?.filter(year=>year?.year!==currentYear);
                // console.log("newList.length <0",newList.length <=0)
                if (activeMonth ==='all' && yearList.length>0) {
                  // console.log("activemonth",activeMonth, "yearList.lenght",yearList.length);
                  let yearCount = yearList.length - 1;
                  let paramYear = yearList[yearCount]?.year;
                  // console.log("paramYear:",paramYear);
                  setActiveYear(`${paramYear}`);
                  
                }
                else{
                 // let yearList = allYear?.filter(year=>year?.year!==newdate);
                  if (yearList.length ===0) {
                    // console.log("activemonth",activeMonth, "yearList.lenght",yearList.length);
                    setAllExpenseData([]);
                    setAllYears([]);
                    setTotalExpenseCount({totalExpenseAmount:0,totalExpenseTax:0});
                    setActiveMonth(null)
                    setActiveYear(JSON.stringify(date.getFullYear()));
                    setActiveMonth('all');
                  }
                  else{
                    setActiveMonth('all');
                    // console.log("else>setActiveMonth('all')")

                  }
                }
              }
          }
          if (!data.success) {
            errorMessage(data?.message)
          }
        }
        if (error) {
            errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
        }
    } catch (error) {
      
    }finally{
      setLoader(false);
    }
  };
 
  // console.log(allYear.length,allYear,allExpenseData)
  return (
    <>
    {loader &&<CustomDeleteloader message={'Deleting . . .'}/>}
      <div className="spacer"></div>
      <div className="invoice-container">
        <div className="container invoice-list">   
          <div className="expenses-wrapper">
            <div className="header-wrapper">
                <div className="action-wrapper">
                    <Link to='create' className="btn btn-primary" title='Create New Expense'><i className="fa fa-plus"></i> <span>Create Expense</span></Link>
                </div>
                <div className="months-filter-wrapper">
                      <button className={`btn-month${ activeMonth ==='all'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('all')} disabled={ activeMonth ==='all' || allYear.length>0?false:true} title="View All Expenses">All</button>
                      <button className={`btn-month${ activeMonth ==='01'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('01')} disabled={ activeMonth ==='01' || allYear.length>0?false:true} title="View Expenses  of January">Jan</button>
                      <button className={`btn-month${ activeMonth ==='02'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('02')} disabled={ activeMonth ==='02' || allYear.length>0?false:true} title="View Expenses of February">Feb</button>
                      <button className={`btn-month${ activeMonth ==='03'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('03')} disabled={activeMonth ==='03' || allYear.length>0?false:true} title="View Expenses of March">Mar</button>
                      <button className={`btn-month${ activeMonth ==='04'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('04')} disabled={activeMonth ==='04' || allYear.length>0?false:true} title="View Expenses of April">Apr</button>
                      <button className={`btn-month${ activeMonth ==='05'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('05')} disabled={activeMonth ==='05' || allYear.length>0?false:true} title="View Expenses of May">May</button>
                      <button className={`btn-month${ activeMonth ==='06'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('06')} disabled={activeMonth ==='06' || allYear.length>0?false:true} title="View Expenses of June">Jun</button>
                      <button className={`btn-month${ activeMonth ==='07'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('07')} disabled={activeMonth ==='07' || allYear.length>0?false:true} title="View Expenses of July">Jul</button>
                      <button className={`btn-month${ activeMonth ==='08'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('08')} disabled={activeMonth ==='08' || allYear.length>0?false:true} title="View Expenses of August">Aug</button>
                      <button className={`btn-month${ activeMonth ==='09'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('09')} disabled={activeMonth ==='09' || allYear.length>0?false:true} title="View Expenses of September">Sep</button>
                      <button className={`btn-month${ activeMonth ==='10'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('10')} disabled={activeMonth ==='10' || allYear.length>0?false:true} title="View Expenses of October">Oct</button>
                      <button className={`btn-month${ activeMonth ==='11'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('11')} disabled={activeMonth ==='11' || allYear.length>0?false:true} title="View Expenses of November">Nov</button>
                      <button className={`btn-month${ activeMonth ==='12'?' active cursor-disable' : ''}${ allYear.length>0?'':' cursor-disable'}`} onClick={()=>handleChangeMonth('12')} disabled={activeMonth ==='12' || allYear.length>0?false:true} title="View Expenses of December">Dec</button>
                </div>  
            </div>
            <div className="listing-wrapper">
                  <ExpenseListing  expensesData={allExpenseData} year={activeYear} loader={tableloader} confirmation={confirmation}/> 
            </div>

            <div className="footer-wrapper">
            {!tableloader  && <div className="total-expense-wrapper"><div> <strong> Total Tax:</strong> { separateComma(totalExpenseCount?.totalExpenseTax)}</div><div> <strong> Total Expense:</strong> { separateComma(totalExpenseCount?.totalExpenseAmount)}</div></div>}
            <div className="years-wrapper">
                        <div className={`year-inner-container`}>
                            {!tableloader &&
                                allYear?.map((year) => { return (<button className={`btn btn-primary ${activeYear === `${year?.year}` || tableloader===true ? 'active cursor-disable' : ''}`} onClick={()=>handleChangeYear(year?.year)} key={year?.year} title={`View Expenses For Year ${year?.year}`}><span>{year?.year}</span></button>) })
                            }
                        </div>

                    </div>
            </div>


          
          </div>    

        </div>
      </div>
      <ReactPortal>
        {isConfirmation && (
          <Modal
            header=""
            type="confirm"
            recordName={`Are you sure want to delete ${deleteModal} expense`}
            icon={"trash-alt text-danger"}
          >
            <button
              className="btn btn-danger m-1 col-md-3 col-12"
              onClick={() => handleDeleteSingleExpense()}
              title='Yes, delete Expense.'
            >
              {" "}
              Yes
            </button>
            <button
              className="btn btn-secondary m-1 col-md-3 col-12"
              onClick={() => closeConfirmation()}
              title='Do not delete.'
            >
              No
            </button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
    </>
  )
}

export default Expenses