import React, { useState } from "react";
// import { deepCopy } from "../../utils/utils";
import InvoiceDescription from "./InvoiceDescription";
// import InvoiceDescriptionRow from './InvoiceDescriptionRow';
import InvoiceNotes from "./InvoiceNotes";
import PersonalDetails from "./PersonalDetails";
// import TaxationDiscount from "./TaxationDiscount";
// import uuid from 'react-uuid';
// import { idGenerator } from "../../utils/utils";
import "./formStepper.css";
import { errorMessage } from "../toastMessages/ToastifyMsg";
// import CustombuttonSpinner from "../CustombuttonSpinner";
// import countriesCurrencies from "../../assets/js/countriesCurrency";
// import ReactPortal from "../reactPortal/ReactPortal";
// import Modal from "../modal/Modal";
// import InvoicePaymentRecord from "./InvoicePaymentRecord";
// import uuid from "react-uuid";

function Edit({
  formData,
  handleBlur,
  handleAddDescription,
  handleRemoveDescription,
  handleChange,
  handleFileInputChange,
  saveModal,
  logoImage,
  removeImage,
  addFooterImages,
  update,
  type,
  prefix,
  footerImagesA,
  removeSingleFooterImage,
  fillFields,
  autoSuggstion,
  suggestList,
  autoSuggstionitems,
  fillItemFields,
  allDescitems,
  showSuggest,
  setShowSuggest,
  setShowSuggestItem,
  showSuggestItem,
  handleHideSuggestion,
  handleTeleFields
}) {
  //  console.log(formData?.color);

  const [showStep, setShowStep] = useState(0); // state for form steps
  const [stepperState,setStepperState] = useState("left");
  // const [stepValid,setStepValid] = useState(false);
  const [toName,setToName] = useState(false);
  const [toEmail,setToEmail] = useState(false);
  const [fromName,setFromName] = useState(false);
  const [fromEmail,setFromEmail] = useState(false);


  const stepVerify = () => {

    if (formData.senderName.trim() === '' || formData.senderEmail.trim() === '' || formData.customerName.trim() === '' || formData.customerEmail.trim() === '' ) {
        if (formData.senderName.trim() === '') {
          setFromName(true);
          setTimeout(() => {
            setFromName(false);
          }, 1000);
        }
        if (formData.senderEmail.trim() === '') {
          setFromEmail(true);
          setTimeout(() => {
            setFromEmail(false);
          }, 1000);
        }
        if (formData.customerName.trim() === '') {
          setToName(true);
          setTimeout(() => {
            setToName(false);
          }, 1000);
        }
        if (formData.customerEmail.trim() === '') {
          setToEmail(true);
          setTimeout(() => {
            setToEmail(false);
          }, 1000);
        }
        errorMessage("Enter required fields to proceed.")
    }
    else{

      setShowStep(showStep + 1);
      setStepperState("left");
    }
  }
  

  // console.log("payment record", formData.payments);

  return (
    <>
      <div className="container px-0 overflow-hidden">
        <div className="col-12">
          <div className="invoiceForm py-3">
            <ul className="form-stepper form-stepper-horizontal text-center mx-auto pl-0">
              {/* Step 1  */}
              <li
                className={`text-center form-stepper-list ${
                  showStep === 0
                    ? "form-stepper-active"
                    : "form-stepper-completed"
                }`}
                step="1"
              >
                <button
                  className="mx-2"
                  type="button"
                  onClick={() =>{if(update){setShowStep(0)} }}
                  title="Personal Details"
                >
                  <span className="form-stepper-circle">
                    <span>
                      {" "}
                      <i className="fas fa-user"></i>
                    </span>
                  </span>
                  {/* <div className="label">Personal <br></br> Details</div> */}
                </button>
                {/* <h5>Personal Details </h5>
                <i className="fa fa-chevron-right"></i> */}
              </li>
              {/* Step 2  */}
              <li
                className={`form-stepper-unfinished text-center form-stepper-list ${
                  showStep === 1
                    ? "form-stepper-active"
                    : showStep === 2
                    ? "form-stepper-completed"
                    : ""
                }`}
                step="2"
              >
                <button
                  className="mx-2"
                  type="button"
                  onClick={() => {if(update) { setShowStep(1)}} }
                  title="Invoice Description"
                >
                  <span className="form-stepper-circle text-muted">
                    <span>
                      <i className="fa fa-prescription-bottle"></i>
                    </span>
                  </span>
                  {/* <div className="label text-muted">Invoice <br></br> Description</div> */}
                </button>
              </li>
              {/* Step 3 */}
              <li
                className={`form-stepper-unfinished text-center form-stepper-list ${
                  showStep === 2 ? "form-stepper-active" : ""
                }`}
                step="3"
              >
                <button
                  className="mx-2"
                  type="button"
                  onClick={() =>{if(update){setShowStep(2)}} }
                  title="Invoice Notes"
                >
                  <span className="form-stepper-circle text-muted">
                    <span>
                      <i className="fa fa-sticky-note"></i>
                    </span>
                  </span>
                  {/* <div className="label text-muted">Invoice <br></br> Notes</div> */}
                </button>
              </li>
            </ul>
          </div>
          <div
            className="invoiceFormContainer"
            style={{ borderTop: `4px solid ${formData?.color}` }}
          >
            {" "}
            <form onSubmit={(event) => saveModal(event)}>
              <div className="formStepsContainer">
                {showStep === 0 && (
                  <div data-aos={`fade-${stepperState}`}>
                    <PersonalDetails
                      formData={formData}
                      handleBlur={handleBlur}
                      handleFileInputChange={handleFileInputChange}
                      logoImage={logoImage}
                      removeImage={removeImage}
                      fillFields={fillFields}
                      autoSuggstion={autoSuggstion}
                      suggestList={suggestList}
                      prefix={prefix}
                      showSuggest={showSuggest}
                      setShowSuggest={setShowSuggest}
                      handleTeleFields={handleTeleFields}
                      toName= {toName}
                      toEmail= {toEmail}
                      fromName={fromName}
                      fromEmail={fromEmail}
                    />

                  </div>
                )}
                {showStep === 1 && (
                  <div data-aos={`fade-${stepperState}`}>
                    <InvoiceDescription
                      handleBlur={handleBlur}
                      formData={formData}
                      handleAddDescription={handleAddDescription}
                      handleRemoveDescription={handleRemoveDescription}
                      handleChange={handleChange}
                      autoSuggstionitems={autoSuggstionitems}
                      fillItemFields={fillItemFields}
                      allDescitems={allDescitems}
                      type={type}
                      showSuggestItem={showSuggestItem}
                      setShowSuggestItem={setShowSuggestItem}
                      handleHideSuggestion={handleHideSuggestion}
                    />

                  </div>
                )}
                {/* {showStep === 2 && (
                    <TaxationDiscount
                      formData={formData}
                      setFormData={setFormData}
                    />
                  )} */}
                {showStep === 2 && (
                  <div data-aos={`fade-${stepperState}`}>
                    <InvoiceNotes
                      formData={formData}
                      handleBlur={handleBlur}
                      addFooterImages={addFooterImages}
                      footerImagesA={footerImagesA}
                      removeImage={removeSingleFooterImage}
                      // updateImageDetail={updateImageDetail}
                    />

                  </div>
                )}
              </div>

              <div className="formNavigateContainer">
                {showStep !== 0 && (
                  <button
                    type="button"
                    disabled={showStep === 0 ? true : false}
                    onClick={() => {setShowStep(showStep - 1);setStepperState("right");}}
                    className="btn btn-primary btn-sm"
                    title="Go Back To Previous Step"
                  >
                    <span>
                      {" "}
                      <i className="fas fa-chevron-left"></i>
                    </span>
                    &nbsp; <span> Previous</span>
                  </button>
                )}
                {showStep < 2 && (
                  <button
                    type="button"
                    onClick={stepVerify}
                    className="btn btn-primary btn-sm"
                    // disabled={stepValid ? true:false}
                    title="Go To Next Step"
                  >
                    <span> Next </span>&nbsp;
                    <span>
                      {" "}
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </button>
                )}
                {/* onClick={() => alert("Final Step reached(Saved). showStep->" + showStep)} */}
                {showStep === 2   && (
                  <button
                    type="sumit"
                    title={
                      formData?.senderName !== "" &&
                      formData?.senderEmail !== "" &&
                      formData.invoicedate !== "" &&
                      formData?.customerName !== "" &&
                      formData?.customerEmail !== ""
                        ? `Click to ${update === true ? "Update" : "Save"}.`
                        : "Please fill all required fields."
                    }
                    className={`btn btn-save btn-sm ${
                      formData?.senderName !== "" &&
                      formData?.senderEmail !== "" &&
                      formData?.customerName !== "" &&
                      formData?.customerEmail !== ""
                        ? ""
                        : "cursor-disable"
                    }`}
                    disabled={
                      formData?.senderName !== "" &&
                      formData?.senderEmail !== "" &&
                      formData?.customerName !== "" &&
                      formData?.customerEmail !== "" &&
                      formData.invoicedate !== ""
                        ? false
                        : true
                    }
                  >
                    {update === true ? "Update" : "Save"}{" "}
                    <span>
                      &nbsp; <i className="fas fa-save"></i>{" "}
                    </span>
                  </button>
                )}
                {/* {update &&
                     <button
                     type="sumit"
                     title={
                       formData?.senderName !== "" &&
                       formData?.senderEmail !== "" &&
                       formData.invoicedate !== "" &&
                       formData?.customerName !== "" &&
                       formData?.customerEmail !== ""
                         ? "Click to save."
                         : "Please fill all required fields."
                     }
                     className={`btn btn-save btn-sm ${
                       formData?.senderName !== "" &&
                       formData?.senderEmail !== "" &&
                       formData?.customerName !== "" &&
                       formData?.customerEmail !== ""
                         ? ""
                         : "cursor-disable"
                     }`}
                     disabled={
                       formData?.senderName !== "" &&
                       formData?.senderEmail !== "" &&
                       formData?.customerName !== "" &&
                       formData?.customerEmail !== "" &&
                       formData.invoicedate !== ""
                         ? false
                         : true
                     }
                   >
                     Update
                     <span>
                       &nbsp; <i className="fas fa-save"></i>{" "}
                     </span>
                   </button>
                } */}
              </div>
            </form>
          </div>
        </div>
        {/* <div className="col-sm-12 col-md-12 col-lg-4 pt-5">
            <TaxationDiscount handleBlur={handleBlur} formData={formData}/>
          </div> */}
      </div>
      {/* <ReactPortal>
            {isOpen && 
            <Modal handleClose={handleModalClose} header="Record Payment">
              <InvoicePaymentRecord handleDeletePayment={handleDeletePayment} handleAddPayment={handleAddPayment} handleModalClose={handleModalClose} formData={formData}/>
            </Modal>}
      </ReactPortal> */}
    </>
  );
}

export default Edit;
