import React, { useEffect, useState } from "react";
// import { ThreeCircles } from "react-loader-spinner";
import {  useNavigate } from "react-router-dom";
import Modal from "./components/modal/Modal";
import ReactPortal from "./components/reactPortal/ReactPortal";
import {
  errorMessage,
  successMessage,
} from "./components/toastMessages/ToastifyMsg";
import AllInvoices from "./Invoice/AllInvoices";
import Outstanding from "./Invoice/Outstanding";
import Paid from "./Invoice/Paid";
import {
  useDeleteSingleInvoiceMutation,
  useDuplicateInvoiceMutation,
  useGetAllInvoicesQuery,
} from "./store/apis/baseApi";
import CustomLoader from "./components/CustomLoader";
import CustomDeleteloader from "./components/CustomDeleteloader";
import SendEmail from "./components/SendEmail";
import axios from "axios";
import { deepCopy } from "./utils/utils";
// import { useEmailInvoiceMutation } from "./store/apis/laravelApi";
import { LARAVELAPI } from "./common/common";
import { useDispatch, useSelector } from "react-redux";
import { setPlanDetails } from "./store/slices/subscribedPlanDetails";

function Invoice() {

  const LARAVELurl = LARAVELAPI;
  
  const  navigate = useNavigate();
  const dispatch = useDispatch();

  const [showInvoice, setShowInvoice] = useState(1);
  const//  [getAllInvoices] 
{
    data,
    error,
    isLoading,
    // refetch: allInvoiceRefetch,
  } 
  // = useLazyGetAllInvoicesQuery()
   = useGetAllInvoicesQuery();

  //  const [emailInvoice] = useEmailInvoiceMutation();

  const invoicePlanDetail = useSelector(state=>state.planDetails);

  const [allInvoices, setAllInvoices] = useState([]);
  const [deleteSingleInvoice] = useDeleteSingleInvoiceMutation();
  const [loader, setLoader] = useState(false);
  const [deleteModalInvoice, setDeleteModalInvoice] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [emailModal,setEmailModal] =useState();
  const [deleteId, setDeleteId] = useState();
  const [emailTitle,setEmailTitle] = useState("");
  const [emailbuttonLoader,setEmailbuttonLoader] = useState(false);
  const [duplicateInvoice] = useDuplicateInvoiceMutation(); 
  const [isConfirmDuplicate,setIsConfirmDuplicate] = useState(false);
  const [duplicateId,SetDuplicateId] = useState("");
  const [duplicateName,SetDuplicateName] = useState("");
  const  [duplicateLoader,setDuplicateLoader] = useState(false);
  const [tableloader,setTableloader] = useState(false);
  const [tableLoader,setTableLoader] = useState(true);
  // sendEmail Body
  const [emailBody,setEmailBody] = useState({
    id: "",
    email_to:"",
    email_body: "Kindly find the attached document.",
    attachment: "true",
    cc_status : "false",
    cc_emails: ""
  });

  useEffect(() => {
    setTimeout(() => {
      setTableLoader(isLoading);
    }, 200);
  }, [isLoading]);

  useEffect(() => {
    (async()=>{
      setTableloader(true);
      // const {data,error}= await getAllInvoices();
      try {
        if (data) {
          if (data.success) {
            let invoiceData = deepCopy(data.data);
            let invoices = invoiceData.map((data) => {
              return { ...data, currency: JSON.parse(data.currency) };
            });
            let filtered = invoices.filter((invoice) => invoice.type === "chargable");
           
            setAllInvoices(filtered || []);
            // setFilterInvoice(invoiceData.filter(invoice=> invoice.status === "outstanding") || []);
          }
          if (!data.success) {
              errorMessage(data.message);
              setAllInvoices([]);
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message);
        }
        
      } catch (error) {
        
      }
      setTableloader(false);
    })()

  }, [data,error]);

  const confirmation = (id, name) => {
    setIsConfirmation(true);
    // deleteInvoice(id);
    setDeleteModalInvoice(name);
    setDeleteId(id);
  };

  // useEffect(() => {
  //   console.log("invoicePlanDetail::",invoicePlanDetail)
  // }, [invoicePlanDetail])


  // const decreaseInvoicePlan = ()=>{
  //   const newData = JSON.parse(JSON.stringify(invoicePlanDetail?.planDetails))
  //   newData.plan_usage_details.invoice.usage_count -= 1;
  //   dispatch(setPlanDetails(newData));
  // }

  const deleteInvoice = async (value) => {
    if (value === true) {
      setIsConfirmation(false);
      setLoader(true);
      try {
        const { data, error } = await deleteSingleInvoice(deleteId);
  
        if (data) {
          // console.log("invoiceData",data);
            if (data.success) {
              setLoader(false);
              successMessage(data.message);
            }
            if (!data.success) {
          // console.log("!success>>invoiceDeleted",data);
              setLoader(false);
              errorMessage(data.message);
            }
        }
        if (error) {
          // console.log("invoice!deleted", error);
          errorMessage(error?.error ?? error?.data?.error?.message);
        }
        
      } catch (error) {
        
      }
    } else {
      setIsConfirmation(false);
      setDeleteId("");
      setDeleteModalInvoice("");
    }
  };

  const handleOpenEmail = (title,invID,customerEmail)=>{
    if (invoicePlanDetail?.planDetails?.plan_usage_details?.email_sent?.usage_count<invoicePlanDetail?.planDetails?.plan_usage_details?.email_sent?.total) {
      setEmailModal(true);
      setEmailBody((prevState)=>{
        return{
          ...prevState,
          id: invID,
          email_to: customerEmail,
        }
      })
      setEmailTitle(title);
    }else{
      errorMessage("Limit Reached. Upgrade your plan.");
    }
  }
  
  const handleCloseEmail = ()=>{
      setEmailModal(false);
      setEmailBody((prevState)=>{
        return{
          ...prevState,
          id: "",
          email_to:"",
          cc_status: "false",
          cc_emails:""
        }
      })
      setEmailTitle("")
  }
  
  // const handleCloseDuplicate = ()=>{
  //   setIsConfirmDuplicate(false);
  // }

  const duplicateConfirmation = (id, name) => {
    if (invoicePlanDetail?.planDetails?.plan_usage_details?.invoice?.usage_count <invoicePlanDetail?.planDetails?.plan_usage_details?.invoice?.total) {
      setIsConfirmDuplicate(true);
      // deleteInvoice(id);
      SetDuplicateName(name);
      SetDuplicateId(id);
    }
    else{
      errorMessage("Limit Reached. Upgrade your plan!");
    }
    // console.log(id, name );
  };

  const increaseInvoicePlan = (type)=>{
    const newPlanDetails = JSON.parse(JSON.stringify(invoicePlanDetail?.planDetails))
    newPlanDetails.plan_usage_details[type].usage_count += 1
    dispatch(setPlanDetails(newPlanDetails));
  }

  const createDuplicateSubmit = async (value) => {
    // console.log(value);
      if (value === true) { 
          if (invoicePlanDetail?.planDetails?.plan_usage_details?.invoice?.usage_count<invoicePlanDetail?.planDetails?.plan_usage_details?.invoice?.total) {
            try{
              setIsConfirmDuplicate(false);
              setDuplicateLoader(true);
                // console.log("duplicate invoice", duplicateId);
                const {data,error} = await duplicateInvoice(duplicateId);
                if (data) {
                  // console.log("duplicate>>",data);
                  if (data.success) {  
                    increaseInvoicePlan("invoice");
                    successMessage(data.message);
                  }
                  if (!data.success) {
                    errorMessage(data.message);
                  }
                }
                if (error) {
                  // console.log("invoice!deleted", error);
                  errorMessage(error?.error ?? error?.data?.error?.message)
                }
    
            }
            catch{
              // console.log(error)
            }
            finally{
              setDuplicateLoader(false)
            }
          }
          else{
            errorMessage("Limit reached for Invoices.Upgrade your plan!");
            navigate('/subscription-plans');
          }     
        } 
      if(value === false) {
        setIsConfirmDuplicate(false);
        SetDuplicateId("");
        SetDuplicateName("");
      }
  }
  
  

  // email
  const emailChange = (e)=>{
    e.preventDefault();
    const {name,value} = e.target;
    if (name === "cc_status" & value === "false") {
      setEmailBody((prevState)=>{
        return({
          ...prevState, cc_emails:""
        })
      })
    }
    setEmailBody((prevState)=>{
      return({
        ...prevState, [name]:value
      })
    })

}

const handleEmail= async(e)=>{
  e.preventDefault();
  setEmailbuttonLoader(true);
  try {
    let body={
      id: emailBody?.id,
      email_to: emailBody?.email_to,
      email_body: emailBody?.email_body,
      attachment: emailBody?.attachment,
      cc_status: emailBody?.cc_status,
      cc_emails: emailBody?.cc_emails,
      type: "invoice"
    }
    // https://stagingfront.tradingforms.com/api/invoice_email?id=59
    // console.log(body);
    let token =  localStorage.getItem("tradingformtoken");
    let {data, error }= await axios(`${LARAVELurl}invoice_email`, {
      method: "POST",
      data: body,
      headers: {
        "Content-Type": "application/json;",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      // const {data,error} = await emailInvoice(body);
      if (data) {
          if (data.success) {
            successMessage(data?.message)
            setEmailbuttonLoader(false);
            setEmailModal(false);
            // const newPlanDetails = JSON.parse(JSON.stringify(invoicePlanDetail?.planDetails))
            // // const newPlanDetails = { ...invoicePlanDetail.planDetails };
            // newPlanDetails.plan_usage_details.email_sent.usage_count += 1
            // dispatch(setPlanDetails(newPlanDetails));
            increaseInvoicePlan("email_sent");
            setEmailBody((prevState)=>{
              return{
                ...prevState,
                id: "",
                email_to:"",
                attachment:"true",
                cc_status: "false",
                cc_emails:""
              }
            })
          }
          if (!data.success) {
              errorMessage(data.message);
              setEmailbuttonLoader(false);
          }
          

      }
      if (error) {
        errorMessage(error?.error ?? error?.data?.error?.message ?? error?.message) ;     
        setEmailbuttonLoader(false);
      }
    
  } catch (error) {
  }
  finally{
    setEmailbuttonLoader(false);
  }
}

const navigateFunc = () => {navigate('/email-history',{ state: { emailHistory: 'invoice' }})};

const handleCreateNavigate=() => {
  if (invoicePlanDetail?.planDetails?.plan_usage_details?.invoice?.usage_count<invoicePlanDetail?.planDetails?.plan_usage_details?.invoice?.total) {
    navigate('create');
  }else {
    errorMessage("Limit Reached. Upgrade your plan!");
    navigate('/subscription-plans');
  } 
}



  return (
    <>
      {loader === true && <CustomDeleteloader message="Deleting . . ." />}
      {duplicateLoader === true && <CustomDeleteloader message="Duplicating . . ." />}
      {/* <div className="spacer"></div> */}
      <div className="spacer"></div>
      <div className="invoice-container">
        <div className="container invoice-list" data-aos="zoom-in">
          <div className="invoice-actions">
            <div className="invoice-navBtn invoice-navBtn-custom">
              <button
                onClick={() => setShowInvoice(1)}
                className={`btn btn-secondary btn-sm ${
                  showInvoice === 1 ? "active cursor-disable" : ""
                }`}
                type="button"
                disabled={showInvoice === 1 ? true:false}
                title="View All Invoices"
              >
                All Invoices
              </button>
              <button
                onClick={() => setShowInvoice(2)}
                className={`btn btn-secondary btn-sm ${
                  showInvoice === 2 ? "active cursor-disable" : ""
                }`}
                type="button"
                disabled={showInvoice === 2 ? true:false}
                title="View Outstanding Invoices"
              >
                Outstanding
              </button>
              <button
                onClick={() => setShowInvoice(3)}
                className={`btn btn-secondary btn-sm ${
                  showInvoice === 3 ? "active cursor-disable" : ""
                }`}
                type="button"
                disabled={showInvoice === 3 ? true:false}
                title="View Paid Invoices"
              >
                Paid
              </button>
            </div>
            <div className="searchCreate-invoice">
              {/* <div className="invoice-searchContainer col-xs-12 col-sm-9 col-md-7">
                <input
                  type="text"
                  name=""
                  placeholder="Search By Client Name"
                ></input>
              </div> */}
              <div className="">
                <button onClick={navigateFunc} type="button" className="btn btn-secondary btn-sm email-history" title="View sent emails history for Invoices & Quotations">
                    <i className="fa fa-history"></i> <span>Email History</span> 
                </button>
              </div>
              <div className="createInvoice">
                <button
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  to={{
                    pathname: "create",
                    // state: `${allInvoiceRefetch}`,
                  }}
                  // state={{allInvoiceRefetch}}
                  onClick={handleCreateNavigate}
                  title="Go to Create New Invoice"
                >
                  <i className="fa fa-plus pe-2"></i> <span>Create Invoice</span>
                </button>
              </div>
            </div>
          </div>
          {
            tableloader && 
            <CustomLoader />
          }
            <div className="container px-0 fadeInOpacity overflow-hidden">
              {showInvoice === 1 && (
                <AllInvoices
                  allInvoice={allInvoices}
                  deleteInvoice={confirmation}
                  handleOpenEmail={handleOpenEmail}
                  duplicateInvoice={duplicateConfirmation}
                  isLoading={tableLoader}
                />
              )}
              {showInvoice === 2 && (
                <Outstanding
                  allInvoice={allInvoices}
                  deleteInvoice={confirmation}
                  handleOpenEmail={handleOpenEmail}
                  duplicateInvoice={duplicateConfirmation}
                  isLoading={tableLoader}
                />
              )}
              {showInvoice === 3 && (
                <Paid allInvoice={allInvoices} 
                deleteInvoice={confirmation} 
                handleOpenEmail={handleOpenEmail} 
                duplicateInvoice={duplicateConfirmation}
                isLoading={tableLoader}
                />
              )}
            </div>
          {/* <div className="invoice-list-footer">
            <span>All your invoices are auto saved here</span>
          </div> */}
        </div>
      </div>
          {/* delete modal */}
      <ReactPortal>
        {isConfirmation && (
          <Modal
            header=""
            type="confirm"
            recordName={`Are you sure want to delete Invoice# ${deleteModalInvoice}`}
            icon={"trash-alt text-danger"}
          >
            <button
              className="btn btn-danger m-1 col-md-3 col-12"
              onClick={() => deleteInvoice(true)}
              title="Yes, Delete Invoice."
            >
              {" "}
              Yes
            </button>
            <button
              className="btn btn-secondary m-1 col-md-3 col-12"
              onClick={() => deleteInvoice(false)}
              title="Do not delete."
            >
              No
            </button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
      
          {/* Email modal */}
      <ReactPortal>
          { emailModal &&
                <Modal handleClose={handleCloseEmail} type="form" contentType="email-form" header={`Enter details to send mail for ${emailTitle}`}>
                    <SendEmail emailBody={emailBody} handleSubmit={handleEmail} emailChange={emailChange} loader={emailbuttonLoader}/>
                </Modal>
          }
      </ReactPortal>
      {/* duplicate modal */}
      <ReactPortal>
        {isConfirmDuplicate && (
          <Modal
            header=""
            type="confirm"
            recordName={`Are you sure want to duplicate Invoice# ${duplicateName}`}
            icon={"copy text-info"}
          >
            <button
              className="btn btn-save m-1 col-md-3 col-12"
              onClick={() => createDuplicateSubmit(true)}
              title="Yes, duplicate Invoice."
            >
              {" "}
              Yes
            </button>
            <button
              className="btn btn-secondary m-1 col-md-3 col-12"
              onClick={() => createDuplicateSubmit(false)}
              title="Do not duplicate"
            >
              No
            </button>
          </Modal>
        )}
      </ReactPortal>



    </>
  );
}
export default Invoice;
