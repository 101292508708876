import React from 'react'
import { Link } from 'react-router-dom'

function SubscriptionFailure() {
    return (

            <div className='container p-5'>
                <div className="subscribed-card rounded-lg col-md-6 col-xl-5">
                    <div style={{ 'borderRadius': '200px', 'height': '150px', 'width': '150px', 'background': '#F8FAF5', 'margin': '0 auto', }}>
                        {/* <span className="checkmark text-danger m-0" style={{'lineHeight': '150px'}}>╳ </span> */}
                        {/* <i className='fa fa-times checkmark text-danger m-0' style={{'lineHeight': '150px'}}></i> */}
                        <i className="fas fa-exclamation checkmark text-danger m-0" style={{'lineHeight': '150px','fontSize':'70px'}}></i>
                    </div>
                    <h1 className='text-danger'>Error</h1>
                    <p>There was a problem with your payment.<br />
                        {/* You will shortly receive an <br/> email of your payment. */}
                    </p>
                    {/* <button className='btn btn-success mt-3' onClick={() => handlePaymentSuccess()}>Proceed</button> */}
                    <Link to='/subscription-plans' className='btn btn-primary mt-4'><i className='fa fa-arrow-left me-2 align-middle'></i> Try again</Link>
                </div>

            </div>
    )
}

export default SubscriptionFailure