import React, { useState } from 'react'

function UploadSign({handleChangeFileSig}) {
    const [showLogo, setShowLogo] = useState(false);
    // const [tempLogo, setTempLogo] = useState(null)

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        event.target.value = "";
        // console.log("logo",event.target.name)
        reader.onloadend = () => {
            setShowLogo(reader.result);
            handleChangeFileSig(reader.result);
        };

        reader.readAsDataURL(file);

        if (!file) {
            setShowLogo(false)
            handleChangeFileSig(false);
        }
        // else {
        //     // setFormData({
        //     //   ...formData,
        //     //   logo: file,
        //     // });
        //     setTempLogo(file);
        // }
    }
    const removeImage= async()=>{
        setShowLogo(false);
        handleChangeFileSig(false);
        // setTempLogo(null);
      }
    // const handleSubmitSign = (image)=>{
    //     if (image) {
    //         handleSaveUploadSign(image)
    //     }
    // }
  return (
  <>
        {/* <div>UploadSign</div> */}
        <div className="formField col-12">
                {/* <label className="col-12" htmlFor="logo">
                    Company Logo
                </label> */}
                <div className="col-12 bussinessLogo">
                    <div className="invoiceLogo my-1 px-0">
                        <div className="logoContainer form-control">
                            {showLogo === false &&
                                <div className="logolabel">
                                    {/* <i className="fas fa-image"></i> */}
                                    <span>Upload Signature</span>
                                </div>
                            }
                            <input type="file" title='Choose Signature' className="form-control" accept=".png,.jpeg,.jpg,.webp" name="logo" defaultValue={''} onChange={handleFileInputChange}></input>
                            <span className="showlabel"><i className="fa fa-plus"></i>
                                {showLogo === false ? ' Add ' : ' Update '

                                }
                                your Signature</span>
                            {showLogo !== false &&
                                <span className="removeImage" onClick={removeImage}><i className="fa fa-times"></i></span>
                            }

                            {showLogo !== false &&
                                <img src={showLogo} className="logoImage" alt="test" height={130} width={200} />
                            }
                        </div>

                    </div>

                </div>
        </div>
        {/* <div className="save-upload-sign">
                <button disabled={!showLogo} type='button' className="btn btn-save w-100" onClick={()=>handleSubmitSign(showLogo)}>Save</button>
        </div> */}
  </>
  )
}

export default UploadSign