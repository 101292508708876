import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment/moment";
import { dataTablecustomStyles, separateComma } from "../utils/utils";
// import CustombuttonSpinner from "../components/CustombuttonSpinner";
import TableLoader from "../components/loaders/TableLoader";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";



function Allsalaries({ deleteSalary, handleOpenEmail, duplicatepayslip, allPayslipsData, url, showFrom ,isLoading}) {
  const [allSalaries, setAllSalaries] = useState([]);
  const [search, setSearch] = useState("");
  const [filterSalaries, setFilterSalaries] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {

    setAllSalaries(allPayslipsData || []);
    setFilterSalaries(allPayslipsData || []);
   

  }, [allPayslipsData]);


  const columns = [
    {
      name: "Payslip #",
      selector: (row) => row.receipt_number,
      sortable: true,
      //  width: '125px'
    },



    !showFrom && (
      {
        name: "From",
        selector: (row) => (<span className="text-capitalize" >{row.from_name}</span>),
        // sortable: true,
      }

    ),
    
    {
      name: "To",
      selector: (row) => (<span className="text-capitalize">{row.to_employee_name}</span>),
      // sortable: true,
    }
    ,
    {
      name: "Date",
      selector: (row) => moment(row.salary_date).format("ll"),
      sortable: true,
    },
    {
      name: "Net Salary",
      // {row.currency_selected.Symbol}
      selector: (row) => (<div>
        <span>

          {row.currency_selected.Symbol}{separateComma(row.total_net_pay)}
        </span>
      </div>),
      //  width: '125px'
      // sortable: true,
    },
    {
      // name: "",
      cell: (row) => (
        <div className="d-flex gap-1" title="Select an action">
          <DropdownButton
              as={ButtonGroup}
              key={'start'}
              id={`dropdown-button-drop-${row.id}`}
              drop={'start'}
              variant="dropdown-menu-custom"
              title={<i className="fas fa-ellipsis-v"></i> }
              // size="sm"
            >
              <Dropdown.Item eventKey="1">
                    <div
                    className="d-flex gap-2 align-items-center listing-button-edits"
                    onClick={() => {
                      navigate(`/${url}/${window.btoa(`${row.id}`)}`);
                    }} 
                    title='View Payslip'
                  >
                    <i className="fa fa-eye"></i> View
                  </div>
              </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <div 
                className="d-flex gap-2 align-items-center listing-button-edits"
                onClick={() => {
                  navigate(`/${url}/${window.btoa(`${row.id}`)}`);
                }}
                title="Edit Payslip"
              >
                <i className="fa fa-edit"></i> Edit
              </div>
            </Dropdown.Item>

              <Dropdown.Item eventKey="3">
                <div
                  className="d-flex gap-2 align-items-center listing-button-edits"
                  onClick={() => deleteSalary(row.id, row.receipt_number, "salary")}
                  title={`Delete ${row.receipt_number}`}

                >
                  <i className="fa fa-trash-alt text-danger"></i> Delete
              </div>
              </Dropdown.Item>

              {/* <Dropdown.Divider /> */}

              <Dropdown.Item eventKey="4">
                <div onClick={()=>handleOpenEmail(row.receipt_number, row.id, row.to_employee_email)} className="d-flex gap-2 align-items-center listing-button-edits"
                title="e-mail Payslip"
                >
                    <i className="fa fa-mail-bulk"></i> Email  Payslip
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="5">
                <div onClick={() => duplicatepayslip(row.id, row.receipt_number, "salary")}  className="d-flex gap-2 align-items-center listing-button-edits" title={`Duplicate ${row.receipt_number}`}>
                    <i className="fa fa-copy"></i> Duplicate 
                </div>
              </Dropdown.Item>

            </DropdownButton>
        </div>
      ),
      width: '60px',
      right: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    const result = allSalaries.filter((invoice) => {
      return invoice?.to_employee_name?.toLowerCase().match(search?.toLowerCase());
    });
    setFilterSalaries(result);
  }, [search, allSalaries]);



  return (
    <>
      <div className="container px-0">
        <div className="py-2">
          {/* 
            title={"All Invoices"} 
            fixedHeader
            fixedHeaderScrollHeight="400px"
          */}
          {isLoading ? (<TableLoader />) : (
            <div className="table-wrapper" data-aos="fade-left">
              <DataTable
                columns={columns}
                data={filterSalaries}
                fixedHeader
                fixedHeaderScrollHeight="400px"
                className="table-scroll-custom"
                highlightOnHover
                pointerOnHover  
                responsive
                subHeader
                subHeaderComponent={
                  <div className="col-md-4 col-12">
                    {filterSalaries.length>0 &&
                      <div className="table-header-custom">
                        <label className="col-md-3 col-12 text-start" htmlFor="search">Search: </label>
                        <div className="col-md-9 col-12">
                          <input
                            type="search"
                            placeholder="Search by Consumer"
                            className="form-control"
                            value={search}
                            autoComplete="off"
                            // style={{ width: "28%" }}
                            onChange={(e) => setSearch(e.target.value)}
                            name="search"
                            id="search"
                          ></input>
                        </div>
                      </div>
                    }
                  </div>
                }
                // subHeaderAlign="left"
                onRowClicked={(row,event)=>{navigate(`/${url}/${window.btoa(`${row.id}`)}`);}}

                pagination
                sortIcon={<i className="fa fa-angle-down"></i>}
                customStyles={dataTablecustomStyles}
              />
            </div>
            )
          }
        </div>
      </div>
    </>
  );
}

export default Allsalaries;
