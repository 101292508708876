import React from 'react'
import CustombuttonSpinner from './CustombuttonSpinner'

function SendEmail({emailBody,handleSubmit,emailChange, loader}) {
    const handleCheckbox = (e) => {
        emailChange({
          preventDefault: e.preventDefault,
          target: {
            name: e.target.name,
            value: JSON.stringify(e.target.checked)
          }
        })
      }
  return (
    <>
        <div className="emailDataContainer">
            <form onSubmit={handleSubmit}>
                <div className="">
                    <div className="col-12">
                            <div className="formField">
                            <label className="col-md-3 col-12" htmlFor="email_to">
                                Email To <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9 col-12">
                                <input
                                className="form-control"
                                type="email"
                                name="email_to"
                                defaultValue={emailBody?.email_to}
                                onChange={(e)=>emailChange(e)}
                                //   onBlur={(e) => handleOnChange(e)}
                                required
                                placeholder="abc@gmail.com"
                                ></input>
                            </div>
                            </div>
                    </div>
                    <div className="col-12">
                          <div className="formField">
                              <label className="col-md-3 col-5" htmlFor="cc_status">
                                 <span>Include CC </span>
                              </label>
                              <div className="col-md-8 col-7">
                                  <input type="checkbox" name="cc_status" onChange={(e) => handleCheckbox(e)} id="cc_status" checked={emailBody?.cc_status === "true" ? true : false} />
                              </div>
                          </div>
                      </div>
                     {emailBody?.cc_status ==="true" && (
                        <div className="col-12">
                            <div className="formField">
                                <label className="col-md-3 col-12" htmlFor="cc_emails">
                                    CC Email  <span className="text-danger">*</span>
                                </label>
                                <div className="col-md-9 col-12">
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="cc_emails"
                                        defaultValue={emailBody?.cc_emails}
                                        onChange={(e) => emailChange(e)}
                                        //   onBlur={(e) => handleOnChange(e)}
                                        required
                                        placeholder="abc@gmail.com, xyz@gmail.com"
                                        multiple
                                    ></input>
                                    <small className='text-danger'><i> For multiple emails seperate it with comma ','</i></small>
                                </div>
                            </div>
                        </div>)
                     }
                    <div className="col-12">
                            <div className="formField align-items-start">
                                <label className="col-md-3 col-12" htmlFor="email_body">
                                    Email Body 
                                </label>
                            <div className="col-md-9 col-12">
                                <textarea
                                    className="form-control"
                                    name="email_body"
                                    defaultValue={emailBody?.email_body}
                                    //   onBlur={(e) => handleOnChange(e)}
                                    onChange={(e)=>emailChange(e)}
                                    placeholder="kinldy find the attached Quotation."
                                    rows={6}
                                ></textarea>
                            </div>
                            </div>
                    </div>
                      <div className="col-12">
                          <div className="formField">
                              <label className="col-md-3 col-5" htmlFor="attachment">
                                  <i className="fa fa-paperclip"></i>&nbsp;<span> Attach PDF</span>
                                  <span className="text-danger"> *</span>
                              </label>
                              <div className="col-md-8 col-7">
                                  <input type="checkbox" name="attachment" onChange={(e) => handleCheckbox(e)} id="attachment" checked={emailBody?.attachment === "true" ? true : false} />

                              </div>
                          </div>
                      </div>
                    <div className="text-end">
                        <button type="submit" className={`btn btn-sm button-email-invoice ${loader ? "cursor-disable": ""}`}  disabled={loader ? true:false}><i className="fa fa-paper-plane"></i>&nbsp; <span> Send</span>
                        {loader &&
                         <span className="spinner-container">
                          
                         <CustombuttonSpinner/>            
                       </span>
                        }</button>
                        
                    </div>
                </div>
            </form>
            
        </div>
    </>
  )
}

export default SendEmail