import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const laravelApi = createApi({
    reducerPath: "laravelApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "development" ? process.env.REACT_APP_LARAVELAPI_STAGE : process.env.REACT_APP_LARAVELAPI_PRODUCTION,

        // credentials: "include",

        prepareHeaders: (headers, { getState }) => {
            const stateToken = getState().auth.token;
            const token = localStorage.getItem("tradingformtoken");

            headers.set("Authorization", `Bearer ${stateToken || token || ""}`);

            // headers.set('Cross-Origin-Opener-Policy', '*');
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Access-Control-Request-Method", "*");

            return headers;
        }
    }),
    endpoints: (builder) => ({
        emailInvoice: builder.mutation({
            query: (body) => ({
                url: "invoice_email",
                method: 'POST',
                body
            })
        }),
        getSingleContract: builder.query({
            query: ({ id }) => {
                return {
                    url: `v1/get_single_contract?cid=${id}`,
                    method: 'GET'
                }
            }
        }),
        acceptTerms: builder.mutation({
            query: (body) => {
                return {
                    url: 'v1/accept_terms_conditions_contract',
                    method: 'POST',
                    body
                }
            }
        }),
        saveContractBody: builder.mutation({
            query: (body) => {
                return {
                    url: 'v1/send_contract_content_details',
                    method: "POST",
                    body
                }
            }
        }),
        // Contracts endpoints
        getAllContracts: builder.query({
            query: () => 'v1/get_all_contracts_listing'
        }),
        // subscription endpoints
        getSubscriptionPlans : builder.query({
            query : ()=>"v1/pricing_packages"
        }),
        createStripeCheckoutSession: builder.mutation({
            query: (body)=>({
                url: 'v1/payment_creating_session',
                method: 'POST',
                body
            })
        }),
        checkSubscriptionOrder: builder.mutation({
            query: (body)=>({
                url:'v1/check_order_placement',
                method: 'POST',
                body
            })
        }),
        currentSubscribedPlan: builder.mutation({
            query: (body)=>({
                url: 'v1/current_subscription_package_activated_check',
                method: 'POST',
                body
            })
        }),
        activatedPlan: builder.mutation({
           query: (body)=>({
            url: 'v1/customer_demo_services',
            method: 'POST',
            body
           })
        }),
        getSubscriptionHistory: builder.mutation({
            query: (body)=>({
                url: '/v1/oders_suscriptiones_purchase_history',
                method: 'POST',
                body
            })
        }),
        getSubscriptionUsage: builder.mutation({
            query: (body)=>({
                url:"v1/suscriptiones_usage_details",
                method: 'POST',
                body
            })
        }),
        getUserPlanService: builder.mutation({
            query: (body)=>({
                url: "v1/suscriptiones_usage_details_new" ,
                method: 'POST',
                body,
            })
        })


    })
})
export const {
    useEmailInvoiceMutation,
    useLazyGetSingleContractQuery,
    useAcceptTermsMutation,
    useSaveContractBodyMutation,
     // contracts
    useLazyGetAllContractsQuery,
    // subscriptions
    useLazyGetSubscriptionPlansQuery,
    useCreateStripeCheckoutSessionMutation,
    useCheckSubscriptionOrderMutation,
    useCurrentSubscribedPlanMutation,
    useActivatedPlanMutation,
    useGetSubscriptionHistoryMutation,
    useGetSubscriptionUsageMutation,
    // 
    useGetUserPlanServiceMutation,
} = laravelApi;
export default laravelApi;