import React from 'react'
import "../.././assets/css/PageNotFound.css"
import { Link } from 'react-router-dom'

function PageNotFound() {
    return (
        <>
            <div className="error-page-container">
                <div className="container error-pages">
                    <div className="col-12 ground-color text-center">
                        <h2>Oops ! <br />Page not found</h2>
                        <div className="errorPage">
                            <div className="clip"><div className="shadow"><span className="digit numberThree">4</span></div></div>
                            <div className="clip"><div className="shadow"><span className="digit numberTwo">0</span></div></div>
                            <div className="clip"><div className="shadow"><span className="digit numberOne">4</span></div></div>
                        </div>
                    </div>
                    <div className="error-btn">
                        <div className="read-more py-2">
                            <Link to="/"
                            ><button className="custom-btn btn-1"> <i className="fas fa-home"></i> Back to Dashboard</button></Link>
                        </div>
                        {/* <div className="read-more-next py-2">
                            <a
                                href="{{ Config('constants.react_url') }}"
                            ><button className="custom-btn btn-1"> TRY IT FREE <i className="fas fa-sign-in-alt"></i></button></a>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default PageNotFound
