import React, { useCallback, useState } from 'react'
import { useLazyReportsQuery } from '../../store/apis/baseApi'
import { useEffect } from 'react';
import { errorMessage } from '../../components/toastMessages/ToastifyMsg';
import { deepCopy } from '../../utils/utils';
import InvoiceReport from './InvoiceReport';
import PayslipReport from './PayslipReport';
import ExportExcel from './ExportExcel';



function Reports() {
  const date = new Date();
  const [tableloader, setTableloader] = useState(true);
  const [showClass, setShowClass] = useState(1)
  const [activeYear, setActiveYear] = useState(JSON.stringify(date.getFullYear()));
  const [reportType, setReportType] = useState('invoices');
  const [reportClass, setReportClass] = useState('chargable');
  const [reportsData, setReportsData] = useState([]);
  const [allYear, setAllYears] = useState([]);

  const [reports] = useLazyReportsQuery();


  const dataFormatter = useCallback(
    (reportsAllData, currentYear) => {
        if (reportType === 'invoices') {
          let year = reportsAllData?.data?.years?.filter(year => { return JSON.stringify(year.year) === currentYear })
          year = year[0];
          let body = {
            year: year.year,
            month_name: reportsAllData?.data?.group,
            total_invoices: year.total_invoices,
            total_consumers: year?.total_consumers,
            discounted_amount: year?.discounted_amount,
            total_tax: year?.total_tax,
            actual_amount: year?.actual_amount,
            total_paid: year?.total_paid,
            total_outstandings: year?.total_outstandings,
          }
          year = body;
          let fullYearData = reportsAllData?.data?.months;
          fullYearData.unshift(year);
    
          return fullYearData;
        }
        if (reportType === 'salaries') {
          let year = reportsAllData?.data?.years?.filter(year => { return JSON.stringify(year.year) === currentYear })
          year = year[0];
          let body = {
            year: year.year,
            month_name: reportsAllData?.data?.group,
            total_salary_slips: year?.total_slips,
            total_consumers: year?.total_consumers,
            total_earnings: year?.total_earnings,
            total_deductions: year?.total_deductions,
            total_net_pay: year?.total_net_pay,
          }
    
          year = body;
          let fullYearData = reportsAllData?.data?.months;
          fullYearData.unshift(year);
          
          return fullYearData;
        
      }
    },
    [reportType]
  )

  useEffect(() => {
    (async () => {
      try {
        setTableloader(true);
        let params = {
          report_type: reportType, report_class: reportClass, year: activeYear, download: 'no'
        }
        const response = await reports(params);
        if (response?.data) {
          if (response?.data?.success) {
            let reportsAllData = deepCopy(response?.data);
            setAllYears(reportsAllData?.data?.years);
            if (reportsAllData?.data?.months.length === 0) {
              let yearCount = reportsAllData?.data?.years.length - 1
              setTableloader(true);
              setActiveYear(`${reportsAllData?.data?.years[yearCount].year}`)
            }
            if (reportsAllData?.data?.months.length) {
              dataFormatter(reportsAllData, activeYear);
            }
            setReportsData(reportsAllData);

          }
          if (!response?.data?.success) {
            errorMessage(response?.data?.message);
          }
        }
        if (response?.error) {
          errorMessage(response?.error?.error ?? response?.error?.data?.error?.message ?? response?.error.data?.message ?? "Server not responding.")
        }

      } catch (error) {

      } finally { setTableloader(false) }
    })()

  }, [reports, activeYear, reportType, reportClass, dataFormatter])

  const changeActiveYear = useCallback((year) => { setActiveYear(`${year}`); setTableloader(true); setReportsData(null); }, [])
  const changeReportType = useCallback((e) => {
    setReportType(e.target.value);
    setReportClass(e.target.value === "invoices" ? "chargable" : "singler");
    setShowClass(1);
    setTableloader(true);
    setReportsData(null);
  }, [])

  const changeReportClass = (tab, reportClass) => {
    setShowClass(tab);
    setReportClass(reportClass);
    setActiveYear(JSON.stringify(date.getFullYear()));
    setTableloader(true);
    setReportsData(null);
  }


  return (
    <div className="reports-wrapper">

      <div className="spacer"></div>
      {/* <div className="spacer"></div> */}
      <div className="invoice-container overflow-hidden">
        <div className="container invoice-list" data-aos="zoom-in">

          <div className="invoice-actions">
            <div className="invoice-navBtn invoice-navBtn-custom">
              <button
                onClick={() => changeReportClass(1, reportType === "invoices" ? 'chargable' : 'singler')}
                className={`btn btn-secondary btn-sm ${showClass === 1 ? "active cursor-disable" : ""}`}
                disabled={showClass === 1 ? true : false}
                type="button"
                title={`View ${reportType === "invoices" ? "Invoices" : "Single Payslip"} Reports`}
              >
                {reportType === "invoices" ? "Invoices" : "Single Payslip"}
              </button>
              <button
                onClick={() => changeReportClass(2, reportType === "invoices" ? 'quotation' : 'grouped')}
                className={`btn btn-secondary btn-sm ${showClass === 2 ? "active cursor-disable" : ""}`}
                disabled={showClass === 2 ? true : false}
                type="button"
                title={`View ${reportType === "invoices" ? "Quotations" : "Group Payslip"} Reports`}
              >
                {reportType === "invoices" ? "Quotations" : "Group Payslip"}
              </button>
              {/* <button
                onClick={() => setShowInvoice(3)}
                className={`btn btn-secondary btn-sm ${
                  showInvoice === 3 ? "active cursor-disable" : ""
                }`}
                type="button"
              >
                Paid
              </button> */}
            </div>
            <div className='report-type-inner col-md-6 col-12'>
              <label htmlFor="type">Select Report Type:</label>
              <div className='col-6'>
                <select value={reportType} className="form-select" onChange={changeReportType}>
                  <option value="invoices">Invoice</option>
                  <option value="salaries">Payslips</option>
                </select>

              </div>
            </div>


          </div>
          <div className="overflow-hidden">
              {
                reportsData &&
                <>
                  <div className='invoice-actions justify-content-end'>
                    <div className='searchCreate-invoice'>
                      <div className="export-button">
                        {tableloader === false &&
                          <ExportExcel reportsData={reportsData} reportClass={reportClass} reportType={reportType} />
                        }
                      </div>
                    </div>
                  </div>
                </>
              }
              {reportType === "invoices" &&
                <div data-aos="fade-right">
                  <InvoiceReport reportsData={reportsData} allYear={allYear} tableloader={tableloader} activeYear={activeYear} changeActiveYear={changeActiveYear} reportClass={reportClass} />
                </div>
              }

              {reportType === "salaries" &&
                <div data-aos="fade-right">
                  <PayslipReport reportsData={reportsData} allYear={allYear} tableloader={tableloader} activeYear={activeYear} changeActiveYear={changeActiveYear} reportClass={reportClass} />
                </div>
              }

          </div>


        </div>

      </div>
    </div>
  )
}

export default Reports