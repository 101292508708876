import { useState } from "react";
import React from 'react'
import './changepassword.css'
import { useUpdateUserPasswordMutation } from "../store/apis/baseApi";
import { errorMessage, successMessage } from "../components/toastMessages/ToastifyMsg";
import CustombuttonSpinner from "../components/CustombuttonSpinner";
import ReactPortal from "../components/reactPortal/ReactPortal";
import Modal from "../components/modal/Modal";
import { setUser } from "../store/slices/userSlice";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDeleteloader from "../components/CustomDeleteloader";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [updateUserPassword] = useUpdateUserPasswordMutation();
  const [loader, setLoader] = useState(false);
  const [logoutLoader, setLogoutLoader] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [valid, setValid] = useState(false);
  // const [mismatchMsg, setMismatchMsg] = useState("");
  const [status, setStatus] = useState(false);
  const [lengthError, setLengthError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const logout = () => {
    localStorage.removeItem("tradingformtoken");
    dispatch(setUser(null));
    successMessage("You've been Logged out");
    navigate("/login");
  };


  const handlesumbit = async (e) => {
    setLoader(true);
    // e.preventDefault();
    if (password.length >= 6 && newPassword.length >= 6) {

      if (password === newPassword) {
        // console.log("same");
        setLogoutLoader(true);
        let body = {
          password: password,
          confirm_password: newPassword,
        }
        const { data, error } = await updateUserPassword(body);
        if (data) {
          // console.log(data);
          if (data.success) {
            // successMessage(data.message);
            setTimeout(() => logout(), 2000);
            // console.log("passworddata>>",data);
          }
          if (!data.success) {
            errorMessage(data.message);
            setLoader(false);
          }

        }
        if (error) {
          if (error.data.errors[0]) {
            console.log("error1", error);
            errorMessage(error.data.errors[0].msg);
          }
          if (error.data.errors[1]) {
            console.log("error1", error);
            errorMessage(error.data.errors[1].msg);
          }
          if (error.data.errors[2]) {
            console.log("error1", error);
            errorMessage(error.data.errors[2].msg);
          }
        }
      }
      else {
        // console.log("not same");
        errorMessage("Password and Confirm-passsword doesn't match. ")
        setLogoutLoader(false);
      }
      // e.preventDefault();

    } else {
      errorMessage("Password & Confirm-password must contain atleast 6 characters.");
    }

    setLoader(false);
  }

  const passwordChangeModal = (type) => {
    // handlesumbit(e);

    if (type === true) {
      // passwordChange(e);
      setIsConfirmation(false);
      handlesumbit();
    }
    if (type === false) {
      setIsConfirmation(false);
    }
  }

  const updatepassword = (e) => {
    e.preventDefault();

    if (password.length >= 6 && newPassword.length >= 6) {
      if (password === newPassword) {

        setIsConfirmation(true);

      }
      else {
        setValid(true);
        setStatus(true)
        setTimeout(() => {
          setStatus(false)
          setValid(false)
        }, 2000);
      }
    }
    else {
      setValid(true);
      setLengthError(true);
      setTimeout(() => {
        setValid(false);
        setLengthError(false);

      }, 1000);
    }



  }

  const showPassword = (field) => {
    if (field === "password") {
      const togglePassword = document.querySelector("#togglePassword");
      const password = document.querySelector("#password"); const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
      // toggle the eye slash icon
      togglePassword.classList.toggle("fa-eye-slash");
    }
    if (field === "newPassword") {
      const togglePassword = document.querySelector("#toggleconPassword");
      const password = document.querySelector("#newPassword"); const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
      // toggle the eye slash icon
      togglePassword.classList.toggle("fa-eye-slash");
    }
  }
  return (
    <>
      {logoutLoader === true &&
        <CustomDeleteloader message="Logging out . . ." />
      }
      {/* <div className='heading-profile'> <h4>Change Password</h4></div> */}
      <div className="password-fields">
        <form onSubmit={updatepassword} className="passwordUpdate-container">

          <div className="form-feild">
            <div className="form-field-inner">
              <label htmlFor="password" className="col-sm-12 col-md-3 col-12 mt-2">New Password</label>
              <div className="col-sm-12 col-md-8 col-12 position-relative">
                <input className={`form-control ${lengthError ? "shake" : ""}`} type="password" id="password" name="password" value={password} onChange={handlePasswordChange} autoComplete="off" required />
                {/* autoFocus={true} */}
                <i
                  className="far fa-eye"
                  onClick={() => showPassword("password")}
                  id="togglePassword"
                ></i>
                <small className={`password-error-container ${lengthError ? "errorMsg" : ""}`}><i>Password must contain atleast 6 characters.</i></small>

              </div>
            </div>


          </div>
          <div className="form-feild">
            <div className="form-field-inner">
              <label htmlFor="newPassword" className="col-sm-12 col-md-3 col-12 mt-2">Confirm Password</label>
              <div className="col-sm-12 col-md-8 col-12 position-relative">
                <input type="password" id="newPassword" className={`form-control ${valid ? "shake" : ""}`} name="newPassword" value={newPassword} onChange={handleNewPasswordChange} autoComplete="off" required />
                <i
                  className="far fa-eye"
                  onClick={() => showPassword("newPassword")}
                  id="toggleconPassword"
                ></i>
                <div>
                  <small className={`password-error-container ${lengthError ? "errorMsg" : ""}`}><i className={status ? "text-danger" : ""}>{status ? "Password doesn't match!" : ""}</i></small>

                </div>
                <button type="sumbit" className={`btn btn-save mt-3 ${password && newPassword ? '' : 'cursor-disable'}`} disabled={password && newPassword ? false : true}>
                  <div className="submitbuttonInnerContainer d-flex">
                    <div className="button-container">
                      <i className="fas fa-save"></i> <span> Update Password</span>
                    </div>
                    {loader === true &&
                      <div className="spinner-container">
                        <CustombuttonSpinner />
                      </div>}
                  </div>
                </button>
              </div>
            </div>

          </div>




          {/* <div className="input-wrapper">
            <label htmlFor="password">New Password</label>
            <div className="password-container">

              <input className="form-control" type="password" id="password" autoFocus={true} name="password" value={password} onChange={handlePasswordChange} autoComplete="off" required />
              <i
                className="far fa-eye"
                onClick={() => showPassword("password")}
                id="togglePassword"
              ></i>
              <small className={`error-container ${lengthError ? "errorMsg" : ""}`}><i>Password must contain atleast 6 characters.</i></small>
            </div>
          </div>

          <div className="input-wrapper">
            <label htmlFor="newPassword">Confirm Password</label>
            <div className="password-container">
              <input type="password" id="newPassword" className={`form-control ${valid ? "shake" : ""}`} name="newPassword" value={newPassword} onChange={handleNewPasswordChange} autoComplete="off" required />
              <i
                className="far fa-eye"
                onClick={() => showPassword("newPassword")}
                id="toggleconPassword"
              ></i>
              <small className={`error-container ${lengthError ? "errorMsg" : ""}`}><i className={status ? "text-danger" : ""}>{status ? "Password doesn't match!" : "Password must contain atleast 6 characters."}</i></small>
            </div>
          </div> */}

          <div className="text-center">
            <div className="col-12">
            </div>
          </div>
        </form>
      </div>
      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon={"exclamation-circle text-warning"}
            header=""
            type="confirm"
            recordName={`After confirmation your account will be logout. Are you sure you want to update password`}
          >
            <button className="btn btn-danger m-1 col-xs-12 col-sm-6 col-md-3" onClick={() => passwordChangeModal(true)}> Yes</button>
            <button className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3" onClick={() => passwordChangeModal(false)}>No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>
    </>
  )
}

export default ChangePassword