import React from 'react'
// import Modal from '../modal/Modal';
// import ReactPortal from '../reactPortal/ReactPortal';
// import PhotoDetails from './PhotoDetails';
// import PhotoDetails from './PhotoDetails';

function InvoiceNotes({formData,handleBlur,addFooterImages,footerImagesA,removeImage}) {


  return (
    <>
        <div className="col-12 invoiceFooter fadeInOpacity">

          <div className="col-12 notesContainer">
            <div className="header">Notes</div>
            <textarea rows="5" name="notes" className="form-control col-12" onChange={(e)=>handleBlur(e)} aria-label="With textarea"  defaultValue={formData?.notes} placeholder='Notes - any relevant information not covered, additional terms and conditions'></textarea>
          </div>
          <div className="invoiceFooterImage col-12">
                  <div className="header">Photos</div>
                  <div className="galleryContainer">


                        {
                          footerImagesA?.map((img,index)=>{
                            return(
                              <div className="singleImage p-2 m-1" key={index} >
                                {/* onClick={()=>removeImage(img.img_filename)} */}
                                  <div className="imageActions">
                                    {/* <span className="edit" onClick={()=>updateImageDetail(img.id)}>
                                      <i className="fa fa-edit"></i>
                                    </span> */}
                                    <span className="remove"  onClick={()=>removeImage(img.id)}>
                                      <i className="fa fa-trash-alt"></i>
                                    </span>
                                  </div>
                                <div className="imageContainer">
                                  <img src={img.img_filename} alt="addedImage" ></img>
                                  {/* onClick={()=> handleSingleImge(img.img_filename)} */}
                                  {/* onClick={()=> handleSingleImge(onImage)} */}
                                </div>
                                <div className="details">
                                  <p><b>{img.img_description}</b></p>
                                  <small>{img.img_additional_notes}</small>
                                </div>
                          </div>
                            )
                          })
                        }

                        {/* <div className="singleImage p-2">
                            <img src={galleryImages[0].img} alt=""></img>
                        </div>
                        <div className="singleImage p-2">
                            <img src={galleryImages[1].img} alt=""></img>
                        </div>
                        <div className="singleImage p-2">
                            <img src={galleryImages[2].img} alt=""></img>
                        </div> */}

                       {/* { index <=4 &&  */}


                          <div className="invoiceLogo m-1" title='Upload Photo'>
                                <input className="form-control" type="file" accept=".png,.jpge,.jpg,.webp" name="" onChange={(e)=>addFooterImages(e)}></input>
                                <div className="logoNote" title='Upload Photo'><i className="fas fa-image"></i> Add Photo</div>
                          </div>



                       {/* }onChange={()=>handleImage()} */}
                        {/* } */}
                  </div>
                  
          </div>
        </div>
        {/* <ReactPortal>
          { isFooterOpen &&(
          <Modal handleClose={handleModalClose} header="Photo Details" type="form">
                <PhotoDetails addImage={addImage} index={index} onImage={onImage} handleClose={handleModalClose}/>
               ======== index={index} onImage={onImage} =======
          </Modal>
          )}
        </ReactPortal> */}
    </>
  )
}

export default InvoiceNotes