import React, { useState } from "react";

import { useDashBoardCountQuery } from "../store/apis/baseApi";
import { useEffect } from "react";
import Donut from "../components/Donut";
import CountGraph from "../components/CountGraph";
import Usage from "./Usage";
import CustomLoader from "../components/CustomLoader";
import { deepCopy } from "../utils/utils";
import { errorMessage } from "../components/toastMessages/ToastifyMsg";

function Landing() {
  const [donut,setDonut] = useState([]);
  const [counts,setCounts] = useState([]);

  const { data, error } = useDashBoardCountQuery();

  useEffect(() => {
    try {
       if (data) {   
        if (data.success) {
            let invoicesDonut =  deepCopy([
            data.data.invoice_type_charagble_count,
            data.data.invoice_type_quotation_count,
            data.data.salary_invoice_count,
          ]);
          // console.log("first",invoicesDonut)
          // let totalInvoiceCount=  deepCopy(data.data.total_invoices_count);
          // let totalSalaryCount =  deepCopy(data.data.salary_invoice_count);
          // let totalConsumersCount =  deepCopy(data.data.total_consumer_count);
          // let totalProductsCount =  deepCopy(data.data.total_products_count);

          setCounts([
            {
              title: "Invoices",
              count:
              {
                type_1: {title:'Invoices', count:data.data.invoices_counts.invoice_type_charagble_count},
                type_2: {title:'Quotations', count:data.data.invoice_type_quotation_count}
              }   

              // totalInvoiceCount
              ,
              textColor: '#ff9100',
              icon: "fa-file-invoice-dollar",
              // color: "#7367F0",
              color: '#F3A53F',
              backgroundcolor: "#fbead5",
              animation: "fade-right"
            },
            {
              title:"Payslips",
              count: 
              {
                type_1: {title:'Payslips', count: data.data.salaries_count.external_salary_invoice_count},
                type_2: {title:'Groups', count: data.data.salaries_count.group_salary_count}
              }
              
              // totalSalaryCount
              ,
              textColor: '#b14044',
              icon: "fa-money-check-alt",
              // color: "#28C76F",
              color: '#CA6B6E',
              backgroundcolor: "#f6e5e6",
              animation: "fade-up"
            },
            {
              title:"Consumers",
              count:
              {
                type_1: {title:'Clients',count: data.data.consumers_count.entity_type_clients_count},
                type_2: {title: 'Employees',count:data.data.consumers_count.entity_type_employee_count}
              }
              //  totalConsumersCount
              ,
              textColor: '#307379',
              icon: "fa-users",
              // color: "#36a2ec",
              // backgroundcolor: "#FCE8E8",
              color: '#478F96',
              backgroundcolor: '#dcfafd',
              animation: "fade-up"
            },
            {
              title:"Products",
              count: 
              {
                type_1:{title:'Digital',count: data.data.items_counts.item_type_digital_count},
                type_2:{title:'Physical',count: data.data.items_counts.item_type_physical_count},
              }
              // totalProductsCount
              ,
              textColor: '#970ec7',
              icon: "fa-box",
              // color: "#FF9F43",
              color: '#8E39AC',
              backgroundcolor: "#edc9fa",
              animation: "fade-left"
            }
            ])
          
          let consumersCount =  deepCopy([
            data.data.entity_type_clients_count,
            data.data.entity_type_company_count,
            data.data.entity_type_employee_count,
          ]);
        
          let itemsCount =  deepCopy([
            data.data.item_type_digital_count,
            data.data.item_type_physical_count,
          ]);
          
          setDonut([
            {
              label: "Invoices",
              labels: [`Invoices: ${invoicesDonut[0]}`, `Quotations: ${invoicesDonut[1]}`, `Payslips:  ${invoicesDonut[2]}`],
              // backgroundColor: ["#0E2B67", "#36A2EC", "#5BACC8"],
              // backgroundColor:['rgba(14,43,103,0.4)','rgba(54, 162, 236,0.4)','rgba(91, 172, 200,0.4)'],
              backgroundColor:['rgba(133, 147, 237, 1)','rgba(90, 107, 207, 1)','rgba(199, 206, 255, 1)'],

              // hoverBackgroundColor: ["#43547C", "#48799F", "#457593"],
              data: invoicesDonut,
              titles: [{
                        title: "Invoices",
                        count: invoicesDonut[0],
                        icon: "fa-file-invoice-dollar",
                        color:"#0E2B67",
                      },
                      {
                        title: "Quotations",
                        count: invoicesDonut[1],
                        icon: "fa-file-invoice",
                        color: "#36A2EC",
                      },
                      {
                        title: "Payslips",
                        count: invoicesDonut[2],
                        icon: "fa-money-check",
                        color: "rgba(255, 206, 86, 0.5)",
                      }
                    ],
              animation: "fade-right"
            },
            {
              label: "Consumers",
              labels: [`Clients: ${consumersCount[0]}`, `Companies: ${consumersCount[1]}`, `Employees: ${consumersCount[2]}`],
              // backgroundColor: ["#0E2B67", "#36A2EC", "#5BACC8"],
              backgroundColor:['rgba(133, 147, 237, 1)','rgba(90, 107, 207, 1)','rgba(199, 206, 255, 1)'],
              // hoverBackgroundColor: ["#43547C", "#48799F", "#457593"],
              data: consumersCount,
              titles: [{
                title: "Clients",
                count: consumersCount[0],
                icon: "fa-users",
                color: "rgba(75, 192, 192, 0.5)",
              },
              {
                title: "Companies",
                count: consumersCount[1],
                icon: "fa-building",
                color: "#36A2EC",
              },
              {
                title: "Employees",
                count: consumersCount[2],
                icon: "fa-user-tie",
                color: "rgba(255, 206, 86, 0.5)",
              }
            ],
            animation: "fade-up"
            },
            {
              label: "Products",
              labels: [`Digital: ${itemsCount[0]}`, `Physical: ${itemsCount[1]}`],
              // backgroundColor: ["#0E2B67", "#36A2EC"],
              backgroundColor:['rgba(133, 147, 237, 1)','rgba(199, 206, 255, 1)'],

              // hoverBackgroundColor: ["#43547C", "#48799F"],
              data: itemsCount,
              titles: [{
                title: "Digital",
                count: itemsCount[0],
                icon: "fa-digital-tachograph",
                color: "#0E2B67",
              },
              {
                title: "Physical",
                count: itemsCount[1],
                icon: "fa-box",
                color: "#36A2EC",
              },
              
            ],
            animation: "fade-left"
            }
          ])
        }
        if (!data.success) {
          console.log("!success.data",data.message);
        }
      }
      if (error) {
        // console.log("ErrorApisdashboard", error?.error ?? error.data.error.message);
        errorMessage(error?.error ?? error.data.error.message);
      }
    } catch (error) {
      // console.log("catchErrorDashCount");
    }
   
  }, [data, error]);

  return (
    <>
      <div className="container overflow-hidden" >
        <div className="spacer"></div>
        

        {/* <h3 className="text-warning">
          {" "}
          Landing Page <br></br> Under Development
        </h3> */}
        <div className="col-12">
      { !data ? (<CustomLoader/>): 
          ( <>
              <div className="count-container">
                <div className="row">
                  { data && counts &&(
                    <>
                      {
                        counts.map((count,index)=>{
                          return( 
                            <CountGraph key={index} dataCount={count}/>

                          )
                        })
                      }
                    </>
                )}
                </div>
              </div>
        <div className="spacer"></div>
              
              <div className="donut-container">
                <div className="row">
                  {data && donut && (
                    // <ConsumerDonut data={invoiceDonut}/>
                    <>
                      {
                        donut.map((donutData,index)=>{
                          return (
                            <Donut data={donutData} key={index} />

                          )
                        })
                      }
                      {/* <Donut data={invoiceDonut} donut={donut}/>
                      <Donut data={itemsDonut} /> */}
                    </>
                  )}
                  {/* <ConsumerDonut/>
                  <ConsumerDonut/> */}
                </div>
              </div>

              <div className="spacer"></div>

              
            </>)
      }<section className="usage">
                  <Usage/>
              </section>
        <div className="spacer"></div>

        </div>

      </div>
    </>
  );
}

export default Landing;
