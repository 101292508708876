import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomDeleteloader from "../../components/CustomDeleteloader";
// import CustomLoader from "../../components/CustomLoader";
import Modal from "../../components/modal/Modal";
import ReactPortal from "../../components/reactPortal/ReactPortal";
import {
  errorMessage,
  successMessage,
} from "../../components/toastMessages/ToastifyMsg";
import axios from "axios";
import SendEmail from "../../components/SendEmail";

import {
  useDeleteInternalSalaryMutation,
  useDuplicateInternalSalarySlipMutation,
  useGetAllGroupInternalSalaryQuery,
  
} from "../../store/apis/baseApi";
import Allsalaries from "../../Salary/Allsalaries";
import { deepCopy } from "../../utils/utils";
import { LARAVELAPI } from "../../common/common";
import { useDispatch, useSelector } from "react-redux";
import { setPlanDetails } from "../../store/slices/subscribedPlanDetails";

function GroupPayslip() {
  const LARAVELurl = LARAVELAPI;

  const salaryslipsPlanDetail = useSelector(state=>state.planDetails);
  const dispatch = useDispatch();

  let groupUrlId;
  const { salarygroupId } = useParams();
  if (salarygroupId) {
    groupUrlId = window.atob(salarygroupId);
  }
  const [breadcrumbTitle,setBreadcrumbTitle] = useState("group");
  const navigate = useNavigate();
  const location = useLocation();
  const {state} = location;

  useEffect(() => {
  setBreadcrumbTitle(state.groupTitle);
  }, [state]);

  const {data:AllGroupInternalSalaryData,error:AllGroupInternalSalaryError ,isLoading} = useGetAllGroupInternalSalaryQuery(groupUrlId);
  const [showInvoice, setShowInvoice] = useState(1);
  const [loader, setLoader] = useState(false);
  const [tableLoader,setTableLoader] = useState(true);
//   const { data: allPayslipData, error: allPayslipError } = useGetAllSalaryQuery();
  const [allGroupPayslip, setAllGroupPayslip] = useState([]);
  const [deleteModalSalary, setDeleteModalSalary] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [emailModal, setEmailModal] = useState();
  const [emailTitle, setEmailTitle] = useState("");
  const [emailbuttonLoader, setEmailbuttonLoader] = useState(false);
  
  const  [deleteInternalSalary] = useDeleteInternalSalaryMutation();
  // const [duplicateSalarySlip] = useDuplicateSalarySlipMutation();
  const [isConfirmDuplicate, setIsConfirmDuplicate] = useState(false);
  const [duplicateId, SetDuplicateId] = useState("");
  const [duplicateName, SetDuplicateName] = useState("");
  const [duplicateLoader, setDuplicateLoader] = useState(false);
  // const [duplicateSalaryGroup] = useDuplicateSalaryGroupMutation();
  const [duplicateInternalSalarySlip] = useDuplicateInternalSalarySlipMutation();
  const [dType, setDType] = useState();

  const [emailBody, setEmailBody] = useState({
    id: "",
    email_to: "",
    email_body: "Kindly find the attached document.",
    attachment: "true",
    cc_status : "false",
    cc_emails: ""
  });

    useEffect(() => {
      if (AllGroupInternalSalaryData) {
        if (AllGroupInternalSalaryData.success) {
          let allSalaries  = deepCopy(AllGroupInternalSalaryData.data);
          //   console.log(allSalaries);
            let finalSalary = allSalaries.map((salary)=>{
              return({...salary,currency_selected:JSON.parse(salary?.currency_selected || {})})
            })
          setAllGroupPayslip(finalSalary || []);
        }
        if (!AllGroupInternalSalaryData.success) {
              // console.log("error>>",AllGroupInternalSalaryData.message);
              // errorMessage(AllGroupInternalSalaryData.message);
              setAllGroupPayslip([]);
        }
      }
      if (AllGroupInternalSalaryError) {
          // console.log(AllGroupInternalSalaryError?.data?.error?.message);
          errorMessage(AllGroupInternalSalaryError?.error ?? AllGroupInternalSalaryError?.data?.error?.message);
      }

    }, [AllGroupInternalSalaryData,AllGroupInternalSalaryError])

    useEffect(() => {
      setTimeout(() => {
        setTableLoader(isLoading)
      }, 500);
    }, [isLoading])
  //   ====================================
  const confirmation = (id, name, type) => {
    // console.log("id>", id, "name>", name, "type>", type);
    setIsConfirmation(true);
    setDType(type);
    // deleteInvoice(id);
    setDeleteId(id);
    setDeleteModalSalary(name);
  };

  const deleteInvoice = async (deltype) => {
    // if (value === true) {
    if (deltype === "delsalary") {
      // console.log("delsalary")
      try {
        setIsConfirmation(false);
        setLoader(true);
        const { data, error } = await deleteInternalSalary(deleteId);

        if (data) {
         if (data.success) {
           successMessage(data.message);
         }
         if (!data.success) {
            errorMessage(data.message);
            // console.log(data.message);
         }
        }
        if (error) {
          // console.log("invoice!deleted", error);
          errorMessage(error?.error ?? error?.data?.error?.message);
        }
      } catch (error) {
        // console.log("catchError", error);
      } finally {
        setLoader(false);
        setDType("");
        setDeleteId();
      }
    }
    // if (deltype === "delgroupsalary") {
    //   // console.log("delgroupsalary")
    //   try {
    //     setIsConfirmation(false);
    //     setLoader(true);
    //     const { data, error } = await deleteSingleGroup(deleteId);
    //     if (data) {
    //       // console.log("deleteGroup",data);
    //       successMessage(data.message);
    //     }
    //     if (error) {
    //       console.log("!deleteGroup", error);
    //       errorMessage(error);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     setLoader(false);
    //     setDeleteId();
    //     setDeleteModalSalary("");
    //     setDType("");
    //   }
    // }
  };

  const handleCloseConfirmation = () => {
    setIsConfirmation(false);
    setDType();
    setDeleteId();
  };

  const handleCloseDuplicate = () => {
    setIsConfirmDuplicate(false);
    setDType();
    SetDuplicateName();
    SetDuplicateId();
  };
  const increasePlan = (type)=>{
    const newPlanDetails = JSON.parse(JSON.stringify(salaryslipsPlanDetail?.planDetails))
    // const newPlanDetails = { ...invoicePlanDetail.planDetails };
    newPlanDetails.plan_usage_details[type].usage_count += 1;
    dispatch(setPlanDetails(newPlanDetails));
  }
  const handleOpenEmail = (title, invID, customerEmail) => {
    if (salaryslipsPlanDetail?.planDetails?.plan_usage_details?.email_sent?.usage_count < salaryslipsPlanDetail?.planDetails?.plan_usage_details?.email_sent?.total) {
      setEmailModal(true);
      setEmailBody((prevState) => {
        return {
          ...prevState,
          id: invID,
          email_to: customerEmail,
        };
      });
      setEmailTitle(title);
    }
    else errorMessage("Limit Reached. Upgrade your plan!");
  };

  const handleCloseEmail =  () => {
    setEmailModal(false);
    setEmailBody((prevState)=>{
      return{
        ...prevState,
        id: "",
        email_to:"",
        cc_status: "false",
        cc_emails:""
      }
    })
  };

  // ======Duplicate============
  const duplicateConfirmation = (id, name, type) => {
    // console.log("id>", id, "name>", name, "type>", type);
    if (salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group_payslip?.usage_count <salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group_payslip?.total) {
      // if (allGroupPayslip.length< salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group_payslip?.total/salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group?.total ) {
      setIsConfirmDuplicate(true);
      // deleteInvoice(id);
      setDType(type);
      SetDuplicateName(name);
      SetDuplicateId(id);
      // }else{
      //   errorMessage("Limit Reached. Upgrade your plan!");
      // }
    }
    else{
      errorMessage("Limit Reached. Upgrade your plan!");
    }
  };
  //================email=======================
  const emailChange = (e) => {
    e.preventDefault();
    const {name,value} = e.target;
    if (name === "cc_status" & value === "false") {
      setEmailBody((prevState)=>{
        return({
          ...prevState, cc_emails:""
        })
      })
    }
    setEmailBody((prevState)=>{
      return({
        ...prevState, [name]:value
      })
    })
    // console.log(emailBody);
  };

  const handleEmail = async (e) => {
    try {
       e.preventDefault();
    setEmailbuttonLoader(true);
    let body = {
      id: emailBody?.id,
      email_to: emailBody?.email_to,
      email_body: emailBody?.email_body,
      attachment: emailBody?.attachment,
      cc_status: emailBody?.cc_status,
      cc_emails: emailBody?.cc_emails,
      type: "salary",
    };
    // https://stagingfront.tradingforms.com/api/invoice_email?id=59
    // console.log(body);
    let token = localStorage.getItem("tradingformtoken");
    const {data,error} = await axios(`${LARAVELurl}salary_invoice_email`, {
      method: "POST",
      data: body,
      headers: {
        "Content-Type": "application/json;",
        "Access-Control-Allow-Origin": "*", //'Access-Control-Allow-Origin'
        Authorization: `Bearer ${token}`,
      },
    })

    if (data) {
      if (data.success) {
        setEmailModal(false);
        successMessage(data?.message);
        // setEmailbuttonLoader(false);
        increasePlan("email_sent");
        setEmailBody((prevState)=>{
          return{
            ...prevState,
            id: "",
            email_to:"",
            attachment:"true",
            cc_status: "false",
            cc_emails:""
          }
        })
      }
      if (!data.success) {
        errorMessage(data.message);
      }
    }
    if (error) {
      errorMessage(error?.error ?? error?.data?.error?.message ?? "Mail not sent!");
      // setEmailbuttonLoader(false);
    }


    } catch (error) {
      // console.log("catchError");
    }
    finally{
      setEmailbuttonLoader(false);
    }
   
  };

  const createDuplicateSubmit = async (type) => {
    // console.log("type>>",type);
    if (type === "dupsalary") {
      try {
        setIsConfirmDuplicate(false);
        setDuplicateLoader(true);
        const { data, error } = await duplicateInternalSalarySlip(duplicateId);
        if (data) {
          if (data.success) {
            increasePlan("salary_group_payslip")
            successMessage(data.message);
          }
          if (!data.success) {
              errorMessage(data.message);
          }
        }
        if (error) {
          errorMessage(error?.error ?? error?.data?.error?.message);
        }
      } catch {
        // console.log("error in duplicate salary slip", error);
      } finally {
        // console.log("finally");
        setDuplicateLoader(false);
        SetDuplicateId("");
        SetDuplicateName("");
      }
    }
    // if (type === "dupgroupsalary") {
    //   try {
    //     setIsConfirmDuplicate(false);
    //     setDuplicateLoader(true);
    //     const {data,error} = await duplicateSalaryGroup(duplicateId);
    //     if(data){
    //       successMessage(data.message);
    //       // console.log("Duplicatedata",data);
    //     }
    //     if(error){
    //       console.log("error in duplicate salary group",error);
    //       errorMessage(error.data.message);
    //     }

    //   } catch (error) {
    //     console.log(error);
    //   }
    //   finally{
    //     setDuplicateLoader(false);
    //     SetDuplicateId("");
    //     SetDuplicateName("");

    //   }
    // }
  };

  //===============================================================================================
  // Check if internal payslip usage reaches the total limit for the group.
  // salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group_payslip?.usage_count<salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group_payslip?.total
  // Then, ensure the user hasn't created more payslips than the group allows:
  // At the moment following formula is used to check total number of payslips are allowed in the group.
  //          total_allowed = salary_group_payslip.total / salary_group.total 
  // where:  
  //        salary_group?.total is total number of groups allowed to create.
  //        salary_group_payslip?.total  is total number of payslips allowed in the payslip group
  // ==============================================================================================

  const handleCreateNavigate=() => {
    if (salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group_payslip?.usage_count<salaryslipsPlanDetail?.planDetails?.plan_usage_details?.salary_group_payslip?.total) {
        navigate(`/salarygroup/${salarygroupId}/create-payslip`);
      
    }else {
      errorMessage("Limit Reached. Upgrade your plan!");
      navigate('/subscription-plans');
    } 
  }

  return (
    <>
      { duplicateLoader && (
          <CustomDeleteloader message="Duplicating . . ." />
      )
      }
      {loader &&
        <CustomDeleteloader message={"Deleting . . ."} />
      }
      {/* <div><b> Internal </b>GroupPayslip</div>
*/}
      <div className="spacer"></div> 
      {/* <div className="spacer"></div> */}
      <div className="invoice-container">
        <div className="container group-internal-salary-listing px-0">
          <div className="col-lg-11 col-xl-10 col-11">
                 <div className="breadcrumb-container">
                  
                  <nav className="breadcrumbs">
                    <Link to="/" className="breadcrumbs__item">Dashboard</Link>
                    <Link to="/salary-slips" className="breadcrumbs__item" state={{showTab: '2'}}>PaySlip Groups</Link>
                    {/* <Link to={``}  onClick={navigateTo} className="breadcrumbs__item">Payslipgroup - <span className="text-uppercase">{breadcrumbTitle}</span></Link>  */}
                    {/* <Link to="" className="breadcrumbs__item">Payslipgroup</Link> */}
                    <span  className="breadcrumbs__item is-active text-uppercase"> {breadcrumbTitle}</span> 
                  </nav>
                    <div className="spacer"></div>
                </div>
          </div>
        </div>
        <div className="container invoice-list" data-aos="zoom-in">
        

          <div className="invoice-actions">
            <div className="invoice-navBtn invoice-navBtn-custom">
              <button
                onClick={() => setShowInvoice(1)}
                className={`btn btn-secondary btn-sm ${
                  showInvoice === 1 ? "active cursor-disable" : ""
                }`}
                type="button"
              >
                All PaySlips
              </button>

              {/* <button
                onClick={() => setShowInvoice(2)}
                className={`btn btn-secondary btn-sm ${
                  showInvoice === 2 ? "active cursor-disable" : ""
                }`}
                type="button"
                >
                PaySlips Group
              </button> */}

              {/* <button onClick={() => setShowInvoice(2)} className="btn btn-secondary btn-sm" type="button">Outstanding</button>
                  <button onClick={() => setShowInvoice(3)} className="btn btn-secondary btn-sm" type="button">Paid</button> */}
            </div>
            <div className="searchCreate-invoice">
                  <Link to='/email-history' state={{emailHistory: 'salary' }} className="btn btn-secondary btn-sm email-history" ><i className="fa fa-history"></i> <span> Email History </span></Link>
              {/* <div className="invoice-searchContainer col-xs-12 col-sm-9 col-md-7">
                        <input type="text" name=""  placeholder="Search By Client Name"></input>
                    </div> */}
              <div className="createInvoice col-xs-12 col-sm-3 col-md-5">
                {showInvoice === 1 && (
                  <button className="btn btn-primary d-flex align-items-center justify-content-center" onClick={handleCreateNavigate} to={`/salarygroup/${salarygroupId}/create-payslip`}>
                    <i className="fa fa-plus pe-2"></i> <span>Create Payslip</span>
                  </button>
                )}
                {/* {showInvoice === 2 && (
                  <button
                    className="btn btn-primary action-button-group"
                    onClick={() => openGroupModal(false, "noValue")}
                  >
                    <i className="fa fa-plus"></i> <div className="action-button-inner">Create Payslip Group</div>
                  </button>
                )} */}
              </div>
            </div>
          </div>

          <div className="container px-0 overflow-hidden">
            {showInvoice === 1 && (
              <Allsalaries
                deleteSalary={confirmation}
                handleOpenEmail={handleOpenEmail}
                duplicatepayslip={duplicateConfirmation}
                allPayslipsData={allGroupPayslip}
                url={`salarygroup/${window.btoa(`${groupUrlId}`)}/edit-payslip`}
                showFrom={true}
                // salarygroup/:salarygroupId/edit-payslip/
                isLoading={tableLoader}
              />
            )}

            {/* {showInvoice === 2 && (
              <AllGroupPayslip
                allgroups={allgroups}
                openGroupModal={openGroupModal}
                deleteSalary={confirmation}
                duplicatepayslipGroup={duplicateConfirmation}
              />
            )} */}
          </div>

          {/* <div className="invoice-list-footer"><span>All your invoices are auto saved here</span></div> */}
        </div>
      </div>

      {/* delete modal */}
      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon={"trash-alt text-danger"}
            header=""
            type="confirm"
            recordName={`Are you sure want to delete this ${
              dType === "salary"
                ? `Payslip# ${deleteModalSalary}`
                : `Payslip Group '${deleteModalSalary}'`
            } `}
          >
            <button
              className="btn btn-danger m-1"
              onClick={() =>
                deleteInvoice(
                  `${dType === "salary" ? "delsalary" : "delgroupsalary"}`
                )
              }
            >
              {" "}
              Yes
            </button>
            <button
              className="btn btn-primary m-1"
              onClick={
                handleCloseConfirmation
                // deleteInvoice(false, `${ dType==='salary' ? 'delsalary':'delgroupsalary'}`)
              }
            >
              No
            </button>
          </Modal>
        )}
      </ReactPortal>

      {/* email modal */}
      <ReactPortal>
        {emailModal && (
          <Modal
            handleClose={handleCloseEmail}
            type="form" contentType="email-form"
            header={`Enter details to send mail for ${emailTitle}`}
          >
            <SendEmail
              emailBody={emailBody}
              handleSubmit={handleEmail}
              emailChange={emailChange}
              loader={emailbuttonLoader}
            />
          </Modal>
        )}
      </ReactPortal>

      {/* duplicate modal */}
      <ReactPortal>
        {isConfirmDuplicate && (
          <Modal
            header=""
            type="confirm"
            icon={"copy text-info"}
            recordName={`Are you sure want to duplicate ${
              dType === "salary"
                ? `Payslip# ${duplicateName}`
                : `Payslip Group '${duplicateName}'`
            }  `}
          >
            <button
              className="btn btn-save m-1 col-xs-12 col-sm-6 col-md-3"
              onClick={() =>
                createDuplicateSubmit(
                  `${dType === "salary" ? "dupsalary" : "dupgroupsalary"}`
                )
              }
            >
              {" "}
              Yes
            </button>
            <button
              className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3"
              onClick={handleCloseDuplicate}
            >
              No
            </button>
          </Modal>
        )}
      </ReactPortal>
    </>
  );
}

export default GroupPayslip;
