import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  useCreateEntitiesSettingsMutation,
  // useLazyGetSingleItemQuery,
  useLazySingleEntitySettingsQuery,
  useUpdateSingleEntitySettingsMutation,
} from "../../store/apis/baseApi";
// import CustomLoader from "../CustomLoader";
import { errorMessage, successMessage } from "../toastMessages/ToastifyMsg";
import "./clients.css";
import CustomDeleteloader from "../CustomDeleteloader";
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { deepCopy } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { setPlanDetails } from "../../store/slices/subscribedPlanDetails";

const CreateClients = (props) => {
  const [entitiesSettings] = useCreateEntitiesSettingsMutation();
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const clientPlanDetail = useSelector(state=>state.planDetails);
  let urlId;
  if (clientId) {
    urlId = window.atob(clientId);
  }
  const [singleEntitySettings] = useLazySingleEntitySettingsQuery();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dataloader, setDataloader] = useState(false);

  // const dispatch = useDispatch();
  const [updateSingleEntitySettings] = useUpdateSingleEntitySettingsMutation();
  const [formData, setFormData] = useState({
    id: "",
    entity_type: "client",
    entity_name: "",
    entity_email: "",
    entity_phone_personel: "",
    entity_phone_official: "",
    entity_country: "",
    entity_state: "",
    entity_city: "",
    entity_address: "",
    entity_fax: "",
    entity_gst: "",
    entity_ntn: "",
    entity_zip_code: "",
    entity_note: "",
    entity_website: ""
  });

  useEffect(() => {

    if (props.updating) {
      setDataloader(true);
      (async () => {
        try {
          const { data, error } = await singleEntitySettings(urlId);
          if (data) {
            if (data.success) {
              let clientData = deepCopy(data.data);
              // console.log("getSingle>clientData success>>>",data);
              setFormData({
                id: urlId,
                entity_type: clientData.entity_type,
                entity_name: clientData.entity_name,
                entity_email: clientData.entity_email,
                entity_phone_personel: clientData.entity_phone_personel,
                entity_phone_official: clientData.entity_phone_official,
                entity_country: clientData.entity_country,
                entity_state: clientData.entity_state,
                entity_city: clientData.entity_city,
                entity_address: clientData.entity_address,
                entity_fax: clientData.entity_fax,
                entity_gst: clientData.entity_gst,
                entity_ntn: clientData.entity_ntn,
                entity_note: clientData.entity_note,
                entity_zip_code: clientData.entity_zip_code,
                entity_website: clientData.entity_website,
              });

            }
            if (!data.success) {
              // console.log(data.message);
              errorMessage(data.message);
            }
          }
          if (error) {
            // console.log("ERROR:", error.data.error.message);
            errorMessage(error?.error ?? error?.data?.error?.message);
          }

        } catch (error) {

        }
      }
      )();
      setTimeout(() => setDataloader(false), 1000);

    }
  }, [clientId, props.updating, singleEntitySettings, urlId]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // console.log(formData);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    // do something with the form data
    if (props.updating) {
      // console.log("updateSubmit>formData",formData);
      try {
        const { data, error } = await updateSingleEntitySettings(formData);
        if (data) {
          // console.log("Data", data);
          if (data.success) {
            setLoader(false);
            successMessage(data.message);
            navigate("/clients");
          }
          if (!data.success) {
            errorMessage(data.message);
            setLoader(false);
          }
        }

        if (error) {
          // console.log("ERROR:", error);
          setLoader(false);
          errorMessage(error?.error ?? error?.data?.message ?? error.data?.message ?? error.data.error);
        }

      } catch (error) {

      }
    } else {
      // console.log("postFormData>>",formData)
      try {
        const { data, error } = await entitiesSettings(formData);

        if (data) {
          // console.log("Data>Submit", data);
          if (data.success) {
            setLoader(false);
            successMessage(data.message);
            const newPlanDetails = JSON.parse(JSON.stringify(clientPlanDetail?.planDetails));
            newPlanDetails.plan_usage_details.consumers.usage_count += 1;
            setTimeout(() => {
              dispatch(setPlanDetails(newPlanDetails));
            }, 10);
            navigate("/clients");

          }
          if (!data.success) {
            setLoader(false);
            errorMessage(data.message);
            // console.log(data.message);
          }
        }

        if (error) {
          // console.log("ERROR:", error.data);
          setLoader(false);
          errorMessage(error?.error ?? error?.data?.error?.message);
        }

      } catch (error) {

      }
    }

    // navigate('/clients');

  };


  return (
    <>
      {dataloader &&
        <CustomDeleteloader message="Loading . . ." />
      }
      {loader &&
        <CustomDeleteloader message={`${clientId ? 'Updating . . .' : 'Adding . . .'}`} />
      }
      <div className="container clients-container" data-aos="zoom-in">
        <div className="spacer"></div>

        <div className="breadcrumb-container">

          <nav className="breadcrumbs">
            <Link to="/" className="breadcrumbs__item">Dashboard</Link>
            <Link to="/clients" className="breadcrumbs__item">Consumers</Link>
            {/* <Link to="" className="breadcrumbs__item">Cart</Link> */}
            <span className="breadcrumbs__item is-active"> {props.updating ? "Edit" : "Create"}</span>
          </nav>
          {/* <div className="spacer"></div> */}
        </div>
        <div className="spacer"> </div>
        <form className=" Clients-form-container " onSubmit={handleSubmit}>
          <div className="heading-card">
            {" "}
            <h3>{props.updating ? "Update" : "Add"} Consumer</h3>
          </div>

          <div className="wrapper-items">
            <div className="col-sm-12 col-md-6 col-12">
              <div className="group-label">
                <label htmlFor="entity_type" className="col-lg-12 col-xl-4"> Consumer Type:<span className="text-danger">*</span> </label>
                <select
                  className="form-select"
                  id="entity_type"
                  name="entity_type"
                  value={formData?.entity_type}
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                >
                  <option value="client">Client</option>
                  <option value="employee">Employee</option>
                  <option value="company">Company</option>
                </select>
              </div>
              <div className="group-label">
                <label htmlFor="entity_name" className="col-lg-12 col-xl-4"> Name<span className="text-danger">*</span> </label>
                <input
                  type="text"
                  name="entity_name"
                  id="entity_name"
                  defaultValue={formData.entity_name}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                  autoComplete="off"
                  placeholder="Siri"
                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_email" className="col-lg-12 col-xl-4">Email<span className="text-danger">*</span> </label>
                <input
                  type="email"
                  name="entity_email"
                  id="entity_email"
                  defaultValue={formData.entity_email}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                  autoComplete="off"
                  placeholder="abc@xyz.com"
                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_phone_personel" className="col-lg-12 col-xl-4">Personal Phone<span className="text-danger">*</span> </label>
                {/* <input
                  type="tel"
                  name="entity_phone_personel"
                  id="entity_phone_personel"
                  value={formData.entity_phone_personel}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                   autoComplete="off"
                  placeholder="(123) 456-7890"
                  // placeholder="+1 (298) 382-4701"
                  maxLength="14"
                /> */}
                <PhoneInput
                  value={formData.entity_phone_personel}
                  country={'us'}
                  onChange={(value, country, e, formattedValue) => handleInputChange(e)}
                  countryCodeEditable={false}
                  inputProps={{
                    name: 'entity_phone_personel'
                  }}
                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_phone_official" className="col-lg-12 col-xl-4">Official Phone<span className="text-danger">*</span> </label>
                {/* <input
                  type="tel"
                  name="entity_phone_official"
                  id="entity_phone_official"
                  value={formData.entity_phone_official}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                   autoComplete="off"
                  placeholder="(123) 456-7890"
                  // placeholder="+1 (298) 382-4701"
                  maxLength="14"

                /> */}
                <PhoneInput
                  value={formData.entity_phone_official}
                  country={'us'}
                  onChange={(value, country, e, formattedValue) => handleInputChange(e)}
                  countryCodeEditable={false}
                  inputProps={{ name: 'entity_phone_official' }}
                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_address" className="col-lg-12 col-xl-4">Street<span className="text-danger">*</span> </label>
                <input
                  type="text"
                  name="entity_address"
                  id="entity_address"
                  defaultValue={formData.entity_address}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                  autoComplete="off"
                  placeholder="Street"
                />
              </div>

              <div className="group-label">
                <label htmlFor="entity_city" className="col-lg-12 col-xl-4"> City<span className="text-danger">*</span> </label>
                <input
                  type="text"
                  name="entity_city"
                  id="entity_city"
                  defaultValue={formData.entity_city}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                  autoComplete="off"
                  placeholder="City"
                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_state" className="col-lg-12 col-xl-4">State<span className="text-danger">*</span> </label>
                <input
                  type="text"
                  name="entity_state"
                  id="entity_state"
                  defaultValue={formData.entity_state}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                  autoComplete="off"
                  placeholder="State"
                />
              </div>
              

            </div>
            <div className="col-sm-12 col-md-6 col-12">
              <div className="group-label">
                <label htmlFor="entity_country" className="col-lg-12 col-xl-4">Country<span className="text-danger">*</span> </label>
                <input
                  type="text"
                  name="entity_country"
                  id="entity_country"
                  defaultValue={formData.entity_country}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                  placeholder="Country"
                  autoComplete="off"
                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_zip_code" className="col-lg-12 col-xl-4">ZipCode<span className="text-danger">*</span> </label>
                <input
                  type="number"
                  name="entity_zip_code"
                  id="entity_zip_code"
                  defaultValue={formData.entity_zip_code}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                  autoComplete="off"
                />
              </div>

              <div className="group-label">
                <label htmlFor="entity_fax" className="col-lg-12 col-xl-4">Fax</label>
                {/* <input
                  type="text"
                  name="entity_fax"
                  id="entity_fax"
                  value={formData.entity_fax}
                  onChange={handleInputChange}
                  className="form-control"
                  // placeholder="+1 (298) 382-4701"
                  maxLength="14"

                /> */}
                <PhoneInput
                  value={formData.entity_fax}
                  country={'us'}
                  onChange={(value, country, e, formattedValue) => handleInputChange(e)}
                  countryCodeEditable={false}
                  inputProps={{ name: 'entity_fax' }}
                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_website" className="col-lg-12 col-xl-4">Website</label>
                <input
                  type="url"
                  name="entity_website"
                  id="entity_website"
                  defaultValue={formData.entity_website}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="https://example.com"

                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_note" className="col-lg-12 col-xl-4">Notes</label>
                <textarea
                  rows="4"
                  cols="30"
                  id="entity_note"
                  name="entity_note"
                  defaultValue={formData?.entity_note}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_gst" className="col-lg-12 col-xl-4">GST#</label>
                <input
                  type="text"
                  name="entity_gst"
                  id="entity_gst"
                  defaultValue={formData.entity_gst}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="group-label">
                <label htmlFor="entity_ntn" className="col-lg-12 col-xl-4">NTN#</label>
                <input
                  type="text"
                  name="entity_ntn"
                  id="entity_ntn"
                  defaultValue={formData.entity_ntn}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="spacer"></div>
              
              {/* entity_type: "",
    entity_name: "",
    entity_email: "",
    entity_phone_personel: "",
    entity_phone_official: "",
    entity_country: "",
    entity_state: "",
    entity_city: "",
    entity_address: "", */}
            </div>
          <div className="btn-button-submit">
            <button type="submit" className={`btn btn-sm btn-save ${formData?.entity_type !== "" &&
              formData?.entity_name !== "" &&
              formData?.entity_email !== "" &&
              formData?.entity_phone_personel.length > 14 &&
              formData?.entity_phone_official.length > 14 &&
              formData?.entity_country !== "" &&
              formData?.entity_state !== "" &&
              formData?.entity_city !== "" &&
              formData?.entity_zip_code !== "" &&
              formData?.entity_address !== "" ? "" : "cursor-disable"}`}
              disabled={formData?.entity_type !== "" &&
                formData?.entity_name !== "" &&
                formData?.entity_email !== "" &&
                formData?.entity_phone_personel.length > 14 &&
                formData?.entity_phone_official.length > 14 &&
                formData?.entity_country !== "" &&
                formData?.entity_state !== "" &&
                formData?.entity_city !== "" &&
                formData?.entity_zip_code !== "" &&
                formData?.entity_address !== "" ? false : true}
              title={formData?.entity_type !== "" &&
                formData?.entity_name !== "" &&
                formData?.entity_email !== "" &&
                formData?.entity_phone_personel.length > 14 &&
                formData?.entity_phone_official.length > 14 &&
                formData?.entity_country !== "" &&
                formData?.entity_state !== "" &&
                formData?.entity_city !== "" &&
                formData?.entity_zip_code !== "" &&
                formData?.entity_address !== "" ? "Click to save." : "Please fill all required fields."}
            >{clientId ? "Update" : "Save"} &nbsp; <span> <i className="fas fa-save"></i></span></button>
          </div>
          </div>
        </form>
      </div>
      <div className="spacer"> </div>
      <div className="spacer"> </div>


    </>
  );
};

export default CreateClients;
