import React, { useCallback } from "react";
import InvoiceDescriptionRow from "./InvoiceDescriptionRow";
import uuid from "react-uuid";
import { separateComma } from "../../utils/utils";

function InvoiceDescription({
  formData,
  handleAddDescription,
  handleRemoveDescription,
  handleChange,
  autoSuggstionitems,
  fillItemFields,allDescitems,
  type,
  showSuggestItem,
  setShowSuggestItem,
  handleHideSuggestion
}) {


  const handleAddRow = useCallback(
    () =>
      handleAddDescription({
        id: uuid(),
        itemDescription: "",
        additionalDetail: "",
        rate: "0.00",
        quantity: "1",
        amount: "0.00",
        tax: formData?.taxType ==="onTotal" ? "true":"false",
        state: "new",
        showSuggestion:false
      }),
    [handleAddDescription,formData?.taxType]
  );

  // console.log(separateComma(Number(formData?.balanceDue)))

  return (
    <>
      <div className="invoiceDescription fadeInOpacity">
        <div className="descriptionHead">
          <div className="col-5">
            <span>DESCRIPTION</span>
          </div>
          <div className="col-2">
            <span>RATE</span>
          </div>
          <div className="col-2">
            <span>QTY</span>
          </div>
          <div className="col-2">
            <span>AMOUNT</span>
          </div>
          <div className="col-1">
            <span>TAX</span>
          </div>
        </div>
        <div className="descriptionBody" id="descriptionBody">
          {/* {inputList} */}
          {formData?.description?.map((element) => {
            return (
              <InvoiceDescriptionRow
                key={element?.id}
                rowData={element}
                handleChange={handleChange}
                currency={formData?.currency?.Symbol}
                deleteRow={handleRemoveDescription}
                autoSuggstionitems={autoSuggstionitems}
                fillItemFields={fillItemFields}
                allDescitems={allDescitems}
                showSuggestItem={showSuggestItem}
                setShowSuggestItem={setShowSuggestItem}
                handleHideSuggestion={handleHideSuggestion}
              />
            );
          })}
        </div>
        <div className="addDescriptionRow">
          <button
            type="button"
            onClick={handleAddRow}
            className="btn btn-sm btn-secondary"
          >
            <i className="fas fa-plus"></i>
          </button>
          {/* <button type="button" onClick={onAddBtnClick} className="btn btn-sm btn-secondary"
          >
            <i className="fas fa-minus"></i>
          </button> */}
        </div>
        <div className="invoiceTotal">
          <div className="col-12 row">
            <div className="col-9" style={{ textAlign: "end" }}>
              Subtotal
            </div>
            <div className="col-3" style={{ textAlign: "end" }}>
              <span>{formData?.currency?.Symbol}</span>
              <span>{separateComma(Number(formData?.subTotal))}</span>
            </div>
          </div>
          {formData?.discountType !== "none" && (
            <div className="col-12 row">
              <div className="col-9" style={{ textAlign: "end" }}>
                Discount ({formData?.discountType ==="flatAmount"? `${formData?.currency?.Symbol}`:''}{formData?.discountType ==="percent"? formData?.discountValue:separateComma(Number(formData?.discountValue))}{formData?.discountType ==="percent"? "%":''})
              </div>
              <div className="col-3" style={{ textAlign: "end" }}>
                <span>-{formData?.currency?.Symbol}</span>
                <span>{separateComma(Number(formData?.discountAmount))}</span>
              </div>
            </div>
          )}
          { formData?.taxType !=="none" &&(
              <div className="col-12 row">
                <div className="col-9" style={{ textAlign: "end" }}>
                  {formData?.taxLabel}({formData?.taxRate}%)
                </div>
                <div className="col-3" style={{ textAlign: "end" }}>
                  <span> {formData?.taxType === "deducted"? "-":""} {formData?.currency?.Symbol}</span>
                  <span>{separateComma(Number(formData?.totalTax))}</span>
                </div>
              </div>)
          }

          <div className="col-12 row">
            <div className="col-9" style={{ textAlign: "end" }}>
              Total
            </div>
            <div className="col-3" style={{ textAlign: "end" }}>
              <span>{formData?.currency?.Symbol}</span>
              <span>{separateComma(Number(formData?.total))}</span>
            </div>
          </div>
          {type === "chargable" &&
            <div className="col-12 row">
              <div className="col-9" style={{ textAlign: "end" }}>
                Paid
              </div>
              <div className="col-3" style={{ textAlign: "end" }}>
                <span>{formData?.currency?.Symbol}</span>
                <span>{separateComma(Number(formData?.totalPaid))}</span>
              </div>
            </div>
          }
          { formData?.type === "chargable" &&
            <div className="col-12 row">
              <div className="col-9" style={{ textAlign: "end" }}>
                Balance Due
              </div>
              <div className="col-3" style={{ textAlign: "end" }}>
                <span>{formData?.currency?.Symbol}</span>
                <span className="font-weight-bold">
                  {separateComma(Number(formData?.balanceDue))}
                </span>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default InvoiceDescription;
