import React, { useCallback } from "react";
import uuid from "react-uuid";
import InvoiceDescriptionRow from "./InvoiceDescriptionRow";
import { separateComma } from "../../utils/utils";
// import uuid from "react-uuid";

function InvoiceDescription({
  formData,
  handleAddEarning,
  handleAddDeduction,
  handleRemoveDescription,
  handleChange,
  total,
  totalDeductions,
  // selectedCurrency,
  handleChange2,
  name,
  allDeductionitems,
  allEarningItems,
  autoSuggstionitems,
  fillItemFields,
  showSuggestEarn,
  setShowSuggestEarn,
  showSuggestDeduct,
  setShowSuggestDecuct,
  handleHideSuggestion,
//  code, symbol 
}) {
  const handleAddRow = useCallback(
    () =>
      handleAddEarning({
        id: uuid(),
        earning_title: "",
        earning_amount: "0.00",
        state: "new",
        showSuggestion:false
      }),
    [handleAddEarning]
  );
  // console.log(formData?.currency_selected?.Symbol,'111222')
  const result = total - totalDeductions;
  // let
  const handleAddRow2 = useCallback(
    () =>
      handleAddDeduction({
        id: uuid(),
        deduction_title: "",
        deduction_amount: "0.00",
        state: "new",
        showSuggestion:false
      }),
    [handleAddDeduction]
  );
  

  return (
    <>
      <div className="salary-description-wrapper">
        <div className="salary-description-table">
          <div
            className="invoiceDescription fadeInOpacity w-100">
            <div className="descriptionHead">
              <div className="col-3">
                <span>EARNINGS</span>
              </div>
              <div className="col-3">
                <span>AMOUNT</span>
              </div>
              {/* <div className="col-3">
                <span>DEDUCTION</span>
              </div>
              <div className="col-3">
                <span>AMOUNT</span>
              </div> */}
              {/* <div className="col-1">
                <span>TAX</span>
              </div> */}
            </div>
            <div className="descriptionBody" id="descriptionBody">
              {/* {inputList} */}
              {formData?.earnings?.map((element, index) => {
                return (
                  <InvoiceDescriptionRow
                    type={"earnings"}
                    key={index}
                    rowData={element}
                    handleChange={handleChange}
                    placeHolder={"Earnings Title"}
                    currency={formData?.currency?.Symbol}
                    deleteRow={handleRemoveDescription}
                    selectedOption={element.earning_title}
                    inputName={'earning_title'}
                    amount={'earning_amount'}
                    inputValues={element.earning_amount}
                    suggestList={allEarningItems}
                    autoSuggstionitems={autoSuggstionitems}
                    fillItemFields={fillItemFields}
                    showSuggest={showSuggestEarn}
                    setShowSuggest={setShowSuggestEarn}
                    handleHideSuggestion={handleHideSuggestion}
                  />
                );
              })}
            </div>
            <div className="addDescriptionRow">
              <button
                type="button"
                onClick={handleAddRow}
                className="btn btn-sm btn-primary"
              >
                <i className="fas fa-plus"></i>
              </button>
              {/* <button type="button" onClick={onAddBtnClick} className="btn btn-sm btn-secondary"
              >
                <i className="fas fa-minus"></i>
              </button> */}
            </div>
            <div>
              <p className="total-amount-formdata">Total Earnings:&nbsp; {formData?.currency_selected?.Symbol}{separateComma(Number(total))}</p>


            </div>

          </div>
          {/* ========================== */}
          <div
            className="invoiceDescription fadeInOpacity"
            style={{ width: "100%" }}
          >
            <div className="descriptionHead">
              <div className="col-3">
                <span>DEDUCTIONS</span>
              </div>
              <div className="col-3">
                <span>AMOUNT</span>
              </div>
              {/* <div className="col-3">
              <span>DEDUCTION</span>
            </div>
            <div className="col-3">
              <span>AMOUNT</span>
            </div> */}
              {/* <div className="col-1">
              <span>TAX</span>
            </div> */}
            </div>
            <div className="descriptionBody" id="descriptionBody">
              {/* {inputList} */}
              {formData?.deductions?.map((element, index) => {
                return (
                  <InvoiceDescriptionRow
                    type={"deductions"}
                    key={index}
                    rowData={element}
                    handleChange={handleChange}
                    currency={formData?.currency?.Symbol}
                    deleteRow={handleRemoveDescription}
                    selectedOption={element.deduction_title}
                    inputName={'deduction_title'}
                    amount={'deduction_amount'}
                    inputValues={element.deduction_amount}
                    placeHolder={"Deduction Title"}
                    suggestList={allDeductionitems}
                    autoSuggstionitems={autoSuggstionitems}
                    fillItemFields={fillItemFields}
                    showSuggest={showSuggestDeduct}
                    setShowSuggest={setShowSuggestDecuct}
                    handleHideSuggestion={handleHideSuggestion}
                  />
                );
              })}
            </div>
            <div className="addDescriptionRow">
              <button
                type="button"
                onClick={handleAddRow2}
                className="btn btn-sm btn-primary"
              >
                <i className="fas fa-plus"></i>
              </button>
              {/* <button type="button" onClick={onAddBtnClick} className="btn btn-sm btn-secondary"
            >
              <i className="fas fa-minus"></i>
            </button> */}
            </div>
            <div>
              <p className="total-amount-formdata">
                Total Deductions:&nbsp; {formData?.currency_selected?.Symbol}{separateComma(Number(totalDeductions))}
              </p>

              <p></p>
            </div>
            {/* <div className="invoiceTotal">
            <div className="col-12 row">
              <div className="col-9" style={{ textAlign: "end" }}>
                Subtotal
              </div>
              <div className="col-3" style={{ textAlign: "end" }}>
                <span>{formData?.currency?.Symbol}</span>
                <span>{finalTotalSum}</span>
              </div>
            </div>
              { formData?.discountType !== "none" &&
                <div className="col-12 row">
                  <div className="col-9" style={{ textAlign: "end" }}>
                    Discount ({formData?.discountValue}%)
                  </div>
                  <div className="col-3" style={{ textAlign: "end" }}>
                    <span>-{formData?.currency?.Symbol}</span>
                    <span> {finalDiscount}</span>
                  </div>
                </div>
              }
            <div className="col-12 row">
              <div className="col-9" style={{ textAlign: "end" }}>
                Tax ({formData?.taxRate}%)
              </div>
              <div className="col-3" style={{ textAlign: "end" }}>
                <span>{formData?.currency?.Symbol}</span>
                <span> {finalTotalTax}</span>
              </div>
            </div>
            <div className="col-12 row">
              <div className="col-9" style={{ textAlign: "end" }}>
                Total
              </div>
              <div className="col-3" style={{ textAlign: "end" }}>
                <span>{formData?.currency?.Symbol}</span>
                <span>{finalGrandTotal}</span>
              </div>
            </div>
            {
              <div className="col-12 row">
              <div className="col-9" style={{ textAlign: "end" }}>
                Paid
              </div>
              <div className="col-3" style={{ textAlign: "end" }}>
                <span>-{formData?.currency?.Symbol}</span>
                <span>{totalPayed.toFixed(2)}</span>
              </div>
            </div>
            }
            <div className="col-12 row">
              <div className="col-9" style={{ textAlign: "end" }}>
                Balance Due
              </div>
              <div className="col-3" style={{ textAlign: "end" }}>
                <span>{formData?.currency?.Symbol}</span>
                <span className="font-weight-bold">{parseFloat(balanceDue).toFixed(2)}</span>
              </div>
            </div>
          </div> */}
          </div>
        </div>
        <div>
          <p
            className="total-amount-formdata"
            style={{ borderTop: '1px solid #cdc5c5', paddingTop: '10px' }}


          >
            Net Amount:&nbsp;  {formData?.currency_selected?.Symbol}{separateComma(Number(result))}
          </p>
        </div>

      </div>
    </>
  );
}

export default InvoiceDescription;
