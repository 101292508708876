import React, { useEffect, useState } from 'react'
import { useLazyEmailHistoryQuery } from '../../store/apis/baseApi';
import { errorMessage } from '../../components/toastMessages/ToastifyMsg';
import { deepCopy } from '../../utils/utils';
import CustombuttonSpinner from '../../components/CustombuttonSpinner';
import moment from "moment/moment";
import SingleEmailRecord from './SingleEmailRecord';
import { useLocation } from 'react-router';

   
function EmailHistory() {
    const [showEmail, setShowEmail] = useState("invoice");

    const location = useLocation();
    const {state} = location;

    const [tableloader, setTableloader] = useState(true);
    const [emailList,setEmailList] = useState([]);
    const [emailCount,setEmailCount] = useState(0);

    const [filterEmail,setFilterEmail] = useState([]);
    const [search,setSearch] =useState("");

    
    useEffect(() => {
        if (state?.emailHistory) {
            setShowEmail(state.emailHistory);
        }
    }, [state]);

    const [emailHistory] = useLazyEmailHistoryQuery();


    useEffect(() => {

        (async () => {
            try {
                setTableloader(true)
                const { data, error } = await emailHistory(showEmail)
                if (data) {
                    if (data?.success) {
                        const allEmailresp = deepCopy(data?.data)
                        const allEmails = allEmailresp?.sents;
                        let convertedTime = allEmails.map((email) => {
                            let date = new Date(email.sent_at);
                            const momentDate = moment(date);
                            const formattedDate = momentDate.format('MMMM D YYYY, h:mm:ss A');
                            // let invoiceDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0];
                            return { ...email, sent_at: formattedDate }
                        });
                        setEmailCount(allEmailresp?.count);
                        setEmailList(convertedTime);
                        setFilterEmail(convertedTime);
                    }
                    if (!data.success) {
                        errorMessage(data?.message);
                    }
                }
                if (error) {
                    errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
                }
            } catch (error) {
            } finally {
                setTimeout(() => {
                    setTableloader(false);

                }, 1000);
            }
        })();
    }, [emailHistory, showEmail]);

    useEffect(() => {
        const result = emailList.filter((email) => {
          return email?.email_to?.toLowerCase().match(search.toLowerCase()) ||  email?.number?.toLowerCase().match(search.toLowerCase());
        });
        setFilterEmail(result);
      }, [search, emailList]);

    return (
        <>
            <section className="email-wrapper">
                <div className='spacer'></div>
                {/* <div className='spacer'></div> */}

                <div className="container invoice-list" data-aos="zoom-in">
                    <h4 className='text-capitalize'>
                        email history 
                    </h4>
                    <div className="invoice-actions">
                        <div className="invoice-navBtn">
                            <button
                                onClick={() => setShowEmail('invoice')}
                                className={`btn btn-secondary btn-sm ${showEmail === 'invoice' ? "active cursor-disable" : ""
                                    }`}
                                type="button"
                            >
                                Invoices
                            </button>
                            <button
                                onClick={() => setShowEmail('quotation')}
                                className={`btn btn-secondary btn-sm ${showEmail === 'quotation' ? "active cursor-disable" : ""
                                    }`}
                                type="button"
                            >
                                Quotations
                            </button>
                            <button
                                onClick={() => setShowEmail('salary')}
                                className={`btn btn-secondary btn-sm ${showEmail === 'salary' ? "active cursor-disable" : ""
                                    }`}
                                type="button"
                            >
                                Payslips
                            </button>
                        </div>
                        <div className='search-container'>
                                        <label className="col-xs-12" htmlFor='search'>Search:</label>
                                        <input type='search' className="col-xs-12 form-control" id='search' defaultValue={search} onChange={(e)=>setSearch(e.target.value)}  placeholder={`search by email or ${showEmail==="salary"?'payslip':showEmail } Number`} autoComplete='off'></input>
                        </div>

                    </div>
                    {
                        tableloader &&
                        <CustombuttonSpinner />
                    }
                    <div className="spacer"></div>
                    <div className="overflow-hidden">
                        {!tableloader &&
                            (
                                <>
                                <div className="table-responsive email-wrapper-size"> 
                                    <div className="email-wrapper-inner" data-aos={`fade-${showEmail ==='invoice'? 'right':showEmail ==="quotation"? 'up':'left'}`}>
                                        { filterEmail.length ? 
                                        filterEmail.map((email,index)=>{
                                            return (
                                                <SingleEmailRecord key={index} email={email}/>
                                            )
                                        }): (<div className='text-capitalize'>There are no records to display.</div>)

                                        }
                                    </div>

                                </div>
                                
                                </>
                            )
                        }

                    </div>

                    <div className="spacer"></div>
                    {!tableloader && 
                        <div className='text-start'><p><b>Total <span className='text-capitalize'> {showEmail}</span> Emails sent :</b> {emailCount} emails</p></div>
                    }
                </div>

            </section>
            <div className="spacer"></div>
                    <div className="spacer"></div>

        </>
    )
}

export default EmailHistory
