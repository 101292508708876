export function convertNumberToCommaNotation(nmbr) {
    return parseFloat(nmbr).toLocaleString("en");
}

export const makeDeepCopy = (data) => structuredClone(data);

export function deepCopy(obj) {
    let copiedObject = obj;

    if (obj && typeof obj === "object") {
        copiedObject = Array.isArray(obj) ? [] : {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] && typeof obj[key] === "object") {
                    copiedObject[key] = deepCopy(obj[key]);
                } else {
                    copiedObject[key] = obj[key];
                }
            }
        }
    }

    return copiedObject;
}

export const idGenerator = (idLength = 16) => {
    let chars =
        "0123456789QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm";
    let firstChar = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm";
    let id = firstChar[Math.floor(Math.random() * firstChar.length)];
    for (let i = 0; i < idLength - 1; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        id += chars[randomNumber];
    }
    return id;
};

export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export function throttle(func, limit = 1000) {
    let lastFunc;
    let lastRan;
    return function () {
        if (!lastRan) {
            func.apply(this, arguments);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func.apply(this, arguments);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
}
// export const FormatDate=(dateString)=>{
//     const date = new Date(dateString);
// const formatteddate = date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });

// console.log(formatteddate); // Output: Jul 18, 2022
// }

// ------------Number Format START-----------------

// Custom function to separate comma
export function separateComma(input) {
    // remove sign if negative
    var val = input.toFixed(2);
    var sign = 1;
    if (val < 0) {
      sign = -1;
      val = -val;
    }
    // trim the number decimal point if it exists
    let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();
    let len = num.toString().length;
    let result = '';
    let count = 1;
  
    for (let i = len - 1; i >= 0; i--) {
      result = num.toString()[i] + result;
      if (count % 3 === 0 && count !== 0 && i !== 0) {
        result = ',' + result;
      }
      count++;
    }
  
    // add number after decimal point
    if (val.toString().includes('.')) {
      result = result + '.' + val.toString().split('.')[1];
    }
    // return result with - sign if negative
    return sign < 0 ? '-' + result : result;
  }
  
//   let num1 = 1223415678.426;
//   console.log(separateComma(num1));
  
//   // decimal number
//   let num2 = -723694769.2343;
//   console.log(separateComma(num2));


// console.log(">>",number.toLocaleString('en',{minimumFractionDigits:2, maximumFractionDigits:2}));
 //to display in clients locale
// console.log(number.toLocaleString(navigator.language,{minimumFractionDigits:2, maximumFractionDigits:2}));


// -----------Number Formate END------------------

export function capitalizeString(string) {
    if (typeof string == "string") {
        return string.split(" ").reduce((str, elem) => {
            return str + " " + elem.charAt(0).toUpperCase() + elem.slice(1).toLowerCase();
        }, "");
    }
    return "";
}


export function capitalizeFirstCharatcter(string) {
    return typeof string === "string" ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export function toastApiMessage(inputString) {
    inputString = capitalizeFirstCharatcter(inputString);
    inputString = inputString.replaceAll(".", "");
    return inputString + ".";
}

// ------------ For telephone number Formate START --------------

export function teleValidation(e){
    // Set a flag to indicate whether the backspace key was pressed last.
    let backspacePressedLast = false;

    // Listen for keydown events on the input.
      // Get the current key code.
      let currentKey = e.which;

      // If the backspace key was pressed, set the flag to true.
      if (currentKey === 8 || currentKey === 46) {
        backspacePressedLast = true;
      } else {
        backspacePressedLast = false;
      }

    // Listen for input events on the input.
      // If the backspace key was pressed last, do nothing.
      if (backspacePressedLast) {
        return;
      }

      // Get the current value of the input.
      let currentValue = e.target.value;

      // Remove all non-digits from the current value.
      let newValue = currentValue.replace(/\D+/g, '');
      // Format the new value to the telephone format.
      let formattedValue = formatToTelephone(newValue);

      // Set the value of the input to the formatted value.
      return formattedValue;

  
  }


  function formatToTelephone(str) {
      var splitString = str.split(''),
          returnValue = '';

      for (var i = 0; i < splitString.length; i++) {
          var currentLoop = i,
              currentCharacter = splitString[i];

          switch (currentLoop) {
              case 0:
                  returnValue = returnValue.concat('(');
                  returnValue = returnValue.concat(currentCharacter);
                  break;
              case 2:
                  returnValue = returnValue.concat(currentCharacter);
                  returnValue = returnValue.concat(') ');
                  break;
              case 5:
                  returnValue = returnValue.concat(currentCharacter);
                  returnValue = returnValue.concat('-');
                  break;
              default:
                  returnValue = returnValue.concat(currentCharacter);
          }
      }

      return returnValue;
  }
// ------------ For telephone number Formate END --------------

// ----------- For current date START -------------------------

export function currentData(){
    var now = new Date();
 
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);

    var today = now.getFullYear()+"-"+(month)+"-"+(day) ;
    return today;
}

// for global notification background-color

export function notificationBackgroundColor(type){
    // news=info
    // update=success
    // alarming=danger
    // normal=primary/info
    const color = type === "news"? "notification-info":type === "update"? "notification-success": type === "alarming" ? "notification-danger":type === "normal" ? "notification-primary":"notification-primary"
    return color;
}

export const notiIcon =(type)=>{ return type === "news"? "info-circle":type === "update"? "check-circle": type === "alarming" ? "exclamation-triangle":""}

function numberToEnglish( n ) {
        
    var string = n.toString(), units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words, and = 'and';

    /* Remove spaces and commas */
    string = string.replace(/[, ]/g,"");

    /* Is number zero? */
    if( parseInt( string ) === 0 ) {
        return 'zero';
    }
    
    /* Array of units as words */
    units = [ '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen' ];
    
    /* Array of tens as words */
    tens = [ '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety' ];
    
    /* Array of scales as words */
    scales = [ '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion' ];
    
    /* Split user argument into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while( start > 0 ) {
        end = start;
        chunks.push( string.slice( ( start = Math.max( 0, start - 3 ) ), end ) );
    }
    
    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if( chunksLen > scales.length ) {
        return '';
    }
    
    /* Stringify each integer in each chunk */
    words = [];
    for( i = 0; i < chunksLen; i++ ) {
        
        chunk = parseInt( chunks[i] );
        
        if( chunk ) {
            
            /* Split chunk into array of individual integers */
            ints = chunks[i].split( '' ).reverse().map( parseFloat );
        
            /* If tens integer is 1, i.e. 10, then add 10 to units integer */
            if( ints[1] === 1 ) {
                ints[0] += 10;
            }
            
            /* Add scale word if chunk is not zero and array item exists */
            if( ( word = scales[i] ) ) {
                words.push( word );
            }
            
            /* Add unit word if array item exists */
            if( ( word = units[ ints[0] ] ) ) {
                words.push( word );
            }
            
            /* Add tens word if array item exists */
            if( ( word = tens[ ints[1] ] ) ) {
                words.push( word );
            }
            
            /* Add 'and' string after units or tens integer if: */
            if( ints[0] || ints[1] ) {
                
                /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
                if( ints[2] || (i + 1) > chunksLen ) {
                    words.push( and );
                }

            
            }
            
            /* Add hundreds word if array item exists */
            if( ( word = units[ ints[2] ] ) ) {
                words.push( word + ' hundred' );
            }
            
        }
        
    }
    
    return words.reverse().join( ' ' );
    
}



export function numberToWords(digit) {
    const string = numberToEnglish(digit);
    
    const words = string.split(" ");
    
    const capitalizedString = words.map(word => word[0].toUpperCase() + word.slice(1).toLowerCase());
    let inWords = capitalizedString.join(" ");
    return inWords;
    
}

 
export async function generateSignatureImage(name){
    const canvas = document.createElement('canvas');
    // const canvas = document.getElementById('signature-canvas');
    // console.log("Canvas: ", canvas);
    const ctx = canvas.getContext('2d');
    // console.log("ctx",ctx);
    ctx.font = '40px brittney';
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, 300, 100);
    ctx.fillStyle = '#000000';
    
    ctx.fillText(name, 10, 60);

    let imgUrl = canvas.toDataURL();
    return imgUrl
    
}
export const suffix = (currentDate)=>{

    let suffix = "";
    if (currentDate === 1 || currentDate === 21 || currentDate === 31) {
        suffix = "st";
    } else if (currentDate === 2 || currentDate === 22) {
        suffix = "nd";
    } else if (currentDate === 3 || currentDate === 23) {
        suffix = "rd";
    } else {
        suffix = "th";
    }
    return `${currentDate}${suffix}`
}


export const dataTablecustomStyles = {
	headRow: {
		style: {
			// border: 'none',
            zIndex: '99',
		},
	},
	headCells: {
		style: {
			color: '#202124',
			fontSize: '14px',
            // zIndex: '99'
		},
	},
	rows: {
    style: {
      borderBottom: '1px solid #FFFFFF',
      },

		highlightOnHoverStyle: {
			backgroundColor: 'rgb(36 182 247 / 8%)',
			borderBottomColor: '#FFFFFF',
			borderRadius: '25px',
			outline: '1px solid #FFFFFF',
		},
	},
	pagination: {
		style: {
			// border: 'none',
            // justifyContent: 'end!important'
		},
	},
};
