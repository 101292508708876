import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCheckSubscriptionOrderMutation } from '../../store/apis/laravelApi';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import CustomDeleteloader from '../../components/CustomDeleteloader';
import { FRONTSITE } from '../../common/common';

function SubscriptionSuccess() {
    const frontsiteUrl = FRONTSITE;

    const [loading, setLoading] = useState(true);
    const [subscribedPlan, setSubscribedPlan] = useState("");
    const [errMsg,setErrMsg] = useState("");
    const [err,setErr] = useState(false); 

    const auth = useSelector(state => state.auth);

    const [checkSubscriptionOrder] = useCheckSubscriptionOrderMutation();



    useEffect(() => {
        const handleCheckSubOrder = async () => {
            try {
                const body = {
                    user_email: auth?.user?.email,
                    user_id: auth?.user?.id
                }
                const resp = await checkSubscriptionOrder(body);
                if (resp.data?.status === "success") {
                    if (resp?.data?.data?.stripe_payment_status.toLowerCase() ==="paid") {
                        setSubscribedPlan(resp?.data?.data);
                    }
                    if (resp?.data?.data?.stripe_payment_status.toLowerCase() ==="unpaid") {
                        setErrMsg("Not subscribed to any plan.");
                        setErr(true);
                        
                    }
                }
                if(resp?.data?.str)
                if (resp?.error) {
                    console.log(resp?.error)
                    if (resp?.error?.status ===403) {   
                        setErrMsg(resp?.error?.data?.message);
                        setErr(true);
                    }
                }
            } catch (error) {
                console.log(error);
            }
            finally { setLoading(false) }
        }
        setLoading(true);
        setTimeout(() => {
            handleCheckSubOrder();
        }, 4000);
    }, [checkSubscriptionOrder, auth])




    return (
        <>
            {loading ? <CustomDeleteloader message="Loading . . ." /> :
                <div className='container p-5'>
                    <div className="subscribed-card rounded-lg col-md-6 col-xl-5">
                        <div style={{ 'borderRadius': '200px', 'height': '200px', 'width': '200px', 'background': '#F8FAF5', 'margin': '0 auto', }}>
                            
                            {err? <i className="fas fa-exclamation checkmark text-danger m-0" style={{'fontSize':'70px'}}></i>: <i className="checkmark">✓</i>}
                        </div>
                        <h1 className={err?'text-danger':''}> {err?"Opps":'Success'} </h1>
                        {err? <p>{errMsg}</p>:
                        <p>You have successfully subscribed to our {subscribedPlan?.valid_for_days === 30 ? 'monthly' : 'yearly'} plan.
                            Your plan will expire on {moment(subscribedPlan?.expires_on).format('ll')}
                            {/* You will shortly receive an <br/> email of your payment. */}
                        </p>

                        }
                            <Link to='/' className='btn btn-primary mt-3 me-2'><i className='fa fa-home me-1'></i> Back to Dashboard</Link>
                            {err &&
                                <Link to={`${frontsiteUrl}contact-us`} target='_blank' className='btn btn-primary mt-3'><i className='fa fa-phone me-1'></i> Contact Us</Link>
                            }
                        
                    </div>

                </div>
            }
        </>

    )
}

export default SubscriptionSuccess