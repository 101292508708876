import React, {  useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DataTable from "react-data-table-component";
// import CustombuttonSpinner from "../components/CustombuttonSpinner";
import { dataTablecustomStyles, deepCopy } from "../utils/utils";
import TableLoader from "../components/loaders/TableLoader";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";



function Allsalaries({allClient, deleteEntity, isLoading}) {
  const [allClients, setAllClients] = useState([]);
  const [search, setSearch] = useState("");
  const [filterClients, setFilterClients] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
      let allData= deepCopy(allClient)
      // console.log(allData)
      setAllClients(allData);
      setFilterClients(allData);
     
  }, [allClient]);
  

  




  const columns = [
    {
      name: "Consumers",
      selector: (row) =>  row.entity_name  ,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => ( <span className="text-capitalize">{row.entity_type}</span>),
    },
    {
      name: "Phone",
      selector: (row) => row.entity_phone_official,
    },
    {
      name: "Email",
      selector: (row) => row.entity_email,
      // sortable: true,
    },
    {
      name: "Country",
      selector: (row) => ( <span className="text-capitalize">{row.entity_country}</span>),
    },
    // {
    //   name: "City/State",
    //   selector: (row) => row.entity_city,
    // },
    {
      // name: "",
      cell: (row) => (
        <div className="d-flex" title='Select an action'>
           
          <DropdownButton
              as={ButtonGroup}
              key={'start'}
              id={`dropdown-button-drop-${row.id}`}
              drop={'start'}
              variant="dropdown-menu-custom"
              title={<i className="fas fa-ellipsis-v"></i> }
              // size="sm"
            >
              <Dropdown.Item eventKey="1">
                    <div
                    className="d-flex gap-2 align-items-center listing-button-edits"
                    onClick={() => {
                      navigate(`/clients/edit/${window.btoa(`${row.id}`)}`);
                    }} 
                    title='View Consumer'
                  >
                    <i className="fa fa-eye"></i> View
                  </div>
              </Dropdown.Item>
            <Dropdown.Item eventKey="2">
              <div 
                className="d-flex gap-2 align-items-center listing-button-edits"
                onClick={() => {
                  navigate(`/clients/edit/${window.btoa(`${row.id}`)}`);
                }}
                title="Edit Consumer"
              >
                <i className="fa fa-edit"></i> Edit
              </div>
            </Dropdown.Item>

              <Dropdown.Item eventKey="3">
                <div
                  className="d-flex gap-2 align-items-center listing-button-edits"
                  onClick={() => deleteEntity(row.id, row.entity_name)}
                  title={`Delete ${row.entity_name} Consumer`}
                >
                  <i className="fa fa-trash-alt text-danger"></i> Delete
              </div>
              </Dropdown.Item>

              {/* <Dropdown.Divider /> */}

              

            </DropdownButton>
        </div>
      ),
      width: '60px',
      right: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    const result = allClients.filter((client) => {
      return client?.entity_name?.toLowerCase().match(search?.toLowerCase());
    });
    setFilterClients(result);
  }, [search, allClients]);
  return  (
    <>

      <div className="container px-0">
        

        <div className="py-2">
          {/* 
            title={"All Invoices"} 
            fixedHeader
            fixedHeaderScrollHeight="400px"
          */}
          {isLoading ? (<TableLoader/>)
          :
          (
            <div data-aos="fade-right">
              <DataTable
              columns={columns}
              data={filterClients}
              fixedHeader
              fixedHeaderScrollHeight="400px"
              className="table-scroll-custom"
              highlightOnHover
              pointerOnHover  
              responsive
              subHeader
              subHeaderComponent={
                <div className="col-md-4 col-12">
                <div className="table-header-custom">
                  <label className="col-md-3 col-12 text-start" htmlFor="search">Search: </label>
                  <div className="col-md-9 col-12">
                    <input
                      type="search"
                      placeholder="Search By Consumer"
                      className="form-control"
                      value={search}
                      autoComplete="off"
                      // style={{ width: "28%" }}
                      onChange={(e) => setSearch(e.target.value)}
                      name="search"
                      id="search"
                    ></input>
                  </div>
                </div>
              </div>
              }
              // subHeaderAlign="left"
              onRowClicked={(row,event)=>{navigate(`/clients/edit/${window.btoa(`${row.id}`)}`)}}
              pagination
              sortIcon={<i className="fa fa-angle-down"></i>}
              customStyles={dataTablecustomStyles}
            />

            </div>
          )

          }
          
        </div>
      </div>
     
    </>
  );
}

export default Allsalaries;
