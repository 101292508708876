import React, { useState } from 'react'
import Modal from '../modal/Modal'
import ReactPortal from '../reactPortal/ReactPortal'
import './GalleryImage.css'

export default function GalleryImages({galleryImages}) {
    const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
      }
      // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }
      const prevSlide = () => {
        slideNumber === 0 
        ? setSlideNumber( galleryImages.length -1 ) 
        : setSlideNumber( slideNumber - 1 )
      }
    
      // Next Image  
      const nextSlide = () => {
        slideNumber + 1 === galleryImages.length 
        ? setSlideNumber(0) 
        : setSlideNumber(slideNumber + 1)
      }
  return (
    <>
    <div className='galleryWrap'>
        {
          galleryImages && galleryImages.map((slide, index) => {
            return(
              <div 
                className='single p-2' 
                key={index}
                onClick={ () => handleOpenModal(index) }
              >
                <div className="single-img">
                    <img src={slide.img_filename} alt='' />
                </div>
                <div className="details">
                  <p><b>{slide.img_description}</b></p>
                  <small>{slide.img_additional_notes}</small>
                </div>
              </div>
            )
          })
        }
      </div>
        <ReactPortal>
            {openModal && 
                <Modal handleClose={handleCloseModal} type="images">
                  
                        <div className='sliderWrap'>
                        <div className="sliderHead">
                          <p>{galleryImages[slideNumber].img_description}</p>
                        </div>
                            <i className="fa fa-times-circle btnClose" onClick={handleCloseModal}></i>
                            <i className="fa fa-chevron-left btnPrev" onClick={prevSlide}></i>
                            <i className="fa fa-chevron-right btnNext" onClick={nextSlide}></i>


                        {/* <i icon={faCircleXmark} className='btnClose'   ></i>
                        
                        <i icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} ></i>
                        <i icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} ></i> */}
                        <div className='fullScreenImage'>
                            <img src={galleryImages[slideNumber].img_filename} alt='' />
                        </div>
                        </div>
                </Modal>
            }
        </ReactPortal>
    </>
  )
}
