import React from 'react';
import { Accordion } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FRONTSITE } from '../common/common';

function PricingFaq() {
    const frontsiteUrl = FRONTSITE;
    return (
        <section className='price-faq-wrapper'>
            <div className='custom-plan-wrapper'>
                <p className='m-0 me-3'>If you are looking for a customized package tailored to your needs, please contact us.</p>
                <Link to={`${frontsiteUrl}contact-us`} className='btn btn-contact-us' target='_blank' title='Go to contact us.'>
                    <span className='me-1'> Contact us </span>
                    {/* <i className='fa fa'></i> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"></path>
                        </svg>
                </Link>
            </div>
            <div className='container'>
                <div className='rounded shadow pricing-faq bg-white pb-3'>
                    <h3 className='text-center pt-3'>Pricing FAQ's</h3>
                    <Accordion >
                        <Accordion.Item eventKey="0" className="border-0">
                            <Accordion.Header className="accordion-header-custom">How does the free trial work?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Our free trial offers full access to all the features of our service for a limited time without any cost. To start your free trial, simply sign up and provide a valid payment method, which will only be charged if you decide to continue with a subscription after the trial period ends. You will receive an email reminder before your trial expires, giving you the option to cancel or upgrade to a paid plan. If you choose not to continue, cancel any time before the trial ends to avoid any charges.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="border-0">
                            <Accordion.Header className="accordion-header-custom">What happens to my current subscription if I purchase a new package?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    When you purchase a new package, any previously subscribed package will expire immediately, regardless of whether it has been fully used or not. This allows you to start enjoying your new package's features right away.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className="border-0">
                            <Accordion.Header className="accordion-header-custom">Can I switch from a monthly to a yearly plan?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Yes, you can switch from a monthly to a yearly plan at any time. When you upgrade, your monthly plan will be discontinued immediately, and you will be charged for the yearly plan on a pro-rata basis depending on the remaining period of your current subscription.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="border-0">
                            <Accordion.Header className="accordion-header-custom">What is the difference between monthly and yearly subscriptions?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Monthly subscriptions offer flexibility with a pay-as-you-go approach, ideal for short-term needs. Yearly subscriptions, on the other hand, are billed annually and typically come at a discounted rate compared to the total cost of 12 monthly payments. This plan is cost-effective for users who require long-term access.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" className="border-0">
                            <Accordion.Header className="accordion-header-custom">How do renewals work for monthly and yearly subscriptions?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    Monthly and yearly subscriptions do not automatically renew at the end of each subscription period. To continue your service without interruption, you will need to manually renew your subscription by purchasing again. You will receive a reminder as your subscription end date approaches, giving you ample time to renew. If you do not renew by the end date, your subscription will expire.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5" className="border-0">
                            <Accordion.Header className="accordion-header-custom">What is your refund policy for subscriptions?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    We generally do not offer refunds for paid subscriptions unless there are extenuating circumstances. If you believe your situation warrants a refund, please contact our customer support team with details for consideration.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6" className="border-0">
                            <Accordion.Header className="accordion-header-custom">Can I upgrade or downgrade my subscription plan midway through a subscription period?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    You can upgrade your subscription plan at any time. However, downgrades are only processed at the end of the current subscription period to ensure that you fully benefit from the plan you've already paid for.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7" className="border-0">
                            <Accordion.Header className="accordion-header-custom">What should I do if I want to cancel my subscription?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    You can cancel your subscription at any time through your account settings. Once canceled, your subscription will remain active until the end of the current billing period, and you will not be charged for the next period.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8" className="border-0">
                            <Accordion.Header className="accordion-header-custom">What payment methods do you accept for subscriptions?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    We accept a variety of payment methods including credit cards, debit cards. All payments are processed securely to ensure your financial information is protected. Please ensure your payment details are up-to-date to avoid any disruption in your subscription services.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9" className="border-0">
                            <Accordion.Header className="accordion-header-custom">How can I access my subscription details?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    You can view and manage your subscription details by logging into your account on our website. Once logged in, navigate to the 'Account Settings' An go to "Subscribed Plan" section where you can see your current plan ,Usage , renewal dates, and payment history. This section also allows you to update payment methods and purchase new subscriptions.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="10" className="border-0">
                            <Accordion.Header className="accordion-header-custom">What should I do if there is a billing error or discrepancy?</Accordion.Header>
                            <Accordion.Body>
                                <p className="text-start">
                                    If you notice any discrepancies or errors in your billing, please contact our customer support team immediately. Provide them with your account details and a description of the issue. We are committed to resolving billing issues promptly and will ensure any errors are corrected and adjustments are made to your account as necessary.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>
            </div>
        </section>
    )
}

export default PricingFaq