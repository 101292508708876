import React from 'react'

const SingleEmailRecord = ({email}) => {
    return (
        <>
            <div className="single-email-wrapper">
                <div className="single-email-inner">
                    <div className="single-email-inner-container">
                        <p><b> {email?.number}</b></p>
                        <p><b>Send To:</b> <span>{email?.email_to}</span> </p>
                        {email?.cc_status === "true" &&(
                            <p><b>CC:</b> <span>{email?.cc_emails}</span></p>
                        )}
                        <p><b>Attachment:</b> <span>{email?.attachment === "true" ?"Attached":"Not Attached"}</span></p>
                        <p><small>{email?.sent_at}</small></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleEmailRecord
