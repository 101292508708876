import React from 'react'

function CustomDeleteloader({message}) {
  return (
    <div className="custom-loader-container">
    <div className="custom-loader">
        {/* <div className="spinner"></div> */}
        {/* <img src={logo} alt=""></img> */}
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <div><b>{message} </b></div>
    </div>
</div>
  )
}

export default CustomDeleteloader