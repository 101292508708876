import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
// import Header from "./components/Header";
import CustomLoader from "./components/CustomLoader";
import SideBar from "./components/sidebar/SideBar";
import DashboardHeader from "./components/dashboardHeader/DashboardHeader";
import { setUser } from "./store/slices/userSlice";
import { setBackgroundUrl } from "./store/slices/backgroundPicture";
import { successMessage } from "./components/toastMessages/ToastifyMsg";
import ReactPortal from "./components/reactPortal/ReactPortal";
import Modal from "./components/modal/Modal";
import Footer from "./components/footer/Footer";
import CustomDeleteloader from "./components/CustomDeleteloader";

function Dashboard() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isConfirmation, setIsConfirmation] = useState(false);
  const [logoutLoader, setLogoutLoader] = useState(false);

  const logout = () => {
    localStorage.removeItem("tradingformtoken");
    dispatch(setUser(null));
    dispatch(setBackgroundUrl("company_bg_images/backgroundpic.png"));
    // clearAuthorizationToken();
    navigate("/login");
    successMessage("You've been Logged out");
    // setLogoutLoader(false);
  };


  const confirmLogout = (type) => {

    if (type === true) {
      setIsConfirmation(false);
      setLogoutLoader(true);
      setTimeout(logout(), 1000);

    }
    if (type === false) {
      setIsConfirmation(false);
    }
  }

  const confirmModal = () => {
    setIsConfirmation(true);
  }

  return user ? (
    <>
      <SideBar logoutConfirmModal={confirmModal}/>
      <main className="dashboard-layout-wrapper">
        {/* <Header /> */}
        <DashboardHeader/>
        <Outlet />
        <div className="spacer"></div>
        <Footer/>
      </main>

      <ReactPortal>
        {isConfirmation && (
          <Modal
            icon={"exclamation-circle text-info"}
            header="Photo Details"
            type="confirm"
            recordName={`Logging out will lead to the loss of unsaved changes. Are you sure you want to proceed with logging out`}
          >
            <button className="btn btn-save m-1 col-xs-12 col-sm-6 col-md-3" onClick={() => confirmLogout(true)} title="Yes, Logut."> Yes</button>
            <button className="btn btn-primary m-1 col-xs-12 col-sm-6 col-md-3" onClick={() => confirmLogout(false)} title="Do not Logout">No</button>
            {/* index={index} onImage={onImage} */}
          </Modal>
        )}
      </ReactPortal>

      {logoutLoader === true &&
        <CustomDeleteloader message="Logging out . . ." />
      }
    </>
  ) : (
    <CustomLoader/>
  );
}

export default Dashboard;
