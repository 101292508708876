import React from 'react'

function Page({id,singleMode,documentRef,children}) {
    return (
      <div ref={documentRef}
      id={id} className="bg-white shadow-1 center pa4" 
      style={{ "display":'none'}}
    >
      {children}
    </div>
    )
  }

export default Page