import React from 'react'
import { Link } from 'react-router-dom'
import { suffix } from '../../../utils/utils'
import moment from 'moment/moment';


function WirelessContractDoc({ content, contractContent, date, handleOpenSignTest, handleShowSpecimen, url, companyPro, handleChange, submit, clientByRef, clientNameRef, clientTitleRef }) {
   
    return (
        <>
            <h3 style={{ 'textAlign': 'center' }}> DP Access MASTER SERVICES AGREEMENT</h3>
            <p><strong></strong></p>
            <p>This Agreement (&ldquo;Agreement&rdquo;) is made, as of  <span style={{ 'padding': '1px 10px', 'borderBottom': '1px solid #000' }}><b>{content?.created_date}</b></span> (&ldquo;Effective Date&rdquo;), by and
                between DP
                Access, a Florida limited liability corporation (&ldquo;DP Access LLC&rdquo;) having its principal place of
                business
                11610 Sally Ann Drive, Palm Beach Gardens, FL 33412 and <span style={{ 'padding': '1px 15px', 'borderBottom': '1px solid #000' }}><b>{content?.service_company_name}</b></span> (&ldquo;Customer&rdquo;) with
                offices at <span style={{ 'padding': '1px 10px', 'borderBottom': '1px solid #000' }}><b>{content?.client_address}</b></span> (jointly referred to herein as the &ldquo;Parties&rdquo; or individually as
                *Party). <br /> <br /> WHEREAS, DP Access is able to provide and arrange for telecommunication
                services
                (collectively referred to as &ldquo;Services&rdquo;), <br /> <br /> WHEREAS, Customer wishes to engage DP
                Access to provide Consolidated Billing as a Service (CBaaS).</p>

            <p>1. &nbsp;<strong>Term; Termination; Early </strong><strong>Termination</strong>: Shall mean termination of
                any and
                all
                contracted services before the agreed upon term agreed specified in this agreement <br />
            </p>
            <p>
                1.1.&nbsp;
                This
                Agreement consists of the terms and conditions set forth herein together
                with any schedules, amendments, addenda, and any other documents that are expressly incorporated
                by
                reference herein.

            </p>

            <p><strong><u>TERM</u></strong>:<br />
                1.2.&nbsp; This Agreement commences on the Effective Date which isthe
                date of the Electronic signature or Portal signature by customer and shall continue for a term of Three (3)
                years
                (&ldquo;Initial Term&rdquo;) unless terminated by either Party as specified in Section 1.3 of the agreement.
                This
                Agreement shall automatically renew for successive additional three (3) year terms (each a &ldquo; Renewal
                Term&rdquo;) unless either Party terminates this Agreement by providing at least ninety (90) days&rsquo; written
                notice of termination to the other Party prior to the expiration of the Initial Term or the Renewal Terms.
                The
                termination of this Agreement pursuant to this Section 1.2 will not affect any Services then currently in
                effect. Notwithstanding the above early termination fees will be due and payable at the time of client termination of Services
              for the remaining contract terms.
            </p>
            <p> <strong>TERMINATION</strong>: <br />
                1.3.&nbsp; Either Party may terminate this Agreement and any Services then in effect upon written notice at
                any time prior to the expiration of the Initial Term or any
                subsequent Renewal Term without penalty or liability under the following condition:</p>
            <p>
                1.3.1.&nbsp;
                Either Party fails to perform any of its material obligations under this Agreement (a &ldquo;Default&rdquo;) and
                does not cure such Default within thirty (30) days of receipt from the other Party written notice specifying
                the
                nature of the Default.
            </p>
            <p>
                1.3.2.&nbsp; Either Party (a) is the subject of any legal proceedings
                relating to its liquidation, insolvency or reorganization, or for the appointment of a receiver, trustee or
                similar officer; (b) makes a general assignment for the benefit of its creditors;
                (c)
                enters into an agreement for the composition, extension, consolidation or readjustment
                of
                all or substantially all of its financial obligations; (d) ceases to operate in the normal course of
                business or
                becomes unable to meet its financial obligations as they become due; or (e) is subject to regulatory
                sanction by
                any
                federal regulatory agency following a judicial or administrative proceeding or following an
                acknowledgement of liability, or wrongdoing arising out of, or in any way
                connected with, a negotiated settlement of a charge or
                allegation
                brought by a federal regulatory agency. The foregoing provision shall not
                apply
                with respect to any involuntary petition of bankruptcy filed against either Party so long as such
                involuntary
                petition is discharged within Sixty (60) days of such filing.
            </p>

            <p>2.&nbsp; Assignment. Neither Party shall assign its rights or delegate its duties under this Agreement
                without the
                prior
                written consent of the other Party, which consent shall not be unreasonably withheld,
                delayed or conditioned, except that either Party may
                assign this Agreement, without the other Party&rsquo;s consent,
                to &nbsp;(i)&nbsp; any parent, subsidiary or Affiliate (as defined
                below in Section 2.1) of such Party, so long as
                the
                assigning Party remains fully liable hereunder or &nbsp;(ii)&nbsp; to a successor in interest of
                all
                or substantially all of the assets, stock or business of a Party, unless the acquirer is a direct competitor
                of
                the other Party. Any attempted assignment or delegation
                in
                contravention of this Section shall be void and of
                no effect, and shall be considered a material default of this agreement.
                Subject to the provisions of this Section, this Agreement shall inure to the benefit of and be binding upon
                the
                Parties as well as their respective successors and assigns, if any.
            </p>
            <p>
                2.1.&nbsp; Affiliate.
                Any partnership, joint venture, corporation, limited liability company or other enterprise, domestic or
                foreign,
                including, but not limited to, parent or subsidiary, that directly or indirectly Controls (as defined below
                in
                Section 2.2), is controlled by, or is under common Control with a Party to this Agreement.
            </p>

            <p>
                3.&nbsp; Service Orders
            </p>

            <p>
                3.1.&nbsp; To receive the Services described herein, Customer
                shall
                place a written Service Order as applicable Service Schedules or place the order through The DP Access
                website
                portal.
            </p>
            <p>
                3.2.&nbsp; All Service Orders are subject to the terms and conditions of this
                Agreement, and no action by either party shall be considered to be any waiver of any terms or
                conditions herein, unless expressly waived in writing by
                an authorized representative of either party. In the event of a conflict
                between the Service Schedule and this Managed Services Agreement, the Service Schedule will govern the
                interpretation of the Parties agreement.
            </p>
            <p>
                4.&nbsp; Title and Risk of Loss to any
                equipment. Title and risk of loss to any equipment purchased by Customer will pass to Customer upon delivery
                of
                the equipment to the location specified in the Service Order.
            </p>
            <p>
                5.&nbsp; Fees; Fee Disputes, Price Changes.
            </p>

            <p>5.1.  Fees shall be charged as provided in the applicable Schedule(s), Amendment(s) or Addenda. DP Access collects an administrative account fee of 7.5% that is 
                subject to change. Payment shall be due thirty (30) days after date of the invoice sent to Customer at the address set forth in Section 18, with
                 the exception of any disputed amounts provided in Section 5.3. Any payment received after thirty (30) days from the invoice date will be considered late and a one- and one-half percent 
                 (1.5%) late payment fee may be assessed monthly, prorated for partial months, on the outstanding amount due. 
                 <br/><br/>
                 Customer is responsible for all related taxes and account fees.
            </p>



            <p>
            5.2. DP Access will notify customer within thirty (30) days of any pricing increases related to individual telecommunication services. 
            </p>


            <p>
            5.3.  Customer may dispute any charge or amount on any invoice, provided that Customer submits written notice of such dispute to DP Access.  
            Any notice of dispute shall be sufficiently detailed so that DP Access may conduct a meaningful investigation of the dispute. DP Access must receive from Customer 
            written notice within thirty (30) days of the date of receipt of transmission and timestamp of email sent containing the invoice.  
            Customer will work with DP Access to resolve the dispute prior to the next billing cycle.  Failure by Customer to follow the provisions herein within thirty (30) days of 
            the receipt of transmission and timestamp of email sent containing the invoice shall render all charges and amounts listed on an invoice due in accordance with Section 5.1 
            within thirty (30) days of receipt of email transmission and timestamp of email sent containing the invoice. Failure to pay an invoice within sixty (60) days will result in Service suspension.   
             DP Access acknowledges and agrees that, as  long  as  the  explanation  relating  to  the  dispute  is  sufficiently  detailed (a) payment of the disputed portion of any invoice may be withheld until such dispute is resolved, and (b) such lack of payment shall not constitute a breach of this Agreement or provide a basis for the cessation of the provision of equipment or Services. 
            </p>



            <p>6. Billing. Each Service Order shall be billed as provided below.</p>
            <p>
                6.1. Non-usage
                based services - all non-usage based services are invoiced 30
                days
                in advance of use plus any non-recurring charges and prorated
                monthly services.
            </p>
            <p>
                6.2. Usage based services - all usage based
                services are invoiced 30 days in advance of use plus
                any non-recurring charges and prorated monthly services. Usage exceeding the
                Customer
                selected data plan (overage) will be invoiced in the following month.
            </p>
            <p>
                6.3.
                Lost or stolen DP Access assets provided to a Customer
                under this Agreement, pursuant to Section 4, will be
                billed
                at the currently published Manufacturer&rsquo;s Suggested Retail Price (MSRP).
            </p>

            <p>6.4. PAYMENT. When DP Access invoices Customer or pays Customer carrier service invoices on behalf of
                its
                customers; DP Access will provide Customer access to all invoices electronically. Customer agrees to pay DP
                Access
                for all Services within five (5) days of DP Access&rsquo;s email (with confirmed receipt) to
                Customer&rsquo;s
                email
                address (as set forth above) notifying Customer that its invoice is available for review electronically.
                Payment
                must be made by ACH transfer. Amounts not paid on or before five (5) days from receipt of invoice shall be
                considered past due, and, if not paid within (15) days of notice thereof from DP Access, Customer agrees to
                pay
                a
                late payment charge equal to one and one-half percent (1.5%) per month, compounded, as applied against the
                past
                due
                amounts. Customer must give DP Access written notice of a dispute with respect to DP Access charges or
                application
                of Taxes within three (3) months of the date of an invoice, or such invoice shall be deemed to be correct
                and
                binding on Customer. Customer shall be liable for the payment of all fees and expenses, including
                attorney&rsquo;s
                fees, reasonably incurred by DP Access in collecting, or attempting to collect, any charges owed hereunder.
                DP
                Access may require a security deposit based on customer credit. In the case of customer emergency, should DP
                Access
                not receive payment, within 30 days of invoice, DP Access will use security deposit to pay outstanding
                invoices.
                However, once DP Access pays invoices using the customer&rsquo;s security deposit and does not receive
                replacement
                security deposit, it will stop paying future invoices and the customer will be subject to carrier
                discontinuance
                of
                service which may result in disruption or termination of service. DP Access is in no way responsible or
                liable
                for
                damages caused by disruption or termination of service for any reason but will make every effort to work
                with
                phone
                companies to restore service provided the DP Access invoice and security deposit are paid in full and
                current.
                DP
                Access will electronically store invoices for two years. Customer may choose, as an alternative to ACH
                transfer,
                to
                pay for Managed Service back up in full via credit card. Or by utilizing DP Access Leasing program.</p>
            <p></p>
            <p>6.5&nbsp; <Link to="https://www.lawinsider.com/clause/creditworthiness" target="_blank" style={{ "color": "#807b7b" }}> <strong>Creditworthiness</strong></Link>. In order
                any
                company to participate in DP Access services, Company must be creditworthy in accordance with the standard
                banking
                policies. Company agrees that DP Access may request and obtain credit information about Company to determine
                their
                creditworthiness. Creditworthiness of Company is determined by DP Access in its sole discretion. DP Access
                Service
                may be denied by DP Access prior to install based on creditworthiness.
            </p>
            <p>7. &nbsp;Availability of Service. Telecommunication service is subject
                to
                transmission limitations and dropped calls caused by
                atmospheric
                or topographical conditions. All telecommunication service types may be temporarily refused,
                limited, interrupted or curtailed due to governmental regulations or orders, system capacity
                limitation or equipment modifications, upgrades, relocations, repairs,
                and
                similar activities necessary or appropriate for the proper operation of
                telecommunication service, or any force majeure. DP Access shall use commercially reasonable efforts
                to
                limit the duration of any such delay.
            </p>
            <p>
                8. Performance Warranty and Limitation
                of
                Liability.
            </p>
            <p>
                8.1. Performance Warranty. DP Access warrants that the equipment will
                function as set forth in this Agreement and that DP Access will provide to Customer all Services in a
                commercially reasonable manner as set forth in this Agreement. THIS PERFORMANCE WARRANTY IS SUBJECT TO
                THE
                WARRANTY EXCLUSIONS SET FORTH BELOW IN SECTION 8.2.
            </p>
            <p>
                8.2. Performance Warranty
                Exclusions. Except as may be expressly agreed in writing by DP Access, the performance warranty does
                not
                apply to:
            </p>
            <p>
                8.2.1. Defects, problems, or failures caused by the Customer&rsquo;s
                intentional misconduct, negligence or nonperformance of obligations essential to DP Access&rsquo;s
                performance
                of its obligations; and/or
            </p>
            <p>
                8.2.2. Defects, problems, or failures caused by an
                event
                force majeure, as described in Section 9 below; and/or
            </p>
            <p>
                8.2.3. Defects, problems,
                or
                failures caused by the Customer&rsquo;s unauthorized use of telecommunication service.
            </p>
            <p>
                8.3.
                DISCLAIMER OF ALL OTHER WARRANTIES. THIS PERFORMANCE
                WARRANTY,
                AND THE WARRANTIES IN SECTION 8 HEREIN, ARE IN LIEU OF, AND DIVERSITY
                POWER
                DISCLAIMS, ANY AND ALL OTHER WARRANTIES, CONDITIONS, OR REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR
                WRITTEN)
                WITH RESPECT TO THE SERVICES PROVIDED UNDER THIS AGREEMENT,
                INCLUDING, WITHOUT LIMITATION, ANY AND ALL WARRANTIES OF MERCHANTABILITY OR FITNESS
                OR
                SUITABILITY FOR ANY PURPOSE, WHETHER ALLEGED TO ARISE BY LAW, BY REASON OF CUSTOM OR
                USAGE IN THE TRADE, OR BY COURSE OF DEALING. IN
                ADDITION, DIVERSITY POWER DISCLAIMS ANY WARRANTY OR REPRESENTATION TO ANY
                PERSON
                OTHER THAN CUSTOMER WITH RESPECT TO THE SERVICES PROVIDED UNDER THIS AGREEMENT.
            </p>
            <p>
                8.4.
                NOTWITHSTANDING ANY OTHER PROVISION OF THIS AGREEMENT, IN NO EVENT SHALL EITHER
                PARTY
                BE LIABLE TO THE OTHER FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES (INCLUDING, WITHOUT
                LIMITATION,
                DAMAGES FOR LOST PROFITS, LOST REVENUES, LOSS OF
                GOODWILL,
                LOSS OF DATA, LOSS OF ANTICIPATED SAVINGS, LOSS OF
                CUSTOMERS, OR
                INTERFERENCE WITH BUSINESS) ARISING OUT OF THE PERFORMANCE OR FAILURE TO PERFORM UNDER THE TERMS OF THIS
                AGREEMENT OR THE PROVISION OF SERVICES, WHETHER OR NOT CAUSED BY THE ACTS OR OMISSIONS OR NEGLIGENCE OF
                SUCH PARTY&rsquo;S EMPLOYEES OR AGENTS, REGARDLESS OF THE
                FORESEEABILITY THEREOF AND REGARDLESS OF WHETHER SUCH PARTY HAS BEEN INFORMED
                OF
                THE POSSIBILITY OR LIKELIHOOD OF SUCH DAMAGES.
            </p>
            <p>
                8.5. &ldquo;CUSTOMER EXPRESSLY
                UNDERSTANDS AND AGREES THAT IT HAS NO CONTRACTUAL RELATIONSHIP WHATSOEVER WITH THE UNDERLYING WIRELESS
                SERVICE
                PROVIDER OR ITS AFFILIATES OR CONTRACTORS AND THAT CUSTOMER IS NOT A THIRD PARTY BENEFICIARY
                OF ANY AGREEMENT BETWEEN DIVERSITY POWER AND THE UNDERLYING
                CARRIER. IN ADDITION, CUSTOMER ACKNOWLEDGES AND AGREES THAT
                THE
                UNDERLYING CARRIER AND ITS AFFILIATES AND CONTRACTORS SHALL
                HAVE NO LEGAL, EQUITABLE, OR OTHER LIABILITY OF ANY KIND TO CUSTOMER AND CUSTOMER HEREBY WAIVES ANY
                AND
                ALL CLAIMS OR DEMANDS THEREFOR.&rdquo;
            </p>

            <p> 8.6. The provisions in Section 8 shall survive termination or expiration of this Agreement.</p>
            <p>9. Indemnification; Force Majeure. </p>
            <p>
                9.1. DP Access shall
                indemnify, defend and hold Customer harmless from and against, and pay any
                judgments, costs, awards, settlements, claims, expenses, fines, losses, damages,
                suits, fees and liabilities awarded against Customer resulting from any
                claim brought by a third party against Customer based on: (a) material
                breach by
                DP Access of the confidentiality obligations set forth in the NDA; (b) violation by DP Access of any
                applicable
                laws, ordinances, regulations or governmental rules; (c) acts of gross negligence or willful misconduct of
                DP
                Access
                or its employees or subcontractors; (d) injury to the property or person of
                Customer
                or Customer&rsquo;s customers or end-users by DP Access or its employees
                or subcontractors; (e) alleged and actual infringement of any patent, copyright or trademark of any
                third party arising from the equipment, unless and except to the extent that such infringement is caused by
                (i)
                DP
                Access&rsquo;s use of data supplied by Customer, or (ii) the improper installation, repair, use or
                maintenance
                of
                the equipment by any persons who or entities which are not employees of DP Access, subcontractors of DP
                Access,
                or
                otherwise acting with authorization on DP Access&rsquo;s behalf.
            </p>
            <p>
                9.2. Customer
                shall indemnify and hold DP Access harmless from, defend DP Access against,
                and pay any judgments, costs, awards, settlements, claims, expenses, fines,
                losses, damages, suits, fees and liabilities awarded against DP Access resulting from any claim brought by a
                third
                party against DP Access based on: (a) breach by Customer of the
                confidentiality obligations set forth in this Agreement, (b)
                violation by Customer of any applicable laws, ordinances, regulations or governmental rules; (c)
                acts or
                gross negligence of or willful misconduct of Customer or its employees or subcontractors; or (d) injury to
                the
                property or person of DP Access by Customer or its employees or subcontractors. <br /> <br />
            </p>
            <p>
                9.3.
                Neither Party will be liable for any failure to fulfill an obligation under this Agreement if such
                fulfillment
                is delayed, prevented, restricted or interfered with for any reason as a
                result of a Force Majeure Event. Force Majeure Event, for
                purposes
                of this agreement, shall be defined as an occurrence outside of
                Party&rsquo;s
                reasonable control and without fault of such Party that
                prevents,
                delays, hinders such Party from performance by reason of
                the
                following: loss or damage due to fire, explosion, act of God or public enemy, power blackout, earthquake,
                flood,
                embargo, civil disorder, riot, act of civil or military authority, act of public enemy, terrorist threat or
                activity, war (declared or undeclared), civil disturbance, act of any government, de jure or de facto,
                or any agency or official thereof, unusually severe
                weather,
                quarantine or restriction, epidemic, or catastrophe, interruption
                or
                failure of telecommunication or digital transmission link, Internet failure
                or
                delay, or any other cause beyond the party&rsquo;s reasonable
                control, provided that such delay, failure, loss or damage could not have been prevented by
                commercially
                reasonable precautions. <br /> <br /> In the event that either Party hereto shall be delayed or hindered
                in or
                prevented from the performance of any act required hereunder by reason of Force Majeure Event, then the
                fulfillment
                of such obligation shall be excused for the period of the delay and the period for the fulfillment of such
                obligation shall be extended for a period equivalent to the period of such delay. <br /> <br />
            </p>
            <p>
                10.
                Confidential Information; Security.
            </p>
            <p>
                10.1. Each Party (a &ldquo;Receiving Party&rdquo;)
                shall hold in confidence, the information, which is confidential and/or proprietary to the other Party
                (&ldquo;Disclosing Party&rdquo;) (&ldquo;Confidential Information&rdquo;). Receiving Party shall not
                disclose
                or make Confidential Information available, in any form, to any third party or use Confidential
                Information for any purpose other than as specified in
                this Agreement. Receiving Party shall take all
                reasonable steps to ensure that Confidential Information is
                disclosed or distributed to its employees or agents that have
                need-to-know
                basis to complete the obligations of this Agreement. Receiving Party
                shall
                use the same care in safeguarding Disclosing Party&rsquo;s
                Confidential Information that it uses to protect its own confidential
                information, which shall in no event be less than reasonable standard of care. <br /> <br />
            </p>
            <p>
                10.2.
                Disclosing Party&rsquo;s Confidential Information shall include,
                but
                not be limited to, all information, regardless of form,
                relating to this Agreement, Services, rates, pricing, incentive programs, financial
                information,
                network plans, marketing information, technology, business plans or strategies, any other financial,
                technical, and/or business information, and all information
                clearly
                marked or designated as confidential, or by its nature
                should
                be regarded as Confidential Information.
            </p>
            <p>
                10.3. The foregoing shall
                not
                prevent Receiving Party from disclosing Confidential Information
                which: (i) is or becomes a part of the public domain
                through no act or omission of Receiving Party in breach of this Agreement; (ii) was in Receiving
                Party&rsquo;s
                lawful possession prior to such access to or the disclosure of same and had not been obtained by Receiving
                Party
                either directly or indirectly from Disclosing Party, all of which is so documented by Receiving
                Party;
                (iii) is lawfully disclosed to Receiving Party by a
                third party without restriction on such disclosure; (iv) is
                required to be disclosed pursuant to lawfully issued subpoena or other legal process,
                provided,
                however, that Receiving Party first provides written notice to Disclosing Party of the request; (v) is
                approved
                in
                writing by Disclosing Party for disclosure; or (vi) is independently developed and is so documented by
                Receiving
                Party.
            </p>
            <p>
                10.4. Receiving Party acknowledges that Disclosing Party is likely to suffer
                irreparable
                damage in the event of any material breach of the provisions of this disclosure. Accordingly, in such
                event,
                Disclosing Party may be entitled to seek injunctive relief, as well as any other applicable remedies at law
                or
                in
                equity against breach or prospective breach of this Section.
            </p>
            <p>
                10.5. Receiving Party
                shall
                provide Disclosing Party with immediate written notice of any actual or potential requirement of any court
                or
                agency
                requiring disclosure of Confidential Information to afford Disclosing Party the opportunity to seek a
                protective
                order or other relief limiting such disclosure before Receiving party responds to such
                demand/request/Order, Receiver will reasonably cooperate with Disclosing
                Party, at Disclosing Party&rsquo;s expense, in obtaining such relief.
            </p>
            <br />
            <p>
                11. Third Party Beneficiaries. Except as otherwise set forth in this Agreement, there
                are
                no third party beneficiaries to this Agreement.
            </p>
            <p>
                12. Survival. This Section and
                Sections 8.4, 9.1, 9.2, 10, 13, 15, and 24 of this Agreement shall survive termination or expiration of this
                Agreement and continue in full force and effect.
            </p>
            <p>
                13. Limitation on Damages. Except for
                each Party&rsquo;s indemnification obligations pursuant to Sections 9.1 and 9.2, provisions hereof each
                Party&rsquo;s liability for general money damages, if any, arising out of or in any way related to its
                performance
                under this Agreement shall be limited to an amount not to exceed the total charges paid by the Client
                hereunder
                during one (1) month immediately preceding the date upon which the claim for such damages arose. Such
                general
                money
                damages shall be the exclusive remedy. IN NO EVENT WILL EITHER PARTY BE LIABLE, ONE TO THE OTHER, FOR
                ANY SPECIAL, INDIRECT, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES IN
                CONNECTION WITH OR ARISING OUT OF THIS AGREEMENT.
            </p>
            <p>
                14.
                Service Fees. Fees apply to invoice preparation, site identification management, IP addressing and asset
                tracking.
            </p>
            <p>
                15. &nbsp;Waiver of Jury Trial. Each Party waives any right it may have to trial by jury in the event of
                litigation
                arising out of this Agreement.
            </p>
            <p>
                16. Severability. If any term, provision or
                part
                of this Agreement is to any extent held invalid, void or unenforceable by a court or agency of competent
                jurisdiction, the remainder of this Agreement shall not be impaired or affected thereby, and each term,
                provision and part shall continue in full force and effect, and shall be valid and enforceable to the
                fullest
                extent permitted by law.
            </p>
            <p>

            </p>
            <p>

            </p>
            <p>
                17. Notices. Except as otherwise provided in this
                Agreement,
                all notices required or permitted to be given hereunder shall be in writing and shall be valid and
                sufficient if
                dispatched by
                a) registered or certified mail, postage prepaid, in any post office in the United States;
                b) hand delivery; or
                c) nationally recognized overnight courier; or
                d) facsimile transmission upon confirmation
                of
                receipt, followed by a copy sent via regular first class mail. Such notices shall be deemed received
                upon
                actual delivery, refusal to accept delivery, or, in the case of registered or certified mail, five (5)
                days
                after dispatch if the most recent address supplied by the recipient is not accurate or is no longer a
                valid
                address for the recipient. <br /> <br /> If to Customer: <br />
                Company:  <span>{content?.service_company_name}</span><br />
                Address: <span>{content?.client_address}</span><br />
                City: <span>{content?.client_city}</span><br />
                State: <span>{content?.client_state}</span><br />
                Zip Code: <span>{content?.client_zipcode}</span><br />
                ATTN:
                <br /> <br /> If to DP Access LLC: <br /> Address: 11601 Sally Ann
                Drive <br /> City: Palm Beach
                Gardens <br /> State:
                FL <br /> Zip Code: 33412 <br /> ATTN:
                Legal
                Department <br /> <br />
            </p>

            <p>
                18. Billing Address. All invoices notifications shall be sent to
                Customer via email:
            </p>
            <p>
                19. Independent Contractor.
                Neither
                Party nor its officers and directors and its
                associated
                personnel and employees (all hereinafter designated &ldquo;Employees&rdquo;)
                shall
                be deemed to be agents or Employees of the other Party, it being understood that both Parties are
                independent
                contractors for all purposes and at all times, and DP Access shall be solely responsible for the
                withholding
                or
                payment of all Federal, State and local Personal Income Taxes, Social Security, Unemployment and
                Sickness
                Disability Insurance and other payroll taxes with respect to only its own Employees, including
                contributions
                from them when and as required by law.
            </p>

            <p>
                20. Authority. The Parties hereby
                represent and warrant that they have full power and authority to enter into and perform this Agreement
                and
                the
                Parties know of no contracts, agreements, promises or undertakings that would prevent the full execution
                and
                performance of this Agreement. Customer will ensure that only an Authorized Contact request changes to
                new
                and existing Service Orders. An &ldquo;Authorized Contact&rdquo; is an individual who is designated and
                granted
                authority to act on behalf of Customer for any and all matters contemplated by this Agreement, including
                accessing the account, and the ability to purchase equipment, add lines of service, cancel service, and
                make
                changes to the account that financially bind the Customer.
            </p>

            <p>
                21. Reservation of Rights.
                Either
                Party&rsquo;s waiver of any provision of this Agreement or its remedies for a breach by the other Party
                must
                be
                in writing and is without prejudice and shall not operate to waive any other remedies which a Party
                shall
                have
                available to it, nor shall such waiver operate to waive the other Party&rsquo;s rights to any remedies
                for a
                future breach, whether of a like or different character.
            </p>
            <p>
                22. Headings. All
                headings of
                the articles of this Agreement are inserted for convenience only and shall not affect any construction
                or
                interpretations of this Agreement.
            </p>

            <p>
                23. Entire Agreement. This Agreement together with
                the
                Schedule(s), Addenda or Amendment(s) specifically referenced and attached hereto embodies the entire
                understanding between Customer and DP Access with respect to the subject matter contained herein and
                there
                are
                no contracts, agreements, understandings, conditions, warranties or representations, oral or written,
                express or
                implied, with reference to the subject matter hereof which are not merged herein. Except as otherwise
                specifically stated, no modification hereto shall be of any force or effect unless reduced to writing
                and
                signed
                by both Parties hereto.
            </p>

            <p>
                24.  Governing Law.  This Agreement shall be governed by the law of the State of Florida regardless of any conflicts 
                of laws or rules that would require the application of the laws of another jurisdiction. Any dispute arising  in 
                connection with this Agreement shall be decided by the state or federal courts located in the State of Florida, 
                County of Palm Beach and the Parties agree to submit to the jurisdiction of the courts of the State of Florida for that purpose. 
            </p>
            <p>25. Software License.</p>
            <p>
                25.1. Use. Customer
                shall
                have the right to use the Software in accordance
                with the documentation for the purposes of sales,
                testing,
                certification, maintenance and the provision of telecommunication services.
            </p>



            <p>25.2. Limitations on Use. Customer also agrees not to use, copy, modify, transfer, rent, reverse
                engineer, decompile or disassemble any Software or documentation except as expressly authorized in this
                Agreement or
                as permitted by law.</p>

           
            <br />

            
            <br />
            <p><strong>Attachments:</strong></p>

            <p><strong>SCHEDULE A &ndash; Consolidated Billing as a Service (C BaaS) </strong></p>
            <p><strong>SCHEDULE D&ndash; Wireless Internet</strong></p>
            <p>
                <strong>SCHEDULE C - MAINTENANCE
                </strong>
            </p>
            <br /><br /><br />
            <h4 style={{ "textAlign": "center" }}>SCHEDULE A - Consolidated Billing as a Service (C BaaS)</h4>
            <br />
            <p>
                1. Definitions: The following terms and definitions shall have the following
                meanings as described herein:
            </p>
            <p>
            C Baas: DP Access  provides customer audit software, pays customer invoices including but limited to local, Long distance, Internet, wireless and all other telephone company and/or telephone carrier invoices consolidated on a single DP Access invoice for customer to make monthly payment to DP Access. 
            </p>

            <p>
                2. C BaaS: Customer may choose to participate in the Services set forth below. <br /> <br />

            </p>


            <p>
                Customer&rsquo;s
                first single Internet Access invoice ordered through the portal will be free of the charges below. All
                customer
                invoices added after will receive the following service and charges. Once a second invoice is added
                below
                pricing applies.
            </p>
            <ul>
                <li>Monthly Software Audits and of each Invoice </li>
                <li>      Billing Consolidation of Invoices (where
                    possible)</li>

                <li> Direct on Time Payment of all Invoices</li>
                <li>  One Bill Summary of all Invoices</li>

                <li>
                    Detailed Online Invoices
                </li>
                <li>
                    Assistance in Ordering, Implementation of Service
                    Recommendations<br />

                </li>


            </ul>
            <p>DPA’s Telecom Audit-Pay-Invoice and Reporting Pricing:</p>
            <ul>
                <li>1.5% of monthly net billing</li>
                <li>Or:</li>
                <li>$30.00 Monthly Minimum Domestic (US) Invoice, $50.00 per International Invoice</li>
                <li>Whichever is greater - $30.00 per invoice or 1.5% of monthly net billing.</li>
                <li>$125.00 Set up Charge per Domestic (US) Invoice, $175.00 per International Invoice</li>
                <li>$150.00 Deactivation per Domestic (US) Invoice, $200.00 per International Invoice*</li>
                <li>$150.00 Basic per order processing charge including analog lines, DSL, POTS</li>
                <li>Order processing charges on complex orders subject to additional charges</li>
                <li>Advanced General Ledger and Trend Reporting monthly fee &ndash; 15.00 per invoice and .75 cents per line
                    item.
                    Custom pricing for large applications.</li>
                <li>Set up charges apply for Reporting Services per application.</li>
                <li>Wireless Reporting pricing to be determined on a case-by-case basis.</li>
            </ul>
            <p></p>
            <p>*Deactivation and billing will stop once DP Access no longer receives the customer invoice.</p>
            <p></p>
            <p>
                3. PAYMENT. Specific to Audit-Pay Service: When DP Access pays Customer carrier service invoices on behalf
                of
                its
                customers; DP Access will provide Customer access to all invoices electronically. Customer agrees to pay
                DP
                Access for all Services within five (5) days of DP Access&rsquo;s email (with confirmed receipt) to
                Customer&rsquo;s email address (as set forth above) notifying Customer that its invoice is available for
                review
                electronically. Payment must be made by ACH transfer or Credit Card payment plus three percent process
                fee.
                Amounts not paid on or before five (5) days from receipt of invoice shall be considered past due, and,
                if
                not
                paid within (15) days of notice thereof from DP Access, Customer agrees to pay a late payment charge
                equal
                to
                one and one-half percent (1.5%) per month, compounded, as applied against the past due amounts. Customer
                must
                give DP Access written notice of a dispute with respect to DP Access charges or application of Taxes
                within
                three (3) months of the date of an invoice, or such invoice shall be deemed to be correct and binding on
                Customer. Customer shall be liable for the payment of all fees and expenses, including attorney&rsquo;s
                fees,
                reasonably incurred by DP Access in collecting, or attempting to collect, any charges owed hereunder. In
                the
                case of customer emergency, should DP Access not receive payment, within 30 days of invoice, DP Access
                will
                use
                security deposit to pay outstanding invoices. However, once DP Access pays invoices using the
                customer&rsquo;s
                security deposit and does not receive replacement security deposit, it will stop paying future invoices
                and
                the
                customer will be subject to local phone (or carrier) discontinuance of service which may result in
                disruption or
                termination of service. DP Access is in no way responsible or liable for damages caused by disruption or
                termination of service for any reason but will make every effort to work with phone companies to restore
                service
                provided the DP Access invoice and security deposit are paid in full and current. DP Access will
                electronically
                store invoices for two years.
            </p>

            <p>4. EARLY TERMINATION FEE. In the event Customer terminates this Agreement prior to the conclusion of the
                Agreement
                Term, DP Access will calculate the number of months remaining in the Term multiplied by the past 12
                months
                monthly average fees at the time of termination. The resulting amount will be invoiced to Customer on
                the
                final
                invoice as Early Termination Fee and is due within thirty calendar days from final invoice date. A
                deactivation
                fee will apply as set forth in attached Schedule(s). Such amount represents liquidated damages, and not
                a
                penalty, and is based on Customer&rsquo;s commitment to pay the monthly fees for the entire Term of this
                Agreement and DP Access&rsquo;s agreement to the fees set forth in the attached Schedule(s).
            </p>
            <p>
                5.
                LIMITATION OF LIABILITY. The total liability of DP Access to customer in connection with this Agreement,
                for
                any
                and all causes or actions and claims, including, without limitation, breach of contract, breach of
                warranty,
                negligence, strict liability, misrepresentation and other torts, shall be limited to the lesser of: (a)
                direct
                damages proven by Customer; or (b) the DP Access fee amount paid by customer to DP Access under this
                Agreement
                for the one (1) month period prior to accrual of the most recent cause of action. Nothing in this
                section
                shall
                limit DP Access&rsquo;s liability: (a) in tort for its willful or intentional misconduct; or (b) for
                bodily
                injury or death proximately caused by DP Access&rsquo;s negligence; or (c) loss or damage to real
                property
                or
                tangible personal property proximately caused by DP Access&rsquo;s negligence. DP Access will make every
                effort
                to prevent local and carrier phone company abuses but is not responsible or liable for actions outside
                its
                control such as slamming (the practice of unauthorized long distance carrier changes) or
                changes
                made by Customer that cause Local Telephone Company Casual Billing where customer is billed
                high
                per minute rates because of any number of conditions. Nor is DP Access responsible for any hacking or
                fraud.
                DP
                Access will, once identified, correct any issues that arise under any circumstance and is not liable for
                these
                conditions. DP Access will make recommendations and assist customer, local and carrier phone companies
                in
                implementing those recommendations to the best of its ability but it is not responsible or liable for
                any
                damages because of the implementation of recommendations or ongoing auditing process.
            </p>

            <p></p>
            
           

            <h4 style={{ "textAlign": "center" }}> SCHEDULE C &ndash; IT Network Services</h4>


            <p>1. Definitions &ndash; From time-to-time, customer may wish to take advantage of DP Access IT Network
                Services.
                Specifically, if needed to assist in the installation of Internet services. Carrier (ATT, Comcast,
                Other)
                will
                deliver service and in most cases, customer will have the technical ability to install/hook up service
                from
                Carrier drop off to customer equipment/router/modem (Remote Support). Should customer require technical
                assistance from DP Access, services and charges for services are listed below:
            </p>
            <p style={{ "margin": "0" }}> Network Setup and Installation</p>
            <p style={{ "margin": "0" }}>Basic Office Networks</p>
            <p style={{ "margin": "0" }}>E-Mail Services</p>
            <p style={{ "margin": "0" }}>Firewall Services</p>
            <p style={{ "margin": "0" }}>VPN (Virtual Private Networks)</p>
            <p style={{ "margin": "0" }}>Computer Repair and Upgrades</p>
            <p style={{ "margin": "0" }}>Security</p>
            <p style={{ "margin": "0" }}>Computer Upgrades</p>
            <p>Remote Support &ndash; to be billed at $99.00 per session.</p>
            <p>All other services listed above pricing based on statement of work estimates per project.</p>
            <br></br>
            
                    <h4 style={{ "textAlign": "center" }}> SCHEDULE D &ndash; WIRELESS INTERNET</h4>

                    <p>SECTION 1: Purchase and Sale of Service and Hardware. Customer agrees to purchase from DP Access (Company), and Company agrees to sell to <span style={{ 'padding': '1px 15px', 'borderBottom': '1px solid #000' }}><b>{content?.service_company_name}</b></span>(Customer), the internet connectivity Service on the wireless Network as stated in the Exhibits hereto.                </p>

                    <p>SECTION 2: Commencement and Duration. This Agreement commences on the Effective Date of initial activation and is month to month unless either party provides the other with a notice of termination at least thirty days prior to the end of the then-current term.</p>

                    <p>SECTION 3: Service Technology Disclosures. </p>

                    <p>3.1 Under this Agreement, Customer may not activate 2G or 3G Devices on the Supplier's Network. All Devices activated must contain a radio module that is activated on Supplier's 4G - LTE or later technology Networks</p>

                    <p>3.2 The 3G Network is not available.</p>

                    <p>3.3 Supplier is not obligated to maintain any particular technology, and may reduce or terminate 3G, LTE, and later technology Network access at any time at its sole discretion. Supplier will provide Customer six (6) months prior written notice of its intention to (i) terminate LTE or later technology Networks on a nationwide basis, (ii) prohibit future activations or reactivations of SIMs or devices using LTE or later technology on a nationwide basis, or (iii) terminate LTE, and later technology Services provided by Carrier Partner Networks which may be reduced or terminated on a different schedule with or without prior notice. Customer may terminate its Supplier wireless Services for particular Devices in specific countries or parts of countries without penalty and any minimum purchase requirements shall be equitably adjusted, to the extent that Supplier or its Carrier Partners no longer provide the technology in such specific country or parts of countries on which affected Devices operate.</p>

                    <p>3.4 This Section 3 applies only to the Supplier Network located in the United States; Supplier makes no commitments regarding facilities operated in the United States or elsewhere by its Carrier Partners.</p>

                    <p>3.5 For purposes of this Section 3, (i) "3G" means UMTS/HSPA (Universal Mobile Telecommunications System/High-Speed Packet Access) technologies, (ii) "LTE" means wireless services which use Long Term Evolution technology, also known as 4G LTE, and (iii) "5G" refers to the fifth generation of wireless technology.</p>

                    <p>SECTION 4: Default and Termination</p>

                    <p>4.1 This Agreement may be terminated upon an Event of Default by either party if such Event of Default is not cured by the defaulting party within thirty days of receipt of notice of the Default; provided, however, that in the case of failure to pay invoices or in the case of abuse or fraudulent use of the Service, or a violation of the confidentiality or publicity provisions of the General Terms and Conditions, this Agreement may be terminated if such Event of Default is not cured within ten days of receipt of notice of the Default.</p>

                    <p>4.2 Either party may terminate this Agreement at its convenience upon thirty days' prior written notice to the other party.</p>

                    <p>4.3 After the effective termination or expiration of this Agreement for any reason except for termination by Supplier due to a material breach by Customer, the parties agree to continue performing their respective obligations (the 'Assurance of Supply Period") under the terms of this Agreement that exist as of the date of termination or expiration for the purpose of unwinding the business relationship between the parties for a period of ninety (90) days following such termination or expiration.</p>

                    <p>4.4 Event of Default means any of the following: (i) the execution of any assignment for the benefit of creditors or the filing for relief by either party under any applicable bankruptcy, reorganization, moratorium, or similar debtor relief; (ii) the appointment of a receiver for Customer or Supplier or for substantially all of their respective assets or properties; (iii) either party's failure to pay any sum owed to the other hereunder at the time such amount comes due; (iv) either party's failure to perform or observe any other term, condition, or covenant to be performed by it under this Agreement; (v) an unauthorized assignment of this Agreement; or (vi) Customer's failure to utilize Equipment compatible with Supplier's Network or other network of Supplier, as determined by Supplier.</p>

                    <p>SECTION 6: International Roaming</p>

                    <p>6.1 Service is not permitted to be used under this Agreement for international roaming and requires a separate agreement/addendum. With respect to any international roaming under this Agreement, Customer:</p>

                    <p>● Shall not characterize the services or devices it provides to End Users as involving the sale, resale, or provision of wireless services, advertise that it is selling or providing wireless service, or charge its customers separately for wireless service.</p>

                    <p>● Shall obtain and hold any necessary licenses or authorizations to operate its business using the Service, and shall comply with any laws and regulations relating thereto.</p>

                    <p>● Provide Supplier with all assistance reasonably required to enable Supplier to comply with requests or requirements of a roaming carrier, regulator, or other governmental body relating to Customer's use of international roaming.</p>

                    <p>● Indemnify and hold Supplier harmless from any claims, losses or damages resulting from Customer's international roaming.</p>

                    <p>SECTION 7: Invoices, Billing Data, Payments</p>

                    <p>7.1 Invoices and Billing Data. Supplier will provide Customer a summary invoice via the portal and/or email for all charges incurred, normally within ten days of the cut-off date for each billing cycle. Supplier, in some cases, may also provide the invoice and billing data through physical delivery in addition to or as a substitute for use of the Portal.</p>

                    <p>7.2 Payments. Notwithstanding anything to the contrary in the General Terms and Conditions, payment, in a form payable in U.S. currency is due within 10 business days of receipt of Customer's invoice; for invoices made available electronically via a Portal, receipt is deemed to be the first day that an invoice is available to Customer for viewing on the Portal.</p>

                    <p>7.3 Rates. Customer will pay for Service at the rates set forth in the Service Plan(s) in the Exhibits hereto. Supplier may modify a Service Plan or charge as provided in the Service Guide.</p>

                    <p>SECTION 8: Industry Publicity</p>

                    <p>Customer agrees that if and when the Parties announce their relationship, Supplier shall have the right to use Customer's name, Marks, and approved images of Customer Devices in any materials (whether internal or external) other than advertising or marketing materials, provided that Customer approves the first such use in advance.</p>

                    <p>SECTION 9. WHAT ARE THE PROHIBITED USES FOR MY DEVICE AND THE SERVICES?</p>

                    <p>Services are a shared resource. Certain activities and uses of our Services and Customer Device are not permitted. If Customer buys, leases, or finances a Device or equipment manufactured for use on the network, Customer agrees, and we rely on Customer agreement, that Customer intends it to be activated on our Service and will not resell or modify the Device, or assist anyone doing so. Here are examples of prohibited uses:</p>

                    <p>A. Compromises network security or capacity, degrades network performance, uses malicious software or “malware”, hinders other customers’ access to the network, or otherwise adversely impacts network service levels or legitimate data flows;</p>

                    <p>B. Uses applications which automatically consume unreasonable amounts of available network capacity;</p>

                    <p>C. Uses applications which are designed for unattended use, automatic data feeds, automated machine-to-machine connections, or applications that are used in a way that degrades network capacity or functionality.</p>

                    <p>D. Misuses the Service, including "spamming" or sending abusive, unsolicited, or other mass automated communications;</p>

                    <p>E. Accesses the accounts of others without authority;</p>

                    <p>F. Results in more than 50% of Customer voice and/or data usage being Off-Net (i.e., connected to another provider’s network) for any 2 billing cycles within any 12-month period;</p>

                    <p>G. Results in unusually high usage (meeting the definition of a heavy data user for Customer Rate Plan) and the majority of Customer data usage being Smartphone Mobile HotSpot (tethering) usage for any 3 billing cycles within any 6-month period;</p>

                    <p>H. Resells the Service, either alone or as part of any other good or service;</p>

                    <p>I. Tampers with, reprograms, alters, or otherwise modifies Customer Device to circumvent any of our policies or violate anyone’s intellectual property rights;</p>

                    <p>J. Causes harm or adversely affects us, the network, our customers, employees, business, or any other person;</p>

                    <p>K. Conflicts with applicable law;</p>

                    <p>L. Is not in accordance with these T&Cs; or</p>

                    <p>M. Attempts or assists or facilitates anyone else in any of the above activities.</p>

                    <p>SECTION 10: Lawful Use of Service and Equipment.</p>

                    <p>Customer agree to use the Service and Equipment only for lawful purposes. Customer shall not use the Service or Equipment for transmitting or receiving any communication or material of any kind which receipt or possession of such communication or material would: (i) constitute a criminal offense, give rise to a civil liability, or otherwise violate any applicable local, state, national or international law; or (ii) encourage conduct that would constitute a criminal offense, give rise to a civil liability, or otherwise violate any applicable local, state, national or international law. Company reserves the right to terminate Customer Service immediately and without advance notice if Company, in its sole discretion, believes that Customer have violated the above restrictions, leaving Customer responsible for the full charges to the end of the current term, including without limitation unbilled charges, plus a disconnect fee, all of which immediately become due and payable.</p>

                    <p>Customer is liable for any and all use of the Service and/or Equipment by any person making use of the Service or Equipment provided to Customer and agrees to indemnify and hold harmless Company against any and all liability for any such use that fails to comply with this Section. Customer acknowledges and agrees that they are solely responsible for any and all use of the Service and/or Equipment provided to them, including any use by third parties. Customer hereby agrees to indemnify and hold harmless the Company, its officers, agents, and employees from any liability, loss, or damage arising from any such use that fails to comply with the terms of this Agreement.</p>

                    <p>Furthermore, Customer agrees to indemnify and hold harmless the Company, its officers, agents, and employees from any liability, loss, or damage they may suffer as a result of claims, demands, costs, or judgments arising out of activities conducted pursuant to the obligations of this Agreement. This includes, but is not limited to, the use of any results obtained from such activities.</p>

                    <p>If Company, in its sole discretion, believes that Customer has violated the above restrictions, Company may forward the objectionable material, as well as Customer communications with Company and Customer personally identifiable information for investigation. In addition, Customer should be aware that, pursuant to the terms of the Truth in Caller ID Act of 2009, it is illegal for any person or entity to knowingly cause, directly or indirectly, any caller identification service to transmit or display misleading or inaccurate caller identification information with the intent to defraud, cause harm, or wrongfully obtain anything of value. Company reserves the right to limit or otherwise prevent calls to certain services such as chat lines, free conference call services and the like where Company is charged fees by a third party carrier related to the termination of those calls that are higher than average wholesale long distance rates.</p>

                    <p><strong>Rate Table:</strong></p>

                    <p><strong>* Hardware Non Recuring Charge:</strong></p>

                    <p>MR40 CAT12 Router purchase and Set up: ${content?.service_non_recurring_cost}</p>


                    <p>**Unlimited Data Monthly Recurring Charge: ${content?.service_recurring_cost}.</p>

                    <p><strong>*Shipping charges Apply</strong></p>

                    <p>**Fair Usage Policy: This policy governs the use of our network resources under our unlimited data plan, which provides unrestricted internet access without data caps. However, it's important to note that while advertised as "unlimited," all resources have inherent limitations. Users are expected to engage in legal and legitimate activities, refraining from illegal actions, network abuse, or activities that degrade network performance. In cases of network congestion, we reserve the right to manage traffic fairly and transparently to ensure equitable access for all users.</p>

                    <p>**Consistent excessive data consumption, defined as usage exceeding 25% of our average customer base or as determined by our network administrators, may result in fair usage measures, such as temporary speed restrictions, to maintain network quality. Non-compliance with this policy may result in warnings, temporary suspension, or termination of service, as deemed necessary. This policy is subject to periodic review and may be amended to reflect industry best practices and regulatory requirements.</p>


                    <p>
                <strong>IN WITNESS WHEREOF</strong>, the Parties&rsquo; authorized
                representatives have executed this Agreement as of the date first written above.
            </p>

            <p>
                {/* Executed this  ____ day of __________. */}
                Executed this  <span style={{ 'padding': '1px 10px', 'borderBottom': '1px solid #000' }}>{suffix(date.getDate())}</span> day of  <span style={{ 'padding': '1px 10px', 'borderBottom': '1px solid #000' }}>{moment().format('MMM')}, {date.getFullYear()}</span>.

            </p>

                    <table style={{ "width": "75%", "textAlign": "left" }}>
                <thead>
                    <tr>
                        <th colSpan="2" style={{ 'textAlign': 'left' }}>DP Access, LLC:</th>
                        <th colSpan="2" style={{ "padding": "6px", 'textAlign': 'left' }} >Customer:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ "padding": "2px 10px 2px 0", }}><label htmlFor='companyBy'> By:</label></td>
                        <td style={{ "padding": "6px 8px", 'borderBottom': '1px solid #000', 'width': '40%' }}><img src={content?.by_company} style={{ "width": '100%', "maxHeight": "65px" }} alt="company_signature" /></td>
                        <td style={{ "padding": "2px 10px", cursor: 'pointer' }} ref={clientByRef}><label htmlFor='clientBy' style={{ cursor: 'pointer' }} onClick={() => url ? handleShowSpecimen() : handleOpenSignTest()}> By:</label></td>
                        <td style={{ "padding": "6px 0", 'textAlign': 'center', 'width': '40%', 'borderBottom': '1px solid #000', cursor: 'pointer', 'verticalAlign': 'bottom' }} onClick={() => url ? handleShowSpecimen() : handleOpenSignTest()} >
                            <div style={{ 'display': 'flex', 'alignItems': !url?'center':'flex-end' , 'justifyContent': !url?'center':'flex-start', 'minHeight': '65px', 'borderRadius': '0.16rem', 'boxShadow': `${!url ? '0 0 0 0.25rem rgba(13,110,253,.25)' : 'unset'}` }}>
                                {url && <img src={url} alt='signature' width={"100%"} ></img>} 
                                {!url && <span style={ {'color': '#c2c2c2','fontSize': '25px','fontStyle': 'italic','fontFamily': 'cursive'}}>Sign Here</span>}
                            </div>
                        </td>

                    </tr>
                    <tr>
                        <td style={{ "padding": "2px 10px 2px 0" }}><label htmlFor='companyName'> Name:</label> </td>
                        <td style={{ "padding": "6px 8px", 'borderBottom': '1px solid #000' }}> {content?.name_company}</td>
                        <td style={{ "padding": "2px 10px" }}><label htmlFor='clientName'> Name:</label></td>
                        <td style={{ "padding": "6px 0", 'borderBottom': '1px solid #000' }}><input className='form-control' disabled={submit} onChange={(e) => handleChange(e)} name='clientName' ref={clientNameRef} id='clientName' defaultValue={companyPro?.clientName} style={{ 'boxShadow': `${!companyPro?.clientName.trim() ? '0 0 0 0.25rem rgba(13,110,253,.25)' : 'unset'}` }}></input></td>

                    </tr>
                    <tr>
                        <td style={{ "padding": "2px 10px 2px 0" }}><label htmlFor='companyTitle'> Title:</label></td>
                        <td style={{ "padding": "6px 8px", 'borderBottom': '1px solid #000' }}>{content?.title_company}</td>
                        <td style={{ "padding": "2px 10px" }}><label htmlFor='clientTitle'> Title:</label></td>
                        <td style={{ "padding": "6px 0", 'borderBottom': '1px solid #000' }}><input className='form-control' disabled={submit} onChange={(e) => handleChange(e)} name='clientTitle' ref={clientTitleRef} id='clientTitle' defaultValue={companyPro?.clientTitle} style={{ 'boxShadow': `${!companyPro?.clientTitle.trim() ? '0 0 0 0.25rem rgba(13,110,253,.25)' : 'unset'}` }}></input></td>
                    </tr>
                </tbody>
            </table>
    
        </>
    )
}

export default WirelessContractDoc