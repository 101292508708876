import React from "react";
import CustombuttonSpinner from "./CustombuttonSpinner";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

function GroupDetailsForm({
  groupDetails,
  groupChange,
  groupDetailsSubmit,
  loader,
  logoImage,
  handleFileInputChange,
  removeImage,
  updateGroup,
  handleTeleFields
}) {
  return (
    <>
      <div className="createGroup-container">
        <form action="" onSubmit={groupDetailsSubmit}>
          <div className="col-12">
            <div className="invoiceHeader container">
              <div className="formField my-1 d-flex">
                <span className="text-danger mx-1">*</span>
                <input
                  type=""
                  name="title"
                  className="form-control"
                  onChange={(e) => groupChange(e)}
                  defaultValue={groupDetails?.title}
                  placeholder="Title"
                  autoComplete="off"
                  required
                ></input>{" "}
                {/* value="Invoice" */}
              </div>
              <div className="invoiceLogo my-1">
                {/* <input type="file" accept=".png,.jpge" name="invoiceLogo" onChange={(e)=>groupChange(e)} ></input>
                                <div className="logoNote">Add Logo</div> */}
                <div className="logoContainer form-control">
                  {logoImage === null && (
                    <div className="logolabel">
                      <i className="fas fa-image"></i>
                      <span> + Logo</span>
                    </div>
                  )}
                  <input
                    type="file"
                    className="form-control"
                    accept=".png,.jpeg,.jpg,.webp"
                    name="logo"
                    defaultValue={""}
                    onChange={handleFileInputChange}
                  ></input>
                  <span className="showlabel">
                    <i className="fa fa-plus"></i>
                    {logoImage === null ? " Add " : " Update "}
                    your logo
                  </span>
                  {logoImage !== null && (
                    <span className="removeImage" onClick={removeImage}>
                      <i className="fa fa-times"></i>
                    </span>
                  )}
                  {/* <div className="overlay"></div> */}
                  {logoImage !== null && (
                    <img
                      src={logoImage}
                      className="logoImage"
                      alt="test"
                      height={130}
                      width={200}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="fromPersonalDetails">
              <h4>From</h4>
              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="from_name">
                  Name <span className="text-danger">*</span>
                </label>
                <div className="w-100">
                  <input
                    className="col-9 form-control"
                    type="text"
                    name="from_name"
                    onChange={(e) => groupChange(e)}
                    defaultValue={groupDetails?.from_name}
                    required
                    autoComplete="off"
                  ></input>{" "}
                  {/* value="" */}
                </div>
              </div>

              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="from_email">
                  Email <span className="text-danger">*</span>
                </label>
                <div className="w-100">
                  <input
                    className="col-9 form-control"
                    type="email"
                    name="from_email"
                    onChange={(e) => groupChange(e)}
                    defaultValue={groupDetails?.from_email}
                    autoComplete="off"
                    required
                  ></input>{" "}
                  {/* value="" */}
                </div>
              </div>
              <div className="formField">
                <label className="col-sm-3 col-12" htmlFor="group_number">
                  Group# <span className="text-danger">*</span>
                </label>
                <div className="w-100">
                  <input
                    className="col-9 form-control"
                    type="text"
                    name="group_number"
                    placeholder="Group-1234"
                    onChange={(e) => groupChange(e)}
                    defaultValue={groupDetails?.group_number}
                    autoComplete="off"
                    required
                  ></input>{" "}
                  {/* value="" */}
                </div>
              </div>

              <div className="showMoreDetailsContainer fadeInOpacity">
                <div className="formField">
                  <label className="col-sm-3 col-12" htmlFor="from_website">
                    Website
                  </label>
                  <div className="w-100">
                    <input
                      className="col-9 form-control"
                      type="url"
                      name="from_website"
                      placeholder="https://www.abc.com"
                      onChange={(e) => groupChange(e)}
                      defaultValue={groupDetails?.from_website}
                      autoComplete="off"
                    ></input>{" "}
                    {/* value="" */}
                  </div>
                </div>
                <div className="formField">
                  <label className="col-sm-3 col-12" htmlFor="from_gst">
                    GST#
                  </label>
                  <div className="w-100">
                    <input
                      className="col-9 form-control"
                      type="text"
                      name="from_gst"
                      // placeholder="123-45-6789"
                      onChange={(e) => groupChange(e)}
                      defaultValue={groupDetails?.from_gst}
                      autoComplete="off"
                    ></input>{" "}
                    {/* value="" */}
                  </div>
                </div>
                <div className="formField">
                  <label className="col-sm-3 col-12" htmlFor="from_ntn">
                    NTN#
                  </label>
                  <div className="w-100">
                    <input
                      className="col-9 form-control"
                      type="text"
                      name="from_ntn"
                      // placeholder="123-45-6789"
                      onChange={(e) => groupChange(e)}
                      defaultValue={groupDetails?.from_ntn}
                      autoComplete="off"
                    ></input>{" "}
                    {/* value="" */}
                  </div>
                </div>
                <div className="formField align-items-start">
                  <label className="col-sm-3 col-12" htmlFor="senderAddress">
                    Address
                  </label>
                  <div className="col-sm-9 col-12">
                    <input
                      type="text"
                      className="form-control"
                      name="from_address1"
                      onChange={(e) => groupChange(e)}
                      placeholder="Street"
                      defaultValue={groupDetails?.from_address1}
                      autoComplete="off"
                    ></input>{" "}
                    <input
                      type="text"
                      className="form-control"
                      name="from_address2"
                      onChange={(e) => groupChange(e)}
                      placeholder="City,State"
                      defaultValue={groupDetails?.from_address2}
                      autoComplete="off"
                    ></input>{" "}
                    <input
                      type="text"
                      className="form-control"
                      name="from_zip_code"
                      onChange={(e) => groupChange(e)}
                      placeholder="ZipCode"
                      defaultValue={groupDetails?.from_zip_code}
                      autoComplete="off"
                    ></input>{" "}
                    {/* value="" */}
                  </div>
                </div>
               
                <div className="formField">
                  <label className="col-sm-3 col-12" htmlFor="from_phone">
                    Business Number
                  </label>
                  <div className="w-100">
                    {/* <input
                      className="col-8 form-control"
                      type="text"
                      name="from_phone"
                      maxLength="14"
                      placeholder="(123) 456-7890"
                      onChange={(e) => groupChange(e)}
                      value={groupDetails?.from_phone}
                      autoComplete="off"
                    ></input> */}
                    <PhoneInput
                        value={groupDetails?.from_phone}
                        country={'us'}
                        onChange={(value, country, e, formattedValue) => groupChange(e)}
                        countryCodeEditable={false}
                        inputProps={{
                          name: 'from_phone'
                        }}
                        // enableSearch={true}
                      />
                  </div>
                </div>
                 <div className="formField">
                  <label className="col-sm-3 col-12" htmlFor="from_fax">
                    Fax Number
                  </label>
                  <div className="w-100">
                    {/* <input
                      className="col-9 form-control"
                      type="text"
                      name="from_fax"
                      maxLength="14"
                      placeholder="(123) 456-7890"
                      onChange={(e) => groupChange(e)}
                      value={groupDetails?.from_fax}
                      autoComplete="off"
                    ></input> */}
                    <PhoneInput
                        value={groupDetails?.from_fax}
                        country={'us'}
                        onChange={(value, country, e, formattedValue) => groupChange(e)}
                        countryCodeEditable={false}
                        inputProps={{
                          name: 'from_fax'
                        }}
                        // enableSearch={true}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            <button
              type="submit"
              className={`btn btn-save ${loader ? "cursor-disable" : ""}`}
              disabled={loader ? true : false}
              title={`${updateGroup ? 'Update': 'Save'} Group`}
            >
              <span> {updateGroup ? 'Update': 'Save'} </span>&nbsp; <i className="fa fa-save"></i>
              {loader && (
                <span className="spinner-container">
                  <CustombuttonSpinner />
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default GroupDetailsForm;
