import React, { useState } from 'react'
import { useForgetPassowrdMutation } from '../store/apis/baseApi';
import logo from "../assets/images/first_without_background.webp"
import { errorMessage, successMessage } from '../components/toastMessages/ToastifyMsg';
import { Link, useNavigate } from 'react-router-dom';
import CustombuttonSpinner from '../components/CustombuttonSpinner';
// import forgotbg from '../assets/images/forgotpassbg.png'

function ForgetPassword() {
const [forgetPassowrd] = useForgetPassowrdMutation();
const navigate = useNavigate();
const [loader,setLoader] = useState(false);

    const handleSubmit = async(e)=>{
      setLoader(true);
        e.preventDefault();
        let email= {email: e.target.email.value}
        try {
          const {data,error} = await forgetPassowrd(email);
          if (data) {
            if (data.success) {
              setLoader(false);
              successMessage(data.messsage);
              navigate("/login");
            }
            if (!data.success) {
              setLoader(false);
              errorMessage(data?.messsage);
            }
            
          }
          if (error) {
            // console.log("forgetAPIerror",error?.data);
            setLoader(false);
            errorMessage(error?.error ?? error?.data?.error?.message);
          }
        }
        catch (error){
          // console.log("catch>error" ,error);
        }
    }
    
  return (
    <>
    <section className="loginView--container">
        <div className="loginView-wrapper forgot-View-wrapper">
          <div className="col-11 col-md-10 col-lg-8 credentials-wrapper credentials-forget-wrapper">
            {/* <div className="logoContainer forgetLogoCont">
                <img src={logo} alt="logo"></img>
            </div> */}
            <div className="credentials-wrapper-inner credentials-forget-wrapper-inner">
              <div className="logoContainer forgetLogoCont">
                <img src={logo} alt="logo"></img>
                {/* <img src={forgotbg} alt='wave'/> */}
              </div>
              <div className="credentials-content">
                <div className="form-container">
                  {/* <form onSubmit={(e)=>handleSubmit(e)}>
                            <input type="email" name="email" ></input>
                            <button type='submit'>click </button>
                        </form> */}
                  <form onSubmit={(e) => handleSubmit(e)} data-aos="zoom-in">

                    <div className="subscribe">
                      <h3>Forgot Your <span className="pos-rel">TradingForms</span> <br></br>Password?</h3>
                      {/* <input placeholder="E-mail" className="subscribe-input" name="email" type="email" required></input> */}
                      <div className="input-container px-0">
                        <input className="input"
                          type="email"
                          name="email"
                          //  autoComplete="new-password"
                          required

                          autoComplete='off'
                        // autoComplete={"false"}
                        ></input>
                        <label className="label" htmlFor="email">Email<span className="text-danger">*</span> </label>
                      </div>
                      <div className="text-start">
                        <span><span className="text-danger">Note:</span> Enter vaild email to change password.</span> <br />
                        <small className="signup mt-2"><span>Already have an account? </span><Link to="/login" className=""> Sign in</Link></small>

                      </div>
                      <br />
                      <div className="">
                        <button type='submit' className="submit-btn">
                          SEND EMAIL
                          {loader &&
                            <span className="spinner-container">

                              <CustombuttonSpinner />
                            </span>
                          }
                        </button>
                      </div>
                      {/* <div className="input-container px-0">
                        <input className="input"
                          type="text"
                          name="email"
                          //  autoComplete="new-password"
                          required
                          
                         
                          // autoComplete={"false"}
                          ></input>
                        <label className="label" htmlFor="email">Email</label>
                      </div> */}
                    </div>

                    {/* ========= */}

                  </form>
                </div>
              </div>
            </div>
          </div>
            <div className="overlay"></div>
        </div>
    </section>
    
    
    
    
    </>
    
  )
}

export default ForgetPassword